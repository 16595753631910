import { Fragment, useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import toast from "react-hot-toast";
import SessionExpiredDialog from "components/Dialog/SessionExpiredDialog";
import jwtDecode from "jwt-decode";

type Props = {
    open: boolean;
    handleClose: () => void;
    children: JSX.Element;
};

export const deleteSession = ({ loginPage = true }) => {
    localStorage.clear();

    let route = loginPage ? `login` : ``;

    window.location.href = `${window.location.origin}/${route}`;
    return {
        isSuccess: false, // For unsuccessful network request isSuccess is false
        newAuthToken: "",
    };
};

const UserSessionProvider = ({ open, handleClose, children }: Props) => {
    const authToken = localStorage.getItem("authToken") || "";
    const signOut = useSignOut();
    useEffect(() => {
        if (authToken) {
            try {
                const data: any = jwtDecode(authToken);
                if (data.exp - Date.now() / 1000 < 0) {
                    toast.dismiss();
                    toast.error("Your access token has expired. Please login again");
                    signOut();
                }
            } catch (error) {}
        }
    }, []);

    useEffect(() => {
        let timeoutId: NodeJS.Timeout;
        if (open)
            timeoutId = setTimeout(() => {
                deleteSession({});
            }, 5000 * 60);

        return () => clearTimeout(timeoutId);
    }, [open]);

    return (
        <Fragment>
            <SessionExpiredDialog
                open={open}
                handleClose={() => {}}
                handleContinue={handleClose}
                handleLogout={() => deleteSession({ loginPage: false })}
            />
            {children}
        </Fragment>
    );
};

export default UserSessionProvider;
