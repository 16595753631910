import { env } from "../../env";
import { getGenericPostRequest, getGenericGetRequest, buildURLs, buildKeys, getGenericPutRequest } from "../utils";

const BASE_URLS = {
    //GET requests
    GET_BACK_OFFICE_SALES_PERSON: "loan-application/salesperson",
    // Post requests
    FORM_DATA: "form-data",
    GET_MANUFACTURER: "backoffice/get-manufacturer",
};

let prefix;

if (env.REACT_APP_API_URL) {
    prefix = `${env.REACT_APP_API_URL}`;
} else {
    prefix = `${env.REACT_APP_API_URL}`;
}

const URLS = buildURLs({
    urls: BASE_URLS,
    prefix,
});

// Export all generic functions
// GET requests
export const getBackOfficeSalesPerson = getGenericGetRequest(URLS.GET_BACK_OFFICE_SALES_PERSON);
export const getManufacturer = getGenericGetRequest(URLS.GET_MANUFACTURER);

// POST requests
export const formData = getGenericPostRequest(URLS.FORM_DATA);

// PUT requests

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
