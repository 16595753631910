import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";

const RequirementForm = ({ prefix, setCurrentInput, pane }: { prefix: string; setCurrentInput: any; pane?: string }) => {
    const requirement = useWatch({ name: `${prefix}.requirement` });
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Work Contract"
                data-testid={generateTestId(`work-contract-${pane}`, "field")}
                value={requirement?.workContract}
                name={`${prefix}.requirement.workContract`}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "workContract",
                    fileAddress: `${prefix}.requirement.workContract`,
                })}
                showCross={localStorage.getItem("disable_form") !== "true" && requirement?.workContract && requirement?.workContract?.link !== ""}
                handleFileModalOpen={() => setCurrentInput({ name: `${prefix}.requirement.workContract`, open: true, multiple: false, position: 0 })}
            />
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Invoice"
                data-testid={generateTestId(`invoice-${pane}`, "field")}
                value={requirement?.workContract}
                name={`${prefix}.requirement.invoice`}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "workContract",
                    fileAddress: `${prefix}.requirement.invoice`,
                })}
                showCross={localStorage.getItem("disable_form") !== "true" && requirement?.invoice && requirement?.invoice?.link !== ""}
                handleFileModalOpen={() => setCurrentInput({ name: `${prefix}.requirement.invoice`, open: true, multiple: false, position: 0 })}
            />
        </Grid>
    );
};

export default RequirementForm;
