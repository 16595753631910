import { LoanOutstandingCreateInput } from "@/__generated__/apiSchemas";

const fixture: LoanOutstandingCreateInput = {
    hasAdditionalLoanOutstanding: false,
    accountType: "Term Loan",
    loanSecurity: "",
    collateralValue: 0,
    accountNumber: "",
    bankName: "",
    financedBy: "",
    otherFinancedBy: "",
    outstandingLoanAmount: "0",
    sanctionAmount: 0,
    repaymentFrequency: "",
    otherRepaymentFrequency: "",

    originalLoanTenorInMonths: 0,
    remainingTenorInMonths: 0,
    emiAmount: "0",
    isLoanOverdue: false,
    amountOverdue: "0",
    numberOfEMIOverdue: 0,
    reasonForNonPayment: "",
};

export default fixture;
