import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { CircularProgress, Grid } from "@mui/material";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { useCityStateValue } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import getEntityDetails from "utils/getEntityDetails";
import fixture from "./fixture";

type Props = {};

const Address = (props: Props) => {
    const postalCode = useWatch({ name: "contact.address.postalCode" });
    const { setValue } = useFormContext();
    const entity = useWatch({ name: "applicant.entity" });
    let personalField = getEntityDetails(entity).personalField;
    if (personalField !== "personal") personalField = `${personalField}.0`;
    const personal = useWatch({ name: `applicant.personal.${personalField}` });
    const contactDetails = useWatch({ name: "contact" });

    const isDisabled = contactDetails?.role === "user" && contactDetails?.userType !== "registered";

    let address = entity.registeredAddress;
    if (["Individual", "Sole Proprietor"].includes(entity?.business)) {
        address = personal.permanentAddress;
        if (address.isSameAsResidentialAddress) {
            address = entity.residentialAddress;
        }
    }

    useEffect(() => {
        if (isDisabled) {
            for (let field of ["address1", "address2", "address3", "landmark", "postalCode", "state", "city"]) {
                if (address[field]) setValue(`contact.address.${field}`, address[field]);
            }
        }
    }, [address]);

    const { isLoading } = useCityStateValue({
        pincode: postalCode,
        cityName: `contact.address.city`,
        stateName: `contact.address.state`,
    });

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CommonAccordion title={`Address`} data-testid={generateTestId(`Address`, "accordion")} tab="contact">
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue }, index) => {
                        return (
                            <CommonFields
                                key={index}
                                inputType={inputType}
                                multiLine
                                name={`contact.address.${name}`}
                                options={
                                    options?.map((value) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                label={label}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable || (name !== "url" && isDisabled)}
                                subtitle={subtitle}
                                adorment={
                                    isLoading &&
                                    (name.endsWith("city") || name.endsWith("state")) && (
                                        <CircularProgress size={20} style={{ marginRight: "1rem" }} />
                                    )
                                }
                            />
                        );
                    })}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default Address;
