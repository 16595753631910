import React, { useContext, memo, useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { NavLink, Link } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import Logo from "assets/images/logo.png";
import SessionContext from "contexts/session";
import { ROUTES } from "globalConstants";
import useFeature from "hooks/useFeature";
import { Accordion, AccordionSummary, AccordionDetails } from "mui-styles/StyledField";
import MyAccount from "popups/MyAccount";
import "./Navbar.styles.scss";

//update the navbar and navlinks to display it automatically in the nav section without updating the code manually
const NAV_ITEMS = [
    {
        label: "LOANS",
        path: ROUTES.CONTACT_US,
    },
    {
        label: "PARTNER WITH US",
        path: ROUTES.CONTACT_US,
    },
    {
        label: "CONTACT US",
        path: ROUTES.CONTACT_US,
    },
    {
        label: "LOGIN",
        path: ROUTES.LOGIN,
    },
];
const NAV_ITEMS_LOGGEDIN = [
    {
        label: "PARTNER WITH US",
        path: ROUTES.CONTACT_US,
    },
];

const Navbar = () => {
    const shouldShowProfilePage = useFeature("profile-page");
    const { authUser } = useContext(SessionContext);
    const [expandNavBar, setExpandNavBar] = React.useState(false);
    const handleDrawerToggle = () => {
        setExpandNavBar(!expandNavBar);
        if (!expandNavBar) document.body.style.overflow = "hidden";
        else document.body.style.overflow = "scroll";
    };

    const signOut = useSignOut();

    const AUTH_NAV_ITEMS = [
        {
            label: "Home Page",
            path: ROUTES.HOME_PAGE,
        },
        {
            label: "My Loans",
            path: ROUTES.HOME,
        },
        {
            label: "Reports",
            path: ROUTES.HOME,
        },
        {
            label: "Partner With Us",
            path: ROUTES.HOME,
        },
        {
            label: "Contact Us",
            path: ROUTES.CONTACT_US,
        },
        {
            label: "Change Password",
            path: ROUTES.RESET_PASSWORD,
        },
        {
            label: "Logout",
            path: "/home-page",
            onClick: () => {
                signOut();
            },
        },
    ];

    useEffect(() => {
        if (shouldShowProfilePage && !AUTH_NAV_ITEMS.some((item) => item.label === "My Profile"))
            AUTH_NAV_ITEMS.push({
                label: "My Profile",
                path: ROUTES.PROFILE,
            });
    });

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
            {/* <Typography variant="h5" sx={{ my: 2 }}>
                <img src={Logo} alt="logo" className="logo" width="175px" />
            </Typography> */}
            {/* <Divider /> */}
            <List>
                {/* before login */}
                {authUser === null
                    ? NAV_ITEMS.map(({ label, path }, index) => (
                          <ListItem key={index} disablePadding>
                              {label !== "LOGIN" ? (
                                  <ListItemButton
                                      style={{ textDecoration: "none", color: "#1b75bc", fontWeight: "400", textTransform: "capitalize" }}
                                      component={NavLink}
                                      to={path}
                                      className="sm-nav-link"
                                  >
                                      <ListItemText primary={label.toLowerCase()} />
                                  </ListItemButton>
                              ) : (
                                  <ListItemButton
                                      style={{ textDecoration: "none", color: "#1b75bc", fontWeight: "400", textTransform: "capitalize" }}
                                      component={NavLink}
                                      to={path}
                                  >
                                      <Button variant="contained" className="nav-login-btn" color="warning">
                                          LOGIN
                                      </Button>
                                  </ListItemButton>
                              )}
                          </ListItem>
                      ))
                    : AUTH_NAV_ITEMS.map(({ label, path, onClick }, index) => (
                          <ListItem key={index} disablePadding>
                              <ListItemButton className="sm-nav-link" component={NavLink} to={path} onClick={onClick}>
                                  <ListItemText primary={label} />
                              </ListItemButton>
                          </ListItem>
                      ))}
            </List>
        </Box>
    );

    return (
        <div className="nav-bar-sticky">
            <AppBar elevation={0} position="sticky" sx={{ margin: 0, width: "100%", bgcolor: "white" }}>
                <div className="top-section"></div>
                <Toolbar disableGutters>
                    <Grid className="nav__content" container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid container item xs={11}>
                            <Grid item>
                                <NavLink to={ROUTES.HOME}>
                                    <img src={Logo} alt="logo" className="logo" />
                                </NavLink>
                            </Grid>
                            <Grid
                                alignItems="center"
                                justifyContent="space-between"
                                item
                                sx={{ marginLeft: "3.7rem", display: { xs: "none", sm: "none", md: "block", lg: "block" } }}
                            >
                                {authUser === null
                                    ? NAV_ITEMS.map(({ label, path }, index) =>
                                          label !== "LOGIN" ? (
                                              <Typography
                                                  key={index}
                                                  style={{ textDecoration: "none" }}
                                                  component={NavLink}
                                                  to={path}
                                                  className="app-nav-links"
                                                  textAlign="center"
                                              >
                                                  {label}
                                              </Typography>
                                          ) : (
                                              ""
                                          )
                                      )
                                    : NAV_ITEMS_LOGGEDIN.map(({ label, path }, index) =>
                                          label !== "LOGIN" ? (
                                              <Typography
                                                  key={index}
                                                  style={{ textDecoration: "none" }}
                                                  component={NavLink}
                                                  to={path}
                                                  className="app-nav-links"
                                                  textAlign="center"
                                              >
                                                  {label}
                                              </Typography>
                                          ) : (
                                              ""
                                          )
                                      )}
                            </Grid>
                        </Grid>
                        <Grid container item xs={1} direction="row" alignItems="center" justifyContent="flex-end">
                            {authUser === null ? (
                                <Button
                                    component={Link}
                                    to="/login"
                                    variant="contained"
                                    className="nav-login-btn"
                                    sx={{ mr: 2, display: { md: "block", lg: "block", xs: "none" } }}
                                    color="warning"
                                    data-testid="login-home-button"
                                >
                                    LOGIN
                                </Button>
                            ) : (
                                <MyAccount />
                            )}
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { lg: "none", md: "none", sm: "block" } }}
                            >
                                {expandNavBar ? <CloseIcon className="nav-icon" /> : <MenuIcon className="nav-icon" />}
                            </IconButton>
                        </Grid>
                    </Grid>
                </Toolbar>
                <Accordion expanded={expandNavBar} sx={{ display: { lg: "none", md: "none", sm: "block" }, backgroundColor: "white", opacity: 1 }}>
                    <AccordionSummary style={{ display: "none" }} aria-controls="panel1d-content" id="panel1d-header"></AccordionSummary>
                    <AccordionDetails>{drawer}</AccordionDetails>
                </Accordion>
                {/* <Box component="nav">
                    <Drawer
                        container={container}
                        variant="persistent"
                        anchor="top"
                        open={mobileOpen}
                        onClose={handleDrawerToggle}
                        ModalProps={{
                            keepMounted: true,
                        }}
                        sx={{
                            display: { md: "none", xs: "block" },
                            "& .MuiDrawer-paper": {
                            boxSizing: "border-box",
                            },
                        }}
                        >
                    </Drawer>
                </Box> */}
            </AppBar>
        </div>
    );
};

export default memo(Navbar);
