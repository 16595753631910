import { INPUT_TYPE } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "income",
        label: "Income",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        subtitle: "Last 12 Months",
    },
    {
        name: "ownUnderlyingAsset",
        label: "Asset",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        subtitle: "Own Underlying Asset ?",
    },
];

export default fixture;
