import { useState, useCallback } from "react";
import { useSignOut } from "react-auth-kit";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { verifyGst } from "api";
import { ROUTES } from "globalConstants";

const useGstVerify = () => {
    const [gst, setGst] = useState("");
    const formId = localStorage.getItem("form_id");
    const [gstVerified, setGstVerified] = useState(false);
    const [gstDetails, setGstDetails] = useState(null);
    const navigate = useNavigate();
    const signOut = useSignOut();
    const { mutate: gstVerify } = useMutation(verifyGst, {
        onSuccess: (data) => {
            if (data?.message === "GST Verification Successful") {
                setGstVerified(true);
                setGstDetails(data?.data?.data);
                toast.dismiss();
                toast.success(data?.message);
            } else {
                toast.dismiss();
                toast.error(data?.message);
            }
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message);
            if (error?.response?.data?.message === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });
    const handleGstValidate = useCallback(() => {
        if (formId && gst.length > 0) {
            toast.dismiss();
            toast.loading("Verifying GST...");
            gstVerify({
                gst: gst,
                form_id: formId,
            });
        }
    }, [gst, formId, gstVerify]);
    return { setGst, gstVerified, handleGstValidate, gstDetails };
};

export default useGstVerify;
