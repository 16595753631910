import React, { useState, useContext } from "react";
import { useSignOut } from "react-auth-kit";
import { Link, NavLink } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import { ROUTES } from "globalConstants";
import useFeature from "hooks/useFeature";
import ChangePasswordPopup from "../popups/ChangePasswordPopup";

const MyAccount = () => {
    const shouldShowProfilePage = useFeature("profile-page");
    const signOut = useSignOut();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openPasswordPopup, setOpenPasswordPopup] = useState(false);

    const handlePasswordPopupOpen = () => {
        setOpenPasswordPopup(true);
    };
    const handlePasswordPopupClose = () => {
        setOpenPasswordPopup(false);
    };
    return (
        <React.Fragment>
            <Box sx={{ display: { xs: "none", sm: "none", md: "flex" }, mr: 2, alignItems: "center", textAlign: "center" }}>
                <Tooltip title="Account settings">
                    <IconButton
                        onClick={handleClick}
                        size="small"
                        aria-controls={open ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? true : undefined}
                        data-testid="my-account-menu"
                    >
                        <Avatar sx={{ width: 30, height: 30, mr: 0.5 }} />
                        <span style={{ color: "#1b75bc", fontWeight: "300", padding: 0, marginRight: "35px", fontSize: "16px" }}>My Account</span>
                    </IconButton>
                </Tooltip>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        color: "#1b75bc !important",
                        fontWeight: "400",
                        padding: 0,
                        marginRight: "35px",
                        letterSpacing: "1px",
                        "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 80,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
                <MenuItem component={Link} to={ROUTES.HOME_PAGE} style={{ marginTop: "8px" }}>
                    My Loans
                </MenuItem>
                {shouldShowProfilePage && (
                    <MenuItem component={Link} to={ROUTES.PROFILE}>
                        My Profile
                    </MenuItem>
                )}
                {/* <MenuItem component={Link} to="/">
                    My Application
                </MenuItem>
                <MenuItem component={Link} to="/">
                    Reports
                </MenuItem> */}
                <MenuItem component={Link} to={ROUTES.CONTACT_US}>
                    Partner With Us
                </MenuItem>
                <MenuItem component={Link} to={ROUTES.CONTACT_US}>
                    Contact Us
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={() => {
                        handlePasswordPopupOpen();
                    }}
                    component={Link}
                    data-testid="change-password-my-account-button"
                    to="#"
                >
                    Change Password
                </MenuItem>
                <MenuItem
                    style={{ marginBottom: "8px" }}
                    onClick={() => {
                        signOut();
                    }}
                    component={Link}
                    to="/"
                    data-testid="my-account-menu-logout"
                >
                    Logout
                </MenuItem>
            </Menu>
            <ChangePasswordPopup handleClickOpen={handlePasswordPopupOpen} handleClose={handlePasswordPopupClose} open={openPasswordPopup} />
        </React.Fragment>
    );
};

export default MyAccount;
