import { Fragment, memo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { HandleNextDialog } from "components/Dialog";
import TabsWrapper from "components/TabsWrapper";
import { useSaveForm } from "hooks";
import { useGuarantorFields, useCoborrowerFields } from "pages/Dashboard/hooks";
import { IncomeTab } from "./components";

const IncomeForm = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const coborrowerFields = useCoborrowerFields();
    const guarantorFields = useGuarantorFields();
    const [open, setOpen] = useState(false);
    const errors = useWatch({
        name: "errors",
    });

    const values = useWatch();
    const hasCoBorrower = useWatch({
        name: "requirement.hasCoBorrower",
    });
    const hasGuarantor = useWatch({
        name: "requirement.hasGuarantor",
    });

    const { setValue } = useFormContext();

    const handleSave = useSaveForm();

    const handleProceed = () => {
        setCurrentStep(3);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Grid container id="form">
                <TabsWrapper
                    pane="income"
                    titles={[
                        "Applicant",
                        // coborrower titles
                        ...coborrowerFields.map((_, index) => {
                            if (coborrowerFields.length === 1) return `Co-borrower`;

                            return `Co-borrower ${index + 1}`;
                        }),
                        // ...guarantorTitles,
                        ...guarantorFields.map((_, index) => {
                            if (guarantorFields.length === 1) return `Guarantor`;

                            return `Guarantor ${index + 1}`;
                        }),
                    ]}
                    bodies={[
                        <IncomeTab index={0} header="applicant" title={`Applicant`} />,
                        // coborrower bodies
                        ...coborrowerFields.map((field, index) => (
                            <IncomeTab
                                index={index}
                                header="coborrower"
                                key={field.id}
                                title={coborrowerFields.length === 1 ? `Co-borrower` : `Co-borrower ${index + 1}`}
                            />
                        )),
                        // guarantor bodies
                        ...guarantorFields.map((field, index) => (
                            <IncomeTab
                                index={index}
                                header="guarantor"
                                key={field.id}
                                title={guarantorFields.length === 1 ? `Guarantor` : `Guarantor ${index + 1}`}
                            />
                        )),
                    ]}
                />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(1)} data-testid="back-button">
                    <KeyboardArrowLeftIcon id="left-arrow" />
                    Back
                </Button>
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={handleSave}
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    className="btn-arrow"
                    onClick={() => {
                        let shouldProceed = true;

                        for (let header of ["applicant", "guarantor", "coborrower"]) {
                            if (header === "applicant") {
                                const headerErrors = errors[header]?.income;
                                const income = values[header]?.income;

                                for (let i in headerErrors) {
                                    if ((i === "asset" || i === "loanOutstanding") && headerErrors[i].some((item: any) => item?.errorsCounts > 0))
                                        shouldProceed = false;
                                    else
                                        for (let j in headerErrors["income"]) {
                                            const incomeErrors = headerErrors["income"][j];

                                            if (income.income[j]?.[`has${j[0].toUpperCase() + j.slice(1)}`] && incomeErrors?.errorsCounts > 0) {
                                                shouldProceed = false;
                                            }
                                        }
                                }
                            } else if (hasCoBorrower === true || hasGuarantor === true) {
                                errors[header]?.forEach((error: any, i: number) => {
                                    const headerErrors = error?.income;
                                    const income = values[header][i]?.income;

                                    for (let i in headerErrors) {
                                        if ((i === "asset" || i === "loanOutstanding") && headerErrors[i].some((item: any) => item?.errorsCounts > 0))
                                            shouldProceed = false;
                                        else
                                            for (let j in headerErrors["income"]) {
                                                const incomeErrors = headerErrors["income"][j];

                                                if (income.income[j]?.[`has${j[0].toUpperCase() + j.slice(1)}`] && incomeErrors?.errorsCounts > 0) {
                                                    shouldProceed = false;
                                                }
                                            }
                                    }
                                });
                            }
                        }
                        if (shouldProceed) handleProceed();
                        else {
                            setValue("showIncompleteField", true);
                            setOpen(true);
                        }
                    }}
                    data-testid="next-button"
                >
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(IncomeForm, () => true);
