import { useEffect, useLayoutEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { OTHER } from "globalConstants";
import { useRcVerify } from "hooks";
import AssetItem from "../AssetItem";
import getVehicleCategory from "../getVehicleCategory";
import fixture from "./fixture";

type Props = {
    index: number;
    prefix: string;
    pane?: string;
};

const ManualForm = ({ index: externalIndex, prefix, pane }: Props) => {
    const asset = useWatch({ name: `${prefix}.${externalIndex}` });
    const { setRc, rcVerified, handleRcValidate, rcDetails, setRcDetails, setRcVerified } = useRcVerify({
        defaultRcVerified: asset?.registrationNumberVerified,
    });
    const { setValue, getValues } = useFormContext();

    useLayoutEffect(() => {
        if (asset?.registrationNumber) setRc(asset?.registrationNumber);
    }, [asset?.registrationNumber]);

    useEffect(() => {
        if (rcVerified) {
            setValue(`${prefix}.${externalIndex}.registrationNumberVerified`, true);
        }
    }, [rcVerified]);

    const activeFields: string[] = ["hasAsset"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${externalIndex}.${item}`) === "" || getValues(`${prefix}.${externalIndex}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.${externalIndex}.errorsCounts`, errorsCounts);
    }, [asset]);

    useEffect(() => {
        if (rcDetails !== null) {
            let assetToFill = asset;
            if (asset.type === "Vehicle") {
                const vehicleCategory = getVehicleCategory(rcDetails?.vehicle_category);
                assetToFill = {
                    ...assetToFill,
                    registrationNumberVerified: true,
                    manufacturer: rcDetails?.maker_description,
                    category: vehicleCategory,
                    registrationDetails: rcDetails,
                    otherCategory: vehicleCategory === OTHER ? rcDetails?.vehicle_category_description : "",
                    model: rcDetails?.maker_model,
                    yearOfManufacture: rcDetails?.manufacturing_date?.split("/")[1],
                };
            }
            if (asset.type === "Construction Equipment") {
                assetToFill = {
                    ...assetToFill,
                    manufacturer: OTHER,
                    otherManufacturer: rcDetails?.maker_description,
                    category: OTHER,
                    registrationDetails: rcDetails,
                    registrationNumberVerified: true,
                    otherCategory: rcDetails?.vehicle_category_description,
                    model: OTHER,
                    otherModel: rcDetails?.maker_model,
                    yearOfManufacture: rcDetails?.manufacturing_date?.split("/")[1],
                };
            }
            setValue(`${prefix}.${externalIndex}`, assetToFill);
        }
    }, [rcDetails]);

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {fixture.map((props, index) => {
                return (
                    <AssetItem
                        activeFields={activeFields}
                        props={props}
                        handleRcValidate={handleRcValidate}
                        rcVerified={rcVerified}
                        prefix={prefix}
                        externalIndex={externalIndex}
                        index={index}
                        setRcVerified={setRcVerified}
                        setRcDetails={setRcDetails}
                        pane={pane}
                    />
                );
            })}
        </Grid>
    );
};

export default ManualForm;
