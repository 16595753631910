import { useEffect, useState } from "react";
import { useSignOut } from "react-auth-kit";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useMutation, useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { getScrapedCreditBureauReport, uploadCreditBureauReport } from "api";
import { ROUTES } from "globalConstants";
import { useSaveForm, useUploadFile } from "hooks";
import { getCreditBureauLoanAccounts, mapLoanAccountCreditFields } from "utils";
import getEntityDetails from "utils/getEntityDetails";

type Props = {
    fileAddress: string;
    name: string;
    type: string;
    prefix: string;
    entityPrefix: string;
};

const useCreditBureauReport = ({ name, fileAddress, type, prefix, entityPrefix }: Props) => {
    const formId = localStorage.getItem("form_id");
    const entity = useWatch({ name: `${entityPrefix}entity` });
    const { setValue } = useFormContext();
    const signOut = useSignOut();
    const navigate = useNavigate();
    const saveForm = useSaveForm(false);
    const location = useLocation();

    let entityKey = entityPrefix?.slice(0, entityPrefix.length - 1).replace(".", "_");
    let entityName = getEntityDetails(entity).entityName;
    let loanFromAccountPrefix = `${entityPrefix}income.loanFromAccount`;
    let loanAccountCreditPrefix = `${entityPrefix}document.business.loanAccountCredit`;
    let formType = location.pathname.includes("prospective-form") ? "prospective" : "long";

    if (prefix.includes("personal")) {
        entityKey = `${entityKey}_${prefix.split(".").slice(-2).join("_")}`;
        entityName = "";
        loanFromAccountPrefix = `${prefix}.loanFromAccount`;
        loanAccountCreditPrefix = `${entityPrefix}document.personal.personal.${prefix.slice(-1)}.loanAccountCredit`;
    }

    // console.log(prefix, entityPrefix, loanAccountCreditPrefix, loanFromAccountPrefix);

    const handleCreditBureauUpload = (downloadData: any) => {
        for (let data of downloadData) {
            const uploadParameters: {
                form_id: string | null;
                file_name: any;
                file_link: string;
                report_type: string;
                entity_key: string;
                form_type: string;
            } = {
                form_id: formId,
                file_name: data.filename,
                report_type: type,
                file_link: data.link,
                entity_key: entityName || entityKey,
                form_type: formType,
            };

            upload(uploadParameters);
            toast.dismiss();
            toast.loading("Generating Credit Bureau Analysis Report");
        }
    };

    const handleUploadFile = useUploadFile({
        name,
        fileAddress,
        showToast: false,
        callback: handleCreditBureauUpload,
    });

    const [requestID, setRequestID] = useState("");

    const { data } = useQuery(
        [`KEYS.CREDIT_BUREAU-${prefix}`],
        () =>
            getScrapedCreditBureauReport({
                queryParams: {
                    requestID,
                },
            }),
        {
            enabled: !!requestID,
            refetchOnWindowFocus: false,
            refetchInterval: 5000,
            onError: (error: any) => {
                toast.dismiss();
                toast.error(error?.response?.data?.message);
            },
        }
    );

    useEffect(() => {
        const status = data?.data?.status;
        if (status === "success") {
            let scrapedData = data?.data?.scraped_data;

            if (!scrapedData?.accounts?.length) return;

            if (requestID) {
                toast.dismiss();
                toast.success("Credit Bureau Analysis Report Generated Successfully");
                setRequestID("");
                setValue(`${prefix}.creditBureau.scrapedData`, data?.data?.scraped_data);
                setValue(`${prefix}.creditBureau.excelURL`, data?.data?.excel_url);

                const loanAccounts = getCreditBureauLoanAccounts(scrapedData);
                const updatedLoanAccountCredit = mapLoanAccountCreditFields(loanAccounts);
                setValue(loanFromAccountPrefix, loanAccounts);
                setValue(loanAccountCreditPrefix, updatedLoanAccountCredit);
                saveForm();
            }
        } else if (status === "processing") {
        } else if (status === "failed") {
            setRequestID("");
            toast.dismiss();
            toast.error("Credit Bureau Analysis Report Generation Failed");
        }
    }, [data?.data]);

    const { mutate: upload } = useMutation(uploadCreditBureauReport, {
        onSuccess: (data) => {
            setRequestID(data?.requestID);
        },

        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message ?? "Something went wrong");
            if (error?.response?.data?.message === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });

    return handleUploadFile;
};

export default useCreditBureauReport;
