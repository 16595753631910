import { Fragment } from "react";
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";

const EntityForm = ({ prefix, setCurrentInput, pane }: { prefix: string; setCurrentInput: any; pane?: string }) => {
    const entity = useWatch({ name: `${prefix}.entity` });

    const applicantEntity = useWatch({ name: `applicant.entity` });

    const business = useWatch({ name: `applicant.entity.business` });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {business !== "Sole Proprietor" && business !== "Individual" && (
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Proof of Business Address"
                    prop={{
                        value: "Rent agreement / Sale deed / Utility bill",
                    }}
                    data-testid={generateTestId(`Proof of Business Address-${pane}`, "field")}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    name={`${prefix}.entity.businessAddressProof`}
                    handleChange={useUploadFile({
                        name: "businessAddressProof",
                        fileAddress: `${prefix}.entity.businessAddressProof`,
                    })}
                    value={entity?.businessAddressProof}
                    showCross={
                        localStorage.getItem("disable_form") !== "true" && entity?.businessAddressProof && entity?.businessAddressProof?.link !== ""
                    }
                    handleFileModalOpen={({ position }) =>
                        setCurrentInput({ name: `${prefix}.entity.businessAddressProof`, open: true, multiple: false, position: position })
                    }
                />
            )}

            {business === "Registered Under Company Act" && (
                <Fragment>
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="MoA and AoA"
                        data-testid={generateTestId(`MoA and AoA-${pane}`, "field")}
                        labelSubtitle="Memorandum and Articles of association"
                        value={entity?.memorandumAndAritclesOfAssociation}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.memorandumAndAritclesOfAssociation`}
                        prop={{
                            value: "Upload as a single or separate files",
                        }}
                        handleChange={useUploadFile({
                            name: "memorandumAndAritclesOfAssociation",
                            fileAddress: `${prefix}.entity.memorandumAndAritclesOfAssociation`,
                        })}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.memorandumAndAritclesOfAssociation &&
                            entity?.memorandumAndAritclesOfAssociation?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({
                                name: `${prefix}.entity.memorandumAndAritclesOfAssociation`,
                                open: true,
                                multiple: true,
                                position: position,
                            })
                        }
                    />
                </Fragment>
            )}
            {(business === "Sole Proprietor" || business === "Individual") && (
                <Fragment>
                    {applicantEntity?.businessAddress?.isSameAsRegisteredAddress === false && (
                        <CommonFields
                            inputType={INPUT_TYPE.FILE}
                            label="Proof of Business Address"
                            data-testid={generateTestId(`Proof of Business Address-${pane}`, "field")}
                            prop={{
                                value: "Rent agreement / Sale deed / Utility bill",
                            }}
                            readOnly={localStorage.getItem("disable_form") === "true"}
                            name={`${prefix}.entity.businessAddressProof`}
                            handleChange={useUploadFile({
                                name: "businessAddressProof",
                                fileAddress: `${prefix}.entity.businessAddressProof`,
                            })}
                            value={entity?.businessAddressProof}
                            showCross={
                                localStorage.getItem("disable_form") !== "true" &&
                                entity?.businessAddressProof &&
                                entity?.businessAddressProof?.link !== ""
                            }
                            handleFileModalOpen={({ position }) =>
                                setCurrentInput({ name: `${prefix}.entity.businessAddressProof`, open: true, multiple: false, position: position })
                            }
                        />
                    )}
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Udyog Aadhaar Certificate"
                        data-testid={generateTestId(`Udyog Aadhaar Certificate-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.udyogAadharCertificate`}
                        handleChange={useUploadFile({
                            name: "udyogAadharCertificate",
                            fileAddress: `${prefix}.entity.udyogAadharCertificate`,
                        })}
                        value={entity?.udyogAadharCertificate}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.udyogAadharCertificate &&
                            entity?.udyogAadharCertificate?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.udyogAadharCertificate`, open: true, multiple: false, position: position })
                        }
                    />
                </Fragment>
            )}
            {business === "Partnership" && (
                <Fragment>
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Partnership Deed"
                        data-testid={generateTestId(`Partnership Deed-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.partnershipDeed`}
                        handleChange={useUploadFile({
                            name: "partnershipDeed",
                            fileAddress: `${prefix}.entity.partnershipDeed`,
                        })}
                        value={entity?.partnershipDeed}
                        showCross={localStorage.getItem("disable_form") !== "true" && entity?.partnershipDeed && entity?.partnershipDeed?.link !== ""}
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.partnershipDeed`, open: true, multiple: false, position: position })
                        }
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Partnership Registration Certificate"
                        data-testid={generateTestId(`Partnership Registration Certificate-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.partnershipRegistrationCertificate`}
                        handleChange={useUploadFile({
                            name: "partnershipRegistrationCertificate",
                            fileAddress: `${prefix}.entity.partnershipRegistrationCertificate`,
                        })}
                        value={entity?.partnershipRegistrationCertificate}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.partnershipRegistrationCertificate &&
                            entity?.partnershipRegistrationCertificate?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({
                                name: `${prefix}.entity.partnershipRegistrationCertificate`,
                                open: true,
                                multiple: false,
                                position: position,
                            })
                        }
                    />
                </Fragment>
            )}
            {business === "other" && applicantEntity?.otherBusiness === "Association" && (
                <Fragment>
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Agreement of Association of Persons"
                        data-testid={generateTestId(`Agreement of Association of Persons-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.aggrementOfAssociationOfPersons`}
                        handleChange={useUploadFile({
                            name: "aggrementOfAssociationOfPersons",
                            fileAddress: `${prefix}.entity.aggrementOfAssociationOfPersons`,
                        })}
                        value={entity?.aggrementOfAssociationOfPersons}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.aggrementOfAssociationOfPersons &&
                            entity?.aggrementOfAssociationOfPersons?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({
                                name: `${prefix}.entity.aggrementOfAssociationOfPersons`,
                                open: true,
                                multiple: false,
                                position: position,
                            })
                        }
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Object of Association"
                        data-testid={generateTestId(`Object of Association-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.objectOfAssociation`}
                        handleChange={useUploadFile({
                            name: "objectOfAssociation",
                            fileAddress: `${prefix}.entity.objectOfAssociation`,
                        })}
                        value={entity?.objectOfAssociation}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" && entity?.objectOfAssociation && entity?.objectOfAssociation?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.objectOfAssociation`, open: true, multiple: false, position: position })
                        }
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Managing Committee Powers"
                        data-testid={generateTestId(`Managing Committee Powers-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.managingCommitteePowers`}
                        handleChange={useUploadFile({
                            name: "managingCommitteePowers",
                            fileAddress: `${prefix}.entity.managingCommitteePowers`,
                        })}
                        value={entity?.managingCommitteePowers}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.managingCommitteePowers &&
                            entity?.managingCommitteePowers?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.managingCommitteePowers`, open: true, multiple: false, position: position })
                        }
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Certificate of Registration"
                        data-testid={generateTestId(`Certificate of Registration-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.certificateOfRegistration`}
                        handleChange={useUploadFile({
                            name: "certificateOfRegistration",
                            fileAddress: `${prefix}.entity.certificateOfRegistration`,
                        })}
                        value={entity?.certificateOfRegistration}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.certificateOfRegistration &&
                            entity?.certificateOfRegistration?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.certificateOfRegistration`, open: true, multiple: false, position: position })
                        }
                    />
                </Fragment>
            )}
            {business === "other" && applicantEntity?.otherBusiness === "Society" && (
                <Fragment>
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Memorandum of Association"
                        data-testid={generateTestId(`Memorandum of Association-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.memorandumOfAssociation`}
                        handleChange={useUploadFile({
                            name: "memorandumOfAssociation",
                            fileAddress: `${prefix}.entity.memorandumOfAssociation`,
                        })}
                        value={entity?.memorandumOfAssociation}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" &&
                            entity?.memorandumOfAssociation &&
                            entity?.memorandumOfAssociation?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.memorandumOfAssociation`, open: true, multiple: false, position: position })
                        }
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Rules and Regulations"
                        data-testid={generateTestId(`Rules and Regulations-${pane}`, "field")}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        name={`${prefix}.entity.rulesAndRegulations`}
                        handleChange={useUploadFile({
                            name: "rulesAndRegulations",
                            fileAddress: `${prefix}.entity.rulesAndRegulations`,
                        })}
                        value={entity?.rulesAndRegulations}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" && entity?.rulesAndRegulations && entity?.rulesAndRegulations?.link !== ""
                        }
                        handleFileModalOpen={({ position }) =>
                            setCurrentInput({ name: `${prefix}.entity.rulesAndRegulations`, open: true, multiple: false, position: position })
                        }
                    />
                </Fragment>
            )}
            {applicantEntity?.gst !== "" && (
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="GST registration certificate"
                    data-testid={generateTestId(`GST registration certificate-${pane}`, "field")}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    name={`${prefix}.entity.gstRegistrationCertificate`}
                    handleChange={useUploadFile({
                        name: "gstRegistrationCertificate",
                        fileAddress: `${prefix}.entity.gstRegistrationCertificate`,
                    })}
                    value={entity?.gstRegistrationCertificate}
                    showCross={
                        localStorage.getItem("disable_form") !== "true" &&
                        entity?.gstRegistrationCertificate &&
                        entity?.gstRegistrationCertificate?.link !== ""
                    }
                    handleFileModalOpen={({ position }) =>
                        setCurrentInput({ name: `${prefix}.entity.gstRegistrationCertificate`, open: true, multiple: false, position: position })
                    }
                />
            )}
        </Grid>
    );
};

export default EntityForm;
