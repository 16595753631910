import React, { memo, Fragment, useRef } from "react";
import { useFormContext, RegisterOptions, useWatch } from "react-hook-form";
import { MenuItem, NativeSelect, Select, TextField } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { IOption } from "types";
import { combineClassNames, convertSnakeCaseToHeaderCase } from "utils";
import maskField from "utils/maskField";

type Props = {
    label?: string;
    defaultValue?: string;
    options?: IOption[];
    subtitle?: string;
    showLabel?: boolean;
    showMask?: boolean;
    className: string;
    readOnly?: boolean;
    labelSubtitle?: string;
    name: string;
    reactHookFormRegisterOptions: RegisterOptions;
    otherOptionName?: string;
    ignoreOthers: boolean;
    otherOptionProps: {
        name?: string;
        subtitle?: string;
        label?: string;
        reactHookFormRegisterOptions?: RegisterOptions;
        className?: string;
        placeholder?: string;
    };
    multiple?: boolean;
    "data-testid"?: string;
    value?: string | number;
    disableReactHookForm: boolean;
};

const CommonSelectField = ({
    label,
    options,
    subtitle,
    readOnly,
    showLabel = true,
    className,
    labelSubtitle,
    reactHookFormRegisterOptions,
    name,
    otherOptionProps,
    ignoreOthers,
    multiple = false,
    "data-testid": dataTestId,
    showMask,
    value,
    disableReactHookForm,
}: Props) => {
    const { register, getValues } = useFormContext();

    const fieldValue = useWatch({
        name,
    });

    const registerObject = disableReactHookForm
        ? { value, onChange: reactHookFormRegisterOptions.onChange }
        : register(name, reactHookFormRegisterOptions);

    // split at last "." and add other to the last field while making it into camelCase
    // e.g. "address.country" => "address.otherCountry"

    const otherOptionField =
        otherOptionProps.name ||
        name
            .split(".")
            .slice(0, -1)
            .concat(
                "other" +
                    name
                        .split(".")
                        .slice(-1)
                        .map((word) => word[0].toUpperCase() + word.slice(1))
            )
            .join(".");

    let otherDataTestId = `other-${dataTestId}`;

    return (
        <Fragment>
            <Grid container className="common__mui__textfield__container" justifyContent="center" alignItems="center">
                <Grid item xs={showLabel ? 6 : 12}>
                    <Typography className="common-label" data-testid={`${dataTestId}-label`}>
                        {label}
                        {labelSubtitle ? (
                            <FormHelperText>
                                <span>{labelSubtitle}</span>
                            </FormHelperText>
                        ) : (
                            ""
                        )}
                    </Typography>
                </Grid>
                <Grid item xs={showLabel ? 6 : 12}>
                    <FormControl
                        className={`common__mui__selectfield ${
                            (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true ? "error" : ""
                        } `}
                        fullWidth
                    >
                        <select
                            multiple={multiple}
                            {...registerObject}
                            disabled={readOnly}
                            className={className + ` ${multiple ? "multiple" : ""} `}
                            data-testid={dataTestId}
                        >
                            {options?.map(({ label, value }, index) => {
                                return (
                                    <option key={index} value={value}>
                                        {maskField(label, showMask && convertSnakeCaseToHeaderCase(fieldValue) === label)}
                                    </option>
                                );
                            })}
                        </select>
                        <FormHelperText className="common__helper-text">{subtitle}</FormHelperText>
                        <FormHelperText error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}>
                            {(getValues(name) === "" || getValues(name) === undefined) &&
                                getValues("showIncompleteField") == true &&
                                "This field is required"}
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
            {fieldValue === OTHER && !ignoreOthers && (
                <CommonFields
                    inputType={INPUT_TYPE.TEXT}
                    multiLine={showMask ? false : true}
                    readOnly={readOnly}
                    subtitle={otherOptionProps.subtitle || ""}
                    reactHookFormRegisterOptions={otherOptionProps.reactHookFormRegisterOptions || {}}
                    label={otherOptionProps.label || "Other"}
                    name={otherOptionField}
                    className={`${otherOptionProps.className} ${name.includes("prefix") ? "short-input-field" : ""}` || ""}
                    placeholder={otherOptionProps.placeholder || ""}
                    data-testid={otherDataTestId}
                    showMask={showMask ? true : false}
                />
            )}
        </Fragment>
    );
};

export default memo(CommonSelectField);
