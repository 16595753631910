const getCreditBureauLoanAccounts = (scrapedData: any) => {
    const accounts = scrapedData?.accounts;
    const activeAccounts = accounts?.filter(
        (account: any) => !["Closed Account", "CLOSED"].includes(account.accountStatus) && account?.outstandingLoanAmount > 0
    );

    const newLoanAccounts = [...activeAccounts];
    newLoanAccounts.forEach((account: any) => {
        account.reportCreated = scrapedData?.reportCreated ?? "";
        if (account.paymentHistory) delete account.paymentHistory;
        if (account?.accountType !== "") {
            const accountType = account?.accountType.toLowerCase();
            if (accountType.includes("credit card")) account.sanctionAmount = account.highestCredit;
        }
        if (account?.outstandingLoanAmount > 0) {
            const { emiAmount, remainingTenorInMonths, originalLoanTenorInMonths } = account;
            if (emiAmount < 0) account.emiAmount = 0;
            if (originalLoanTenorInMonths < 0) account.originalLoanTenorInMonths = 0;
            if (remainingTenorInMonths < 0) account.remainingTenorInMonths = 0;
        }
    });

    return newLoanAccounts;
};

export default getCreditBureauLoanAccounts;
