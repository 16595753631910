import { Fragment, memo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { HandleNextDialog } from "components/Dialog";
import TabsWrapper from "components/TabsWrapper";
import { useSaveForm } from "hooks";
import { useGuarantorFields, useCoborrowerFields } from "pages/Dashboard/hooks";
import { EntityForm } from "./components";

const Entity = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const coborrowerFields = useCoborrowerFields();
    const guarantorFields = useGuarantorFields();

    const { setValue } = useFormContext();
    const errors = useWatch({
        name: "errors",
    });
    const hasCoBorrower = useWatch({
        name: "requirement.hasCoBorrower",
    });
    const hasGuarantor = useWatch({
        name: "requirement.hasGuarantor",
    });

    const [open, setOpen] = useState(false);

    const handleSave = useSaveForm();

    const handleProceed = () => {
        setCurrentStep(2);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const handleNext = () => {
        let shouldProceed = true;
        for (let header of ["applicant", "guarantor", "coborrower"]) {
            if (header === "applicant") {
                if (errors[header]?.creditBureau?.errorsCounts > 0) {
                    shouldProceed = false;
                }
                const headerErrors = errors[header]?.entity;
                if (headerErrors?.errorsCounts > 0) shouldProceed = false;
                for (let i in headerErrors) {
                    if (headerErrors[i]?.errorsCounts > 0) {
                        shouldProceed = false;
                    }
                }
            } else if (hasCoBorrower === true || hasGuarantor === true) {
                errors[header]?.forEach((error: any) => {
                    if (error?.creditBureau?.errorsCounts > 0) {
                        shouldProceed = false;
                    }
                    const headerErrors = error?.entity;
                    if (headerErrors?.errorsCounts > 0) shouldProceed = false;
                    for (let i in headerErrors) {
                        if (headerErrors[i]?.errorsCounts > 0) {
                            shouldProceed = false;
                        }
                    }
                });
            }
        }
        if (shouldProceed) handleProceed();
        else {
            setValue("showIncompleteField", true);
            setOpen(true);
        }
    };

    return (
        <Fragment>
            <Grid container id="form">
                <TabsWrapper
                    pane="entity"
                    titles={[
                        "Applicant",
                        // coborrower titles
                        ...coborrowerFields.map((field, index) => {
                            if (coborrowerFields.length === 1) return `Co-borrower`;

                            return `Co-borrower ${index + 1}`;
                        }),
                        // ...guarantorTitles,
                        ...guarantorFields.map((field, index) => {
                            if (guarantorFields.length === 1) return `Guarantor`;

                            return `Guarantor ${index + 1}`;
                        }),
                    ]}
                    bodies={[
                        <EntityForm index={0} header="applicant" title="Applicant" />,
                        // coborrower bodies
                        ...coborrowerFields.map((field, index) => (
                            <EntityForm
                                index={index}
                                header="coborrower"
                                key={field.id}
                                title={coborrowerFields.length === 1 ? `Co-borrower` : `Co-borrower ${index + 1}`}
                            />
                        )),
                        // guarantor bodies
                        ...guarantorFields.map((field, index) => (
                            <EntityForm
                                index={index}
                                header="guarantor"
                                key={field.id}
                                title={guarantorFields.length === 1 ? `Guarantor` : `Guarantor ${index + 1}`}
                            />
                        )),
                    ]}
                />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(0)} data-testid="back-button">
                    <KeyboardArrowLeftIcon id="left-arrow" />
                    Back
                </Button>
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={handleSave}
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
                <Button variant="contained" className="btn-arrow" onClick={handleNext} data-testid="next-button">
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};
//

export default memo(Entity, () => true);
