import { useLayoutEffect, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import OtpInput from "react-otp-input";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { useAadharVerify, usePanVerify } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

interface Props {
    prefix?: string;
    pane?: string;
}

const KYC = ({ prefix, pane }: Props) => {
    const { handleOtpSubmit, open, handleClose, aadharVerified, handleAadharValidate, setAadhar, aadharOtp, setAadharOtp, aadharDetails } =
        useAadharVerify();
    const { setPan, panVerified, handlePanValidate } = usePanVerify();

    const { setValue, getValues } = useFormContext();

    const kyc = useWatch({
        name: `${prefix}.kyc`,
    });
    const errors = useWatch({
        name: `errors.${prefix}.kyc`,
    });
    const aadhar = useWatch({
        name: `${prefix}.kyc.aadhar`,
    });
    const pan = useWatch({
        name: `${prefix}.kyc.pan`,
    });
    const panValidated = useWatch({
        name: `${prefix}.kyc.panValidated`,
    });
    const aadharValidated = useWatch({
        name: `${prefix}.kyc.aadharValidated`,
    });
    const entityPrefix = prefix?.split(".")?.[0] === "applicant" ? "applicant" : prefix?.split(".")?.slice(0, 2).join(".");
    const business = useWatch({
        name: `${entityPrefix}.entity.business`,
    });

    useEffect(() => {
        if (aadharVerified) {
            setValue(`${prefix}.kyc.aadharValidated`, aadharVerified);
        }
        if (panVerified) {
            setValue(`${prefix}.kyc.panValidated`, panVerified);
        }
    }, [aadharVerified, panVerified]);

    useEffect(() => {
        if (aadharDetails !== null) {
            const { full_name, care_of, dob, gender, address, zip } = aadharDetails;

            const yourDetails = {
                // @ts-ignore
                firstName: full_name.split(" ")[0],
                // @ts-ignore
                lastName: full_name.split(" ")[1],
                dateOfBirth: new Date(dob).toLocaleDateString(),
                prefix: "Mr.",
                otherPrefix: "",
                middleName: "",
                maritalStatus: "Single",
                noOfDependents: 0,
                // if maritalStatus married
                noOfChildren: 0,
            };
            const residentialAddress = {
                // @ts-ignore
                address1: address.house,
                // @ts-ignore
                address2: address.loc,
                address3: "",
                landmark: "",
                // @ts-ignore
                postalCode: zip,
                // @ts-ignore
                city: address.vtc,
                // @ts-ignore
                state: address.state,
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                // in case of rentType === "Monthly Rent PM"
                monthlyRent: 0,
                // in case of rentType === "Owner Name"
                ownerDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
            };
            setValue(`${prefix}.kyc.aadharValidated`, true);
            setValue(`${prefix}.yourDetails`, yourDetails);
            setValue(`${prefix}.residentialAddress`, residentialAddress);
        }
    }, [aadharDetails]);

    useLayoutEffect(() => {
        if (aadhar) {
            setAadhar(aadhar);
        }
        if (pan) {
            setPan(pan);
        }
    }, [pan, aadhar]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 2,
        borderRadius: "10px",
    };

    useEffect(() => {
        if (aadharValidated) {
            setValue(`${prefix}.kyc.aadharValidated`, true);
        }
        if (panValidated) {
            setValue(`${prefix}.kyc.panValidated`, true);
        }
    }, [aadharValidated, panValidated]);

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.kyc.${item}`) === "" || getValues(`${prefix}.kyc.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.kyc.errorsCounts`, errorsCounts);
    }, [kyc]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="KYC"
            tab="personal"
            keys={`${prefix}.kyc`}
            data-testid={generateTestId(`kyc-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" data-testid="personal__background">
                {fixture.map(({ name, inputType, subtitle, label, options }, index) => {
                    if (business !== "Partnership" && business !== "Registered Under Company Act" && name === "din") return "";
                    activeFields.push(name);
                    return (
                        <CommonFields
                            key={index}
                            inputType={inputType}
                            name={`${prefix}.kyc.${name}`}
                            subtitle={subtitle}
                            data-testid={generateTestId(`${label}-${pane}`, "field")}
                            options={
                                options?.map((value) => ({
                                    value,
                                    label: convertSnakeCaseToHeaderCase(value),
                                })) || []
                            }
                            readOnly={localStorage.getItem("disable_form") === "true"}
                            label={label}
                            {...(name === "aadhar" && { onlyNumbers: true, isValidate: true })}
                            {...(name === "aadhar" && aadharValidated
                                ? { verified: true }
                                : name === "pan" && panValidated
                                ? { verified: true }
                                : {})}
                            {...(name === "aadhar" && !aadharValidated
                                ? { handleValidate: handleAadharValidate }
                                : name === "pan" && !panValidated
                                ? { handleValidate: handlePanValidate }
                                : {})}
                            {...(name === "pan"
                                ? {
                                      isValidate: true,
                                      reactHookFormRegisterOptions: {
                                          setValueAs(value) {
                                              return value.toUpperCase();
                                          },
                                          value: pan,
                                      },
                                  }
                                : {})}
                        />
                    );
                })}
                <Modal hideBackdrop open={open} onClose={handleClose}>
                    <Box sx={{ ...style, width: 200 }}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} container justifyContent={"flex-end"} alignItems="center">
                                <CloseOutlinedIcon onClick={handleClose} className="close-icon" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="otp-text">Enter the OTP sent your registered phone linked with Aadhar Number</Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <OtpInput
                                    value={aadharOtp}
                                    className="otp-input"
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    onChange={(e: any) => {
                                        setAadharOtp(e);
                                    }}
                                    numInputs={6}
                                    separator={<span>-</span>}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Button variant="contained" className="otp-submit__btn" onClick={handleOtpSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
        </CommonAccordion>
    );
};

export default KYC;
