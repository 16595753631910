import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import DollerImage from "assets/images/dollar.png";
import ShadowSmallImage from "assets/images/shadow_small.jpg";
import Layer41Image from "assets/images/layer-41.png";
import OutlineImage from "assets/images/outline.png";
import "./LoanSteps.styles.scss";

export interface Props {}

const LoanSteps = () => {
    return (
        <Grid container id="loan-steps__container" direction="column" justifyContent="center" alignItems="center">
            <Box component="div" className="centered-div home-btn">
                <Button>Loan in 3 Easy Steps</Button>
            </Box>
            <Container>
                <Grid container alignItems="center" justifyContent="center">
                    <Grid item xs={12} sm={3}>
                        <Grid className="specializations" container direction="column" justifyContent="center" alignItems="center">
                            <img style={{ background: "#dcbef2" }} src={DollerImage} alt="" />
                            <img src={ShadowSmallImage} alt="" />
                            <Typography variant="h5">
                                Give Your <span>Information</span>{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Grid className="specializations" container direction="column" justifyContent="center" alignItems="center">
                            <img style={{ background: "#92dbf4" }} src={Layer41Image} alt="" />
                            <img src={ShadowSmallImage} alt="" />
                            <Typography variant="h5">
                                Get Your <span>Financier</span>{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Grid className="specializations" container direction="column" justifyContent="center" alignItems="center">
                            <img style={{ background: "#f8beaa" }} src={OutlineImage} alt="" />
                            <img src={ShadowSmallImage} alt="" />
                            <Typography variant="h5">
                                Get Your <span>Funds</span>{" "}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            <Box component="div" className="centered-div demo-btn">
                <Button variant="contained">See The Demo</Button>
            </Box>
        </Grid>
    );
};

export default LoanSteps;
