import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

const SessionExpiredDialog = ({
    handleClose,
    open,
    handleContinue,
    handleLogout,
}: {
    handleClose: () => void;
    open: boolean;
    handleContinue: () => void;
    handleLogout: () => void;
}) => {
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent className="max-w-md">
                <Typography className="w-full text-center font-medium mb-6" gutterBottom>
                    Session Expired. Do you want to continue?
                </Typography>
                <div className="flex items-center justify-between px-8">
                    <Button
                        onClick={() => {
                            handleContinue();
                        }}
                    >
                        Continue Session
                    </Button>
                    <Button
                        onClick={() => {
                            handleLogout();
                        }}
                    >
                        Logout
                    </Button>
                </div>
            </DialogContent>
        </Dialog>
    );
};

export default SessionExpiredDialog;
