import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { defaultWorkOrderFixture } from "fixtures";
import { useCompanyNames } from "hooks";
import AddAnotherWorkOrder from "./AddAnotherWorkOrder";
import DeleteWorkOrder from "./DeleteWorkOrder";
import WorkOrderItem from "./WorkOrderItem";

const WorkOrder = ({
    workOrderPrefixInReactForm,
    pane,
    setCurrentInput,
}: {
    workOrderPrefixInReactForm: string;
    setCurrentInput: any;
    pane?: string;
}) => {
    const { fields, append, remove } = useFieldArray({
        name: `${workOrderPrefixInReactForm}`,
    });
    const errors = useWatch({
        name: `errors.${workOrderPrefixInReactForm}`,
    });

    const { setValue } = useFormContext();

    const appendNewWorkOrder = (index: number) => {
        if (index == fields.length - 1) append({ ...defaultWorkOrderFixture, id: crypto.randomUUID() });
    };

    const handleDeleteWorkOrder = (index: number) => {
        remove(index);
        const incomeErrors = errors?.filter((_: any, i: number) => i !== index);
        setValue(`errors.${workOrderPrefixInReactForm}`, incomeErrors);
    };

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            {fields.map((field, index: number) => {
                return (
                    <div key={field.id} className="workOrder__container relative" style={{ width: "inherit" }}>
                        <WorkOrderItem
                            index={index}
                            appendNewWorkOrder={appendNewWorkOrder}
                            prefix={workOrderPrefixInReactForm}
                            pane={pane}
                            setCurrentInput={setCurrentInput}
                        />
                        <DeleteWorkOrder index={index} handleDeleteWorkOrder={handleDeleteWorkOrder} />
                    </div>
                );
            })}
            <AddAnotherWorkOrder append={append} pane={pane} />
        </Grid>
    );
};

export default WorkOrder;
