import { useLayoutEffect } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { usePrevious } from "hooks";

/*
 * This hook is used to automatically add and remove fields from a field array
 * based on the value of a count field.
 *
 * props
 *  name: string => name of the field array
 * countName: string => name of the count field
 *
 * Example:
 *   const { append, remove, fields } = useFieldArrayWithAutoAddAndRemove({
 *      name: `coborrower`,
 *     countName: `requirement.numberOfCoborrower`
 *  });
 *
 */
interface Props {
    name: string;
    countName: string;
    fixture: any;
}

const useFieldArrayWithAutoAddAndRemove = ({ name, countName, fixture }: Props) => {
    const { append, remove, fields, ...rest } = useFieldArray({
        name: name,
    });

    const count = +(useWatch({ name: countName }) || 0);
    const previousCount = fields.length;

    useLayoutEffect(() => {
        if (!count || count == previousCount) return;

        // if count is less than previousCount
        // then remove the extra fields
        if (previousCount > count) {
            const removedIndices = [];

            for (let i = previousCount; i > count; i--) {
                removedIndices.push(i - 1);
            }

            remove(removedIndices);
        }

        // if count is greater than previousCount
        // then append the extra fields
        if (previousCount < count) {
            const appendObjects = [];

            for (let i = previousCount; i < count; i++) {
                appendObjects.push(fixture);
            }

            append(appendObjects);
        }
    }, [count, previousCount]);

    return {
        append,
        remove,
        fields,
        ...rest,
    };
};

export default useFieldArrayWithAutoAddAndRemove;
