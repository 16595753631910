import { useSignOut } from "react-auth-kit";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "globalConstants";

const AUTH_ERRORS = ["Your access token has expired. Please login again", "No access token found in request", "Your access token is invalid"];

const useError = () => {
    const signOut = useSignOut();
    const navigate = useNavigate();

    const showError = (error: any) => {
        toast.dismiss();
        toast.error(error?.stack?.message || error?.response?.data?.message || "Something went wrong");
        if (AUTH_ERRORS.includes(error?.stack?.message) || AUTH_ERRORS.includes(error?.response?.data?.message)) {
            signOut();
            localStorage.clear();
            navigate(ROUTES.LOGIN);
        }
    };

    return showError;
};

export default useError;
