import { Fragment, useEffect } from "react";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { useCompanyNames } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import "./MachineUsage.styles.scss";
import fixture from "./fixture";

const MachineUsage = () => {
    const machineUsage = useWatch({ name: "requirement.machineUsage" });
    const errors = useWatch({ name: "errors.requirement.machineUsage" });

    const { companyNames, isCompaniesLoading, setCompanyName } = useCompanyNames();

    const { setValue, getValues, register } = useFormContext();

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`requirement.machineUsage.${item}`) === "" || getValues(`requirement.machineUsage.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.requirement.machineUsage.errorsCounts`, errorsCounts);

        if (machineUsage?.customerType === "Company") setCompanyName(machineUsage?.customer);
    }, [machineUsage]);

    return (
        <Grid container id="machine-usage__container" direction="column" justifyContent="center" alignItems="center" data-testid="machine-usage">
            <CommonAccordion
                showError={errors?.errorsCounts > 0}
                title={`Machine Usage`}
                data-testid={generateTestId(`Machine Usage`, "accordion")}
                tab="requirement"
            >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(
                        (
                            {
                                name,
                                inputType,
                                defaultValue,
                                options,
                                reactHookFormRegisterOptions,
                                extendedOptions,
                                subtitle,
                                editable,
                                label,
                                multiple,
                                clarificatoryText,
                                dataTestId,
                                showMask,
                            },
                            index
                        ) => {
                            let subSectors = [
                                "infrastructure",
                                "otherInfrastructure",
                                "civilWork",
                                "otherCivilWork",
                                "farming",
                                "otherFarming",
                                "materialHandling",
                                "otherMaterialHandling",
                                "mines",
                                "otherMines",
                                "ports",
                                "otherPorts",
                                "quarry",
                                "otherQuarry",
                                "otherSubSector",
                            ];
                            if (name === "endUse") {
                                if (machineUsage.isFirstTimeUser === true) {
                                    options = extendedOptions!.isFirstTimeUser.options;
                                } else {
                                    options = extendedOptions!.isNotFirstTimeUser.options;
                                }
                            }

                            if (machineUsage.isFirstTimeUser !== true) {
                                if (machineUsage.endUse === "Hirer") {
                                    if (name === "rentingRate" || name === "rentingRateUnit") return "";
                                }
                                if (machineUsage.endUse === "Own Use") {
                                    if (name === "hiringRate" || name === "hiringRateUnit" || name === "isWorkContract") return "";
                                }
                            }
                            if (machineUsage.isFirstTimeUser == true) {
                                if (name === "isFirstTimeBuyer") return "";
                                if (name === "hiringRate" || name === "hiringRateUnit" || name === "isWorkContract") return "";
                            }

                            if (machineUsage.endUse === "Hirer" && machineUsage?.hiringRateUnit !== "Per Hour") {
                                if (name === "monthlyHours") {
                                    return "";
                                }
                            }

                            if (machineUsage.endUse === "Own Use" && machineUsage?.rentingRateUnit !== "Per Hour") {
                                if (name === "monthlyHours") {
                                    return "";
                                }
                            }

                            if (!machineUsage.revenueSourcing?.includes(OTHER)) {
                                if (name === "otherRevenueSourcing") {
                                    return "";
                                }
                            }
                            // if (
                            //     machineUsage.isFirstTimeBuyer === false &&
                            //     machineUsage.isFirstTimeUser === false &&
                            //     machineUsage.endUse === "Hirer"
                            // ) {
                            //     if (name === "businessActivity" || name === "rentingRate" || name === "rentingRateUnit") return "";
                            // }
                            if (machineUsage.isFirstTimeUser === true) {
                                defaultValue = "Hirer";
                            }
                            if (machineUsage.isFirstTimeUser === false) {
                                defaultValue = "Own Use";
                            }
                            if (!machineUsage.businessActivity?.includes("Infrastructure")) {
                                if (name === "infrastructure" || name === "otherInfrastructure") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Civil Work")) {
                                if (name === "civilWork" || name === "otherCivilWork") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Farming")) {
                                if (name === "farming" || name === "otherFarming") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Material Handling")) {
                                if (name === "materialHandling" || name === "otherMaterialHandling") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Mines")) {
                                if (name === "mines" || name === "otherMines") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Ports")) {
                                if (name === "ports" || name === "otherPorts") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes("Quarry")) {
                                if (name === "quarry" || name === "otherQuarry") {
                                    return "";
                                }
                            }
                            if (!machineUsage.businessActivity?.includes(OTHER)) {
                                if (name === "otherSubSector") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.infrastructure?.includes(OTHER)) {
                                if (name === "otherInfrastructure") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.civilWork?.includes(OTHER)) {
                                if (name === "otherCivilWork") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.farming?.includes(OTHER)) {
                                if (name === "otherFarming") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.materialHandling?.includes(OTHER)) {
                                if (name === "otherMaterialHandling") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.mines?.includes(OTHER)) {
                                if (name === "otherMines") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.ports?.includes(OTHER)) {
                                if (name === "otherPorts") {
                                    return "";
                                }
                            }
                            if (!machineUsage.subSectors.quarry?.includes(OTHER)) {
                                if (name === "otherQuarry") {
                                    return "";
                                }
                            }

                            if (subSectors?.includes(name)) {
                                name = `subSectors.${name}`;
                            }

                            if (name === "customer" && machineUsage?.customerType === "Company") {
                                activeFields.push(name);
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                                            <Grid item xs={6}>
                                                <Typography className="common-label" data-testid={generateTestId(`${label}`, "field-label")}>
                                                    {label}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    freeSolo
                                                    disablePortal
                                                    disabled={localStorage.getItem("disable_form") === "true"}
                                                    className="common__mui__textfield"
                                                    options={companyNames}
                                                    value={machineUsage?.customer}
                                                    data-testid={generateTestId(`${label}`, "field")}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            disabled={localStorage.getItem("disable_form") === "true" || isCompaniesLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: isCompaniesLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null,
                                                            }}
                                                            className="autocomplete-input"
                                                            //@ts-ignore
                                                            {...register(`requirement.machineUsage.${name}`)}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                );
                            }

                            activeFields.push(name);
                            return (
                                <Fragment key={index}>
                                    <CommonFields
                                        inputType={inputType}
                                        name={`requirement.machineUsage.${name}`}
                                        showMask={showMask}
                                        options={
                                            options?.includes("yes")
                                                ? options?.map((item) => ({ label: convertSnakeCaseToHeaderCase(item), value: item === "yes" })) ?? []
                                                : options?.map((value: string) => ({
                                                      value,
                                                      label: convertSnakeCaseToHeaderCase(value),
                                                  })) || []
                                        }
                                        label={label}
                                        reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                        prop={{ value: clarificatoryText || "" }}
                                        multiple={multiple}
                                        readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                        subtitle={subtitle}
                                        data-testid={dataTestId}
                                        ignoreOthers={true}
                                    />
                                </Fragment>
                            );
                        }
                    )}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default MachineUsage;
