import { INPUT_TYPE } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "perAddress",
        label: "",
        inputType: INPUT_TYPE.SELECT,
        options: ["same_as_residential", "different_permanent_address"],
        defaultValue: "same_as_residential",
        editable: true,
    },
    {
        name: "address1",
        label: "Address 1",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Building Number and Name",
    },
    {
        name: "address2",
        label: "Address 2",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Street Number and Street Name",
    },
    {
        name: "address3",
        label: "Address 3",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Locality",
    },
    {
        name: "landmark",
        label: "Landmark",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Near your Place",
    },
    {
        name: "postalCode",
        label: "Postal Code",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
    },
    {
        name: "city",
        label: "City",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "state",
        label: "State",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "stay",
        label: "Stay",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Residing at Address(Years)",
    },
    {
        name: "cityStay",
        label: "Length of Stay",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Residing at City(Years)",
    },
    {
        name: "ownership",
        label: "Ownership",
        inputType: INPUT_TYPE.SELECT,
        options: ["owned_by_yourself", "owned_by_parents", "rented"],
        defaultValue: "",
        editable: true,
        subtitle: "Owned or rented?",
    },
];

export default fixture;
