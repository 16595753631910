import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { useGetAllManufacturers, useFindAllDealers } from "__generated__/apiComponents";
import CommonAccordion from "components/CommonAccordion";
import CommonAutoComplete from "components/CommonAutoComplete";
import CommonFields from "components/CommonFields";
import { useError } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import getEntityDetails from "utils/getEntityDetails";
import getPersonalName from "utils/getPersonalName";
import fixture from "./fixture";

type Props = {};

const ContactDetails = (props: Props) => {
    const { setValue } = useFormContext();
    const contactDetails = useWatch({ name: "contact" });
    const entity = useWatch({ name: "applicant.entity" });
    const personal = useWatch({ name: "applicant.personal" });
    const showError = useError();

    const { data: manufacturers } = useGetAllManufacturers<{ manufacturer_name: string }[]>(
        {},
        {
            onError: showError,
            enabled: contactDetails?.userType !== "registered" && (contactDetails?.role === "manufacturer" || contactDetails?.role === "dealer"),
        }
    );
    const { data: dealers } = useFindAllDealers<{ name: string; address: any }[]>(
        { queryParams: {} },
        {
            onError: showError,
            enabled: contactDetails?.userType !== "registered" && (contactDetails?.role === "manufacturer" || contactDetails?.role === "dealer"),
        }
    );
    const manufacturersNames = manufacturers?.map((item, i) => item.manufacturer_name);
    const dealerNames = dealers?.map((item) => item.name) as any;

    const { personalField, personalFieldLabel } = getEntityDetails(entity);

    const role = contactDetails?.role;
    const names = contactDetails?.full_name?.split(",") ?? [];

    useEffect(() => {
        if (entity.business !== "Sole Proprietor" && entity.business !== "Individual") {
            const names = personal[personalField].map((personal: any) => getPersonalName(personal.yourDetails));
            setValue("contact.full_name", names.join(","));
        }
    }, [personal, personalField, entity.business]);

    useEffect(() => {
        if (role === "dealer" && contactDetails?.userType !== "registered") {
            const address = dealers?.find((dealer) => dealer.name === contactDetails?.businessName)?.address;
            setValue("contact.address", {
                address1: address?.address1,
                address2: address?.address2,
                address3: address?.address3,
                landmark: address?.landmark,
                postalCode: address?.postalCode,
                city: address?.city,
                state: address?.state,
            });
        }
    }, [contactDetails?.businessName, contactDetails?.userType, role]);

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CommonAccordion title={`Contact Details`} data-testid={generateTestId(`Contact Details`, "accordion")} tab="contact">
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(
                        (
                            { name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue, labelGenerator },
                            index
                        ) => {
                            if (name === "manufacturer") {
                                if (role === "dealer")
                                    return (
                                        <CommonAutoComplete
                                            key={name}
                                            label="Manufacturer"
                                            value={contactDetails.roleDetails?.manufacturer ?? []}
                                            onChange={(values) => {
                                                setValue("contact.roleDetails.manufacturer", values);
                                            }}
                                            suggestions={manufacturersNames ?? []}
                                            creatable
                                            multiple
                                            data-testid={generateTestId(label, "multiple-autocomplete-field")}
                                            readOnly={contactDetails?.userType === "registered"}
                                        />
                                    );
                                else return "";
                            }
                            if (name === "dealer") {
                                if (role === "manufacturer" && contactDetails?.userType !== "registered")
                                    return (
                                        <CommonAutoComplete
                                            key={name}
                                            label="Dealer"
                                            value={contactDetails.roleDetails?.dealer ?? []}
                                            onChange={(values) => {
                                                setValue("contact.roleDetails.dealer", values);
                                            }}
                                            suggestions={dealerNames ?? []}
                                            creatable
                                            multiple
                                            data-testid={generateTestId(label, "multiple-autocomplete-field")}
                                        />
                                    );
                                else return "";
                            }
                            if (
                                name === "businessName" &&
                                ["fi", "dealer", "manufacturer"].includes(role) &&
                                contactDetails?.userType !== "registered"
                            ) {
                                return (
                                    <CommonAutoComplete
                                        key={name}
                                        label={labelGenerator?.(contactDetails.role) || label}
                                        value={contactDetails.businessName}
                                        onChange={(value) => {
                                            setValue("contact.businessName", value);
                                        }}
                                        suggestions={role === "dealer" ? dealerNames : role === "manufacturer" ? manufacturersNames : options ?? []}
                                        creatable
                                        data-testid={generateTestId(label, "multiple-autocomplete-field")}
                                    />
                                );
                            }

                            if (name === "full_name" && role === "user") {
                                return names?.map((val: string, i: number) => (
                                    <CommonFields
                                        key={`${name}.${i}`}
                                        inputType={inputType}
                                        name={`contact.${name}`}
                                        value={val}
                                        disableReactHookForm={true}
                                        multiLine
                                        label={personalFieldLabel ? `${personalFieldLabel} ${i + 1}` : "Contact Name"}
                                        readOnly={true}
                                    />
                                ));
                            }

                            return (
                                <CommonFields
                                    key={index}
                                    inputType={inputType}
                                    name={`contact.${name}`}
                                    multiLine
                                    options={
                                        options?.map((value) => ({
                                            value,
                                            label: convertSnakeCaseToHeaderCase(value),
                                        })) || []
                                    }
                                    label={labelGenerator?.(role) || label}
                                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                    readOnly={
                                        localStorage.getItem("disable_form") === "true" ||
                                        !editable ||
                                        ((name === "businessName" || name === "full_name" || name === "email" || name === "mobile") &&
                                            role === "user") ||
                                        contactDetails?.userType === "registered"
                                    }
                                    subtitle={subtitle}
                                />
                            );
                        }
                    )}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default ContactDetails;
