import { INPUT_TYPE } from "globalConstants";
import { OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "prefix",
        label: "Prefix",
        inputType: INPUT_TYPE.SELECT,
        options: ["mr.", "mrs.", "ms.", "dr.", "prof.", OTHER],
        defaultValue: "mr.",
        editable: true,
        className: `short-input-field`,
    },
    {
        name: "firstName",
        label: "First Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        showMask: true,
    },
    {
        name: "middleName",
        label: "Middle Name",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "lastName",
        label: "Last Name",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "dateOfBirth",
        label: "Date of Birth",
        inputType: INPUT_TYPE.DATE,
        editable: true,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "age",
        label: "Age",
        inputType: INPUT_TYPE.NUMBER,
        editable: false,
    },
    {
        name: "maritalStatus",
        label: "Marital Status",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: ["single", "married", "divorced"],
        showMask: true,
    },
    {
        name: "noOfChildren",
        label: "No of Children",
        editable: true,
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        name: "noOfDependents",
        label: "No of Dependents",
        editable: true,
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        name: "f_prefix",
        className: `short-input-field`,
        label: "Prefix",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["mr.", "mrs.", "ms.", "dr.", "prof.", OTHER],
        defaultValue: "mr.",
    },
    {
        name: "f_firstName",
        label: "First Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "f_middleName",
        label: "Middle Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "f_lastName",
        label: "Last Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "m_prefix",
        label: "Prefix",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["mr.", "mrs.", "ms.", "dr.", "prof.", OTHER],
        defaultValue: "mr.",
        className: `short-input-field`,
    },
    {
        name: "m_firstName",
        label: "First Name",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "m_middleName",
        label: "Middle Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "m_lastName",
        label: "Last Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "s_prefix",
        className: `short-input-field`,
        label: "Prefix",
        inputType: INPUT_TYPE.SELECT,
        options: ["mr.", "mrs.", "ms.", "dr.", "prof.", OTHER],
        defaultValue: "mr.",
        editable: true,
    },
    {
        name: "s_firstName",
        label: "First Name",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "s_middleName",
        label: "Middle Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "s_lastName",
        label: "Last Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
];

export default fixture;
