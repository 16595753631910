import React, { useContext, useState } from "react";
import { useSignIn } from "react-auth-kit";
import toast from "react-hot-toast";
import { Link, useNavigate, useParams } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button, Grid, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useLogin } from "__generated__/apiComponents";
import Footer from "components/Footer";
import GoogleLoginButton from "components/GoogleLoginButton";
import Navbar from "components/Navbar";
import { ROUTES } from "globalConstants";
import ForgotPasswordPopup from "popups/ForgotPasswordPopup";
import "./Login.styles.scss";

const Login = () => {
    const [{ password, email }, setValues] = useState({
        password: "",
        email: "",
    });

    const [errors, setErrors] = useState({
        password: "",
        email: "",
    });

    const navigate = useNavigate();
    const signIn = useSignIn();

    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [open, setOpen] = useState(false);

    const { mutate: handleLogin, isLoading } = useLogin({
        onSuccess: (data: any) => {
            // if (data.message === "User does not exist") {
            //     toast.error("User doesn't exist. Please sign up.");
            // } else if (data.message === "Password Incorrect") {
            //     toast.error("Password is incorrect.");
            // }
            if (data) {
                const { accessToken, role, first_login, refreshToken, full_name } = data;
                localStorage.setItem("authToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);
                if (
                    signIn({
                        token: accessToken,
                        // 10 minutes
                        expiresIn: 20,
                        tokenType: "Bearer",
                        refreshToken,
                        // one day in minutes
                        refreshTokenExpireIn: 1 * 24 * 60,
                        authState: {
                            // formID,
                            isAdmin: role === "admin",
                            role,
                            username: data.username,
                            email: data?.email,
                            mobile: data?.mobile,
                            full_name,
                            loan: params?.business ? "business" : params?.construction ? "construction" : undefined,
                        },
                    })
                ) {
                    navigate(ROUTES.DASHBOARD);
                }
            } else if (data.message === "User already logged in on another device") {
                toast.success("User already logged in on another device");
            }
        },
        onError: (error: any) => {
            toast.error(error?.stack?.message || "Something went wrong");
        },
    });

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
    };

    const handleClickShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleSubmit = () => {
        toast.dismiss();

        if (!email && !password) {
            setErrors((prevState) => ({ ...prevState, email: "Email is required", password: "Password is required" }));
            toast.error("Email and Password is required");
            return;
        } else if (!email) {
            setErrors((prevState) => ({ ...prevState, email: "Email is required" }));
            toast.error("Email is required");
            return;
        } else if (!password) {
            setErrors((prevState) => ({ ...prevState, password: "Password is required", email: "" }));
            toast.error("Password is required");
            return;
        }

        setErrors((prevState) => ({ ...prevState, email: "", password: "" }));

        handleLogin({
            body: {
                email,
                password,
                loginSource: "portal",
            },
        });
    };

    return (
        <Grid container direction="column" id="login__container">
            <Navbar />
            <Grid id="login__content" item xs={12} justifyContent="center" alignItems="center">
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                    <Grid item id="login__image" sx={{ display: { xs: "none", md: "block" } }} xs={12} md={6}>
                        <div />
                    </Grid>
                    <Grid item id="login__form" xs={12} md={6}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h4" id="login__heading">
                                LOGIN
                            </Typography>
                            <GoogleLoginButton id="google-btn" type="login" />

                            <Typography component={"h6"} id="dash">
                                <Typography id="circle">or</Typography>
                            </Typography>
                            <FormControl className="field" required>
                                <TextField
                                    className={`styled-textfield ${errors.email !== "" ? "error" : ""}`}
                                    name="email"
                                    label="Email"
                                    inputProps={{ "data-testid": "login-email-input-field" }}
                                    placeholder="Enter your email"
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                    value={email}
                                    required
                                />
                                <FormHelperText error className="helper-text">
                                    {errors.email}
                                </FormHelperText>
                            </FormControl>
                            <FormControl className="field" required>
                                <TextField
                                    className={`styled-textfield ${errors.email !== "" ? "error" : ""}`}
                                    name="password"
                                    label="Password"
                                    inputProps={{ "data-testid": "login-password-input-field" }}
                                    placeholder="Enter your password"
                                    type={showPassword ? "text" : "password"}
                                    fullWidth
                                    onChange={handleChange}
                                    value={password}
                                    required
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                >
                                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormHelperText error className="helper-text">
                                    {errors.password}
                                </FormHelperText>
                            </FormControl>
                            <Typography variant="h6" id="forgot-password" data-testid="forgot-password-login-button" onClick={handleClickOpen}>
                                Forgot Password ?
                            </Typography>
                            <Button disabled={isLoading} onClick={handleSubmit} variant="contained" id="login__btn" data-testid="login-button">
                                {isLoading ? <CircularProgress className="white" /> : "Login"}
                            </Button>
                            <Link to={ROUTES.REGISTER} id="register__btn">
                                New User? Register
                            </Link>
                        </Grid>

                        <ForgotPasswordPopup handleClickOpen={handleClickOpen} handleClose={handleClose} open={open} />
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Login;
