import { Fragment, useEffect } from "react";
import { useWatch, useFieldArray, useFormContext } from "react-hook-form";
import { Typography } from "@mui/material";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import generateTestId from "utils/generateTestId";
import AssetTab from "../AssetTab";
import LoanTab from "../LoanTab";
import LoanFromAccount from "./LoanFromAccount";
import { BusinessIncome, EmployeeIncome, AgriIncome, RentalIncome, InvestmentIncome, IncomeStatement } from "./components";

// TODO: Refactor code so that we can have all the logic like isBusinessIncome can be directly inside that component

const IncomeTab = ({ header, index: indexFromProp, title: pane }: { header: string; index: number; title?: string }) => {
    const incomePrefixInReactForm = `${header}.${header === "applicant" ? "" : `${indexFromProp}.`}income.income`;
    const assetPrefixInReactForm = `${header}.${header === "applicant" ? "" : `${indexFromProp}.`}income.asset`;

    const loanPrefixInReactForm = `${header}.${header === "applicant" ? "" : `${indexFromProp}.`}income.loanOutstanding`;
    const loanFromAccountPrefixInReactForm = `${header}.${header === "applicant" ? "" : `${indexFromProp}.`}income.loanFromAccount`;

    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };

    const { fields: loanFormAccountFields } = useFieldArray({
        name: `${loanFromAccountPrefixInReactForm}`,
    });

    const isBusinessIncome = useWatch({
        name: `${incomePrefixInReactForm}.businessIncome.hasBusinessIncome`,
    });
    const businessIncome = useWatch({
        name: `${incomePrefixInReactForm}.businessIncome`,
    });
    const businessIncomeErrors = useWatch({
        name: `errors.${incomePrefixInReactForm}.businessIncome`,
    });
    const employmentIncome = useWatch({
        name: `${incomePrefixInReactForm}.employmentIncome`,
    });
    const employmentIncomeErrors = useWatch({
        name: `errors.${incomePrefixInReactForm}.employmentIncome`,
    });
    const agricultureIncome = useWatch({
        name: `${incomePrefixInReactForm}.agricultureIncome`,
    });
    const agricultureIncomeErrors = useWatch({
        name: `errors.${incomePrefixInReactForm}.agricultureIncome`,
    });
    const rentalIncome = useWatch({
        name: `${incomePrefixInReactForm}.rentalIncome`,
    });
    const rentalIncomeErrors = useWatch({
        name: `errors.${incomePrefixInReactForm}.rentalIncome`,
    });
    const investmentIncome = useWatch({
        name: `${incomePrefixInReactForm}.investmentIncome`,
    });
    const investmentIncomeErrors = useWatch({
        name: `errors.${incomePrefixInReactForm}.investmentIncome`,
    });
    const isEmployeeIncome = useWatch({
        name: `${incomePrefixInReactForm}.employmentIncome.hasEmploymentIncome`,
    });
    const isAgriIncome = useWatch({
        name: `${incomePrefixInReactForm}.agricultureIncome.hasAgricultureIncome`,
    });
    const isRentalIncome = useWatch({
        name: `${incomePrefixInReactForm}.rentalIncome.hasRentalIncome`,
    });
    const isInvestmentIncome = useWatch({
        name: `${incomePrefixInReactForm}.investmentIncome.hasInvestmentIncome`,
    });

    const { setValue, getValues } = useFormContext();

    const businessIncomeActiveFields: string[] = ["hasBusinessIncome"];
    const employeeIncomeActiveFields: string[] = ["hasEmploymentIncome"];
    const agricultureIncomeActiveFields: string[] = ["hasAgricultureIncome"];
    const rentalIncomeActiveFields: string[] = ["hasRentalIncome"];
    const investmentIncomeActiveFields: string[] = ["hasInvestmentIncome"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of businessIncomeActiveFields) {
            if (
                getValues(`${incomePrefixInReactForm}.businessIncome.${item}`) === "" ||
                getValues(`${incomePrefixInReactForm}.businessIncome.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${incomePrefixInReactForm}.businessIncome.errorsCounts`, errorsCounts);
    }, [businessIncome]);

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of employeeIncomeActiveFields) {
            if (
                getValues(`${incomePrefixInReactForm}.employmentIncome.${item}`) === "" ||
                getValues(`${incomePrefixInReactForm}.employmentIncome.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${incomePrefixInReactForm}.employmentIncome.errorsCounts`, errorsCounts);
    }, [employmentIncome]);

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of agricultureIncomeActiveFields) {
            if (
                getValues(`${incomePrefixInReactForm}.agricultureIncome.${item}`) === "" ||
                getValues(`${incomePrefixInReactForm}.agricultureIncome.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${incomePrefixInReactForm}.agricultureIncome.errorsCounts`, errorsCounts);
    }, [agricultureIncome]);

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of rentalIncomeActiveFields) {
            if (
                getValues(`${incomePrefixInReactForm}.rentalIncome.${item}`) === "" ||
                getValues(`${incomePrefixInReactForm}.rentalIncome.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${incomePrefixInReactForm}.rentalIncome.errorsCounts`, errorsCounts);
    }, [rentalIncome]);

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of investmentIncomeActiveFields) {
            if (
                getValues(`${incomePrefixInReactForm}.investmentIncome.${item}`) === "" ||
                getValues(`${incomePrefixInReactForm}.investmentIncome.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${incomePrefixInReactForm}.investmentIncome.errorsCounts`, errorsCounts);
    }, [investmentIncome]);

    return (
        <Fragment>
            <Grid container id="income-form" direction="column" justifyContent="center" alignItems="center">
                <CommonAccordion
                    keys={`${header}.businessIncome`}
                    showError={businessIncomeErrors?.errorsCounts > 0}
                    titleReactNode={
                        <CommonFields
                            inputType={INPUT_TYPE.RADIO}
                            name={`${incomePrefixInReactForm}.businessIncome.hasBusinessIncome`}
                            options={[
                                { label: "Yes", value: true },
                                { label: "No", value: false },
                            ]}
                            data-testid={generateTestId(`business-income-${pane}`, "field")}
                            label="Business Income"
                            className="common-mui-accordian__radio"
                            infoText="annual sales or service income (e.g. hiring income/commissons)"
                            readOnly={localStorage.getItem("disable_form") === "true" || false}
                        />
                    }
                    showDetail={isBusinessIncome === true}
                    title="Business Income"
                    data-testid={generateTestId(`business-income-${pane}`, "accordion")}
                    tab="income"
                    className="common-accordion-field entity-container long__accordian"
                    defaultExpanded={isBusinessIncome === true}
                >
                    {isBusinessIncome === true && (
                        <BusinessIncome activeFields={businessIncomeActiveFields} prefix={incomePrefixInReactForm} pane={pane} />
                    )}
                </CommonAccordion>
                {isBusinessIncome === true &&
                    businessIncome?.sales?.length > 0 &&
                    businessIncome.hasFiledIncomeTaxReturn === false &&
                    businessIncome.hasProfitLossStatement === false && (
                        <IncomeStatement header={header} prefix={incomePrefixInReactForm} pane={pane} />
                    )}

                {isBusinessIncome === false ? (
                    <>
                        <CommonAccordion
                            keys={`${header}.employeeIncome`}
                            showError={employmentIncomeErrors?.errorsCounts > 0}
                            titleReactNode={
                                <CommonFields
                                    inputType={INPUT_TYPE.RADIO}
                                    name={`${incomePrefixInReactForm}.employmentIncome.hasEmploymentIncome`}
                                    options={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false },
                                    ]}
                                    data-testid={generateTestId(`employee-income-${pane}`, "field")}
                                    label="Employee Income"
                                    className="common-mui-accordian__radio"
                                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                                />
                            }
                            showDetail={isEmployeeIncome === true}
                            className="common-accordion-field entity-container"
                            defaultExpanded={isEmployeeIncome === true}
                            title="Employee Income"
                            data-testid={generateTestId(`employee-income-${pane}`, "accordion")}
                            tab="income"
                        >
                            {isEmployeeIncome === true ? (
                                <EmployeeIncome
                                    activeFields={employeeIncomeActiveFields}
                                    prefix={incomePrefixInReactForm}
                                    entityType={header}
                                    entityIndex={indexFromProp}
                                    pane={pane}
                                />
                            ) : (
                                ""
                            )}
                        </CommonAccordion>
                        <CommonAccordion
                            keys={`${header}.agricultureIncome`}
                            showError={agricultureIncomeErrors?.errorsCounts > 0}
                            titleReactNode={
                                <CommonFields
                                    inputType={INPUT_TYPE.RADIO}
                                    name={`${incomePrefixInReactForm}.agricultureIncome.hasAgricultureIncome`}
                                    options={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false },
                                    ]}
                                    label="Agriculture Income"
                                    data-testid={generateTestId(`agriculture-income-${pane}`, "field")}
                                    className="common-mui-accordian__radio"
                                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                                />
                            }
                            showDetail={isAgriIncome === true}
                            tab="income"
                            className="common-accordion-field entity-container"
                            defaultExpanded={isAgriIncome === true}
                            title="Agriculture Income"
                            data-testid={generateTestId(`agriculture-income-${pane}`, "accordion")}
                        >
                            {isAgriIncome === true ? (
                                <AgriIncome activeFields={agricultureIncomeActiveFields} prefix={incomePrefixInReactForm} pane={pane} />
                            ) : (
                                ""
                            )}
                        </CommonAccordion>
                        <CommonAccordion
                            keys={`${header}.rentalIncome`}
                            showError={rentalIncomeErrors?.errorsCounts > 0}
                            titleReactNode={
                                <CommonFields
                                    inputType={INPUT_TYPE.RADIO}
                                    name={`${incomePrefixInReactForm}.rentalIncome.hasRentalIncome`}
                                    options={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false },
                                    ]}
                                    data-testid={generateTestId(`rental-income-${pane}`, "field")}
                                    label="Rental Income"
                                    className="common-mui-accordian__radio"
                                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                                />
                            }
                            tab="income"
                            showDetail={isRentalIncome === true}
                            title="Rental Income"
                            data-testid={generateTestId(`rental-income-${pane}`, "accordion")}
                            className="common-accordion-field entity-container"
                            defaultExpanded={isRentalIncome === true}
                        >
                            {isRentalIncome === true ? (
                                <RentalIncome activeFields={rentalIncomeActiveFields} prefix={incomePrefixInReactForm} pane={pane} />
                            ) : (
                                ""
                            )}
                        </CommonAccordion>
                        <CommonAccordion
                            keys={`${header}.investmentIncome`}
                            showError={investmentIncomeErrors?.errorsCounts > 0}
                            titleReactNode={
                                <CommonFields
                                    inputType={INPUT_TYPE.RADIO}
                                    name={`${incomePrefixInReactForm}.investmentIncome.hasInvestmentIncome`}
                                    options={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false },
                                    ]}
                                    data-testid={generateTestId(`investment-income-${pane}`, "field")}
                                    label="Investment Income"
                                    className="common-mui-accordian__radio"
                                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                                />
                            }
                            showDetail={isInvestmentIncome === true}
                            tab="income"
                            className="common-accordion-field entity-container"
                            defaultExpanded={isInvestmentIncome === true}
                            title="Investment Income"
                            data-testid={generateTestId(`investment-income-${pane}`, "accordion")}
                        >
                            {isInvestmentIncome === true ? (
                                <InvestmentIncome activeFields={investmentIncomeActiveFields} prefix={incomePrefixInReactForm} pane={pane} />
                            ) : (
                                ""
                            )}
                        </CommonAccordion>
                    </>
                ) : (
                    ""
                )}
                <Fragment>
                    {loanFormAccountFields?.length === 0 && (
                        <Grid container item xs={12} className="collapsed-box">
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Typography variant="h5" className="status-text">
                                    Loans detected from Credit Bureau
                                </Typography>
                                <Typography variant="h6" className="status-heading">
                                    Pending on User
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {loanFormAccountFields?.map((loanFormAccountField, index) => {
                        return <LoanFromAccount prefix={loanFromAccountPrefixInReactForm} index={index} pane={pane} />;
                    })}
                </Fragment>
                {businessIncome?.hasBalanceSheet !== true && (
                    <Fragment>
                        <Divider className="divider" />
                        <AssetTab assetPrefixInReactForm={assetPrefixInReactForm} pane={pane} />
                        <LoanTab loanPrefixInReactForm={loanPrefixInReactForm} entityType={header} entityIndex={indexFromProp} pane={pane} />
                    </Fragment>
                )}
            </Grid>
        </Fragment>
    );
};

export default IncomeTab;
