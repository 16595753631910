import { useEffect } from "react";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import infoIcon from "assets/images/info.png";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { defaultDocumentPersonalDetailsFixture, defaultPersonalDetailsWithKYCFixture } from "fixtures";
import { INPUT_TYPE } from "globalConstants";
import { useFieldArrayWithAutoAddAndRemove, usePartnerOrDirectorToCoborrowerOrGuarantorConversion } from "hooks";
import { useCoborrowerFields, useGuarantorFields } from "pages/Dashboard/hooks";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import generateTestId from "utils/generateTestId";

const OwnerShipDetails = ({ prefix, pane }: { prefix: string; pane?: string }) => {
    const { getValues, setValue } = useFormContext();

    const ownershipDetails = useWatch({
        name: `${prefix}entity.ownershipDetails`,
    });
    const errors = useWatch({
        name: `errors.${prefix}entity.ownershipDetails`,
    });

    const { fields, append, remove } = useFieldArray({
        name: `${prefix}entity.ownershipDetails.partnerDetails`,
    });

    useFieldArrayWithAutoAddAndRemove({
        countName: `${prefix}entity.ownershipDetails.noOfPartners`,
        name: `${prefix}personal.partners`,
        fixture: defaultPersonalDetailsWithKYCFixture,
    });
    useFieldArrayWithAutoAddAndRemove({
        countName: `${prefix}entity.ownershipDetails.noOfPartners`,
        name: `${prefix}document.personal.personal`,
        fixture: defaultDocumentPersonalDetailsFixture,
    });

    const isApplicant = prefix.includes("applicant");

    const coborrowerFields = useCoborrowerFields();
    const guarantorFields = useGuarantorFields();

    const entityOptions = [
        "Applicant",
        ...coborrowerFields.map((field, index) => {
            if (coborrowerFields.length === 1) return `Coborrower`;

            return `Coborrower ${index + 1}`;
        }),
        ...guarantorFields.map((field, index) => {
            if (guarantorFields.length === 1) return `Guarantor`;

            return `Guarantor ${index + 1}`;
        }),
    ].map((item: string) => ({ label: item.replace(" ", "-"), value: item.toLowerCase().replace(" ", "-") }));

    usePartnerOrDirectorToCoborrowerOrGuarantorConversion({
        entity: "partner",
        entityDetails: ownershipDetails.partnerDetails,
        isApplicant,
    });

    useEffect(() => {
        const activeFields: string[] = ["noOfPartners"];
        fields.map((item, index) => {
            activeFields.push(`partnerDetails.${index}.name`);
            activeFields.push(`partnerDetails.${index}.sharePercentage`);
        });
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}entity.ownershipDetails.${item}`) === "" || getValues(`${prefix}entity.ownershipDetails.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}entity.ownershipDetails.errorsCounts`, errorsCounts);

        ownershipDetails.partnerDetails.forEach((partner: any, i: number) => {
            let [firstName, middleName, lastName] = partner.name.split(" ").filter((name: string) => name);
            if (!lastName) {
                lastName = middleName;
                middleName = "";
            }
            if (!middleName && !lastName) {
                lastName = "";
                middleName = "";
            }
            if (partner.entityType === "applicant") {
                setValue(`${prefix}personal.partners.${i}.yourDetails.firstName`, firstName);
                setValue(`${prefix}personal.partners.${i}.yourDetails.middleName`, middleName);
                setValue(`${prefix}personal.partners.${i}.yourDetails.lastName`, lastName);
            } else {
                const [entity, index] = partner.entityType?.split("-");
                const entityType = `${entity}.${Number(index ?? "1") - 1}`;
                setValue(`${entityType}.personal.personal.yourDetails.firstName`, firstName);
                setValue(`${entityType}.personal.personal.yourDetails.middleName`, middleName);
                setValue(`${entityType}.personal.personal.yourDetails.lastName`, lastName);
            }
        });
    }, [ownershipDetails]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title={`Ownership Detail`}
            tab="entity"
            data-testid={generateTestId(`ownership-detail-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <CommonFields
                    inputType={INPUT_TYPE.NUMBER}
                    name={`${prefix}entity.ownershipDetails.noOfPartners`}
                    data-testid={generateTestId(`partners-${pane}`, "field")}
                    prop={{
                        value: "No. of Partners",
                    }}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            // get fields.length and compare with e.target.value
                            // depending on that append or remove
                            const currentLength = fields.length;
                            const targetValue = +e.target.value || 0;

                            if (currentLength < targetValue) {
                                const runningArray = [];
                                for (let i = 0; i < targetValue - currentLength; i++) {
                                    runningArray.push({
                                        name: "",
                                        sharePercentage: 0,
                                        entityType: "applicant",
                                    });
                                }
                                append(runningArray, {
                                    shouldFocus: false,
                                });
                            } else if (currentLength > targetValue) {
                                // get the indices of elements to be removed starting from last
                                const indicesToRemove = [];
                                for (let i = 0; i < currentLength - targetValue; i++) {
                                    indicesToRemove.push(currentLength - i - 1);
                                }
                                // remove the elements
                                remove(indicesToRemove);
                            }
                        },

                        max: {
                            value: 5,
                            message: "Maximum 5 Partners allowed",
                        },
                        min: {
                            value: 1,
                            message: "Minimum 1 Partner required",
                        },
                        setValueAs: (value) => Number(value),
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    label="Partners"
                    className={"short-input-field"}
                />
                {fields.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        style={{ marginTop: "0" }}
                        container
                        direction="row"
                        justifyContent="space-between"
                        spacing={3}
                        alignItems="center"
                    >
                        <Grid item xs={4} container>
                            <Typography className="common-label" data-testid={generateTestId(`names-${pane}`, "field-label")}>
                                Names
                            </Typography>
                        </Grid>
                        <Grid item xs={4} container>
                            <Typography className="common-label" data-testid={generateTestId(`share-${pane}`, "field-label")}>
                                % Share
                                <sup id="tooltip">
                                    <Tooltip title={"Share of Each Partner"} placement="top" arrow>
                                        <img src={infoIcon} alt="icon" />
                                    </Tooltip>
                                </sup>
                            </Typography>
                        </Grid>
                        {isApplicant && (
                            <Grid item xs={4} container>
                                <Typography className="common-label" data-testid={generateTestId(`entity-type-${pane}`, "field-label")}>
                                    Select Entity
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                )}
                {fields.map((field, index) => (
                    <Grid key={field.id} container direction="row" justifyContent="space-between" spacing={3} alignItems="center">
                        <Grid item xs={4} container>
                            <CommonFields
                                inputType={INPUT_TYPE.TEXT}
                                name={`${prefix}entity.ownershipDetails.partnerDetails.${index}.name`}
                                data-testid={generateTestId(`names-${index + 1}-${pane}`, "field")}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                                showLabel={false}
                                label=""
                            />
                        </Grid>
                        <Grid item xs={4} container>
                            <CommonFields
                                inputType={INPUT_TYPE.NUMBER}
                                name={`${prefix}entity.ownershipDetails.partnerDetails.${index}.sharePercentage`}
                                data-testid={generateTestId(`share-${index + 1}-${pane}`, "field")}
                                prop={{
                                    value: "Share of each partners",
                                }}
                                showLabel={false}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                                label=""
                                className={"short-input-field"}
                            />
                        </Grid>
                        {isApplicant && (
                            <Grid item xs={4} container justifyContent="flex-end">
                                <CommonFields
                                    inputType={INPUT_TYPE.SELECT}
                                    name={`${prefix}entity.ownershipDetails.partnerDetails.${index}.entityType`}
                                    data-testid={generateTestId(`entity-type-${index + 1}-${pane}`, "field")}
                                    options={entityOptions}
                                    value={ownershipDetails.partnerDetails[index].entityType}
                                    reactHookFormRegisterOptions={{
                                        onChange: (e) => {
                                            if (
                                                ownershipDetails.partnerDetails.some(
                                                    (detail: any) => e.target.value !== "applicant" && detail.entityType === e.target.value
                                                )
                                            ) {
                                                toast.dismiss();
                                                toast.error(`${convertCamelCaseToTitleCase(e.target.value)} is already selected`);
                                                return;
                                            }
                                            setValue(`${prefix}entity.ownershipDetails.partnerDetails.${index}.entityType`, e.target.value);
                                        },
                                    }}
                                    disableReactHookForm={true}
                                    // prop={{
                                    //     value: "% Holding of each Director Shareholder",
                                    // }}

                                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                                    label=""
                                    showLabel={false}
                                />
                            </Grid>
                        )}
                    </Grid>
                ))}
            </Grid>
        </CommonAccordion>
    );
};
export default OwnerShipDetails;
