import { Fragment, useEffect } from "react";
import { useWatch, useFormContext, useFieldArray } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import Customer from "./Customer";
import fixture from "./fixture";

const BusinessExperience = ({ prefix, header, pane }: { prefix: string; header: string; pane?: string }) => {
    const { setValue, getValues, control } = useFormContext();
    const machineUsage = useWatch({
        name: "requirement.machineUsage",
    });

    const businessExperience = useWatch({
        name: `${prefix}entity.businessExperience`,
    });

    const errors = useWatch({
        name: `errors.${prefix}entity.businessExperience`,
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: `${prefix}entity.businessExperience.customers`,
    });

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (
                getValues(`${prefix}entity.businessExperience.${item}`) === "" ||
                getValues(`${prefix}entity.businessExperience.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}entity.businessExperience.errorsCounts`, errorsCounts);
    }, [businessExperience]);

    return (
        <CommonAccordion
            title={`Business Experience`}
            data-testid={generateTestId(`business-experience-${pane}`, "accordion")}
            tab="entity"
            keys={`${header}.businessExperience`}
            showError={errors?.errorsCounts > 0}
        >
            <Grid
                className="business-experience-tab"
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                data-testid="businessExperience"
            >
                {fixture.map(({ name, inputType, options, subtitle, label, reactHookFormRegisterOptions, clarificatoryText, dataTestId }, index) => {
                    if (header === "applicant") {
                        if (machineUsage?.isFirstTimeUser === false) {
                            if (name === "industryExperienceInYears" || name === "businessActivity" || name === "otherBusinessActivity") {
                                return "";
                            }
                            if (!businessExperience?.kindOfExperience?.includes("Operator")) {
                                if (name === "operatorExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.kindOfExperience?.includes("Supervisor")) {
                                if (name === "supervisorExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.kindOfExperience?.includes("Owner")) {
                                if (name === "ownerExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.kindOfExperience?.includes(OTHER)) {
                                if (name === "otherExperience" || name === "otherExperienceInYears") {
                                    return "";
                                }
                            }

                            if (!businessExperience?.equipmentExperience?.includes("Backhoe Loaders")) {
                                if (name === "backhoeLoadersExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Compactors")) {
                                if (name === "compactorsExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Excavators")) {
                                if (name === "excavatorsExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Gensets")) {
                                if (name === "gensetsExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Mini Excavators")) {
                                if (name === "miniExcavatorsExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Skid Steer Loaders")) {
                                if (name === "skidSteerLoadersExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Super Loaders")) {
                                if (name === "superLoadersExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Telehandlers")) {
                                if (name === "telehandlersExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes("Wheeled Loaders")) {
                                if (name === "wheeledLoadersExperienceInYears") {
                                    return "";
                                }
                            }
                            if (!businessExperience?.equipmentExperience?.includes(OTHER)) {
                                if (name === "otherEquipmentExperience" || name === "otherEquipmentExperienceInYears") {
                                    return "";
                                }
                            }
                        }
                        if (machineUsage?.isFirstTimeUser !== false) {
                            if (
                                name === "operatorExperienceInYears" ||
                                name === "supervisorExperienceInYears" ||
                                name === "ownerExperienceInYears" ||
                                name === "kindOfExperience" ||
                                name === "otherExperience" ||
                                name === "otherExperienceInYears" ||
                                name === "equipmentExperience" ||
                                name === "backhoeLoadersExperienceInYears" ||
                                name === "compactorsExperienceInYears" ||
                                name === "excavatorsExperienceInYears" ||
                                name === "gensetsExperienceInYears" ||
                                name === "miniExcavatorsExperienceInYears" ||
                                name === "skidSteerLoadersExperienceInYears" ||
                                name === "superLoadersExperienceInYears" ||
                                name === "telehandlersExperienceInYears" ||
                                name === "wheeledLoadersExperienceInYears" ||
                                name === "otherEquipmentExperienceInYears" ||
                                name === "otherEquipmentExperience"
                            ) {
                                return "";
                            }

                            if (!businessExperience?.businessActivity?.includes(OTHER)) {
                                if (name === "otherBusinessActivity") {
                                    return "";
                                }
                            }
                        }
                    }
                    if (header !== "applicant") {
                        if (
                            name === "operatorExperienceInYears" ||
                            name === "supervisorExperienceInYears" ||
                            name === "ownerExperienceInYears" ||
                            name === "kindOfExperience" ||
                            name === "otherExperience" ||
                            name === "otherExperienceInYears" ||
                            name === "equipmentExperience" ||
                            name === "backhoeLoadersExperienceInYears" ||
                            name === "compactorsExperienceInYears" ||
                            name === "excavatorsExperienceInYears" ||
                            name === "gensetsExperienceInYears" ||
                            name === "miniExcavatorsExperienceInYears" ||
                            name === "skidSteerLoadersExperienceInYears" ||
                            name === "superLoadersExperienceInYears" ||
                            name === "telehandlersExperienceInYears" ||
                            name === "wheeledLoadersExperienceInYears" ||
                            name === "otherEquipmentExperienceInYears" ||
                            name === "otherEquipmentExperience"
                        ) {
                            return "";
                        }

                        if (!businessExperience.businessActivity?.includes(OTHER)) {
                            if (name === "otherBusinessActivity") {
                                return "";
                            }
                        }
                    }

                    if (!businessExperience.customerSourcing?.includes(OTHER)) {
                        if (name === "otherCustomerSourcing") {
                            return "";
                        }
                    }

                    if (
                        !businessExperience.customerSourcing?.includes("Existing Customers") &&
                        !businessExperience.customerSourcing?.includes("Private Bidding from Organisations") &&
                        !businessExperience.customerSourcing?.includes("Public Bidding from Organisations")
                    ) {
                        if (name === "principal" || name === "expectedOrderValue") {
                            return "";
                        }
                    }

                    activeFields.push(name);

                    return (
                        <CommonFields
                            key={index}
                            inputType={inputType}
                            subtitle={subtitle}
                            readOnly={localStorage.getItem("disable_form") === "true"}
                            options={
                                options?.map((value) => ({
                                    value,
                                    label: convertSnakeCaseToHeaderCase(value),
                                })) || []
                            }
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            ignoreOthers={true}
                            label={label}
                            name={`${prefix}entity.businessExperience.${name}`}
                            data-testid={generateTestId(`${dataTestId || label}-${pane}`, "field")}
                            prop={{ value: clarificatoryText || "" }}
                        />
                    );
                })}
                <CommonFields
                    inputType={INPUT_TYPE.NUMBER}
                    name={`${prefix}entity.businessExperience.noOfCustomers`}
                    data-testid={generateTestId(`Customers-${pane}`, "field")}
                    prop={{
                        value: "Number of Customers (0-10)",
                    }}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            // get fields.length and compare with e.target.value
                            // depending on that append or remove
                            const currentLength = fields.length;
                            const targetValue = +e.target.value || 0;

                            if (currentLength < targetValue) {
                                const runningArray = [];
                                for (let i = 0; i < targetValue - currentLength; i++) {
                                    runningArray.push({
                                        customerName: "",
                                        revenue: 0,
                                    });
                                }
                                append(runningArray, {
                                    shouldFocus: false,
                                });
                            } else if (currentLength > targetValue) {
                                // get the indices of elements to be removed starting from last
                                const indicesToRemove = [];
                                for (let i = 0; i < currentLength - targetValue; i++) {
                                    indicesToRemove.push(currentLength - i - 1);
                                }
                                // remove the elements
                                remove(indicesToRemove);
                            }
                        },

                        max: {
                            value: 10,
                            message: "Maximum 10 Customers allowed",
                        },
                        min: {
                            value: 0,
                            message: "Minimum 0 Customer required",
                        },
                        setValueAs: (value) => Number(value),
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    label="Customers"
                    className={"short-input-field"}
                />
                {fields.map((field, index) => (
                    <Customer key={field.id} index={index} remove={remove} prefix={`${prefix}entity.businessExperience.customers`} pane={pane} />
                ))}
            </Grid>
        </CommonAccordion>
    );
};

export default BusinessExperience;
