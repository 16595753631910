import { INPUT_TYPE } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "income",
        label: "Sales/Income",
        inputType: INPUT_TYPE.PRICE,
        editable: false,
        defaultValue: "",
    },
    {
        name: "expense",
        label: "Expense",
        editable: true,
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "depreciation",
        editable: true,
        label: "Depreciation",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "financingCost",
        editable: true,
        label: "Financial Cost",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "grossProfit",
        label: "Gross Profit",
        editable: false,
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "netProfit",
        editable: false,
        label: "Net Profit",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
];

export default fixture;
