import React, { useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { CircularProgress } from "@mui/material";
import { useLogin } from "__generated__/apiComponents";
import { login } from "api";
import axios from "axios";
import { ROUTES } from "globalConstants";
import { env } from "../../env";

type Props = {};

const GoogleLogin = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const signIn = useSignIn();

    const params = useParams();

    useEffect(() => {
        const url = `${env.REACT_APP_NODEJS_API_URL}/auth/google/callback${location.search}`;
        (async () => {
            try {
                const { data } = await axios.post(url);
                if (data.email && data.newUser) {
                    navigate(`${ROUTES.REGISTER}?email=${data.email}`);
                } else {
                    if (data) {
                        const { accessToken, role, first_login, refreshToken, full_name } = data;
                        localStorage.setItem("authToken", accessToken);
                        localStorage.setItem("refreshToken", refreshToken);
                        if (
                            signIn({
                                token: accessToken,
                                // 10 minutes
                                expiresIn: 10,
                                tokenType: "Bearer",
                                refreshToken,
                                // one day in minutes
                                refreshTokenExpireIn: 1 * 24 * 60,
                                authState: {
                                    // formID,
                                    isAdmin: role === "admin",
                                    role,
                                    username: data.username,
                                    email: data?.email,
                                    mobile: data?.mobile,
                                    full_name,
                                    loan: params?.business ? "business" : params?.construction ? "construction" : undefined,
                                },
                            })
                        ) {
                            navigate(ROUTES.DASHBOARD);
                        }
                    }
                }
            } catch (error) {}
        })();
    }, []);
    return (
        <div className="flex items-center justify-center h-screen w-screen">
            <CircularProgress />
        </div>
    );
};

export default GoogleLogin;
