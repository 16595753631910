import React, { useEffect } from "react";
import { useController, useFormContext, useWatch } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";

const TradeReferenceItem = ({
    index,
    activeFields,
    props: { inputType, name, label, options, reactHookFormRegisterOptions, showMask, editable = true, showLabel, placeholder, infoText },
}: {
    index: number;
    props: ICommonFixture;
    activeFields: string[];
}) => {
    const tradeReferences = useWatch({
        name: `references.tradeReferences.${index}`,
    });
    const { register, setValue } = useFormContext();

    useEffect(() => {
        if (tradeReferences.noOfYears > 99) {
            setValue(`references.tradeReferences.${index}.noOfYears`, 99);
        }
    }, [tradeReferences]);

    const { field: mobileField } = useController({ name: `references.tradeReferences.${index}.mobile` });
    const { field: landlineField } = useController({ name: `references.tradeReferences.${index}.landline` });
    if (name === "mobile") {
        activeFields.push("mobile");
        return (
            <Grid className="common__mui__textfield__container" container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    <Typography className="common-label" data-testid={`mobile-reference-${index + 1}-field-label`}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <PhoneInput
                            disabled={localStorage.getItem("disable_form") === "true"}
                            inputProps={{ "data-testid": `mobile-reference-${index + 1}-field` }}
                            country={"in"}
                            {...mobileField}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
    if (name === "landline") {
        activeFields.push("landline");
        return (
            <Grid className="common__mui__textfield__container" container direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                    <Typography className="common-label" data-testid={`landline-reference-${index + 1}-field-label`}>
                        {label}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <PhoneInput
                            disabled={localStorage.getItem("disable_form") === "true"}
                            country={"in"}
                            inputProps={{ "data-testid": `landline-reference-${index + 1}-field` }}
                            {...landlineField}
                        />
                    </Grid>
                </Grid>
            </Grid>
        );
    }

    activeFields.push(name);

    return (
        <CommonFields
            inputType={inputType}
            {...register(`references.tradeReferences.${index}.${name}`)}
            name={`references.tradeReferences.${index}.${name}`}
            showMask={showMask}
            placeholder={placeholder}
            multiLine={inputType === INPUT_TYPE.NUMBER || showMask ? false : true}
            options={
                options?.map((value: string) => ({
                    value: value !== OTHER ? convertSnakeCaseToHeaderCase(value) : value,
                    label: convertSnakeCaseToHeaderCase(value),
                })) || []
            }
            label={label}
            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
            prop={{
                value: infoText || "",
            }}
            data-testid={generateTestId(`${label}-reference-${index + 1}`, "field")}
        />
    );
};

export default TradeReferenceItem;
