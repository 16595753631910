import { useQuery } from "react-query";
import KEYS, { getCityState } from "api";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const useCityStateValue = ({ pincode, cityName, stateName }: { pincode: string; cityName?: string; stateName?: string }) => {
    const { setValue } = useFormContext();
    const { data, isLoading, error } = useQuery(
        [KEYS.GET_CITY_STATE, pincode],
        () => {
            return getCityState({
                pathParams: {
                    code: pincode,
                },
            });
        },
        {
            enabled: pincode?.length === 6,
        }
    );

    const { District, Block, State: state } = data?.[0]?.PostOffice?.[0] || {};
    const city = Block === "NA" ? District : Block;
    useEffect(() => {
        if (cityName && city) setValue(cityName, city);

        if (stateName && state) setValue(stateName, state);
    }, [city, state]);

    return { city, state, isLoading };
};

export default useCityStateValue;
