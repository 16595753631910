//@ts-nocheck
import { memo, useMemo, useEffect, useState, useContext } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDisconnectLoanApplicationAccess, useGetAllSalesAgentUsers, useShareLoanApplication } from "__generated__/apiComponents";
import CommonAccordion from "components/CommonAccordion";
import AuthUserContext from "contexts/session";
import { OTHER } from "globalConstants";
import { useSaveForm } from "hooks";
import generateTestId from "utils/generateTestId";
import "./Equipment.styles.scss";
import { DeleteEquipment, EquipmentItem } from "./components";
import fixture from "./fixture";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 2,
    borderRadius: "10px",
};

const Equipment = ({ index, remove }: { index: number; remove: any }) => {
    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };

    const { setValue, getValues } = useFormContext();

    const equipment = useWatch({
        name: `requirement.equipments.${index}`,
    });

    const errors = useWatch({
        name: `errors.requirement.equipments.${index}`,
    });

    const [selectedAgent, setSelectedAgent] = useState<string>("");

    const firstEquipment = useWatch({
        name: `requirement.equipments.0`,
    });

    const [selectedOption, setSelectedOption] = useState({
        label: "",
        id: "",
        username: "",
    });

    const [disableSalesAgent, setDisableSalesAgent] = useState<boolean>(false);

    const [open, setOpen] = useState(false);

    const salesAgent = useWatch({
        name: `requirement.equipments.${index}.salesAgent`,
    });

    const previousSalesAgent = salesAgent?.username;

    const { authUser } = useContext(AuthUserContext);

    const {
        data,
        isLoading: isSalesPersonLoading,
        refetch,
    } = useGetAllSalesAgentUsers(
        { queryParams: { name: salesAgent.fullName } },
        {
            enabled: false,
            refetchOnWindowFocus: false,

            onError: (error: any) => {
                toast.dismiss();
                toast.error(error?.stack?.message);
            },
        }
    );

    const saveFrom = useSaveForm();

    const { mutate: share } = useShareLoanApplication({
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message);
        },
        onSuccess: (data: any) => {
            toast.dismiss();
            toast.success(data?.message);
            setValue(`requirement.equipments.0.salesAgent.username`, selectedOption?.username);
            setValue(`requirement.equipments.0.salesAgent.fullName`, selectedOption?.label);

            saveFrom();
        },
    });

    const { mutate: disconnect } = useDisconnectLoanApplicationAccess({
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });

    useEffect(() => {
        if (index === 0) {
            const delayDebounceFn = setTimeout(() => {
                if (salesAgent?.fullName.length > 2) {
                    refetch();
                }
            }, 300);

            return () => clearTimeout(delayDebounceFn);
        }
    }, [salesAgent?.fullName]);

    const salesAgentNames = useMemo(() => {
        if (data?.length > 0) {
            return data?.map((item: any, index: number) => {
                return {
                    label: item?.full_name,
                    id: item?.id,
                    username: item?.username,
                };
            });
        } else {
            return [];
        }
    }, [data]);

    const handleSelectValue = (value: string) => {
        if (value.length > 0) {
            setSelectedAgent(value);
            setOpen(true);
        }
    };

    useEffect(() => {
        if (localStorage.getItem("disable_form") !== "true") {
            if (selectedOption?.label !== "" && selectedOption?.id !== "") {
                if (previousSalesAgent)
                    disconnect({
                        body: { loanApplicationId: localStorage.getItem("form_id") ?? "", username: previousSalesAgent },
                    });
                share({
                    body: { loanApplicationId: localStorage.getItem("form_id") ?? "", userId: selectedOption?.id },
                });
            }
        }
    }, [selectedOption]);

    const handleSendEmail = () => {
        const user = salesAgentNames.find((item: any) => item.label === selectedAgent);
        setSelectedOption((prevUser: any) => {
            return prevUser === user ? prevUser : user;
        });
    };

    useEffect(() => {
        if (index > 0) {
            setValue(`requirement.equipments.${index}.salesAgent.fullName`, firstEquipment?.salesAgent?.fullName);
            setValue(`requirement.equipments.${index}.salesAgent.username`, firstEquipment?.salesAgent?.username);
            setValue(`requirement.equipments.${index}.sellerName`, firstEquipment?.sellerName);
        }
    }, [firstEquipment]);

    useEffect(() => {
        if (authUser && authUser?.role === "salesperson" && index === 0) {
            setValue(`requirement.equipments.0.salesAgent.fullName`, authUser?.full_name);
            setValue(`requirement.equipments.0.salesAgent.username`, authUser?.username);
        }
    }, [authUser]);

    useEffect(() => {
        if (firstEquipment?.salesAgent?.fullName === "") {
            setValue(`requirement.equipments.0.salesAgent.username`, "");
        }
    }, []);

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`requirement.equipments.${index}.${item}`) === "" || getValues(`requirement.equipments.${index}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.requirement.equipments.${index}.errorsCounts`, errorsCounts / 2);
    }, [equipment]);

    useEffect(() => {
        if (equipment.manufacturer === OTHER) {
            setValue(`requirement.equipments.${index}.category`, "other");
            setValue(`requirement.equipments.${index}.model`, "other");
            setValue(`requirement.equipments.${index}.sellerName`, "other");
        }
    }, [equipment.manufacturer]);

    useEffect(() => {
        if (equipment?.category === OTHER) {
            setValue(`requirement.equipments.${index}.model`, "other");
        }
    }, [equipment.category]);

    return (
        <Grid className="equipment-tab" container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CommonAccordion
                title={`${indexText(index)} Equipment`}
                tab="requirement"
                showError={errors?.errorsCounts > 0}
                id="equipment__container"
                data-testid={generateTestId(`${indexText(index)} Equipment`, "accordion")}
            >
                {fixture.map((props, internalIndex) => (
                    <EquipmentItem
                        index={index}
                        key={internalIndex}
                        props={props}
                        isSalesPersonLoading={isSalesPersonLoading}
                        salesAgentNames={salesAgentNames}
                        disableSalesAgent={disableSalesAgent}
                        handleSelectValue={handleSelectValue}
                        activeFields={activeFields}
                        isSalesPerson={authUser?.role === "salesperson" || (firstEquipment?.salesAgent?.username !== "" && !authUser?.isAdmin)}
                    />
                ))}
            </CommonAccordion>
            <DeleteEquipment index={index} remove={remove} />
            {index === 0 && (
                <Modal hideBackdrop open={open} onClose={() => setOpen(false)}>
                    <Box sx={{ ...style, width: 300 }}>
                        <Grid container justifyContent="center" alignItems="center">
                            <Grid item xs={12} container justifyContent={"flex-end"} alignItems="center">
                                <CloseOutlinedIcon onClick={() => setOpen(false)} className="close-icon" />
                            </Grid>
                            <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                <Typography className="otp-text">
                                    Sales agent selection cannot be changed once made. Do you want to confirm your choice?
                                </Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent={"center"} alignItems="center">
                                <Typography className="otp-text">{selectedAgent}</Typography>
                            </Grid>
                            <Grid item xs={12} container alignItems="center" justifyContent="space-around">
                                <Button
                                    variant="contained"
                                    style={{ backgroundColor: "#1b75bc" }}
                                    onClick={() => {
                                        handleSendEmail();
                                        setOpen(false);
                                    }}
                                    data-testid="sales-agent-dialog-yes-button"
                                >
                                    Yes
                                </Button>
                                <Button
                                    style={{ backgroundColor: "#767676", marginLeft: "10px" }}
                                    variant="contained"
                                    className="btn"
                                    onClick={() => {
                                        setOpen(false);
                                    }}
                                >
                                    No
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            )}
        </Grid>
    );
};

export default memo(Equipment);
