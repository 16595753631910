import { useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetProspectiveUserById } from "__generated__/apiComponents";
import { defaultValues } from "contexts/longForm";
import { ROUTES } from "globalConstants";
import { useError } from "hooks";

const useSetProspectiveDefaultValues = () => {
    const location = useLocation();
    const showError = useError();
    const signOut = useSignOut();
    const { id } = useParams();
    const navigate = useNavigate();

    const { data, refetch, error, isLoading } = useGetProspectiveUserById<any>({
        pathParams: { id: id! },
    });

    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const formData = data?.loan_applications?.[0];

    const setValue = methods.setValue;

    const setDefaultValues = async (values: any) => {
        await Object?.entries(values).forEach(([name, value]: any) => setValue(name, value));
    };

    useEffect(() => {
        if (data) {
            if (data?.role == "user" && data.userType !== "registered") {
                if (formData) {
                    setDefaultValues(formData?.data);
                    // @ts-ignore
                    localStorage.setItem("is_masked", formData?.is_masked);
                    localStorage.setItem("form_id", formData?.id);
                    // @ts-ignore
                    setValue("loanApplicant", formData?.applicant);
                    if (data.loanApplicationId) localStorage.setItem("is_prospective_form", "false");
                    else localStorage.setItem("is_prospective_form", "true");
                } else {
                    toast.dismiss();
                    toast.error("Loan application does not exist");
                    navigate(ROUTES.HOME);
                }
            }

            // @ts-ignore
            setValue("followUpDate", data?.followUpDate);
            // @ts-ignore
            setValue("newFollowUpDate", { date: "", subject: "", note: [] });
            // @ts-ignore
            setValue("contact", {
                businessName: data?.businessName ?? "",
                full_name: data?.full_name ?? "",
                landline: data?.landline ?? "",
                mobile: data?.mobile ?? "",
                email: data?.email ?? "",
                roleDetails: data?.roleDetails,
                socialMedia: data?.socialMedia ?? { facebook: "", linkedin: "", instagram: "", twitter: "" },
                //@ts-ignore
                address: data?.address ?? {
                    ...data?.address,
                },
                role: data?.role,
                userType: data?.userType,
            });
            if (data?.businessName) document.title = data?.businessName;
        }
        if (error) {
            showError(error);
        }

        return () => {
            document.title = "SMECredit";
        };
    }, [data, error]);

    return { isLoading, methods, refetch, formData, prospectiveUser: data };
};

export default useSetProspectiveDefaultValues;
