import { INPUT_TYPE } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "sales",
        label: "Sales/Income",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        defaultValue: "",
    },
    {
        name: "hasFiledIncomeTaxReturn",
        label: "Income Tax Return",
        inputType: INPUT_TYPE.RADIO,
        editable: true,
        options: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        defaultValue: "",
        subtitle: "Filed Income Tax Return ?",
    },
    {
        name: "incomeTaxPassword",
        label: "Income Tax Password",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "e-Filing Portal",
    },
    {
        name: "hasProfitLossStatement",
        label: "Profit and Loss Statement",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        defaultValue: "",
    },
    {
        name: "hasBalanceSheet",
        label: "Balance Sheet",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        defaultValue: "",
    },
    {
        name: "authorized",
        label: "Authorized",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        defaultValue: "",
    },
    {
        name: "paidUp",
        label: "Paid Up",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        defaultValue: "",
    },
    {
        name: "obligation",
        label: "Obligation",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        defaultValue: "",
    },
];

export default fixture;
