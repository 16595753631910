import { Fragment, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Background, Kyc, Personal, PersonalAddress, ResidentialAddress } from "..";
import DeselectFileModal from "../../../Document/components/DeselectFileModal";
import CreditBureau from "../CreditBureau";
import LoanFromAccount from "../LoanFromAccount";

const OtherPersons = ({
    prefix,
    entityAadharValidated,
    entityPrefix,
    pane,
}: {
    prefix: string;
    entityAadharValidated: boolean;
    entityPrefix: string;
    pane: string;
}) => {
    const isDisabled = useWatch({
        name: "disabled",
    });

    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });

    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };

    const { fields: loanFormAccountFields } = useFieldArray({
        name: `${prefix}.loanFromAccount`,
    });

    return (
        <fieldset className="fieldset" disabled={isDisabled}>
            <Grid id="entity-form" direction="column" justifyContent="center" alignItems="center">
                <Kyc prefix={prefix} pane={pane} />
                <CreditBureau prefix={prefix} entityPrefix={entityPrefix} setCurrentInput={setCurrentInput} pane={pane} />
                <Personal prefix={prefix} entityAadharValidated={entityAadharValidated} pane={pane} />
                <Background prefix={prefix} pane={pane} />
                <ResidentialAddress prefix={prefix} pane={pane} />
                <PersonalAddress prefix={prefix} pane={pane} />
                <Fragment>
                    {loanFormAccountFields?.length === 0 && (
                        <Grid container item xs={12} className="collapsed-box">
                            <Grid item xs={12} container justifyContent="space-between" alignItems="center">
                                <Typography variant="h5" className="status-text">
                                    Loans detected from Credit Bureau
                                </Typography>
                                <Typography variant="h6" className="status-heading">
                                    Pending on User
                                </Typography>
                            </Grid>
                        </Grid>
                    )}
                    {loanFormAccountFields?.map((loanFormAccountField, index) => {
                        return <LoanFromAccount prefix={`${prefix}.loanFromAccount`} index={index} pane={pane} />;
                    })}
                </Fragment>
                <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
            </Grid>
        </fieldset>
    );
};

export default OtherPersons;
