import React from "react";
import Grid from "@mui/material/Grid";
import BankAccountItem from "./BankAccountItem";

const BankAccount = ({
    prefix,
    setCurrentInput,
    index,
    entityType,
    entityIndex,
    pane,
}: {
    prefix: string;
    setCurrentInput: any;
    index: number;
    entityType: string;
    entityIndex: number;
    pane?: string;
}) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <BankAccountItem
                index={index}
                prefix={prefix}
                setCurrentInput={setCurrentInput}
                entityType={entityType}
                entityIndex={entityIndex}
                pane={pane}
            />
        </Grid>
    );
};

export default BankAccount;
