import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { CommonLabel } from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useCreditBureauReport, useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";

const CreditBureau = ({
    prefix,
    loanAccountCreditPrefix,
    setCurrentInput,
    pane,
}: {
    prefix: string;
    loanAccountCreditPrefix: string;
    setCurrentInput: any;
    pane?: string;
}) => {
    const creditBureau = useWatch({ name: `${prefix}entity.creditBureau` });
    const errors = useWatch({ name: `errors.${prefix}entity.creditBureau` });
    const { setValue, getValues } = useFormContext();
    const isDisabled = localStorage.getItem("disable_form") === "true" || !creditBureau?.concentShared;

    const reportLinks = [
        {
            label: "Equifax",
            value: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
        },
        {
            label: "Experian",
            value: "https://consumer.experian.in/ECV-OLN/view/angular/#/",
        },
        {
            label: "CRIF High Mark",
            value: "https://cir.crifhighmark.com/free-credit-report/freeflow",
        },
    ];

    useEffect(() => {
        setValue(`${prefix}entity.creditBureau.creditReportLink`, reportLinks.find((item) => item.label === creditBureau?.bureauName)?.value ?? "");
    }, [creditBureau?.bureauName]);

    const activeFields: string[] = ["bureauName", "report", "cibilScore"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}entity.creditBureau.${item}`) === "" || getValues(`${prefix}entity.creditBureau.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}entity.creditBureau.errorsCounts`, errorsCounts);
    }, [creditBureau]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title={`Credit Bureau`}
            tab="entity"
            data-testid={generateTestId(`credit-bureau-${pane}`, "accordion")}
            keys={`${loanAccountCreditPrefix}.creditBureau`}
        >
            <Grid container id="income-form" direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12} container alignItems={"center"}>
                    <Checkbox
                        size="small"
                        onChange={(e) => {
                            setValue(`${prefix}entity.creditBureau.concentShared`, e.target.checked);
                        }}
                        checked={creditBureau?.concentShared}
                        id={generateTestId(`credit-bureau-${pane}`, "consent")}
                    />
                    <Typography
                        variant="h6"
                        style={{
                            fontSize: "0.85rem",
                        }}
                        component="label"
                        htmlFor={generateTestId(`credit-bureau-${pane}`, "consent")}
                        data-testid={generateTestId(`consent-${pane}`, "field")}
                    >
                        <span data-testid={generateTestId(`consent-${pane}`, "field-label")}>
                            By clicking this checkbox, you consent to share your credit information with us and our data partners, and you allow us to
                            request your credit information from credit bureaus
                        </span>
                    </Typography>
                </Grid>
                <CommonFields
                    inputType={INPUT_TYPE.SELECT}
                    name={`${prefix}entity.creditBureau.bureauName`}
                    options={[
                        {
                            label: "Equifax",
                            value: "Equifax",
                        },
                        {
                            label: "Experian",
                            value: "Experian",
                        },
                        {
                            label: "CRIF High Mark",
                            value: "CRIF High Mark",
                        },
                        {
                            label: "Cibil",
                            value: "Cibil",
                        },
                    ]}
                    label="Bureau Name"
                    data-testid={generateTestId(`bureau-name-${pane}`, "field")}
                    readOnly={isDisabled}
                />
                <Grid className={"common__mui__textfield__container"} container direction="row" justifyContent="center" alignItems="center">
                    <CommonLabel
                        showLabel={true}
                        label={"Credit Report Link"}
                        infoText={""}
                        data-testid={generateTestId(`credit-report-link-${pane}`, "field-label")}
                        labelSubtitle={"Register and download your personal credit report from this link"}
                    />
                    <Grid item xs={6}>
                        {isDisabled ? (
                            <Typography
                                variant="h6"
                                component={"button"}
                                style={{
                                    fontSize: "0.9rem",
                                    color: "gray",
                                }}
                                data-testid={generateTestId(`credit-report-link-${pane}`, "field")}
                                disabled={true}
                            >
                                Get Free {creditBureau?.bureauName ?? "Equifax"} Credit Report
                            </Typography>
                        ) : (
                            <a
                                style={{
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    color: "#3f51b5",
                                    textDecoration: "underline",
                                }}
                                data-testid={generateTestId(`credit-report-link-${pane}`, "field")}
                                href={reportLinks.find((item) => item.label === creditBureau?.bureauName)?.value}
                                target="_blank"
                            >
                                Get Free {creditBureau?.bureauName ?? "Equifax"} Credit Report
                            </a>
                        )}
                    </Grid>
                </Grid>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        label="Upload Report"
                        labelSubtitle="Upload the report you generated at the previous link"
                        data-testid={generateTestId(`upload-report-${pane}`, "field")}
                        value={creditBureau?.report}
                        readOnly={isDisabled}
                        name={`${prefix}entity.creditBureau.report`}
                        handleChange={useCreditBureauReport({
                            name: "report",
                            prefix: `${prefix}entity`,
                            entityPrefix: prefix,
                            fileAddress: `${prefix}entity.creditBureau.report`,
                            type: creditBureau?.bureauName ?? "Equifax",
                        })}
                        showCross={localStorage.getItem("disable_form") !== "true" && creditBureau?.report && creditBureau?.report?.link !== ""}
                        handleFileModalOpen={() =>
                            setCurrentInput({ name: `${prefix}entity.creditBureau.report`, open: true, multiple: false, position: 0 })
                        }
                    />
                </Grid>
                <Divider className="divider mt-3" />
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <CommonFields
                        inputType={INPUT_TYPE.NUMBER}
                        data-testid={generateTestId(`cibil-score-${pane}`, "field")}
                        label="Cibil Score"
                        showLabel={true}
                        value={creditBureau?.cibilScore}
                        subtitle="Current Cibil Score"
                        readOnly={isDisabled}
                        name={`${prefix}entity.creditBureau.cibilScore`}
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.FILE}
                        data-testid={generateTestId(`cibil-score-screenshot-${pane}`, "field")}
                        label="Cibil Score Screenshot"
                        showLabel={true}
                        value={creditBureau?.cibilReport}
                        readOnly={isDisabled}
                        name={`${prefix}entity.creditBureau.cibilReport`}
                        handleChange={useUploadFile({
                            name: "cibilReport",
                            fileAddress: `${prefix}entity.creditBureau.cibilReport`,
                            typeAccepted: ["image/jpg", "image/jpeg", "image/png"],
                        })}
                        showCross={
                            localStorage.getItem("disable_form") !== "true" && creditBureau?.cibilReport && creditBureau?.cibilReport?.link !== ""
                        }
                        handleFileModalOpen={() =>
                            setCurrentInput({ name: `${prefix}entity.creditBureau.cibilReport`, open: true, multiple: false, position: 0 })
                        }
                    />
                </Grid>
            </Grid>
        </CommonAccordion>
    );
};

export default CreditBureau;
