import { env } from "../../env";
import { getGenericPostRequest, getGenericGetRequest, buildURLs, buildKeys } from "../utils";

const BASE_URLS = {
    //GET requests

    // Post requests
    VERIFY_AADHAR: "verify/aadhaar",
    VERIFY_PAN: "verify/pan",
    VERIFY_RC: "verify/rc",
    VERIFY_GST: "verify/gst",
    GET_COMPANIES: "search/companies",
    GET_FORM: "form",
};

let prefix;
if (env.REACT_APP_API_URL) {
    prefix = `${env.REACT_APP_API_URL}`;
} else {
    prefix = `${env.REACT_APP_API_URL}`;
}

const URLS = buildURLs({
    urls: BASE_URLS,
    prefix: `${prefix}financial/`,
});

// Export all generic functions
// GET requests
export const getForm = getGenericGetRequest(URLS.GET_FORM);

// POST requests
export const verifyAadhar = getGenericPostRequest(URLS.VERIFY_AADHAR);
export const verifyPan = getGenericPostRequest(URLS.VERIFY_PAN);
export const verifyRc = getGenericPostRequest(URLS.VERIFY_RC);
export const verifyGst = getGenericPostRequest(URLS.VERIFY_GST);
export const getCompanies = getGenericPostRequest(URLS.GET_COMPANIES);

// PUT requests

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
