import { useEffect } from "react";
import { RegisterOptions, useFieldArray, useFormContext } from "react-hook-form";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import { Button, Stack } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { combineClassNames } from "utils";
import CommonLabel from "../CommonLabel";

type Props = {
    textFieldVariant?: "filled" | "outlined" | "standard";
    shouldAddMarginTop: boolean;
    subtitle: string;
    reactHookFormRegisterOptions?: RegisterOptions;
    label: string;
    showLabel: boolean;
    infoText: string;
    labelSubtitle?: string;
    name: string;
    isValidate?: boolean;
    handleValidate?: () => void;
    className?: string;
    verified?: boolean;
    readOnly?: boolean;
    multiline: boolean;
    placeholder: string;
    disableReactHookForm?: boolean;
    value?: any;
    adorment?: any;
    onlyNumbers: boolean;
    "data-testid"?: string;
};

const CommonMultipleTextField = ({
    textFieldVariant = "outlined",
    shouldAddMarginTop,
    subtitle,
    reactHookFormRegisterOptions,
    label,
    showLabel,
    infoText,
    labelSubtitle,
    name,
    isValidate,
    handleValidate,
    className,
    verified,
    readOnly,
    multiline,
    placeholder,
    disableReactHookForm,
    value,
    adorment,
    onlyNumbers,
    "data-testid": dataTestId,
}: Props) => {
    const { register, getValues, control } = useFormContext();

    const { fields, append, remove } = useFieldArray({
        control,
        name: name,
    });

    const isStandardTextField = textFieldVariant === "standard";

    const inlineStyles: any = {};

    if (!shouldAddMarginTop) {
        inlineStyles.marginTop = 0;
    }

    if (subtitle) {
        inlineStyles.marginBottom = "1.5rem";
    }

    const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly || verified;

    useEffect(() => {
        if (fields.length === 0) append("");
    }, [fields]);

    return (
        <Grid
            className={isStandardTextField ? "" : "common__mui__textfield__container"}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={inlineStyles}
        >
            <CommonLabel
                showLabel={showLabel}
                label={label}
                infoText={infoText}
                labelSubtitle={labelSubtitle}
                required={!!reactHookFormRegisterOptions?.required}
                data-testid={`${dataTestId}-label`}
            />
            <Grid item xs={showLabel ? 6 : 12}>
                <Stack rowGap={1}>
                    {fields.map((field, index: number) => (
                        <Stack>
                            <FormControl key={field.id} fullWidth>
                                <TextField
                                    variant={textFieldVariant}
                                    fullWidth
                                    multiline={multiline}
                                    inputProps={{
                                        "data-testid": dataTestId,
                                        className: combineClassNames(className),
                                        ...reactHookFormRegisterOptions,
                                    }}
                                    placeholder={placeholder}
                                    disabled={isDisabled}
                                    type="text"
                                    helperText={
                                        (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true
                                            ? "This field is required"
                                            : ""
                                    }
                                    error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}
                                    InputProps={{
                                        className: combineClassNames("common-textfield", className),
                                        ...(textFieldVariant !== "outlined"
                                            ? {
                                                  disableUnderline: isStandardTextField,
                                              }
                                            : {}),
                                        endAdornment: adorment || undefined,
                                    }}
                                    className={combineClassNames(
                                        "common__mui__textfield",
                                        isDisabled ? "bg-shade" : undefined,
                                        isStandardTextField ? "border-none" : "",
                                        (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true
                                            ? "error"
                                            : ""
                                    )}
                                    onKeyPress={(event) => {
                                        if (onlyNumbers) {
                                            const isAllowedKey = /\d/.test(event.key);
                                            if (!isAllowedKey) {
                                                event.preventDefault();
                                            }
                                        }
                                    }}
                                    {...register(`${name}.${index}`)}
                                />
                                <FormHelperText
                                    className={combineClassNames("common__helper-text", isValidate ? "validate" : "", verified ? "verified" : "")}
                                    onClick={handleValidate}
                                >
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        {verified ? (
                                            <Grid item xs="auto" container>
                                                {" "}
                                                <CheckCircleIcon className="Icon validate-icon" />{" "}
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                        <Grid item xs={true}>
                                            <span>{subtitle}</span>
                                        </Grid>
                                    </Grid>
                                </FormHelperText>
                            </FormControl>
                            {index > 0 && (
                                <Button
                                    style={{
                                        position: "absolute",
                                        left: "100%",
                                        width: "1rem",
                                    }}
                                    color="error"
                                    data-testid={`deleteEquipment.${index}`}
                                    disabled={localStorage.getItem("disable_form") === "true"}
                                    onClick={() => remove(index)}
                                >
                                    <DeleteForeverOutlinedIcon />
                                </Button>
                            )}
                        </Stack>
                    ))}
                    <Button
                        onClick={() => append("")}
                        className="common-fullwidth-button"
                        disabled={localStorage.getItem("disable_form") === "true"}
                        style={{ marginBottom: "1rem" }}
                        data-testid="add-another-principal-button"
                    >
                        <AddCircleOutlineRoundedIcon fontSize="small" className="AddEquipmentIcon" />
                        <span>Add Another Principal</span>
                    </Button>
                </Stack>
            </Grid>
        </Grid>
    );
};

export default CommonMultipleTextField;
