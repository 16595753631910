import { useState } from "react";
import TextField from "@mui/material/TextField";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";

type Props = { "data-testid"?: string } & Omit<DatePickerProps<any, any>, "renderInput">;

const CommonDatePicker = (props: Props) => {
    const [open, setOpen] = useState(false);
    const { "data-testid": dataTestId } = props;

    return (
        <DatePicker
            open={props?.disabled ? false : open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            data-testid={dataTestId}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        onClick={(e) => {
                            e.preventDefault();
                            setOpen(true);
                        }}
                        disabled={true}
                        inputProps={{
                            readOnly: true,
                            style: {
                                cursor: "pointer",
                            },
                        }}
                        style={{
                            cursor: "pointer",
                        }}
                    />
                );
            }}
            {...props}
        />
    );
};

export default CommonDatePicker;
