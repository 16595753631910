import React, { useState } from "react";
import AccordianContext from "./context";

const defaultValue = {
    requirement: "",
    entity: "",
    income: "",
    guarantor: "",
    personal: "",
    document: "",
};

const AccordionProvider = ({ children }: { children: JSX.Element }) => {
    const [currentTab, setCurrentTab] = useState(defaultValue);

    return (
        <AccordianContext.Provider
            value={{
                currentTab,
                setCurrentTab,
            }}
        >
            {children}
        </AccordianContext.Provider>
    );
};

export default AccordionProvider;
