import { Fragment, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import CommonFields from "components/CommonFields";
import { BANK_NAMES, INPUT_TYPE } from "globalConstants";
import useBSAReport from "hooks/useBsaReport";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const BankAccountItem = ({
    index,
    prefix,
    setCurrentInput,
    entityType,
    entityIndex,
    pane,
}: {
    index: number;
    prefix: string;
    setCurrentInput: any;
    entityType: string;
    entityIndex: number;
    pane?: string;
}) => {
    const loan = useWatch({ name: `${prefix}.bankAccount.${index}` });
    const entity = useWatch({ name: `${prefix.replace(".document.business", "")}.entity` });
    let shouldUpload = true;
    let errorText = "";

    for (const key of ["accountNumber", "bankName"]) {
        if (!loan[key]) {
            errorText += ", " + convertCamelCaseToTitleCase(key);
            shouldUpload = false;
        }
    }

    for (const key of ["entityName", "pan"]) {
        if (key === "pan" && !entity[key]) {
            errorText += ", " + "PAN";
            shouldUpload = false;
        }
        if (!entity[key] && key === "entityName") {
            errorText += ", " + convertCamelCaseToTitleCase(key);
            shouldUpload = false;
        }
    }

    if (errorText) errorText += " required";

    const { setValue } = useFormContext();

    useEffect(() => {
        if (loan?.bankName !== "") {
            let bankCode = BANK_NAMES.find((bank) => bank.name === loan.bankName)?.code;
            setValue(`${prefix}.bankAccount.${index}.bankCode`, bankCode);
        }
    }, [loan?.bankName]);

    return (
        <Fragment>
            {fixture.map(({ name, inputType, options, subtitle, label, readOnly, placeholder, showMask }, num) => {
                return (
                    <CommonFields
                        key={num}
                        showMask={showMask}
                        inputType={inputType}
                        data-testid={generateTestId(`${label}-bank-account-${index + 1}-${pane}`, "field")}
                        name={`${prefix}.bankAccount.${index}.${name}`}
                        placeholder={placeholder}
                        subtitle={subtitle}
                        readOnly={localStorage.getItem("disable_form") === "true" || readOnly}
                        options={
                            options?.map((value) => ({
                                value: value.toUpperCase(),
                                label: value,
                            })) || []
                        }
                        label={label}
                    />
                );
            })}
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Loan Account Statement"
                data-testid={generateTestId(`Loan Account Statement-bank-account-${index + 1}-${pane}`, "field")}
                readOnly={localStorage.getItem("disable_form") === "true" || !shouldUpload}
                name={`${prefix}.bankAccount.${index}.statement`}
                multiple={true}
                onClick={() => {
                    if (!shouldUpload) {
                        toast.dismiss();
                        toast.error(errorText?.slice(2));
                    }
                }}
                handleChange={useBSAReport({
                    name: "statement",
                    fileAddress: `${prefix}.bankAccount.${index}.statement`,
                    multiple: true,
                    prefix,
                })}
                labelSubtitle="Last 12 months"
                value={loan?.statement}
                showCross={localStorage.getItem("disable_form") !== "true" && loan?.statement && loan?.statement?.[0]?.link !== ""}
                handleFileModalOpen={({ position }) =>
                    setCurrentInput({ name: `${prefix}.bankAccount.${index}.statement`, open: true, multiple: true, position: position })
                }
            />
        </Fragment>
    );
};

export default BankAccountItem;
