import { Grid } from "@mui/material";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const SocialMedia = () => {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CommonAccordion title="Social Media" data-testid={generateTestId(`FI Details`, "accordion")} tab="contact">
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue }, index) => {
                        return (
                            <CommonFields
                                key={index}
                                inputType={inputType}
                                name={`contact.socialMedia.${name}`}
                                options={
                                    options?.map((value) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                label={label}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                subtitle={subtitle}
                            />
                        );
                    })}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default SocialMedia;
