import { Fragment, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { useCityStateValue } from "hooks";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

interface Props {
    prefix?: string;
    pane?: string;
}

const PersonalAddress = ({ prefix, pane }: Props) => {
    const perAddress = useWatch({
        name: `${prefix}.permanentAddress`,
    });
    const errors = useWatch({
        name: `errors.${prefix}.permanentAddress`,
    });

    const { setValue, getValues } = useFormContext();

    const { isLoading } = useCityStateValue({
        pincode: perAddress?.postalCode,
        cityName: `${prefix}.permanentAddress.city`,
        stateName: `${prefix}.permanentAddress.state`,
    });

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.permanentAddress.${item}`) === "" || getValues(`${prefix}.permanentAddress.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.permanentAddress.errorsCounts`, errorsCounts);
    }, [perAddress]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="Permanent Address"
            tab="personal"
            keys={`${prefix}.personalAddress`}
            data-testid={generateTestId(`permanent-address-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(({ name, inputType, defaultValue, options, subtitle, label, showMask, editable, dataTestId }, index) => {
                    let ownerDetails = ["prefix", "firstName", "middleName", "lastName"];
                    if (perAddress?.isSameAsResidentialAddress === "true" || perAddress?.isSameAsResidentialAddress === true) {
                        if (
                            name === "address1" ||
                            name === "address2" ||
                            name === "address3" ||
                            name === "landmark" ||
                            name === "postalCode" ||
                            name === "city" ||
                            name === "state" ||
                            name === "lengthOfStayInYears" ||
                            name === "lengthOfCityStayInYears" ||
                            name === "ownership" ||
                            name === "ownerDetails.prefix" ||
                            name === "ownerDetails.firstName" ||
                            name === "ownerDetails.middleName" ||
                            name === "ownerDetails.lastName" ||
                            name === "rentType" ||
                            name === "monthlyRent" ||
                            name === "relation"
                        ) {
                            return "";
                        }
                    }

                    if (perAddress?.ownership === "Owned by self") {
                        if (
                            name === "ownerDetails.prefix" ||
                            name === "ownerDetails.firstName" ||
                            name === "ownerDetails.middleName" ||
                            name === "ownerDetails.lastName" ||
                            name === "rentType" ||
                            name === "monthlyRent" ||
                            name === "relation"
                        ) {
                            return "";
                        }
                    }

                    if (perAddress?.ownership === "Owned by relation") {
                        if (
                            name === "ownerDetails.prefix" ||
                            name === "ownerDetails.firstName" ||
                            name === "ownerDetails.middleName" ||
                            name === "ownerDetails.lastName" ||
                            name === "rentType" ||
                            name === "monthlyRent"
                        ) {
                            return "";
                        }
                    }
                    if (perAddress?.ownership === "Rented") {
                        if (
                            name === "ownerDetails.prefix" ||
                            name === "ownerDetails.firstName" ||
                            name === "ownerDetails.middleName" ||
                            name === "ownerDetails.lastName" ||
                            name === "relation"
                        )
                            return "";
                    }
                    if (ownerDetails?.includes(name)) {
                        name = `ownerDetails.${name}`;
                    }

                    activeFields.push(name);

                    return (
                        <Fragment key={index}>
                            <CommonFields
                                inputType={inputType}
                                name={`${prefix}.permanentAddress.${name}`}
                                showMask={showMask}
                                data-testid={generateTestId(`${dataTestId || label}-permanent-${pane}`, "field")}
                                subtitle={subtitle}
                                options={
                                    options?.map((option) => ({
                                        value: option.value,
                                        label: option.label,
                                    })) || []
                                }
                                label={label}
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                adorment={
                                    isLoading && (name === "city" || name == "state") ? (
                                        <CircularProgress size={20} style={{ marginRight: "1rem" }} />
                                    ) : (
                                        <></>
                                    )
                                }
                                {...(name === "isSameAsResidentialAddress" && {
                                    reactHookFormRegisterOptions: {
                                        setValueAs: (value) => {
                                            return value == "true" || value == true;
                                        },
                                    },
                                })}
                            />
                        </Fragment>
                    );
                })}
            </Grid>
        </CommonAccordion>
    );
};

export default PersonalAddress;
