import { RegisterOptions, useFormContext } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { combineClassNames } from "utils";
import generateTestId from "utils/generateTestId";
import CommonLabel from "../CommonLabel";

type Props = {
    textFieldVariant?: "filled" | "outlined" | "standard";
    shouldAddMarginTop: boolean;
    subtitle: string;
    reactHookFormRegisterOptions?: RegisterOptions;
    label: string;
    showLabel: boolean;
    infoText: string;
    labelSubtitle?: string;
    name: string;
    isValidate?: boolean;
    handleValidate?: () => void;
    className?: string;
    verified?: boolean;
    readOnly?: boolean;
    multiline: boolean;
    placeholder: string;
    disableReactHookForm?: boolean;
    value?: any;
    adorment?: any;
    onlyNumbers: boolean;
    "data-testid"?: string;
    showMask?: boolean;
};

const CommonTextField = ({
    textFieldVariant = "outlined",
    shouldAddMarginTop,
    subtitle,
    reactHookFormRegisterOptions,
    label,
    showLabel,
    infoText,
    labelSubtitle,
    name,
    isValidate,
    handleValidate,
    className,
    verified,
    readOnly,
    multiline,
    placeholder,
    disableReactHookForm,
    value,
    adorment,
    onlyNumbers,
    "data-testid": dataTestId,
    showMask,
}: Props) => {
    const { register, getValues } = useFormContext();

    const isStandardTextField = textFieldVariant === "standard";

    const inlineStyles: any = {};

    if (!shouldAddMarginTop) {
        inlineStyles.marginTop = 0;
    }

    if (subtitle) {
        inlineStyles.marginBottom = "1.5rem";
    }

    const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly || verified;

    const registerObject = disableReactHookForm
        ? { value }
        : register(name, {
              ...reactHookFormRegisterOptions,
          });

    let extraText = "";
    if (
        dataTestId?.includes("equipment") ||
        dataTestId?.includes("vehicle") ||
        dataTestId?.includes("partner") ||
        dataTestId?.includes("director") ||
        dataTestId?.includes("person")
    ) {
        extraText = `-${dataTestId?.split("-").slice(-3, -1).join("-")}`;
    } else if (dataTestId?.includes("asset")) {
        extraText = `-${dataTestId?.split("-").slice(-4, -1).join("-")}`;
    } else extraText = `-${dataTestId?.split("-").slice(-2, -1)[0]}`;

    return (
        <Grid
            className={isStandardTextField ? "" : "common__mui__textfield__container"}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={inlineStyles}
        >
            <CommonLabel
                showLabel={showLabel}
                label={label}
                infoText={infoText}
                labelSubtitle={labelSubtitle}
                required={!!reactHookFormRegisterOptions?.required}
                data-testid={`${dataTestId}-label`}
            />
            <Grid item xs={showLabel ? 6 : 12}>
                <FormControl fullWidth>
                    <TextField
                        variant={textFieldVariant}
                        fullWidth
                        multiline={multiline}
                        inputProps={{
                            "data-testid": dataTestId ?? name,
                            className: combineClassNames(className),
                            ...reactHookFormRegisterOptions,
                        }}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        type={showMask ? "password" : "text"}
                        helperText={
                            (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true
                                ? "This field is required"
                                : ""
                        }
                        error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}
                        InputProps={{
                            className: combineClassNames("common-textfield", className),
                            ...(textFieldVariant !== "outlined"
                                ? {
                                      disableUnderline: isStandardTextField,
                                  }
                                : {}),
                            endAdornment: adorment || undefined,
                        }}
                        className={combineClassNames(
                            "common__mui__textfield",
                            isDisabled ? "bg-shade" : undefined,
                            isStandardTextField ? "border-none" : "",
                            (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true ? "error" : ""
                        )}
                        onKeyPress={(event) => {
                            if (onlyNumbers) {
                                const isAllowedKey = /\d/.test(event.key);
                                if (!isAllowedKey) {
                                    event.preventDefault();
                                }
                            }
                        }}
                        {...registerObject}
                    />
                    <FormHelperText
                        className={combineClassNames("common__helper-text", isValidate ? "validate" : "", verified ? "verified" : "")}
                        data-testid={generateTestId(`${subtitle}${extraText}`, `button`)}
                        onClick={handleValidate}
                    >
                        <Grid container direction="row" justifyContent="center" alignItems="center">
                            {verified ? (
                                <Grid item xs="auto" container>
                                    {" "}
                                    <CheckCircleIcon className="Icon validate-icon" />{" "}
                                </Grid>
                            ) : (
                                ""
                            )}
                            <Grid item xs={true}>
                                <span>{subtitle}</span>
                            </Grid>
                        </Grid>
                    </FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default CommonTextField;
