import { useWatch } from "react-hook-form";
import { defaultCoborrowerFixture } from "fixtures";
import { useFieldArrayWithAutoAddAndRemove } from "hooks";

const useCoborrowerFields = () => {
    const coborrowerValue = useWatch({
        name: `requirement.hasCoBorrower`,
    });

    const hasCoBorrower = coborrowerValue === true || coborrowerValue === true ? true : false;

    const { fields } = useFieldArrayWithAutoAddAndRemove({
        name: `coborrower`,
        countName: `requirement.numberOfCoBorrower`,
        fixture: defaultCoborrowerFixture,
    });

    return hasCoBorrower === true ? fields : [];
};

export default useCoborrowerFields;
