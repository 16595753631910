import BusinessTab from "./components/BusinessTab";
import CoborrowerBusinessTab from "./components/CoborrowerBusinessTab";
import PersonalTab from "./components/PersonalTab";
import PostDisbursalTab from "./components/PostDisbursalTab";

interface Props {
    entityType: string;
    index: number;
    title: string;
    type?: string;
}
const DocumentPane = ({ entityType, index, type, title }: Props) => {
    const prefix = `${entityType}${entityType === "applicant" ? "" : "." + index}.document`;

    switch (entityType) {
        case "applicant":
            if (type === "business") return <BusinessTab prefix={`${prefix}.business`} entityType={entityType} index={index} pane={title} />;
            if (type === "personal") return <PersonalTab prefix={`${prefix}.personal`} entityType={entityType} index={index} pane={title} />;
            if (type === "postDisbursal") return <PostDisbursalTab prefix={`${prefix}.postDisbursal`} pane={title} />;
            break;
        case "coborrower":
            if (type === "business") {
                return <CoborrowerBusinessTab prefix={`${prefix}.business`} entityType={entityType} index={index} pane={title} />;
            }
            return <PersonalTab prefix={`${prefix}.personal`} entityType={entityType} index={index} pane={title} />;
        case "guarantor":
            if (type === "business") {
                return <CoborrowerBusinessTab prefix={`${prefix}.business`} entityType={entityType} index={index} pane={title} />;
            }
            return <PersonalTab prefix={`${prefix}.personal`} entityType={entityType} index={index} pane={title} />;
    }
    return <h1>404</h1>;
};

export default DocumentPane;
