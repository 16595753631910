import { Fragment, memo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Grid, Typography } from "@mui/material";
import CommonAccordion from "components/CommonAccordion";
import { HandleNextDialog } from "components/Dialog";
import moment from "moment";
import generateTestId from "utils/generateTestId";
import "./FollowUp.styles.scss";
import FollowUpDate from "./FollowUpDate";

type Props = {};

const FollowUp = ({ setCurrentStep, refetch }: { setCurrentStep?: any; refetch?: any }) => {
    const [open, setOpen] = useState(false);
    const { control } = useFormContext();

    const handleProceed = () => {
        setCurrentStep(1);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    const { fields } = useFieldArray({
        control,
        name: "followUpDate",
    });

    return (
        <Fragment>
            <Grid container id="follow-up" data-testid="follow-up" direction="column" justifyContent="center" alignItems="center">
                <CommonAccordion
                    title="New Follow Up Date"
                    data-testid={generateTestId(`new-follow-up-date`, "accordion")}
                    tab="follow-up"
                    className="common-accordion-field entity-container"
                >
                    <FollowUpDate index={0} isNew refetch={refetch} />
                </CommonAccordion>
                {fields.map((field: any, index: number) => {
                    return (
                        <CommonAccordion
                            key={field.id}
                            keys={`${index}-follow-up-date`}
                            title=""
                            data-testid={generateTestId(`follow-up-date-${index + 1}`, "accordion")}
                            titleReactNode={
                                <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                    <Typography className="common-custom-label">{moment(field?.date).format("DD-MM-YYYY")}</Typography>
                                </Grid>
                            }
                            tab="follow-up"
                            className="common-accordion-field entity-container"
                        >
                            <FollowUpDate index={index} />
                        </CommonAccordion>
                    );
                })}
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                {/* <Button
                    variant="contained"
                    className="btn-arrow"
                    onClick={() => {
                        // let shouldProceed = true;
                        // for (let i in errors) {
                        //     if (i === "equipments" && errors[i].some((item: any) => item?.errorsCounts > 0)) shouldProceed = false;
                        //     else if ((i === "coborrowerError" || i === "guarantorError") && errors[i] > 0) {
                        //         shouldProceed = false;
                        //     } else if (errors[i]?.errorsCounts > 0) {
                        //         shouldProceed = false;
                        //     }
                        // }
                        // if (shouldProceed) handleProceed();
                        // else {
                        //     setValue("showIncompleteField", true);
                        //     setOpen(true);
                        // }
                    }}
                >
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button> */}
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(FollowUp, () => true);
