import   './PrivacyPolicy.scss'
import Navbar from 'components/Navbar';
import Footer from 'components/Footer';

const Privacy = () => {
    return      <>
    <Navbar /> <div className="privacy-page">
     <p className="" >

 <h1 className='header' >   Privacy Policy    </h1>
 Your privacy is important to us.  
This privacy policy (“Policy”) has been drafted in accordance with the Information Technology
Act, 2000 and the Information Technology (Reasonable Security Practices and Procedures and
Sensitive Personal Data or Information) Rules, 2011. <br></br> <br></br> 
SMECreditt Private Limited (hereinafter “SMECreditt” “Us”, “Our” “We”, which shall mean and
include its affiliates, successors, and assigns) facilitates the provision of financial products to
various customers through financial institutions who have partnered with SMECreditt (“Service”).
This policy describes our policies and procedures for collection, use, storage, processing,
disclosure, and protection of any information, including, but not limited to, business or personal
information provided by you as a user (hereinafter referred to as “you” “your” or “user”) while
availing the services. user shall mean any person/ persons, who avails the services of SMECreditt.
This policy constitutes a legal agreement between you, as the user and SMECreditt. you must be a
natural person, citizen of India, who is at least 18 years of age and eligible to enter into a binding
contract under the Indian contract act, 1872. <br></br> <br></br> 
By availing the services and voluntarily providing us with information (personal and/ or nonpersonal), you are consenting to our use of it in accordance with this policy. if you do not agree
with the terms and conditions of this policy, please do not proceed further to avail the services.
Use of your information by us for provision of services will be governed by this policy together
with all policies, notices, guidelines, disclaimers that are shared with you from time to time and
such other terms as may be applicable to you in your capacity as a user. this policy shall be
enforceable against you in the same manner as any other written agreement. <br></br> <br></br> 
We may update this policy from time to time. you must periodically review the policy for the latest
information on our privacy practices. 

<h2>1. Information Collected by SMECreditt  </h2>
Information as described under this Clause shall collectively be referred to as “Information”.
We provide Services and to this end, we collect personal information from you through our onboarded affiliates (“Partners”) who will interact with you for the collection of this information.
The Partners will collect information from you and will transfer it to us for the purpose of
provision of the Services. We shall further transfer the information collected from you to the
financial institutions partnered with us for provision of financial products (“Lending Partners”).
This information is shared/uploaded by you, should you choose to share it with SMECreditt for
the purpose of availing Services. This information comprises of your personal details pertaining to
your name, parentage, marital status, nationality, state of residence, city of residence, email
address, date of birth, gender, contact number/mobile number, demographic information, recent
photograph, signature image, and other Know Your Customer (KYC) documents [address proof,
identity proof, income proof, Permanent Account Number or such other officially valid
documents/details (OVDs)] accepted for concluding a financial transaction.<br></br> <br></br> 
We also collect some non-personal details when you visit our digital properties, we collect the IP
address of the device you use to connect to the Internet, information such as what browser,
browser version, operating system you have, the website you came from or the advertisement you
viewed or clicked on.<br></br> <br></br> 
We use cookies and/or pixel tags to collect information and store your online preferences. Cookies
are widely used, and most browsers are set up to accept them automatically. If you would prefer,
you can choose to not accept cookies. It's important to note that cookies and pixel tags do not
capture any information that can personally identify you.<br></br> <br></br> 
We may also supplement the information we collect with information we receive from other
companies. We may supplement the information we collect about you with outside records from
third parties in order to enhance our ability to serve you, to tailor our content to you and to offer
you opportunities to purchase products or services that we believe may be of interest to you. We
may combine the information we receive from those sources with information we collect through
the Services. In those cases, we will apply this Privacy Policy to any Personal Information received,
unless we have disclosed otherwise.
<h2>2. Process of Collecting Information</h2>
There are certain industry standard technologies we use to collect usage Information. In the
future, other new technology and methods for collecting Usage Information may develop.
<h3>a. Direct user input </h3>
We collect your personal data when you voluntarily provide it to us. For example, you may give us:<br></br> <br></br> 
i. your email address, phone number if you choose to sign up for our service, or subscribe to
our newsletters, updates, or other information from us;<br></br> <br></br> 
ii. your personal identifiable information such as Aadhaar, PAN numbers etc. that you choose
to submit via forms and interactive portions of our websites;<br></br> <br></br> 
iii. your personal financial information and financial information of entities that you
represent that you choose to submit via forms and interactive portions of our websites;<br></br> <br></br> 
iv. your contact information, and any other personal data you choose to include, if you email,
text, or instant message us, or contact us through our websites;<br></br> <br></br> 
v. any personal data contained in, or included with feedback, comments, photos, videos, or
other information you submit via online portals, forms, surveys, or interactive portions of
our websites.
<h3>b. Third party APIs </h3>
We may receive information about you from other sources, including publicly available databases
or third parties from whom we have purchased data, and combine this data with information we
already have about you. This helps us to update, expand and analyse our records, identify new
customers, and provide products and services that may be of interest to you. If you provide us
personal information about others, or if others give us your information, we will only use that
information for the specific reason for which it was provided to us.
<h3>c. Embedded Scripts</h3>
An embedded script is programming code that is designed to collect information about your
interactions with the Services, such as the links you click on. The code is temporarily downloaded
onto your Device from our web server or a third-party service provider, is active only while you
are connected to the Services and is deactivated or deleted thereafter.
<h3>d. Network data</h3>
When you visit our websites, we automatically receive your IP address and information such as
the user-agent of your web browser. We may use such data to administer the website and analyse
aggregate trends. This information is provided to us by your communications network and web
browser.
<h3>e. Web Beacons </h3>
Small graphic images or other web programming code called web beacons may be included in our
web and mobile pages and messages. Web beacons may be invisible to you, but any electronic
image or other web programming code inserted into a web or mobile page, or e-mail can act as a
web beacon. We do not tie web beacons to Personal Information.
<h3>f. Cookies </h3>
You can control whether to accept cookies or not. If you decide to not accept cookies, some
features and services on our site may not work properly because we may not be able to recognize
and associate you. In addition, the offers we provide when you visit us may not be as relevant to
you or tailored to your interests. If you would prefer not to accept cookies, you can:<br></br> <br></br> 
i. Change your browser settings to notify you when you receive a cookie, which lets you
choose whether or not to accept it.<br></br> <br></br> 
ii. Set your browser to automatically not accept any cookies. 
<h3>g. Social Media Widgets </h3>
Our Web site includes Social Media Features, such as the Facebook Like button and Widgets, the
Share this button or interactive mini programs that run on our site. These Features may collect
your IP address, which page you are visiting on our site, and may set a cookie to enable the
Feature to function properly. Social Media Features and Widgets are either hosted by a third party
or hosted directly on our Site. Your interactions with these Features are governed by the privacy
policy of the company providing it.
<h2>3. Purpose of Collection of Information</h2>
SMECreditt processes the Information for the following purposes:<br></br> <br></br> 
i. for the sole purpose of providing the Services, i.e., facilitating the provision of financial
products to you through Lending Partners;<br></br> <br></br> 
ii. for the purpose of complying and assist the Lending Partners in complying with various
laws, regulations, and guidelines (including Know Your Customer (KYC) norms/AntiMoney Laundering (AML) standards/Combating of Financing of Terrorism (CFT) /
Obligation of banks under the Prevention of Money Laundering Act, 2002) promulgated by
the Government of India from time to time by SMECreditt or its associated partners;<br></br> <br></br> 
iii. sending you targeted communications and offer<br></br> <br></br> 
SMECreditt will not sell or rent your Information to anyone, for any reason, at any time. However,
we will be sharing your Information with our affiliates and business partners including credit
information companies, where we feel that you will be assisted better for the purpose of
underwriting and approval of your loan, or any other financial product transaction/related
transaction or for doing an aggregate check of your credit profile on your behalf.
Our third-party partners are under an obligation under the contract executed with us to maintain
confidentiality of your information and not transfer such information without our prior consent.
By availing the Services, you authorize SMECreditt to send texts and email alerts to you and any
other service requirements, including promotional mails and SMSs.<br></br> <br></br> 
Further, it enables SMECreditt to:<br></br> <br></br> 
i. Send you any administrative notices, offer alerts and communications relevant to your use
of the Service;<br></br> <br></br> 
ii. Enable you to apply for certain products and services for which you have chosen to apply;<br></br> <br></br> 
iii. Carry market research, project planning, troubleshooting issues, detecting and protecting
against error, fraud or other criminal activity;<br></br> <br></br> 
iv. Comply with all applicable laws and regulations;
For availing the Service such as applying for a loan, or any other financial product we will collect
details such as your name, parentage, email address, nationality, address, mobile number, PAN,
recent photograph, other Know Your Customer (KYC) documents like address proof, identity
proof, etc. from our Partners.<br></br> <br></br> 
We may also use third party service providers to provide the Service to you, such as sending email messages on our behalf or hosting and operating a particular feature or functionality of the
Service. Our contracts with these third parties outline the appropriate use and handling of your
Information and prohibit them from using any of your Information for purposes unrelated to the
product or service they're providing. We require such third parties to maintain the confidentiality
of the Information provided to them.<br></br> <br></br> 
We further use your Information for the following purposes:<br></br> <br></br> 
i. To perform support services through a third-party service provider, provided it conforms
to the Privacy Policy of the SMECreditt and your prior consent to do so is obtained;<br></br> <br></br> 
ii. You have specifically authorized such use;<br></br> <br></br> 
iii. The disclosure is necessary for compliance of a legal obligation or as required by law, such
as to comply with a subpoena, or similar legal process;<br></br> <br></br> 
iv. The information is shared with Government agencies mandated under law;<br></br> <br></br> 
v. The information is shared with any third party by an order under the law;<br></br> <br></br> 
When we believe in good faith that disclosure is necessary to protect our rights, protect your
safety or the safety of others, investigate fraud, or respond to a government request.<br></br> <br></br> 
You hereby understand that when you apply for a loan on our platform, SMECreditt may check
your records amongst other things with its own records, any information as available to
SMECreditt through any of its sources, those at credit reference agencies (CRAs); those at fraud
prevention agencies (FPAs) for assessing your credit worthiness, verifying identity to prevent and
detect crime and money laundering. SMECreditt also has the right, and obligation, to report your
details to CRAs in the event that you have delayed in repaying your loan instalment.
<h2>4. Targeted Advertising </h2>
We place advertisements where we think they will be most relevant to customers. We believe that
online behavioural advertising helps to provide you with more relevant advertising based upon on
the websites you visit. We place advertisements by developing and using our own customer
segmentation methodologies that may combine online and offline information about our current
and prospective customers. In addition, we may use marketing segments provided by online
publishers and network advertising companies.<br></br> <br></br> 
We contract with third-party advertising networks, publishers, and other entities to advertise our
products and services on websites not affiliated with us. Network advertising companies that
provide these services have their own privacy policies and are not subject to our Online Privacy
Statement.
<h2>5. Do Not Track </h2>
Although there is no standard for how Do Not Track consumer browser settings should work on
commercial websites, the industry has self-regulatory initiatives designed to provide consumers a
choice in the types of ads they may see online and to conveniently opt-out from online behavioural
ads served by some or all of the companies participating in these programs. In order for
behavioural advertising opt-outs to work on your device, your browser must be set to accept
cookies. If you delete cookies, buy a new Device, access our Services from a different device, login
under a different screen name, or change web browsers, you will need to opt-out again. If your
browser has scripting disabled, you do not need to opt out, as online behavioural advertising
technology does not work when scripting is disabled. Please check your browser's security
settings to validate whether scripting is active or disabled.
<h2>6. Cookies </h2>
A cookie is a piece of software code that an internet web site sends to your browser when you
access information at that site. A cookie is stored as a simple text file on your computer or mobile
device by a website’s server and only that server will be able to retrieve or read the contents of
that cookie. Cookies let you navigate between pages efficiently as they store your preferences, and
generally improve your experience of a website. SMECreditt uses following types of cookies to
enhance your experience and interactivity:<br></br> <br></br> 
i. Analytics cookies for anonymously remembering your computer  or mobile device when
you visit our website to keep track of browsing patterns.<br></br> <br></br>
ii. Service cookies for helping us to make our website work efficiently, remembering your
registration and login details, settings preferences, and keeping track of the pages you
view.<br></br> <br></br> 
iii. Non-persistent cookies a.k.a per-session cookies. Per-session cookies serve technical
purposes of providing seamless navigation. These cookies do not collect personal
information on users, and they are deleted as soon as you leave our website. The cookies
do not permanently record data and they are not stored on your computer’s hard drive.
You may note additionally that when you visit our websites, you may be required to accept
cookies. If you choose to have your browser refuse cookies, it is possible that our websites may
not function properly.
<h2>7. Control Your Information Collected Online?</h2>
You have the ability to choose what personal information, including what sensitive personal
information (i.e. your financial information) you provide to us. However, if you choose not to
provide all the information and data that is requested of you, we may not be able to provide you
with the Services that you have subscribed to. You may, at any time while availing of our Services
or otherwise, withdraw the consent given earlier. By writing to support@smecreditt.com.<br></br> <br></br> 
You can review the information that you have provided to us by logging into your account at the
SMECreditt website and correct or amend any personal information or sensitive personal data or
information to ensure that the information or data you provided us is accurate and/or not
deficient. SMECreditt is not responsible for the authenticity of any personal information or
sensitive personal data, or information supplied to it by you or any third party.
<h2>8. How do we protect your information? </h2>
We store and process your data only in India. Our data centre and systems also maintain backup
and disaster recovery systems in India. We are hosted on Public Cloud on Amazon Webservices
(Mumbai Region) which provides a secure and scalable technology platform.
We are an ISO 27001 Certified organization and have Information Security Management System
framework in place that ensures up-to-date physical security, such as secure areas in offices;
electronic safeguards, such as passwords, firewalls, and encryption; and secure development
procedures. Access control systems are in place. Our safety and security processes are audited by
a third-party cyber security audit agency from time to time.<br></br> <br></br> 
SMECreditt algorithms identify the Personally Identifiable Information (PII) fields at the source
itself and do not collect this information or mask it before collection wherever PII data is not
needed. In the scenarios where PII data collection is mandatory, we encrypt the data with the
latest security algorithms before transmitting the data. All such data is encrypted at rest.
Information you and our partners provide and receive during the services is transmitted through
a secure sockets layer (SSL) transmission. We encrypt and store PII data wherever it is possible.
No data is shared with any third party unless until it is a core feature to serve the end-user, in all
such scenarios the user consent is taken.<br></br> <br></br> 
We restrict access to your personal information only to those employees who are required to
know such information in order provide our services to you. We train our employees on all our
security procedures, and we conduct audits to check compliance.<br></br> <br></br> 
No security measures can provide absolute protection of data, but we will strive to protect your
information. If you are a security enthusiast and have found a possible security vulnerability on
our products, we encourage you to report the issue to us responsibly.
<h2>9. Communications </h2>
We provide our registered customers with periodic emailers and email/SMS alerts. We also allow
users to subscribe to email newsletters and from time to time may transmit emails promoting
SMECreditt or third-party products.<br></br> <br></br> 
Subject to the express consent clause above, Service subscribers may opt-out of receiving our
promotional emails and terminate their newsletter subscriptions by following the instructions in
the emails. Opting out in this manner will not end transmission of service-related emails/SMS,
such as email/SMS alerts. The above services are also provided by our partners.
<h2>10. Severability and Exclusion </h2>
We have taken every effort to ensure that this Policy adheres with the applicable laws. The
invalidity or unenforceability of any part of this Policy shall not prejudice or affect the validity or
enforceability of the remainder of this Policy.<br></br> <br></br> 
This Policy shall be inapplicable to any unsolicited information You provide Us through this
Platform or through any other means. All unsolicited information shall be deemed to be nonconfidential, and the Company shall be free to use and/ or disclose such unsolicited information
without any limitations.
<h2>11. Governing Law and Dispute Resolution</h2>
This Policy shall be governed by and construed in accordance with the laws of the Republic of
India. The courts at Delhi, India shall have exclusive jurisdiction in relation to any disputes arising
out of or in connection with this Policy.
<h2>12. No Waiver</h2>
The rights and remedies available under this Policy may be exercised as often as necessary and
are cumulative and not exclusive of rights or remedies provided by law. It may be waived only in
writing. Delay in exercising or non-exercise of any such right or remedy does not constitute a
waiver of that right or remedy, or any other right or remedy.
<h2>13. How do I know about changes to the Privacy Policy </h2>
From time to time, we may change this Online Privacy Statement. The effective date of this
statement, as indicated below,
January 6, 2023
2023-01-06
shows the last time this statement was revised or materially changed. Checking the effective date
below allows you to determine whether there have been changes since the last time you reviewed
the statement.
<h2>14. How long do we retain your Information? </h2>
SMECreditt will not retain your sensitive personal information for no longer than is required for
the purposes for which the information may lawfully be used or is otherwise required under any
other law for the time being in force.<br></br> <br></br> 

    </p>
    </div>
    <Footer/>
     </>
};

export default Privacy;
