import React from "react";
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import LoanAccountCreditItem from "./LoanAccountCreditItem";

const LoanAccountCredit = ({ prefix, setCurrentInput, index }: { prefix: string; setCurrentInput: any; index: number }) => {
    const firstLoan = useWatch({ name: `${prefix}.loanAccountCredit.0` });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <LoanAccountCreditItem index={index} prefix={prefix} setCurrentInput={setCurrentInput} />
            {index === 0 && (
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Emi Receipt"
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    name={`${prefix}.loanAccountCredit.0.emiReceipt`}
                    multiple={true}
                    prop={{
                        value: "Entire Loan Period",
                    }}
                    handleChange={useUploadFile({
                        name: "emiReceipt",
                        fileAddress: `${prefix}.loanAccountCredit.0.emiReceipt`,
                        multiple: true,
                    })}
                    value={firstLoan?.emiReceipt}
                    showCross={localStorage.getItem("disable_form") !== "true" && firstLoan?.emiReceipt && firstLoan?.emiReceipt?.[0]?.link !== ""}
                    handleFileModalOpen={({ position }) => {
                        setCurrentInput({ name: `${prefix}.loanAccountCredit.0.emiReceipt`, open: true, multiple: true, position: position });
                    }}
                />
            )}
        </Grid>
    );
};

export default LoanAccountCredit;
