import { Fragment, useEffect, useState } from "react";
import { useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import CommonDatePicker from "components/CommonDatePicker";
import { CommonLabel } from "components/CommonFields";
import { useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";
import { INPUT_TYPE } from "../../../..";

const ITReturn = ({ prefix, setCurrentInput, pane }: { prefix: string; setCurrentInput: any; pane?: string }) => {
    const { setValue } = useFormContext();

    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const income = useWatch({ name: `${prefix}.income` });
    const businessIncome = useWatch({ name: `applicant.income.income.businessIncome` });
    const employmentIncome = useWatch({ name: `applicant.income.income.employmentIncome` });

    const [years, setYears] = useState<Array<any> | null>(null);

    const handleYearChange = (newValue: Date | null) => {
        let year = newValue?.toISOString().substring(0, 4);
        let year1 = parseInt(year || "") + 1;
        let year3 = parseInt(year || "") - 1;
        setYears([year1, year, year3] || null);
    };

    const handleChange = () => {
        setValue(`${prefix}.income.lastFiledITReturn`, []);
        handleClose();
        setYears(null);
    };

    useEffect(() => {
        if (income?.lastFiledITReturn?.length > 0) {
            let years: any[] = [];
            income?.lastFiledITReturn?.map((item: any) => {
                if (item?.year_index === "1") {
                    years.push(item?.associated_date, Number(item?.associated_date) - 1, Number(item?.associated_date) - 2);
                    return;
                }
                if (item?.year_index === "2") {
                    years.push(Number(item?.associated_date) + 1, item?.associated_date, Number(item?.associated_date) - 1);
                    return;
                }
                if (item?.year_index === "3") {
                    years.push(Number(item?.associated_date) + 2, Number(item?.associated_date) + 1, item?.associated_date);
                    return;
                }
            });
            setYears(years);
        }
    }, []);
    return (
        <Fragment>
            <Grid container className="common__mui__textfield__container" justifyContent="center" alignItems="center">
                <CommonLabel
                    showLabel={true}
                    label="Income Tax Return"
                    labelSubtitle="Last 3 Years"
                    data-testid={generateTestId(`income-tax-return-${pane}`, "field-label")}
                />
                <Grid item xs={window.innerWidth < 400 ? 8 : 6} className="attach__container">
                    {years === null && (
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: "100%" }} className="date__container">
                            <CommonDatePicker
                                className="common__mui__date__container"
                                disabled={
                                    localStorage.getItem("disable_form") === "true" ||
                                    (businessIncome?.hasBusinessIncome === true && businessIncome?.hasFiledIncomeTaxReturn === false) ||
                                    (employmentIncome?.hasEmploymentIncome === true && employmentIncome?.doYouPayIncomeTax === false)
                                }
                                views={["year"]}
                                label="Select Latest Year"
                                value={""}
                                onChange={handleYearChange}
                                disableFuture={true}
                                components={{
                                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                                }}
                                //@ts-ignore
                                OpenPickerButtonProps={{ "data-testid": generateTestId(`select-latest-year-income-tax-return-${pane}`, "button") }}
                            />
                        </LocalizationProvider>
                    )}
                    <TextField
                        type={INPUT_TYPE.FILE}
                        name={`${prefix}.income.lastFiledITReturn.0`}
                        id={"file-select-lastFiledITReturn-0"}
                        style={{ display: "none" }}
                        disabled={localStorage.getItem("disable_form") === "true"}
                        onChange={useUploadFile({
                            name: "lastFiledITReturn",
                            fileAddress: `${prefix}.income.lastFiledITReturn.0`,
                            associatedDate: years?.[0],
                        })}
                        inputProps={{ "data-testid": generateTestId(`${years?.[0]}-income-tax-return-${pane}`, "field-attach") }}
                    />
                    <TextField
                        type={INPUT_TYPE.FILE}
                        name={`${prefix}.income.lastFiledITReturn.1`}
                        style={{ display: "none" }}
                        disabled={localStorage.getItem("disable_form") === "true" || !income.lastFiledITReturn[0]}
                        id={"file-select-lastFiledITReturn-1"}
                        onChange={useUploadFile({
                            name: "lastFiledITReturn",
                            fileAddress: `${prefix}.income.lastFiledITReturn.1`,
                            associatedDate: years?.[1],
                        })}
                        inputProps={{ "data-testid": generateTestId(`${years?.[1]}-income-tax-return-${pane}`, "field-attach") }}
                    />
                    <TextField
                        type={INPUT_TYPE.FILE}
                        name={`${prefix}.income.lastFiledITReturn.2`}
                        style={{ display: "none" }}
                        disabled={localStorage.getItem("disable_form") === "true" || !income.lastFiledITReturn[0]}
                        id={"file-select-lastFiledITReturn-2"}
                        onChange={useUploadFile({
                            name: "lastFiledITReturn",
                            fileAddress: `${prefix}.income.lastFiledITReturn.2`,
                            associatedDate: years?.[2],
                        })}
                        inputProps={{ "data-testid": generateTestId(`${years?.[2]}-income-tax-return-${pane}`, "field-attach") }}
                    />
                    {years !== null && (
                        <>
                            <CloseIcon className="close-icon" onClick={handleOpen} />
                            {income?.lastFiledITReturn?.[0] && income?.lastFiledITReturn?.[0]?.link !== "" ? (
                                <div className="flex w-full">
                                    <p className="year-text">{income?.lastFiledITReturn?.[0]?.associated_date}</p>
                                    <Tooltip arrow placement="top" title={income?.lastFiledITReturn?.[0]?.filename}>
                                        <label className="download-text">{income?.lastFiledITReturn?.[0]?.filename}</label>
                                    </Tooltip>
                                    <CancelIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (localStorage.getItem("disable_form") !== "true")
                                                setCurrentInput({
                                                    name: `${prefix}.income.lastFiledITReturn.0`,
                                                    open: true,
                                                    multiple: false,
                                                    position: 0,
                                                });
                                        }}
                                    />
                                    <FileDownloadIcon
                                        className="download-icon"
                                        onClick={() => {
                                            window.open(income?.lastFiledITReturn?.[0]?.link, "_blank");
                                        }}
                                    />
                                </div>
                            ) : (
                                <label htmlFor={"file-select-lastFiledITReturn-0"} className="attach">
                                    <p className="year">{years?.[0]}</p>
                                    <AttachFileIcon className="icon" />
                                    <span>Attach</span>
                                </label>
                            )}
                            {income?.lastFiledITReturn?.[1] && income?.lastFiledITReturn?.[1]?.link !== "" ? (
                                <div className="flex w-full">
                                    <p className="year-text">{income?.lastFiledITReturn?.[1]?.associated_date}</p>
                                    <Tooltip arrow placement="top" title={income?.lastFiledITReturn?.[1]?.filename}>
                                        <label className="download-text">{income?.lastFiledITReturn?.[1]?.filename}</label>
                                    </Tooltip>
                                    <CancelIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (localStorage.getItem("disable_form") !== "true")
                                                setCurrentInput({
                                                    name: `${prefix}.income.lastFiledITReturn.1`,
                                                    open: true,
                                                    multiple: false,
                                                    position: 0,
                                                });
                                        }}
                                    />
                                    <FileDownloadIcon
                                        className="download-icon"
                                        onClick={() => {
                                            window.open(income?.lastFiledITReturn?.[1]?.link, "_blank");
                                        }}
                                    />
                                </div>
                            ) : (
                                <label htmlFor={"file-select-lastFiledITReturn-1"} className="attach">
                                    <p className="year">{years?.[1]}</p>
                                    <AttachFileIcon className="icon" />
                                    <span>Attach</span>
                                </label>
                            )}
                            {income?.lastFiledITReturn?.[2] && income?.lastFiledITReturn?.[2]?.link !== "" ? (
                                <div className="flex w-full">
                                    <p className="year-text">{income?.lastFiledITReturn?.[2]?.associated_date}</p>
                                    <Tooltip arrow placement="top" title={income?.lastFiledITReturn?.[2]?.filename}>
                                        <label className="download-text">{income?.lastFiledITReturn?.[2]?.filename}</label>
                                    </Tooltip>
                                    <CancelIcon
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            if (localStorage.getItem("disable_form") !== "true")
                                                setCurrentInput({
                                                    name: `${prefix}.income.lastFiledITReturn.2`,
                                                    open: true,
                                                    multiple: false,
                                                    position: 0,
                                                });
                                        }}
                                    />
                                    <FileDownloadIcon
                                        className="download-icon"
                                        onClick={() => {
                                            window.open(income?.lastFiledITReturn?.[2]?.link, "_blank");
                                        }}
                                    />
                                </div>
                            ) : (
                                <label htmlFor={"file-select-lastFiledITReturn-2"} className="attach">
                                    <p className="year">{years?.[2]}</p>
                                    <AttachFileIcon className="icon" />
                                    <span>Attach</span>
                                </label>
                            )}
                        </>
                    )}
                </Grid>
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box
                        sx={{
                            position: "absolute" as "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            width: 400,
                            bgcolor: "#eae7ed",
                            border: "1px solid #000",
                            borderRadius: "10px",
                            boxShadow: 24,
                            p: 3,
                        }}
                    >
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                            <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                        </Typography>
                        <Typography
                            id="modal-modal-title"
                            variant="h6"
                            component="h6"
                            style={{ display: "flex", justifyContent: "center", fontSize: "1.1rem", margin: "10px 0px", color: "#79797a" }}
                        >
                            Do you want to change year ?
                        </Typography>
                        <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                            <Button variant="contained" name="yes" onClick={() => handleChange()}>
                                Yes
                            </Button>
                            <Button
                                variant="contained"
                                name="no"
                                onClick={() => handleClose()}
                                style={{ backgroundColor: "#767676", marginLeft: "10px" }}
                            >
                                No
                            </Button>
                        </Typography>
                    </Box>
                </Modal>
            </Grid>
        </Fragment>
    );
};

export default ITReturn;
