import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "business",
        label: "Business",
        inputType: INPUT_TYPE.SELECT,
        options: ["Sole Proprietor", "Partnership", "Registered Under Company Act", "Individual", OTHER],
    },
    {
        name: "otherBusiness",
        label: "Other",
        inputType: INPUT_TYPE.SELECT,
        options: ["Association", "Body Of Individuals", "Club", "Hindu Undivided Family (HUF)", "Society", "Trust"],
        defaultValue: "Association",
        editable: true,
        dataTestId: "other-business",
    },
    {
        name: "entityName",
        label: "Entity Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Entity Availing Loan",
        showMask: true,
    },
    {
        name: "cin",
        label: "CIN",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    // {
    //     name: "individualName",
    //     label: "Name",
    //     inputType: INPUT_TYPE.TEXT,
    //     defaultValue: "",
    //     subtitle: "Person Availing Loan",
    //     showMask: true,
    // },
    {
        name: "relationship",
        label: "Relationship",
        inputType: INPUT_TYPE.SELECT,
        options: ["Son", "Daughter", "Brother", "Sister", "Father", "Mother", "Grand Father", "Grand Mother", OTHER],
        defaultValue: "Association",
        editable: true,
    },
    // {
    //     name: "companyName",
    //     label: "Company Name",
    //     inputType: INPUT_TYPE.TEXT,
    //     defaultValue: "",
    //     subtitle: "Entity Availing Loan",
    // },
    {
        name: "incorporationType",
        label: "Incorporation Type",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: ["Private", "Public"],
        defaultValue: "",
    },
    {
        name: "pan",
        label: "PAN Number",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Validate PAN",
        showMask: true,
    },
    {
        name: "proprietorName",
        label: "Proprietor Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        showMask: true,
    },
    {
        name: "aadhar",
        label: "Aadhar",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Validate Aadhar",
        showMask: true,
    },
    {
        name: "gst",
        label: "GSTIN",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Validate GSTIN",
        showMask: true,
    },
    {
        name: "liabilityType",
        editable: true,
        label: "Liability",
        inputType: INPUT_TYPE.RADIO,
        options: ["Limited", "Unlimited"],
        defaultValue: "",
    },
    {
        name: "dateOfEstablishment",
        label: "Date of Establishment",
        inputType: INPUT_TYPE.DATE,
        defaultValue: "",
        subtitle: "Year Started",
    },
];

export default fixture;
