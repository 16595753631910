import { Fragment } from "react";
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const BusinessIncome = ({ prefix, activeFields, pane }: { prefix: string; activeFields: string[]; pane?: string }) => {
    const businessIncome = useWatch({ name: `${prefix}.businessIncome` });
    const business = useWatch({ name: `${prefix.replace(".income.income", "")}.entity.business` });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {fixture.map(({ name, inputType, reactHookFormRegisterOptions, editable, options, subtitle, label }, index) => {
                if (businessIncome.hasFiledIncomeTaxReturn === false && name === "incomeTaxPassword") {
                    return "";
                }
                if (business !== "Partnership") {
                    if (name === "obligation") return "";
                }
                if (business !== "Registered Under Company Act") {
                    if (name === "paidUp" || name === "authorized") return "";
                }

                activeFields.push(name);
                return (
                    <Fragment key={index}>
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.businessIncome.${name}`}
                            data-testid={generateTestId(`${label}-business-${pane}`, "field")}
                            options={
                                options?.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                })) || []
                            }
                            label={label}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                            subtitle={subtitle}
                        />
                    </Fragment>
                );
            })}
        </Grid>
    );
};

export default BusinessIncome;
