import { Fragment, useEffect, useLayoutEffect, useMemo } from "react";
import { useAuthUser } from "react-auth-kit";
import { useFormContext, useWatch, Controller } from "react-hook-form";
import { Manufacturer, Dealer } from "@/types/manufacturers";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useGetAllManufacturers, useFindAllDealers } from "__generated__/apiComponents";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER, ROUTES } from "globalConstants";
import { useMaskedFields, useRcVerify } from "hooks";
import { ICommonFixture } from "types";
import { combineClassNames, convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import { OwnMarginOrLTV, BasicPriceOrInvoiceValue } from "../";

const EquipmentItem = ({
    props: {
        inputType,
        disableReactHookForm,
        name,
        readOnly,
        className,
        infoText,
        label,
        options,
        subtitle,
        placeholder = "",
        reactHookFormRegisterOptions,
        dataTestId,
        showMask,
    },
    index,
    isSalesPersonLoading,
    handleSelectValue,
    salesAgentNames,
    disableSalesAgent,
    isSalesPerson,
    activeFields,
}: {
    props: ICommonFixture;
    index: number;
    isSalesPersonLoading: boolean;
    salesAgentNames: any;
    handleSelectValue: (value: string) => void;
    disableSalesAgent: boolean;
    isSalesPerson: boolean;
    activeFields: string[];
}) => {
    const { setValue, register, control, getValues } = useFormContext();
    const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly;
    isSalesPersonLoading = false;

    const equipment = useWatch({
        name: `requirement.equipments.${index}`,
    });
    const basicPrice = equipment.basicPrice;
    const tcsPercentage = equipment.tcsPercentage;

    let currentOptions: string[] | undefined = [];
    let currentLabelGenerator = convertSnakeCaseToHeaderCase;
    const manufacturer = equipment.manufacturer;

    const { isMasked } = useMaskedFields();

    const { data: manufacturers } = useGetAllManufacturers<Manufacturer[]>({});
    const { data: dealers } = useFindAllDealers<Dealer[]>({ queryParams: { manufacturer } });

    switch (name) {
        case "manufacturer":
            currentOptions = manufacturers?.map((element) => element.manufacturer_name) ?? [];
            break;

        case "category":
            currentOptions = manufacturers?.find((item) => item.manufacturer_name === manufacturer)?.categories.map((item) => item.category) ?? [];
            break;

        case "model":
            currentOptions =
                manufacturers
                    ?.find((item) => item.manufacturer_name === manufacturer)
                    ?.categories?.find((item) => item.category === equipment.category)?.models ?? [];
            break;

        case "sellerName":
            currentOptions = dealers?.map((dealer) => dealer.name) ?? [];
            currentOptions?.push(OTHER);
            break;

        default:
            currentOptions = options;
            break;
    }

    const { setRc, rcVerified, handleRcValidate } = useRcVerify({
        defaultRcVerified: equipment.registrationNumberVerified,
    });

    useEffect(() => {
        if (rcVerified) {
            setValue(`requirement.equipments.${index}.registrationNumberVerified`, true);
        }
    }, [rcVerified]);

    useEffect(() => {
        if (manufacturers) {
            if (name === "category" && equipment.manufacturer !== OTHER) {
                if (!currentOptions?.includes(equipment.category))
                    if (currentOptions && currentOptions?.length > 0) setValue(`requirement.equipments.${index}.category`, currentOptions?.[0]);
            }
            if (name === "sellerName" && equipment.manufacturer !== OTHER) {
                if (!currentOptions?.includes(equipment.sellerName))
                    if (currentOptions && currentOptions?.length > 0) setValue(`requirement.equipments.${index}.sellerName`, currentOptions?.[0]);
            }
        }
    }, [equipment.manufacturer]);

    useEffect(() => {
        if (name === "model" && equipment.category !== OTHER) {
            if (!currentOptions?.includes(equipment.model))
                if (currentOptions && currentOptions?.length > 0) setValue(`requirement.equipments.${index}.model`, currentOptions?.[0]);
        }
    }, [equipment.category]);

    const memoizedOptions = useMemo(
        () =>
            currentOptions?.map((value) => ({
                label: currentLabelGenerator(value) === "Other" || currentLabelGenerator(value) === "other" ? "OTHER" : currentLabelGenerator(value),
                value,
            })) || [],
        [currentOptions]
    );

    let value: string | number = "0";

    useLayoutEffect(() => {
        setRc(equipment.registrationNumber);
    }, [equipment.registrationNumber]);

    switch (name) {
        case "category":
            if (equipment.newOrUsed === "used") return <Fragment />;

            break;

        case "model":
            if (equipment.newOrUsed === "used") return <Fragment />;

            break;

        case "basicPriceOrInvoiceValue":
            activeFields.push("basicPrice", "invoiceValue");
            return <BasicPriceOrInvoiceValue index={index} key={name} />;

        case "ownMarginOrLtv":
            activeFields.push("ownMargin", "loanToValue");
            return <OwnMarginOrLTV key={name} index={index} />;

        case "gst":
            value = +((basicPrice || 0) * 0.18).toFixed(2) || "";
            break;

        case "tcsPercentage":
            const gst = +(basicPrice * 0.18) || 0;
            value = (((Number(basicPrice) + gst) * 1) / 100).toFixed(2);

            value = isNaN(Number(value)) ? "0" : value;
            break;
        default:
            break;
    }

    if (equipment.newOrUsed === "new") {
        if (name === "registrationNumber" || name === "manufacturerDate") return <Fragment />;
    }

    if (equipment.newOrUsed === "used") {
        if (name === "manufacturer" || name === "model" || name === "noOfUnits") return <Fragment />;

        if (name == "sellerName") {
            if (index > 0) {
                readOnly = true;
            }
            inputType = INPUT_TYPE.TEXT;
        }
    }

    if (name === "sellerName" && index > 0) {
        readOnly = true;
    }
    if (name === "salesAgent.fullName" && index > 0) {
        readOnly = true;
        inputType = INPUT_TYPE.TEXT;
    }

    if (name === "gst" || name === "tcs") {
        value = +value;
    }

    if (name === "salesAgent.fullName") {
        if (index === 0) {
            activeFields.push("salesAgent.fullName");
            return (
                <Fragment key={index}>
                    {isSalesPerson ? (
                        <CommonFields
                            inputType={INPUT_TYPE.TEXT}
                            label={label}
                            name={`requirement.equipments.${index}.${name}`}
                            options={memoizedOptions}
                            subtitle={subtitle}
                            placeholder={placeholder}
                            className={className}
                            data-testid={`sales-agent-equipment-${index + 1}-field`}
                            readOnly={true}
                            prop={{
                                value: infoText || "",
                            }}
                            value={value}
                            showMask={isMasked}
                            disableReactHookForm={disableReactHookForm}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                        />
                    ) : (
                        <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                            <Grid item xs={6}>
                                <Typography className="common-label" data-testid={`sales-agent-equipment-${index + 1}-field-label`}>
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name={`requirement.equipments.${index}.${name}`}
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            disabled={localStorage.getItem("disable_form") === "true" || disableSalesAgent || readOnly}
                                            className="common__mui__textfield"
                                            options={salesAgentNames}
                                            value={value}
                                            onClose={(event: React.SyntheticEvent) => {
                                                if (index > 0) return;
                                                // @ts-ignore
                                                const value = event?.target?.innerText;

                                                if (value?.length > 0) handleSelectValue(value);
                                            }}
                                            data-testid={`sales-agent-equipment-${index + 1}-field`}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    type={isMasked ? "password" : "text"}
                                                    disabled={
                                                        localStorage.getItem("disable_form") === "true" ||
                                                        isSalesPersonLoading ||
                                                        disableSalesAgent ||
                                                        readOnly
                                                    }
                                                    defaultValue={equipment.salesAgent}
                                                    className={combineClassNames(
                                                        "autocomplete-input",

                                                        "common__mui__textfield",
                                                        isDisabled ? "bg-shade" : undefined,
                                                        // isStandardTextField ? "border-none" : "",
                                                        (getValues(`requirement.equipments.${index}.${name}`) === "" ||
                                                            getValues(`requirement.equipments.${index}.${name}`) === undefined) &&
                                                            getValues("showIncompleteField") == true
                                                            ? "error"
                                                            : ""
                                                    )}
                                                    helperText={
                                                        (getValues(`requirement.equipments.${index}.${name}`) === "" ||
                                                            getValues(`requirement.equipments.${index}.${name}`) === undefined) &&
                                                        getValues("showIncompleteField") == true
                                                            ? "This field is required"
                                                            : ""
                                                    }
                                                    error={
                                                        (getValues(`requirement.equipments.${index}.${name}`) === "" ||
                                                            getValues(`requirement.equipments.${index}.${name}`) === undefined) &&
                                                        getValues("showIncompleteField") == true
                                                    }
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: isSalesPersonLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                                    }}
                                                    {...register(`requirement.equipments.${index}.${name}`)}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Fragment>
            );
        }
    }

    activeFields.push(name);

    if (currentOptions?.length === 0) {
        return <Fragment></Fragment>;
    }

    return (
        <CommonFields
            inputType={inputType}
            label={label}
            name={`requirement.equipments.${index}.${name}`}
            options={memoizedOptions}
            subtitle={subtitle}
            placeholder={placeholder}
            className={className}
            readOnly={localStorage.getItem("disable_form") === "true" || readOnly}
            prop={{
                value: infoText || "",
            }}
            value={value}
            showMask={showMask}
            disableReactHookForm={disableReactHookForm}
            data-testid={generateTestId(`${label}-equipment-${index + 1}`, "field")}
            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
            {...(name === "registrationNumber" && rcVerified ? { verified: true } : {})}
            {...(name === "registrationNumber" && !rcVerified ? { handleValidate: handleRcValidate } : {})}
            {...(name === "registrationNumber" ? { isValidate: true } : {})}
        />
    );
};

export default EquipmentItem;
