import "./Terms&Conditions.style.scss";
import Navbar from "components/Navbar";
import Footer from "components/Footer";

const TermsAndConditions = () => {
    return (
        <>
            <Navbar />{" "}
            <div className="terms-conditions-page">
            <h1 className='header' >   Terms & Conditions   </h1>
                <p className="">

                    These Terms and Conditions together with our Privacy Policy (collectively “the Terms”)
and Terms (if any, as may be separately agreed between You and SMECreditt in
connection with specific Services that you may avail from time to time) are the terms and
conditions subject to which you are allowed to access/use this website smecreditt.com
(“the Website”) and/or the online, web-based facilities, solutions and services made by
SMECreditt, including available through the Website(“the Service”).<br></br> <br></br> 
These Terms are legally binding. So please do read through them carefully and make sure
you understand them before you accept these Terms. By clicking the box indicating
acceptance of these Terms, you acknowledge and agree that you have read and
understood these Terms and that these Terms form a legal and binding agreement
between us. If you do not agree to these Terms, do not access, or use the Website or the
Service or any part thereof. 
SMECreditt may modify these Terms / the Additional Terms from time to time and any
changes will be notified to you through the Website or via the Service. Once you have
received notice of any change in these Terms/ the Additional Terms, any subsequent use
of the Website or the Service will be deemed to constitute your acceptance of such
modified / changed Terms.<br></br> <br></br> 
In these Terms and any Additional Term as may be relevant, “User”, “you” or “your”
shall refer to you, the person who is accessing / using this Website / the Service and the
term “SMECreditt”, “we” or “our” shall refer to SMECreditt, and wherever the context
requires, shall also include SMECreditt licensors and affiliates and their respective
employees, officers, directors, agents and representatives.
<h2>1.GENERAL</h2>
The Platform provides the Users access to various financial products offered by the
financial partners of SMECreditt. SMECreditt is merely a facilitator providing the
Platform to any person in need of financial products and does not directly provide any
financial services to the Users. SMECreditt is not an organization registered with the
Reserve Bank of India and does not hold any license to engage in any activities relating
to lending or borrowing. SMECreditt is not a Financial Institution under the Companies
Act, 2013 or the Banking Regulation Act, 1949 or any other laws for the timebeing in
force in India. SMECreditt is not a deposit taking company or a chit fund or a company
offering any investment schemes as per the rules and regulations for the time being in
force in India. <br></br> <br></br> 
SMECreditt is a service provider facilitating the provision of financial products from
financial institutions to individuals desirous of availing such financial
products (“Services”). The final decision as regards lending and borrowing is also subject
to the determination of the financial partner.
In the event You wish to avail any of the products displayed on the Platform, You will be
required to provide certain information and our representatives will contact You
through phone or email to understand your requirements. <br></br> <br></br> 
For the provision of the Services, SMECreditt will be using and sharing the information
of the User with multiple financial institutions and other parties, as may be required for
the purpose mentioned above and SMECreditt shall also run multiple credit checks to
be able to get the best available offers to suit the needs of the Users. The collection,
storing, use and transfer of information shared by the User shall be governed in
accordance with the Privacy Policy.
<h2>2. ELIGIBILITY</h2>
The User of this Platform unequivocally declares and agrees that the User is a natural /
legal person who has attained the age of at least 18 years and is legally allowed to enter
into a contract in India. The User is allowed to utilize the Services of SMECreditt in
accordance with the terms and conditions detailed under these Terms & Conditions. By
registering on the Platform, the User hereby undertakes to agree and abide by the
Terms & Conditions detailed herein. If the User violates any of these Terms &
Conditions, or otherwise violates an agreement entered into through the medium ofthe
Platform, SMECreditt may terminate the User’s membership, delete his/her profile and
any content or information posted online by the User on the Platform and / or prohibit
the User from using or accessing the Platform at any time in its sole discretion, with or
without notice, including without limitation if SMECreditt determines that the User is
under the age of 18 years.
<h2>3. YOUR ACCOUNT</h2>
If You wish to use the Platform, You are required to maintain an account and will be
required to furnish certain information and details, including Your name, email id,
contact number and any other information deemed necessary by SMECreditt. You are
responsible for maintaining the confidentiality and security of Your account, password,
activities that occur in or through Your account and for restricting access to Your
computer to prevent unauthorized access to Your account. You agree to accept
responsibility for all activities that occur under Your account or password. <br></br> <br></br>  You should
take all necessary steps to ensure that the password is kept confidential and secure and
should inform Us immediately if You have any reason to believe that Your password has
become known to anyone else, or if the password is being, or is likely to be, used in an
unauthorized manner. Please ensure that the details You provide Us with are true,
correct, accurate and complete. Upon SMECreditt gaining knowledge of having any
reasonable suspicion that the information provided by You is wrong, inaccurate, or
incorrect, SMECreditt shall immediately terminate Your account without any notice to
You in this regard. In the event of any changes to the information shared by You at the
time of registering Yourself on the Platform, You shall be responsible for forthwith
notifying the said changes to Us. The right to use this Platform is personal to the User
and is not transferable to any other person or entity.<br></br> <br></br> 
You shall, at all times, abide by the Terms & Conditions stated herein and any breach of
the same may also lead to SMECreditt terminating Your account and appropriate civil
and criminal remedies will be sought against You as provided under the laws of India.
<h2>4. PRIVACY</h2>
SMECreditt collects certain information from You in order to provide the Services.
SMECreditt’s use of Your personal information is governed in accordance with the
Privacy Policy located at https://www.smecreditt.com/privacy-policy.<br></br> <br></br> 
You confirm that all information given to SMECreditt (whether in SMECreditt’s online
form or otherwise) is true, correct and not misleading. You agree to provide any
additional documents to SMECreditt as may be required from time to time.
<h2>5. LICENSE AND SITE ACCESS</h2>
SMECreditt grants You a limited license to access and use the Platform for availing the
Services, but not to download any material from it (other than page caching) or modify
it, or any portion of it. Any unauthorized access to the Platform or any networks, servers
or computer systems connected to the Platform and any attempt to modify, adapt,
translate, or reverse engineer any part ofthe Platform or re-format or frame any portion
of the pages of the Platform is not permitted. This license is non-transferable and does
not permit any resale or commercial use of this Platform or its contents; any
downloading or copying of account information for the benefit of anyone other than Your
authorized use; or any use of data mining, robots, or similar data gathering and
extraction tools. This Platform or any portion of this Platform (including but not limited
to any copyrighted material, trademarks, or other proprietary information) may not be
reproduced, duplicated, copied, sold, resold, visited, distributed, or otherwise exploited
for any commercial purpose. Any unauthorized use of the Platform shall terminate the
permission or revoke the license granted by SMECreditt.<br></br> <br></br> 
SMECreditt shall at all times have the right to monitor your activities on your account
and Platform and shall have the right to terminate your account and the Services in the
event of any unauthorized use.
<h2>6. SERVICE TERMINATION</h2>
SMECreditt reserves the right to terminate the Services and your use of the Platformat
any time without giving notice of termination to You and without any liability towards
you. Upon termination, (a) the rights and licenses granted to You in these Terms &
Conditions will terminate; and (b) User must stop using the Platform.
<h2>7. LIMITATION OF LIABILITY</h2>
Notwithstanding anything to the contrary contained under these Terms & Conditions,
neither SMECreditt nor its affiliated companies, subsidiaries, officers, directors,
employees, or any related party shall have any liability to You or to any third party for
any indirect, incidental, special, or consequential damages or any loss of revenue or
profits arising under or relating to these Terms & Conditions. To the maximum extent
permitted by law, You waive, release, discharge and hold harmless SMECreditt, its
affiliated and subsidiary companies, and each of its directors, officers, employees, and
agents, from any and all claims, losses, damages, liabilities, expenses and causes of
action arising out of Your use of the Platform.
<h2>8. INDEMNITY</h2>
You agree to indemnify, save, and hold SMECreditt, its affiliates, contractors,
employees, officers, directors, agents and its third party associates, licensors, and
partners harmless from any and all claims, demands, losses, damages, and liabilities,
costs and expenses, including without limitation legal fees and expenses, arising out of
or related to Your use or misuse of the Services or of the Platform, any violation by You
of these Terms & Conditions, or any breach of the representations, warranties, and
covenants made by You during the creation of Your account or Your infringement of any
intellectual property or other right of any person or entity, or as a result of any
threatening, libellous, obscene, harassing or offensive material posted/ transmitted by
You on the Platform. SMECreditt reserves the right, at Your expense, to assume the
exclusive defence and control of any matter for which You are required to indemnify
SMECreditt, including rights to settle, and You agree to cooperate with SMECreditt’s
defence and settlement of these claims.
<h2>9. ADVERTISERS/ THIRD PARTY LINKS ONTHE WEBSITE</h2>
SMECreditt accepts no responsibility for advertisements contained within the Platform.
SMECreditt has no control over and accepts no responsibility for the content of any
website or mobile application to which a link on the Platform exists. Such linked
websites and mobile applications are provided “as is” for User’s convenience only with no
warranty, express or implied, for the information provided therein. SMECreditt does
not provide any endorsement or recommendation of any third-party website or mobile
application to which the Platform provides a link.
<h2>10. ACKNOWLEDGEMENTS</h2>
You acknowledge that SMECreditt is a platform solely engaged in providing services
relating to facilitation of provision of financial services to the Users. SMECreditt is not
engaged in either grant of loan or any other financial products. You acknowledge that the
use of the financial products is entirely based on the negotiations between You and the
financial partners of SMECreditt and SMECreditt or its affiliates, contractors, employees,
officers, directors, agents and its third-party associates or licensors do not have any
liabilities with respect to any financial products or assistance procured or intended to be
procured by a User. You acknowledge that SMECreditt will not be responsible for any
claim or damage in case of use of the financial products by You. SMECreditt in no
manner warrants or guarantees the performance of a service provider/financial partner
that is offering the various financial products or services on the Platform.
<h2>11. GOVERNING LAWS</h2>
Your use of the Platform and the terms contained herein, including the Privacy Policy, is
governed by the laws of India. Courts of New Delhi shall have the exclusive jurisdiction
to resolve any disputes that arise pursuant to Your use of the Platform.
<h2>12. ARBITRATION, GOVERNING LAW, AND JURISDICTION</h2>
1. Any dispute arising from or relating to, these Terms or any Additional Terms, or
your use of the Website or any Service shall be referred to arbitration by a sole
arbitrator appointed by SMECreditt. The venue of arbitration shall be New
Delhi
and the language of arbitration shall be English. The arbitration proceeding shall
be governed by the Indian Arbitration Act as amended from time to time and the
award of the arbitrator shall be final and binding on both parties.<br></br> <br></br> 
2. This Agreement shall, in all respects, be governed by and construed in all
respects in accordance with the laws of India.<br></br> <br></br> 
3. Competent courts located in New Delhi shall have exclusive jurisdiction in
connection with any matter arising from or relating to, these Terms or any
Additional Terms, or your use ofthe Website or any Service, to the exclusion of
any other court that may otherwise have had such jurisdiction in the matter.
<h2>13. WARRANTY & DISCLAIMER</h2>
1. SMECreditt will make commercially reasonable efforts to provide the Service that you
have subscribed to and paid for, in a professional and workmanlike manner.
2. Except as expressly warranted above, SMECreditt does not warrant, and hereby expressly
disclaims all warranties, express or implied, including but not limited to those of
merchantability, quality or fitness for any particular purpose, performance, accuracy,
completeness or non-infringement. SMECreditt makes no warranty that the Service will
meet your requirements or that it will be error free or free from defects or deficiencies.
The Website, the Service and Content are provided on an ‘AS IS’ and ‘AS AVAILABLE’
basis.
<h2>14. RIGHT TO MODIFY THE SERVICE</h2>
1. SMECreditt reserves the right to discontinue, change or modify the Service (by addition,
deletion, or modification) from time to time. In the event of the discontinuation of any
Service or part thereof or any change that results in a material degradation or material
reduction in the functionality of the Service, your sole remedy will be to receive a refund
of the charges you may have paid for the same, pro-rated for any completed Service and
subject to a maximum of 50% of the charges paid unless SMECreditt has not commenced
provision of the Service on the date of such discontinuation.
<h2>15. CHARGES</h2>
1. SMECreditt shall be entitled to charges for the Service as per the respective Service that
you subscribe to. The charges as applicable from time to time are detailed in the product
section of our website. Charges paid are non-refundable unless SMECreditt fails to
commence the provision of the subscribed service within seven (7) days from the date of
acceptance of your order for the Service. SMECreditt’s records shall be final and binding
as to any such commencement of the relevant Service. <br></br> <br></br> 
2. SMECreditt may receive a referral / facilitation fee from third party banks / other credit
institutions for (a) assisting with the settlement of outstanding amounts dues owed to
them by you and / or (b) for referring their products / services to you and by accepting
these Terms you confirm that you have no objection to the same.
<h2>16. PERMITTED USE</h2>
1. You may use the Website and the Service solely for your own personal and noncommercial use, subject to these Terms and any Additional Terms that you may accept
and agree to be bound by.<br></br> <br></br> 
2. All content, data, design, information or other materials available on and underlying the
Website (collectively “Content”), including but not limited to software underlying the
Website or which are required for accessing or using the Website, images, text, layouts,
arrangements, displays, illustrations, photographs, graphics, audio and video clips, HTML
files and other content, logos, trademarks including the trademark ‘SMECREDITT’ and
other trademarks that may be used on the Website are the property of SMECreditt and/or
its licensors and are protected by copyright and/or other applicable intellectual property
right laws. <br></br> <br></br>  You may use the said Content only for your own personal, non-commercial use
in connection with your use of the Service, subject always to these Terms. You are not
permitted to copy, reproduce, publish, perform, communicate to the public in any manner
whatsoever, transmit, sell, license, distribute or transfer in any manner whatsoever,
adapt, modify, translate, disassemble, decompile or reverse engineer, create derivative
works from, or in any way use or exploit the Website, the Service, the Content or any part
thereof except for your personal, non-commercial use in accordance with these Terms.
You shall not remove or obliterate any copyright, trademark or proprietary rights notices
from the Content or the Website and shall reproduce all such notices on all authorized
copies of the Content. Use of the Website, the Service and/or Content in violation of these
Terms may violate copyright, trademark, and other applicable laws, and could result in
appropriate legal action. <br></br> <br></br> 
3. All rights, title and interest in the Website, the Service, and the Content, except the limited
license granted to you as per these Terms, are exclusively reserved by SMECreditt.<br></br> <br></br> 
4. You shall not use the Website, the Service, the Content, or any part thereof for any
purpose that is unlawful or not permitted by these Terms. Without prejudice to the
generality of the above, you shall not use the Website, the Service, the Content, or any
part thereof to host, display, upload, modify, publish, transmit, update, or share any
information that:
belongs to another person and to which you do not have any right to
impersonate another person
is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic,
paedophilic, libellous, invasive of another's privacy, hateful, or racially, ethnically
objectionable, disparaging, relating, or encouraging money laundering or gambling, or
otherwise unlawful in any manner whatever
harm minors in any way
infringes any patent, trademark, copyright, or other proprietary rights
violates any law for the time being in force
deceives or misleads SMECreditt about the origin of such messages or communicates any
information which is grossly offensive or menacing in nature
contains software viruses or any other computer code, files or programs designed to
interrupt, destroy, or limit the functionality of any computer resource
threatens the unity, integrity, defence, security or sovereignty of India, friendly relations
with foreign states, or public order or causes incitement to the commission of any
cognizable offence or prevents investigation of any offence or is insulting any other
nation or
is unsolicited or unauthorized advertising, junk mail, or spam.<br></br> <br></br> 
5. If you are, or if we have reasonable cause to suspect that you are, in violation of any of
these Terms, SMECreditt reserves the right to immediately restrict, suspend, or terminate
your access to the Website and/or use of the Service.
<h2>17. SCOPE OF SERVICE AND LIMITATIONS</h2>
1. The Service provided by SMECreditt is available and appropriate only for use in India.<br></br> <br></br> 
2. The Service provided by SMECreditt is aimed at improving your credit worthiness and
assisting you with taking actions that are expected to enhance your credit rating and
consequently, over a period of time, your eligibility for credit assistance from banks or
other credit institutions. Basis the specific action that you opt and pay for (wherever such
charges are applicable), SMECreditt will assist you with providing the details of your
current credit health and provide you with options on ways to resolve issues and coordinate with you to implement the action chosen to improve your chances for becoming
eligible for credit assistance over a period of time. You further provide consent and
authorise SMECreditt to retain as long as it deems it fit, any analysis, interpretation,
derivative of information obtained from the credit information report and also any
information collected from various lenders, for the purpose of fulfilling services under
these Terms.<br></br> <br></br> 
3. For details of the various Services offered by SMECreditt, the scope of such Service
provided, the term of the relevant Service and the charges applicable for the respective
Service, please refer the relevant Additional Terms.<br></br> <br></br> 
4. You may be required to accept and agree to be bound by additional terms and conditions
(“Additional Terms”) for availing the specific Service you choose. The applicable
Additional Terms are set out at the point of access to the relevant Services. These
Additional Terms are in addition to, and not in derogation of these Terms. In the event of
any of these Terms conflicting with any Additional Terms, the Additional Terms shall
prevail to the extent of such conflict, in respect of, and to the extent it relates to that
particular Service.<br></br> <br></br> 
5. All transactions undertaken on your behalf by SMECreditt will be on the basis of your
express instructions/prior approval and will be strictly on a non-discretionary basis.
SMECreditt only offers to provide options and assist you (using commercially reasonable
efforts) with actions that you could take to improve your credit record and remove
negative comments in your credit history, with the sole aim of improving your credit
rating and improving your chances of becoming eligible to receive financial assistance
from banks or other credit institutions. You fully understand and agree that the decision
on the course of action that you chose to adopt will rest exclusively with you and you are
not obliged to accept any advice / recommendations provided to you by SMECreditt.
SMECreditt shall not be responsible for any adverse impact resulting from your decision
on the course of action that you choose to adopt or your failure to abide by the financial
obligations you undertake pursuant thereto. While determining your course of action, you
are required to independently analyse all relevant factors and exercise your independent
discretion.<br></br> <br></br> 
6. The Service provided to you is based on your credit information report and credit rating
maintained by various authorized Credit Information Companies (“CICs) in India and any
other financial information that you may choose to provide to SMECreditt or authorise/
give consent to obtain from such CIC. If the information and details provided to
SMECreditt (by the concerned CICs or you) are incorrect or incomplete, it will adversely
affect the quality of the Service. In addition, there are several unknown factors beyond the
control of SMECreditt that may impact the outcome of the Service. For instance, credit
scores are calculated differently by the various credit information companies; banks and
other lending institutions may have their own internal policies subject to which they
accept settlement of delayed dues or offer financial assistance and therefore, it is
impossible to predict how any one specific action that we may recommend will affect
your credit rating or your ability to obtain financial assistance.  <br></br> <br></br> Therefore, you hereby
understand and acknowledge that the reports and analysis provided to you by
SMECreditt is based on SMECreditt’s commercially reasonable efforts and our past
knowledge and experience, and SMECreditt does not guarantee any outcome vis-à-vis
your credit report or credit rating maintained by CICs nor do we guarantee that you will
receive financial assistance from any bank or other credit institution. Individual results
may vary based on several factors which are beyond the control of SMECreditt. While we
make commercially reasonable efforts to ensure that our analysis are comprehensive and
will provide you with options to improve your credit rating and credit worthiness, it is
not meant to be exhaustive or conclusive and errors may occur.<br></br> <br></br> 
7. By accepting these Terms, you hereby give consent and authorise SMECreditt at its
discretion to:
Request and receive reports from CICs now and for a period of next six months
Retain and store such reports for a period of six months from the date such report was
received from the CIC
Extend the periods stated above for further such periods perpetually every time you login
to the SMECreditt Website. <br></br> <br></br> 
You hereby understand and agree that SMECreditt is required by law to purge any report
after six months of obtaining such report, if you fail to login and validate your consent for
retaining such report for further period of six months. <br></br> <br></br> 
8. By accepting these Terms, you hereby request and authorize SMECreditt to communicate
with you, and accept instructions from you or your approval, (i) over the telephone (at
the number you provide to SMECreditt) (ii) by email (at the email address you provide to
SMECreditt); (iii) in writing (including through documents uploaded by you through your
User ID or otherwise), and/or any other mode and form as may be determined
appropriate by SMECreditt from time to time. <br></br> <br></br>  You hereby consent to SMECreditt, at its
sole discretion, (i)recording all telephone calls between you and SMECreditt and any such
records of SMECreditt shall be conclusive and binding evidence of the fact and content of
such calls (ii) providing your details to banks and credit institutions to validate
correctness of reporting with the CICs (iii) To share your information including certain
personal identifiable information with business partners (i.e. financial institutions) of
SMECreditt to provide you with pre-approved offers per the eligibility criteria, from such
business partners. It is your responsibility to intimate SMECreditt of any change in your
email address or telephone number. You hereby confirm that instructions / approval so
provided by you will be valid, effective, and binding on you without any further enquiry
or due diligence as to identity of the person purporting to give such instruction /
approval. SMECreditt may, in its sole discretion, seek further confirmation of any
instruction / approval given or purporting to originate from you.<br></br> <br></br> 
9. For as long as your User ID remains registered with us, SMECreditt may, from time to
time, recommend our own products and services or the products and/or services of
third-party banks and other credit institutions to you. You hereby consent that based
upon your request, SMECreditt may forward your contact details to third party banks and
credit institutions, and you authorize them to contact you, even if you are registered with
a Do Not Call (DNC) status with your telecom provider. If you have asked us, or if you do
ask us in future not to send you any marketing material, you agree that this restriction
will not apply to these recommendations / referrals. The provision of referrals related to
the products and/or services of third-party banks and other credit institutions is purely a
referral service and is not an offer made to you by SMECreditt for any financial product
and/or financial services and should not be construed as such. Such third-party referral is
provided strictly on a non-risk participation basis.  <br></br> <br></br> The ultimate decision regarding the
appropriateness of any product / service that is provided to you by SMECreditt is
exclusively yours and you should exercise due caution and/or seek independent advice
before entering into any investment or financial obligation based on these referrals. You
should consult your appropriate advisors and independently consider the merits and
risks of subscribing or purchasing any recommended products / services, including
engaging with any third party and / or purchasing their products / services. The third
party/parties will be entitled to determine, in their sole discretion, whether to offer any
of their products and/or services to you and any such offer will be subject to such terms
and conditions as may be agreed between you and the concerned third party. SMECreditt
shall not be a party to and shall not be responsible or liable for any transactions,
interactions, conduct or communications between you and our third-party partners, on or
through the Service or otherwise. SMECreditt may receive a referral / facilitation fee from
these third-party banks / other credit institutions for the referral and you hereby confirm
that you have no objection to the same.<br></br> <br></br> 
10. SMECreditt shall not be liable for any failure or delay to perform any of its obligations if
performance is prevented, hindered or delayed, in part or entirely, by any event beyond
the reasonable control of SMECreditt, including without limitation, sabotage, fire, flood,
explosion, acts of god, acts of third parties, civil commotion, strikes or industrial action of
any kind, riots, insurrection, war, acts of government, acts of third parties beyond
SMECreditt’s reasonable control, breach of computer systems, malfunctioning of
computer systems by any malicious, destructive or corrupting code or virus, mechanical
or technical errors/failures, power shut down, faults or failures in telecommunication or
internet services or unauthorized access to computer systems, (“Force Majeure event”). <br></br> <br></br> 
You hereby understand and acknowledge that the transmission, storage and or retrieval
of information and the provision of the Service through the internet is subject to a variety
of factors beyond the reasonable control of SMECreditt, that make such transmission,
storage, retrieval and or provision of Service potentially unreliable, including any
interruption or unavailability of the third-party hosting services or internet services used
by SMECreditt to host the Website and / provide the Service. In no event shall SMECreditt
be liable for any failure, interruption or delay of the Service or the Website resulting in
part or entirely from such Force Majeure events. SMECreditt’s obligations shall be
suspended for so long as the Force Majeure event continues SMECreditt does not provide
legal advice or any advice on your tax liability. You should seek appropriate advice in this
regard.
                </p>
            </div>
            <Footer />
        </>
    );
};

export default TermsAndConditions;
