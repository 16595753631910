import { memo } from "react";
import { useWatch } from "react-hook-form";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import isEqual from "lodash/isEqual";
import generateTestId from "utils/generateTestId";
import { ManualForm, UploadForm } from ".";

type Props = {
    index: number;
    appendNewAsset: (index: number) => void;
    prefix: string;
    pane?: string;
};

const FirstAsset = ({ index: externalIndex, appendNewAsset, prefix, pane }: Props) => {
    const asset = useWatch({ name: `${prefix}.${externalIndex}` });
    const errors = useWatch({ name: `errors.${prefix}.${externalIndex}` });

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            keys={`${prefix}.${externalIndex}`}
            data-testid={generateTestId(`Asset ${externalIndex === 0 ? "" : externalIndex + 1}-${pane}`, "accordion")}
            titleReactNode={
                <CommonFields
                    inputType={INPUT_TYPE.RADIO}
                    name={`${prefix}.${externalIndex}.hasAsset`}
                    label={`Asset ${externalIndex === 0 ? "" : externalIndex + 1}`}
                    options={[
                        { label: "Upload File", value: true },
                        { label: "Add Manually", value: false },
                    ]}
                    data-testid={generateTestId(`Asset ${externalIndex === 0 ? "" : externalIndex + 1}-${pane}`, "field")}
                    prop={{
                        value: "Do you own any Asset ?",
                    }}
                    className="common-mui-accordian__radio"
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            if (e.target.value === "false") {
                                appendNewAsset(externalIndex);
                            }
                        },
                    }}
                />
            }
            showDetail={asset.hasAsset !== ""}
            title={`Asset ${externalIndex === 0 ? "" : externalIndex + 1}`}
            tab="income"
            defaultExpanded={asset.hasAsset !== ""}
        >
            {asset.hasAsset === false && <ManualForm index={externalIndex} prefix={prefix} pane={pane} />}
            {asset.hasAsset === true && <UploadForm prefix={prefix} pane={pane} />}
        </CommonAccordion>
    );
};

export default memo(FirstAsset, (prevProps, nextProps) => isEqual(prevProps, nextProps));
