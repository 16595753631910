import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "facebook",
        label: "Facebook",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "linkedin",
        label: "Linkedin",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "instagram",
        label: "Instagram",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "twitter",
        label: "Twitter",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
];

export default fixture;
