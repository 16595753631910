import { useState, useCallback } from "react";
import { useSignOut } from "react-auth-kit";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useVerifyRC } from "__generated__/apiComponents";
import { ROUTES } from "globalConstants";


const useRcVerify = ({ defaultRcVerified = false }: { defaultRcVerified?: boolean }) => {
    const [rc, setRc] = useState("");
    const [rcDetails, setRcDetails] = useState(null as any);
    const formId = localStorage.getItem("form_id");
    const [rcVerified, setRcVerified] = useState(defaultRcVerified);
    const navigate = useNavigate();
    const signOut = useSignOut();
    const { mutate: rcVerify } = useVerifyRC({
        onSuccess: (data: any) => {
            if (data) {
                setRcVerified(true);
                toast.dismiss();
                const rcData = data?.data;

                for (let key in rcData) {
                    if (rcData[key] === null) {
                        rcData[key] = "";
                    }
                }
                setRcDetails(rcData);
                toast.success(data?.message);
            }
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
            if (error?.stack?.message === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });
    const handleRcValidate = useCallback(() => {
        if (formId && rc.length > 0) {
            toast.dismiss();
            toast.loading("Verifying RC...");
            rcVerify({
                body: {
                    loanApplicationId: formId,
                    rc,
                },
            });
        }
    }, [rc, formId, rcVerify]);
    return { setRc, rcVerified, handleRcValidate, rcDetails, setRcVerified, setRcDetails };
};

export default useRcVerify;