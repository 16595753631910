import { Fragment, memo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Grid } from "@mui/material";
import { useUpdateProspectiveUser } from "__generated__/apiComponents";
import { HandleNextDialog } from "components/Dialog";
import { useError, useSaveForm } from "hooks";
import Address from "./Components/Address";
import ContactDetails from "./Components/ContactDetails";
import RoleDetails from "./Components/RoleDetails";
import SocialMedia from "./Components/SocialMedia";
import "./ContactForm.styles.scss";

const ContactForm = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const [open, setOpen] = useState(false);
    const contactDetails = useWatch({ name: "contact" });
    const saveForm = useSaveForm();

    const handleProceed = () => {
        setCurrentStep(1);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Grid container id="contact-form" data-testid="contact-form" direction="column" justifyContent="center" alignItems="center">
                <ContactDetails />
                <Address />
                {["fi", "dealer", "manufacturer"].includes(contactDetails?.role) && <RoleDetails />}
                <SocialMedia />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={saveForm}
                    variant="contained"
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(ContactForm, () => true);
