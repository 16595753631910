import { env } from "../../env";
import { getGenericPostRequest, getGenericGetRequest, buildURLs, buildKeys, getGenericPutRequest } from "../utils";

const BASE_URLS = {
    //GET requests

    // Post requests
    LOGIN: "login",
    REGISTER: "register",
    FORGOT_PASSWORD: "forgot-password",
    CHANGE_PASSWORD: "change-password",
    RESET_PASSWORD: "reset-password",
    TOKEN_REFRESH: "token-refresh",
    CHANGE_EMAIL: "change-email",
    RESET_EMAIL: "reset-email",
};

let prefix;
if (env.REACT_APP_API_URL) {
    prefix = `${env.REACT_APP_API_URL}`;
} else {
    prefix = `${env.REACT_APP_API_URL}`;
}

prefix += "user/";

const URLS = buildURLs({
    urls: BASE_URLS,
    prefix,
});

// Export all generic functions
// GET requests

export const checkResetPassword = getGenericGetRequest(URLS.RESET_PASSWORD);

// POST requests
export const login = getGenericPostRequest(URLS.LOGIN);
export const register = getGenericPostRequest(URLS.REGISTER);
export const refreshToken = getGenericPostRequest(URLS.TOKEN_REFRESH);
export const forgotPassword = getGenericPostRequest(URLS.FORGOT_PASSWORD);
export const resetPassword = getGenericPostRequest(URLS.RESET_PASSWORD);
export const changePassword = getGenericPostRequest(URLS.CHANGE_PASSWORD);
export const changeEmail = getGenericPostRequest(URLS.CHANGE_EMAIL);
export const verifyEmail = getGenericGetRequest(URLS.RESET_EMAIL);
export const resetEmail = getGenericPostRequest(URLS.RESET_EMAIL);

// PUT requests

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
