import { Grid, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import Image1 from "../../assets/images/01.png";
import Image2 from "../../assets/images/02.png";
import Image3 from "../../assets/images/03.png";
import Image4 from "../../assets/images/04.png";
import Image5 from "../../assets/images/05.png";
import "./Footer.styles.scss";

export interface Props {}

const Footer = ({}: Props) => {
    return (
        <footer>
            <Grid container direction="row" justifyContent="center" alignItems="center" className="footer__container">
                <Typography variant="h6" style={{ fontSize: "14px" }}>
                    &#169; SMECREDIT.All Rights reserved.
                </Typography>
                {/* <div className="links">
                    <NavLink to="#">
                        <img src={Image1} alt="" />
                    </NavLink>
                    <NavLink to="#">
                        <img src={Image2} alt="" />
                    </NavLink>
                    <NavLink to="#">
                        <img src={Image3} alt="" />
                    </NavLink>
                    <NavLink to="#">
                        <img src={Image4} alt="" />
                    </NavLink>
                    <NavLink to="#">
                        <img src={Image5} alt="" />
                    </NavLink>
                </div> */}
            </Grid>
            <Grid container className="footer_bottom_colorBox" />
        </footer>
    );
};

export default Footer;
