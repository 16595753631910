import { useFieldArray, useWatch } from "react-hook-form";
import { Box, Divider, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useMaskedFields, useUploadFile } from "hooks";
import formatLoanAccountFIName from "utils/formatLoanAcccountFIName";
import generateTestId from "utils/generateTestId";
import LoanAccountCredit from "../LoanAccountCredit";

const PersonalForm = ({
    prefix,
    index,
    setCurrentInput,
    entityType,
    entityIndex,
    pane,
}: {
    prefix: string;
    index: number;
    setCurrentInput: any;
    entityType: string;
    entityIndex: number;
    pane: string;
}) => {
    const personal = useWatch({ name: `${prefix}.personal` });

    const { fields: loanAccountCreditFields } = useFieldArray({
        name: `${prefix}.personal.${index}.loanAccountCredit`,
    });

    const { maskField } = useMaskedFields();

    return (
        <Grid container direction="column" spacing={2}>
            <Grid container item direction="row" justifyContent="flex-start" alignItems="center">
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="PAN"
                    name={`${prefix}.personal.${index}.pan`}
                    data-testid={generateTestId(`pan-${pane}`, "field")}
                    handleChange={useUploadFile({
                        name: `pan`,
                        fileAddress: `${prefix}.personal.${index}.pan`,
                    })}
                    value={personal?.[index]?.pan}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    showCross={localStorage.getItem("disable_form") !== "true" && personal?.[index]?.pan && personal?.[index]?.pan?.link !== ""}
                    handleFileModalOpen={() =>
                        setCurrentInput({
                            name: `${prefix}.personal.${index}.pan`,
                            open: true,
                            multiple: false,
                            position: 0,
                        })
                    }
                />
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Proof of Address"
                    data-testid={generateTestId(`proof-of-address-${pane}`, "field")}
                    name={`${prefix}.personal.${index}.addressProof`}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    handleChange={useUploadFile({
                        name: `addressProof`,
                        fileAddress: `${prefix}.personal.${index}.addressProof`,
                    })}
                    value={personal?.[index]?.addressProof}
                    showCross={
                        localStorage.getItem("disable_form") !== "true" &&
                        personal?.[index]?.addressProof &&
                        personal?.[index]?.addressProof?.link !== ""
                    }
                    handleFileModalOpen={() =>
                        setCurrentInput({ name: `${prefix}.personal.${index}.addressProof`, open: true, multiple: false, position: 0 })
                    }
                />
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Recent Photo"
                    data-testid={generateTestId(`recent-photo-${pane}`, "field")}
                    name={`${prefix}.personal.${index}.recentPhoto`}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    labelSubtitle="Colour passport photo"
                    handleChange={useUploadFile({
                        name: `recentPhoto`,
                        fileAddress: `${prefix}.personal.${index}.recentPhoto`,
                        typeAccepted: ["image/jpg", "image/jpeg", "image/png"],
                        dimensionCheck: { width: 200, height: 200 },
                    })}
                    value={personal?.[index]?.recentPhoto}
                    showCross={
                        localStorage.getItem("disable_form") !== "true" &&
                        personal?.[index]?.recentPhoto &&
                        personal?.[index]?.recentPhoto?.link !== ""
                    }
                    handleFileModalOpen={() =>
                        setCurrentInput({ name: `${prefix}.personal.${index}.recentPhoto`, open: true, multiple: false, position: 0 })
                    }
                />
            </Grid>
            <Grid item>
                {loanAccountCreditFields.map((field: any, internalIndex: number) => {
                    return (
                        <CommonAccordion
                            key={field.id}
                            keys={`${internalIndex}-loanAccount`}
                            title=""
                            data-testid={generateTestId(`loan-account-${internalIndex + 1}-${pane}`, "accordion")}
                            titleReactNode={
                                <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                    <Typography className="common-custom-label">{maskField(formatLoanAccountFIName(field.bankName))}</Typography>
                                    <Typography className="common-custom-label">
                                        {field.accountType.length > 20 ? field.accountType.slice(0, 20) + "..." : field.accountType}
                                    </Typography>
                                </Grid>
                            }
                            tab="document-personal"
                            className="common-accordion-field entity-container"
                        >
                            <LoanAccountCredit
                                prefix={`${prefix}.personal.${index}`}
                                entityPrefix={`${prefix.replace(".document.personal", "")}`}
                                index={internalIndex}
                                setCurrentInput={setCurrentInput}
                            />
                        </CommonAccordion>
                    );
                })}
            </Grid>
        </Grid>
    );
};

export default PersonalForm;
