import { useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { IEquipment } from "types";
import { convertSnakeCaseToHeaderCase, getLtvFromOwnMargin } from "utils";
import generateTestId from "utils/generateTestId";
import "./Loan.styles.scss";
import fixture from "./fixture";

const Loan = () => {
    const requirementLoan = useWatch({ name: "requirement.loan" });
    const loanType = useWatch({
        name: "requirement.loanType",
    });
    const errors = useWatch({ name: "errors.requirement.loan" });
    const equipments = useWatch({ name: "requirement.equipments" });
    const vehicles = useWatch({
        name: "requirement.vehicles",
    });
    const { setValue, getValues } = useFormContext();

    const PMT = (ir: number, np: number, pv: number, fv: number = 0, type: number, pvif: number) => {
        var pmt;
        if (ir === 0) return -(pv + fv) / (np === 0 ? 1 : np);

        pmt = (-ir * (pv * pvif + fv)) / (pvif - 1 === 0 ? 1 : pvif - 1);

        if (type === 1) pmt /= 1 + ir === 0 ? 1 : 1 + ir;

        return pmt;
    };

    useEffect(() => {
        let totalMargin = 0;
        let totalLoan = 0;
        let totalEMI = 0;
        const interestRate = requirementLoan?.interestRate / 12 / 100;

        const arrays = loanType === "Construction Equipment" ? equipments : vehicles;

        arrays?.forEach((item: any) => {
            let noOfUnits = item.noOfUnits || 0;
            const currentMargin = +item.ownMargin || 0;
            const currentInvoiceValue = (parseFloat(item.invoiceValue) || 0) * noOfUnits;
            const currentTenor = parseInt(item.tenor.toString()) || 0;
            const currentTime = Math.pow(1 + interestRate, currentTenor);
            const currentLoan = currentInvoiceValue - currentMargin;
            let currentEMI = PMT(interestRate, currentTenor, currentLoan, 0, 1, currentTime);
            // const currentEMI = +(currentLoan * interestRate * currentTime).toFixed(2);

            totalMargin += currentMargin;
            totalLoan += currentLoan;
            totalEMI -= currentEMI;
        });

        const totalLtV = getLtvFromOwnMargin({
            ownMargin: totalMargin,
            invoiceValue: totalLoan + totalMargin,
        });

        if (totalLoan < totalMargin) {
            setValue("requirement.loan.totalLoan", 0);
            setValue("requirement.loan.totalEmi", 0);
            setValue("requirement.loan.totalOwnMargin", `${isNaN(totalMargin) ? 0 : totalMargin}`);
            setValue("requirement.loan.totalLTV", isNaN(totalLtV) ? 0 : totalLtV);
        } else {
            setValue("requirement.loan.totalLoan", `${isNaN(totalLoan) ? 0 : totalLoan}`);
            setValue("requirement.loan.totalOwnMargin", `${isNaN(totalMargin) ? 0 : totalMargin}`);
            setValue("requirement.loan.totalEmi", `${isNaN(totalEMI) ? 0 : totalEMI}`);
            setValue("requirement.loan.totalLTV", isNaN(totalLtV) ? 0 : totalLtV);
        }
    }, [equipments, requirementLoan.interestRate, setValue]);

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`requirement.loan.${item}`) === "" || getValues(`requirement.loan.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.requirement.loan.errorsCounts`, errorsCounts);
    }, [requirementLoan]);

    return (
        <Grid container id="loan__container" direction="column" justifyContent="center" alignItems="center" data-testid="requirementLoan">
            <CommonAccordion showError={errors?.errorsCounts > 0} title={`Loan`} data-testid={generateTestId(`Loan`, "accordion")} tab="requirement">
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue }, index) => {
                        if (requirementLoan?.loanType !== "Term Loan") {
                            if (name === "repaymentFrequency" || name === "totalEmi") {
                                return "";
                            }
                        }
                        if (requirementLoan?.repaymentFrequency !== OTHER || requirementLoan?.loanType === "Overdraft") {
                            if (name === "otherRepaymentFrequency") return "";
                        }

                        if (name === "totalLoan") {
                            defaultValue = requirementLoan?.totalLoan;
                        }

                        return (
                            <CommonFields
                                key={index}
                                inputType={inputType}
                                name={`requirement.loan.${name}`}
                                options={
                                    options?.map((value) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                label={label}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                prop={
                                    name === "interestRate"
                                        ? {
                                              value: "Your current borrowing interest rate. The final interest rate will be decided by the bank after completion of loan application",
                                          }
                                        : {
                                              value: "",
                                          }
                                }
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                subtitle={subtitle}
                            />
                        );
                    })}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default Loan;
