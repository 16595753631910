import { useState } from "react";
import { useFormContext, useController, RegisterOptions } from "react-hook-form";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";

interface CommonDateFieldProps {
    showLabel: boolean;
    label: string;
    name: string;
    readOnly: boolean;
    maxDate?: Date;
    "data-testid"?: string;
    showMask?: boolean;
    reactHookFormRegisterOptions?: RegisterOptions;
    disableFuture?: boolean;
}

const CommonDateField = ({
    showLabel,
    label,
    name,
    maxDate,
    showMask,
    reactHookFormRegisterOptions,
    readOnly = false,
    "data-testid": dataTestId,
    disableFuture = true,
}: CommonDateFieldProps) => {
    const { control, getValues } = useFormContext();
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<any>("");

    const { field } = useController({
        control,
        name,
    });

    return (
        <Grid container className="common__mui__textfield__container" justifyContent="center" alignItems="center">
            <Grid item xs={showLabel ? 6 : 12}>
                <Typography className="common-label" data-testid={`${dataTestId}-label`}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={window.innerWidth < 400 ? 8 : showLabel ? 6 : 12}>
                <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: "100%" }} className="date__container">
                    <DatePicker
                        className={`common__mui__date__container ${readOnly ? "read-only" : ""}`}
                        open={open}
                        onOpen={() => {
                            if (!readOnly) setOpen(true);
                        }}
                        onClose={() => setOpen(false)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                inputProps={{
                                    "data-testid": dataTestId,
                                    readOnly: readOnly,
                                }}
                                type={showMask ? "password" : "text"}
                                onClick={(e) => {
                                    if (readOnly) {
                                        return;
                                    }
                                    e.preventDefault();
                                    setOpen(true);
                                }}
                                value={moment(field?.value, moment.ISO_8601).isValid() ? moment(field?.value).format("DD-MM-YYYY") : field.value}
                            />
                        )}
                        inputFormat="DD/MM/YYYY"
                        components={{
                            OpenPickerIcon: CalendarTodayOutlinedIcon,
                        }}
                        disableFuture={disableFuture}
                        {...(maxDate
                            ? {
                                  maxDate,
                              }
                            : {})}
                        {...field}
                        value={value}
                        onChange={(date) => {
                            if (date) {
                                setValue(date);
                                if (reactHookFormRegisterOptions?.setValueAs) {
                                    field.onChange(reactHookFormRegisterOptions.setValueAs(date.toISOString()));
                                } else field.onChange(moment(date.toISOString()).format("DD-MM-YYYY"));
                            }
                        }}
                    />
                </LocalizationProvider>
                <FormHelperText error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}>
                    {(getValues(name) === "" || getValues(name) === undefined) &&
                        getValues("showIncompleteField") == true &&
                        "This field is required"}
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default CommonDateField;
export type { CommonDateFieldProps };
