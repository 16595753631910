import { getGenericGetRequest, buildKeys } from "../utils";

const BASE_URLS = {
    //GET requests
    GET_CITY_STATE: "pincode/:code",

    // Post requests
};

const URLS = {
    GET_CITY_STATE: "https://api.postalpincode.in/pincode/:code",
};

// Export all generic functions
// GET requests
export const getCityState = getGenericGetRequest(URLS.GET_CITY_STATE, true);

// POST requests

// PUT requests

// Export query keys
const KEYS = buildKeys(BASE_URLS);
export default KEYS;
