import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "educationQualification",
        label: "Education Qualification",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            "junior_high_school_(10th_class)",
            "high_school_(12th_class)",
            "vocational_school",
            "diploma",
            "graduate",
            "engineer_graduate",
            "medicine",
            "law",
            "charted_accountant",
            "post_graduate",
            "no_formal_edcation",
            OTHER,
        ],
    },
    {
        name: "profession",
        label: "Profession",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            "practicing_doctor",
            "practicing_lawyer",
            "accountant",
            "procecutor",
            "press",
            "financier",
            "money_lender",
            "firm_or_TV_actor",
            "share_broker",
            "collection_agency",
            "direct_selling_Agent",
            "verification_agency",
            "wine_merchant",
            "bar_owner",
            "internet_gambling",
            "lottery",
            "chit_fund",
            "nidhi_company",
            "jwellery",
            "tours_and_travel_operator",
            "financial_institution",
            OTHER,
        ],
    },
    {
        name: "ownerOrEmployed",
        label: "Owned or Employed",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["owner", "employed"],
    },
    {
        name: "caste",
        label: "Caste",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            "general",
            "economically_backward_class(EBC)",
            "economically_weaker_section(EWS)",
            "other_backward_class(OBC)",
            "sheduled_caste(SC)",
            "sheduled_tribe(ST)",
            "special_backward_class(SBC)",
            OTHER,
        ],
    },
    {
        name: "subCaste",
        label: "Sub Caste",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
    },
    {
        name: "religion",
        label: "Religion",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: ["buddhist", "cristian", "hindu", "jain", "muslim", "sikh", "zorastrain", "does_not_have_a_religion", OTHER],
    },
    {
        name: "physicalDisability",
        label: "Physical Disability",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: ["yes", "no"],
    },
];

export default fixture;
