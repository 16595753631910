// @ts-nocheck
import { Fragment, useState, useContext, useEffect, useMemo } from "react";
import { useSignOut } from "react-auth-kit";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useCreateLoanApplication, useDisableLoanApplication, useFilterLoanApplications } from "__generated__/apiComponents";
import { defaultValues } from "contexts/longForm";
import SessionContext from "contexts/session";
import { ROUTES } from "globalConstants";
import { convertSnakeCaseToHeaderCase } from "utils";
import { EntityCard, AddNewLoan } from ".";
import "./HomePage.styles.scss";

const Home = ({ header }: { header: string }) => {
    const [open, setOpen] = useState(false);

    const signOut = useSignOut();

    const { authUser } = useContext(SessionContext);

    const navigate = useNavigate();

    const titles = ["All", "Open", "Submitted", "Processed", "Applied"];

    const bodies = [
        <Home header="all" />,
        <Home header="open" />,
        <Home header="submitted" />,
        <Home header="processed" />,
        <Home header="applied" />,
    ];

    // const { data } = useGetAllLoanApplication({}, {});

    const { data, refetch, isLoading, error } = useFilterLoanApplications({
        queryParams: {
            filter: header ?? "all",
        },
    });

    if (!isLoading && error) {
        toast.dismiss();
        toast.error(error?.stack?.message);
        if (error?.stack?.message === "jwt expired") {
            localStorage.clear();
            signOut();
            navigate(ROUTES.LOGIN);
        }
    }

    const { mutate: handleDeleteForm } = useDisableLoanApplication({
        onSuccess: (data: any) => {
            if (data?.data) {
                toast.dismiss();
                toast.success(data?.data);
            }
            refetch();
            handleCloseDelete();
        },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });

    const loans = useMemo(() => {
        return data ?? [];
    }, [data]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => setOpen(false);

    const [resume, setResume] = useState(false);

    const [application, setApplication] = useState({
        form_id: "",
        is_submit: false,
    });

    const [deleteLoan, setDeleteLoan] = useState(false);
    const handleOpenResume = () => setResume(true);

    const handleCloseResume = () => setResume(false);
    const handleOpenDelete = () => setDeleteLoan(true);
    const handleCloseDelete = () => setDeleteLoan(false);

    const { mutate: handleFormData } = useCreateLoanApplication({
        onSuccess: (data: any) => {
            localStorage.setItem("form_id", data?.id);
            localStorage.removeItem("is_submit");
            localStorage.removeItem("disable_form");
            handleRedirect();
        },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });
    useEffect(() => {
        if (authUser === null) {
            navigate("/login");
        }
        if (authUser) {
            refetch();
        }
    }, [authUser]);

    const handleRedirect = () => {
        const form_id = localStorage.getItem("form_id");
        if (form_id) {
            navigate(`/dashboard/${form_id}`);
        }
    };

    const handleResumeApplication = () => {
        localStorage.setItem("form_id", application?.form_id);
        localStorage.setItem("is_submit", application?.is_submit?.toString());
        if (application?.is_submit && authUser?.role !== "salesperson" && authUser?.role !== "sales_support")
            localStorage.setItem("disable_form", true);
        else localStorage.setItem("disable_form", false);
        navigate(`/dashboard/${application.form_id}`);
    };

    const handleDeleteApplication = () => {
        handleDeleteForm({
            body: {
                id: application?.form_id,
            },
        });
    };

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 350,
        bgcolor: "#eae7ed",
        border: "1px solid #000",
        borderRadius: "5px",
        boxShadow: 24,
        p: 3,
    };

    return (
        <Fragment>
            <div id="homePage__container">
                <Container id="card">
                    <Grid container alignItems="center" justifyContent="flex-start" flexWrap="wrap">
                        {isLoading ? (
                            <Grid item xs={12} container justifyContent="center" className="progress-container" alignItems="center">
                                <CircularProgress />
                            </Grid>
                        ) : (
                            <Fragment>
                                <Grid className="centered-div item" item xs={12} sm={6}>
                                    <AddNewLoan handleOpen={handleOpen} />
                                </Grid>
                                {loans?.map((item: any, index: number) => {
                                    const loanType = item?.data?.requirement?.loan?.loanType;
                                    const loanAmt = item?.data?.requirement?.loan?.totalLoan;
                                    return (
                                        <Grid className="centered-div item" item xs={12} sm={6} key={index}>
                                            <EntityCard
                                                handleOpenResume={handleOpenResume}
                                                handleOpenDelete={handleOpenDelete}
                                                setApplication={setApplication}
                                                isSubmit={item?.is_submit || false}
                                                isDisabled={item?.is_submit && authUser?.role !== "salesperson" && authUser?.role !== "sales_support"}
                                                entity_name={item?.data?.applicant?.entity?.entityName}
                                                formId={item?.id}
                                                loan_type={convertSnakeCaseToHeaderCase(loanType || "NA")}
                                                loan_amt={loanAmt}
                                                completeness={item?.form_completion_percentage?.toFixed(2) || 0}
                                                isMasked={item?.is_masked}
                                            />
                                        </Grid>
                                    );
                                })}
                            </Fragment>
                        )}
                    </Grid>
                </Container>
            </div>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                        style={{ display: "flex", justifyContent: "center", fontSize: "1.1rem", margin: "10px 0px", color: "#79797a" }}
                    >
                        Do you want to start a new loan application?
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ backgroundColor: "#1b75bc" }}
                            onClick={() => {
                                handleFormData({ body: defaultValues });
                            }}
                            data-testid="new-loan-dialog-yes-button"
                        >
                            Yes
                        </Button>
                        <Button variant="contained" onClick={() => handleClose()} style={{ backgroundColor: "#767676", marginLeft: "10px" }}>
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            <Modal open={resume} onClose={handleCloseResume} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "1.1rem",
                            margin: "10px 0px",
                            color: "#79797a",
                            textAlign: "center",
                        }}
                    >
                        {application?.is_submit && authUser?.role !== "salesperson" && authUser?.role !== "sales_support"
                            ? "Continue to view your loan Application"
                            : "Do you want to resume/complete your loan Application?"}
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#1b75bc" }}
                            onClick={() => handleResumeApplication()}
                            data-testid="resume-loan-dialog-yes-button"
                        >
                            Yes
                        </Button>
                        <Button variant="contained" onClick={() => handleCloseResume()} style={{ backgroundColor: "#767676", marginLeft: "10px" }}>
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            <Modal open={deleteLoan} onClose={handleCloseDelete} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: "1.1rem",
                            margin: "10px 0px",
                            color: "#79797a",
                            textAlign: "center",
                        }}
                    >
                        Do you want to delete your loan Application?
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            style={{ backgroundColor: "#1b75bc" }}
                            onClick={() => handleDeleteApplication()}
                            data-testid="delete-loan-dialog-yes-button"
                        >
                            Yes
                        </Button>
                        <Button variant="contained" onClick={() => handleCloseDelete()} style={{ backgroundColor: "#767676", marginLeft: "10px" }}>
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
            {/* <Footer /> */}
        </Fragment>
    );
};

export default Home;
