import { memo, useEffect, useLayoutEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { useRcVerify } from "hooks";
import isEqual from "lodash/isEqual";
import generateTestId from "utils/generateTestId";
import AssetItem from "../AssetItem";
import getVehicleCategory from "../getVehicleCategory";
import fixture from "./fixture";

type Props = {
    index: number;
    appendNewAsset: (index: number) => void;
    prefix: string;
    pane?: string;
};

const AssetTabDetails = ({ index: externalIndex, appendNewAsset, prefix, pane }: Props) => {
    const asset = useWatch({ name: `${prefix}.${externalIndex}` });
    const errors = useWatch({ name: `errors.${prefix}.${externalIndex}` });
    const { setRc, rcVerified, handleRcValidate, rcDetails, setRcDetails, setRcVerified } = useRcVerify({
        defaultRcVerified: asset?.registrationNumberVerified,
    });
    const { setValue, getValues } = useFormContext();

    useLayoutEffect(() => {
        if (asset?.registrationNumber) setRc(asset?.registrationNumber);
    }, [asset?.registrationNumber]);

    useEffect(() => {
        if (rcVerified) {
            setValue(`${prefix}.${externalIndex}.registrationNumberVerified`, true);
        }
    }, [rcVerified]);

    const activeFields: string[] = ["hasAsset"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${externalIndex}.${item}`) === "" || getValues(`${prefix}.${externalIndex}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.${externalIndex}.errorsCounts`, errorsCounts);
    }, [asset]);

    useEffect(() => {
        if (rcDetails !== null) {
            let assetToFill = asset;
            if (asset.type === "Vehicle") {
                const vehicleCategory = getVehicleCategory(rcDetails?.vehicle_category);
                assetToFill = {
                    ...assetToFill,
                    registrationNumberVerified: true,
                    manufacturer: rcDetails?.maker_description,
                    category: vehicleCategory,
                    registrationDetails: rcDetails,
                    otherCategory: vehicleCategory === OTHER ? rcDetails?.vehicle_category_description : "",
                    model: rcDetails?.maker_model,
                    yearOfManufacture: rcDetails?.manufacturing_date?.split("/")[1],
                };
            }
            if (asset.type === "Construction Equipment") {
                assetToFill = {
                    ...assetToFill,
                    manufacturer: OTHER,
                    otherManufacturer: rcDetails?.maker_description,
                    category: OTHER,
                    registrationDetails: rcDetails,
                    registrationNumberVerified: true,
                    otherCategory: rcDetails?.vehicle_category_description,
                    model: OTHER,
                    otherModel: rcDetails?.maker_model,
                    yearOfManufacture: rcDetails?.manufacturing_date?.split("/")[1],
                };
            }
            setValue(`${prefix}.${externalIndex}`, assetToFill);
        }
    }, [rcDetails]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            keys={`${prefix}.${externalIndex}`}
            data-testid={generateTestId(`Asset ${externalIndex === 0 ? "" : externalIndex + 1}-${pane}`, "accordion")}
            titleReactNode={
                <CommonFields
                    inputType={INPUT_TYPE.RADIO}
                    name={`${prefix}.${externalIndex}.hasAsset`}
                    label={`Asset ${externalIndex === 0 ? "" : externalIndex + 1}`}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                    prop={{
                        value: "Do you own any Asset ?",
                    }}
                    data-testid={generateTestId(`Asset ${externalIndex === 0 ? "" : externalIndex + 1}-${pane}`, "field")}
                    className="common-mui-accordian__radio"
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            if (e.target.value === "true") {
                                appendNewAsset(externalIndex);
                            }
                        },
                    }}
                />
            }
            showDetail={asset.hasAsset === true}
            title={`Asset ${externalIndex === 0 ? "" : externalIndex + 1}`}
            tab="income"
            defaultExpanded={asset.hasAsset === true}
        >
            {asset.hasAsset === true && (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map((props, index) => {
                        return (
                            <AssetItem
                                activeFields={activeFields}
                                props={props}
                                handleRcValidate={handleRcValidate}
                                prefix={prefix}
                                externalIndex={externalIndex}
                                index={index}
                                rcVerified={rcVerified}
                                setRcVerified={setRcVerified}
                                setRcDetails={setRcDetails}
                                pane={pane}
                            />
                        );
                    })}
                </Grid>
            )}
        </CommonAccordion>
    );
};

export default memo(AssetTabDetails, (prevProps, nextProps) => isEqual(prevProps, nextProps));
