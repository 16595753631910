import { memo } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import infoIcon from "assets/images/info.png";

type Props = {
    showLabel: boolean;
    label: string;
    labelSubtitle?: string;
    infoText?: string;
    required?: boolean;
    "data-testid"?: string;
};

const Label = memo(
    ({ showLabel, label, infoText, labelSubtitle, required, "data-testid": dataTestId }: Props) => {
        return (
            <Grid item xs={showLabel ? 6 : 0}>
                <Typography className="common-label">
                    {label && <span data-testid={dataTestId}>{label}</span>}
                    {required && <span style={{ color: "red" }}> *</span>}
                    {infoText ? (
                        <sup id="tooltip">
                            <Tooltip title={infoText} placement="top" arrow>
                                <img src={infoIcon} alt="icon" />
                            </Tooltip>
                        </sup>
                    ) : (
                        ""
                    )}
                </Typography>
                {labelSubtitle ? (
                    <FormHelperText style={{ fontStyle: "italic" }}>
                        <span>{labelSubtitle}</span>
                    </FormHelperText>
                ) : (
                    ""
                )}
            </Grid>
        );
    },
    (prevState, nextState) => prevState.showLabel === nextState.showLabel && prevState.label === nextState.label
);

export default Label;
