import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

export const StyledTextField = styled(TextField)(({ theme }) => ({
    "& .MuiOutlinedInput-root": {
        paddingLeft: 0,
        paddingRight: 0,
    },
    "& .MuiInputAdornment-positionStart": {
        backgroundColor: "#e9ecef;",
        padding: "28px 14px",
        marginRight: "0px",
        borderTopLeftRadius: "10px",
        borderBottomLeftRadius: "10px",
        border: "1px solid black",
    },
    "& .MuiInputBase-input": {
        borderRadius: "10px",
    },
    "& .MuiInputBase-inputAdornedStart": {
        borderTopLeftRadius: "0px",
        borderBottomLeftRadius: "0px",
    },

    "& .MuiInputAdornment-positionEnd": {
        backgroundColor: "#e9ecef;",
        padding: "28px 14px",
        marginLeft: "0px",
        borderTopRightRadius: "10px",
        borderBottomRightRadius: "10px",
        border: "1px solid black",
    },

    "& .MuiInputBase-inputAdornedEnd": {
        borderTopRightRadius: "0px",
        borderBottomRightRadius: "0px",
    },

    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "transparent !important",
        borderRadius: "0.5rem",
    },
}));

export const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0,
    },
    "&:before": {
        display: "none",
    },
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1),
    },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
