/* eslint-disable max-lines */
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import Typography from "@mui/material/Typography";
import { useCheckResetPasswordLink, useResetPassword } from "__generated__/apiComponents";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { ROUTES } from "globalConstants";
import qs from "query-string";
import "./ResetPassword.styles.scss";

const ResetPassword = () => {
    const location = useLocation();

    const navigate = useNavigate();

    const { email, token } = qs.parse(location.search);

    const { data, error } = useCheckResetPasswordLink({
        queryParams: {
            token: (token as string) ?? "",
        },
    });

    useEffect(() => {
        if (error) {
            toast.dismiss();
            toast.error("Reset Link Expired");
            navigate(ROUTES.LOGIN);
        } else {
        }
    }, [error]);

    const { mutate: resetPasswordMutation } = useResetPassword({
        onSuccess: (data: any) => {
            toast.dismiss();
            toast.success(data);
            navigate(ROUTES.LOGIN);
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });

    const [{ password, confirmPassword }, setValues] = useState({
        password: "",
        confirmPassword: "",
    });

    const [showPassword, setShowPassword] = useState(false);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const handlePasswordChange = () => {
        if (password === "") {
            toast.error("Password is required");

            return;
        } else if (confirmPassword === "") {
            toast.error("Confirm Password is required");

            return;
        } else if (password !== confirmPassword) {
            toast.error("Password Doesn't Matched");

            return;
        }
        if (email && token)
            resetPasswordMutation({
                body: {
                    email: email as string,
                    token: token as string,
                    newPassword: password,
                },
            });
    };

    return (
        <Grid container direction={"column"} id="reset-password__container">
            <Navbar />
            <Grid item xs={12} justifyContent="center" alignItems="center" container>
                <Typography variant="h4" component="h4" gutterBottom>
                    Reset Password
                </Typography>
            </Grid>
            <Grid item xs={6} container justifyContent="center" alignItems="center" direction={"column"}>
                <FormControl className="field" variant="outlined" required>
                    <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                    <OutlinedInput
                        fullWidth
                        id="outlined-adornment-password"
                        type={showPassword ? "text" : "password"}
                        value={password}
                        className="input-field"
                        name="password"
                        onChange={(e) => {
                            setValues({
                                password: e.target.value,
                                confirmPassword,
                            });
                        }}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setShowPassword(!showPassword);
                                    }}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="New Password"
                    />
                </FormControl>
                <FormControl className="field" variant="outlined" required>
                    <InputLabel htmlFor="outlined-adornment-cpassword">Confirm Password</InputLabel>
                    <OutlinedInput
                        fullWidth
                        className="input-field"
                        id="outlined-adornment-cpassword"
                        type={showConfirmPassword ? "text" : "password"}
                        value={confirmPassword}
                        name="confirmPassword"
                        onChange={(e) => {
                            setValues({
                                password,
                                confirmPassword: e.target.value,
                            });
                        }}
                        required
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => {
                                        setShowConfirmPassword(!showConfirmPassword);
                                    }}
                                    edge="end"
                                >
                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Confirm Password"
                    />
                </FormControl>
                <Button onClick={handlePasswordChange} variant="contained" className="reset-btn">
                    Reset
                </Button>
            </Grid>

            <Footer />
        </Grid>
    );
};

export default ResetPassword;
