import { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { useCityStateValue } from "hooks";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

interface Props {
    prefix?: string;
    pane?: string;
}

const ResidentialAddress = ({ prefix, pane }: Props) => {
    const resAddress = useWatch({
        name: `${prefix}.residentialAddress`,
    });
    const errors = useWatch({
        name: `errors.${prefix}.residentialAddress`,
    });

    const { setValue, getValues } = useFormContext();
    const { isLoading } = useCityStateValue({
        pincode: resAddress.postalCode,
        cityName: `${prefix}.residentialAddress.city`,
        stateName: `${prefix}.residentialAddress.state`,
    });

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.residentialAddress.${item}`) === "" || getValues(`${prefix}.residentialAddress.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.residentialAddress.errorsCounts`, errorsCounts);
    }, [resAddress]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="Residential Address"
            tab="personal"
            data-testid={generateTestId(`residential-address-${pane}`, "accordion")}
            keys={`${prefix}.residentialAddress`}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(({ name, inputType, options, subtitle, label, editable }, index) => {
                    let ownerDetails = ["prefix", "firstName", "middleName", "lastName"];
                    if (resAddress.ownership !== "Rented") {
                        if (name === "monthlyRent") {
                            return "";
                        }
                    }
                    if (resAddress.ownership !== "Owned by relation") {
                        if (name === "relation") {
                            return "";
                        }
                    }
                    if (ownerDetails.includes(name)) {
                        name = `ownerDetails.${name}`;
                    }

                    activeFields.push(name);
                    return (
                        <CommonFields
                            key={index}
                            inputType={inputType}
                            name={`${prefix}.residentialAddress.${name}`}
                            subtitle={subtitle}
                            data-testid={generateTestId(`${label}-residential-${pane}`, "field")}
                            options={
                                options?.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                })) || []
                            }
                            label={label}
                            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                            adorment={
                                isLoading && (name == "city" || name == "state") ? (
                                    <CircularProgress size={20} style={{ marginRight: "1rem" }} />
                                ) : (
                                    <></>
                                )
                            }
                        />
                    );
                })}
            </Grid>
        </CommonAccordion>
    );
};

export default ResidentialAddress;
