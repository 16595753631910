import { Fragment, useState } from "react";
import { useWatch, useFieldArray } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import { defaultDocumentPersonalDetailsFixture } from "fixtures";
import { OTHER } from "globalConstants";
import generateTestId from "utils/generateTestId";
import { PersonalForm } from "../..";
import DeselectFileModal from "../DeselectFileModal";

const PersonalTab = ({ prefix, entityType, index, pane }: { prefix: string; entityType: string; index: number; pane: string }) => {
    let personalPrefix = `${entityType}.${entityType === "applicant" ? "" : `${index}`}`;

    const numberOfPersons = useWatch({
        name: `${personalPrefix}.entity.managementDetails.noOfPersons`,
    });

    const partnerDetails = useWatch({
        name: `${personalPrefix}.entity.ownershipDetails.partnerDetails`,
    });

    const directorDetails = useWatch({
        name: `${personalPrefix}.entity.shareholdingDirectors.directorDetails`,
    });

    const business = useWatch({ name: `${personalPrefix}.entity.business` });
    let titles: { title: string; index: number }[] = [];

    switch (business) {
        case "Partnership":
            partnerDetails.forEach((partner: any, i: number) => {
                if (partner?.entityType === "applicant") {
                    titles.push({ title: `Partner ${i + 1}`, index: i });
                }
            });
            break;
        case "Registered Under Company Act":
            directorDetails.forEach((director: any, i: number) => {
                if (director?.entityType === "applicant") {
                    titles.push({ title: `Director ${i + 1}`, index: i });
                }
            });

            break;
        case OTHER:
            for (let i = 0; i < numberOfPersons; i++) {
                titles.push({ title: `Person ${i + 1}`, index: i });
            }
            break;
        default: {
            titles.push({ title: `Personal`, index: 0 });
        }
    }

    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });
    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };
    return (
        <Fragment>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                {titles.map(({ title, index }, i) => (
                    <CommonAccordion data-testid={generateTestId(`${title}-${pane}`, "accordion")} title={title} tab="document">
                        <PersonalForm
                            prefix={prefix}
                            index={index}
                            entityIndex={index}
                            entityType={entityType}
                            setCurrentInput={setCurrentInput}
                            pane={`${title}-${pane}`}
                        />
                    </CommonAccordion>
                ))}
            </Grid>
            <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
        </Fragment>
    );
};

export default PersonalTab;
