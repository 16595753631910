import { Fragment, memo, useState } from "react";
import { useFieldArray, useWatch } from "react-hook-form";
import { useFormContext } from "react-hook-form";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { HandleNextDialog } from "components/Dialog";
import TabsWrapper from "components/TabsWrapper";
import { defaultTradeReferenceFixture, defaultReferenceCheckFixture } from "fixtures";
import { useSaveForm } from "hooks";
import "./Reference.style.scss";
import { TradeReferences } from "./components";
import Checks from "./components/Checks";
import { DeleteTradeReference } from "./components/TradeReferencesTab/components";
import AddAnotherReference from "./components/TradeReferencesTab/components/AddAnotherReference";

const ReferencesForm = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const { fields, append, remove } = useFieldArray({
        name: "references.tradeReferences",
    });
    const {
        fields: checkFields,
        append: appendCheck,
        remove: removeCheck,
    } = useFieldArray({
        name: "references.checks",
    });

    const errors = useWatch({ name: "errors.references" });

    const { setValue } = useFormContext();

    const appendNewReference = () => {
        append(defaultTradeReferenceFixture);
        appendCheck(defaultReferenceCheckFixture);
    };

    const handleDeleteReference = (index: number) => {
        remove(index);
        removeCheck(index);

        const tradeReferenceErrors = errors?.tradeReferences?.filter((_: any, i: number) => i !== index);
        setValue(`errors.references.tradeReferences`, tradeReferenceErrors);
    };

    const [open, setOpen] = useState(false);

    const handleSave = useSaveForm();

    const handleProceed = () => {
        setCurrentStep(4);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Grid container id="form">
                <TabsWrapper
                    pane="entity"
                    titles={["References", "Checks"]}
                    bodies={[
                        <Grid container id="guarantor-form__container">
                            {fields.map((field, index: number) => (
                                <div key={field.id} className="asset__container" style={{ width: "600px" }}>
                                    <TradeReferences index={index} />
                                    <DeleteTradeReference index={index} handleDeleteReference={handleDeleteReference} />
                                </div>
                            ))}
                            <AddAnotherReference append={appendNewReference} />
                        </Grid>,
                        <Grid container id="guarantor-form__container">
                            {checkFields.map((_, index: number) => (
                                <div key={index} className="asset__container" style={{ width: "600px" }}>
                                    <Checks index={index} />
                                </div>
                            ))}
                        </Grid>,
                    ]}
                />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container " container>
                <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(2)}>
                    <KeyboardArrowLeftIcon id="left-arrow" />
                    Back
                </Button>
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={handleSave}
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    className="btn-arrow"
                    onClick={() => {
                        let shouldProceed = true;

                        for (let i in errors) {
                            if (i === "tradeReferences") {
                                const tradeReferenceErrors = errors[i];
                                for (let j in tradeReferenceErrors) {
                                    if (tradeReferenceErrors[j]?.errorsCounts > 0) {
                                        shouldProceed = false;
                                    }
                                }
                            }
                        }
                        if (shouldProceed) handleProceed();
                        else {
                            setValue("showIncompleteField", true);
                            setOpen(true);
                        }
                    }}
                >
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(ReferencesForm, () => true);
