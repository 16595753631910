import { Fragment } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import EastIcon from "@mui/icons-material/East";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Logo from "assets/images/logo.png";
import LoanImage1 from "assets/images/001.jpg";
import LoanImage2 from "assets/images/002.jpg";
import LoanImage3 from "assets/images/003.jpg";
import LoanImage4 from "assets/images/004.jpg";
import ShadowLargeImage from "assets/images/shadow_large.jpg";
import MessageImage from "assets/images/message_line.png";
import ContactImage from "assets/images/contact.png";
import VoiceImage from "assets/images/voice.png";
import CheckedImage from "assets/images/check.png";
import Layer40Image from "assets/images/layer-40.png";
import LoanSteps from "pages/Home/Components/LoanSteps";
import "./Home.styles.scss";

export interface Props {}

const Home = () => {
    return (
        <Fragment>
            <Navbar />
            <div id="home__container">
                <section id="home__front">
                    <div id="home__navigator">
                        <Typography variant="h5" maxWidth={300}>
                            Finance your growth <br /> Buy your machine
                        </Typography>
                        <Button variant="contained" className="know_more_button">
                            KNOW MORE <EastIcon />
                        </Button>
                    </div>
                </section>

                <LoanSteps />

                <Typography variant="h4" textAlign="center">
                    Loans to finance your growth
                </Typography>
                <Container style={{ maxWidth: "1080px" }}>
                    <Grid container alignItems="center" justifyContent="center" style={{ margin: "auto" }}>
                        <Grid style={{ maxWidth: "auto" }} className="centered-div" item xs={12} sm={6} md={3}>
                            <Box className="loan-types centered-div">
                                <div className="property centered-div">
                                    <img src={LoanImage1} alt="" />
                                    <Typography variant="h5">Construction Equipment</Typography>
                                </div>
                                <img src={ShadowLargeImage} alt="" />
                            </Box>
                        </Grid>
                        <Grid className="centered-div" item xs={12} sm={6} md={3}>
                            <Box className="loan-types centered-div">
                                <div className="property centered-div">
                                    <img src={LoanImage2} alt="" />
                                    <Typography variant="h5">Business Equipment</Typography>
                                </div>
                                <img src={ShadowLargeImage} alt="" />
                            </Box>
                        </Grid>
                        <Grid className="centered-div" item xs={12} sm={6} md={3}>
                            <Box className="loan-types centered-div">
                                <div className="property centered-div">
                                    <img src={LoanImage3} alt="" />
                                    <Typography variant="h5">Overdraft and Term Loans</Typography>
                                </div>
                                <img src={ShadowLargeImage} alt="" />
                            </Box>
                        </Grid>
                        <Grid className="centered-div" item xs={12} sm={6} md={3}>
                            <Box className="loan-types centered-div">
                                <div className="property centered-div">
                                    <img src={LoanImage4} alt="" />
                                    <Typography variant="h5">Restructuring and Refinancing</Typography>
                                </div>
                                <img src={ShadowLargeImage} alt="" />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <Typography variant="h4" textAlign="center">
                    We are wired differently
                </Typography>
                <Typography variant="h6" textAlign="center" className="gray-text">
                    We make getting a loan as easy as online shopping - swift, smooth, safe.
                </Typography>
                <Container>
                    <Grid container spacing={2} columns={12} className="properties-container">
                        <Grid className="centered-div" item xs={12} sm={6} md={4}>
                            <Box className="properties centered-div">
                                <img src={MessageImage} alt="" />
                                <Typography variant="h5">Advanced machine-learning</Typography>
                            </Box>
                        </Grid>
                        <Grid className="centered-div" item xs={12} sm={6} md={4}>
                            <Box className="properties centered-div">
                                <img src={ContactImage} alt="" />
                                <Typography variant="h5">Models that understand you</Typography>
                            </Box>
                        </Grid>
                        <Grid className="centered-div" item xs={12} sm={12} md={4}>
                            <Box className="properties centered-div">
                                <img src={VoiceImage} alt="" />
                                <Typography variant="h5">Algos for the best offer</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
                <div className="gray-background">
                    <Container>
                        <Grid container spacing={0} columns={12} justifyContent="center" className="reasons_grid_wrapper">
                            <Grid className="" item xs={12} sm={6} md={6}>
                                <Grid className="friendly-image" container direction="row" justifyContent="flex-end">
                                    <img src={Layer40Image} alt="" />
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6}>
                                <Box className="reasons centered-div">
                                    <div className="why">
                                        <Typography variant="h3">
                                            Why <img src={Logo} alt="" />?
                                        </Typography>
                                    </div>
                                    <Grid container direction="row" className="reason">
                                        <Grid item xs={2}>
                                            <img src={CheckedImage} alt="" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h6">Asia's 1st construction financing platform</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" className="reason">
                                        <Grid item xs={2}>
                                            <img src={CheckedImage} alt="" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h6">Heavy equipment and building material focus</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" className="reason">
                                        <Grid item xs={2}>
                                            <img src={CheckedImage} alt="" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h6">Free credit rating through industry-centric models</Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid container direction="row" className="reason">
                                        <Grid item xs={2}>
                                            <img src={CheckedImage} alt="" />
                                        </Grid>
                                        <Grid item xs={10}>
                                            <Typography variant="h6">Quick, easy and intuitive customer onboarding</Typography>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </div>
            </div>
            <Footer />
        </Fragment>
    );
};

export default Home;
