import { useState, Fragment, useMemo, memo, useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import shortFormIcon1 from "assets/images/short_form_icon_1.png";
import shortFormIcon2 from "assets/images/short_form_icon_2.png";
import shortFormIcon3 from "assets/images/short_form_icon_3.png";
import shortFormIcon4 from "assets/images/short_form_icon_4.png";
import shortFormIcon5 from "assets/images/short_form_icon_5.png";
import Navbar from "components/Navbar";
import { AccordionProvider } from "contexts/accordion";
import { withLongFormProvider } from "contexts/longForm";
import { TabsWrapperProvider } from "contexts/tabsWrapper";
import { useIsSmallScreen } from "hooks";
import "styles/Forms/AllForms.scss";
import { usePrompt } from "../../hooks/usePrompt";
import "./Dashboard.styles.scss";
import { DocumentForm, GuarantorForm, RequirementForm, IncomeForm } from "./components";
import Entity from "./components/Entity";
import PersonalTab from "./components/PersonalTab";
import { useSetDefaultValues } from "./hooks";

const Dashboard = () => {
    const [currentStep, setCurrentStep] = useState(0);

    const { loading, methods, formData } = useSetDefaultValues();

    const authToken = localStorage.getItem("authToken");

    const navigate = useNavigate();

    const showPrompt = authToken !== null && !loading && formData.length !== 0;

    usePrompt("Are you sure you want to leave?", showPrompt);

    // Empty Commit

    // TODO: Change steps to tabs
    const steps = useMemo(
        () => [
            {
                title: "REQUIREMENT",
                form: <RequirementForm setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon1,
            },
            {
                title: "ENTITY",
                form: <Entity setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon2,
            },
            {
                title: "INCOME",
                form: <IncomeForm setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon3,
            },
            {
                title: "REFERENCES",
                form: <GuarantorForm setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon5,
            },
            {
                title: "PERSONAL",
                form: <PersonalTab setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon4,
            },
            {
                title: "DOCUMENT",
                form: <DocumentForm setCurrentStep={setCurrentStep} />,
                icon: shortFormIcon3,
            },
        ],
        []
    );
    const isSmallScreen = useIsSmallScreen();

    useEffect(() => {
        if (!authToken) {
            navigate("/login");
        }
    }, [authToken]);

    // this is needed to enable proxies, do not delete
    const errors = methods.formState.errors;

    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname.includes("dashboard")) {
        } else {
            // @ts-ignore
            setValue("disabled", true);
            localStorage.setItem("is_submit", "true");
        }
    }, []);

    return (
        <FormProvider {...methods}>
            <Navbar />
            <Grid container direction="row" justifyContent="center" alignItems="stretch" id="dashboard-container">
                <Grid item>
                    <Grid
                        container
                        id="button-list-container"
                        direction={isSmallScreen ? "row" : "column"}
                        justifyContent="center"
                        alignItems="flex-end"
                    >
                        {steps.map((step, index) => (
                            <Fragment key={index}>
                                {isSmallScreen ? (
                                    <Grid item xs={isSmallScreen ? "auto" : 2} key={index} className="mobile-btn">
                                        <Button className={`button ${index === currentStep ? "active" : ""}`} onClick={() => setCurrentStep(index)}>
                                            <Grid container direction="column" justifyContent="center" alignItems="center">
                                                <Box className="button-img" component="img" src={step.icon} />
                                                <span>{step.title}</span>
                                            </Grid>
                                        </Button>
                                    </Grid>
                                ) : (
                                    <Button
                                        key={index}
                                        className={`button ${index === currentStep ? "active" : ""}`}
                                        onClick={() => setCurrentStep(index)}
                                        data-testid={`${step.title.toLowerCase()}-tab`}
                                    >
                                        <Grid item container direction="column" justifyContent="center" alignItems="center">
                                            <Box className="button-img" component="img" src={step.icon} />
                                            <span>{step.title}</span>
                                        </Grid>
                                    </Button>
                                )}
                            </Fragment>
                        ))}
                    </Grid>
                </Grid>
                <Grid item xs={true} id="form-container">
                    {loading ? (
                        <CircularProgress />
                    ) : (
                        <Grid container direction="row">
                            <Grid item xs={isSmallScreen ? 11 : 7}>
                                <TabsWrapperProvider>
                                    <AccordionProvider>{steps[currentStep].form}</AccordionProvider>
                                </TabsWrapperProvider>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default withLongFormProvider(memo(Dashboard));
