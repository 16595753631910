import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import { defaultLoanFixture, defaultLoanAccountFixture } from "fixtures";
import { LoanOutstanding } from "../..";
import DeleteLoanOutstanding from "./DeleteLoanOutstanding";

const LoanTab = ({
    loanPrefixInReactForm,
    entityType,
    entityIndex,
    pane,
}: {
    loanPrefixInReactForm: string;
    entityType: string;
    entityIndex: number;
    pane?: string;
}) => {
    const { fields, append, remove } = useFieldArray({
        name: `${loanPrefixInReactForm}`,
    });

    const { append: appendLoanAccount, remove: removeLoanAccount } = useFieldArray({
        name: `${entityType}.${entityType === "applicant" ? "" : `${entityIndex}.`}document.business.loanAccountAdditional`,
    });

    const errors = useWatch({
        name: `errors.${loanPrefixInReactForm}`,
    });

    const { setValue } = useFormContext();

    const handleDeleteLoan = (index: number) => {
        remove(index);
        removeLoanAccount(index);
        const loanErrors = errors?.filter((_: any, i: number) => i !== index);
        setValue(`errors.${loanPrefixInReactForm}`, loanErrors);
    };

    const handleAppend = (index: number) => {
        if (index == fields.length - 1) {
            append(defaultLoanFixture);
            appendLoanAccount(defaultLoanAccountFixture);
        }
    };
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            {fields.map((field, index: number) => (
                <div key={field.id} className="loan__container" style={{ width: "inherit" }}>
                    <LoanOutstanding index={index} prefix={loanPrefixInReactForm} handleAppend={handleAppend} pane={pane} />
                    <DeleteLoanOutstanding index={index} handleDeleteLoan={handleDeleteLoan} />
                </div>
            ))}
        </Grid>
    );
};

export default LoanTab;
