import { RegisterOptions, UseControllerReturn, UseFormGetFieldState, useController, useFormContext } from "react-hook-form";

const WrapWithFormController = ({
    children,
    name,
    rules,
}: {
    children: (methods: UseControllerReturn, getFieldState: UseFormGetFieldState<any>) => any;
    name: string;
    rules: RegisterOptions;
}) => {
    const { control, getFieldState } = useFormContext();
    const methods = useController({
        control,
        name,
        rules,
    });

    return children(methods, getFieldState);
};

export default WrapWithFormController;
