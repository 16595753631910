import { CommercialVehicleCreateInput } from "__generated__/apiSchemas";

const fixture: CommercialVehicleCreateInput = {
    newOrUsed: "new",
    registrationNumber: "",
    registrationNumberVerified: false,
    manufacturer: "TATA_MOTORS",
    otherManufacturer: "",
    sellerName: "",
    otherSellerName: "",
    noOfUnits: 1,
    category: "RIGID_TRUCKS",
    otherCategory: "",
    model: "TATA_ULTRA_1918.T",
    otherModel: "",
    invoiceValue: "",
    disableInvoiceValue: false,
    basicPrice: 0,
    tcsPercentage: 0,
    gst: 0,
    ownMargin: 0,
    disableOwnMargin: false,
    salesAgent: {
        username: "",
        fullName: "",
    },
    sendEmailTo: "",
    loanToValue: 0,
    tenor: 0,
    manufacturerDate: "",
};

export default fixture;
