import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import BalanceSheet from "./BalanceSheet";
import CashFlow from "./CashFlow";
import GSTReturn from "./GSTReturn";
import ITReturn from "./ITReturn";
import ProfitAndLoss from "./ProfitAndLoss";

const IncomeForm = ({
    prefix,
    setCurrentInput,
    entityType,
    index,
    pane,
}: {
    prefix: string;
    setCurrentInput: any;
    entityType: string;
    index: number;
    pane?: string;
}) => {
    const businessIncome = useWatch({ name: `${entityType}.${entityType === "applicant" ? "" : `${index}.`}income.income.businessIncome` });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {businessIncome.hasFiledIncomeTaxReturn === true && <ITReturn prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />}
            <GSTReturn prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
            <BalanceSheet prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
            <ProfitAndLoss prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
            <CashFlow prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
        </Grid>
    );
};

export default IncomeForm;
