import React from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { useIsSmallScreen } from "hooks";
import IciciLogo from "assets/images/Icici-bank-logo.png";
import HDFCLogo from "assets/images/hdfc-bank-logo-1.png";
import InduslandLogo from "assets/images/indusland-_bank.png";
import AxisLogo from "assets/images/Axis-Bank-logo.png";
import SundaramLogo from "assets/images/sundaram-finance-logo.png";
const Offers = ({ setCurrentStep }: { setCurrentStep: React.Dispatch<React.SetStateAction<number>> }) => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="offer-form">
                <Grid item xs={isSmallScreen ? 12 : 7}></Grid>
                <Grid item xs={isSmallScreen ? 12 : 5}>
                    <Typography variant="h5" className="short-form__title" component="h4">
                        Available Offers
                    </Typography>
                    <div className="short-form__option-body">
                        <Typography variant="h6" component="p" id="offers">
                            <b>70 - 90%</b>&nbsp; LTV range
                            <br />
                            <b>10 - 15%</b>&nbsp; Interest rate range
                            <br />
                            <b>36 - 60</b>&nbsp; Tenor in months
                            <br />
                        </Typography>
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="offers__images">
                            <img src={IciciLogo} alt="ICICI Bank" />
                            <img src={HDFCLogo} alt="HDFC Bank" />
                            <img src={InduslandLogo} alt="Indusland Bank" />
                            <img src={AxisLogo} alt="Axis Bank" />
                            <img src={SundaramLogo} alt="Sundaram Bank" />
                        </Grid>
                        <Typography variant="h6" className="offer_text" component="p">
                            For a <b>free</b> customised offer, <b>register</b> now!
                        </Typography>
                    </div>
                    <br />
                    <Grid justifyContent="flex-end" style={{ width: "80%" }} container>
                        <Button variant="contained" className="btn-arrow">
                            Submit
                        </Button>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(3)}>
                            <ArrowBackIosIcon />
                            Back
                        </Button>
                        <Button variant="contained" className="btn-arrow">
                            Register
                        </Button>
                    </Grid>
                    <Grid container direction="row" className="progress">
                        <Grid container direction="column" className="progress-bar" id="offer">
                            100%
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default Offers;
