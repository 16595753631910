import { Fragment, useEffect, useLayoutEffect, useMemo } from "react";
import { useFormContext, useWatch, Controller } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { useMaskedFields, useRcVerify } from "hooks";
import { ICommonFixture } from "types";
import { combineClassNames, convertSnakeCaseToHeaderCase, convertSnakeCaseToTitleCase } from "utils";
import generateTestId from "utils/generateTestId";
import { OwnMarginOrLTV, BasicPriceOrInvoiceValue } from "..";

const VehicleItem = ({
    props: {
        inputType,
        disableReactHookForm,
        name,
        readOnly,
        className,
        infoText,
        label,
        options,
        subtitle,
        placeholder = "",
        reactHookFormRegisterOptions,
        getOptions,
        dataTestId,
        showMask,
    },
    index,
    isSalesPersonLoading,
    handleSelectValue,
    salesAgentNames,
    disableSalesAgent,
    isSalesPerson,
    activeFields,
}: {
    props: ICommonFixture;
    index: number;
    isSalesPersonLoading: boolean;
    salesAgentNames: any;
    handleSelectValue: (value: string) => void;
    disableSalesAgent: boolean;
    isSalesPerson: boolean;
    activeFields: string[];
}) => {
    const { setValue, register, control, getValues } = useFormContext();
    const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly;
    isSalesPersonLoading = false;

    const { isMasked } = useMaskedFields();

    const vehicle = useWatch({
        name: `requirement.vehicles.${index}`,
    });
    const basicPrice = vehicle.basicPrice;
    const tcsPercentage = vehicle.tcsPercentage;

    let currentOptions = options;
    let currentLabelGenerator = convertSnakeCaseToHeaderCase;
    const manufacturer = vehicle.manufacturer;

    switch (name) {
        case "category":
            currentOptions = getOptions!({
                manufacturer: vehicle.manufacturer,
            });
            break;
        case "model":
            currentOptions = getOptions!({
                category: `${vehicle.category}`,
            });
            currentLabelGenerator = convertSnakeCaseToTitleCase;
            break;
    }
    useEffect(() => {
        if (vehicle.manufacturer === OTHER) {
            setValue(`requirement.vehicles.${index}.category`, OTHER);
            setValue(`requirement.vehicles.${index}.model`, OTHER);
        } else {
            setValue(`requirement.vehicles.${index}.category`, "RIGID_TRUCKS");
        }
    }, [vehicle.manufacturer]);

    useEffect(() => {
        if (vehicle.category === "RIGID_TRUCKS") {
            setValue(`requirement.vehicles.${index}.model`, "TATA_ULTRA_1918.T");
        } else if (vehicle.category === "TRACTOR_TRAILER") {
            setValue(`requirement.vehicles.${index}.model`, "TATA_SIGNA_4018.S_&_LPS_4018_COWL");
        } else if (vehicle.category === "TIPPERS_(Signa_&_Prima)") {
            setValue(`requirement.vehicles.${index}.model`, "TATA_SIGNA_1923.K");
        } else {
            setValue(`requirement.vehicles.${index}.model`, OTHER);
        }
    }, [vehicle.category]);

    const { setRc, rcVerified, handleRcValidate } = useRcVerify({
        defaultRcVerified: vehicle.registrationNumberVerified,
    });

    useEffect(() => {
        if (rcVerified) {
            setValue(`requirement.vehicles.${index}.registrationNumberVerified`, true);
        }
    }, [rcVerified]);

    const memoizedOptions = useMemo(
        () =>
            currentOptions?.map((value) => ({
                label: currentLabelGenerator(value) === "Other" ? "OTHER" : currentLabelGenerator(value),
                value,
            })) || [],
        [currentOptions]
    );

    let value: string | number = "";

    useLayoutEffect(() => {
        if (name == "model" && (vehicle.manufacturer === OTHER || !currentOptions?.length)) {
            setValue(`requirement.vehicles.${index}.model`, OTHER);
        }
    }, [manufacturer, setValue]);

    useLayoutEffect(() => {
        setRc(vehicle.registrationNumber);
    }, [vehicle.registrationNumber]);

    switch (name) {
        case "category":
            if (vehicle.newOrUsed === "used") return <Fragment />;
            break;

        case "model":
            if (vehicle.newOrUsed === "used") return <Fragment />;
            // if (vehicle.manufacturer === OTHER || !currentOptions?.length) {
            //     inputType = INPUT_TYPE.TEXT;
            // } else {
            //     inputType = INPUT_TYPE.SELECT;
            // }

            break;
        case "basicPriceOrInvoiceValue":
            activeFields.push("basicPrice", "invoiceValue");
            return <BasicPriceOrInvoiceValue index={index} key={name} />;

        case "ownMarginOrLtv":
            activeFields.push("ownMargin", "loanToValue");
            return <OwnMarginOrLTV key={name} index={index} />;

        case "gst":
            value = +((basicPrice || 0) * 0.18).toFixed(2) || "";
            break;

        case "tcsPercentage":
            const gst = +(basicPrice * 0.18).toFixed(2) || 0;
            value = (((basicPrice + gst) * tcsPercentage) / 100).toFixed(2);
            value = isNaN(Number(value)) ? "0" : value;
            break;
        default:
            break;
    }

    if (vehicle.newOrUsed === "new") {
        if (name === "registrationNumber" || name === "manufacturerDate") return <Fragment />;
    }

    if (vehicle.newOrUsed === "used") {
        if (name === "manufacturer" || name === "model" || name === "noOfUnits") return <Fragment />;

        if (name == "sellerName") {
            if (index > 0) {
                readOnly = true;
            }
            inputType = INPUT_TYPE.TEXT;
        }
    }

    if (name === "sellerName" && index > 0) {
        readOnly = true;
    }
    if (name === "salesAgent.fullName" && index > 0) {
        readOnly = true;
        inputType = INPUT_TYPE.TEXT;
    }

    if (name === "gst" || name === "tcs") {
        value = +value;
    }

    if (name === "salesAgent.fullName") {
        if (index === 0) {
            activeFields.push("salesAgent.fullName");
            return (
                <Fragment key={index}>
                    {isSalesPerson ? (
                        <CommonFields
                            inputType={INPUT_TYPE.TEXT}
                            label={label}
                            name={`requirement.vehicles.${index}.${name}`}
                            options={memoizedOptions}
                            subtitle={subtitle}
                            placeholder={placeholder}
                            className={className}
                            readOnly={true}
                            prop={{
                                value: infoText || "",
                            }}
                            value={value}
                            disableReactHookForm={disableReactHookForm}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                        />
                    ) : (
                        <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                            <Grid item xs={6}>
                                <Typography className="common-label" data-testid={`sales-agent-vehicle-${index + 1}-field-label`}>
                                    {label}
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Controller
                                    name={`requirement.vehicles.${index}.${name}`}
                                    control={control}
                                    render={({ field: { value } }) => (
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            data-testid={`sales-agent-vehicle-${index + 1}-field`}
                                            disabled={isSalesPersonLoading || disableSalesAgent || readOnly}
                                            className="common__mui__textfield"
                                            options={salesAgentNames}
                                            value={value}
                                            onClose={(event: React.SyntheticEvent) => {
                                                if (index > 0) return;
                                                // @ts-ignore
                                                const value = event?.target?.innerText;

                                                if (value?.length > 0) handleSelectValue(value);
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    type={isMasked ? "password" : "text"}
                                                    disabled={isSalesPersonLoading || disableSalesAgent || readOnly}
                                                    defaultValue={vehicle.salesAgent}
                                                    className={combineClassNames(
                                                        "autocomplete-input",

                                                        "common__mui__textfield",
                                                        isDisabled ? "bg-shade" : undefined,
                                                        // isStandardTextField ? "border-none" : "",
                                                        (getValues(`requirement.vehicles.${index}.${name}`) === "" ||
                                                            getValues(`requirement.vehicles.${index}.${name}`) === undefined) &&
                                                            getValues("showIncompleteField") == true
                                                            ? "error"
                                                            : ""
                                                    )}
                                                    helperText={
                                                        (getValues(`requirement.vehicles.${index}.${name}`) === "" ||
                                                            getValues(`requirement.vehicles.${index}.${name}`) === undefined) &&
                                                        getValues("showIncompleteField") == true
                                                            ? "This field is required"
                                                            : ""
                                                    }
                                                    error={
                                                        (getValues(`requirement.vehicles.${index}.${name}`) === "" ||
                                                            getValues(`requirement.vehicles.${index}.${name}`) === undefined) &&
                                                        getValues("showIncompleteField") == true
                                                    }
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        startAdornment: isSalesPersonLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                                    }}
                                                    {...register(`requirement.vehicles.${index}.${name}`)}
                                                    data-testid={`requirement.vehicles.${index}.${name}`}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Fragment>
            );
        }
    }

    activeFields.push(name);

    return (
        <CommonFields
            inputType={inputType}
            label={label}
            name={`requirement.vehicles.${index}.${name}`}
            options={memoizedOptions}
            showMask={showMask}
            subtitle={subtitle}
            placeholder={placeholder}
            className={className}
            readOnly={localStorage.getItem("disable_form") === "true" || readOnly}
            prop={{
                value: infoText || "",
            }}
            data-testid={generateTestId(`${label}-vehicle-${index + 1}`, "field")}
            value={value}
            disableReactHookForm={disableReactHookForm}
            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
            {...(name === "registrationNumber" && rcVerified ? { verified: true } : {})}
            {...(name === "registrationNumber" && !rcVerified ? { handleValidate: handleRcValidate } : {})}
            {...(name === "registrationNumber" ? { isValidate: true } : {})}
        />
    );
};

export default VehicleItem;
