import { ComponentClass } from "react";
import AuthUserContext from "./context";
import { useAuthUser } from "react-auth-kit";

const withAuthUserProvider = (Component: ComponentClass | (() => JSX.Element)) => (props: any) => {
    const auth = useAuthUser();
    const authUser = auth();

    return (
        <AuthUserContext.Provider
            value={{
                // @ts-ignore
                authUser,
            }}
        >
            <Component {...props} />
        </AuthUserContext.Provider>
    );
};

export default withAuthUserProvider;
