import { INPUT_TYPE } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "address1",
        label: "Address 1",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "address2",
        label: "Address 2",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "address3",
        label: "Address 3",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "landmark",
        label: "Landmark",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "postalCode",
        label: "Postal Code",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "city",
        label: "City",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "state",
        label: "State",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "url",
        label: "URL",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
];

export default fixture;
