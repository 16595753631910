import { DownloadDataCreateInput } from "@/__generated__/apiSchemas";


interface bankAccount {
    statement: DownloadDataCreateInput[];
    bankName: string;
    bankCode: string;
    accountType: string;
    accountNumber: string;
}

const fixture: bankAccount = {
    statement: [{ link: "" }],
    bankName: "",
    bankCode: "",
    accountType: "",
    accountNumber: "",
};

export default fixture;