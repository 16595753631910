import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "income",
        label: "Income",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        editable: true,
        subtitle: "Last 12 Months",
    },
    {
        name: "activityType",
        label: "Activity Type",
        inputType: INPUT_TYPE.SELECT,
        options: ["agriculture_farming", OTHER],
        defaultValue: "agriculture_farming",
        editable: true,
    },
    {
        name: "ownUnderlyingAsset",
        label: "Asset",
        inputType: INPUT_TYPE.SELECT,
        options: ["yes", "no"],
        editable: true,
        subtitle: "Own Underlying Asset ?",
    },
];

export default fixture;
