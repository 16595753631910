import { Fragment } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { defaultAssetFixture } from "fixtures";
import { DeleteAsset, AssetTabDetails, FirstAsset } from "./components";

const AssetTab = ({ assetPrefixInReactForm, pane }: { assetPrefixInReactForm: string; pane?: string }) => {
    const { fields, append, remove } = useFieldArray({
        name: `${assetPrefixInReactForm}`,
    });
    const errors = useWatch({
        name: `errors.${assetPrefixInReactForm}`,
    });

    const { setValue } = useFormContext();

    const appendNewAsset = (index: number) => {
        if (index == fields.length - 1) append(defaultAssetFixture);
    };

    const handleDeleteAsset = (index: number) => {
        remove(index);
        const incomeErrors = errors?.filter((_: any, i: number) => i !== index);
        setValue(`errors.${assetPrefixInReactForm}`, incomeErrors);
    };

    const firstAssetDetails = useWatch({
        name: `${assetPrefixInReactForm}.0`,
    });

    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <FirstAsset index={0} appendNewAsset={appendNewAsset} prefix={assetPrefixInReactForm} pane={pane} />
            {firstAssetDetails.hasAsset === false &&
                fields.map((field, index: number) => {
                    if (index === 0) {
                        return <Fragment />;
                    } else {
                        return (
                            <div key={field.id} className="asset__container" style={{ width: "inherit" }}>
                                <AssetTabDetails index={index} appendNewAsset={appendNewAsset} prefix={assetPrefixInReactForm} pane={pane} />
                                <DeleteAsset index={index} handleDeleteAsset={handleDeleteAsset} />
                            </div>
                        );
                    }
                })}
        </Grid>
    );
};

export default AssetTab;
