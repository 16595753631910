const BANK_NAMES = [
    { name: "ABHYUDAYA CO OPERATIVE BANK", code: "FI00088" },
    { name: "ABU DHABI COMMERCIAL BANK", code: "FI00202" },
    { name: "ADARSH CO OPERATIVE BANK", code: "FI00254" },
    { name: "AHMEDNAGAR MERCHANT CO OPERATIVE BANK", code: "FI00207" },
    { name: "AHMEDNAGAR SHAHAR SAHAKARI BANK", code: "FI00229" },
    { name: "AKHAND ANAND CO OPERATIVE BANK", code: "FI00147" },
    { name: "ALLAHABAD BANK", code: "FI00002" },
    { name: "AMBAJOGAI PEOPLES CO OPERATIVE BANK", code: "FI00242" },
    { name: "AMBERNATH JAI HIND CO OPERATIVE BANK", code: "FI00252" },
    { name: "ANDHRA BANK", code: "FI00003" },
    { name: "ANDHRA PRAGATHI GRAMEENA BANK", code: "FI00125" },
    { name: "ANDHRAPRADESH GRAMEENA VIKAS BANK", code: "FI00204" },
    { name: "ANNASAHEB MAGAR SAHAKARI BANK", code: "FI00151" },
    { name: "APNA SAHAKARI BANK", code: "FI00089" },
    { name: "ASSOCIATE CO OPERATIVE BANK", code: "FI00191" },
    { name: "AU SMALL FINANCE BANK", code: "FI00004" },
    { name: "AXIS BANK", code: "FI00005" },
    { name: "BANDHAN BANK", code: "FI00008" },
    { name: "BANK OF AMERICA", code: "FI00144" },
    { name: "BANK OF BARODA", code: "FI00009" },
    { name: "BANK OF INDIA", code: "FI00010" },
    { name: "BANK OF MAHARASHTRA", code: "FI00011" },
    { name: "BASSEIN CATHOLIC CO OPERATIVE BANK", code: "FI00127" },
    { name: "BHAGINI NIVEDITA SAHAKARI BANK", code: "FI00236" },
    { name: "BHARAT CO OPERATIVE BANK", code: "FI00100" },
    { name: "BHARATI SAHAKARI BANK", code: "FI00150" },
    { name: "BNI BANK", code: "FI00139" },
    { name: "BNP PARIBAS", code: "FI00120" },
    { name: "BOMBAY MERCANTILE CO OPERATIVE BANK", code: "FI00113" },
    { name: "BULDANA URBAN CO OPERATIVE CREDIT SOCIETY", code: "FI00226" },
    { name: "CANARA BANK", code: "FI00012" },
    { name: "CAPITAL SMALL FINANCE BANK", code: "FI00110" },
    { name: "CATHOLIC SYRIAN BANK", code: "FI00015" },
    { name: "CENTRAL BANK OF INDIA", code: "FI00016" },
    { name: "CITI BANK", code: "FI00018" },
    { name: "CITIZEN CREDIT CO OPERATIVE BANK", code: "FI00119" },
    { name: "CITY UNION BANK", code: "FI00019" },
    { name: "CORPORATION BANK", code: "FI00020" },
    { name: "COSMOS BANK", code: "FI00080" },
    { name: "CTBC BANK", code: "FI00220" },
    { name: "DBS BANK", code: "FI00021" },
    { name: "DCB BANK", code: "FI00022" },
    { name: "DENA BANK", code: "FI00023" },
    { name: "DEUTSCHE BANK", code: "FI00024" },
    { name: "DHANLAXMI BANK", code: "FI00025" },
    { name: "DMK JAOLI BANK", code: "FI00223" },
    { name: "DOMBIVLI NAGARI SAHAKARI BANK", code: "FI00114" },
    { name: "DSP CO OPERATIVE BANK", code: "FI00192" },
    { name: "EQUITAS SMALL FINANCE BANK", code: "FI00122" },
    { name: "ESAF SMALL FINANCE BANK", code: "FI00186" },
    { name: "EXCELLENT CO OPERATIVE BANK", code: "FI00161" },
    { name: "FEDERAL BANK", code: "FI00028" },
    { name: "FINCARE SMALL FINANCE BANK", code: "FI00184" },
    { name: "FINGROWTH CO OPERATIVE BANK", code: "FI00167" },
    { name: "FIVE STAR BANK", code: "FI00243" },
    { name: "G P PARSIK BANK", code: "FI00079" },
    { name: "GS MAHANAGAR CO OPERATIVE BANK", code: "FI00126" },
    { name: "GUJARAT AMBUJA CO OPERATIVE BANK", code: "FI00232" },
    { name: "GUJARAT STATE CO OPERATIVE BANK", code: "FI00117" },
    { name: "HDFC BANK", code: "FI00030" },
    { name: "HSBC BANK", code: "FI00032" },
    { name: "ICICI BANK", code: "FI00033" },
    { name: "IDBI BANK", code: "FI00034" },
    { name: "IDFC FIRST BANK", code: "FI00035" },
    { name: "IDFC FIRST BANK LIMITED", code: "FI00035" },
    { name: "INDIAN BANK", code: "FI00039" },
    { name: "INDIAN OVERSEAS BANK", code: "FI00040" },
    { name: "INDRAYANI CO OPERATIVE BANK", code: "FI00136" },
    { name: "INDUSIND BANK", code: "FI00042" },
    { name: "INDUSIND BANK LIMITED", code: "FI00042" },
    { name: "J&K BANK", code: "FI00044" },
    { name: "JANA SMALL FINANCE BANK", code: "FI00137" },
    { name: "JANAKALYAN CO OPERATIVE BANK", code: "FI00250" },
    { name: "JANAKALYAN SAHAKARI BANK", code: "FI00164" },
    { name: "JANASEVA SAHAKARI BANK", code: "FI00107" },
    { name: "JANATA SAHAKARI BANK PUNE", code: "FI00106" },
    { name: "KANKARIAA MANINAGAR NAGRIK SAHAKARI BANK", code: "FI00175" },
    { name: "KARNATAKA BANK", code: "FI00046" },
    { name: "KARNATAKA GRAMIN BANK", code: "FI00155" },
    { name: "KARUR VYSYA BANK", code: "FI00047" },
    { name: "KERALA GRAMIN BANK", code: "FI00248" },
    { name: "KHAMGAON URBAN CO OPERATIVE BANK", code: "FI00103" },
    { name: "KOKAN MERCANTILE CO OPERATIVE BANK", code: "FI00171" },
    { name: "KOTAK MAHINDRA BANK", code: "FI00048" },
    { name: "KURLA NAGRIK SAHAKARI BANK", code: "FI00170" },
    { name: "LAKSHMI VILAS BANK", code: "FI00051" },
    { name: "MAHARASHTRA GRAMIN BANK", code: "FI00140" },
    { name: "MAHESH BANK", code: "FI00118" },
    { name: "MAHESH SAHAKARI BANK", code: "FI00143" },
    { name: "MALVIYA URBAN CO OPERATIVE BANK", code: "FI00227" },
    { name: "MANGAL CO OPERATIVE BANK", code: "FI00219" },
    { name: "MODEL CO OPERATIVE BANK", code: "FI00196" },
    { name: "MSANT SOPANKAKA SAHAKARI BANK", code: "FI00101" },
    { name: "MUFG BANK", code: "FI00138" },
    { name: "MUMBAI DISTRICT CENTRAL CO OPERATIVE BANK", code: "FI00197" },
    { name: "NAGARIK SAHAKARI BANK", code: "FI00240" },
    { name: "NAGPUR NAGARIK SAHAKARI BANK", code: "FI00172" },
    { name: "NAINITAL BANK", code: "FI00055" },
    { name: "NEW INDIA CO OPERATIVE BANK", code: "FI00145" },
    { name: "NIDHI CO OPERATIVE BANK", code: "FI00131" },
    { name: "NKGSB CO OPERATIVE BANK", code: "FI00132" },
    { name: "NUTAN NAGARIK SAHAKARI BANK", code: "FI00112" },
    { name: "OMPRAKASH DEORA PEOPLES CO OPERATIVE BANK", code: "FI00246" },
    { name: "ORIENTAL BANK OF COMMERCE", code: "FI00056" },
    { name: "PANDHARPUR URBAN CO OPERATIVE BANK", code: "FI00104" },
    { name: "PAVANA SAHAKARI BANK", code: "FI00217" },
    { name: "PAYTM PAYMENT BANK", code: "FI00212" },
    { name: "PIMPRI CHINCHWAD SAHAKARI BANK", code: "FI00149" },
    { name: "PRERANA CO OPERATIVE BANK", code: "FI00230" },
    { name: "PRIME CO OPERATIVE BANK", code: "FI00178" },
    { name: "PROGRESSIVE CO OPERATIVE BANK", code: "FI00090" },
    { name: "PUNE CANTONMENT SAHAKARI BANK", code: "FI00234" },
    { name: "PUNE DISTRICT CENTRAL CO OPERATIVE BANK", code: "FI00199" },
    { name: "PUNJAB & MAHARASHTRA CO OPERATIVE BANK", code: "FI00091" },
    { name: "PUNJAB & SIND BANK", code: "FI00058" },
    { name: "PUNJAB NATIONAL BANK", code: "FI00059" },
    { name: "RAJARAMBAPU SAHAKARI BANK", code: "FI00206" },
    { name: "RAJARSHI SHAHU SAHAKARI BANK", code: "FI00142" },
    { name: "RAJGURUNAGAR SAHAKARI BANK", code: "FI00158" },
    { name: "RAJKOT NAGARIK SAHAKARI BANK", code: "FI00198" },
    { name: "RAJKOT PEOPLES CO OPERATIVE BANK", code: "FI00099" },
    { name: "RAMRAJYA SAHAKARI BANK", code: "FI00237" },
    { name: "RBL BANK", code: "FI00060" },
    { name: "RETAIL BANK", code: "FI00093" },
    { name: "SAHEBRAO DESHMUKH CO OPERATIVE BANK", code: "FI00189" },
    { name: "SAMATA SAHAKARI BANK", code: "FI00169" },
    { name: "SANGLI DISTRICT CENTRAL CO OPERATIVE BANK", code: "FI00225" },
    { name: "SANGLI URBAN CO OPERATIVE BANK", code: "FI00105" },
    { name: "SARASPUR NAGARIK CO OPERATIVE BANK", code: "FI00179" },
    { name: "SARASWAT CO OPERATIVE BANK", code: "FI00063" },
    { name: "SARVA HARYANA GRAMIN BANK", code: "FI00205" },
    { name: "SAURASHTRA GRAMIN BANK", code: "FI00097" },
    { name: "SHAMRAO VITHAL CO OPERATIVE BANK", code: "FI00111" },
    { name: "SHIKSHAK SAHAKARI BANK", code: "FI00203" },
    { name: "SHIVAJI NAGARI SAHAKARI BANK", code: "FI00249" },
    { name: "SHREE CO OPERATIVE BANK", code: "FI00215" },
    { name: "SHREE KADI NAGARIK SAHAKARI BANK", code: "FI00094" },
    { name: "SHREE PARSWANATH CO OPERATIVE BANK", code: "FI00193" },
    { name: "SHREE WARANA SAHAKARI BANK", code: "FI00141" },
    { name: "SHRI ADINATH CO OPERATIVE BANK", code: "FI00177" },
    { name: "SHRI ARIHANT CO OPERATIVE BANK", code: "FI00129" },
    {
        name: "SHRI CHHATRAPATI RAJARSHI SHAHU URBAN CO OPERATIVE BANK",
        code: "FI00165",
    },
    { name: "SHRI MAHALAXMI CO OPERATIVE BANK", code: "FI00166" },
    { name: "SHRIKRISHNA CO OPERATIVE BANK", code: "FI00247" },
    { name: "SHUSHRUTI SOUHARDA SAHAKARA BANK", code: "FI00228" },
    { name: "SIDBI", code: "FI00069" },
    { name: "SILICON VALLEY BANK", code: "FI00152" },
    { name: "SIR M VISVESVARAYA CO OPERATIVE BANK", code: "FI00098" },
    { name: "SOLAPUR JANATA SAHAKARI BANK", code: "FI00102" },
    { name: "SOUTH INDIAN BANK", code: "FI00065" },
    { name: "STANDARD CHARTERED BANK", code: "FI00067" },
    { name: "STATE BANK OF INDIA", code: "FI00068" },
    { name: "STATE BANK OF MAURITIUS", code: "FI00092" },
    { name: "STERLING URBAN CO OPERATIVE BANK", code: "FI00128" },
    { name: "SUNDARLAL SAWJI URBAN CO OPERATIVE BANK", code: "FI00083" },
    { name: "SURAT NATIONAL CO OPERATIVE BANK", code: "FI00124" },
    { name: "SURYODAY SMALL FINANCE BANK", code: "FI00185" },
    { name: "SUVRNAYUG SAHAKARI BANK", code: "FI00218" },
    { name: "SYNDICATE BANK", code: "FI00071" },
    { name: "TAMIL NADU STATE APEX CO OPERATIVE BANK", code: "FI00241" },
    { name: "TAMILNAD MERCANTILE BANK", code: "FI00072" },
    { name: "THANE BHARAT SAHAKARI BANK", code: "FI00146" },
    { name: "THE ABHINAV SAHAKARI BANK", code: "FI00133" },
    { name: "THE AGRASEN CO OPERATIVE URBAN BANK", code: "FI00211" },
    { name: "THE AHMEDABAD DISTRICT CO OPERATIVE BANK", code: "FI00156" },
    { name: "THE AHMEDABAD MERCANTILE CO OPERATIVE BANK", code: "FI00168" },
    {
        name: "THE AKOLA JANATA COMMERCIAL CO OPERATIVE BANK",
        code: "FI00224",
    },
    { name: "THE AKOLA URBAN CO OPERATIVE BANK", code: "FI00148" },
    { name: "THE BHAGYALAKSHMI MAHILA SAHAKARI BANK", code: "FI00244" },
    { name: "THE BHAGYODAYA CO OPERATIVE BANK", code: "FI00251" },
    { name: "THE CHITNAVISPURA SAHAKARI BANK", code: "FI00188" },
    { name: "THE COMMERCIAL CO OPERATIVE BANK", code: "FI00160" },
    { name: "THE CO OPERATIVE BANK OF RAJKOT", code: "FI00222" },
    { name: "THE DAHOD URBAN CO OPERATIVE BANK", code: "FI00239" },
    { name: "THE DARUSSALAM CO OPERATIVE URBAN BANK", code: "FI00245" },
    { name: "THE DECCAN MERCHANTS CO OPERATIVE BANK", code: "FI00209" },
    { name: "THE FINANCIAL CO OPERATIVE BANK", code: "FI00190" },
    { name: "THE GREATER BOMBAY CO OPERATIVE BANK", code: "FI00208" },
    { name: "THE HOTEL INDUSTRIALISTS CO OPERATIVE BANK", code: "FI00199" },
    { name: "THE JALGAON PEOPLES CO OPERATIVE BANK", code: "FI00180" },
    { name: "THE KALUPUR COMMERCIAL CO OPERATIVE BANK", code: "FI00087" },
    { name: "THE KALYAN JANATA SAHAKARI BANK", code: "FI00134" },
    { name: "THE KANAKAMAHALAKSHMI CO OPERATIVE BANK", code: "FI00213" },
    { name: "THE KANGRA CO OPERATIVE BANK", code: "FI00191" },
    { name: "THE KARNAVATI CO OPERATIVE BANK", code: "FI00231" },
    { name: "THE KHATTRI CO OPERATIVE URBAN BANK", code: "FI00183" },
    { name: "THE KOLHAPUR URBAN CO OPERATIVE BANK", code: "FI00154" },
    { name: "THE KUKARWADA NAGARIK SAHAKARI BANK", code: "FI00173" },
    { name: "THE KUNBI SAHAKARI BANK", code: "FI00238" },
    { name: "THE MALAD SAHAKARI BANK", code: "FI00210" },
    { name: "THE MEHSANA URBAN CO OPERATIVE BANK", code: "FI00086" },
    { name: "THE NASIK MERCHANT CO OPERATIVE BANK", code: "FI00159" },
    { name: "THE NATIONAL CO OPERATIVE BANK", code: "FI00174" },
    { name: "THE NAWANAGAR CO OPERATIVE BANK", code: "FI00181" },
    { name: "THE SARANGPUR CO OPERATIVE BANK", code: "FI00201" },
    { name: "THE SARVODAYA SAHAKARI BANK", code: "FI00135" },
    { name: "THE SEVA VIKAS CO OPERATIVE BANK", code: "FI00197" },
    { name: "THE SOCIAL CO OPERATIVE BANK", code: "FI00194" },
    { name: "THE SURAT PEOPLE CO OPERATIVE BANK", code: "FI00115" },
    { name: "THE SUTEX CO OPERATIVE BANK", code: "FI00123" },
    { name: "THE THANE DISTRICT CO OPERATIVE BANK", code: "FI00130" },
    { name: "THE VAIDYANATH URBAN CO OPERATIVE BANK", code: "FI00153" },
    { name: "THE VARACHHA CO OPERATIVE BANK", code: "FI00116" },
    { name: "THE VIJAY CO OPERATIVE BANK", code: "FI00095" },
    { name: "THE VISHWESHWAR SAHAKARI BANK", code: "FI00163" },
    { name: "THE YAVATMAL URBAN CO OPERATIVE BANK", code: "FI00221" },
    { name: "THE ZOROASTRIAN CO OPERATIVE BANK", code: "FI00182" },
    { name: "TJSB SAHAKARI BANK", code: "FI00081" },
    { name: "TUMKUR GRAIN MERCHANTS CO OPERATIVE BANK", code: "FI00235" },
    { name: "TUMKUR VEERASHAIVA CO OPERATIVE BANK", code: "FI00233" },
    { name: "UCO BANK", code: "FI00001" },
    { name: "UJJIVAN SMALL FINANCE BANK", code: "FI00250" },
    { name: "UNION BANK OF INDIA", code: "FI00002" },
    { name: "UNITED BANK OF INDIA", code: "FI00003" },
    { name: "UTKARSH SMALL FINANCE BANK", code: "FI00249" },
    { name: "VASAI JANATA SAHAKARI BANK", code: "FI00214" },
    { name: "VASAI VIKAS SAHAKARI BANK", code: "FI00162" },
    { name: "VIDARBHA MERCHANTS URBAN CO OPERATIVE BANK", code: "FI00253" },
    { name: "VIDHARBHA KONKAN GRAMIN BANK", code: "FI00200" },
    { name: "VIDYA SAHAKARI BANK", code: "FI00195" },
    { name: "VIJAYA BANK", code: "FI00077" },
    { name: "WARDHAMAN URBAN CO OPERATIVE BANK", code: "FI00216" },
    { name: "YES BANK", code: "FI00078" },
    { name: "AU SMALL FINANCE BANK LIMITED", code: "FI00004" },
    { name: "CAPITAL SMALL FINANCE BANK LIMITED", code: "" },
    { name: "FINCARE SMALL FINANCE BANK LIMITED", code: "" },
    { name: "EQUITAS SMALL FINANCE BANK LIMITED", code: "" },
    { name: "ESAF SMALL FINANCE BANK LIMITED", code: "" },
    { name: "JANA SMALL FINANCE BANK LIMITED", code: "" },
    { name: "NORTH EAST SMALL FINANCE BANK LIMITED", code: "" },
    { name: "SURYODAY SMALL FINANCE BANK LIMITED", code: "" },
    { name: "UJJIVAN SMALL FINANCE BANK LIMITED", code: "" },
    { name: "UNITY SMALL FINANCE BANK LIMITED", code: "" },
    { name: "UTKARSH SMALL FINANCE BANK LIMITED", code: "" },
    { name: "AIRTEL PAYMENT BANK", code: "" },
    { name: "FINO PAYMENT BANK", code: "" },
    { name: "INDIA POST PAYMENT BANK", code: "" },
    { name: "JIO PAYMENT BANK ", code: "" },
    { name: "NSDL PAYMENT BANK", code: "" },
    { name: "AXIS", code: "FI00005" },
    { name: "AXIS BANK LTD", code: "FI00005" },
    { name: "BANDHAN BANK LTD", code: "" },
    { name: "CATHOLIC SYRIAN BANK LTD", code: "" },
    { name: "DCB BANK LTD", code: "" },
    { name: "DHANALAKSHMI BANK LTD", code: "" },
    { name: "FEDERAL BANK LTD", code: "" },
    { name: "HDFC LIMITED", code: "FI00030" },
    { name: "HDFC BANK LTD", code: "FI00030" },
    { name: "ICICI BANK LTD", code: "FI00033" },
    { name: "ICICI BANK LIMITED", code: "FI00033" },
    { name: "IDBI LTD", code: "" },
    { name: "IDFC BANK LIMITED", code: "" },
    { name: "INDUSIND BANK LTD", code: "FI00042" },
    { name: "JAMMU AND KASHMIR BANK", code: "" },
    { name: "KARNATAKA BANK LTD", code: "" },
    { name: "KARUR VYSYA BANK LTD", code: "" },
    { name: "KOTAK MAHINDRA BANK LTD", code: "" },
    { name: "RATNAKAR BANK LIMITED", code: "" },
    { name: "TAMILNAD MERCANTILE BANK LTD", code: "" },
    { name: "YES BANK LTD", code: "FI00078" },
    { name: "PUNJAB AND SIND BANK", code: "" },
    { name: "AMERICAN EXPRESS", code: "" },
    { name: "BARCLAYS BANK PLC", code: "" },
    { name: "DEUTSCHE BANK LTD", code: "" },
    { name: "HONGKONG AND SHANGHAI BKG CORPN", code: "" },
    { name: "SBM BANK INDIA", code: "" },
    { name: "STANDARD CHARTERED BANK LTD", code: "" },
    { name: "ARMAN FINANCIAL SERVICES LIMITED", code: "" },
    { name: "CHINMAY FINLEASE LIMITED", code: "" },
    { name: "ISHAN FINLEASE LIMITED", code: "" },
    { name: "JAYLAKSHMI CREDIT COMPANY LIMITED", code: "" },
    { name: "K Z LEASING AND FINANCE LIMITED", code: "" },
    { name: "NORTH POLE FINANCE LIMITED", code: "" },
    { name: "RAJATH FINANCE LIMITED", code: "" },
    { name: "AMRIT MALWA CAPITAL LIMITED", code: "" },
    { name: "BABA PURAN DASS FINANCIAL SERVICES LIMITED", code: "" },
    { name: "DELHI PUNJAB FINANCE CO LIMITED", code: "" },
    { name: "PHF LEASING LIMITED", code: "" },
    { name: "PKF FINANCE LIMITED", code: "" },
    { name: "PUNJAB KASHMIR FINANCE LIMITED", code: "" },
    { name: "EXCELLENT FIN-INVEST LTD", code: "" },
    { name: "G T P FINANCE LIMITED", code: "" },
    { name: "GALADA FINANCE LIMITED", code: "" },
    { name: "GOVE FINANCE LIMITED", code: "" },
    { name: "INTEGRATED FINANCE COMPANY LIMITED", code: "" },
    { name: "NEWLINK OVERSEAS FINANCE LIMITED", code: "" },
    { name: "SAKTHI FINANCE LIMITED", code: "" },
    { name: "SEYAD SHARIAT FINANCE LIMITED", code: "" },
    { name: "SHRIRAM CITY UNION FINANCE LIMITED", code: "" },
    { name: "SHRIRAM TRANSPORT FINANCE COMPANY LIMITED", code: "" },
    {
        name: "SRI VIJAYARAM HIRE PURCHASE AND LEASING FINANCE LIMITED",
        code: "",
    },
    { name: "SUNDARAM FINANCE LIMITED", code: "" },
    {
        name: "TAMILNADU INDUSTRIAL DEVELOPMENT CORPORATION LIMITED",
        code: "",
    },
    {
        name: "TAMILNADU POWER FINANCE AND INFRASTRUCTURE DEVELOPMENT CORPORATION LIMITED",
        code: "",
    },
    {
        name: "TAMILNADU TRANSPORT DEVELOPMENT FINANCE CORPORATION LIMITED",
        code: "",
    },
    {
        name: "TAMILNADU URBAN FINANCE AND INFRASTRUCTURE DEVELOPMENT CORPORATION LIMITED",
        code: "",
    },
    { name: "NEELGAGAN FINANCE LIMITED", code: "" },
    { name: "S.P.R. FINANCE LIMITED", code: "" },
    { name: "SANGRAMA MOTORS FINANCE INDIA LIMITED", code: "" },
    { name: "SUDERSHAN HIRE PURCHASE LIMITED", code: "" },
    { name: "BALAJI INSTALMENTS LIMITED", code: "" },
    { name: "DHARA MOTOR FINANCE LIMITED", code: "" },
    {
        name: "THE PEERLESS GENERAL FINANCE & INVESTMENT COMPANY LTD",
        code: "",
    },
    {
        name: "BAJAJ FINANCE LTD. [FORMERLY: BAJAJ AUTO FINANCE LTD.]",
        code: "",
    },
    { name: "BAJAJ HOUSING FINANCE LTD", code: "" },
    { name: "BAJAJ FINSERV", code: "" },
    { name: "BERAR FINANCE LIMITED", code: "" },
    { name: "MAHINDRA & MAHINDRA FINANCIAL SERVICES LTD", code: "" },
    { name: "MOONSHINE FINVEST LIMITED", code: "" },
    { name: "BANSAL CREDITS LIMITED", code: "" },
    { name: "SAMRAT MOTOR FINANCE LTD.", code: "" },
    { name: "THE DELHI SAFE DEPOSIT COMPANY LTD.", code: "" },
    { name: "GRIHASTHA FINANCE LTD", code: "" },
    { name: "OPEL FINANCE LTD", code: "" },
    {
        name: "KERALA STATE POWER AND INFRASTRUCTURE FINANCE CORPORATION LIMITED",
        code: "",
    },
    { name: "MUTHOOT HOUSING FINANCE COMPANY LTD", code: "" },
    { name: "MUTHOOT VEHICLE & ASSET FINANCE LTD", code: "" },
    { name: "MUTHOOT CAPITAL SERVICES LIMITED", code: "" },
    { name: "SREERAGH GENERAL FINANCE LIMITED", code: "" },
    { name: "ADANI CAPITAL PRIVATE LIMITED", code: "" },
    { name: "ADITYA BIRLA FINANCE LIMITED", code: "" },
    { name: "BILAKHIA HOLDINGS PRIVATE LIMITED", code: "" },
    { name: "GUJARAT INDUSTRIAL INVESTMENT CORPORATION LIMITED", code: "" },
    { name: "GUJARAT STATE FINANCIAL SERVICES LIMITED (GSFS)", code: "" },
    { name: "HDB FINANCIAL SERVICES LIMITED", code: "" },
    { name: "M3 INVESTMENT PVT. LTD", code: "" },
    { name: "MAS FINANCIAL SERVICES LIMITED", code: "" },
    { name: "NAMRA FINANCE LIMITED", code: "" },
    { name: "PAHAL FINANCIAL SERVICES LIMITED", code: "" },
    { name: "CANBANK FACTORS LIMITED", code: "" },
    { name: "CAPFLOAT FINANCIAL SERVICES PRIVATE LIMITED.", code: "" },
    {
        name: "CATERPILLAR FINANCIAL SERVICES INDIA PRIVATE LIMITED",
        code: "",
    },
    { name: "CHAITANYA INDIA FIN CREDIT PRIVATE LIMITED", code: "" },
    { name: "CISCO SYSTEMS CAPITAL (INDIA) PVT.LTD.", code: "" },
    { name: "CREDITACCESS GRAMEEN LIMITED", code: "" },
    {
        name: "D DEVARAJ URS BACKWARD CLASSES DEVELOPMENT CORPORATION LTD.",
        code: "",
    },
    { name: "DR.B.R.AMBEDKAR DEVELOPMENT CORPORATION", code: "" },
    {
        name: "HEWLETT-PACKARD FINANCIAL SERVICES (INDIA) PRIVATE LIMITED",
        code: "",
    },
    { name: "HIGHER EDUCATION FINANCING AGENCY ( GOVT. COMPANY)", code: "" },
    { name: "JANA HOLDING LIMITED (TRANSFERRED TO MUMBAI RO)", code: "" },
    { name: "JUPITER CAPITAL PRIVATE LIMITED", code: "" },
    { name: "KISETSU SAISON FINANCE (INDIA) PRIVATE LIMITED", code: "" },
    { name: "KRAZYBEE SERVICES PRIVATE LIMITED", code: "" },
    { name: "NABFINS LIMITED", code: "" },
    { name: "NAVI FINSERV PRIVATE LIMITED", code: "" },
    {
        name: "PRAZIM TRADING AND INVESTMENT COMPANY PRIVATE LIMITED",
        code: "",
    },
    { name: "SAMASTA MICROFINANCE LIMITED", code: "" },
    {
        name: "TARISH INVESTMENT AND TRADING COMPANY PRIVATE LIMITED",
        code: "",
    },
    {
        name: "THE KARNATAKA MINORITIES DEVELOPMENT CORPORATION LTD",
        code: "",
    },
    { name: "TOYOTA FINANCIAL SERVICES INDIA LIMITED", code: "" },
    { name: "VARTHANA FINANCE PRIVATE LIMITED", code: "" },
    {
        name: "VISAGE HOLDINGS AND FINANCE PVT LTD (SHIFTED FROM NEW DELHI R.O)",
        code: "",
    },
    {
        name: "VISTAAR FINANCIAL SERVICES P LTD (TRANSFERRED FROM MUMBAI RO)",
        code: "",
    },
    { name: "VOLVO FINANCIAL SERVICES (INDIA) PRIVATE LIMITED", code: "" },
    { name: "ANNAPURNA FINANCE PRIVATE LIMITED.", code: "" },
    {
        name: "OCEAN CAPITAL MARKET LTD. (TRANSFERRED FROM KOLKATA RO)",
        code: "",
    },
    { name: "KARUN CARPETS PRIVATE LIMITED", code: "" },
    { name: "MIDLAND MICROFIN LIMITED", code: "" },
    { name: "NAHAR CAPITAL AND FINANCIAL SERVICES LIMITED", code: "" },
    { name: "VARDHMAN HOLDINGS LIMITED", code: "" },
    { name: "ACSYS INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "APTUS FINANCE INDIA PRIVATE LIMITED", code: "" },
    { name: "ARISE INVESTMENTS AND CAPITAL LIMITED", code: "" },
    { name: "ASIRVAD MICRO FINANCE LIMITED", code: "" },
    { name: "BELSTAR MICROFINANCE LIMITED", code: "" },
    {
        name: "CHOLAMANDALAM INVESTMENT AND FINANCE COMPANY LIMITED",
        code: "",
    },
    { name: "DAIMLER FINANCIAL SERVICES INDIA PRIVATE LIMITED", code: "" },
    {
        name: "DVARA KSHETRIYA GRAMIN FINANCIAL SERVICES PRIVATE LIMITED",
        code: "",
    },
    { name: "FIVE STAR BUSINESS FINANCE LIMITED", code: "" },
    { name: "FORD CREDIT INDIA PRIVATE LIMITED", code: "" },
    { name: "FULLERTON INDIA CREDIT COMPANY LIMITED", code: "" },
    { name: "HINDUJA LEYLAND FINANCE LIMITED", code: "" },
    { name: "IDFC FINANCIAL HOLDING COMPANY LIMITED", code: "" },
    { name: "IDFC LIMITED", code: "" },
    { name: "KKR INDIA ASSET FINANCE LIMITED", code: "" },
    { name: "MADURA MICRO FINANCE LIMITED", code: "" },
    { name: "NABKISAN FINANCE LIMITED", code: "" },
    {
        name: "NISSAN RENAULT FINANCIAL SERVICES INDIA PRIVATE LIMITED",
        code: "",
    },
    { name: "NORTHERN ARC CAPITAL LIMITED", code: "" },
    { name: "RAMA INVESTMENT COMPANY PRIVATE LIMITED", code: "" },
    { name: "REPCO MICRO FINANCE LIMITED", code: "" },
    { name: "S.M.I.L.E MICRO FINANCE LIMITED", code: "" },
    {
        name: "SAMUNNATI FINANCIAL INTERMEDIATION & SERVICES PRIVATE LIMITED",
        code: "",
    },
    { name: "SITA INVESTMENT COMPANY LIMITED", code: "" },
    { name: "TVS CREDIT SERVICES LTD", code: "" },
    { name: "VERITAS FINANCE PRIVATE LIMITED", code: "" },
    { name: "VIVRITI CAPITAL PRIVATE LIMITED", code: "" },
    { name: "NORTH EASTERN DEVELOPMENT FINANCE CORPORATION LTD.", code: "" },
    { name: "ANDHRA PRADESH POWER FINANCE CORPORATION LTD", code: "" },
    { name: "CASPIAN IMPACT INVESTMENTS PRIVATE LIMITED", code: "" },
    {
        name: "IKF FINANCE LTD. (CONVERTED FROM CATEGORY A TO CATEGORY B)",
        code: "",
    },
    {
        name: "INDIAN SCHOOL FINANCE COMPANY PVT. LTD. (FORMERLY CORPORATE DEPOSITS & INVESTMENTS LTD)",
        code: "",
    },
    {
        name: "KANAKADURGA FINANCE LIMITED (FORMERLY-KANAKA DURGA LEASING & FINANCE LTD.)",
        code: "",
    },
    {
        name: "KLM AXIVA FINVEST LIMITED (FORMERLY NEEDS FINVEST LIMITED)",
        code: "",
    },
    {
        name: "MAANAVEEYA DEVELOPMENT & FINANCE PRIVATE LIMITED (FORMERLY MAANAVEEYA HOLDINGS & INVESTMENTS PRIVATE LIMITED)",
        code: "",
    },
    {
        name: "NABSAMRUDDHI FINANCE LIMITED(FORMERLY KNOWN AS AGRI BUSINESS FINANCE LIMITED (FORMERLY AGRI BUSINESS FINANCE(AP) LTD.))",
        code: "",
    },
    { name: "NCC INFRASTRUCTURE HOLDINGS LIMITED", code: "" },
    { name: "SHARE MICROFIN LTD", code: "" },
    { name: "SPANDANA SPHOORTY FINANCIAL LTD.", code: "" },
    { name: "VAYA FINSERV PRIVATE LIMIED", code: "" },
    { name: "ESS KAY FINCORP LTD", code: "" },
    { name: "FINOVA CAPITAL PRIVATE LIMITED", code: "" },
    { name: "JUMBO FINVEST (INDIA) LTD.", code: "" },
    { name: "KOGTA FINANCIAL INDIA LTD", code: "" },
    { name: "KOGTA FINANCIAL (INDIA) LTD.", code: "" },
    { name: "LAXMI INDIA FINLEASECAP PRIVATE LIMITED", code: "" },
    { name: "SONATA FINANCE PRIVATE LIMITED", code: "" },
    { name: "ADVENTZ FINANCE PRIVATE LIMITED", code: "" },
    { name: "AROHAN FINANCIAL SERVICES LIMITED", code: "" },
    { name: "ASA INTERNATIONAL INDIA MICROFINANCE LIMITED", code: "" },
    { name: "BANDHAN FINANCIAL HOLDINGS LIMITED", code: "" },
    { name: "BRABOURNE COMMERCE (P) LTD", code: "" },
    { name: "CENTRAL INDIA INDUSTRIES LIMITED", code: "" },
    { name: "GWALIOR FINANCE CORPORATION LIMITED", code: "" },
    {
        name: "KIRAN VYAPAR LTD(FORMERLY KIRAN VYAPAR PRIVATE LIMITED)",
        code: "",
    },
    { name: "L&T FINANCE LIMITED", code: "" },
    { name: "MEENAKSHI MERCANTILES LTD", code: "" },
    { name: "PILANI INVESTMENT & INDUSTRIES CORPORATION LTD.", code: "" },
    { name: "PLACID LTD.", code: "" },
    {
        name: "POONAWALLA FINCORP LIMITED(FORMERLY MAGMA FINCORP LIMITED)",
        code: "",
    },
    { name: "RAINBOW INVESTMENTS LIMITED", code: "" },
    { name: "RUSSELL CREDIT LTD", code: "" },
    { name: "SHEKHAVATI INVESTMENTS AND TRADERS LIMITED", code: "" },
    { name: "SHREE CAPITAL SERVICES LTD.", code: "" },
    { name: "SPOTLIGHT VANIJYA LTD.", code: "" },
    {
        name: "SREI EQUIPMENT FINANCE LIMITED(FORMERLY SREI EQUIPMENT FINANCE PRIVATE LIMITED)",
        code: "",
    },
    { name: "SREI INFASTRUCTURE FINANCE LIMITED", code: "" },
    { name: "UMANG COMMERCIAL CO PVT LTD", code: "" },
    { name: "UMT INVESTMENTS LTD", code: "" },
    { name: "VILLAGE FINANCIAL SERVICES LIMITED", code: "" },
    {
        name: "WEST BENGAL INFRASTRUCTURE DEVELOPMENT FINANCE CORPN",
        code: "",
    },
    { name: "WILLIAMSON MAGOR & CO LTD", code: "" },
    {
        name: "A K CAPITAL FINANCE LIMITED (PREVIOUSLY A K CAPITAL FINANCE PRIVATE LIMITED ) (TRFD FROM KOLKATA)",
        code: "",
    },
    {
        name: "ABANS FINANCE PRIVATE LIMITED (FORMERLY ABANS FINANCE LIMITED) (FORMERLY SOFED COMMTRADE PVT. LTD.)",
        code: "",
    },
    { name: "AGRIWISE FINSERV LIMITED", code: "" },
    { name: "ALCO COMPANY PRIVATE LIMITED", code: "" },
    {
        name: "AMBIT FINVEST PVT. LTD. [FORMERLY: AMBIT RETAIL FINANCE PVT. LTD.]",
        code: "",
    },
    {
        name: "ANAND RATHI GLOBAL FINANCE LTD. [FORMERLY: RATHI GLOBAL FINANCE LTD.]",
        code: "",
    },
    {
        name: "ANERI FINCAP LIMITED (PREVIOUSLY FARRY INDUSTRIES LTD)",
        code: "",
    },
    {
        name: "ARKA FINCAP LIMITED (FORMERLY KNOWN AS KIRLOSKAR CAPITAL LIMITED)",
        code: "",
    },
    { name: "ASEEM INFRASTRUCTURE FINANCE LIMITED", code: "" },
    {
        name: "ASHV FINANCE LIMITED (FORMERLY JAIN SONS FINLEASE LIMITED)",
        code: "",
    },
    {
        name: "AUTHUM INVESTMENT & INFRASTRUCTURE LIMITED (SHIFTED FROM KOLKATA)",
        code: "",
    },
    {
        name: "AUXILO FINSERVE PRIVATE LIMITED (W.E.F. SEPTEMBER 11",
        code: "",
    },
    { name: "AVANSE FINANCIAL SERVICES LTD", code: "" },
    {
        name: "AVENDUS FINANCE PRIVATE LIMITED (FORMERLY PACIFIC HIRE PURCHASE LIMITED)- SHIFTED FROM BANGALORE",
        code: "",
    },
    {
        name: "AXIS FINANCE LTD. {OLD NAME- ENAM FINANCE PVT LTD (TRANSFERRED FROM CHENNAI)}",
        code: "",
    },
    {
        name: "BACHHRAJ & CO. PVT. LIMITED [FORMERLY BACHHRAJ & CO. LIMITED]",
        code: "",
    },
    { name: "BAJAJ HOLDINGS AND INVESTMENT LIMITED", code: "" },
    {
        name: "BARCLAYS INVESTMENTS & LOANS (INDIA) PRIVATE LIMITED (PREVIOUSLY BARCLAYS INVESTMENTS & LOANS (INDIA) LIMITED.",
        code: "",
    },
    { name: "BIRLA GROUP HOLDINGS PVT LTD", code: "" },
    { name: "BIRLA TMT HOLDINGS PRIVATE LIMITED", code: "" },
    {
        name: "BLACKSOIL CAPITAL PRIVATE LIMITED (FORMERLY SARVODAYA CAPITAL PVT. LTD.)",
        code: "",
    },
    {
        name: "BOB FINANCIAL SOLUTIONS LIMITED (PREVIOUSLY KNOWN AS BOBCARDS LIMITED)",
        code: "",
    },
    {
        name: "CAPRI GLOBAL CAPITAL LIMITED (FORMERLY MONEY MATTERS FINANCIAL SERVICES LTD.)",
        code: "",
    },
    { name: "CENTRUM FINANCIAL SERVICES LTD.", code: "" },
    {
        name: "CENTRUM MICROCREDIT LIMITED (OLD NAME: CENTRUM MICROCREDIT PRIVATE LIMITED)",
        code: "",
    },
    { name: "CHHATTISGARH INVESTMENTS LIMITED", code: "" },
    {
        name: "CITICORP FINANCE (INDIA) LIMITED (CITI FINANCIAL CONSUMER FINANCE INDIA LTD)",
        code: "",
    },
    { name: "COX & KINGS FINANCIAL SERVICE LIMITED", code: "" },
    { name: "CREDIT SUISSE FINANCE (INDIA) PRIVATE LIMITED", code: "" },
    { name: "CYRUS INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "DE LAGE LANDEN FINANCIAL SERVICES INDIA PVT LTD", code: "" },
    { name: "DEUTSCHE INDIA HOLDINGS PVT. LTD.", code: "" },
    { name: "DEUTSCHE INVESTMENTS INDIA PVT. LTD.", code: "" },
    {
        name: "DSP ADIKO HOLDINGS PVT. LTD. ( FORMERLY - ADIKO INVESTMENT PRIVATE LIMITED)",
        code: "",
    },
    {
        name: "DSP HMK HOLDINGS PVT. LTD. ( FORMERLY - HMK INVESTMENT P LTD. )",
        code: "",
    },
    {
        name: "DSP INVESTMENT PVT. LTD. ( FORMERLY - BIKO INVESTMENT PVT LTD.)",
        code: "",
    },
    { name: "ECL FINANCE LIMITED", code: "" },
    { name: "EDC LIMITED", code: "" },
    { name: "EDELWEISS FINANCE & INVESTMENTS LIMITED", code: "" },
    {
        name: "EDELWEISS FINVEST LIMITED (FORMERLY EDELWEISS FINVEST PRIVATE LIMITED)",
        code: "",
    },
    {
        name: "EDELWEISS RETAIL FINANCE LIMITED(FORMERLY AFFLUENT DEALCOM (P) LTD)",
        code: "",
    },
    {
        name: "ELECTRONICA FINANCE LIMITED (FORMERLY ELECTRONICA LEASING & FINANCE LIMITED)",
        code: "",
    },
    {
        name: "FINO FINANCE PVT LIMITED (PREVIOUSLY INTREPID FINANCE & LEASING PVT. LTD",
        code: "",
    },
    { name: "FINQUEST FINANCIAL SOLUTIONS PVT. LTD.", code: "" },
    { name: "FORTUNE CREDIT CAPITAL LTD.", code: "" },
    { name: "FORTUNE INTEGRATED ASSETS FINANCE LIMITED", code: "" },
    { name: "GAGANDEEP CREDIT CAPITAL PRIVATE LIMITED", code: "" },
    { name: "GOLDMAN SACHS (INDIA) CAPITAL MARKETS PVT LTD", code: "" },
    {
        name: "GOLDMAN SACHS (INDIA) FINANCE PVT LTD (FORMERLY: PRATHAM INVESTMENTS & TRADING PVT. LTD.)",
        code: "",
    },
    { name: "HATHWAY INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "HDFC CREDILA FINANCIAL SERVICES LIMITED", code: "" },
    { name: "HDFC INVESTMENTS LIMITED", code: "" },
    {
        name: "HSBC INVESTDIRECT FINANCIAL SERVICES (INDIA) LTD. (OLD NAME INVESTSMART FINANCIAL SERVICES LTD.)",
        code: "",
    },
    { name: "ICICI SECURITIES PRIMARY DEALERSHIP LTD.", code: "" },
    { name: "IGH HOLDINGS PVT. LTD.", code: "" },
    {
        name: "IIFL FINANCE LIMITED (FORMERLY KNOWN AS IIFL HOLDINGS LIMITED)",
        code: "",
    },
    {
        name: "IIFL WEALTH PRIME LIMITED (FORMERLY CHEPHIS CAPITAL MARKETS LTD)",
        code: "",
    },
    {
        name: "IL & FS FINANCIAL SERVICES LTD. ( FORMERLY IL & FS FINVEST  LTD.)",
        code: "",
    },
    { name: "INCRED FINANCIAL SERVICES LIMITED", code: "" },
    { name: "INDIA FACTORING & FINANCE SOLUTIONS PVT LTD", code: "" },
    {
        name: "INDIA INFOLINE FINANCE LIMITED (OLD NAME- INDIA INFOLINE INVESTMENT SERVICES LTD.)",
        code: "",
    },
    { name: "INDIA INFRADEBT LIMITED", code: "" },
    { name: "INDOSTAR CAPITAL FINANCE LTD", code: "" },
    { name: "INDUSTRIAL INVESTMENT TRUST LTD.", code: "" },
    { name: "INFINA FINANCE PVT. LTD.", code: "" },
    { name: "INNOVEN CAPITAL INDIA PRIVATE LIMITED", code: "" },
    { name: "INSTANT HOLDINGS LTD. [FORMERLY: KEC HOLDINGS LTD.]", code: "" },
    {
        name: "J M FINANCIAL & INVESTMENT CONSULTANCY SERVICES PVT. LTD.",
        code: "",
    },
    { name: "J. P. MORGAN SECURITIES INDIA PVT. LTD.", code: "" },
    { name: "JAYKAY FINHOLDING (INDIA) PVT. LTD.", code: "" },
    { name: "JM FINANCIAL CAPITAL LIMITED", code: "" },
    {
        name: "JM FINANCIAL CREDIT SOLUTIONS LIMITED (EARLIER FICS CONSULTANCY SERVICES LTD)",
        code: "",
    },
    {
        name: "JM FINANCIAL PRODUCTS LTD. [FORMERLY: JM FINANCIAL PRODUCTS PVT LTD]",
        code: "",
    },
    { name: "JOHN DEERE FINANCIAL INDIA PRIVATE LIMITED", code: "" },
    { name: "JULIUS BAER CAPITAL (INDIA) PRIVATE LIMITED", code: "" },
    { name: "KANISHTHA FINANCE & INVESTMENT PVT. LTD", code: "" },
    { name: "KARVY FINANCIAL SERVICES LTD", code: "" },
    { name: "KKR INDIA FINANCIAL SERVICES LIMITED", code: "" },
    { name: "KOTAK INFRASTRUCTURE DEBT FUND LIMITED", code: "" },
    { name: "KOTAK MAHINDRA INVESTMENTS LTD.", code: "" },
    { name: "KOTAK MAHINDRA PRIME LTD.", code: "" },
    { name: "L & T INFRA DEBT FUND LIMITED", code: "" },
    { name: "L & T INFRASTRUCTURE FINANCE COMPANY LIMITED", code: "" },
    {
        name: "LENDINGKART FINANCE LIMITED (FORMERLY KNOWN AS AADRI INFIN LIMITED)",
        code: "",
    },
    { name: "MAHIMNA MECANTILE CREDITS LTD", code: "" },
    {
        name: "MENTOR CAPITAL LTD. [FORMERLY; PACIFIC CORPORATE SERVICES LTD.]",
        code: "",
    },
    { name: "MICRO UNITS DEVELOPMENT & REFINANCE AGENCY LIMITED", code: "" },
    { name: "MORGAN STANLEY INDIA PRIMARY DEALER PVT. LTD.", code: "" },
    { name: "MOTILAL OSWAL FINVEST LIMITED", code: "" },
    {
        name: "MUTHOOT MICROFIN LTD (FORMERLY KNOWN AS PANCHRATNA SECURITIES LIMITED) MFI",
        code: "",
    },
    { name: "NAMAN FINANCE & INVESTMENT PVT. LTD.", code: "" },
    { name: "NEOGROWTH CREDIT PRIVATE LIMITED", code: "" },
    {
        name: "NIIF INFRASTRUCTURE FINANCE LIMITED ( IDFC INFRASTRUCTURE FINANCE LIMITED)",
        code: "",
    },
    { name: "NOMURA CAPITAL (INDIA) PVT. LTD.", code: "" },
    { name: "NOMURA FIXED INCOME SECURITIES PVT. LTD.", code: "" },
    {
        name: "ORIX LEASING & FINANCIAL SERVICES INDIA LIMITED (FORMERELY OAIS AUTO FINANCIAL SERVICES LIMITED",
        code: "",
    },
    {
        name: "PAYU FINANCE INDIA PRIVATE LIMITED (FORMERLY KNOWN AS SIDVIK LEASING PVT. LTD.)",
        code: "",
    },
    {
        name: "PHL FININVEST PRIVATE LIMITED (NPIL FININVEST PRIVATE LIMITED)",
        code: "",
    },
    {
        name: "POONAWALLA FINANCE PRIVATE LIMITED (FORMERLY ADAR POONAWALLA FINVEST PRIVATE LIMITED)",
        code: "",
    },
    { name: "PROFECTUS CAPITAL PVT. LTD.", code: "" },
    {
        name: "RELIANCE COMMERCIAL FINANCE LIMITED(FORMERLY KNOWN AS RELIANCE GILTS LTD.)",
        code: "",
    },
    { name: "RELIANCE FINANCIAL LIMITED", code: "" },
    {
        name: "RELIANCE RETAIL FINANCE LTD. (FORMERLY RELIANCE POWER VENTURES LTD.)",
        code: "",
    },
    { name: "RELIANCE STRATEGIC INVESTMENTS LIMITED", code: "" },
    {
        name: "RELIANCE VENTURES LIMITED (FORMERLY KNOWN AS RELIANCE FERTILISERS PRIVATE LIMITED)",
        code: "",
    },
    { name: "SBFC FINANCE PRIVATE LIMITED", code: "" },
    {
        name: "SBI DFHI LTD. (FORMERELY  DICOUNT & FINANCE HOUSE OF INDIA)",
        code: "",
    },
    {
        name: "SBI GLOBAL FACTORS LTD. [FORMERLY: GLOBAL TRADE FINANCE LIMITED]",
        code: "",
    },
    {
        name: "SHAMYAK INVESTMENT PRIVATE LIMITED (PREVIOUSLY AMRIT PETROLEUMS PRIVATE LIMITED)",
        code: "",
    },
    { name: "SHANGHVI FINANCE PRIVATE LIMITED", code: "" },
    { name: "SHAPOORJI PALLONJI FINANCE PRIVATE LIMITED", code: "" },
    { name: "SHAREKHAN BNP PARIBAS  FINANCIAL  SERVICES LIMITED", code: "" },
    { name: "SICOM INVESTMENTS & FINANCE LTD.", code: "" },
    { name: "SICOM LIMITED", code: "" },
    { name: "SIEMENS FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    {
        name: "STANDARD CHARTERED CAPITAL LIMITED (FORMERLY STANDARD CHARTERED INVESTMENTS & LOANS (INDIA) LTD.)",
        code: "",
    },
    {
        name: "STCI FINANCE LTD. ( OLD NAME - SECURITIES TRADING CORPORATION OF INDIA LTD.)",
        code: "",
    },
    { name: "STCI PRIMARY DEALER LIMITED", code: "" },
    { name: "STERLING INVESTMENT CORPORATION PRIVATE LIMITED", code: "" },
    {
        name: "SUMMIT SECURITIES LTD. [FORMERLY: RPG ITOCHU FINANCE LTD.]",
        code: "",
    },
    { name: "SVATANTRA MICROFIN PRIVATE LIMITED", code: "" },
    { name: "TATA CAPITAL FINANCIAL SERVICES LIMITED", code: "" },
    { name: "TATA CLEANTECH CAPITAL LIMITED", code: "" },
    { name: "TATA INVESTMENT CORPORATION LTD", code: "" },
    {
        name: "TATA MOTORS FINANCE LIMITED (FROMERLY SHEBA PROPERTIES LIMITED)",
        code: "",
    },
    { name: "TATA MOTORS FINANCE SOLUTIONS LIMITED", code: "" },
    { name: "TELECOM INVESTMENT INDIA PVT. LTD.", code: "" },
    { name: "THAKUR FININVEST PVT. LTD", code: "" },
    {
        name: "THAR COMMERCIAL FINANCE PRIVATE LIMITED ( FORMERLY KNOWN AS INDIA ADVANTAGE FINANCE PVT. LTD.)",
        code: "",
    },
    { name: "THE SWASTIK SAFE DEPOSIT & INVESTMENTS LTD", code: "" },
    {
        name: "UGRO CAPITAL  LIMITED (PREVIOUSLY CHOKHANI SECURITIES LIMITED)",
        code: "",
    },
    { name: "UNITED PETRO FINANCE LIMITED", code: "" },
    { name: "VOLKSWAGEN FINANCE PRIVATE LIMITED", code: "" },
    { name: "WEST END INVESTMENT & FINANCE CONSULTANCY PVT. LTD.", code: "" },
    {
        name: "WHEELSEMI PRIVATE LIMITED (FORMERLY VARADNARAYAN SAVINGS & INVESTMENT CO.PVT.LTD.)",
        code: "",
    },
    { name: "WINRO COMMERCIAL (INDIA) LTD.", code: "" },
    { name: "XANDER FINANCE PRIVATE LIMITED", code: "" },
    { name: "AVAIL FINANCIAL SERVICES LTD.", code: "" },
    { name: "AYE FINANCE PRIVATE LIMITED", code: "" },
    { name: "BFL INVESTMENTS & FINANCIAL CONSULTANTS PVT. LTD.", code: "" },
    { name: "BMW INDIA FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "BUSSAN AUTO FINANCE INDIA PRIVATE LTD.", code: "" },
    { name: "CAPITAL INDIA FINANCE LIMITED", code: "" },
    { name: "CAPITAL TRUST LIMITED", code: "" },
    { name: "CELLCAP INVOFIN INDIA PVT LTD", code: "" },
    { name: "CHOWDRY ASSOCIATES", code: "" },
    { name: "CLIX CAPITAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "CLIX FINANCE INDIA PRIVATE LIMITED", code: "" },
    { name: "CNH INDUSTRIAL CAPITAL (INDIA) PRIVATE LIMITED", code: "" },
    { name: "DHANI LOANS AND SERVICES LIMITED", code: "" },
    { name: "DMI FINANCE PVT LTD.", code: "" },
    { name: "FUSION MICROFINANCE LIMITED", code: "" },
    { name: "GLOBE FINCAP LTD.", code: "" },
    { name: "GYAN ENTERPRISES PVT. LTD.", code: "" },
    { name: "HCL CAPITAL PRIVATE LIMITED", code: "" },
    { name: "HCL CORPORATION PRIVATE LIMITED", code: "" },
    { name: "HERO FINCORP LIMITED", code: "" },
    { name: "HOME CREDIT INDIA FINANCE PRIVATE LIMITED", code: "" },
    { name: "IFCI  LIMITED", code: "" },
    { name: "IFCI FACTORS LTD.", code: "" },
    { name: "IFFCO KISAN FINANCE LIMITED", code: "" },
    { name: "INDIA INFRASTRUCTURE FINANCE COMPANY LIMITED", code: "" },
    { name: "INDIABULLS HOUSING FINANCE LIMITED", code: "" },
    { name: "INDIABULLS COMMERCIAL CREDIT LTD.", code: "" },
    { name: "INDIAN RAILWAY FINANCE CORPORATION LTD", code: "" },
    { name: "INDIAN RENEWABLE ENERGY DEVELOPMENT AGENCY LIMITED", code: "" },
    { name: "LIGHT MICRO FINANCE PRIVATE LIMITED", code: "" },
    { name: "MEHROTRA INVOFIN INDIA PVT LTD", code: "" },
    { name: "MILKY INVESTMENTS AND TRADING COMPANY", code: "" },
    { name: "MONEYWISE FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "OXYZO FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "P C MEDIA SYSTEMS LTD", code: "" },
    { name: "P.C. FINANCIAL SERVICES PVT. LTD.", code: "" },
    { name: "PAISALO DIGITAL LIMITED", code: "" },
    { name: "PNB HOUSING FINANCE LTD", code: "" },
    { name: "PNB GILTS LTD.", code: "" },
    { name: "POWER FINANCE CORPORATION LTD.", code: "" },
    { name: "PTC INDIA FINANCIAL SERVICES LTD.", code: "" },
    { name: "PURAN ASSOCIATES PVT.LTD", code: "" },
    { name: "RAJASTHAN GLOBAL SECURITIES PRIVATE LIMITED", code: "" },
    { name: "RATNA COMMERCIAL ENTERPRISES PRIVATE LIMITED", code: "" },
    { name: "RATTANINDIA FINANCE PRIVATE LIMITED", code: "" },
    { name: "REC LIMITED", code: "" },
    { name: "RELIGARE FINVEST LTD.", code: "" },
    { name: "RKG FINVEST LTD.", code: "" },
    { name: "S V CREDITLINE LIMITED", code: "" },
    { name: "SATIN CREDITCARE NETWORK LIMITED", code: "" },
    { name: "SATYA MICROCAPITAL LIMITED", code: "" },
    { name: "SBI CARDS & PAYMENT SERVICES LTD.", code: "" },
    { name: "SITAL LEASING & FINANCE LTD.", code: "" },
    { name: "TELETEC FINSEC INDIA PVT LTD", code: "" },
    { name: "THE NATIONAL SMALL INDUSTRIES CORPORATION LTD.", code: "" },
    { name: "TOURISM FINANCE CORPORATION OF INDIA LTD.", code: "" },
    { name: "UTSAV SECURITIES PVT. LTD", code: "" },
    { name: "VIC ENTERPRISES P. LTD.", code: "" },
    { name: "WINDY INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "FEDBANK FINANCIAL SERVICES LIMITED", code: "" },
    {
        name: "KERALA STATE BACKWARD CLASSES DEVELOPMENT CORPORATION LTD",
        code: "",
    },
    {
        name: "KERALA STATE INDUSTRIAL DEVELOPMENT CORPORATION LIMITED",
        code: "",
    },
    {
        name: "KERALA TRANSPORT DEVELOPMENT FINANCE CORPORATION LIMITED",
        code: "",
    },
    { name: "KOSAMATTAM FINANCE LIMITED", code: "" },
    { name: "MANAPPURAM FINANCE LIMITED", code: "" },
    { name: "MAXVALUE CREDITS & INVESTMENTS LIMITED", code: "" },
    { name: "MUTHOOT FINANCE  LIMITED", code: "" },
    { name: "MUTHOOT FINCORP LIMITED", code: "" },
    { name: "MUTHOOTTU MINI FINANCIERS LIMITED", code: "" },
    { name: "A N N CAPITAL FINANCE PRIVATE LIMITED", code: "" },
    { name: "AAGAM HOLDINGS PRIVATE LIMITED", code: "" },
    { name: "ABHINANDAN TRADEX LIMITED", code: "" },
    { name: "ABHISHEK FINLEASE LIMITED", code: "" },
    {
        name: "ACHI FINANCE AND MANAGEMENT CONSULTANCY PRIVATE LIMITED",
        code: "",
    },
    { name: "ADARSH CAPITAL FINSTOCK LTD", code: "" },
    { name: "ADINATH EXIM RESOURCES LTD", code: "" },
    { name: "ADJAVIS CAPITAL PRIVATE LIMITED", code: "" },
    { name: "ADVAITA FINANCE SOLUTION PRIVATE LIMITED", code: "" },
    { name: "ADVANCE FINSTOCK PRIVATE LTD.", code: "" },
    { name: "AGARWAL FISCAL PRIVATE LIMITED", code: "" },
    { name: "AIM FINCON PRIVATE LIMITED", code: "" },
    { name: "AIRAN FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "ALFA FISCAL SERVICES PRIVATE LTD", code: "" },
    { name: "ALTITUDE FINVEST LIMITED", code: "" },
    { name: "AMARNATH SECURITIES LIMITED", code: "" },
    { name: "AMARPADMA CREDITS PVT.LTD", code: "" },
    { name: "AMIGO FINSTOCK PRIVATE LTD.", code: "" },
    { name: "ANAND PROPERTY FINANCE LIMITED", code: "" },
    { name: "ANANYA FINANCE FOR INCLUSIVE GROWTH PVT. LTD.", code: "" },
    { name: "ANKIT FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "ANMOL TIE UP PRIVATE LIMITED", code: "" },
    { name: "APA FINANCE LIMITED", code: "" },
    { name: "APOLLO INDUSTRIES & PROJECT LTD.", code: "" },
    { name: "ARIHANT CLASSIC FINANCE LTD", code: "" },
    { name: "ARISTO TRADERS PRIVATE LIMITED", code: "" },
    { name: "ARMOUR CAPITAL PRIVATE LIMITED", code: "" },
    { name: "ARYAFINROOT FINANCIALS PRIVATE LIMITED", code: "" },
    {
        name: "ASCOM LEASING & INVESTMENTS LIMITED (TRANSFERRED FROM HYDERABAD RO)",
        code: "",
    },
    { name: "ASHOK FAMILY HOLDINGS PVT. LTD.", code: "" },
    { name: "ASHUTOSH AUTOFIN PVT LTD", code: "" },
    { name: "ASHUTOSH FINSEC LIMITED", code: "" },
    { name: "ATLANTA INFRASTRUCTURE AND FINANCE LTD", code: "" },
    { name: "ATREYI VINCOM PRIVATE LIMITED", code: "" },
    { name: "ATUL FIN RESOURCES LIMITED", code: "" },
    { name: "AURFIN LEASING LIMITED", code: "" },
    { name: "AVIRAT FINANCE PRIVATE LIMITED", code: "" },
    { name: "AVISA FINANCE LIMITED", code: "" },
    {
        name: "AXON FINANCE AND SECURITIES LTD (PREVIOUSLY KNOWN AS UNITY SECURITIES & FINANCE LTD)",
        code: "",
    },
    { name: "BANSIDHAR LEASE AND FINANCE PRIVATE LTD.", code: "" },
    { name: "BARODA BROKERS PVT. LTD.", code: "" },
    { name: "BHANDERI FINCAP LIMITED", code: "" },
    { name: "BHARAT FINLEASE LIMITED", code: "" },
    { name: "BIR FINANCE PRIVATE LIMITED", code: "" },
    { name: "BRAHMA LEASING & FINANCE PVT. LTD.", code: "" },
    { name: "BRAHMPUTRA CAPITAL & FINANCIAL SERVICES LIMITED", code: "" },
    { name: "BRIJLAXMI LEASING AND FINANCE LTD.", code: "" },
    {
        name: "BULTI LEASING & FINANCE LTD (RESTORED VIDE AA ORDER DATED DECEMBER 2",
        code: "",
    },
    { name: "CAPTREE FINANCE PVT LTD", code: "" },
    { name: "CEEJAY FINANCE LTD", code: "" },
    { name: "CEEJAY MICROFIN LIMITED", code: "" },
    { name: "CENTURY VEHICLELOANS PRIVATE LIMITED", code: "" },
    { name: "CHAMPAWAT INVESTMENTS & CONSULTANTS PVT. LTD.", code: "" },
    { name: "CHAMPION AGRO FINANCE LIMITED", code: "" },
    { name: "CHOUDHRY FINANCE AND INVESTMENT PVT LTD", code: "" },
    { name: "CHRYSALIS FINANCE LTD.", code: "" },
    { name: "CLIMAX WATERPROOFING PRODUCTS PRIVATE LIMITED", code: "" },
    { name: "COUNTWELL MANAGEMENT SERVICES (P) LTD.", code: "" },
    { name: "D. S. INTEGRATED FINSEC PVT. LTD.", code: "" },
    { name: "DAVE SECURITIES PRIVATE LTD.", code: "" },
    { name: "DEALING BENEFICIAL FINANCIAL SERVICES PVT. LTD.", code: "" },
    {
        name: "DEVIKA FINCAP PRIVATE LIMITED (RESTORED VIDE AA ORDER DATED JANUARY 20",
        code: "",
    },
    { name: "DH  QUICKFIN  PRIVATE  LIMITED", code: "" },
    {
        name: "DIMPLE FINANCE AND INVESTMENT PRIVATE LIMITED (TRANSFERRED FROM CHANDIGARH RO)",
        code: "",
    },
    { name: "DIVINE LIGHT FINANCE LIMITED", code: "" },
    { name: "DIVYALAXMI FINLEASE PVT. LTD.", code: "" },
    { name: "DIVYANIRMAN GOLD FINANCE PRIVATE LIMITED", code: "" },
    { name: "DOWNTOWN FINANCE PRIVATE LTD", code: "" },
    { name: "DP SONS FINVEST PRIVATE LIMITED", code: "" },
    { name: "EDIFICE FINCAP PRIVATE LIMITED", code: "" },
    { name: "ENN ENN CAPITAL PRIVATE LIMITED", code: "" },
    { name: "FINCRED INVESTMENTS LIMITED", code: "" },
    { name: "FINSTARS CAPITAL LIMTED", code: "" },
    { name: "FLIPSTAR FINANCE LIMITED", code: "" },
    { name: "G K FINCAP LIMITED", code: "" },
    { name: "GANDHINAGAR LEASING & FINANCE LTD.", code: "" },
    { name: "GANESH FINSTOCK LIMITED", code: "" },
    { name: "GEETGANGA INVESTMENT PRIVATE LIMITED", code: "" },
    { name: "GILTEDGED INDUSTRIAL SECURITIES LIMITED", code: "" },
    { name: "GOYAL ASSOCIATES LTD.", code: "" },
    { name: "GRAMADHAR FINSERVE PRIVATE LIMITED", code: "" },
    { name: "GSD TRADING AND FINANCIAL SERVICES PVT. LTD", code: "" },
    { name: "GUARDIAN FINANCE PRIVATE LIMITED", code: "" },
    { name: "GUJARAT FINVEST SERVICES PRIVATE LIMITED", code: "" },
    { name: "GUJARAT INVESTA LTD.", code: "" },
    { name: "HARDIK FINANCE LIMITED", code: "" },
    { name: "HASU INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "HET FINANCE PRIVATE LIMITED", code: "" },
    { name: "HIM FINCAP PRIVATE LIMITED", code: "" },
    { name: "HIMACHAL MOTOR AND GENERAL FINANCE LIMITED", code: "" },
    { name: "HIREN HOLDINGS PRIVATE LIMITED", code: "" },
    { name: "IBL FINANCE PRIVATE LIMITED", code: "" },
    { name: "INDO CREDIT CAPITAL LIMITED", code: "" },
    { name: "ISHA SECURITIES  LTD.", code: "" },
    { name: "JAIN FINSCAP PRIVATE LIMITED", code: "" },
    { name: "JASPAT INVESTMENTS & CONSULTANCY P. LTD.", code: "" },
    { name: "JYOT INTERNATIONAL MARKETING LIMITED", code: "" },
    { name: "K.L.B. SECURITIES PRIVATE LTD.", code: "" },
    { name: "KALINGA HIRE PURCHASE FINANCE LIMITED", code: "" },
    { name: "KAPASHI COMMERCIAL LTD.", code: "" },
    { name: "KARNAVATI CAPITAL MARKET LTD.", code: "" },
    { name: "KASHIBA FINANCE PVT. LTD.", code: "" },
    { name: "KESHAV FINCAP PRIVATE LIMITED", code: "" },
    { name: "KHAVDA FINANCE LTD", code: "" },
    {
        name: "KHETAN TREXCON PVT LTD. (TRANSFERRED TO AHMEDABAD RO VIDE LETTER DATED 11/12/2020)",
        code: "",
    },
    { name: "KHETAWAT FINANCIAL RESOURCES PRIVATE LIMITED", code: "" },
    { name: "KHUSHBU AUTO FINANCE LIMITED", code: "" },
    { name: "KIFS FINANCIAL SERVICES LIMITED", code: "" },
    { name: "KIVA CAPITAL PRIVATE LIMITED", code: "" },
    { name: "KRISHNA  CAPITAL  &SECURITIES  LTD", code: "" },
    { name: "KRONE FINSTOCK PRIVATE LIMITED", code: "" },
    { name: "KRUNAL CONSTRUCTION & FINANCE LTD.", code: "" },
    { name: "KUBER UDYOG LTD", code: "" },
    { name: "KUNJAL INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "KUNVARJI FINCORP PVT. LTD", code: "" },
    { name: "LALWANI LEASE FINANCE LIMITED", code: "" },
    { name: "LANDMARK GLOBFIN LIMITED", code: "" },
    { name: "LIANER DISTRIBUTORS PRIVATE LIMITED", code: "" },
    { name: "LONG VIEW FINANCIAL SERVICES PVT. LTD", code: "" },
    { name: "LUCKY-DHAN VYAPAAR PRIVATE LIMITED", code: "" },
    { name: "LUFT CAPITALS LIMITED", code: "" },
    { name: "M.G.F. GROWTH RESEARCH AND INVESTMART LIMITED", code: "" },
    { name: "M/S MANDHANA LEAFIN LIMITED", code: "" },
    { name: "MADHURAM  TRADERS  PRIVATE LIMITED", code: "" },
    { name: "MAHAN INDUSTRIES LIMITED", code: "" },
    { name: "MALIBU KAPITAL LIMITED", code: "" },
    { name: "MANGALAM FINSERV PRIVATE LIMITED", code: "" },
    { name: "MANI MARKET CREATERS LTD", code: "" },
    { name: "MANSAROVER TRADEX LIMITED", code: "" },
    {
        name: "MARCO PRIVATE LIMITED (FORMERLY AS YGB HOLDINGS PVT. LTD. )",
        code: "",
    },
    { name: "MARG TECHNO PROJECTS LTD", code: "" },
    { name: "MARWADI FINLEASE PVT. LTD.", code: "" },
    { name: "MAULESH INVESTMENT PRIVATE LTD.", code: "" },
    { name: "MAXIM FINANCE PVT. LTD.", code: "" },
    { name: "MERIDIAN FINANCE PRIVATE LIMITED", code: "" },
    { name: "MERIDIAN SECURITIES PRIVATE LTD.", code: "" },
    { name: "MERIT CREDIT CORPORATION LIMITED", code: "" },
    { name: "MINIX HOLDINGS  PVT.  LTD", code: "" },
    { name: "MIXON HOLDINGS PVT. LTD.", code: "" },
    { name: "MOYAL INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "MRUNAL AGENCY & FINANCIALS PRIVATE LIMITED", code: "" },
    { name: "NALIN LEASE FINANCE LTD", code: "" },
    { name: "NALWA TRADING LIMITED", code: "" },
    { name: "NAR NARAYAN FINCAP PRIVATE LIMITED", code: "" },
    { name: "NARMADA FINTRADE PVT. LIMITED", code: "" },
    { name: "NAVDEEP INVESTMENT PRIVATE LIMITED", code: "" },
    { name: "NAVDURGA INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "NJ CAPITAL PRIVATE LIMITED", code: "" },
    { name: "NOVATIS FINOVATIVE LIMITED", code: "" },
    { name: "OPTIMUS FINANCE LIMITED", code: "" },
    { name: "ORIENTAL FINSTOCK SERVICES LTD", code: "" },
    { name: "PACIFIC FINSTOCK LTD", code: "" },
    { name: "PADMALAYA FINSERVE PRIVATE LIMITED", code: "" },
    { name: "PARKER FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "PARSHWA CREDIT AND FINANCE PRIVATE LIMITED", code: "" },
    { name: "POOJA FINSTOCK INTERNATIONAL LIMTIED", code: "" },
    { name: "PRAHASH FIN-STOCK PRIVATE LTD", code: "" },
    { name: "PRAMOL TRADERS HOLDINGS PRIVATE LIMITED", code: "" },
    { name: "PRASHAM FINANCE LIMITED", code: "" },
    { name: "PRAYAS FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "PREMIUM FINANCE PVT. LTD.", code: "" },
    { name: "PRIPAN  INVESTMENT  PRIVATE  LTD", code: "" },
    { name: "PRISM  FINANCE  LTD", code: "" },
    { name: "PUNJAB LEASE FINANCING LIMITED", code: "" },
    { name: "RAAJRATNA STOCKHOLDING PRIVATE LIMITED", code: "" },
    { name: "RADHE FINSEC INDIA LTD", code: "" },
    { name: "RAJ RADHE FINANCE LTD.", code: "" },
    { name: "RAJKOT INVESTMENT TRUST LTD", code: "" },
    { name: "RAJRUSHI WEALTH CREATORS LIMITED", code: "" },
    { name: "RAMCHANDRA  LEASING  &FINANCE  LTD", code: "" },
    { name: "RAMKRISHNA FINLEASE PRIVATE LIMITED", code: "" },
    { name: "RATNAAFIN CAPITAL PRIVATE LIMITED", code: "" },
    { name: "RAVI FINCORP LIMITED", code: "" },
    { name: "RIDDHI SECURITIES LIMITED", code: "" },
    { name: "RIYA CAPLEASE (INDIA) LTD.", code: "" },
    { name: "RSA FINCORP PRIVATE LIMITED", code: "" },
    { name: "RUDRA SECURITIES & CAPITAL LTD.", code: "" },
    { name: "SAGAR SAMRAT CAPLEASE PRIVATE LIMITED", code: "" },
    { name: "SAMPATI SECURITIES LIMITED", code: "" },
    { name: "SANJEET MOTOR FINANCE PRIVATE LIMITED", code: "" },
    { name: "SATYAM FINSTOCK PRIVATE LIMITED", code: "" },
    { name: "SHANTI SURI SECURITIES PRIVATE LIMITED", code: "" },
    { name: "SHARAD FINLEASE PRIVATE LIMITED", code: "" },
    { name: "SHELJA FINLEASE PRIVATE LIMITED", code: "" },
    { name: "SHIKHA INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "SHILCHAR FINANCE PRIVATE LIMITED", code: "" },
    { name: "SHREE BALA FINVEST PRIVATE LIMITED", code: "" },
    { name: "SHREE KAMDHENU FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "SHREE RAMKRUPA FINANCE PRIVATE LIMITED", code: "" },
    { name: "SHREE UMIYA FINLEASE PRIVATE LIMITED", code: "" },
    { name: "SHRI ARYA INVESTMENT PRIVATE LIMITED", code: "" },
    { name: "SHROFF CAPITAL & FINANCE PRIVATE LIMITED", code: "" },
    { name: "SIDDHI VINAYAK FINCAP LIMITED", code: "" },
    { name: "SIHL FINCAP LIMITED", code: "" },
    { name: "SIMANDHAR FINANCE LIMITED", code: "" },
    { name: "SIMANDHAR FINLEASE PRIVATE LIMITED", code: "" },
    { name: "SPAN FINCAP LIMITED", code: "" },
    { name: "STANROSE MAFATLAL INVESTMENTS & FINANCE LTD", code: "" },
    { name: "SUGAN ENTERPRISE PRIVATE LIMITED", code: "" },
    { name: "SUGAN FINSTOCK PRIVATE LIMITED", code: "" },
    { name: "SUMAN FINSTOCKS PRIVATE LIMITED", code: "" },
    { name: "SUN FINLEASE (GUJARAT) LIMITED", code: "" },
    { name: "SUN INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "SUNGOLD CAPITAL LIMITED", code: "" },
    { name: "SUNRISE FINLEASE LIMITED", code: "" },
    { name: "TANOT FINANCE LIMITED", code: "" },
    { name: "TAPTIGANGA FINSERV PRIVATE LIMITED", code: "" },
    { name: "TEXMAT AGENCIES PRIVATE LIMITED", code: "" },
    { name: "TIRUPATI FINLEASE LTD.", code: "" },
    { name: "TOKYO FINANCE LTD.", code: "" },
    { name: "TRADEBULLS CAPITAL LIMITED", code: "" },
    { name: "TRENDLINE FINANCE LTD", code: "" },
    { name: "TRIBHUVAN FINLEASE & DEVELOPERS PVT LTD", code: "" },
    { name: "TRIMURTI FINCAP PRIVATE LIMITED", code: "" },
    { name: "TRIPOLI MANAGEMENT PRIVATE LIMITED", code: "" },
    { name: "TRISHNA FINVEST PVT. LTD.", code: "" },
    { name: "TRUST FINSTOCK PRIVATE LIMITED", code: "" },
    { name: "TYPHOON  FINANCIAL  SERVICES  LTD", code: "" },
    { name: "ULHAS SECURITIES PRIVATE LIMITED", code: "" },
    { name: "UNICON SUPPLIERS PRIVATE LIMITED", code: "" },
    { name: "VAISHALI SECURITIES LIMITED", code: "" },
    { name: "VARDAN FINLEASE LIMITED", code: "" },
    { name: "VDCAP FINSERV PRIVATE LIMITED", code: "" },
    { name: "VIKRAM VENTURE CAPITAL PVT.LTD.", code: "" },
    { name: "VINI INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "VINZOL CAPITAL & FINANCE LTD.", code: "" },
    { name: "VIP FINSTOCKS PVT. LTD.", code: "" },
    { name: "VISNAGAR LEASE FINANCE LTD.", code: "" },
    { name: "WELL FIN SECURITIES LIMITED", code: "" },
    { name: "WELLWISHER SALES PROMOTION PRIVATE LIMITED", code: "" },
    { name: "WESTERN CAPITAL ADVISORS PRIVATE LIMITED", code: "" },
    { name: "WESTERN FINTRADE PVT.LTD.", code: "" },
    { name: "WHITE-ELEPHANT FINANCE LIMITED", code: "" },
    { name: "Y AND H PATEL FINANCE LIMITED", code: "" },
    { name: "YASH FINCAP LIMITED", code: "" },
    { name: "ZEN FINCORP PRIVATE LIMITED", code: "" },
    { name: "ACHAL FINANCE LTD", code: "" },
    { name: "ANASHRI FINVEST LIMITED", code: "" },
    {
        name: "APANA FINVEST PVT LTD (TRANSFERRED FROM KOLKATA OFFICE)",
        code: "",
    },
    { name: "APARAMPAAR FINANCE PRIVATE LIMITED", code: "" },
    { name: "APAROKSHA FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "AVANTI FINANCE PRIVATE LIMITED", code: "" },
    { name: "AVANTI MICROFINANCE PRIVATE LIMITED", code: "" },
    { name: "BAGARIA INVESTECH PVT LIMITED", code: "" },
    { name: "BAIRAVI FINANCE PRIVATE LIMITED", code: "" },
    { name: "BASANTH INVESTMENTS (P) LTD.", code: "" },
    { name: "BHARAT ENERGY VENTURES LIMITED", code: "" },
    {
        name: "BIKANNA COMMERCIAL COMPANY LTD(TRANSFERRED FROM KOLKOTA RO)",
        code: "",
    },
    { name: "BILLIONLOANS FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "BOTHRA CAPITAL PRIVATE LIMITED", code: "" },
    { name: "CELINDIA FINANCE & INVESTMENT COMPANY LIMITED", code: "" },
    {
        name: "CHEMM FINANCE LTD  (CONVERTED FROM DEPOSIT TO NON-DEPOSIT TAKING NBFC)",
        code: "",
    },
    {
        name: "CHETANA FINANCIAL SERVICES PRIVATE LIMITED (FORMERLY NIMBA FINANCE & LEASING LTD)",
        code: "",
    },
    { name: "CITRUS SECURITIES PRIVATE LIMITED", code: "" },
    { name: "CONATUS FINSERVE PRIVATE LIMITED", code: "" },
    { name: "COORG TEA COMPANY LTD", code: "" },
    { name: "DAVINTA FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "DELFIN INTERNATIONAL LIMITED", code: "" },
    { name: "DESHANA FINLEASE SERVCES LTD", code: "" },
    { name: "DHANLAXMI MOTORS FINANCE PVT LTD", code: "" },
    { name: "DHRUVI SECURITIES PVT LTD", code: "" },
    { name: "DIGANTA INVESTMENTS AND FINANCE CO. LTD", code: "" },
    { name: "EKAGRATA FINANCE PRIVATE LIMITED", code: "" },
    { name: "ETYACOL TECHNOLOGIES PRIVATE LIMITED", code: "" },
    { name: "EZCRED PRIVATE LIMITED", code: "" },
    { name: "FESTINO COMMERCE PRIVATE LIMITED", code: "" },
    {
        name: "FINCFRIENDS PRIVATE LIMITED (REISSUED ON DECEMBER 24",
        code: "",
    },
    { name: "FINNABLE CREDIT PRIVATE LIMITED", code: "" },
    { name: "GEETANJALI CREDIT AND CAPITAL LIMITED", code: "" },
    { name: "GENFIN CAPITAL PRIVATE LIMITED", code: "" },
    { name: "GILADA FINANCE AND INVESTMENTS LTD.", code: "" },
    {
        name: "GRANDHI ENTERPRISES  PRIVATE LIMITED(TRANSFERED FROM CHE FORMERLY BLUEMOON INVESTMENTS P LTD)",
        code: "",
    },
    { name: "HERAMBA LEASING LTD", code: "" },
    { name: "HIVELOOP CAPITAL PRIVATE LIMITED", code: "" },
    { name: "IDF FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "IMPACT CORPORATE INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "JAYANTH FINANCIAL  SERVICES", code: "" },
    {
        name: "JINVANI TRADING & INVESTMENT COMPANY PRIVATE LIMITED (FILES SHIFTED TO BANGALORE RO)",
        code: "",
    },
    { name: "KADAMBA MYMONEY LIMITED.", code: "" },
    { name: "KALANDRI CAPITAL PRIVATE LIMITED", code: "" },
    {
        name: "KALLARAKKAL FINCORP PRIVATE LIMITED ( FORMELY KNOWN AS HMF FINANCIAL SERVICES PRIVATE LIMITED) (FORMERLY KNOWN AS ADHOKSHAJA FINANCE & INVESTMENTS (P)LTD)",
        code: "",
    },
    { name: "KALPAVITTA FINANCE PRIVATE LIMITED", code: "" },
    { name: "KANVA SHREE CREDIT PVT LTD", code: "" },
    { name: "KASHIRAJ SUPPLY PRIVATE LIMITED", code: "" },
    { name: "KAUNSHYA INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "KEMFIN SERVICES PVT LTD", code: "" },
    { name: "KNAB FINANCE ADVISORS PRIVATE LIMITED", code: "" },
    { name: "KOUSHIK FINANCIAL SERVICES PVT LTD", code: "" },
    { name: "KSHETRAPAL FINANCE PVT LTD", code: "" },
    {
        name: "LAKSHMI SARASWATHI MOTORS & GENERAL FINANCE ( PVT.) LTD.",
        code: "",
    },
    { name: "LAMINA LEASING AND FINANCE LTD", code: "" },
    { name: "LF2PEER FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "LOANZEN FINANCE PRIVATE LIMITED", code: "" },
    { name: "LTCV CREDIT PRIVATE LIMITED", code: "" },
    { name: "MABA CORPORATE SERVICES PVT LTD", code: "" },
    {
        name: "MANJUSHREE FINCAP PRIVATE LIMITED  (TRANSFERED FROM GUWAHATI)",
        code: "",
    },
    { name: "MEGHANA CORPORATES PVT LTD", code: "" },
    { name: "MICROGRAAM MARKETPLACE PRIVATE LIMITED", code: "" },
    { name: "MOHAM CAPITAL PRIVATE LIMITED", code: "" },
    { name: "MONEYGEAR FINTECH PRIVATE LIMITED", code: "" },
    { name: "MOTCAB FINANCE PRIVATE LIMITED", code: "" },
    { name: "MYSORE LEASING & FINANCE LIMITED", code: "" },
    { name: "NAGARJUNA CREDITS AND CAPITALS LTD.", code: "" },
    { name: "NARENDRA FINANCE CO PVT LTD", code: "" },
    { name: "NAVACHETANA MICROFIN SERVICES PRIVATE LIMITED", code: "" },
    { name: "NIRANTARA FINACCESS PRIVATE LIMITED", code: "" },
    { name: "NISKHA FINANCE PRIVATE LIMITED", code: "" },
    { name: "NITSTONE FINSERV PRIVATE LIMITED", code: "" },
    { name: "NUTAN TRADE & AGENCIES PRIVATE LIMITED", code: "" },
    { name: "O3 FINANCE PRIVATE LIMITED", code: "" },
    { name: "OLETY FINANCE PVT LTD.", code: "" },
    { name: "OPPORTUNITY MICRO FINANCE INDIA LIMITED", code: "" },
    { name: "P.S.N FINANCE PRIVATE LIMITED", code: "" },
    { name: "PARK VIEW PROPERTIES PRIVATE LIMITED", code: "" },
    { name: "PCI MARKETING PRIVATE LIMITED", code: "" },
    {
        name: "PERFIOS ACCOUNT AGGREGATION SERVICES PRIVATE LIMITED",
        code: "",
    },
    { name: "PERSEUS FINANCE PVT LTD", code: "" },
    {
        name: "PRAVEEN CAPITAL PRIVATE LIMITED (TRANSFERRED FROM MUMBAI RO)",
        code: "",
    },
    { name: "PREKSHA FINANCE PRIVATE LIMITED", code: "" },
    { name: "PROLIFIC FINVEST PVT LTD", code: "" },
    { name: "PUPIL FINANCE PRIVATE LIMITED", code: "" },
    { name: "QUADRILLION FINANCE PRIVATE LIMITED", code: "" },
    { name: "RAMAIAH CAPITAL PRIVATE LIMITED", code: "" },
    { name: "RANG DE P2P FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "RNAWEP INVESTMENTS PRIVATE LIMITED", code: "" },
    {
        name: "RORS FINANCE PRIVATE LIMITED (FORMERLY KNOWN AS PACE FINANCE PRIVATE LIMITED)",
        code: "",
    },
    { name: "RSK FUND FINANCE PRIVATE LIMITED", code: "" },
    { name: "RUPEEK CAPITAL PVT LTD", code: "" },
    { name: "SAGAR LEASFIN LTD", code: "" },
    { name: "SHAAN FINANCE  LIMITED (RECLASSIFIED TO B)", code: "" },
    { name: "SHABRI INVESTMENTS PRIVATE LIMITED", code: "" },
    { name: "SHIVANGINI PROPERTIES LIMITED", code: "" },
    { name: "SHREE BIRADEV FINANCE PVT LTD", code: "" },
    { name: "SHREE MARIKAMBA MICRO FINANCE PRIVATE LIMITED", code: "" },
    {
        name: "SHRUTI FINANCIAL SERVICES PRIVATE LIMITED (TRANSFERED FROM GUWAHATI)",
        code: "",
    },
    { name: "SOUTH INDIA FINVEST PRIVATE LIMITED", code: "" },
    { name: "SOUTHERN HIGHTECH CAPITAL PRIVATE LIMITED", code: "" },
    { name: "SOWJENVEE FINANCE LTD", code: "" },
    { name: "SRI RAMACHANDRA ENTERPRISES PVT LTD", code: "" },
    { name: "SRIKARA FINANCE & INVESTMENT PVT LTD", code: "" },
    { name: "SURABHI FINANCE CORPORATION LIMITED", code: "" },
    { name: "TAPSTART CAPITAL PRIVATE LIMITED", code: "" },
    { name: "TECHFINO CAPITAL PRIVATE LIMITED", code: "" },
    { name: "THE GENERAL INVESTMENT & COMMERCIAL CORPORATION", code: "" },
    { name: "THE WESTERN INDIA INDUSTRIAL CORPORATION", code: "" },
    {
        name: "TRIKAAL LEASING AND FINANCE LTD - CONVERTED TO NBFC-ND-ICC VIDE RDS ORDER DATED AUGUST 10",
        code: "",
    },
    { name: "U M B SECURITIES LIMITED", code: "" },
    { name: "U.D.FINNVEST PVT LTD", code: "" },
    { name: "UC INCLUSIVE CREDIT PRIVATE LIMITED", code: "" },
    { name: "UNIMONI FINANCIAL SERVICES LIMITED", code: "" },
    { name: "UPMOVE CAPITAL PRIVATE LIMITED", code: "" },
    { name: "VASAVI FINVEST   LTD", code: "" },
    { name: "VIJAYANAGAR INDUSTRIAL CREDITS LTD", code: "" },
    { name: "VIMAL INFIN  PVT  LTD", code: "" },
    { name: "VINAYAK COMMERCE PRIVATE LIMITED", code: "" },
    { name: "WAY 2 WEALTH CAPITAL PVT LIMITED", code: "" },
    { name: "WHIZDM FINANCE PRIVATE LIMITED", code: "" },
    { name: "WORTGAGE FINANCE PRIVATE LIMITED", code: "" },
    {
        name: "YUNUS SOCIAL BUSINESS FUND BENGALURU PRIVATE LIMITED",
        code: "",
    },
    { name: "ZERODHA CAPITAL PRIVATE LIMITED", code: "" },
    { name: "A S P FINCAP PRIVATE LIMITED", code: "" },
    { name: "AALOK DEEP FINANCE PVT. LTD.", code: "" },
    { name: "AB LEASING & FINANCE LIMITED", code: "" },
    { name: "ADCON CAPITAL SERVICES LTD.", code: "" },
    { name: "AD-MANUM FINANCE LIMITED.", code: "" },
    { name: "AEREO DEALCOM PRIVATE LIMITED", code: "" },
    { name: "AGNI FIN-LEASE PVT.LTD.", code: "" },
    { name: "ANJANI FINANCE LIMITED", code: "" },
    { name: "ANTARAL FINANCE & INVESTMENT (P) LTD.", code: "" },
    { name: "ANTRIKSH COMMERCE PRIVATE LIMITED", code: "" },
    { name: "ARHAM CREDIT CAPITAL PVT LTD", code: "" },
    { name: "ARIHANT FINANCIAL SERVICES LTD.", code: "" },
    { name: "ARIHANT UDYOG LTD.", code: "" },
    { name: "ARMOUR FINANCE & COMMERCE PVT. LTD.", code: "" },
    { name: "ARYABHATT SALES PRIVATE LIMITED", code: "" },
    { name: "ASCENT LEASING & FINANCE CO. LTD.", code: "" },
    { name: "ATLANTA SECURITIES PRIVATE LIMITED", code: "" },
    { name: "AVAILABLE FINANCE LTD.", code: "" },
    { name: "AVIRAL FINANCE PRIVATE LIMITED", code: "" },
    { name: "B.C.M. INVESTMENTS (P) LTD.", code: "" },
    { name: "BANKA FINANCE & SECURITIES PVT. LTD.", code: "" },
    { name: "BERYL SECURITIES LIMITED", code: "" },
    { name: "BEYANT HOLDING & FINANCE LTD.", code: "" },
    { name: "BLUE RIVER FINVEST PRIVATE LIMITED", code: "" },
    { name: "CASELLA SECUTITIES PVT. LTD.", code: "" },
    { name: "CHANDRALOK FINVEST PRIVATE LIMITED", code: "" },
    { name: "CHARBHUJA FINANCE LTD..", code: "" },
    { name: "CHORSIYA FINVEST PRIVATE LIMITED", code: "" },
    { name: "CI CAPITAL PRIVATE LIMITED", code: "" },
    { name: "COMPEER LEASING & INVESTMENT PVT. LTD.", code: "" },
    { name: "CONTINENTAL JEWELTECH MINING PVT. LTD.", code: "" },
    { name: "DINPATI COMMERCIALS PRIVATE LIMITED", code: "" },
    { name: "DITYA FINANCE PRIVATE LIMITED", code: "" },
    { name: "DOLPHIN HOLDINGS & REAL ESTATE PVT. LTD", code: "" },
    { name: "DRAP LEASING & FINANCE PVT. LTD.", code: "" },
    { name: "EAST WEST FINVEST INDIA  LIMITED", code: "" },
    { name: "ECONOTECH SERVICES PRIVATE LIMITED", code: "" },
    { name: "ESCORT FINVEST PRIVATE LIMITED", code: "" },
    { name: "FAIRWELL FINANCE LTD.", code: "" },
    { name: "FASTLEND CAPITAL LIMITED", code: "" },
    { name: "G.S. LEASING & FINANCE PVT. LTD.", code: "" },
    { name: "GAGAN INVESTMENT PVT LTD", code: "" },
    { name: "GANGOTRI TRACON PVT. LTD.", code: "" },
    { name: "GENESIS SECURITIES PVT. LTD.", code: "" },
    { name: "GTLAXMI FINANCE PRIVATE LIMITED", code: "" },
    { name: "HARA COMMERCIALS PVT LTD", code: "" },
    { name: "HARE KRISHNA FINANCE PRIVATE LIMITED", code: "" },
    { name: "HIMMAT FINLEASE PVT. LTD.", code: "" },
    { name: "HIND CARPETS PVT LTD", code: "" },
    { name: "IRFC FINANCE PRIVATE LIMITED", code: "" },
    { name: "IVES LEASING AND FINANCE LTD.", code: "" },
    { name: "J.V. MODI SECURITIES LTD.", code: "" },
    { name: "JAINAND FINVEST PRIVATE LIMITED", code: "" },
    { name: "JDS CAPITAL MARKETS PVT. LTD.", code: "" },
    { name: "K.K. PATEL FINANCE LTD.", code: "" },
    { name: "K.S. FINLEASE LTD.", code: "" },
    { name: "KHEDIA FINVEST (INDIA) PVT. LIMITED", code: "" },
    { name: "KRISHNAKRIPA HOLDINGS PRIVATE LIMITED", code: "" },
    { name: "LANDMARK CAPITAL MARKETS PVT. LTD.", code: "" },
    { name: "LEO S.M. FINANCE LIMITED", code: "" },
    { name: "M.L. SECURITIES & FINANCE PRIVATE LIMITED", code: "" },
    { name: "MAHAMAYA FINANCE PRIVATE LIMITED", code: "" },
    { name: "MAHAUTKARSH SECURITIES & FINANCE PVT. LTD", code: "" },
    { name: "MAJESTIC LEASING COMPANY PRIVATE LIMITED", code: "" },
    { name: "MALANI FINANCIAL SERVICES LIMITED", code: "" },
    { name: "MANGLAMBALAJEE FINANCE LIMITED", code: "" },
    { name: "MARWAH FINANCE PRIVATE LIMITED", code: "" },
    { name: "MSJ COLONISING AND LEASING CO. PVT. LTD.", code: "" },
    { name: "MVS LEASING PRIVATE LIMITED", code: "" },
    { name: "NEURATY AGENTS PRIVATE LIMITED", code: "" },
    { name: "ORRISH FINANCE PRIVATE LIMITED", code: "" },
    { name: "P J CREDIT CAPITAL LTD.", code: "" },
    { name: "PALRIWALA FINANCE PVT. LTD.", code: "" },
    { name: "PANAJI FINANCE LIMITED", code: "" },
    { name: "PIONEER MAX BUSINESS PVT LTD.", code: "" },
    { name: "PRECISE FINANCE & INVESTMENT LIMITED", code: "" },
    { name: "PRODIGEE FINANCE LIMITED", code: "" },
    { name: "PUJAM SALES AND SERVICES PRIVATE LIMITED", code: "" },
    { name: "PURVI FINVEST  LIMITED", code: "" },
    { name: "R.G. FINLEASE PVT. LTD.", code: "" },
    { name: "RAJVIR SECURITIES AND FINANCE PVT. LTD.", code: "" },
    { name: "RANEKA FINCOM  PRIVATE LIMITED", code: "" },
    { name: "RANJIT SECURITIES LTD.", code: "" },
    { name: "RATHI FINLEASE LTD.", code: "" },
    { name: "RDK GLOBAL FINANCE PRIVATE LIMITED", code: "" },
    { name: "RECAPITA FINANCE PRIVATE LIMITED", code: "" },
    { name: "RUDRAKSH GOLD SECURITIES PVT. LTD.", code: "" },
    { name: "SAMRUDDHI SWASTIK TRADING & INVESTMENTS LIMITED", code: "" },
    { name: "SARDA RESOURCES PVT. LTD.", code: "" },
    { name: "SATYA PRAKASH CAPITAL INVESTMENT LTD.", code: "" },
    { name: "SAWALRAM LEASING & FINANCING LTD.", code: "" },
    { name: "SCINDIA INVESTMENTS PVT. LTD.", code: "" },
    { name: "SGK FINVEST PVT. LTD.", code: "" },
    { name: "SHREE NAIVEDYA FINCOM PVT.LTD.", code: "" },
    { name: "SHREYASH SECURITIES & FINANCE LIMITED", code: "" },
    { name: "SHRI AARISHT FINANCE PRIVATE LIMITED", code: "" },
    { name: "SHRI RAM FINANCE CORPORATION  PRIVATE LIMITED", code: "" },
    { name: "SILVER FINANCE SOLUTIONS PRIVATE LIMITED", code: "" },
    { name: "SILVER STAR FINANCE & LEASING LTD", code: "" },
    { name: "SOJATIA AUTO FINANCE PVT. LTD.", code: "" },
    { name: "SUKHCHAIN HIRE PURCHASE PRIVATE LIMITED", code: "" },
    { name: "SULABH PLANTATIONS AND FINANCE PVT. LTD.", code: "" },
    { name: "SUNITA FINLEASE LTD.", code: "" },
    { name: "SWADHA FINLEASE PRIVATE LIMITED", code: "" },
    { name: "SWAN FINANCE LIMITED", code: "" },
    { name: "SWASTIKA FIN-MART PRIVATE LIMITED", code: "" },
    { name: "SWASTIKA PRINTS PRIVATE LIMITED", code: "" },
    { name: "TDS FINCAP  PVT. LTD.", code: "" },
    { name: "TRIDENT LAMI-PACK PRIVATE LIMITED", code: "" },
    { name: "TRIMURTHI FINVEST PVT. LTD.", code: "" },
    { name: "TUMUS INVESTMENT PVT. LTD.", code: "" },
    { name: "UNITARA FINANCE LIMITED", code: "" },
    { name: "VICONIC VYAPAR PVT LIMITED", code: "" },
    { name: "VIIKING FINANCE PRIVATE LIMITED", code: "" },
    { name: "VIJI FINANCE LIMITED", code: "" },
    { name: "VSC FINANCE PVT. LTD.", code: "" },
    { name: "WHITE PAPER CAPITAL LIMITED", code: "" },
    { name: "WOOD CAPITAL FINANCE LIMITED", code: "" },
    { name: "ADHIKAR MICROFINANCE LTD (TRFD FROM AHMEDABAD)", code: "" },
    {
        name: "BHARATIYA FINCOM PRIVATE LIMITED (FORMERLY UTKAL HOLDINGS PVT. LTD.)",
        code: "",
    },
    {
        name: "CARNEX SALES AGENCIES PRIVATE LIMITED (TRANSFERRED FROM KOLKATA RO)",
        code: "",
    },
    { name: "EAST COAST TRADEFIN LTD.", code: "" },
    { name: "EVERBLUE TRAVIN PVT. LTD.", code: "" },
    { name: "G U FINANCIAL SERVICES PRIVATE LIMITED.", code: "" },
    { name: "GLOWMORE FINANCE PRIVATE LIMITED", code: "" },
    {
        name: "HILL VIEW HIRE PURCHASE PRIVATE LIMITED (TRANSFERRED FROM KOLKATA RO)",
        code: "",
    },
    { name: "IMFA ALLOYS FINLEASE LIMITED", code: "" },
    { name: "MADHUMALTI DISTRIBUTORS PRIVATE LIMITED", code: "" },
    { name: "ORICRED FINSERV PRIVATE LIMITED", code: "" },
    { name: "PRIME CAPITAL MARKET LIMITED", code: "" },
    { name: "RAJPATH FINANCIAL SERVICES PRIVATE LTD.", code: "" },
    { name: "SAHEBRAMKA PROJECTS LTD.", code: "" },
    { name: "SHREE LEASING & FINANCE LTD.", code: "" },
    { name: "TETRON CAPITAL LIMITED", code: "" },
    { name: "VECTOR FINANCE PRIVATE LIMITED", code: "" },
    { name: "VEDVYAS FINANCE PRIVATE LTD.", code: "" },
    {
        name: "AASHRIYA CAPITAL & FINANCIAL SERVICES PRIVATE LIMITED",
        code: "",
    },
    { name: "AGILE FINSERV PRIVATE LIMITED", code: "" },
    { name: "AKAL HIRE PURCHASE LIMITED", code: "" },
    { name: "AMBIUM FINSERVE PRIVATE LIMITED", code: "" },
    { name: "ANADI INVESTMENTS PVT LTD", code: "" },
    { name: "ART FINANCE (INDIA) PRIVATE LIMITED", code: "" },
    { name: "ASHISH FINVEST PRIVATE LIMITED", code: "" },
    { name: "AVANCER FINLEASE INDIA LIMITED", code: "" },
    { name: "BAGRRYS HOLDING LIMITED", code: "" },
    { name: "BAJWA ADVANCES PVT LTD", code: "" },
    { name: "BALRAJ HIRE PURCHASE PVT LTD", code: "" },
    { name: "BAROTA FINANCE LIMITED", code: "" },
    { name: "BASEL INVESTMENTS LIMITED", code: "" },
    { name: "BHAGYODAY INVESTMENTS PVT LTD", code: "" },
    { name: "BHATIA HIRE PURCHASE PVT LTD", code: "" },
    { name: "BSN CAPITAL SERVICES PVT LTD", code: "" },
    { name: "BSN FINVEST PVT LTD", code: "" },
    { name: "CHAAR CHINAR DEPOSITS AND HIRE PURCHASE LIMITED", code: "" },
    { name: "CHATHA FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "CHATHA FINANCIERS PRIVATE LIMITED", code: "" },
    { name: "CHOPRA HIRE PURCHASE PVT LTD", code: "" },
    { name: "CITYGOLD CREDIT CAPITAL LIMITED", code: "" },
    { name: "CLASSIC GLOBAL FINANCE AND CAPITAL LTD", code: "" },
    { name: "CONSOLIDATED CAPITAL SERVICES LIMITED", code: "" },
    { name: "CORPORATE TRUST PRIVATE LIMITED", code: "" },
    { name: "CREATIVE INTRA LIMITED", code: "" },
    { name: "DAYANAND MUNJAL INVESTMENTS PVT LTD", code: "" },
    { name: "DECIMUS FINANCIAL LIMITED", code: "" },
    { name: "DEE DEE SECURITIES PRIVATE LIMITED", code: "" },
    { name: "DEEP FINANCE LTD", code: "" },
    { name: "DEEP FINVEST LIMITED", code: "" },
    { name: "DEEPAK DEPOSIT AND ADVANCES LTD", code: "" },
    { name: "DEVAKAR INVESTMENT AND TRADING COMPANY PVT LTD", code: "" },
    { name: "DHANSAMRIDHI FINANCE PRIVATE LIMITED", code: "" },
    { name: "DOABA FINSERV PRIVATE LIMITED", code: "" },
    { name: "DPGC FINANCE LIMITED", code: "" },
    {
        name: "EMERALD LEASING FINANCE AND INVESTMENT COMPANY LIMITED",
        code: "",
    },
    { name: "EX SERVICEMEN HIRE PURCHASE FINANCE PRIVATE LIMITED", code: "" },
    { name: "FAIRDEAL GENERAL FINANCE CO LIMITED", code: "" },
    { name: "FINDOC FINVEST PRIVATE LIMITED", code: "" },
    { name: "FINVASIA FINANCIAL SERVICES PRIVATE LIMITED", code: "" },
    { name: "FLAMINGO FINANCE AND INVESTMENT CO LTD", code: "" },
    { name: "FOLSOM FINANCE PRIVATE LIMITED", code: "" },
    { name: "GALLEY FINANCIERS AND PROPERTY CONSULTANTS PVT LTD", code: "" },
    { name: "GANG-JONG DEVELOPMENT FINANCE PRIVATE LIMITED", code: "" },
    { name: "GILL AND VIRK FINANCE COMPANY LIMITED", code: "" },
    { name: "GLOSEC FINANCE PRIVATE LIMITED", code: "" },
    { name: "GOLF LINK FINANCE AND RESORTS PRIVATE LIMITED", code: "" },
    { name: "GOODLUCK ADVANCES AND FINLEASE LIMITED", code: "" },
    { name: "GURDASPUR FINANCE & LEASING PRIVATE LIMITED", code: "" },
    { name: "H S B LEASING LTD", code: "" },
    { name: "HANUMANT KRIPA EASY FINANCE PRIVATE LIMITED", code: "" },
    { name: "HARGOBIND SAHIB HIRE PURCHASE PRIVATE LIMITED", code: "" },
    { name: "HIMALYAN FINANCIAL SERVICES LIMITED", code: "" },
    { name: "HIMANI LEASING COMPANY LTD", code: "" },
    { name: "HRD FINCAP LIMITED", code: "" },
    { name: "IMPACT LEASING PRIVATE LIMITED", code: "" },
    { name: "J R FINANCE LIMITED", code: "" },
    { name: "JALANDHAR CARGO ADVANCES LIMITED", code: "" },
    { name: "JARYAL MOTOR FINANCE COMPANY LIMITED", code: "" },
    { name: "JASHAN FINLEASE LIMITED", code: "" },
    { name: "JASSI FINANCE PRIVATE LIMITED", code: "" },
    { name: "JASSI HIRE PURCHASE LIMITED", code: "" },
    { name: "JIWANJYOTI DEPOSITS AND ADVANCES LIMITED", code: "" },
    { name: "KAMAL FINSERVE PRIVATE LIMITED", code: "" },
    { name: "KAY KAY HIRE PURCHASE PRIVATE LIMITED", code: "" },
    { name: "KINETIC TRUST LIMITED", code: "" },
    { name: "KING LEASING AND REAL ESTATE LIMITED", code: "" },
    { name: "KISSAN FINVEST LIMITED", code: "" },
    { name: "KOVALAM INVESTMENT AND TRADING COMPANY LIMITED", code: "" },
    { name: "KSR CAPITAL SERVICES LTD", code: "" },
    { name: "LIBRA AUTO AND GENERAL FINANCE LIMITED", code: "" },
    { name: "LOTUS CAPITAL SERVICES PVT LTD", code: "" },
    { name: "M R FINLEASE LIMITED", code: "" },
    {
        name: "MAHAKALI CAPITAL INVESTMENTS SERVICES PRIVATE LIMITED",
        code: "",
    },
    { name: "MAKRO LEASE PRIVATE LIMITED", code: "" },
    { name: "MAND FINANCE LIMITED", code: "" },
    { name: "MANHAR FINANCE PRIVATE LIMITED", code: "" },
    { name: "MANSAROVER HOLDINGS LIMITED", code: "" },
    { name: "MARBLE FINVEST LIMITED", code: "" },
    { name: "MARSH CAPITAL SERVICES PRIVATE LIMITED", code: "" },
];
export default BANK_NAMES;
