import React, { ComponentClass, useState } from "react";
import ShortFormContext from "./context";

const getNewObject = (
    currentObj: {
        [key: string]: any;
    },
    updateObj: {
        [key: string]: any;
    }
) => {
    const newObj = { ...currentObj };
    const keys = Object.keys(updateObj);
    keys.forEach((key) => {
        if (typeof updateObj[key] === "object") {
            newObj[key] = getNewObject(currentObj[key], updateObj[key]);
        } else {
            newObj[key] = updateObj[key];
        }
    });
    return newObj;
};

const defaultValue = {
    requirementForm: {
        type: "",
        email: "",
        value: "",
        loanValue: "",
        fname: "",
        lname: "",
        company: "",
        phone: "",
    },
    entityForm: {
        type: "",
        liability: "",
        incorporation: "",
        entity: "",
    },
    experienceForm: {
        type: "",
        establishmentYear: "",
        experience: "",
        yearOfExperience: "",
    },
    loanForm: {
        outstanding: "",
        status: "",
    },
};

export default (Component: ComponentClass | (() => JSX.Element)) => (props: any) => {
    // TODO: Fix any type
    const [form, setForm] = useState<any>(defaultValue);
    const [otherElement, setOtherElement] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevState: any) => ({
            ...prevState,
            [e.target.name]: !form[e.target.name],
        }));
    };

    const handleNestedFieldChange = (updateObj: { [key: string]: any }) => {
        setForm((prevState: any) => {
            return getNewObject(prevState, updateObj);
        });
    };
    return (
        <ShortFormContext.Provider
            value={{
                form,
                setForm,
                handleChange,
                otherElement,
                setOtherElement,
                handleCheckBox,
                handleNestedFieldChange,
            }}
        >
            <Component {...props} />
        </ShortFormContext.Provider>
    );
};
