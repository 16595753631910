import userKeys from "./user";
import financialKeys from "./financial";
import ELTProcessKeys from "./eltProcess";
import externalKeys from "./external";
import formKeys from "./common";

const KEYS = {
    ...externalKeys,
    ...userKeys,
    ...financialKeys,
    ...formKeys,
    ...ELTProcessKeys,
};

export default KEYS;

export * from "./user";
export * from "./external";
export * from "./financial";
export * from "./common";
export * from "./external";
export * from "./eltProcess";
