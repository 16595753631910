import { Autocomplete, Grid, TextField, Typography, createFilterOptions } from "@mui/material";
import generateTestId from "utils/generateTestId";

type Props = {
    label: string;
    className?: string;
    suggestions: string[];
    onChange: (data: string | string[]) => void;
    value?: string | string[];
    placeholder?: string;
    "data-testid"?: string;
    name?: string;
    multiple?: boolean;
    creatable?: boolean;
    readOnly?: boolean;
};

const CommonAutoComplete = ({
    className,
    onChange,
    suggestions,
    value,
    label,
    placeholder,
    name,
    multiple,
    creatable,
    "data-testid": dataTestId,
    readOnly,
}: Props) => {
    const filter = createFilterOptions<string>();
    if (!dataTestId && label) dataTestId = generateTestId(label, "field");

    return (
        <Grid item xs={12} alignItems="center" container style={{ marginTop: "1rem" }}>
            <Grid item xs={6}>
                <Typography className="common-label" data-testid={`${dataTestId}-label`}>
                    {label}
                </Typography>
            </Grid>
            <Grid item xs={6}>
                <Autocomplete
                    multiple={multiple}
                    disablePortal
                    className="col-span-2"
                    readOnly={readOnly}
                    disabled={readOnly}
                    data-testid={dataTestId}
                    value={multiple ? (value as string[]) : (value as string)}
                    size="small"
                    onChange={(e, newValue) => {
                        onChange(newValue!);
                    }}
                    filterOptions={(options, params) => {
                        const filtered = filter(options, params);

                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option) => inputValue === option);
                        if (inputValue !== "" && !isExisting && creatable) {
                            filtered.push(inputValue);
                        }

                        return filtered;
                    }}
                    options={[...new Set(suggestions)]}
                    freeSolo={creatable}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    isOptionEqualToValue={(option, value) => {
                        if (option === "other" && !suggestions.includes(value)) return true;
                        else return option === value;
                    }}
                    sx={{
                        "& .MuiOutlinedInput-root": {
                            borderRadius: 2,
                        },
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            inputProps={{
                                ...params.inputProps,
                            }}
                            className="autocomplete-input"
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
};

export default CommonAutoComplete;
