import { Fragment, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useGetAllCompanies, useGetCompanyInfo } from "__generated__/apiComponents";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { useCompanyNames, useError, useUploadFile } from "hooks";
import DeselectFileModal from "pages/Dashboard/components/Document/components/DeselectFileModal";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const EmployeeIncome = ({
    prefix,
    entityType,
    entityIndex,
    activeFields,
    pane,
}: {
    prefix: string;
    entityType: string;
    entityIndex: number;
    activeFields: string[];
    pane?: string;
}) => {
    const showError = useError();
    const employeeIncome = useWatch({ name: `${prefix}.employmentIncome` });
    const [show, setShow] = useState(false);
    const [companyFound, setCompanyFound] = useState(false);
    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });
    const handleUploadFile = useUploadFile({
        name: "employmentIncome",
        fileAddress: `${prefix}.employmentIncome.certificateOfIncorporation`,
    });

    const handleCloseFile = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };
    const { register, setValue } = useFormContext();
    const { companyNames, isCompaniesLoading, data, setCompanyName, setCompanyType } = useCompanyNames();
    const { mutate: getCompanyInfo } = useGetCompanyInfo({
        onError: showError,
        onSuccess: (data: any) => {
            if (data) {
                setShow(true);
                let incorporationType = data?.class.toLowerCase().includes("private") ? "Private" : "Public";
                let date = new Date(data?.["date_of_registration"] * 1000).toISOString();
                setValue(`${prefix}.employmentIncome.companyName`, data?.company_name);
                setValue(`${prefix}.employmentIncome.startOfEmployment`, date);
                setValue(`${prefix}.employmentIncome.incorporationType`, incorporationType);
            }
        },
    });

    const handleSelectCompany = (value: string) => {
        getCompanyInfo({
            body: {
                cin: value,
            },
        });
    };
    useEffect(() => {
        //@ts-ignore
        if (data?.length === 0) {
            setCompanyFound(false);
            setValue(`${prefix}.employmentIncome.startOfEmployment`, "");
            setValue(`${prefix}.employmentIncome.incorporationType`, "");
            setShow(true);
        } else {
            setCompanyFound(true);
        }
    }, [data]);

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center" data-testid="employee-income">
            {fixture.map(({ name, inputType, reactHookFormRegisterOptions, editable, options, subtitle, label, dataTestId }, index) => {
                if (employeeIncome.sector !== "Private") {
                    if (name === "businessConstitution") return "";
                }
                if (employeeIncome.businessConstitution !== "Partnership") {
                    if (name === "liabilityType" || name === "noOfPartners") {
                        return "";
                    }
                }
                if (employeeIncome.businessConstitution !== "Registered Under Company Act") {
                    if (name === "incorporationType" || name === "noOfDirectors") {
                        return "";
                    }
                }
                if (employeeIncome.businessConstitution !== OTHER) {
                    if (name === "otherBusinessConstitution") return "";
                }
                if (name === "incorporationType") {
                    if (employeeIncome.sector !== "Private") return "";
                    if (employeeIncome.businessConstitution !== "Registered Under Company Act") return "";
                }
                if (employeeIncome.sector === "Private" && employeeIncome.businessConstitution === "Partnership") {
                    if (name === "liabilityType" || name === "noOfPartners") return "";
                }
                if (employeeIncome.sector === "Private" && employeeIncome.businessConstitution === "Registered Under Company Act") {
                    if (name === "noOfDirectors") return "";
                }
                if (!show && employeeIncome.businessConstitution === "Registered Under Company Act") {
                    if (name === "incorporationType" || name === "startOfEmployment") return "";
                }
                if (name === "companyName" && employeeIncome.businessConstitution !== "Registered Under Company Act") {
                    return "";
                }
                if (employeeIncome.sector === "Private" && employeeIncome.businessConstitution === "Registered Under Company Act") {
                    if (name === "employerName") {
                        return "";
                    }
                }
                if (employeeIncome.sector === "Private") {
                    if (employeeIncome.businessConstitution === "Sole Proprietor" || employeeIncome.businessConstitution === "Partnership") {
                        if (name === "designation" || name === "otherDesignation") {
                            return "";
                        }
                    }
                }
                if (employeeIncome.sector !== "Private") {
                    if (name === "otherBusinessConstitution") {
                        return "";
                    }
                }
                if (name === "companyName" && employeeIncome.businessConstitution === "Registered Under Company Act") {
                    activeFields.push(name);
                    return (
                        <Fragment key={index}>
                            <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                                <Grid item xs={6}>
                                    <Typography className="common-label" data-testid={generateTestId(`company-name-employee-${pane}`, "field-label")}>
                                        {label}
                                    </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                    <Autocomplete
                                        freeSolo
                                        disablePortal
                                        className="common__mui__textfield"
                                        data-testid={generateTestId(`company-name-employee-${pane}`, "field")}
                                        options={companyNames}
                                        value={employeeIncome?.companyName}
                                        disabled={localStorage.getItem("disable_form") === "true"}
                                        onInputChange={(event, value) => {
                                            setCompanyName(value);
                                            if (value.length === 0) {
                                                setValue(`${prefix}.employmentIncome.dateOfEstablishment`, "");
                                                setValue(`${prefix}.employmentIncome.incorporationType`, "");
                                                setShow(false);
                                            }
                                        }}
                                        onChange={(e, value) => {
                                            if (value?.id) {
                                                handleSelectCompany(value.id);
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                disabled={localStorage.getItem("disable_form") === "true"}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // startAdornment: isLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                                }}
                                                className="autocomplete-input"
                                                {...register(`${prefix}.employmentIncome.${name}`)}
                                            />
                                        )}
                                    />
                                    <FormHelperText className={"common__helper-text"}>
                                        <Grid container direction="row" justifyContent="center" alignItems="center">
                                            <Grid item xs={true}>
                                                <span>{subtitle}</span>
                                            </Grid>
                                        </Grid>
                                    </FormHelperText>
                                </Grid>
                            </Grid>
                        </Fragment>
                    );
                }
                activeFields.push(name);
                return (
                    <Fragment key={index}>
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.employmentIncome.${name}`}
                            options={
                                options?.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                })) || []
                            }
                            multiLine={name === "employerName"}
                            data-testid={generateTestId(`${dataTestId || label}-employee-${pane}`, "field")}
                            label={label}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            readOnly={
                                localStorage.getItem("disable_form") === "true" ||
                                (name === "incorporationType" || name === "startOfEmployment"
                                    ? employeeIncome.business === "Registered Under Company Act"
                                        ? companyFound
                                        : false
                                    : !editable)
                            }
                            subtitle={subtitle}
                            ignoreOthers={name === "businessConstitution"}
                        />
                    </Fragment>
                );
            })}
            {!companyFound && show && (
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Certificate of Incorporation"
                    name={`${prefix}.employmentIncome.certificateOfIncorporation`}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    handleChange={handleUploadFile}
                    data-testid={generateTestId(`certificate-of-incorporation-employee-${pane}`, "field")}
                    showCross={employeeIncome?.certificateOfIncorporation?.length > 0}
                    handleFileModalOpen={() =>
                        setCurrentInput({ name: `${prefix}.employmentIncome.certificateOfIncorporation`, open: true, multiple: false, position: 0 })
                    }
                />
            )}
            <DeselectFileModal handleClose={handleCloseFile} currentInput={currentInput} />
        </Grid>
    );
};

export default EmployeeIncome;
