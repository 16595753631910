import React, { Fragment } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Grid from "@mui/material/Grid";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import "./CriteriaView.styles.scss";

function CriteriaView() {
    const rows = [
        {
            sr_no: 1,
            segment: "Retail Contractor",
            variable: "Years in Business",
            actual_value: "15",
            criteria: "> = 3 Years",
            criteria_met: "YES",
        },
        {
            sr_no: 2,
            segment: "Retail Contractor",
            variable: "CRV of Owned Equipments",
            actual_value: "2,000,000",
            criteria: ">=Rs 25 lacs",
            criteria_met: "NO",
        },
        {
            sr_no: 3,
            segment: "Retail Contractor",
            variable: "Stay at the Same City",
            actual_value: "10",
            criteria: "For at least 2 years",
            criteria_met: "YES",
        },
        {
            sr_no: 4,
            segment: "Retail Contractor",
            variable: "Gross Contract Order Book",
            actual_value: "7,000,000",
            criteria: ">= Rs 50 lacs",
            criteria_met: "YES",
        },
        {
            sr_no: 5,
            segment: "Retail Contractor",
            variable: "	Gross Turnover for last financial year",
            actual_value: "20,000,000",
            criteria: ">=Rs 100 lacs",
            criteria_met: "YES",
        },
        {
            sr_no: 6,
            segment: "Retail Contractor",
            variable: "Cash Profit",
            actual_value: "900,000",
            criteria: "Positive for Last 2 years",
            criteria_met: "YES",
        },
        {
            sr_no: 7,
            segment: "Retail Contractor",
            variable: "Gross Contract Order Book",
            actual_value: "7,000,000",
            criteria: "	>= Rs. 100 lacs",
            criteria_met: "NO",
        },
    ];
    return (
        <Fragment>
            <Navbar />
            <TableContainer className="table__container">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Sr No</TableCell>
                            <TableCell>Segment</TableCell>
                            <TableCell>Variable</TableCell>
                            <TableCell>Actual Value</TableCell>
                            <TableCell>Criteria</TableCell>
                            <TableCell>Criteria Met</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.sr_no}>
                                <TableCell>{row.sr_no}</TableCell>
                                <TableCell>{row.segment}</TableCell>
                                <TableCell>{row.variable}</TableCell>
                                <TableCell>{row.actual_value}</TableCell>
                                <TableCell>{row.criteria}</TableCell>
                                <TableCell>{row.criteria_met}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Footer />
        </Fragment>
    );
}

export default CriteriaView;
