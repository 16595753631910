import { useEffect } from "react";
import { useSignOut } from "react-auth-kit";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { withAuthUserProvider } from "contexts/session";
import jwtDecode from "jwt-decode";
import AdminView from "pages/AdminView";
import Contact from "pages/ContactUs";
import Dashboard from "pages/Dashboard";
import HomePage from "pages/HomePage";
import Privacy from "pages/PrivacyPolicy/PrivacyPolicy";
import Profile from "pages/Profile";
import ResetEmail from "pages/ResetEmail";
import ResetPassword from "pages/ResetPassword";
import ShortForm from "pages/ShortForm";
import TermsAndConditions from "pages/Terms&conditions/Terms&Conditions";
import ROUTES from "./globalConstants/routes";
import useFeature from "./hooks/useFeature";
import CriteriaView from "./pages/CriteriaView";
import ProspectiveForm from "./pages/Dashboard/ProspectiveForm";
import GoogleLogin from "./pages/GoogleLogin";
import Home from "./pages/Home";
import Login from "./pages/Login";
import RedirectedLogin from "./pages/RedirectedLogin/RedirectedLogin";
import Register from "./pages/Register/Register";

const MainRouter = () => {
    const shouldShowProfilePage = useFeature("profile-page");
    const signOut = useSignOut();
    useEffect(() => {
        const refreshToken = localStorage.getItem("authToken_refresh");
        if (refreshToken) {
            // @ts-ignore
            const decoded: any = jwtDecode(refreshToken);
            if (decoded.exp < Date.now() / 1000) {
                signOut();
            }
        }
    }, []);

    return (
        <Router {...(process.env.REACT_APP_BASE_URI ? { basename: process.env.REACT_APP_BASE_URI } : {})}>
            <Routes>
                <Route path={ROUTES.HOME} element={<Home />} />
                <Route path={ROUTES.DASHBOARD} element={<Navigate to={ROUTES.HOME_PAGE} />} />

                <Route path={ROUTES.HOME_PAGE} element={<HomePage />} />
                <Route path={ROUTES.ADMIN_VIEW} element={<AdminView />} />
                <Route path={ROUTES.LOGIN} element={<Login />} />
                <Route path={ROUTES.SHORTFORM} element={<ShortForm />} />
                <Route path={ROUTES.REGISTER} element={<Register />} />
                <Route path={ROUTES.CONTACT_US} element={<Contact />} />
                <Route path={ROUTES.PRIVACY_POLICY} element={<Privacy />} />
                <Route path={ROUTES.TERMS_AND_CONDITIONS} element={<TermsAndConditions />} />
                <Route path={ROUTES.CRITERIA_VIEW} element={<CriteriaView />} />
                <Route path={ROUTES.DASHBOARD_ID} element={<Dashboard />} />
                <Route path={ROUTES.PROSPECTIVE_FORM} element={<ProspectiveForm />} />
                <Route path={ROUTES.VIEW_APPLICATION_ID} element={<Dashboard />} />
                <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
                <Route path={ROUTES.RESET_EMAIL} element={<ResetEmail />} />
                <Route path={ROUTES.GOOGLE_AUTH_CALLBACK} element={<GoogleLogin />} />
                <Route path={ROUTES.REDIRECTED_LOGIN} element={<RedirectedLogin />} />
                {shouldShowProfilePage && <Route path={ROUTES.PROFILE} element={<Profile />} />}
                <Route path="/*" element={<Navigate to={ROUTES.HOME} />} />
            </Routes>
        </Router>
    );
};

export default withAuthUserProvider(MainRouter);
