/**
 * Generated by @openapi-codegen
 *
 * @version 0.0.0
 */
import * as reactQuery from "@tanstack/react-query";
import { useApiContext, ApiContext } from "./apiContext";
import type * as Fetcher from "./apiFetcher";
import { apiFetch } from "./apiFetcher";
import type * as Schemas from "./apiSchemas";

export type CreateBackOfficeLoanError = Fetcher.ErrorWrapper<undefined>;

export type CreateBackOfficeLoanRequestBody = {
    loanApplicationId: string;
};

export type CreateBackOfficeLoanVariables = {
    body: CreateBackOfficeLoanRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchCreateBackOfficeLoan = (variables: CreateBackOfficeLoanVariables, signal?: AbortSignal) =>
    apiFetch<void, CreateBackOfficeLoanError, CreateBackOfficeLoanRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans",
        method: "post",
        ...variables,
        signal,
    });

export const useCreateBackOfficeLoan = (
    options?: Omit<reactQuery.UseMutationOptions<void, CreateBackOfficeLoanError, CreateBackOfficeLoanVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CreateBackOfficeLoanError, CreateBackOfficeLoanVariables>(
        (variables: CreateBackOfficeLoanVariables) => fetchCreateBackOfficeLoan({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetAllBackOfficeLoansError = Fetcher.ErrorWrapper<undefined>;

export type GetAllBackOfficeLoansVariables = ApiContext["fetcherOptions"];

export const fetchGetAllBackOfficeLoans = (variables: GetAllBackOfficeLoansVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllBackOfficeLoansError, undefined, {}, {}, {}>({ url: "/backOfficeLoans", method: "get", ...variables, signal });

export const useGetAllBackOfficeLoans = <TData = void>(
    variables: GetAllBackOfficeLoansVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllBackOfficeLoansError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllBackOfficeLoansError, TData>(
        queryKeyFn({ path: "/backOfficeLoans", operationId: "getAllBackOfficeLoans", variables }),
        ({ signal }) => fetchGetAllBackOfficeLoans({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllLoanApplicationIssuesQueryParams = {
    loanApplicationId: string;
};

export type GetAllLoanApplicationIssuesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLoanApplicationIssuesVariables = {
    queryParams: GetAllLoanApplicationIssuesQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetAllLoanApplicationIssues = (variables: GetAllLoanApplicationIssuesVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllLoanApplicationIssuesError, undefined, {}, GetAllLoanApplicationIssuesQueryParams, {}>({
        url: "/backOfficeLoans/issues",
        method: "get",
        ...variables,
        signal,
    });

export const useGetAllLoanApplicationIssues = <TData = void>(
    variables: GetAllLoanApplicationIssuesVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllLoanApplicationIssuesError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllLoanApplicationIssuesError, TData>(
        queryKeyFn({ path: "/backOfficeLoans/issues", operationId: "getAllLoanApplicationIssues", variables }),
        ({ signal }) => fetchGetAllLoanApplicationIssues({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllWorkOrdersByLoanIdQueryParams = {
    loanApplicationId: string;
};

export type GetAllWorkOrdersByLoanIdError = Fetcher.ErrorWrapper<undefined>;

export type GetAllWorkOrdersByLoanIdVariables = {
    queryParams: GetAllWorkOrdersByLoanIdQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetAllWorkOrdersByLoanId = (variables: GetAllWorkOrdersByLoanIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllWorkOrdersByLoanIdError, undefined, {}, GetAllWorkOrdersByLoanIdQueryParams, {}>({
        url: "/backOfficeLoans/workOrders/loanId",
        method: "get",
        ...variables,
        signal,
    });

export const useGetAllWorkOrdersByLoanId = <TData = void>(
    variables: GetAllWorkOrdersByLoanIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllWorkOrdersByLoanIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllWorkOrdersByLoanIdError, TData>(
        queryKeyFn({ path: "/backOfficeLoans/workOrders/loanId", operationId: "getAllWorkOrdersByLoanId", variables }),
        ({ signal }) => fetchGetAllWorkOrdersByLoanId({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllWorkOrdersByContractorQueryParams = {
    contractorName: string;
};

export type GetAllWorkOrdersByContractorError = Fetcher.ErrorWrapper<undefined>;

export type GetAllWorkOrdersByContractorVariables = {
    queryParams: GetAllWorkOrdersByContractorQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetAllWorkOrdersByContractor = (variables: GetAllWorkOrdersByContractorVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllWorkOrdersByContractorError, undefined, {}, GetAllWorkOrdersByContractorQueryParams, {}>({
        url: "/backOfficeLoans/workOrders/contractorId",
        method: "get",
        ...variables,
        signal,
    });

export const useGetAllWorkOrdersByContractor = <TData = void>(
    variables: GetAllWorkOrdersByContractorVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllWorkOrdersByContractorError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllWorkOrdersByContractorError, TData>(
        queryKeyFn({ path: "/backOfficeLoans/workOrders/contractorId", operationId: "getAllWorkOrdersByContractor", variables }),
        ({ signal }) => fetchGetAllWorkOrdersByContractor({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetWorkOrderByIdQueryParams = {
    id: string;
};

export type GetWorkOrderByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetWorkOrderByIdVariables = {
    queryParams: GetWorkOrderByIdQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetWorkOrderById = (variables: GetWorkOrderByIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetWorkOrderByIdError, undefined, {}, GetWorkOrderByIdQueryParams, {}>({
        url: "/backOfficeLoans/workOrders/id",
        method: "get",
        ...variables,
        signal,
    });

export const useGetWorkOrderById = <TData = void>(
    variables: GetWorkOrderByIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetWorkOrderByIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetWorkOrderByIdError, TData>(
        queryKeyFn({ path: "/backOfficeLoans/workOrders/id", operationId: "getWorkOrderById", variables }),
        ({ signal }) => fetchGetWorkOrderById({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetConsolidatedExcelQueryParams = {
    loanApplicationId: string;
};

export type GetConsolidatedExcelError = Fetcher.ErrorWrapper<undefined>;

export type GetConsolidatedExcelVariables = {
    queryParams: GetConsolidatedExcelQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetConsolidatedExcel = (variables: GetConsolidatedExcelVariables, signal?: AbortSignal) =>
    apiFetch<void, GetConsolidatedExcelError, undefined, {}, GetConsolidatedExcelQueryParams, {}>({
        url: "/backOfficeLoans/getConsolidatedExcel",
        method: "get",
        ...variables,
        signal,
    });

export const useGetConsolidatedExcel = <TData = void>(
    variables: GetConsolidatedExcelVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetConsolidatedExcelError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetConsolidatedExcelError, TData>(
        queryKeyFn({ path: "/backOfficeLoans/getConsolidatedExcel", operationId: "getConsolidatedExcel", variables }),
        ({ signal }) => fetchGetConsolidatedExcel({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type FilterAndSortBackOfficeLoansError = Fetcher.ErrorWrapper<undefined>;

export type FilterAndSortBackOfficeLoansRequestBody = {
    filters: void;
};

export type FilterAndSortBackOfficeLoansVariables = {
    body: FilterAndSortBackOfficeLoansRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchFilterAndSortBackOfficeLoans = (variables: FilterAndSortBackOfficeLoansVariables, signal?: AbortSignal) =>
    apiFetch<void, FilterAndSortBackOfficeLoansError, FilterAndSortBackOfficeLoansRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/filter",
        method: "post",
        ...variables,
        signal,
    });

export const useFilterAndSortBackOfficeLoans = (
    options?: Omit<reactQuery.UseMutationOptions<void, FilterAndSortBackOfficeLoansError, FilterAndSortBackOfficeLoansVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, FilterAndSortBackOfficeLoansError, FilterAndSortBackOfficeLoansVariables>(
        (variables: FilterAndSortBackOfficeLoansVariables) => fetchFilterAndSortBackOfficeLoans({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ResolveLoanApplicationIssuesError = Fetcher.ErrorWrapper<undefined>;

export type ResolveLoanApplicationIssuesRequestBody = {
    status: Schemas.LoanApplicationIssueStatus;
    comments: Schemas.LoanApplicationIssueComments[];
    resolution: string;
    issueId: string;
};

export type ResolveLoanApplicationIssuesVariables = {
    body: ResolveLoanApplicationIssuesRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchResolveLoanApplicationIssues = (variables: ResolveLoanApplicationIssuesVariables, signal?: AbortSignal) =>
    apiFetch<void, ResolveLoanApplicationIssuesError, ResolveLoanApplicationIssuesRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/issues/resolve",
        method: "post",
        ...variables,
        signal,
    });

export const useResolveLoanApplicationIssues = (
    options?: Omit<reactQuery.UseMutationOptions<void, ResolveLoanApplicationIssuesError, ResolveLoanApplicationIssuesVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ResolveLoanApplicationIssuesError, ResolveLoanApplicationIssuesVariables>(
        (variables: ResolveLoanApplicationIssuesVariables) => fetchResolveLoanApplicationIssues({ ...fetcherOptions, ...variables }),
        options
    );
};

export type AddCommentToLoanApplicationIssueError = Fetcher.ErrorWrapper<undefined>;

export type AddCommentToLoanApplicationIssueRequestBody = {
    comment: string;
    issueId: string;
};

export type AddCommentToLoanApplicationIssueVariables = {
    body: AddCommentToLoanApplicationIssueRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchAddCommentToLoanApplicationIssue = (variables: AddCommentToLoanApplicationIssueVariables, signal?: AbortSignal) =>
    apiFetch<void, AddCommentToLoanApplicationIssueError, AddCommentToLoanApplicationIssueRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/issues/addComment",
        method: "post",
        ...variables,
        signal,
    });

export const useAddCommentToLoanApplicationIssue = (
    options?: Omit<
        reactQuery.UseMutationOptions<void, AddCommentToLoanApplicationIssueError, AddCommentToLoanApplicationIssueVariables>,
        "mutationFn"
    >
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, AddCommentToLoanApplicationIssueError, AddCommentToLoanApplicationIssueVariables>(
        (variables: AddCommentToLoanApplicationIssueVariables) => fetchAddCommentToLoanApplicationIssue({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetFilteredWorkOrdersError = Fetcher.ErrorWrapper<undefined>;

export type GetFilteredWorkOrdersRequestBody = {
    filters?: void;
    loanId?: string;
};

export type GetFilteredWorkOrdersVariables = {
    body?: GetFilteredWorkOrdersRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGetFilteredWorkOrders = (variables: GetFilteredWorkOrdersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetFilteredWorkOrdersError, GetFilteredWorkOrdersRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/workOrders/filter",
        method: "post",
        ...variables,
        signal,
    });

export const useGetFilteredWorkOrders = (
    options?: Omit<reactQuery.UseMutationOptions<void, GetFilteredWorkOrdersError, GetFilteredWorkOrdersVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GetFilteredWorkOrdersError, GetFilteredWorkOrdersVariables>(
        (variables: GetFilteredWorkOrdersVariables) => fetchGetFilteredWorkOrders({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateWorkOrderError = Fetcher.ErrorWrapper<undefined>;

export type UpdateWorkOrderRequestBody = Schemas.PrismaLoanApplicationWorkOrderCreateInput & {
    contractor_id?: string;
    entity: string;
};

export type UpdateWorkOrderVariables = {
    body?: UpdateWorkOrderRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchUpdateWorkOrder = (variables: UpdateWorkOrderVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateWorkOrderError, UpdateWorkOrderRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/workOrders/update",
        method: "post",
        ...variables,
        signal,
    });

export const useUpdateWorkOrder = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateWorkOrderError, UpdateWorkOrderVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateWorkOrderError, UpdateWorkOrderVariables>(
        (variables: UpdateWorkOrderVariables) => fetchUpdateWorkOrder({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateBackOfficeLoanPathParams = {
    id: string;
};

export type UpdateBackOfficeLoanError = Fetcher.ErrorWrapper<undefined>;

export type UpdateBackOfficeLoanVariables = {
    body?: Schemas.PrismaBackOfficeLoanUpdateInput;
    pathParams: UpdateBackOfficeLoanPathParams;
} & ApiContext["fetcherOptions"];

export const fetchUpdateBackOfficeLoan = (variables: UpdateBackOfficeLoanVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateBackOfficeLoanError, Schemas.PrismaBackOfficeLoanUpdateInput, {}, {}, UpdateBackOfficeLoanPathParams>({
        url: "/backOfficeLoans/{id}",
        method: "put",
        ...variables,
        signal,
    });

export const useUpdateBackOfficeLoan = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateBackOfficeLoanError, UpdateBackOfficeLoanVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateBackOfficeLoanError, UpdateBackOfficeLoanVariables>(
        (variables: UpdateBackOfficeLoanVariables) => fetchUpdateBackOfficeLoan({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteBackOfficeLoanPathParams = {
    id: string;
};

export type DeleteBackOfficeLoanError = Fetcher.ErrorWrapper<undefined>;

export type DeleteBackOfficeLoanVariables = {
    pathParams: DeleteBackOfficeLoanPathParams;
} & ApiContext["fetcherOptions"];

export const fetchDeleteBackOfficeLoan = (variables: DeleteBackOfficeLoanVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteBackOfficeLoanError, undefined, {}, {}, DeleteBackOfficeLoanPathParams>({
        url: "/backOfficeLoans/{id}",
        method: "delete",
        ...variables,
        signal,
    });

export const useDeleteBackOfficeLoan = (
    options?: Omit<reactQuery.UseMutationOptions<void, DeleteBackOfficeLoanError, DeleteBackOfficeLoanVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteBackOfficeLoanError, DeleteBackOfficeLoanVariables>(
        (variables: DeleteBackOfficeLoanVariables) => fetchDeleteBackOfficeLoan({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateLoanApprovalStatusError = Fetcher.ErrorWrapper<undefined>;

export type UpdateLoanApprovalStatusRequestBody = {
    approvalStatus: Schemas.ApprovalStatus;
    loanApplicationId: string;
};

export type UpdateLoanApprovalStatusVariables = {
    body: UpdateLoanApprovalStatusRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchUpdateLoanApprovalStatus = (variables: UpdateLoanApprovalStatusVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateLoanApprovalStatusError, UpdateLoanApprovalStatusRequestBody, {}, {}, {}>({
        url: "/backOfficeLoans/approvalStatus",
        method: "post",
        ...variables,
        signal,
    });

export const useUpdateLoanApprovalStatus = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateLoanApprovalStatusError, UpdateLoanApprovalStatusVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateLoanApprovalStatusError, UpdateLoanApprovalStatusVariables>(
        (variables: UpdateLoanApprovalStatusVariables) => fetchUpdateLoanApprovalStatus({ ...fetcherOptions, ...variables }),
        options
    );
};

export type CreateFiFormError = Fetcher.ErrorWrapper<undefined>;

export type CreateFiFormVariables = {
    body: Schemas.PrismaFiFormCreateInput;
} & ApiContext["fetcherOptions"];

export const fetchCreateFiForm = (variables: CreateFiFormVariables, signal?: AbortSignal) =>
    apiFetch<void, CreateFiFormError, Schemas.PrismaFiFormCreateInput, {}, {}, {}>({ url: "/fiForms", method: "post", ...variables, signal });

export const useCreateFiForm = (options?: Omit<reactQuery.UseMutationOptions<void, CreateFiFormError, CreateFiFormVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CreateFiFormError, CreateFiFormVariables>(
        (variables: CreateFiFormVariables) => fetchCreateFiForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetAllFiFormError = Fetcher.ErrorWrapper<undefined>;

export type GetAllFiFormVariables = ApiContext["fetcherOptions"];

export const fetchGetAllFiForm = (variables: GetAllFiFormVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllFiFormError, undefined, {}, {}, {}>({ url: "/fiForms", method: "get", ...variables, signal });

export const useGetAllFiForm = <TData = void>(
    variables: GetAllFiFormVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllFiFormError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllFiFormError, TData>(
        queryKeyFn({ path: "/fiForms", operationId: "getAllFiForm", variables }),
        ({ signal }) => fetchGetAllFiForm({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetFiFormByIdPathParams = {
    fiFormId: string;
};

export type GetFiFormByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetFiFormByIdVariables = {
    pathParams: GetFiFormByIdPathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetFiFormById = (variables: GetFiFormByIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetFiFormByIdError, undefined, {}, {}, GetFiFormByIdPathParams>({
        url: "/fiForms/{fiFormId}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetFiFormById = <TData = void>(
    variables: GetFiFormByIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetFiFormByIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetFiFormByIdError, TData>(
        queryKeyFn({ path: "/fiForms/{fiFormId}", operationId: "getFiFormById", variables }),
        ({ signal }) => fetchGetFiFormById({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type UpdateFiFormPathParams = {
    fiFormId: string;
};

export type UpdateFiFormError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFiFormVariables = {
    body?: Schemas.PrismaFiFormUpdateInput;
    pathParams: UpdateFiFormPathParams;
} & ApiContext["fetcherOptions"];

export const fetchUpdateFiForm = (variables: UpdateFiFormVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateFiFormError, Schemas.PrismaFiFormUpdateInput, {}, {}, UpdateFiFormPathParams>({
        url: "/fiForms/{fiFormId}",
        method: "put",
        ...variables,
        signal,
    });

export const useUpdateFiForm = (options?: Omit<reactQuery.UseMutationOptions<void, UpdateFiFormError, UpdateFiFormVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateFiFormError, UpdateFiFormVariables>(
        (variables: UpdateFiFormVariables) => fetchUpdateFiForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteFiFormPathParams = {
    fiFormId: string;
};

export type DeleteFiFormError = Fetcher.ErrorWrapper<undefined>;

export type DeleteFiFormVariables = {
    pathParams: DeleteFiFormPathParams;
} & ApiContext["fetcherOptions"];

export const fetchDeleteFiForm = (variables: DeleteFiFormVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteFiFormError, undefined, {}, {}, DeleteFiFormPathParams>({
        url: "/fiForms/{fiFormId}",
        method: "delete",
        ...variables,
        signal,
    });

export const useDeleteFiForm = (options?: Omit<reactQuery.UseMutationOptions<void, DeleteFiFormError, DeleteFiFormVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteFiFormError, DeleteFiFormVariables>(
        (variables: DeleteFiFormVariables) => fetchDeleteFiForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetFiFormByFiNamePathParams = {
    fiName: string;
};

export type GetFiFormByFiNameError = Fetcher.ErrorWrapper<undefined>;

export type GetFiFormByFiNameVariables = {
    pathParams: GetFiFormByFiNamePathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetFiFormByFiName = (variables: GetFiFormByFiNameVariables, signal?: AbortSignal) =>
    apiFetch<void, GetFiFormByFiNameError, undefined, {}, {}, GetFiFormByFiNamePathParams>({
        url: "/fiForms/fi/{fiName}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetFiFormByFiName = <TData = void>(
    variables: GetFiFormByFiNameVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetFiFormByFiNameError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetFiFormByFiNameError, TData>(
        queryKeyFn({ path: "/fiForms/fi/{fiName}", operationId: "getFiFormByFiName", variables }),
        ({ signal }) => fetchGetFiFormByFiName({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllCompaniesError = Fetcher.ErrorWrapper<undefined>;

export type GetAllCompaniesRequestBody = {
    onlyCompanyNames?: boolean;
    filters: void;
    sorting?: {
        [key: string]: Schemas.PrismaSortOrder;
    };
    /**
     * @format double
     */
    pageSize?: number;
    /**
     * @format double
     */
    page?: number;
};

export type GetAllCompaniesVariables = {
    body: GetAllCompaniesRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGetAllCompanies = (variables: GetAllCompaniesVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllCompaniesError, GetAllCompaniesRequestBody, {}, {}, {}>({
        url: "/financial/search/companies",
        method: "post",
        ...variables,
        signal,
    });

export const useGetAllCompanies = (
    options?: Omit<reactQuery.UseMutationOptions<void, GetAllCompaniesError, GetAllCompaniesVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GetAllCompaniesError, GetAllCompaniesVariables>(
        (variables: GetAllCompaniesVariables) => fetchGetAllCompanies({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetCompanyInfoError = Fetcher.ErrorWrapper<undefined>;

export type GetCompanyInfoRequestBody = {
    companyName?: string;
    cin?: string;
};

export type GetCompanyInfoVariables = {
    body?: GetCompanyInfoRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGetCompanyInfo = (variables: GetCompanyInfoVariables, signal?: AbortSignal) =>
    apiFetch<void, GetCompanyInfoError, GetCompanyInfoRequestBody, {}, {}, {}>({
        url: "/financial/companyInfo",
        method: "post",
        ...variables,
        signal,
    });

export const useGetCompanyInfo = (
    options?: Omit<reactQuery.UseMutationOptions<void, GetCompanyInfoError, GetCompanyInfoVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GetCompanyInfoError, GetCompanyInfoVariables>(
        (variables: GetCompanyInfoVariables) => fetchGetCompanyInfo({ ...fetcherOptions, ...variables }),
        options
    );
};

export type FindAllDealersQueryParams = {
    manufacturer?: string;
};

export type FindAllDealersError = Fetcher.ErrorWrapper<undefined>;

export type FindAllDealersVariables = {
    queryParams?: FindAllDealersQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchFindAllDealers = (variables: FindAllDealersVariables, signal?: AbortSignal) =>
    apiFetch<void, FindAllDealersError, undefined, {}, FindAllDealersQueryParams, {}>({
        url: "/financial/dealers",
        method: "get",
        ...variables,
        signal,
    });

export const useFindAllDealers = <TData = void>(
    variables: FindAllDealersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, FindAllDealersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, FindAllDealersError, TData>(
        queryKeyFn({ path: "/financial/dealers", operationId: "findAllDealers", variables }),
        ({ signal }) => fetchFindAllDealers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllManufacturersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllManufacturersVariables = ApiContext["fetcherOptions"];

export const fetchGetAllManufacturers = (variables: GetAllManufacturersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllManufacturersError, undefined, {}, {}, {}>({ url: "/financial/manufacturers", method: "get", ...variables, signal });

export const useGetAllManufacturers = <TData = void>(
    variables: GetAllManufacturersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllManufacturersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllManufacturersError, TData>(
        queryKeyFn({ path: "/financial/manufacturers", operationId: "getAllManufacturers", variables }),
        ({ signal }) => fetchGetAllManufacturers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type VerifyAadhaarError = Fetcher.ErrorWrapper<undefined>;

export type VerifyAadhaarRequestBody = {
    otp?: string;
    loanApplicationId: string;
    clientId?: string;
    submitOtp?: boolean;
    generateOtp?: boolean;
    aadhaar?: string;
};

export type VerifyAadhaarVariables = {
    body: VerifyAadhaarRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchVerifyAadhaar = (variables: VerifyAadhaarVariables, signal?: AbortSignal) =>
    apiFetch<void, VerifyAadhaarError, VerifyAadhaarRequestBody, {}, {}, {}>({
        url: "/financial/verify/aadhaar",
        method: "post",
        ...variables,
        signal,
    });

export const useVerifyAadhaar = (options?: Omit<reactQuery.UseMutationOptions<void, VerifyAadhaarError, VerifyAadhaarVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, VerifyAadhaarError, VerifyAadhaarVariables>(
        (variables: VerifyAadhaarVariables) => fetchVerifyAadhaar({ ...fetcherOptions, ...variables }),
        options
    );
};

export type VerifyPanError = Fetcher.ErrorWrapper<undefined>;

export type VerifyPanRequestBody = {
    loanApplicationId: string;
    pan: string;
};

export type VerifyPanVariables = {
    body: VerifyPanRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchVerifyPan = (variables: VerifyPanVariables, signal?: AbortSignal) =>
    apiFetch<void, VerifyPanError, VerifyPanRequestBody, {}, {}, {}>({ url: "/financial/verify/pan", method: "post", ...variables, signal });

export const useVerifyPan = (options?: Omit<reactQuery.UseMutationOptions<void, VerifyPanError, VerifyPanVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, VerifyPanError, VerifyPanVariables>(
        (variables: VerifyPanVariables) => fetchVerifyPan({ ...fetcherOptions, ...variables }),
        options
    );
};

export type VerifyRCError = Fetcher.ErrorWrapper<undefined>;

export type VerifyRCRequestBody = {
    loanApplicationId: string;
    rc: string;
};

export type VerifyRCVariables = {
    body: VerifyRCRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchVerifyRC = (variables: VerifyRCVariables, signal?: AbortSignal) =>
    apiFetch<void, VerifyRCError, VerifyRCRequestBody, {}, {}, {}>({ url: "/financial/verify/rc", method: "post", ...variables, signal });

export const useVerifyRC = (options?: Omit<reactQuery.UseMutationOptions<void, VerifyRCError, VerifyRCVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, VerifyRCError, VerifyRCVariables>(
        (variables: VerifyRCVariables) => fetchVerifyRC({ ...fetcherOptions, ...variables }),
        options
    );
};

export type CreateGeneratedFormError = Fetcher.ErrorWrapper<undefined>;

export type CreateGeneratedFormVariables = {
    body: Schemas.PrismaGeneratedFormCreateInput;
} & ApiContext["fetcherOptions"];

export const fetchCreateGeneratedForm = (variables: CreateGeneratedFormVariables, signal?: AbortSignal) =>
    apiFetch<void, CreateGeneratedFormError, Schemas.PrismaGeneratedFormCreateInput, {}, {}, {}>({
        url: "/generatedForms",
        method: "post",
        ...variables,
        signal,
    });

export const useCreateGeneratedForm = (
    options?: Omit<reactQuery.UseMutationOptions<void, CreateGeneratedFormError, CreateGeneratedFormVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CreateGeneratedFormError, CreateGeneratedFormVariables>(
        (variables: CreateGeneratedFormVariables) => fetchCreateGeneratedForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetAllGeneratedFormError = Fetcher.ErrorWrapper<undefined>;

export type GetAllGeneratedFormVariables = ApiContext["fetcherOptions"];

export const fetchGetAllGeneratedForm = (variables: GetAllGeneratedFormVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllGeneratedFormError, undefined, {}, {}, {}>({ url: "/generatedForms", method: "get", ...variables, signal });

export const useGetAllGeneratedForm = <TData = void>(
    variables: GetAllGeneratedFormVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllGeneratedFormError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllGeneratedFormError, TData>(
        queryKeyFn({ path: "/generatedForms", operationId: "getAllGeneratedForm", variables }),
        ({ signal }) => fetchGetAllGeneratedForm({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetGeneratedFormByIdPathParams = {
    id: string;
};

export type GetGeneratedFormByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetGeneratedFormByIdVariables = {
    pathParams: GetGeneratedFormByIdPathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetGeneratedFormById = (variables: GetGeneratedFormByIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetGeneratedFormByIdError, undefined, {}, {}, GetGeneratedFormByIdPathParams>({
        url: "/generatedForms/{id}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetGeneratedFormById = <TData = void>(
    variables: GetGeneratedFormByIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetGeneratedFormByIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetGeneratedFormByIdError, TData>(
        queryKeyFn({ path: "/generatedForms/{id}", operationId: "getGeneratedFormById", variables }),
        ({ signal }) => fetchGetGeneratedFormById({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type UpdateGeneratedFormPathParams = {
    id: string;
};

export type UpdateGeneratedFormError = Fetcher.ErrorWrapper<undefined>;

export type UpdateGeneratedFormVariables = {
    body?: Schemas.PrismaGeneratedFormUpdateInput;
    pathParams: UpdateGeneratedFormPathParams;
} & ApiContext["fetcherOptions"];

export const fetchUpdateGeneratedForm = (variables: UpdateGeneratedFormVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateGeneratedFormError, Schemas.PrismaGeneratedFormUpdateInput, {}, {}, UpdateGeneratedFormPathParams>({
        url: "/generatedForms/{id}",
        method: "put",
        ...variables,
        signal,
    });

export const useUpdateGeneratedForm = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateGeneratedFormError, UpdateGeneratedFormVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateGeneratedFormError, UpdateGeneratedFormVariables>(
        (variables: UpdateGeneratedFormVariables) => fetchUpdateGeneratedForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteGeneratedFormPathParams = {
    id: string;
};

export type DeleteGeneratedFormError = Fetcher.ErrorWrapper<undefined>;

export type DeleteGeneratedFormVariables = {
    pathParams: DeleteGeneratedFormPathParams;
} & ApiContext["fetcherOptions"];

export const fetchDeleteGeneratedForm = (variables: DeleteGeneratedFormVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteGeneratedFormError, undefined, {}, {}, DeleteGeneratedFormPathParams>({
        url: "/generatedForms/{id}",
        method: "delete",
        ...variables,
        signal,
    });

export const useDeleteGeneratedForm = (
    options?: Omit<reactQuery.UseMutationOptions<void, DeleteGeneratedFormError, DeleteGeneratedFormVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteGeneratedFormError, DeleteGeneratedFormVariables>(
        (variables: DeleteGeneratedFormVariables) => fetchDeleteGeneratedForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetError = Fetcher.ErrorWrapper<undefined>;

export type GetVariables = ApiContext["fetcherOptions"];

export const fetchGet = (variables: GetVariables, signal?: AbortSignal) =>
    apiFetch<string, GetError, undefined, {}, {}, {}>({ url: "/", method: "get", ...variables, signal });

export const useGet = <TData = string>(
    variables: GetVariables,
    options?: Omit<reactQuery.UseQueryOptions<string, GetError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<string, GetError, TData>(
        queryKeyFn({ path: "/", operationId: "get", variables }),
        ({ signal }) => fetchGet({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type GetAllLoanApplicationVariables = ApiContext["fetcherOptions"];

export const fetchGetAllLoanApplication = (variables: GetAllLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllLoanApplicationError, undefined, {}, {}, {}>({ url: "/loanApplications", method: "get", ...variables, signal });

export const useGetAllLoanApplication = <TData = void>(
    variables: GetAllLoanApplicationVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllLoanApplicationError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllLoanApplicationError, TData>(
        queryKeyFn({ path: "/loanApplications", operationId: "getAllLoanApplication", variables }),
        ({ signal }) => fetchGetAllLoanApplication({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type CreateLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type CreateLoanApplicationRequestBody = Schemas.LoanApplicationDataCreateInput & {
    isShortLoan?: boolean;
    loanApplicationId?: string;
};

export type CreateLoanApplicationVariables = {
    body?: CreateLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchCreateLoanApplication = (variables: CreateLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, CreateLoanApplicationError, CreateLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications",
        method: "post",
        ...variables,
        signal,
    });

export const useCreateLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, CreateLoanApplicationError, CreateLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CreateLoanApplicationError, CreateLoanApplicationVariables>(
        (variables: CreateLoanApplicationVariables) => fetchCreateLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type FilterLoanApplicationsQueryParams = {
    filter: "all" | "processed" | "applied" | "submitted" | "open";
};

export type FilterLoanApplicationsError = Fetcher.ErrorWrapper<undefined>;

export type FilterLoanApplicationsVariables = {
    queryParams: FilterLoanApplicationsQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchFilterLoanApplications = (variables: FilterLoanApplicationsVariables, signal?: AbortSignal) =>
    apiFetch<void, FilterLoanApplicationsError, undefined, {}, FilterLoanApplicationsQueryParams, {}>({
        url: "/loanApplications/filter",
        method: "get",
        ...variables,
        signal,
    });

export const useFilterLoanApplications = <TData = void>(
    variables: FilterLoanApplicationsVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, FilterLoanApplicationsError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, FilterLoanApplicationsError, TData>(
        queryKeyFn({ path: "/loanApplications/filter", operationId: "filterLoanApplications", variables }),
        ({ signal }) => fetchFilterLoanApplications({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetLoanApplicationByIdPathParams = {
    id: string;
};

export type GetLoanApplicationByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetLoanApplicationByIdVariables = {
    pathParams: GetLoanApplicationByIdPathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetLoanApplicationById = (variables: GetLoanApplicationByIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetLoanApplicationByIdError, undefined, {}, {}, GetLoanApplicationByIdPathParams>({
        url: "/loanApplications/{id}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetLoanApplicationById = <TData = void>(
    variables: GetLoanApplicationByIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetLoanApplicationByIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetLoanApplicationByIdError, TData>(
        queryKeyFn({ path: "/loanApplications/{id}", operationId: "getLoanApplicationById", variables }),
        ({ signal }) => fetchGetLoanApplicationById({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type UpdateLoanApplicationPathParams = {
    id: string;
};

export type UpdateLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type UpdateLoanApplicationVariables = {
    body: Schemas.PrismaLoanApplicationCreateInput;
    pathParams: UpdateLoanApplicationPathParams;
} & ApiContext["fetcherOptions"];

export const fetchUpdateLoanApplication = (variables: UpdateLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateLoanApplicationError, Schemas.PrismaLoanApplicationCreateInput, {}, {}, UpdateLoanApplicationPathParams>({
        url: "/loanApplications/{id}",
        method: "put",
        ...variables,
        signal,
    });

export const useUpdateLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateLoanApplicationError, UpdateLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateLoanApplicationError, UpdateLoanApplicationVariables>(
        (variables: UpdateLoanApplicationVariables) => fetchUpdateLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ShareLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type ShareLoanApplicationRequestBody = {
    userId: string;
    loanApplicationId: string;
};

export type ShareLoanApplicationVariables = {
    body: ShareLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchShareLoanApplication = (variables: ShareLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, ShareLoanApplicationError, ShareLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications/share",
        method: "post",
        ...variables,
        signal,
    });

export const useShareLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, ShareLoanApplicationError, ShareLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ShareLoanApplicationError, ShareLoanApplicationVariables>(
        (variables: ShareLoanApplicationVariables) => fetchShareLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DisconnectLoanApplicationAccessError = Fetcher.ErrorWrapper<undefined>;

export type DisconnectLoanApplicationAccessRequestBody = {
    username?: string;
    userId?: string;
    loanApplicationId: string;
};

export type DisconnectLoanApplicationAccessVariables = {
    body: DisconnectLoanApplicationAccessRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDisconnectLoanApplicationAccess = (variables: DisconnectLoanApplicationAccessVariables, signal?: AbortSignal) =>
    apiFetch<void, DisconnectLoanApplicationAccessError, DisconnectLoanApplicationAccessRequestBody, {}, {}, {}>({
        url: "/loanApplications/disconnect",
        method: "post",
        ...variables,
        signal,
    });

export const useDisconnectLoanApplicationAccess = (
    options?: Omit<reactQuery.UseMutationOptions<void, DisconnectLoanApplicationAccessError, DisconnectLoanApplicationAccessVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DisconnectLoanApplicationAccessError, DisconnectLoanApplicationAccessVariables>(
        (variables: DisconnectLoanApplicationAccessVariables) => fetchDisconnectLoanApplicationAccess({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type DeleteLoanApplicationRequestBody = {
    id: string;
};

export type DeleteLoanApplicationVariables = {
    body: DeleteLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDeleteLoanApplication = (variables: DeleteLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteLoanApplicationError, DeleteLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications/delete",
        method: "post",
        ...variables,
        signal,
    });

export const useDeleteLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, DeleteLoanApplicationError, DeleteLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteLoanApplicationError, DeleteLoanApplicationVariables>(
        (variables: DeleteLoanApplicationVariables) => fetchDeleteLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DisableLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type DisableLoanApplicationRequestBody = {
    id: string;
};

export type DisableLoanApplicationVariables = {
    body: DisableLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDisableLoanApplication = (variables: DisableLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, DisableLoanApplicationError, DisableLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications/disable",
        method: "post",
        ...variables,
        signal,
    });

export const useDisableLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, DisableLoanApplicationError, DisableLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DisableLoanApplicationError, DisableLoanApplicationVariables>(
        (variables: DisableLoanApplicationVariables) => fetchDisableLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type SaveLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type SaveLoanApplicationRequestBody = {
    id: string;
    data?: Schemas.LoanApplicationDataCreateInput;
};

export type SaveLoanApplicationVariables = {
    body: SaveLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchSaveLoanApplication = (variables: SaveLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, SaveLoanApplicationError, SaveLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications/save",
        method: "post",
        ...variables,
        signal,
    });

export const useSaveLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, SaveLoanApplicationError, SaveLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, SaveLoanApplicationError, SaveLoanApplicationVariables>(
        (variables: SaveLoanApplicationVariables) => fetchSaveLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type SubmitLoanApplicationError = Fetcher.ErrorWrapper<undefined>;

export type SubmitLoanApplicationRequestBody = {
    id: string;
} & Schemas.PrismaLoanApplicationUpdateInput;

export type SubmitLoanApplicationVariables = {
    body?: SubmitLoanApplicationRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchSubmitLoanApplication = (variables: SubmitLoanApplicationVariables, signal?: AbortSignal) =>
    apiFetch<void, SubmitLoanApplicationError, SubmitLoanApplicationRequestBody, {}, {}, {}>({
        url: "/loanApplications/submit",
        method: "post",
        ...variables,
        signal,
    });

export const useSubmitLoanApplication = (
    options?: Omit<reactQuery.UseMutationOptions<void, SubmitLoanApplicationError, SubmitLoanApplicationVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, SubmitLoanApplicationError, SubmitLoanApplicationVariables>(
        (variables: SubmitLoanApplicationVariables) => fetchSubmitLoanApplication({ ...fetcherOptions, ...variables }),
        options
    );
};

export type AddLoanApplicationIssueError = Fetcher.ErrorWrapper<undefined>;

export type AddLoanApplicationIssueRequestBody = Schemas.OmitLoanApplicationIssueId & {
    loanApplicationId: string;
};

export type AddLoanApplicationIssueVariables = {
    body?: AddLoanApplicationIssueRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchAddLoanApplicationIssue = (variables: AddLoanApplicationIssueVariables, signal?: AbortSignal) =>
    apiFetch<void, AddLoanApplicationIssueError, AddLoanApplicationIssueRequestBody, {}, {}, {}>({
        url: "/loanApplications/addIssue",
        method: "post",
        ...variables,
        signal,
    });

export const useAddLoanApplicationIssue = (
    options?: Omit<reactQuery.UseMutationOptions<void, AddLoanApplicationIssueError, AddLoanApplicationIssueVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, AddLoanApplicationIssueError, AddLoanApplicationIssueVariables>(
        (variables: AddLoanApplicationIssueVariables) => fetchAddLoanApplicationIssue({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ContactUsError = Fetcher.ErrorWrapper<undefined>;

export type ContactUsRequestBody = {
    message: string;
    mobile: string;
    companyName: string;
    email: string;
    lastName: string;
    firstName: string;
};

export type ContactUsVariables = {
    body: ContactUsRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchContactUs = (variables: ContactUsVariables, signal?: AbortSignal) =>
    apiFetch<string, ContactUsError, ContactUsRequestBody, {}, {}, {}>({ url: "/loanApplications/contactUs", method: "post", ...variables, signal });

export const useContactUs = (options?: Omit<reactQuery.UseMutationOptions<string, ContactUsError, ContactUsVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<string, ContactUsError, ContactUsVariables>(
        (variables: ContactUsVariables) => fetchContactUs({ ...fetcherOptions, ...variables }),
        options
    );
};

export type SendEmailWithDocsError = Fetcher.ErrorWrapper<undefined>;

export type SendEmailWithDocsRequestBody = {
    loanApplicationId: string;
    email: string;
};

export type SendEmailWithDocsVariables = {
    body: SendEmailWithDocsRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchSendEmailWithDocs = (variables: SendEmailWithDocsVariables, signal?: AbortSignal) =>
    apiFetch<void, SendEmailWithDocsError, SendEmailWithDocsRequestBody, {}, {}, {}>({
        url: "/loanApplications/sendEmailWithDocs",
        method: "post",
        ...variables,
        signal,
    });

export const useSendEmailWithDocs = (
    options?: Omit<reactQuery.UseMutationOptions<void, SendEmailWithDocsError, SendEmailWithDocsVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, SendEmailWithDocsError, SendEmailWithDocsVariables>(
        (variables: SendEmailWithDocsVariables) => fetchSendEmailWithDocs({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateDocumentDataError = Fetcher.ErrorWrapper<undefined>;

export type UpdateDocumentDataRequestBody = {
    value: void;
    name: string;
    id: string;
};

export type UpdateDocumentDataVariables = {
    body: UpdateDocumentDataRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchUpdateDocumentData = (variables: UpdateDocumentDataVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateDocumentDataError, UpdateDocumentDataRequestBody, {}, {}, {}>({
        url: "/loanApplications/upload",
        method: "put",
        ...variables,
        signal,
    });

export const useUpdateDocumentData = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateDocumentDataError, UpdateDocumentDataVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateDocumentDataError, UpdateDocumentDataVariables>(
        (variables: UpdateDocumentDataVariables) => fetchUpdateDocumentData({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteDocumentDataError = Fetcher.ErrorWrapper<undefined>;

export type DeleteDocumentDataRequestBody = {
    name: string;
    form_id: string;
};

export type DeleteDocumentDataVariables = {
    body: DeleteDocumentDataRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDeleteDocumentData = (variables: DeleteDocumentDataVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteDocumentDataError, DeleteDocumentDataRequestBody, {}, {}, {}>({
        url: "/loanApplications/deleteData",
        method: "delete",
        ...variables,
        signal,
    });

export const useDeleteDocumentData = (
    options?: Omit<reactQuery.UseMutationOptions<void, DeleteDocumentDataError, DeleteDocumentDataVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteDocumentDataError, DeleteDocumentDataVariables>(
        (variables: DeleteDocumentDataVariables) => fetchDeleteDocumentData({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetAllProspectiveUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllProspectiveUsersVariables = ApiContext["fetcherOptions"];

export const fetchGetAllProspectiveUsers = (variables: GetAllProspectiveUsersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllProspectiveUsersError, undefined, {}, {}, {}>({ url: "/prospectiveUser", method: "get", ...variables, signal });

export const useGetAllProspectiveUsers = <TData = void>(
    variables: GetAllProspectiveUsersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllProspectiveUsersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllProspectiveUsersError, TData>(
        queryKeyFn({ path: "/prospectiveUser", operationId: "getAllProspectiveUsers", variables }),
        ({ signal }) => fetchGetAllProspectiveUsers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type CreateProspectiveUserError = Fetcher.ErrorWrapper<undefined>;

export type CreateProspectiveUserRequestBody = Schemas.PickPrismaUserCreateInputIdOrRoleOrBusinessNameOrFullNameOrAddress & {
    longFormData?: Schemas.PrismaLoanApplicationDataCreateInput;
    formType?: Schemas.FormType;
};

export type CreateProspectiveUserVariables = {
    body?: CreateProspectiveUserRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchCreateProspectiveUser = (variables: CreateProspectiveUserVariables, signal?: AbortSignal) =>
    apiFetch<void, CreateProspectiveUserError, CreateProspectiveUserRequestBody, {}, {}, {}>({
        url: "/prospectiveUser",
        method: "post",
        ...variables,
        signal,
    });

export const useCreateProspectiveUser = (
    options?: Omit<reactQuery.UseMutationOptions<void, CreateProspectiveUserError, CreateProspectiveUserVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CreateProspectiveUserError, CreateProspectiveUserVariables>(
        (variables: CreateProspectiveUserVariables) => fetchCreateProspectiveUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetProspectiveUserByIdPathParams = {
    id: string;
};

export type GetProspectiveUserByIdError = Fetcher.ErrorWrapper<undefined>;

export type GetProspectiveUserByIdVariables = {
    pathParams: GetProspectiveUserByIdPathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetProspectiveUserById = (variables: GetProspectiveUserByIdVariables, signal?: AbortSignal) =>
    apiFetch<void, GetProspectiveUserByIdError, undefined, {}, {}, GetProspectiveUserByIdPathParams>({
        url: "/prospectiveUser/{id}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetProspectiveUserById = <TData = void>(
    variables: GetProspectiveUserByIdVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetProspectiveUserByIdError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetProspectiveUserByIdError, TData>(
        queryKeyFn({ path: "/prospectiveUser/{id}", operationId: "getProspectiveUserById", variables }),
        ({ signal }) => fetchGetProspectiveUserById({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type AddFollowUpCommentError = Fetcher.ErrorWrapper<undefined>;

export type AddFollowUpCommentRequestBody = Schemas.OmitPrismaFollowUpCommentsCreateInputNote & {
    note: Schemas.LoanApplicationIssueComments[];
    userId: string;
};

export type AddFollowUpCommentVariables = {
    body?: AddFollowUpCommentRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchAddFollowUpComment = (variables: AddFollowUpCommentVariables, signal?: AbortSignal) =>
    apiFetch<void, AddFollowUpCommentError, AddFollowUpCommentRequestBody, {}, {}, {}>({
        url: "/prospectiveUser/addFollowUpComment",
        method: "post",
        ...variables,
        signal,
    });

export const useAddFollowUpComment = (
    options?: Omit<reactQuery.UseMutationOptions<void, AddFollowUpCommentError, AddFollowUpCommentVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, AddFollowUpCommentError, AddFollowUpCommentVariables>(
        (variables: AddFollowUpCommentVariables) => fetchAddFollowUpComment({ ...fetcherOptions, ...variables }),
        options
    );
};

export type AddProspectiveLongFormError = Fetcher.ErrorWrapper<undefined>;

export type AddProspectiveLongFormRequestBody = {
    prospectiveUserId: string;
    data: Schemas.LoanApplicationDataCreateInput;
};

export type AddProspectiveLongFormVariables = {
    body: AddProspectiveLongFormRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchAddProspectiveLongForm = (variables: AddProspectiveLongFormVariables, signal?: AbortSignal) =>
    apiFetch<void, AddProspectiveLongFormError, AddProspectiveLongFormRequestBody, {}, {}, {}>({
        url: "/prospectiveUser/addLongForm",
        method: "post",
        ...variables,
        signal,
    });

export const useAddProspectiveLongForm = (
    options?: Omit<reactQuery.UseMutationOptions<void, AddProspectiveLongFormError, AddProspectiveLongFormVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, AddProspectiveLongFormError, AddProspectiveLongFormVariables>(
        (variables: AddProspectiveLongFormVariables) => fetchAddProspectiveLongForm({ ...fetcherOptions, ...variables }),
        options
    );
};

export type AddWorkOrderError = Fetcher.ErrorWrapper<undefined>;

export type AddWorkOrderRequestBody = {
    prospectiveUserId: string;
    /**
     * From T, pick a set of properties whose keys are in the union K
     */
    data: Schemas.OmitPrismaWorkOrderCreateWithoutContractorInputWorkOrderComments & {
        workOrderComments: void;
    };
};

export type AddWorkOrderVariables = {
    body: AddWorkOrderRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchAddWorkOrder = (variables: AddWorkOrderVariables, signal?: AbortSignal) =>
    apiFetch<void, AddWorkOrderError, AddWorkOrderRequestBody, {}, {}, {}>({
        url: "/prospectiveUser/addWorkOrder",
        method: "post",
        ...variables,
        signal,
    });

export const useAddWorkOrder = (options?: Omit<reactQuery.UseMutationOptions<void, AddWorkOrderError, AddWorkOrderVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, AddWorkOrderError, AddWorkOrderVariables>(
        (variables: AddWorkOrderVariables) => fetchAddWorkOrder({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateFollowUpCommentError = Fetcher.ErrorWrapper<undefined>;

export type UpdateFollowUpCommentRequestBody = (Schemas.OmitPrismaFollowUpCommentsCreateInputNote & {
    note: Schemas.LoanApplicationIssueComments[];
})[];

export type UpdateFollowUpCommentVariables = {
    body?: UpdateFollowUpCommentRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchUpdateFollowUpComment = (variables: UpdateFollowUpCommentVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateFollowUpCommentError, UpdateFollowUpCommentRequestBody, {}, {}, {}>({
        url: "/prospectiveUser/updateFollowUpComment",
        method: "post",
        ...variables,
        signal,
    });

export const useUpdateFollowUpComment = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateFollowUpCommentError, UpdateFollowUpCommentVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateFollowUpCommentError, UpdateFollowUpCommentVariables>(
        (variables: UpdateFollowUpCommentVariables) => fetchUpdateFollowUpComment({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetFilteredProspectiveUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetFilteredProspectiveUsersRequestBody = {
    orderView: "default" | "priority" | "highPriority";
    filters: void;
};

export type GetFilteredProspectiveUsersVariables = {
    body: GetFilteredProspectiveUsersRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGetFilteredProspectiveUsers = (variables: GetFilteredProspectiveUsersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetFilteredProspectiveUsersError, GetFilteredProspectiveUsersRequestBody, {}, {}, {}>({
        url: "/prospectiveUser/filter",
        method: "post",
        ...variables,
        signal,
    });

export const useGetFilteredProspectiveUsers = (
    options?: Omit<reactQuery.UseMutationOptions<void, GetFilteredProspectiveUsersError, GetFilteredProspectiveUsersVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GetFilteredProspectiveUsersError, GetFilteredProspectiveUsersVariables>(
        (variables: GetFilteredProspectiveUsersVariables) => fetchGetFilteredProspectiveUsers({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateProspectiveUserError = Fetcher.ErrorWrapper<undefined>;

export type UpdateProspectiveUserVariables = {
    body: Schemas.PickPrismaUserCreateInputIdOrBusinessNameOrFullNameOrEmailOrMobileOrLandlineOrAddressOrRoleDetailsOrFollowUpDateOrSocialMedia;
} & ApiContext["fetcherOptions"];

export const fetchUpdateProspectiveUser = (variables: UpdateProspectiveUserVariables, signal?: AbortSignal) =>
    apiFetch<
        void,
        UpdateProspectiveUserError,
        Schemas.PickPrismaUserCreateInputIdOrBusinessNameOrFullNameOrEmailOrMobileOrLandlineOrAddressOrRoleDetailsOrFollowUpDateOrSocialMedia,
        {},
        {},
        {}
    >({ url: "/prospectiveUser/update", method: "post", ...variables, signal });

export const useUpdateProspectiveUser = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateProspectiveUserError, UpdateProspectiveUserVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateProspectiveUserError, UpdateProspectiveUserVariables>(
        (variables: UpdateProspectiveUserVariables) => fetchUpdateProspectiveUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateScoremeWebhookError = Fetcher.ErrorWrapper<undefined>;

export type UpdateScoremeWebhookVariables = {
    body?: void;
} & ApiContext["fetcherOptions"];

export const fetchUpdateScoremeWebhook = (variables: UpdateScoremeWebhookVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateScoremeWebhookError, void, {}, {}, {}>({ url: "/scoreme/webhook", method: "post", ...variables, signal });

export const useUpdateScoremeWebhook = (
    options?: Omit<reactQuery.UseMutationOptions<void, UpdateScoremeWebhookError, UpdateScoremeWebhookVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateScoremeWebhookError, UpdateScoremeWebhookVariables>(
        (variables: UpdateScoremeWebhookVariables) => fetchUpdateScoremeWebhook({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GenerateBackofficeBSAReportError = Fetcher.ErrorWrapper<undefined>;

export type GenerateBackofficeBSAReportRequestBody = {
    data: {
        entityKey: string;
        fileAddress?: string;
        loanApplicationId: string;
        entityType: string;
        pan: string;
        entityName: string;
    };
    bankAccount: Schemas.PrismaDocumentBusinessBankAccountCreateInput[];
};

export type GenerateBackofficeBSAReportVariables = {
    body: GenerateBackofficeBSAReportRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGenerateBackofficeBSAReport = (variables: GenerateBackofficeBSAReportVariables, signal?: AbortSignal) =>
    apiFetch<void, GenerateBackofficeBSAReportError, GenerateBackofficeBSAReportRequestBody, {}, {}, {}>({
        url: "/scoreme/backoffice/bsaReport",
        method: "post",
        ...variables,
        signal,
    });

export const useGenerateBackofficeBSAReport = (
    options?: Omit<reactQuery.UseMutationOptions<void, GenerateBackofficeBSAReportError, GenerateBackofficeBSAReportVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GenerateBackofficeBSAReportError, GenerateBackofficeBSAReportVariables>(
        (variables: GenerateBackofficeBSAReportVariables) => fetchGenerateBackofficeBSAReport({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GenerateBSAReportError = Fetcher.ErrorWrapper<undefined>;

export type GenerateBSAReportRequestBody = {
    data: {
        entityKey: string;
        fileAddress?: string;
        loanApplicationId: string;
        entityType: string;
        pan: string;
        entityName: string;
    };
    bankAccount: Schemas.PrismaDocumentBusinessBankAccountCreateInput;
};

export type GenerateBSAReportVariables = {
    body: GenerateBSAReportRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchGenerateBSAReport = (variables: GenerateBSAReportVariables, signal?: AbortSignal) =>
    apiFetch<void, GenerateBSAReportError, GenerateBSAReportRequestBody, {}, {}, {}>({
        url: "/scoreme/bsaReport",
        method: "post",
        ...variables,
        signal,
    });

export const useGenerateBSAReport = (
    options?: Omit<reactQuery.UseMutationOptions<void, GenerateBSAReportError, GenerateBSAReportVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, GenerateBSAReportError, GenerateBSAReportVariables>(
        (variables: GenerateBSAReportVariables) => fetchGenerateBSAReport({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetBankStatementQueryParams = {
    loanApplicationId: string;
};

export type GetBankStatementError = Fetcher.ErrorWrapper<undefined>;

export type GetBankStatementVariables = {
    queryParams: GetBankStatementQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetBankStatement = (variables: GetBankStatementVariables, signal?: AbortSignal) =>
    apiFetch<void, GetBankStatementError, undefined, {}, GetBankStatementQueryParams, {}>({
        url: "/scoreme/getBankStatement",
        method: "get",
        ...variables,
        signal,
    });

export const useGetBankStatement = <TData = void>(
    variables: GetBankStatementVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetBankStatementError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetBankStatementError, TData>(
        queryKeyFn({ path: "/scoreme/getBankStatement", operationId: "getBankStatement", variables }),
        ({ signal }) => fetchGetBankStatement({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetBSAConsolidatedCombinedQueryParams = {
    loanApplicationId: string;
};

export type GetBSAConsolidatedCombinedError = Fetcher.ErrorWrapper<undefined>;

export type GetBSAConsolidatedCombinedVariables = {
    queryParams: GetBSAConsolidatedCombinedQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetBSAConsolidatedCombined = (variables: GetBSAConsolidatedCombinedVariables, signal?: AbortSignal) =>
    apiFetch<void, GetBSAConsolidatedCombinedError, undefined, {}, GetBSAConsolidatedCombinedQueryParams, {}>({
        url: "/scoreme/getBSAConsolidatedCombined",
        method: "get",
        ...variables,
        signal,
    });

export const useGetBSAConsolidatedCombined = <TData = void>(
    variables: GetBSAConsolidatedCombinedVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetBSAConsolidatedCombinedError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetBSAConsolidatedCombinedError, TData>(
        queryKeyFn({ path: "/scoreme/getBSAConsolidatedCombined", operationId: "getBSAConsolidatedCombined", variables }),
        ({ signal }) => fetchGetBSAConsolidatedCombined({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetCombinedLoanJsonQueryParams = {
    loanApplicationId: string;
};

export type GetCombinedLoanJsonError = Fetcher.ErrorWrapper<undefined>;

export type GetCombinedLoanJsonVariables = {
    queryParams: GetCombinedLoanJsonQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetCombinedLoanJson = (variables: GetCombinedLoanJsonVariables, signal?: AbortSignal) =>
    apiFetch<void, GetCombinedLoanJsonError, undefined, {}, GetCombinedLoanJsonQueryParams, {}>({
        url: "/scoreme/backoffice/combinedLoanJson",
        method: "get",
        ...variables,
        signal,
    });

export const useGetCombinedLoanJson = <TData = void>(
    variables: GetCombinedLoanJsonVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetCombinedLoanJsonError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetCombinedLoanJsonError, TData>(
        queryKeyFn({ path: "/scoreme/backoffice/combinedLoanJson", operationId: "getCombinedLoanJson", variables }),
        ({ signal }) => fetchGetCombinedLoanJson({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type UploadError = Fetcher.ErrorWrapper<undefined>;

export type UploadRequestBody = {
    /**
     * @format double
     */
    fileSize: number;
    path: string;
    fileName: string;
}[];

export type UploadVariables = {
    body?: UploadRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchUpload = (variables: UploadVariables, signal?: AbortSignal) =>
    apiFetch<void, UploadError, UploadRequestBody, {}, {}, {}>({ url: "/storage/upload", method: "post", ...variables, signal });

export const useUpload = (options?: Omit<reactQuery.UseMutationOptions<void, UploadError, UploadVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UploadError, UploadVariables>(
        (variables: UploadVariables) => fetchUpload({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DownloadError = Fetcher.ErrorWrapper<undefined>;

export type DownloadRequestBody = {
    key: string | string[];
};

export type DownloadVariables = {
    body: DownloadRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDownload = (variables: DownloadVariables, signal?: AbortSignal) =>
    apiFetch<void, DownloadError, DownloadRequestBody, {}, {}, {}>({ url: "/storage/download", method: "post", ...variables, signal });

export const useDownload = (options?: Omit<reactQuery.UseMutationOptions<void, DownloadError, DownloadVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DownloadError, DownloadVariables>(
        (variables: DownloadVariables) => fetchDownload({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteError = Fetcher.ErrorWrapper<undefined>;

export type DeleteRequestBody = {
    path: string | string[];
};

export type DeleteVariables = {
    body: DeleteRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDelete = (variables: DeleteVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteError, DeleteRequestBody, {}, {}, {}>({ url: "/storage/delete", method: "post", ...variables, signal });

export const useDelete = (options?: Omit<reactQuery.UseMutationOptions<void, DeleteError, DeleteVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteError, DeleteVariables>(
        (variables: DeleteVariables) => fetchDelete({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetAllUserError = Fetcher.ErrorWrapper<undefined>;

export type GetAllUserVariables = ApiContext["fetcherOptions"];

export const fetchGetAllUser = (variables: GetAllUserVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllUserError, undefined, {}, {}, {}>({ url: "/users", method: "get", ...variables, signal });

export const useGetAllUser = <TData = void>(
    variables: GetAllUserVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllUserError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllUserError, TData>(
        queryKeyFn({ path: "/users", operationId: "getAllUser", variables }),
        ({ signal }) => fetchGetAllUser({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetMeError = Fetcher.ErrorWrapper<undefined>;

export type GetMeVariables = ApiContext["fetcherOptions"];

export const fetchGetMe = (variables: GetMeVariables, signal?: AbortSignal) =>
    apiFetch<void, GetMeError, undefined, {}, {}, {}>({ url: "/users/me", method: "get", ...variables, signal });

export const useGetMe = <TData = void>(
    variables: GetMeVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetMeError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetMeError, TData>(
        queryKeyFn({ path: "/users/me", operationId: "getMe", variables }),
        ({ signal }) => fetchGetMe({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllSalesAgentUsersQueryParams = {
    name?: string;
};

export type GetAllSalesAgentUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllSalesAgentUsersVariables = {
    queryParams?: GetAllSalesAgentUsersQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetAllSalesAgentUsers = (variables: GetAllSalesAgentUsersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllSalesAgentUsersError, undefined, {}, GetAllSalesAgentUsersQueryParams, {}>({
        url: "/users/salesAgent",
        method: "get",
        ...variables,
        signal,
    });

export const useGetAllSalesAgentUsers = <TData = void>(
    variables: GetAllSalesAgentUsersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllSalesAgentUsersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllSalesAgentUsersError, TData>(
        queryKeyFn({ path: "/users/salesAgent", operationId: "getAllSalesAgentUsers", variables }),
        ({ signal }) => fetchGetAllSalesAgentUsers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type VerifyEmailQueryParams = {
    username: string;
    email: string;
    token: string;
};

export type VerifyEmailError = Fetcher.ErrorWrapper<undefined>;

export type VerifyEmailVariables = {
    queryParams: VerifyEmailQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchVerifyEmail = (variables: VerifyEmailVariables, signal?: AbortSignal) =>
    apiFetch<void, VerifyEmailError, undefined, {}, VerifyEmailQueryParams, {}>({ url: "/users/verifyEmail", method: "get", ...variables, signal });

export const useVerifyEmail = <TData = void>(
    variables: VerifyEmailVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, VerifyEmailError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, VerifyEmailError, TData>(
        queryKeyFn({ path: "/users/verifyEmail", operationId: "verifyEmail", variables }),
        ({ signal }) => fetchVerifyEmail({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetUserByRolePathParams = {
    role: Schemas.Role;
};

export type GetUserByRoleError = Fetcher.ErrorWrapper<undefined>;

export type GetUserByRoleVariables = {
    pathParams: GetUserByRolePathParams;
} & ApiContext["fetcherOptions"];

export const fetchGetUserByRole = (variables: GetUserByRoleVariables, signal?: AbortSignal) =>
    apiFetch<void, GetUserByRoleError, undefined, {}, {}, GetUserByRolePathParams>({
        url: "/users/role/{role}",
        method: "get",
        ...variables,
        signal,
    });

export const useGetUserByRole = <TData = void>(
    variables: GetUserByRoleVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetUserByRoleError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetUserByRoleError, TData>(
        queryKeyFn({ path: "/users/role/{role}", operationId: "getUserByRole", variables }),
        ({ signal }) => fetchGetUserByRole({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type GetAllBackofficeUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetAllBackofficeUsersVariables = ApiContext["fetcherOptions"];

export const fetchGetAllBackofficeUsers = (variables: GetAllBackofficeUsersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetAllBackofficeUsersError, undefined, {}, {}, {}>({ url: "/users/backoffice", method: "get", ...variables, signal });

export const useGetAllBackofficeUsers = <TData = void>(
    variables: GetAllBackofficeUsersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetAllBackofficeUsersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetAllBackofficeUsersError, TData>(
        queryKeyFn({ path: "/users/backoffice", operationId: "getAllBackofficeUsers", variables }),
        ({ signal }) => fetchGetAllBackofficeUsers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type BackofficeLoginError = Fetcher.ErrorWrapper<undefined>;

export type BackofficeLoginRequestBody = {
    ssoSource?: "google";
    loginSource: "sso" | "portal";
    password: string;
    email: string;
};

export type BackofficeLoginVariables = {
    body: BackofficeLoginRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchBackofficeLogin = (variables: BackofficeLoginVariables, signal?: AbortSignal) =>
    apiFetch<void, BackofficeLoginError, BackofficeLoginRequestBody, {}, {}, {}>({
        url: "/users/backoffice/login",
        method: "post",
        ...variables,
        signal,
    });

export const useBackofficeLogin = (
    options?: Omit<reactQuery.UseMutationOptions<void, BackofficeLoginError, BackofficeLoginVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, BackofficeLoginError, BackofficeLoginVariables>(
        (variables: BackofficeLoginVariables) => fetchBackofficeLogin({ ...fetcherOptions, ...variables }),
        options
    );
};

export type GetFilteredBackOfficeUsersQueryParams = {
    email?: string;
    mobile?: string;
};

export type GetFilteredBackOfficeUsersError = Fetcher.ErrorWrapper<undefined>;

export type GetFilteredBackOfficeUsersVariables = {
    queryParams?: GetFilteredBackOfficeUsersQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchGetFilteredBackOfficeUsers = (variables: GetFilteredBackOfficeUsersVariables, signal?: AbortSignal) =>
    apiFetch<void, GetFilteredBackOfficeUsersError, undefined, {}, GetFilteredBackOfficeUsersQueryParams, {}>({
        url: "/users/backoffice/filter",
        method: "get",
        ...variables,
        signal,
    });

export const useGetFilteredBackOfficeUsers = <TData = void>(
    variables: GetFilteredBackOfficeUsersVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, GetFilteredBackOfficeUsersError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, GetFilteredBackOfficeUsersError, TData>(
        queryKeyFn({ path: "/users/backoffice/filter", operationId: "getFilteredBackOfficeUsers", variables }),
        ({ signal }) => fetchGetFilteredBackOfficeUsers({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type VerifyRegisterLinkQueryParams = {
    token: string;
};

export type VerifyRegisterLinkError = Fetcher.ErrorWrapper<undefined>;

export type VerifyRegisterLinkVariables = {
    queryParams: VerifyRegisterLinkQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchVerifyRegisterLink = (variables: VerifyRegisterLinkVariables, signal?: AbortSignal) =>
    apiFetch<void, VerifyRegisterLinkError, undefined, {}, VerifyRegisterLinkQueryParams, {}>({
        url: "/users/backoffice/register/verify",
        method: "get",
        ...variables,
        signal,
    });

export const useVerifyRegisterLink = <TData = void>(
    variables: VerifyRegisterLinkVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, VerifyRegisterLinkError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, VerifyRegisterLinkError, TData>(
        queryKeyFn({ path: "/users/backoffice/register/verify", operationId: "verifyRegisterLink", variables }),
        ({ signal }) => fetchVerifyRegisterLink({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type CheckResetPasswordLinkQueryParams = {
    token: string;
};

export type CheckResetPasswordLinkError = Fetcher.ErrorWrapper<undefined>;

export type CheckResetPasswordLinkVariables = {
    queryParams: CheckResetPasswordLinkQueryParams;
} & ApiContext["fetcherOptions"];

export const fetchCheckResetPasswordLink = (variables: CheckResetPasswordLinkVariables, signal?: AbortSignal) =>
    apiFetch<void, CheckResetPasswordLinkError, undefined, {}, CheckResetPasswordLinkQueryParams, {}>({
        url: "/users/checkResetPasswordLink",
        method: "get",
        ...variables,
        signal,
    });

export const useCheckResetPasswordLink = <TData = void>(
    variables: CheckResetPasswordLinkVariables,
    options?: Omit<reactQuery.UseQueryOptions<void, CheckResetPasswordLinkError, TData>, "queryKey" | "queryFn">
) => {
    const { fetcherOptions, queryOptions, queryKeyFn } = useApiContext(options);
    return reactQuery.useQuery<void, CheckResetPasswordLinkError, TData>(
        queryKeyFn({ path: "/users/checkResetPasswordLink", operationId: "checkResetPasswordLink", variables }),
        ({ signal }) => fetchCheckResetPasswordLink({ ...fetcherOptions, ...variables }, signal),
        {
            ...options,
            ...queryOptions,
        }
    );
};

export type RegisterError = Fetcher.ErrorWrapper<undefined>;

export type RegisterRequestBody = {
    loginSource?: "sso" | "portal";
    ssoSource?: "google";
} & Schemas.OmitPrismaUserCreateInputRole;

export type RegisterVariables = {
    body?: RegisterRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchRegister = (variables: RegisterVariables, signal?: AbortSignal) =>
    apiFetch<void, RegisterError, RegisterRequestBody, {}, {}, {}>({ url: "/users/register", method: "post", ...variables, signal });

export const useRegister = (options?: Omit<reactQuery.UseMutationOptions<void, RegisterError, RegisterVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, RegisterError, RegisterVariables>(
        (variables: RegisterVariables) => fetchRegister({ ...fetcherOptions, ...variables }),
        options
    );
};

export type LoginError = Fetcher.ErrorWrapper<undefined>;

export type LoginRequestBody = {
    ssoSource?: "google";
    loginSource: "sso" | "portal";
    password?: string;
    email: string;
};

export type LoginVariables = {
    body: LoginRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchLogin = (variables: LoginVariables, signal?: AbortSignal) =>
    apiFetch<void, LoginError, LoginRequestBody, {}, {}, {}>({ url: "/users/login", method: "post", ...variables, signal });

export const useLogin = (options?: Omit<reactQuery.UseMutationOptions<void, LoginError, LoginVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, LoginError, LoginVariables>(
        (variables: LoginVariables) => fetchLogin({ ...fetcherOptions, ...variables }),
        options
    );
};

export type TokenRefreshError = Fetcher.ErrorWrapper<undefined>;

export type TokenRefreshRequestBody = {
    refreshToken: string;
};

export type TokenRefreshVariables = {
    body: TokenRefreshRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchTokenRefresh = (variables: TokenRefreshVariables, signal?: AbortSignal) =>
    apiFetch<void, TokenRefreshError, TokenRefreshRequestBody, {}, {}, {}>({ url: "/users/token-refresh", method: "post", ...variables, signal });

export const useTokenRefresh = (options?: Omit<reactQuery.UseMutationOptions<void, TokenRefreshError, TokenRefreshVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, TokenRefreshError, TokenRefreshVariables>(
        (variables: TokenRefreshVariables) => fetchTokenRefresh({ ...fetcherOptions, ...variables }),
        options
    );
};

export type FilterUsersByUserNameError = Fetcher.ErrorWrapper<undefined>;

export type FilterUsersByUserNameRequestBody = {
    mobile: string;
    username: string;
};

export type FilterUsersByUserNameVariables = {
    body: FilterUsersByUserNameRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchFilterUsersByUserName = (variables: FilterUsersByUserNameVariables, signal?: AbortSignal) =>
    apiFetch<void, FilterUsersByUserNameError, FilterUsersByUserNameRequestBody, {}, {}, {}>({
        url: "/users/filter",
        method: "post",
        ...variables,
        signal,
    });

export const useFilterUsersByUserName = (
    options?: Omit<reactQuery.UseMutationOptions<void, FilterUsersByUserNameError, FilterUsersByUserNameVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, FilterUsersByUserNameError, FilterUsersByUserNameVariables>(
        (variables: FilterUsersByUserNameVariables) => fetchFilterUsersByUserName({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ChangeEmailError = Fetcher.ErrorWrapper<undefined>;

export type ChangeEmailRequestBody = {
    newEmail: string;
};

export type ChangeEmailVariables = {
    body: ChangeEmailRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchChangeEmail = (variables: ChangeEmailVariables, signal?: AbortSignal) =>
    apiFetch<void, ChangeEmailError, ChangeEmailRequestBody, {}, {}, {}>({ url: "/users/changeEmail", method: "post", ...variables, signal });

export const useChangeEmail = (options?: Omit<reactQuery.UseMutationOptions<void, ChangeEmailError, ChangeEmailVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ChangeEmailError, ChangeEmailVariables>(
        (variables: ChangeEmailVariables) => fetchChangeEmail({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ResetEmailError = Fetcher.ErrorWrapper<undefined>;

export type ResetEmailRequestBody = {
    token: string;
    username: string;
    email: string;
};

export type ResetEmailVariables = {
    body: ResetEmailRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchResetEmail = (variables: ResetEmailVariables, signal?: AbortSignal) =>
    apiFetch<void, ResetEmailError, ResetEmailRequestBody, {}, {}, {}>({ url: "/users/resetEmail", method: "post", ...variables, signal });

export const useResetEmail = (options?: Omit<reactQuery.UseMutationOptions<void, ResetEmailError, ResetEmailVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ResetEmailError, ResetEmailVariables>(
        (variables: ResetEmailVariables) => fetchResetEmail({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ChangePasswordError = Fetcher.ErrorWrapper<undefined>;

export type ChangePasswordRequestBody = {
    newPassword: string;
    oldPassword: string;
};

export type ChangePasswordVariables = {
    body: ChangePasswordRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchChangePassword = (variables: ChangePasswordVariables, signal?: AbortSignal) =>
    apiFetch<void, ChangePasswordError, ChangePasswordRequestBody, {}, {}, {}>({
        url: "/users/changePassword",
        method: "post",
        ...variables,
        signal,
    });

export const useChangePassword = (
    options?: Omit<reactQuery.UseMutationOptions<void, ChangePasswordError, ChangePasswordVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ChangePasswordError, ChangePasswordVariables>(
        (variables: ChangePasswordVariables) => fetchChangePassword({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ForgetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ForgetPasswordRequestBody = {
    isFrontOffice?: boolean;
    email: string;
};

export type ForgetPasswordVariables = {
    body: ForgetPasswordRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchForgetPassword = (variables: ForgetPasswordVariables, signal?: AbortSignal) =>
    apiFetch<void, ForgetPasswordError, ForgetPasswordRequestBody, {}, {}, {}>({
        url: "/users/backoffice/forgetPassword",
        method: "post",
        ...variables,
        signal,
    });

export const useForgetPassword = (
    options?: Omit<reactQuery.UseMutationOptions<void, ForgetPasswordError, ForgetPasswordVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ForgetPasswordError, ForgetPasswordVariables>(
        (variables: ForgetPasswordVariables) => fetchForgetPassword({ ...fetcherOptions, ...variables }),
        options
    );
};

export type ResetPasswordError = Fetcher.ErrorWrapper<undefined>;

export type ResetPasswordRequestBody = {
    newPassword: string;
    email: string;
    token: string;
};

export type ResetPasswordVariables = {
    body: ResetPasswordRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchResetPassword = (variables: ResetPasswordVariables, signal?: AbortSignal) =>
    apiFetch<void, ResetPasswordError, ResetPasswordRequestBody, {}, {}, {}>({ url: "/users/resetPassword", method: "post", ...variables, signal });

export const useResetPassword = (options?: Omit<reactQuery.UseMutationOptions<void, ResetPasswordError, ResetPasswordVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, ResetPasswordError, ResetPasswordVariables>(
        (variables: ResetPasswordVariables) => fetchResetPassword({ ...fetcherOptions, ...variables }),
        options
    );
};

export type RedirectedLoginError = Fetcher.ErrorWrapper<undefined>;

export type RedirectedLoginRequestBody = {
    token: string;
};

export type RedirectedLoginVariables = {
    body: RedirectedLoginRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchRedirectedLogin = (variables: RedirectedLoginVariables, signal?: AbortSignal) =>
    apiFetch<void, RedirectedLoginError, RedirectedLoginRequestBody, {}, {}, {}>({
        url: "/users/redirectedLogin",
        method: "post",
        ...variables,
        signal,
    });

export const useRedirectedLogin = (
    options?: Omit<reactQuery.UseMutationOptions<void, RedirectedLoginError, RedirectedLoginVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, RedirectedLoginError, RedirectedLoginVariables>(
        (variables: RedirectedLoginVariables) => fetchRedirectedLogin({ ...fetcherOptions, ...variables }),
        options
    );
};

export type UpdateUserPathParams = {
    userId: string;
};

export type UpdateUserError = Fetcher.ErrorWrapper<undefined>;

export type UpdateUserRequestBody = Schemas.OmitPrismaUserUpdateInputNotification & {
    notification: Schemas.PrismaNotificationCreateInput;
    oldPassword?: string;
};

export type UpdateUserVariables = {
    body?: UpdateUserRequestBody;
    pathParams: UpdateUserPathParams;
} & ApiContext["fetcherOptions"];

export const fetchUpdateUser = (variables: UpdateUserVariables, signal?: AbortSignal) =>
    apiFetch<void, UpdateUserError, UpdateUserRequestBody, {}, {}, UpdateUserPathParams>({
        url: "/users/{userId}",
        method: "post",
        ...variables,
        signal,
    });

export const useUpdateUser = (options?: Omit<reactQuery.UseMutationOptions<void, UpdateUserError, UpdateUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, UpdateUserError, UpdateUserVariables>(
        (variables: UpdateUserVariables) => fetchUpdateUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type CheckUserError = Fetcher.ErrorWrapper<undefined>;

export type CheckUserRequestBody = {
    mobile: string;
    email: string;
};

export type CheckUserVariables = {
    body: CheckUserRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchCheckUser = (variables: CheckUserVariables, signal?: AbortSignal) =>
    apiFetch<void, CheckUserError, CheckUserRequestBody, {}, {}, {}>({ url: "/users/backoffice/check", method: "post", ...variables, signal });

export const useCheckUser = (options?: Omit<reactQuery.UseMutationOptions<void, CheckUserError, CheckUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, CheckUserError, CheckUserVariables>(
        (variables: CheckUserVariables) => fetchCheckUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type InviteUserError = Fetcher.ErrorWrapper<undefined>;

export type InviteUserVariables = {
    body: Schemas.PickPrismaUserCreateInputEmailOrMobileOrRoleOrBusinessNameOrAddressOrRoleDetails;
} & ApiContext["fetcherOptions"];

export const fetchInviteUser = (variables: InviteUserVariables, signal?: AbortSignal) =>
    apiFetch<void, InviteUserError, Schemas.PickPrismaUserCreateInputEmailOrMobileOrRoleOrBusinessNameOrAddressOrRoleDetails, {}, {}, {}>({
        url: "/users/backoffice/invite",
        method: "post",
        ...variables,
        signal,
    });

export const useInviteUser = (options?: Omit<reactQuery.UseMutationOptions<void, InviteUserError, InviteUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, InviteUserError, InviteUserVariables>(
        (variables: InviteUserVariables) => fetchInviteUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type BackOfficeRegisterError = Fetcher.ErrorWrapper<undefined>;

export type BackOfficeRegisterRequestBody = {
    roleDetails?: Schemas.PrismaRoleDetailsCreateInput;
    token: string;
    fullName: string;
    password: string;
};

export type BackOfficeRegisterVariables = {
    body: BackOfficeRegisterRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchBackOfficeRegister = (variables: BackOfficeRegisterVariables, signal?: AbortSignal) =>
    apiFetch<void, BackOfficeRegisterError, BackOfficeRegisterRequestBody, {}, {}, {}>({
        url: "/users/backoffice/register",
        method: "post",
        ...variables,
        signal,
    });

export const useBackOfficeRegister = (
    options?: Omit<reactQuery.UseMutationOptions<void, BackOfficeRegisterError, BackOfficeRegisterVariables>, "mutationFn">
) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, BackOfficeRegisterError, BackOfficeRegisterVariables>(
        (variables: BackOfficeRegisterVariables) => fetchBackOfficeRegister({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DisableUserError = Fetcher.ErrorWrapper<undefined>;

export type DisableUserRequestBody = {
    email: string;
};

export type DisableUserVariables = {
    body: DisableUserRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDisableUser = (variables: DisableUserVariables, signal?: AbortSignal) =>
    apiFetch<void, DisableUserError, DisableUserRequestBody, {}, {}, {}>({ url: "/users/backoffice/disable", method: "post", ...variables, signal });

export const useDisableUser = (options?: Omit<reactQuery.UseMutationOptions<void, DisableUserError, DisableUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DisableUserError, DisableUserVariables>(
        (variables: DisableUserVariables) => fetchDisableUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type EnableUserError = Fetcher.ErrorWrapper<undefined>;

export type EnableUserRequestBody = {
    email: string;
};

export type EnableUserVariables = {
    body: EnableUserRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchEnableUser = (variables: EnableUserVariables, signal?: AbortSignal) =>
    apiFetch<void, EnableUserError, EnableUserRequestBody, {}, {}, {}>({ url: "/users/backoffice/enable", method: "post", ...variables, signal });

export const useEnableUser = (options?: Omit<reactQuery.UseMutationOptions<void, EnableUserError, EnableUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, EnableUserError, EnableUserVariables>(
        (variables: EnableUserVariables) => fetchEnableUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type DeleteUserError = Fetcher.ErrorWrapper<undefined>;

export type DeleteUserRequestBody = {
    email: string;
};

export type DeleteUserVariables = {
    body: DeleteUserRequestBody;
} & ApiContext["fetcherOptions"];

export const fetchDeleteUser = (variables: DeleteUserVariables, signal?: AbortSignal) =>
    apiFetch<void, DeleteUserError, DeleteUserRequestBody, {}, {}, {}>({ url: "/users/backoffice/delete", method: "post", ...variables, signal });

export const useDeleteUser = (options?: Omit<reactQuery.UseMutationOptions<void, DeleteUserError, DeleteUserVariables>, "mutationFn">) => {
    const { fetcherOptions } = useApiContext();
    return reactQuery.useMutation<void, DeleteUserError, DeleteUserVariables>(
        (variables: DeleteUserVariables) => fetchDeleteUser({ ...fetcherOptions, ...variables }),
        options
    );
};

export type QueryOperation =
    | {
          path: "/backOfficeLoans";
          operationId: "getAllBackOfficeLoans";
          variables: GetAllBackOfficeLoansVariables;
      }
    | {
          path: "/backOfficeLoans/issues";
          operationId: "getAllLoanApplicationIssues";
          variables: GetAllLoanApplicationIssuesVariables;
      }
    | {
          path: "/backOfficeLoans/workOrders/loanId";
          operationId: "getAllWorkOrdersByLoanId";
          variables: GetAllWorkOrdersByLoanIdVariables;
      }
    | {
          path: "/backOfficeLoans/workOrders/contractorId";
          operationId: "getAllWorkOrdersByContractor";
          variables: GetAllWorkOrdersByContractorVariables;
      }
    | {
          path: "/backOfficeLoans/workOrders/id";
          operationId: "getWorkOrderById";
          variables: GetWorkOrderByIdVariables;
      }
    | {
          path: "/backOfficeLoans/getConsolidatedExcel";
          operationId: "getConsolidatedExcel";
          variables: GetConsolidatedExcelVariables;
      }
    | {
          path: "/fiForms";
          operationId: "getAllFiForm";
          variables: GetAllFiFormVariables;
      }
    | {
          path: "/fiForms/{fiFormId}";
          operationId: "getFiFormById";
          variables: GetFiFormByIdVariables;
      }
    | {
          path: "/fiForms/fi/{fiName}";
          operationId: "getFiFormByFiName";
          variables: GetFiFormByFiNameVariables;
      }
    | {
          path: "/financial/dealers";
          operationId: "findAllDealers";
          variables: FindAllDealersVariables;
      }
    | {
          path: "/financial/manufacturers";
          operationId: "getAllManufacturers";
          variables: GetAllManufacturersVariables;
      }
    | {
          path: "/generatedForms";
          operationId: "getAllGeneratedForm";
          variables: GetAllGeneratedFormVariables;
      }
    | {
          path: "/generatedForms/{id}";
          operationId: "getGeneratedFormById";
          variables: GetGeneratedFormByIdVariables;
      }
    | {
          path: "/";
          operationId: "get";
          variables: GetVariables;
      }
    | {
          path: "/loanApplications";
          operationId: "getAllLoanApplication";
          variables: GetAllLoanApplicationVariables;
      }
    | {
          path: "/loanApplications/filter";
          operationId: "filterLoanApplications";
          variables: FilterLoanApplicationsVariables;
      }
    | {
          path: "/loanApplications/{id}";
          operationId: "getLoanApplicationById";
          variables: GetLoanApplicationByIdVariables;
      }
    | {
          path: "/prospectiveUser";
          operationId: "getAllProspectiveUsers";
          variables: GetAllProspectiveUsersVariables;
      }
    | {
          path: "/prospectiveUser/{id}";
          operationId: "getProspectiveUserById";
          variables: GetProspectiveUserByIdVariables;
      }
    | {
          path: "/scoreme/getBankStatement";
          operationId: "getBankStatement";
          variables: GetBankStatementVariables;
      }
    | {
          path: "/scoreme/getBSAConsolidatedCombined";
          operationId: "getBSAConsolidatedCombined";
          variables: GetBSAConsolidatedCombinedVariables;
      }
    | {
          path: "/scoreme/backoffice/combinedLoanJson";
          operationId: "getCombinedLoanJson";
          variables: GetCombinedLoanJsonVariables;
      }
    | {
          path: "/users";
          operationId: "getAllUser";
          variables: GetAllUserVariables;
      }
    | {
          path: "/users/me";
          operationId: "getMe";
          variables: GetMeVariables;
      }
    | {
          path: "/users/salesAgent";
          operationId: "getAllSalesAgentUsers";
          variables: GetAllSalesAgentUsersVariables;
      }
    | {
          path: "/users/verifyEmail";
          operationId: "verifyEmail";
          variables: VerifyEmailVariables;
      }
    | {
          path: "/users/role/{role}";
          operationId: "getUserByRole";
          variables: GetUserByRoleVariables;
      }
    | {
          path: "/users/backoffice";
          operationId: "getAllBackofficeUsers";
          variables: GetAllBackofficeUsersVariables;
      }
    | {
          path: "/users/backoffice/filter";
          operationId: "getFilteredBackOfficeUsers";
          variables: GetFilteredBackOfficeUsersVariables;
      }
    | {
          path: "/users/backoffice/register/verify";
          operationId: "verifyRegisterLink";
          variables: VerifyRegisterLinkVariables;
      }
    | {
          path: "/users/checkResetPasswordLink";
          operationId: "checkResetPasswordLink";
          variables: CheckResetPasswordLinkVariables;
      };
