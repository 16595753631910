import { Fragment } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import useBSAReport from "hooks/useBsaReport";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import fixture from "./fixture";

const LoanAccountCreditItem = ({ index, prefix, setCurrentInput }: { index: number; prefix: string; setCurrentInput: any }) => {
    const loan = useWatch({ name: `${prefix}.loanAccountCredit.${index}` });
    const entity = useWatch({ name: `${prefix.replace(".document.business", "")}.entity` });
    let shouldUpload = true;
    let errorText = "";

    for (const key of ["accountNumber", "bankName"]) {
        if (!loan[key]) {
            errorText += ", " + convertCamelCaseToTitleCase(key);
            shouldUpload = false;
        }
    }

    for (const key of ["entityName", "pan"]) {
        if (key === "pan" && !entity[key]) {
            errorText += ", " + "PAN";
            shouldUpload = false;
        }
        if (!entity[key] && key === "entityName") {
            errorText += ", " + convertCamelCaseToTitleCase(key);
            shouldUpload = false;
        }
    }

    if (errorText) errorText += " required";

    const { setValue } = useFormContext();

    // useEffect(() => {
    //     if (loan?.bankName !== "") {
    //         let bankCode = BANK_NAMES.find((bank) => bank.name === loan.bankName)?.code ?? "";
    //         setValue(`${prefix}.loanAccountCredit.${index}.bankCode`, bankCode);
    //     }
    // }, [loan]);

    return (
        <Fragment>
            {fixture.map(({ name, inputType, options, subtitle, label, editable, placeholder, showMask }, num) => {
                return (
                    <CommonFields
                        key={num}
                        showMask={showMask}
                        inputType={inputType}
                        data-testid={`${prefix}.loanAccountCredit.${index}.${name}`}
                        name={`${prefix}.loanAccountCredit.${index}.${name}`}
                        placeholder={placeholder}
                        subtitle={subtitle}
                        readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                        options={
                            options?.map((value) => ({
                                value,
                                label: value,
                            })) || []
                        }
                        label={label}
                    />
                );
            })}
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Loan Account Statement"
                prop={{
                    value: "Entire Loan Period",
                }}
                readOnly={localStorage.getItem("disable_form") === "true" || !shouldUpload}
                name={`${prefix}.loanAccountCredit.${index}.statement`}
                multiple={true}
                onClick={() => {
                    if (!shouldUpload) {
                        toast.dismiss();
                        toast.error(errorText?.slice(2));
                    }
                }}
                handleChange={useBSAReport({
                    name: "statement",
                    fileAddress: `${prefix}.loanAccountCredit.${index}.statement`,
                    multiple: true,
                    prefix,
                })}
                value={loan?.statement}
                showCross={localStorage.getItem("disable_form") !== "true" && loan?.statement && loan?.statement?.[0]?.link !== ""}
                handleFileModalOpen={({ position }) =>
                    setCurrentInput({ name: `${prefix}.loanAccountCredit.${index}.statement`, open: true, multiple: true, position: position })
                }
            />
        </Fragment>
    );
};

export default LoanAccountCreditItem;
