import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const AgriIncome = ({ prefix, activeFields, pane }: { prefix: string; activeFields: string[]; pane?: string }) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {fixture.map(({ name, inputType, reactHookFormRegisterOptions, editable, options, subtitle, label, dataTestId }, index) => {
                activeFields.push(name);
                return (
                    <Fragment key={index}>
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.agricultureIncome.${name}`}
                            data-testid={dataTestId || generateTestId(`${label}-agriculture-${pane}`, "field")}
                            options={
                                options?.map((value: string) => ({
                                    value,
                                    label: convertSnakeCaseToHeaderCase(value),
                                })) || []
                            }
                            label={label}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                            subtitle={subtitle}
                        />
                    </Fragment>
                );
            })}
        </Grid>
    );
};

export default AgriIncome;
