import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "name",
        label: "Name",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        infoText: "Name of trades person / professional reference",
        showLabel: false,
        textFieldVariant: "standard",
        showMask: true,
    },
    {
        name: "mobile",
        label: "Mobile",
        inputType: INPUT_TYPE.TEXT,
        placeholder: "Mobile No.",
        defaultValue: "",
        editable: true,
        showLabel: false,
        textFieldVariant: "standard",
    },
    {
        name: "landline",
        label: "Landline",
        inputType: INPUT_TYPE.TEXT,
        placeholder: "Landline No.",
        defaultValue: "",
        editable: true,
        showLabel: false,
        textFieldVariant: "standard",
    },
    {
        name: "email",
        label: "Email Address",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        placeholder: "example@gmail.com",
        defaultValue: "",
        showLabel: false,
        textFieldVariant: "standard",
    },
    {
        name: "details",
        label: "Details",
        inputType: INPUT_TYPE.TEXT,
        placeholder: "Details",
        editable: true,
        infoText: "How have you worked with the person",
        showLabel: false,
        textFieldVariant: "standard",
    },
    {
        name: "relationshipToBorrower",
        label: "Relationship to Borrower",
        inputType: INPUT_TYPE.SELECT,
        options: ["son", "daughter", "brother", "sister", "father", "mother", "grandfather", "grandmother", "supplier", OTHER],
        defaultValue: "son",
        showLabel: false,
        editable: true,
        textFieldVariant: "standard",
    },
    {
        name: "noOfYears",
        label: "Years known",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
        editable: true,
        showLabel: false,
        textFieldVariant: "standard",
        infoText: "Number of years you know the person (0-99)",
    },
    // {
    //     name: "reference",
    //     label: "Reference Check",
    //     inputType: INPUT_TYPE.TEXT,
    //     placeholder: "Reference",
    //     editable: true,
    //     defaultValue: "",
    //     showLabel: false,
    //     textFieldVariant: "standard",
    // },
    // {
    //     name: "remarks",
    //     label: "Remarks (if any)",
    //     inputType: INPUT_TYPE.TEXT,
    //     editable: true,
    //     showLabel: false,
    //     defaultValue: "",
    //     textFieldVariant: "standard",
    // },
];

export default fixture;
