import { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

interface Props {
    prefix?: string;
    pane?: string;
}

const Background = ({ prefix, pane }: Props) => {
    const background = useWatch({
        name: `${prefix}.background`,
    });
    const errors = useWatch({
        name: `errors.${prefix}.background`,
    });

    const { setValue, getValues } = useFormContext();

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.background.${item}`) === "" || getValues(`${prefix}.background.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.background.errorsCounts`, errorsCounts);
    }, [background]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="Background"
            tab="personal"
            keys={`${prefix}.background`}
            data-testid={generateTestId(`background-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" data-testid="personal__background">
                {fixture.map(({ name, inputType, options, subtitle, label, otherOptionProps }, index) => {
                    activeFields.push(name);
                    return (
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.background.${name}`}
                            key={index}
                            subtitle={subtitle}
                            data-testid={generateTestId(`${label}-${pane}`, "field")}
                            readOnly={localStorage.getItem("disable_form") === "true"}
                            options={
                                options?.map((value) => ({
                                    value: value !== OTHER ? convertSnakeCaseToHeaderCase(value) : value,
                                    label: convertSnakeCaseToHeaderCase(value),
                                })) || []
                            }
                            label={label}
                            otherOptionProps={otherOptionProps}
                        />
                    );
                })}
            </Grid>
        </CommonAccordion>
    );
};

export default Background;
