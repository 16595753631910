import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixtures } from "types";


const fixture: ICommonFixtures[] = [
    {
        name: "type",
        label: "Type",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                value: "Construction Equipment",
                label: "Construction Equipment",
            },
            {
                value: "Vehicle",
                label: "Vehicle",
            },
            {
                value: "Property",
                label: "Property",
            },
            {
                label: "OTHER",
                value: OTHER,
            },
        ],
    },
    {
        name: "value",
        label: "Value",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        editable: true,
        placeholder: "70,800",
        subtitle: "Equipment Cost",
    },
    {
        name: "propertyDetail",
        label: "Detail",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                value: "Commercial",
                label: "Commercial",
            },
            {
                value: "Residential",
                label: "Residential",
            },
            {
                value: "Industrial",
                label: "Industrial",
            },
            {
                value: "Agricultural",
                label: "Agricultural",
            },
        ],
    },
    {
        name: "propertyType",
        label: "Property Type",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        extendedOptions: {
            isCommercial: {
                options: [
                    {
                        label: "Shop",
                        value: "Shop",
                    },
                    {
                        label: "Office",
                        value: "Office",
                    },
                    {
                        label: "Shop cum Office",
                        value: "Shop cum Office",
                    },
                ],
                defaultValue: "shop",
            },
            isResidential: {
                options: [
                    {
                        label: "Independent House",
                        value: "Independent House",
                    },
                    {
                        label: "Flat",
                        value: "Flat",
                    },
                    {
                        label: "Apartment",
                        value: "Apartment",
                    },
                ],
                defaultValue: "independent_house",
            },
            isIndustrial: {
                options: [
                    {
                        label: "Factory",
                        value: "Factory",
                    },
                    {
                        label: "Warehouse",

                        value: "Warehouse",
                    },
                    {
                        label: "Port",
                        value: "Port",
                    },
                    {
                        label: "OTHER",
                        value: OTHER,
                    },
                ],
                defaultValue: "factory",
            },
            isAgricultural: {
                options: [
                    {
                        label: "Farm Land",
                        value: "Farm Land",
                    },
                    {
                        label: "Fish Farm",
                        value: "Fish Farm",
                    },
                    {
                        label: "OTHER",
                        value: OTHER,
                    },
                ],
                defaultValue: "farm_land",
            },
        },
    },
    {
        name: "landArea",
        label: "Land Area",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
    },
    {
        name: "builtArea",
        label: "Built Up Area",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
    },
    {
        name: "description",
        label: "Description",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        multiline: true,
    },
    {
        name: "manufacturer",
        label: "Manufacturer",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
    },

    {
        name: "category",
        label: "Category",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        extendedOptions: {
            isVehicle: {
                options: [
                    {
                        label: "Carrying Passenger",
                        value: "Carrying Passenger",
                    },
                    {
                        label: "Carrying Goods",
                        value: "Carrying Goods",
                    },
                    {
                        label: "3 Wheeler Vehicle",
                        value: "3 Wheeler Vehicle",
                    },
                    {
                        label: "2 Wheeler Vehicle",
                        value: "2 Wheeler Vehicle",
                    },
                    {
                        label: "Agricultural Vehicle",
                        value: "Agricultural Vehicle",
                    },
                    {
                        label: "Tractor",
                        value: "Tractor",
                    },
                    {
                        label: "Trailer",
                        value: "Trailer",
                    },
                    {
                        label: "OTHER",
                        value: OTHER,
                    },
                ],
            },
        },
    },
    {
        name: "model",
        label: "Model",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
    },
    {
        name: "yearOfManufacture",
        label: "Year of Manufacture",
        inputType: INPUT_TYPE.YEAR,
        defaultValue: "",
    },
    {
        name: "dateOfPurchase",
        label: "Date of Purchase",
        inputType: INPUT_TYPE.DATE,
        editable: true,
        defaultValue: "",
    },
    {
        name: "registrationNumber",
        label: "Registration Number",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        subtitle: "Validate RC",
    },
    {
        name: "isFinanced",
        label: "Financed",
        inputType: INPUT_TYPE.RADIO,
        editable: true,
        options: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        subtitle: "Loan Against Asset",
    },
];

export default fixture;