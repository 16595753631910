import { ACCOUNT_TYPES, BANK_NAMES, INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "accountType",
        label: "Account Type",
        inputType: INPUT_TYPE.SELECT,
        options: ACCOUNT_TYPES,
        defaultValue: "Term Loan",
        editable: false,
    },
    {
        name: "loanSecurity",
        label: "Loan Security",
        inputType: INPUT_TYPE.RADIO,
        options: ["Secured", "Unsecured"],
        defaultValue: "",
        editable: false,
    },
    {
        name: "collateralValue",
        label: "Collateral Value",
        inputType: INPUT_TYPE.PRICE,
        editable: false,
    },
    {
        name: "financedBy",
        label: "Financed by",
        inputType: INPUT_TYPE.SELECT,
        editable: false,
        options: BANK_NAMES.map((bank) => bank.name),
        showMask: true,
    },
    {
        name: "sanctionAmount",
        label: "Sanction Amount",
        inputType: INPUT_TYPE.PRICE,
        editable: false,
        subtitle: "Amount sanctioned at the time of loan approval",
    },
    {
        name: "outstandingLoanAmount",
        label: "Outstanding Loan",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        editable: false,
        subtitle: "Amount of loan remaining",
    },
    {
        name: "repaymentFrequency",
        label: "Repayment Frequency",
        inputType: INPUT_TYPE.SELECT,
        options: ["one_(1)_month", "three_(3)_month", "six_(6)_month", "twelve_(12)_month", "at_maturity_of_loan", OTHER],
        editable: false,
    },
    {
        name: "originalLoanTenorInMonths",
        label: "Original Loan Tenor",
        inputType: INPUT_TYPE.NUMBER,
        editable: false,
        defaultValue: "",
        subtitle: "Loan period in months  (0-600)",
        placeholder: "Month",
        reactHookFormRegisterOptions: {
            max: {
                value: 600,
                message: "Original Loan Tenor should be in range of 0-600",
            },
            min: {
                value: 0,
                message: "OriginalLoan Tenor should be in range of 0-600",
            },
            onChange: (e) => {
                const value = e.target.value;
                if (value > 600) {
                    let val = value.toString();
                    val = val.slice(0, -1);
                    e.target.value = parseInt(val);
                }
            },
        },
    },
    {
        name: "remainingTenorInMonths",
        label: "Remaining Loan Tenor",
        inputType: INPUT_TYPE.NUMBER,
        editable: false,
        defaultValue: "",
        subtitle: "Loan period in months (0-600)",
        placeholder: "Month",
        reactHookFormRegisterOptions: {
            max: {
                value: 600,
                message: "Remaining Loan Tenor should be in range of 0-600",
            },
            min: {
                value: 0,
                message: "Remaining OriginalLoan Tenor should be in range of 0-600",
            },
            onChange: (e) => {
                const value = e.target.value;
                if (value > 600) {
                    let val = value.toString();
                    val = val.slice(0, -1);
                    e.target.value = parseInt(val);
                }
            },
        },
    },
    {
        name: "emiAmount",
        label: "EMI Amount",
        editable: false,
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "isLoanOverdue",
        label: "Loan Overdue",
        inputType: INPUT_TYPE.RADIO,
        options: ["yes", "no"],
        editable: false,
        defaultValue: "",
        subtitle: "Is Loan Overdue ?",
    },
    {
        name: "numberOfEMIOverdue",
        label: "Overdue EMIs",
        inputType: INPUT_TYPE.NUMBER,
        editable: false,
        subtitle: "How many EMIs are overdue?",
        defaultValue: "",
        reactHookFormRegisterOptions: {
            max: {
                value: 999,
                message: "Number of EMI should be in range of 0-999",
            },
            min: {
                value: 0,
                message: "Number of EMI should be in range of 0-999",
            },
        },
    },
    {
        name: "amountOverdue",
        label: "Amount Overdue",
        editable: false,
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "reasonForNonPayment",
        editable: true,
        label: "Reason (for non-payment)",
        inputType: INPUT_TYPE.SELECT,
        options: [
            "late_disbursement",
            "complicated_terms",
            "high_interest_rate",
            "inadequate_loan",
            "illness",
            "family_contingency",
            "legal_contingency",
            "business_failure",
            "mismanagement",
            "technical_error",
            "missed_payment",
            "dispute_/_fraud",
            OTHER,
        ],
        defaultValue: "",
    },
];

export default fixture;
