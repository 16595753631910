export const fixFormValues = (values: any) => {
    if (values.errors) delete values.errors;
    if ("showIncompleteField" in values) delete values.showIncompleteField;
    if ("showIncompleteFields" in values) delete values.showIncompleteFields;
    if (values.loanApplicant) delete values.loanApplicant;
    if (values?.contact) delete values.contact;
    if (values?.followUpDate) delete values.followUpDate;
    if (values?.newFollowUpDate) delete values.newFollowUpDate;

    return values;
};
