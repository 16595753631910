import { useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import generateTestId from "utils/generateTestId";
import "./TradeReferences.scss";
import { TradeReferenceItem } from "./components";
import fixture from "./fixture";

const TradeReferences = ({ index: externalIndex }: { index: number }) => {
    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };

    const { setValue, getValues } = useFormContext();

    const tradeReferences = useWatch({ name: `references.tradeReferences.${externalIndex}` });
    const errors = useWatch({ name: `errors.references.tradeReferences.${externalIndex}` });

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (
                getValues(`references.tradeReferences.${externalIndex}.${item}`) === "" ||
                getValues(`references.tradeReferences.${externalIndex}.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.references.tradeReferences.${externalIndex}.errorsCounts`, errorsCounts);
    }, [tradeReferences]);

    return (
        <Grid className="reference-tab" container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CommonAccordion
                showError={errors?.errorsCounts > 0}
                title={` ${indexText(externalIndex)} Reference`}
                data-testid={generateTestId(`${indexText(externalIndex)} Reference`, "accordion")}
                tab="guarantor"
            >
                {fixture.map((props, internalIndex) => (
                    <TradeReferenceItem activeFields={activeFields} index={externalIndex} key={internalIndex} props={props} />
                ))}
            </CommonAccordion>
        </Grid>
    );
};

export default TradeReferences;
