import React, { useState, useEffect, Fragment, useContext } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TabsWrapperContext from "contexts/tabsWrapper";
import generateTestId from "utils/generateTestId";
import "./TabsWrapper.styles.scss";

interface ComponentProps {
    titles: string[];
    bodies: React.ReactNode[];
    pane: string;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    isCurrent: boolean;
}

const TabPanel = (props: TabPanelProps) => {
    const { children, isCurrent, index, ...other } = props;
    const [alreadyRendered, setAlreadyRendered] = useState(false);
    useEffect(() => {
        if (isCurrent) {
            setAlreadyRendered(true);
        }
    }, [index, isCurrent]);

    return (
        <div className="tab-panel" role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} hidden={!isCurrent} {...other}>
            {isCurrent || alreadyRendered ? <Fragment>{children}</Fragment> : <Fragment />}
        </div>
    );
};

function TabsWrapper({ titles, bodies, pane }: ComponentProps) {
    const { value, setValue } = useContext(TabsWrapperContext);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue((prevState: any) => {
            // @ts-ignore
            return { ...prevState, [pane]: newValue };
        });
    };

    return (
        <div id="tabs-container">
            <Box sx={{ width: "100%" }}>
                <Box sx={{ width: "80vw " }}>
                    <Tabs
                        value={value[pane]}
                        onChange={handleChange}
                        TabIndicatorProps={{ sx: { display: "none" } }}
                        sx={{
                            "& .MuiTabs-flexContainer": {
                                flexWrap: "wrap",
                            },
                        }}
                    >
                        {titles.map((item, idx) => (
                            <Tab
                                label={item.toUpperCase()}
                                key={idx}
                                className={`tab-item`}
                                // convert to lowercase and replace spaces with hyphens
                                data-testid={generateTestId(item, "pane")}
                            />
                        ))}
                    </Tabs>
                </Box>
                {bodies.map((item, idx) => (
                    <TabPanel key={idx} index={idx} isCurrent={value[pane] === idx}>
                        {item}
                    </TabPanel>
                ))}
            </Box>
        </div>
    );
}

export default TabsWrapper;
