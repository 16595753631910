import { Fragment, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER, INPUT_TYPE } from "globalConstants";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import "./LoanoutStanding.styles.scss";
import fixture from "./components/LoanTab/fixture";

type Props = {
    index: number;
    prefix: string;
    handleAppend: any;
    pane?: string;
};
const LoanOutStanding = ({ index: externalIndex, prefix, handleAppend, pane }: Props) => {
    const loan = useWatch({ name: `${prefix}.${externalIndex}` });
    const errors = useWatch({ name: `errors.${prefix}.${externalIndex}` });
    const { register, getValues, setValue } = useFormContext();
    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };
    const activeFields: string[] = ["hasAdditionalLoanOutstanding"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${externalIndex}.${item}`) === "" || getValues(`${prefix}.${externalIndex}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.${externalIndex}.errorsCounts`, errorsCounts);
    }, [loan]);

    const financedBy = useWatch({
        name: `${prefix}.${externalIndex}.financedBy`,
    });
    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            keys={`${prefix}.${externalIndex}`}
            titleReactNode={
                <CommonFields
                    inputType={INPUT_TYPE.RADIO}
                    name={`${prefix}.${externalIndex}.hasAdditionalLoanOutstanding`}
                    label={`${indexText(externalIndex)} Loan Outstanding`}
                    data-testid={generateTestId(`${indexText(externalIndex)} Loan Outstanding-${pane}`, "field")}
                    options={[
                        { label: "Yes", value: true },
                        { label: "No", value: false },
                    ]}
                    prop={{
                        value: "Do you own any Loan ?",
                    }}
                    className="common-mui-accordian__radio"
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            if (e.target.value === "true") {
                                handleAppend(externalIndex);
                            }
                        },
                    }}
                />
            }
            title={`${indexText(externalIndex)} Loan Outstanding`}
            data-testid={generateTestId(`${indexText(externalIndex)} Loan Outstanding-${pane}`, "accordion")}
            tab="income"
            showDetail={loan?.hasAdditionalLoanOutstanding === true}
            defaultExpanded={loan?.hasAdditionalLoanOutstanding === true}
        >
            {loan.hasAdditionalLoanOutstanding === true && (
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(({ name, inputType, reactHookFormRegisterOptions, editable, options, subtitle, label }, index) => {
                        if (name === "financedBy") {
                            activeFields.push(name);
                            return (
                                <Grid
                                    container
                                    className="common__mui__textfield__container dropdown_container"
                                    justifyContent="center"
                                    alignItems="center"
                                >
                                    <Grid item xs={6}>
                                        <Typography
                                            className="common__lable"
                                            data-testid={generateTestId(`${label}-loan-outstanding-${externalIndex + 1}-${pane}`, "field-label")}
                                        >
                                            {label}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            data-testid={generateTestId(`${label}-loan-outstanding-${externalIndex + 1}-${pane}`, "field")}
                                            disabled={localStorage.getItem("disable_form") === "true"}
                                            options={
                                                options?.filter((value, index) => {
                                                    if (financedBy !== "") {
                                                        if (value?.toLocaleLowerCase().includes(financedBy?.toLocaleLowerCase()))
                                                            return convertSnakeCaseToHeaderCase(value);
                                                    } else {
                                                        return convertSnakeCaseToHeaderCase;
                                                    }
                                                }) ?? []
                                            }
                                            value={financedBy}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    disabled={localStorage.getItem("disable_form") === "true"}
                                                    className="autocomplete-input"
                                                    {...register(`${prefix}.${externalIndex}.${name}`, reactHookFormRegisterOptions)}
                                                />
                                            )}
                                            className="autocomplete"
                                        />
                                    </Grid>
                                </Grid>
                            );
                        }
                        if (loan.loanSecurity !== "Secured") {
                            if (name === "collateralValue") {
                                return "";
                            }
                        }
                        if (loan?.isLoanOverdue !== true) {
                            if (name === "amountOverdue" || name === "reasonForNonPayment" || name === "numberOfEMIOverdue") {
                                return "";
                            }
                        }
                        if (loan.accountType === "Overdraft") {
                            if (
                                name === "repaymentFrequency" ||
                                name === "originalLoanTenorInMonths" ||
                                name === "remainingTenorInMonths" ||
                                name === "emiAmount" ||
                                name === "amountOverdue"
                            )
                                return "";
                        }
                        if (name === "isLoanOverdue") {
                            activeFields.push(name);
                            return (
                                <CommonFields
                                    inputType={inputType}
                                    name={`${prefix}.${externalIndex}.${name}`}
                                    data-testid={generateTestId(`${label}-loan-outstanding-${externalIndex + 1}-${pane}`, "field")}
                                    options={[
                                        {
                                            label: "Yes",
                                            value: true,
                                        },
                                        {
                                            label: "No",
                                            value: false,
                                        },
                                    ]}
                                    label={label}
                                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                    readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                    subtitle={subtitle}
                                />
                            );
                        }
                        if (
                            name === "repaymentFrequency" ||
                            name === "originalLoanTenorInMonths" ||
                            name === "remainingTenorInMonths" ||
                            name === "emiAmount"
                        ) {
                            if (loan.outstandingLoanAmount > 0 && !loan[name]) {
                                editable = true;
                            }
                        }
                        activeFields.push(name);
                        return (
                            <Fragment key={index}>
                                <CommonFields
                                    inputType={inputType}
                                    name={`${prefix}.${externalIndex}.${name}`}
                                    options={
                                        options?.map((value: string) => ({
                                            value: value !== OTHER ? convertSnakeCaseToHeaderCase(value) : value,
                                            label: convertSnakeCaseToHeaderCase(value),
                                        })) || []
                                    }
                                    label={label}
                                    data-testid={generateTestId(`${label}-loan-outstanding-${externalIndex + 1}-${pane}`, "field")}
                                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                    readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                    subtitle={subtitle}
                                />
                            </Fragment>
                        );
                    })}
                </Grid>
            )}
        </CommonAccordion>
    );
};

export default LoanOutStanding;
