import { Fragment, useState, useMemo, memo } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useSubmitLoanApplication } from "__generated__/apiComponents";
import { HandleNextDialog } from "components/Dialog";
import TabsWrapper from "components/TabsWrapper";
import { ROUTES, OTHER } from "globalConstants";
import { useMaskedFields, useSaveForm } from "hooks";
import useFeature from "hooks/useFeature";
import { useGuarantorFields, useCoborrowerFields } from "pages/Dashboard/hooks";
import DocumentPane from "./DocumentPane";

const DocumentForm = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const coborrowerFields = useCoborrowerFields();
    const guarantorFields = useGuarantorFields();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const applicant = useWatch({ name: "applicant" });

    const coborrower = useWatch({ name: "coborrower" });

    const guarantor = useWatch({ name: "guarantor" });

    const partnerDetails = useWatch({
        name: `applicant.entity.ownershipDetails.partnerDetails`,
    });

    const directorDetails = useWatch({
        name: `applicant.entity.shareholdingDirectors.directorDetails`,
    });

    let removeApplicantPersonalPane = false;
    if (applicant.entity.business === "Partnership" && partnerDetails.every((partner: any) => partner.entityType !== "applicant")) {
        removeApplicantPersonalPane = true;
    }
    if (
        applicant.entity.business === "Registered Under Company Act" &&
        directorDetails.every((director: any) => director.entityType !== "applicant")
    ) {
        removeApplicantPersonalPane = true;
    }

    const handleSave = useSaveForm();
    const handleClose = () => setOpen(false);

    const { maskField } = useMaskedFields();

    const isSubmitButtonDisabled = useFeature("disable-submit-button");

    const applicantName = useMemo(() => {
        let name = "";

        name = applicant.entity.entityName !== "" ? applicant.entity.entityName : "";

        if (name === "") {
            name = applicant.personal.personal.yourDetails.firstName !== "" ? applicant.personal.personal.yourDetails.firstName : "applicant";
        }
        return name;
    }, [applicant]);

    const getCoborrowerName = (index: number) => {
        let name = "";

        name = coborrower[index].entity.entityName !== "" ? coborrower[index].entity.entityName : "";

        if (name === "") {
            name =
                coborrower[index].personal.personal.yourDetails.firstName !== ""
                    ? coborrower[index].personal.personal.yourDetails.firstName
                    : "Co-borrower" + (index + 1);
        }
        return name;
    };
    const getGuarantorName = (index: number) => {
        let name = "";
        name = guarantor[index].entity.entityName !== "" ? guarantor[index].entity.entityName : "";

        if (name === "") {
            name =
                guarantor[index].personal.personal.yourDetails.firstName !== ""
                    ? guarantor[index].personal.personal.yourDetails.firstName
                    : "Guarantor" + (index + 1);
        }
        return name;
    };

    const applicantTitles = [
        {
            title: maskField(applicantName) + " Business",
            entityType: "applicant",
            index: 0,
            type: "business",
        },
        {
            title: maskField(applicantName) + " Personal",
            entityType: "applicant",
            index: 0,
            type: "personal",
        },
        {
            title: maskField(applicantName) + " Post Disbursal",
            entityType: "applicant",
            index: 0,
            type: "postDisbursal",
        },
    ];

    if (removeApplicantPersonalPane) applicantTitles.splice(1, 1);

    const coborrowerTitles = useMemo(() => {
        let titles = [];
        if (coborrowerFields.length === 1) {
            titles.push(
                {
                    title: maskField(getCoborrowerName(0)) + " Business",
                    entityType: "coborrower",
                    index: 0,
                    type: "business",
                },
                {
                    title: maskField(getCoborrowerName(0)) + " Personal",
                    entityType: "coborrower",
                    index: 0,
                    type: "personal",
                }
            );
        } else {
            for (let i = 0; i < coborrowerFields.length; i++) {
                titles.push(
                    {
                        title: maskField(getCoborrowerName(i)) + ` Business ${i + 1}`,
                        entityType: "coborrower",
                        index: i,
                        type: "business",
                    },
                    {
                        title: maskField(getCoborrowerName(i)) + ` Personal ${i + 1}`,
                        entityType: "coborrower",
                        index: i,
                        type: "personal",
                    }
                );
            }
        }
        return titles;
    }, [coborrowerFields]);

    const guarantorTitles = useMemo(() => {
        let titles = [];
        if (guarantorFields.length === 1) {
            titles.push(
                {
                    title: maskField(getGuarantorName(0)) + " Business",
                    entityType: "guarantor",
                    index: 0,
                    type: "business",
                },
                {
                    title: maskField(getGuarantorName(0)) + " Personal",
                    entityType: "guarantor",
                    index: 0,
                    type: "personal",
                }
            );
        } else {
            for (let i = 0; i < guarantorFields.length; i++) {
                titles.push(
                    {
                        title: maskField(getGuarantorName(i)) + ` Business ${i + 1}`,
                        entityType: "guarantor",
                        index: i,
                        type: "business",
                    },
                    {
                        title: maskField(getGuarantorName(i)) + ` Personal ${i + 1}`,
                        entityType: "guarantor",
                        index: i,
                        type: "personal",
                    }
                );
            }
        }
        return titles;
    }, [guarantorFields]);

    const titles = useMemo(() => [...applicantTitles, ...coborrowerTitles, ...guarantorTitles], [guarantorTitles, coborrowerTitles]);

    const { mutate: submitForm } = useSubmitLoanApplication({
        onSuccess: (data) => {
            handleClose();
            toast.dismiss();
            toast.success("Form Submitted Successfully");
            navigate(ROUTES.HOME_PAGE);
        },
        onError: (error) => {
            handleClose();
            toast.dismiss();
            // @ts-ignore
            toast.error(error?.stack?.message || "Something went wrong");
        },
    });
    const { getValues, setValue } = useFormContext();

    const handleSubmit = () => {
        let values = getValues();

        let requestObj: any = {
            id: localStorage.getItem("form_id"),
            data: values,
        };
        submitForm({ body: requestObj });
    };

    const { pathname } = useLocation();

    const isDisabled = useWatch({
        name: "disabled",
    });

    const handleProceed = () => {
        handleSubmit();
    };

    const handleCancel = () => {
        setOpen(false);
    };

    return (
        <Fragment>
            <Grid container id="form">
                <TabsWrapper
                    pane="document"
                    titles={[...titles.map((field) => field.title)]}
                    bodies={[...titles].map((field, index) => (
                        <div className="form-wrapper" key={index}>
                            <fieldset disabled={isDisabled} className="fieldset">
                                <DocumentPane {...field} />
                            </fieldset>
                        </div>
                    ))}
                />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(4)} data-testid="back-button">
                    <KeyboardArrowLeftIcon id="left-arrow" />
                    Back
                </Button>
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("disable_form") === "true"}
                    className="btn-arrow"
                    onClick={handleSave}
                    data-testid="save-button"
                >
                    Save
                </Button>{" "}
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("is_submit") === "true" || isSubmitButtonDisabled}
                    className="btn-arrow"
                    onClick={() => {
                        setValue("showIncompleteField", true);
                        setOpen(true);
                    }}
                    data-testid="submit-button"
                >
                    Submit
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="submit"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(DocumentForm, () => true);
