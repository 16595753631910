import { useWatch } from "react-hook-form";
import { Grid } from "@mui/material";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { convertSnakeCaseToHeaderCase } from "utils";
import convertToTitleCase from "utils/convertToTitleCase";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const RoleDetails = () => {
    const role = useWatch({ name: "contact.role" });
    const userType = useWatch({ name: "contact.userType" });
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            <CommonAccordion title={`${convertToTitleCase(role)} Details`} data-testid={generateTestId(`FI Details`, "accordion")} tab="contact">
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue }, index) => {
                        return (
                            <CommonFields
                                key={index}
                                inputType={inputType}
                                name={`contact.roleDetails.${name}`}
                                options={
                                    options?.map((value) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                label={label}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable || userType === "registered"}
                                subtitle={subtitle}
                            />
                        );
                    })}
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};

export default RoleDetails;
