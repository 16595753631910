import { LoanApplicationWorkOrderCreateInput } from "@/__generated__/apiSchemas";

const defaultWorkOrderFixture: LoanApplicationWorkOrderCreateInput = {
    id: "",
    contractorType: "Company",
    hasTender: false,
    tenderComment: "",
    advertisementChannel: "Newspaper",
    advertisementDetail: "",
    dateOfAdvertisement: "",
    contractorName: "",
    hasWorkOrder: false,
    workOrder: { link: "" },
    address: {
        address1: "",
        address2: "",
        address3: "",
        city: "",
        landmark: "",
        postalCode: "",
        state: "",
    },
};

export default defaultWorkOrderFixture;
