import React from "react";
import AddIcon from "@mui/icons-material/Add";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LoanImage1 from "assets/images/001.jpg";
import "./AddNewLoan.styles.scss";

interface AddNewLoanProps {
    handleOpen: () => void;
}

const AddNewLoan = ({ handleOpen }: AddNewLoanProps) => {
    return (
        <div id="small__card" onClick={() => handleOpen()}>
            <Box className="loan-types centered-div">
                <div className="property centered-div">
                    <img src={LoanImage1} alt="" />
                </div>
            </Box>
            <Button id="btn" variant="text" onClick={() => handleOpen()} style={{ width: "100%" }} data-testid="add-new-loan-button">
                <AddIcon /> ADD NEW LOAN
            </Button>
        </div>
    );
};

export default AddNewLoan;
