import { OTHER } from "globalConstants";

const getVehicleCategory = (value: string) => {
    switch (value) {
        case "2WN":
            return "2 Wheeler Vehicle";
            break;
        case "3WN":
            return "3 Wheeler Vehicle";
            break;
        case "2WT":
            return "2 Wheeler Vehicle";
            break;
        case "HPV":
            return "Carrying Passenger";
            break;
        case "LPV":
            return "Carrying Passenger";
            break;
        case "LGV":
            return "Carrying Goods";
            break;
        default:
            return OTHER;
    }
};

export default getVehicleCategory;
