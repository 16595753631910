import React, { Fragment, useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import Home from "./Home";

const TabPanel = (props: TabPanelProps) => {
    const { children, isCurrent, index, ...other } = props;
    const [alreadyRendered, setAlreadyRendered] = useState(false);
    useEffect(() => {
        if (isCurrent) {
            setAlreadyRendered(true);
        }
    }, [index, isCurrent]);

    return (
        <div className="tab-panel" role="tabpanel" id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} hidden={!isCurrent} {...other}>
            {isCurrent || alreadyRendered ? <Fragment>{children}</Fragment> : <Fragment />}
        </div>
    );
};

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    isCurrent: boolean;
}

const HomePage = () => {
    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const titles = ["All", "Open", "Submitted", "Processed", "Applied"];

    const bodies = [
        <Home header="all" />,
        <Home header="open" />,
        <Home header="submitted" />,
        <Home header="processed" />,
        <Home header="applied" />,
    ];

    return (
        <Fragment>
            <Navbar />
            <Grid container justifyContent="center" alignItems="center">
                <div id="tabs-container">
                    <Box sx={{ width: "100%" }}>
                        <Box>
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                TabIndicatorProps={{ sx: { display: "none" } }}
                                sx={{
                                    "& .MuiTabs-flexContainer": {
                                        flexWrap: "wrap",
                                    },
                                }}
                            >
                                {titles.map((item, idx) => (
                                    <Tab
                                        label={item}
                                        key={idx}
                                        className={`tab-item`}
                                        // convert to lowercase and replace spaces with hyphens
                                        data-testid={`${titles[idx].toLowerCase().replace(/\s/g, "-")}__tab`}
                                    />
                                ))}
                            </Tabs>
                        </Box>
                        {bodies.map((item, idx) => (
                            <TabPanel key={idx} index={idx} isCurrent={value === idx}>
                                {item}
                            </TabPanel>
                        ))}
                    </Box>
                </div>
            </Grid>
            <Footer />
        </Fragment>
    )
}

export default HomePage
