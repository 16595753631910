import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { Personal, Background, PersonalAddress } from "..";

const SoleProprietorship = ({ prefix, entityAadharValidated, pane }: { prefix: string; entityAadharValidated: boolean; pane?: string }) => {
    const isDisabled = useWatch({
        name: "disabled",
    });

    return (
        <fieldset className="fieldset" disabled={isDisabled}>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <Personal prefix={prefix} entityAadharValidated={entityAadharValidated} pane={pane} />
                <Background prefix={prefix} pane={pane} />
                <PersonalAddress prefix={prefix} pane={pane} />
            </Grid>
        </fieldset>
    );
};

export default SoleProprietorship;
