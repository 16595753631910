import { useEffect, useMemo, useState } from "react";
import { useGetAllCompanies } from "__generated__/apiComponents";
import { sortCompanyNames } from "utils";
import useError from "./useError";

const useCompanyNames = () => {
    const [companyName, setCompanyName] = useState<string>();
    const [companyType, setCompanyType] = useState<"Company" | "LLP">("Company");
    const showError = useError();
    const {
        data,
        mutate: getCompany,
        isLoading,
    } = useGetAllCompanies({
        onError: showError,
    });

    const companyNames: { label: string; id: string }[] = useMemo(() => {
        //@ts-ignore
        if (data?.length > 0) {
            //@ts-ignore
            return sortCompanyNames(data)?.map((item: any, index: number) => {
                return {
                    label: item?.company_name,
                    id: item?.cin,
                };
            });
        } else {
            return [];
        }
    }, [data]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (companyName && companyName?.length > 2) {
                getCompany({
                    body: {
                        onlyCompanyNames: true,
                        //@ts-ignore
                        filters: {
                            field: [
                                {
                                    field: "company_name",
                                    type: "contains",
                                    value: companyName,
                                },
                                {
                                    field: "company_type",
                                    type: "equals",
                                    value: companyType,
                                },
                            ],
                        },
                    },
                });
            }
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [companyName, companyType]);

    useEffect(() => {
        if (companyName === "")
            getCompany({
                body: {
                    onlyCompanyNames: true,
                    //@ts-ignore
                    filters: {
                        field: [
                            {
                                field: "company_name",
                                type: "contains",
                                value: "aa",
                            },
                            {
                                field: "company_type",
                                type: "equals",
                                value: companyType,
                            },
                        ],
                    },
                },
            });
    }, [companyName, companyType]);

    //@ts-ignore
    return { companyNames, data, setCompanyName, setCompanyType, isCompaniesLoading: isLoading };
};

export default useCompanyNames;
