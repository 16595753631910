interface loanAccount {
    statement: any;
    bankName: string;
    bankCode: string;
    accountType: string;
    accountNumber: string;

    emiReceipt?: any;
}

const fixture: loanAccount = {
    statement: [
        {
            link: "",
        },
    ],
    bankName: "",
    bankCode: "",

    accountType: "",
    accountNumber: "",
    emiReceipt: [
        {
            link: "",
        },
    ],
};

export default fixture;
