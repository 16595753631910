import { Fragment, useEffect } from "react";
import { useAuthUser } from "react-auth-kit";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { useMaskedFields } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import formatLoanAccountFIName from "utils/formatLoanAcccountFIName";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const LoanFromAccount = ({ index: externalIndex, prefix, pane }: { index: number; prefix: string; pane: string }) => {
    const loan = useWatch({ name: `${prefix}.${externalIndex}` });
    const errors = useWatch({ name: `errors.${prefix}.${externalIndex}` });
    const accountType = loan.accountType.length > 20 ? loan.accountType.slice(0, 20) + "..." : loan.accountType;

    const { setValue, getValues } = useFormContext();
    const { maskField } = useMaskedFields();

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${externalIndex}.${item}`) === "" || getValues(`${prefix}.${externalIndex}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.${externalIndex}.errorsCounts`, errorsCounts);
    }, [loan]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            key={loan?.id}
            keys={`${prefix}.${externalIndex}`}
            title=""
            data-testid={generateTestId(`loan-account-${externalIndex + 1}-${pane}`, "accordion")}
            titleReactNode={
                <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                    <Typography className="common-custom-label">{maskField(formatLoanAccountFIName(loan.financedBy))}</Typography>
                    <Typography className="common-custom-label">{accountType}</Typography>
                </Grid>
            }
            tab="personal"
            className="common-accordion-field"
        >
            <Grid container item xs={12} direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, subtitle, label, editable, showMask }, index) => {
                    if (loan?.isLoanOverdue !== true) {
                        if (name === "amountOverdue" || name === "reasonForNonPayment" || name === "numberOfEMIOverdue") {
                            return "";
                        }
                    }
                    if (loan.typeOfLoan === "Overdraft") {
                        if (
                            name === "repaymentFrequency" ||
                            name === "originalLoanTenorInMonths" ||
                            name === "remainingTenorInMonths" ||
                            name === "emiAmount" ||
                            name === "amountOverdue"
                        )
                            return "";
                    }
                    if (loan.loanSecurity !== "Secured") {
                        if (name === "collateralValue") {
                            return "";
                        }
                    }
                    if (name === "isLoanOverdue") {
                        return (
                            <CommonFields
                                inputType={inputType}
                                name={`${prefix}.${externalIndex}.${name}`}
                                options={[
                                    {
                                        label: "Yes",
                                        value: true,
                                    },
                                    {
                                        label: "No",
                                        value: false,
                                    },
                                ]}
                                label={label}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                readOnly={true}
                                subtitle={subtitle}
                            />
                        );
                    }

                    if (
                        name === "repaymentFrequency" ||
                        name === "originalLoanTenorInMonths" ||
                        name === "remainingTenorInMonths" ||
                        name === "emiAmount"
                    ) {
                        if (loan.outstandingLoanAmount > 0 && !loan[name]) {
                            editable = true;
                        }
                    }
                    return (
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.${externalIndex}.${name}`}
                            showMask={showMask}
                            options={
                                options?.map((value: string) => ({
                                    value: value,
                                    label: convertSnakeCaseToHeaderCase(value),
                                })) || []
                            }
                            label={label}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                            subtitle={subtitle}
                        />
                    );
                })}
            </Grid>
        </CommonAccordion>
    );
};

export default LoanFromAccount;
