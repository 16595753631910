import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";
import { convertSnakeCaseToHeaderCase } from "utils";

//
const fixture: ICommonFixture[] = [
    {
        name: "newOrUsed",
        label: "New or Used",
        inputType: INPUT_TYPE.SELECT,
        options: ["new", "used"],
        editable: true,
        defaultValue: "new",
        dataTestId: "new-or-used-field",
    },
    {
        name: "registrationNumber",
        label: "Registration Number",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Validate RC",
        dataTestId: "registration-number-field",
    },
    {
        name: "manufacturer",
        label: "Manufacturer",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: ["JCB", "TATA HITACHI", OTHER],
        defaultValue: "JCB",
        labelGenerator: (value) => (value === "JCB" ? convertSnakeCaseToHeaderCase(value) : convertSnakeCaseToHeaderCase(value)),
        otherOptionProps: {
            subtitle: "Name of Manufacturar",
        },
        dataTestId: "manufacturer-field",
        showMask: true,
    },

    {
        name: "category",
        label: "Category",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        defaultValue: "BACKHOE_LOADERS",
        otherOptionProps: {
            subtitle: "Machine Category",
        },
        getOptions: ({ manufacturer }) => {
            switch (manufacturer) {
                case "JCB":
                    return [
                        "BACKHOE_LOADERS",
                        "COMPACTORS",
                        "EXCAVATORS",
                        "GENSETS",
                        "MINI_EXCAVATORS",
                        "SKID_STEER_LOADERS",
                        "SUPER_LOADERS",
                        "TELEHANDLERS",
                        "WHEELED_LOADERS",
                        OTHER,
                    ];
                case "TATA HITACHI":
                    return ["BACKHOE_LOADERS", "EXCAVATORS", OTHER];
                case OTHER:
                    return [
                        "BACKHOE_LOADERS",
                        "COMPACTORS",
                        "EXCAVATORS",
                        "GENSETS",
                        "MINI_EXCAVATORS",
                        "PICK_&_CARRY_CRANES",
                        "SKID_STEER_LOADERS",
                        "SUPER_LOADERS",
                        "TELEHANDLERS",
                        "WHEELED_LOADERS",
                        OTHER,
                    ];
                default:
                    return [];
            }
        },
        dataTestId: "category-field",
        showMask: true,
    },
    {
        name: "model",
        label: "Model",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        otherOptionProps: {
            subtitle: "Machine Model",
        },
        dataTestId: "model-field",
        showMask: true,
    },
    {
        name: "sellerName",
        label: "Seller Name",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["leo_earthmovers", OTHER],
        getOptions({ manufacturer }) {
            switch (manufacturer) {
                case "JCB":
                    return ["Varun Motors", "Gold Fields", "MGB Motors & Auto Agencies Pvt. Ltd."];
                case "TATA_HITACHI":
                    return ["SAI SRK MOTORS", "RAMA EXCAVATORS", "SARASWATHI MARKETING & SERVICE"];

                default:
                    return [];
            }
        },
        readOnly: false,
        subtitle: "Purchased From",
        defaultValue: "",
        dataTestId: "seller-name-field",
        showMask: true,
    },
    {
        name: "salesAgent.fullName",
        label: "Sales Agent",
        inputType: INPUT_TYPE.SELECT,
        options: [],
        subtitle: "CE Sales Agent, if any",
        defaultValue: "",
        dataTestId: "sales-agent-field",
    },
    {
        name: "noOfUnits",
        label: "No. of Units",
        inputType: INPUT_TYPE.NUMBER,
        placeholder: "1",
        infoText: "No. of machines being purchased for this model",
        className: "short-input-field",
        dataTestId: "no-of-units-field",
    },
    {
        name: "basicPriceOrInvoiceValue",
        label: "Basic Price or Invoice Value",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "gst",
        label: "GST",
        inputType: INPUT_TYPE.PRICE,
        placeholder: "5,40,000",
        className: "common__mui__textfield-grey",
        readOnly: true,
        disableReactHookForm: true,
        dataTestId: "gst-field",
    },
    {
        name: "tcsPercentage",
        label: "TCS",
        inputType: INPUT_TYPE.PRICE,
        placeholder: "35,000",
        readOnly: true,
        className: "common__mui__textfield-grey",
        disableReactHookForm: true,
        dataTestId: "tcs-field",
    },
    {
        name: "ownMarginOrLtv",
        label: "Own Margin or LTV",
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        name: "tenor",
        label: "Tenor",
        inputType: INPUT_TYPE.NUMBER,
        placeholder: "Month",
        subtitle: "Loan period in Months",
        required: true,
        reactHookFormRegisterOptions: {
            max: {
                value: 60,
                message: "* Tenor cannot be greater than 60",
            },
        },
        dataTestId: "tenor-field",
    },
    {
        name: "manufacturerDate",
        label: "Manufacture date",
        inputType: INPUT_TYPE.DATE,
        placeholder: "Date",
        dataTestId: "manufacture-date-field",
    },
];

export default fixture;
