import { useState, useCallback } from "react";
import { useSignOut } from "react-auth-kit";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useVerifyAadhaar } from "__generated__/apiComponents";
import { verifyAadhar } from "api";
import { ROUTES } from "globalConstants";

const useAadharVerify = () => {
    const navigate = useNavigate();
    const signOut = useSignOut();
    const [aadhar, setAadhar] = useState("");
    const [aadharOtp, setAadharOtp] = useState("");
    const [aadharDetails, setAadharDetails] = useState(null);
    const formId = localStorage.getItem("form_id");
    const [aadharVerified, setAadharVerified] = useState(false);
    const { mutate: verify } = useVerifyAadhaar({
        onSuccess: (data: any) => {
            localStorage.setItem("client_id", data?.client_id);
            if (data) {
                toast.dismiss();
                toast.success("OTP Sent Successfully");
                handleOpen();
            }
        },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.payload??"");
            if (error?.payload === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });
    const { mutate: verifyOTP } = useVerifyAadhaar({
        onSuccess: (data: any) => {
            if (data) {
                toast.dismiss();
                toast.success("OTP Verified Successfully");
                setAadharDetails(data?.data);
                setAadharVerified(true);
                handleClose();
            }
        },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.payload??"");
            if (error?.payload === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });
    const handleAadharValidate = useCallback(() => {
        if (formId && aadhar.length > 0) {
            toast.dismiss();
            toast.loading("Verifying Aadhar...");
            verify({
                body: {
                    aadhaar: aadhar,
                    loanApplicationId: formId,
                    generateOtp: true,
                },
            });
        }
    }, [aadhar, formId, verify]);
    const [open, setOpen] = useState(false);
    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };
    const handleOtpSubmit = () => {
        if (aadharOtp) {
            if (aadharOtp.length !== 4) {
                toast.dismiss();
                toast.error("OTP should be 4 digits");
                return;
            }
            toast.dismiss();
            toast.loading("Verifying OTP...");
            verifyOTP({
                body: {
                    submitOtp: true,
                    clientId: localStorage.getItem("client_id") || "",
                    otp: aadharOtp,
                    loanApplicationId: formId || "",
                },
            });
        }
    };
    return {
        handleOtpSubmit,
        open,
        handleClose,
        handleOpen,
        aadharVerified,
        handleAadharValidate,
        aadhar,
        setAadhar,
        aadharOtp,
        setAadharOtp,
        setAadharVerified,
        aadharDetails,
    };
};

export default useAadharVerify;
