import { CoborrowerOrGuarantorCreateInput, RegistrationDetailsCreateInput } from "__generated__/apiSchemas";
import { ICoborrower } from "types";
import defaultWorkOrderFixture from "./defaultWorkOrderFixture";

const fixture: CoborrowerOrGuarantorCreateInput = {
    entity: {
        business: "Sole Proprietor",
        otherBusiness: "",
        entityName: "",
        certificateOfIncorporation: "",
        relationship: "Son",
        otherRelationship: "",

        aadhar: "",
        aadharValidated: false,
        gst: "",
        gstValidated: false,
        pan: "",
        panValidated: false,
        liabilityType: "Limited",
        incorporationType: "Public",
        dateOfEstablishment: "",
        proprietorName: "",
        managementDetails: {
            noOfPersons: 1,
            names: [" "],
        },
        shareholdingDirectors: {
            noOfDirectors: 1,
            directorDetails: [
                {
                    name: "",
                    sharePercentage: 0,
                },
            ],
        },
        ownershipDetails: {
            noOfPartners: 1,
            partnerDetails: [
                {
                    name: "",
                    sharePercentage: 0,
                },
            ],
        },
        businessExperience: {
            kindOfExperience: [],
            industryExperienceInYears: 0,
            operatorExperienceInYears: 0,
            supervisorExperienceInYears: 0,
            ownerExperienceInYears: 0,
            otherExperience: "",
            otherExperienceInYears: 0,
            businessActivity: [],
            customers: [],
            noOfCustomers: 0,
        },
        creditBureau: {
            bureauName: "",
            creditReportLink: "",
            report: {
                link: "",
                upload_date: "",
                filename: "",

                year_index: 0,
            },
            concentShared: false,
            cibilScore: 0,
            cibilReport: {},
            scrapedData: {},
        },
        residentialAddress: {
            address1: "",
            address2: "",
            address3: "",
            landmark: "",

            postalCode: "",
            city: "",
            state: "",
            lengthOfStayInYears: 0,
            lengthOfCityStayInYears: 0,
            ownership: "Owned by self",
            rentType: "Monthly Rent PM",
            // in case of rentType === "Monthly Rent PM"
            monthlyRent: 0,
            // in case of rentType === "Owner Name"
            ownerDetails: {
                prefix: "",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
        },

        registeredAddress: {
            address1: "",
            address2: "",
            address3: "",
            landmark: "",
            postalCode: "",
            city: "",
            state: "",
            lengthOfStayInYears: 0,
            lengthOfCityStayInYears: 0,
            ownership: "Owned by self",
            rentType: "Monthly Rent PM",
            // in case of rentType === "Monthly Rent PM"
            monthlyRent: 0,
            // in case of rentType === "Owner Name"
            ownerDetails: {
                prefix: "",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
            isSameAsResidentialAddress: true,
        },

        businessAddress: {
            address1: "",
            address2: "",
            address3: "",
            landmark: "",
            postalCode: "",
            city: "",
            state: "",
            lengthOfStayInYears: 0,
            lengthOfCityStayInYears: 0,
            ownership: "Owned by self",
            rentType: "Monthly Rent PM",
            // in case of rentType === "Monthly Rent PM"
            monthlyRent: 0,
            // in case of rentType === "Owner Name"
            ownerDetails: {
                prefix: "",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
            isSameAsRegisteredAddress: true,
        },

        mailingAddress: {
            address1: "",
            address2: "",
            address3: "",
            landmark: "",
            postalCode: "",
            city: "",
            state: "",
            isSameAsBusinessAddress: true,
        },
    },
    income: {
        income: {
            businessIncome: {
                hasBusinessIncome: false,
                sales: "0",
                hasFiledIncomeTaxReturn: false,
                incomeTaxPassword: "",
                hasProfitLossStatement: false,
                hasBalanceSheet: false,
            },
            incomeStatement: {
                expense: "0",

                depreciation: "0",
                financingCost: "0",
            },
            employmentIncome: {
                hasEmploymentIncome: false,
                certificateOfIncorporation: {},
                income: "0",
                companyName: "",
                sector: "Private",
                businessConstitution: "Sole Proprietor",

                otherBusinessConstitution: "",
                employerName: "",
                designation: "",
                otherDesignation: "",
                startOfEmployment: "",
                doYouPayIncomeTax: false,
                // in case of businessConstitution === "Partnership"
                liabilityType: "Limited",
                noOfPartners: 0,
                // in case of businessConstitution === "Registered under Company Act"
                incorporationType: "Public",
                noOfDirectors: 0,
            },
            agricultureIncome: {
                hasAgricultureIncome: false,
                income: "0",

                activityType: "",
                otherActivityType: "",
                ownUnderlyingAsset: "",
            },
            rentalIncome: {
                hasRentalIncome: false,
                income: "0",
                propertyDetail: "",
                propertyType: "",
                otherPropertyType: "",
                ownUnderlyingAsset: "",
            },
            investmentIncome: {
                hasInvestmentIncome: false,
                income: "0",
                ownUnderlyingAsset: "",
            },
        },
        loanFromAccount: [],
        asset: [
            {
                emiAmount: 0,
                sanctionAmount: 0,
                hasAsset: false,
                outstandingLoanAmount: 0,
                typeOfLoan: "",
                repaymentFrequency: "",
                remainingLoanTenorInMonths: 0,
                originalLoanTenorInMonths: 0,
                type: "Construction Equipment",
                value: "0",
                filledExcel: {
                    link: "",
                    filename: "",
                    year_index: 0,
                },
                propertyType: "Shop",
                propertyDetail: "Commercial",
                manufacturer: "JCB",
                otherManufacturer: "",
                category: "Electric Scissors",
                otherCategory: "",
                model: "S1530E ELECTRIC SCISSOR",
                otherModel: "",
                description: "",
                landArea: {
                    value: 0,
                    unit: "Sq Foot",
                },
                builtUpArea: {
                    value: 0,
                    unit: "Sq Foot",
                },
                yearOfManufacture: "",
                registrationNumber: "",
                registrationNumberVerified: false,
                isFinanced: false,
                financedBy: "",
                dateOfPurchase: "",
            },
        ],

        loanOutstanding: [
            {
                hasAdditionalLoanOutstanding: false,

                accountType: "Term Loan",
                accountNumber: "",
                bankName: "",
                financedBy: "",
                otherFinancedBy: "",

                outstandingLoanAmount: "0",

                repaymentFrequency: "",
                otherRepaymentFrequency: "",

                originalLoanTenorInMonths: 0,
                remainingTenorInMonths: 0,
                emiAmount: "0",
                isLoanOverdue: false,
                amountOverdue: "0",
                numberOfEMIOverdue: 0,
                reasonForNonPayment: "",
            },
        ],
    },
    personal: {
        personal: {
            yourDetails: {
                prefix: "Mr.",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
                dateOfBirth: new Date().toLocaleDateString(),
                maritalStatus: "Single",
                noOfDependents: 0,
                // if maritalStatus married
                noOfChildren: 0,
            },
            fatherDetails: {
                prefix: "Mr.",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
            motherDetails: {
                prefix: "Mrs.",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
            spouseDetails: {
                prefix: "Mr.",
                otherPrefix: "",
                firstName: "",
                middleName: "",
                lastName: "",
            },
            background: {
                educationQualification: "Junior High School (10th Class)",
                otherEducationQualification: "",
                profession: "Practicing Doctor",
                otherProfession: "",
                ownerOrEmployed: "Owner",
                caste: "General",
                otherCaste: "",
                subCaste: "",
                religion: "Buddhist",
                otherReligion: "",
                physicalDisability: "No",
            },
            residentialAddress: {
                address1: "",

                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                // in case of rentType === "Monthly Rent PM"
                monthlyRent: 0,
                // in case of rentType === "Owner Name"
                ownerDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
            },
            permanentAddress: {
                isSameAsResidentialAddress: true,
                address1: "",
                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                // in case of rentType === "Monthly Rent PM"
                monthlyRent: 0,
                // in case of rentType === "Owner Name"
                ownerDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
            },
        },
        partners: [
            {
                yourDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: new Date().toLocaleDateString(),
                    maritalStatus: "Single",
                    noOfDependents: 0,
                    // if maritalStatus married
                    noOfChildren: 0,
                },
                fatherDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                motherDetails: {
                    prefix: "Mrs.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                spouseDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                background: {
                    educationQualification: "Junior High School (10th Class)",
                    otherEducationQualification: "",
                    profession: "Practicing Doctor",
                    otherProfession: "",
                    ownerOrEmployed: "Owner",
                    caste: "General",
                    otherCaste: "",
                    subCaste: "",
                    religion: "Buddhist",
                    otherReligion: "",
                    physicalDisability: "No",
                },
                residentialAddress: {
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",

                    city: "",
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                permanentAddress: {
                    isSameAsResidentialAddress: true,
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                kyc: {
                    aadhar: "",
                    aadharValidated: false,

                    pan: "",
                    panValidated: false,
                },
                creditBureau: {
                    bureauName: "Equifax",
                    creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                    report: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    concentShared: false,
                    cibilScore: 0,
                    cibilReport: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    scrapedData: {
                        credit_score: 0,
                        accounts: [],
                    },
                    excelURL: "",
                },
            },
        ],
        directors: [
            {
                yourDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: new Date().toLocaleDateString(),
                    maritalStatus: "Single",
                    noOfDependents: 0,
                    // if maritalStatus married
                    noOfChildren: 0,
                },
                fatherDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                motherDetails: {
                    prefix: "Mrs.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                spouseDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                background: {
                    educationQualification: "Junior High School (10th Class)",
                    otherEducationQualification: "",
                    profession: "Practicing Doctor",
                    otherProfession: "",
                    ownerOrEmployed: "Owner",
                    caste: "General",
                    otherCaste: "",

                    subCaste: "",
                    religion: "Buddhist",
                    otherReligion: "",
                    physicalDisability: "No",
                },
                residentialAddress: {
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",

                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                permanentAddress: {
                    isSameAsResidentialAddress: true,
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                kyc: {
                    aadhar: "",
                    aadharValidated: false,

                    pan: "",
                    panValidated: false,
                },
                creditBureau: {
                    bureauName: "Equifax",
                    creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                    report: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    concentShared: false,
                    cibilScore: 0,
                    cibilReport: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    scrapedData: {
                        credit_score: 0,
                        accounts: [],
                    },
                    excelURL: "",
                },
            },
        ],
        persons: [
            {
                yourDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: new Date().toLocaleDateString(),
                    maritalStatus: "Single",
                    noOfDependents: 0,
                    // if maritalStatus married
                    noOfChildren: 0,
                },
                fatherDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                motherDetails: {
                    prefix: "Mrs.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                spouseDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                background: {
                    educationQualification: "Junior High School (10th Class)",
                    otherEducationQualification: "",
                    profession: "Practicing Doctor",
                    otherProfession: "",
                    ownerOrEmployed: "Owner",
                    caste: "General",
                    otherCaste: "",
                    subCaste: "",
                    religion: "Buddhist",
                    otherReligion: "",
                    physicalDisability: "No",
                },
                residentialAddress: {
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                permanentAddress: {
                    isSameAsResidentialAddress: true,
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                },
                kyc: {
                    aadhar: "",
                    aadharValidated: false,

                    pan: "",
                    panValidated: false,
                },
                creditBureau: {
                    bureauName: "Equifax",
                    creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                    report: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    concentShared: false,
                    cibilScore: 0,
                    cibilReport: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    scrapedData: {
                        credit_score: 0,
                        accounts: [],
                    },
                    excelURL: "",
                },
            },
        ],
    },
    document: {
        personal: {
            personal: [
                {
                    pan: { link: "" },
                    addressProof: { link: "" },
                    recentPhoto: { link: "" },
                    loanAccountCredit: [],
                },
            ],
        },
        business: {
            loanAccountCredit: [],
            loanAccountAdditional: [
                {
                    statement: [{ link: "" }],
                    bankName: "",
                    accountType: "",
                    accountNumber: "",
                    emiReceipt: [{ link: "" }],
                },
            ],
            bankAccount: [
                {
                    statement: [{ link: "" }],
                    bankName: "",
                    bankCode: "",
                    accountType: "",
                    accountNumber: "",
                },
            ],
            workOrder: [],
        },
    },
};
const data: RegistrationDetailsCreateInput = {
    blacklist_status: "NA",
    body_type: "FULLY BUILD",
    challan_details: "null",
    client_id: "rc_KLkRdzpwozkypQeavkkV",
    color: "PEARL AMAZING WHITE",
    cubic_capacity: "109.2",
    father_name: "BABU DOE",
    financed: true,
    financer: "NA",
    fit_up_to: "2030-11-17",
    fuel_type: "PETROL",
    insurance_company: "ICICI Lombard General Insurance Co. Ltd.",
    insurance_policy_number: "3005/210113792/00/000",
    insurance_upto: "2021-11-29",
    latest_by: "2021-10-26",
    less_info: false,
    maker_description: "HONDA MOTORCYCLE AND SCOOTER INDIA (P) LTD",
    maker_model: "ACTIVA 3G WITH ELECTRIC AUTO",
    manufacturing_date: "11/2015",
    masked_name: false,
    mobile_number: "",
    national_permit_issued_by: "",
    national_permit_number: "",
    national_permit_upto: "1900-01-01",
    no_cylinders: "1",
    noc_details: "NA",
    non_use_from: "1900-01-01",
    non_use_status: "null",
    non_use_to: "1900-01-01",
    norms_type: "BHARAT STAGE III",
    owner_name: "JOHN DOE",
    owner_number: "1",
    permanent_address: "JOHN DOE HOMES, New Delhi -110043",
    permit_issue_date: "1900-01-01",
    permit_number: "",
    permit_type: "",
    permit_valid_from: "1900-01-01",
    permit_valid_upto: "1900-01-01",
    present_address: "JOHN DOE HOMES, New Delhi -110043",
    pucc_number: "P569061234",
    pucc_upto: "2021-01-07",
    rc_number: "DL2SN1234",
    rc_status: "ACTIVE",
    registered_at: "I P ESTATE, Delhi",
    registration_date: "2015-11-18",
    seat_capacity: "2",
    sleeper_capacity: "0",
    standing_capacity: "0",
    tax_paid_upto: "LTT",
    tax_upto: "1900-01-01",
    unladen_weight: "108",
    variant: "null",
    vehicle_category: "2WN",
    vehicle_category_description: "M-Cycle/Scooter(2WN)",
    vehicle_chasi_number: "ME4JF504LF8123456",
    vehicle_engine_number: "JF50E82312345",
    vehicle_gross_weight: "269",
    wheelbase: "1238",
};

export default fixture;
