import { Fragment, memo, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { HandleNextDialog } from "components/Dialog";
import TabsWrapper from "components/TabsWrapper";
import { useSaveForm } from "hooks";
import { PersonalPane } from "./components";
import { usePersonalTabFields } from "./hooks";

const PersonalTab = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const personalTabFields = usePersonalTabFields();

    const { setValue } = useFormContext();

    const errors = useWatch({
        name: "errors",
    });

    const hasCoBorrower = useWatch({
        name: "requirement.hasCoBorrower",
    });
    const hasGuarantor = useWatch({
        name: "requirement.hasGuarantor",
    });

    const [open, setOpen] = useState(false);

    const handleSave = useSaveForm();

    const handleProceed = () => {
        setCurrentStep(5);
    };

    const handleCancel = () => {
        setOpen(false);
    };
    const handleNext = () => {
        let shouldProceed = true;
        for (let header of ["applicant", "guarantor", "coborrower"]) {
            if (header === "applicant") {
                const headerErrors = errors[header]?.personal?.personal;
                if (headerErrors?.errorsCounts > 0) shouldProceed = false;
                for (let i in headerErrors) {
                    if (headerErrors[i]?.errorsCounts > 0) {
                        shouldProceed = false;
                    }
                }
            } else if (hasCoBorrower === true || hasGuarantor === true) {
                errors[header]?.forEach((error: any) => {
                    const headerErrors = error?.personal?.personal;
                    if (headerErrors?.errorsCounts > 0) shouldProceed = false;
                    for (let i in headerErrors) {
                        if (headerErrors[i]?.errorsCounts > 0) {
                            shouldProceed = false;
                        }
                    }
                });
            }
        }
        if (shouldProceed) handleProceed();
        else {
            setValue("showIncompleteField", true);
            setOpen(true);
        }
    };

    return (
        <Fragment>
            <Grid container id="form">
                <TabsWrapper
                    pane="personal"
                    titles={[...personalTabFields.map((field) => field.title)]}
                    bodies={[...personalTabFields].map((field) => (
                        <div className="form-wrapper">
                            <PersonalPane {...field} />
                        </div>
                    ))}
                />
            </Grid>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(3)} data-testid="back-button">
                    <KeyboardArrowLeftIcon id="left-arrow" />
                    Back
                </Button>
                <Button
                    variant="contained"
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={handleSave}
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
                <Button variant="contained" className="btn-arrow" onClick={handleNext} data-testid="next-button">
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(PersonalTab, () => true);
