import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DeleteForeverOutlinedIcon from "assets/images/delete.svg";
import { useMaskedFields } from "hooks";
import maskField from "utils/maskField";
import "./EntityCard.styles.scss";

interface Prop {
    form_id: string;
    is_submit: boolean;
}

interface EntityCardProps {
    handleOpenResume: () => void;
    setApplication: (value: Prop) => void;
    entity_name: string;
    loan_type: string;
    loan_amt: string;
    completeness: number;
    isSubmit: boolean;
    isDisabled: boolean;
    formId: string;
    handleOpenDelete: () => void;
    index: number;
    isMasked: boolean;
}

const EntityCard = ({
    handleOpenResume,
    setApplication,
    handleOpenDelete,
    entity_name,
    loan_type,
    loan_amt,
    completeness,
    formId,
    isSubmit,
    isDisabled,
    index,
    isMasked,
}: EntityCardProps) => {
    const { isAuthMasked } = useMaskedFields();
    return (
        <div
            id="small__card"
            onClick={() => {
                setApplication({
                    form_id: formId,
                    is_submit: isSubmit,
                });
            }}
        >
            <Grid justifyContent="flex-start" className="details">
                <Typography component="h5">
                    Entity Name : <span>{maskField(entity_name, isAuthMasked && isMasked)}</span>
                </Typography>
                <Typography component="h5">
                    Loan Type : <span>{loan_type}</span>
                </Typography>
                <Typography component="h5">
                    {loan_type !== "NA" && (
                        <>
                            {loan_type} : <span>{loan_amt}</span>
                        </>
                    )}
                </Typography>
            </Grid>
            <Grid container direction="row" className="progress">
                <Grid container direction="column" className="progress-bar" style={{ width: `${completeness}%` }}>
                    {completeness}%
                </Grid>
            </Grid>
            <Grid container justifyContent={`${isSubmit ? "center" : "space-around"}`} alignItems="center">
                {!isSubmit && (
                    <Button
                        id="btn"
                        className="btn delete"
                        variant="text"
                        onClick={() => {
                            handleOpenDelete();
                        }}
                        data-testid={`delete-loan-${formId}-button`}
                    >
                        <img src={DeleteForeverOutlinedIcon} className="delete-icon" /> DELETE
                    </Button>
                )}

                <Button
                    id="btn"
                    className="btn"
                    variant="text"
                    onClick={() => {
                        handleOpenResume();
                    }}
                    data-testid={`resume-loan-${formId}-button`}
                >
                    <InsertDriveFileOutlinedIcon />
                    {isDisabled && isSubmit ? "VIEW" : "RESUME"}
                </Button>
            </Grid>
        </div>
    );
};

export default EntityCard;
