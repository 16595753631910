import { Fragment, memo, useState, useEffect } from "react";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { HandleNextDialog } from "components/Dialog";
import { INPUT_TYPE } from "globalConstants";
import { useSaveForm } from "hooks";
import generateTestId from "utils/generateTestId";
import "./RequirementForm.styles.scss";
import { ConstructionEquipment, AddAnotherEquipment, Equipment, Loan, MachineUsage, Vehicle, AddAnotherVehicle } from "./components";

const RequirementForm = ({ setCurrentStep }: { setCurrentStep?: any }) => {
    const { setValue, getValues, control } = useFormContext();

    const [open, setOpen] = useState(false);

    const loanType = useWatch({
        name: "requirement.loanType",
    });

    const {
        fields: vehicleFields,
        append: appendVehicle,
        remove: removeVehicle,
    } = useFieldArray({
        name: "requirement.vehicles",
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "requirement.equipments",
    });

    const hasCoBorrower = useWatch({
        name: "requirement.hasCoBorrower",
    });
    const hasGuarantor = useWatch({
        name: "requirement.hasGuarantor",
    });
    const isDisabled = useWatch({
        name: "disabled",
    });

    const requirement = useWatch({
        name: "requirement",
    });

    const entityBusiness = useWatch({
        name: "applicant.entity.business",
    });

    const directorDetails = useWatch({
        name: "applicant.entity.shareholdingDirectors.directorDetails",
    });
    const partnerDetails = useWatch({
        name: "applicant.entity.ownershipDetails.partnerDetails",
    });
    const errors = useWatch({
        name: "errors.requirement",
    });

    const handleSave = useSaveForm();

    const removeEquipment = (index: number) => {
        remove(index);

        const equipmentErrors = errors?.equipments?.filter((_: any, i: number) => i !== index);
        setValue(`errors.requirement.equipments`, equipmentErrors);
    };

    const removeVehicleOne = (index: number) => {
        removeVehicle(index);

        const vehicleErrors = errors?.vehicles?.filter((_: any, i: number) => i !== index);
        setValue(`errors.requirement.vehicles`, vehicleErrors);
    };

    const handleProceed = () => {
        setCurrentStep(1);
    };

    const handleCancel = () => {
        setOpen(false);
    };

    useEffect(() => {
        // for coborrower
        let coborrowerError = 0;
        let guarantorError = 0;

        const coborrowerFields = ["hasCoBorrower", "numberOfCoBorrower"];
        const guarantorFields = ["hasGuarantor", "numberOfGuarantor"];

        for (const item of coborrowerFields) {
            if (
                (getValues(`requirement.${item}`) === true && getValues(`requirement.${item}`) === "") ||
                getValues(`requirement.${item}`) === undefined
            ) {
                coborrowerError++;
            }
        }
        for (const item of guarantorFields) {
            if (
                (getValues(`requirement.${item}`) === true && getValues(`requirement.${item}`) === "") ||
                getValues(`requirement.${item}`) === undefined
            ) {
                guarantorError++;
            }
        }
        setValue("errors.requirement.coborrowerError", coborrowerError);
        setValue("errors.requirement.guarantorError", guarantorError);
    }, [requirement]);

    const checkCoborrowerRelation = () => {
        let coborrowers = 0;
        let relatedWith = "";
        let coborrowerIndexes: number[] = [];
        if (entityBusiness === "Registered Under Company Act") {
            directorDetails.forEach((detail: any) => {
                if (detail.entityType.includes("coborrower")) {
                    coborrowers += 1;
                    coborrowerIndexes.push(Number(detail.entityType.split("-")?.[1] ?? 0));
                }
                relatedWith = "director";
            });
        }
        if (entityBusiness === "Partnership")
            partnerDetails.forEach((detail: any) => {
                if (detail.entityType.includes("coborrower")) {
                    coborrowers += 1;
                    coborrowerIndexes.push(Number(detail.entityType.split("-")?.[1] ?? 0));
                }
                relatedWith = "partner";
            });

        return { isRelated: coborrowers !== 0, relatedWith, coborrowerIndexes };
    };

    const checkGuarantorRelation = () => {
        let guarantors = 0;
        let relatedWith = "";
        let guarantorIndexes: number[] = [];
        if (entityBusiness === "Registered Under Company Act") {
            directorDetails.forEach((detail: any) => {
                if (detail.entityType.includes("guarantor")) {
                    guarantors += 1;
                    guarantorIndexes.push(Number(detail.entityType.split("-")?.[1] ?? 0));
                }
            });
            relatedWith = "director";
        }
        if (entityBusiness === "Partnership")
            partnerDetails.forEach((detail: any) => {
                if (detail.entityType.includes("guarantor")) {
                    guarantors += 1;
                    guarantorIndexes.push(Number(detail.entityType.split("-")?.[1] ?? 0));
                }
                relatedWith = "partner";
            });

        return { isRelated: guarantors !== 0, relatedWith, guarantorIndexes };
    };

    useEffect(() => {}, []);

    return (
        <Fragment>
            <fieldset className="fieldset" disabled={isDisabled}>
                <Grid container id="requirement-form" data-testid="requirement-form" direction="column" justifyContent="center" alignItems="center">
                    <ConstructionEquipment />
                    {loanType === "Construction Equipment" ? (
                        <Fragment>
                            {fields.map((field, index: number) => {
                                return <Equipment index={index} key={field.id} remove={removeEquipment} />;
                            })}
                            <AddAnotherEquipment append={append} />
                        </Fragment>
                    ) : (
                        <Fragment>
                            {vehicleFields.map((field, index: number) => {
                                return <Vehicle index={index} key={field.id} remove={removeVehicleOne} />;
                            })}
                            <AddAnotherVehicle append={appendVehicle} />
                        </Fragment>
                    )}

                    <Loan />
                    <MachineUsage />
                    <CommonAccordion
                        titleReactNode={
                            <CommonFields
                                inputType={INPUT_TYPE.RADIO}
                                name="requirement.hasCoBorrower"
                                data-testid={generateTestId("Co-Borrower", "radio-accordion")}
                                label="Co-Borrower"
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                                className="common-mui-accordian__radio"
                                disableReactHookForm={true}
                                value={hasCoBorrower}
                                reactHookFormRegisterOptions={{
                                    onChange: (e) => {
                                        const value = e.target.value === "true";
                                        const { isRelated, relatedWith } = checkCoborrowerRelation();
                                        if (isRelated) {
                                            toast.error(`Some coborrowers is selected as ${relatedWith}, Please deselect first`);
                                        } else setValue("requirement.hasCoBorrower", value);
                                    },
                                    setValueAs: (value) => Boolean(value),
                                }}
                            />
                        }
                        showError={errors?.coborrowerError > 0}
                        className="common-accordion-field entity-container long__accordian"
                        title={"Co-Borrower"}
                        showDetail={hasCoBorrower === true || hasCoBorrower === true}
                        defaultExpanded={hasCoBorrower === true || hasCoBorrower === true}
                        data-testid={generateTestId(`Co-Borrower`, "accordion")}
                        tab="requirement"
                    >
                        <Grid container item xs={12}>
                            <CommonFields
                                inputType={INPUT_TYPE.NUMBER}
                                label="No. of Co-Borrowers"
                                name="requirement.numberOfCoBorrower"
                                disableReactHookForm={true}
                                value={requirement.numberOfCoBorrower}
                                reactHookFormRegisterOptions={{
                                    min: {
                                        value: 1,
                                        message: "Minimum 1 Co-borrower is required",
                                    },
                                    max: {
                                        value: 3,
                                        message: "Maximum 3 Co-borrowers are allowed",
                                    },
                                    onChange: (e) => {
                                        const value = Number(e.target.value);
                                        const { coborrowerIndexes, isRelated, relatedWith } = checkCoborrowerRelation();
                                        const maxValue = Math.max(...coborrowerIndexes);
                                        if (isRelated && maxValue > value) {
                                            toast.dismiss();
                                            toast.error(`Some coborrowers is selected as ${relatedWith}, Please deselect first`);
                                        } else if (e.target.value > 3) {
                                            toast.dismiss();
                                            toast.error(`Maximum 3 Co-borrowers are allowed`);
                                            setValue("requirement.numberOfCoBorrower", 3);
                                        } else setValue("requirement.numberOfCoBorrower", value);
                                    },
                                    setValueAs: (value) => Number(value),
                                }}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                            />
                        </Grid>
                    </CommonAccordion>
                    <CommonAccordion
                        showError={errors?.guarantorError > 0}
                        titleReactNode={
                            <CommonFields
                                inputType={INPUT_TYPE.RADIO}
                                data-testid={generateTestId("Guarantor", "radio-accordion")}
                                name="requirement.hasGuarantor"
                                label="Guarantor"
                                options={[
                                    { label: "Yes", value: true },
                                    { label: "No", value: false },
                                ]}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                                className="common-mui-accordian__radio"
                                disableReactHookForm={true}
                                value={hasGuarantor}
                                reactHookFormRegisterOptions={{
                                    onChange: (e) => {
                                        const value = e.target.value === "true";
                                        const { isRelated, relatedWith } = checkGuarantorRelation();
                                        if (isRelated) {
                                            toast.error(`Some guarantors is selected as ${relatedWith}, Please deselect first`);
                                        } else setValue("requirement.hasGuarantor", value);
                                    },
                                    setValueAs: (value) => Boolean(value),
                                }}
                            />
                        }
                        className="common-accordion-field entity-container long__accordian"
                        title={"Guarantor"}
                        showDetail={hasGuarantor === true || hasGuarantor === true}
                        defaultExpanded={hasGuarantor === true || hasGuarantor === true}
                        tab="requirement"
                        data-testid={generateTestId(`Guarantor`, "accordion")}
                    >
                        <Grid container item xs={12}>
                            <CommonFields
                                inputType={INPUT_TYPE.NUMBER}
                                label="No. of Guarantors"
                                name="requirement.numberOfGuarantor"
                                value={requirement.numberOfGuarantor}
                                disableReactHookForm={true}
                                reactHookFormRegisterOptions={{
                                    min: {
                                        value: 1,
                                        message: "Minimum 1 Guarantor is required",
                                    },
                                    max: {
                                        value: 3,
                                        message: "Maximum 3 Guarantors are allowed",
                                    },
                                    onChange: (e) => {
                                        const value = Number(e.target.value);
                                        const { guarantorIndexes, isRelated, relatedWith } = checkGuarantorRelation();
                                        const maxValue = Math.max(...guarantorIndexes);
                                        if (isRelated && maxValue > value) {
                                            toast.error(`Some guarantors is selected as ${relatedWith}, Please deselect first`);
                                        } else if (e.target.value > 3) {
                                            toast.dismiss();
                                            toast.error(`Maximum 3 Guarantors are allowed`);
                                            setValue("requirement.numberOfGuarantor", 3);
                                        } else setValue("requirement.numberOfGuarantor", value);
                                    },
                                    setValueAs: (value) => Number(value),
                                }}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                            />
                        </Grid>
                    </CommonAccordion>
                </Grid>
            </fieldset>
            <Grid justifyContent="flex-end" className="btn-container" container>
                <Button
                    disabled={localStorage.getItem("disable_form") === "true"}
                    onClick={handleSave}
                    variant="contained"
                    className="btn-arrow"
                    data-testid="save-button"
                >
                    Save
                </Button>
                <Button
                    variant="contained"
                    className="btn-arrow"
                    onClick={() => {
                        let shouldProceed = true;

                        for (let i in errors) {
                            if (i === "equipments" && errors[i].some((item: any) => item?.errorsCounts > 0)) shouldProceed = false;
                            else if ((i === "coborrowerError" || i === "guarantorError") && errors[i] > 0) {
                                shouldProceed = false;
                            } else if (errors[i]?.errorsCounts > 0) {
                                shouldProceed = false;
                            }
                        }
                        if (shouldProceed) handleProceed();
                        else {
                            setValue("showIncompleteField", true);
                            setOpen(true);
                        }
                    }}
                >
                    Next
                    <KeyboardArrowRightIcon id="right-arrow" />
                </Button>
            </Grid>
            <HandleNextDialog
                open={open}
                handleClose={() => {
                    setOpen(false);
                }}
                type="next"
                handleProceed={handleProceed}
                handleCancel={handleCancel}
            />
        </Fragment>
    );
};

export default memo(RequirementForm, () => true);
