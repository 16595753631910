import { memo } from "react";
import { RegisterOptions, UseFormReturn } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { INPUT_TYPE } from "globalConstants";
import isEqual from "lodash/isEqual";
import { combineClassNames, sanitizeReactHookFormRegisterOptions } from "utils";
import CommonLabel from "../CommonLabel";

type Props = {
    showLabel: boolean;
    label: string;
    infoText: string;
    labelSubtitle?: string;
    shouldAddMarginTop?: boolean;
    subtitle?: string;
    textFieldVariant?: "filled" | "outlined" | "standard";
    name: string;
    multiline: boolean;
    placeholder: string;
    reactHookFormRegisterOptions?: RegisterOptions;
    isValidate?: boolean;
    handleValidate?: () => void;
    className?: string;
    verified?: boolean;
    inputType: INPUT_TYPE.NUMBER | INPUT_TYPE.PERCENTAGE;
    readOnly: boolean;
    formContextMethods: UseFormReturn;
    defaultValue?: string;
    getValues: any;
    "data-testid"?: string;
    value?: string | number;
    disableReactHookForm: boolean;
};

const CommonNumberField = ({
    showLabel,
    label,
    infoText,
    labelSubtitle,
    shouldAddMarginTop,
    subtitle,
    textFieldVariant = "outlined",
    name,
    multiline,
    placeholder,
    reactHookFormRegisterOptions,
    isValidate,
    handleValidate,
    className,
    verified,
    inputType,
    readOnly,
    formContextMethods,
    defaultValue,
    getValues,
    "data-testid": dataTestId,
    value,
    disableReactHookForm,
}: Props) => {
    const { register, getFieldState, setValue } = formContextMethods;

    const { error } = getFieldState(name);

    const inlineStyles: any = {};

    if (!shouldAddMarginTop) {
        inlineStyles.marginTop = 0;
    }

    if (subtitle) {
        inlineStyles.marginBottom = "1.5rem";
    }

    const isStandardTextField = textFieldVariant === "standard";
    if (inputType === INPUT_TYPE.PERCENTAGE)
        reactHookFormRegisterOptions = {
            min: 0,
            ...reactHookFormRegisterOptions,
        };
    const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly || verified;

    const { onChange, ...registerMethod } = disableReactHookForm
        ? { value, onChange: reactHookFormRegisterOptions?.onChange }
        : register(name, {
              ...reactHookFormRegisterOptions,
          });

    return (
        <Grid
            className={isStandardTextField ? "" : "common__mui__textfield__container"}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={inlineStyles}
        >
            <CommonLabel
                showLabel={showLabel}
                label={label}
                infoText={infoText}
                labelSubtitle={labelSubtitle}
                required={!!reactHookFormRegisterOptions?.required}
                data-testid={`${dataTestId}-label`}
            />
            <Grid item xs={showLabel ? 6 : 12}>
                <FormControl fullWidth>
                    <TextField
                        variant={textFieldVariant}
                        fullWidth
                        multiline={multiline}
                        placeholder={placeholder}
                        disabled={isDisabled}
                        type="number"
                        defaultValue={defaultValue}
                        InputProps={{
                            className: combineClassNames("common-textfield", className),
                            ...(textFieldVariant !== "outlined"
                                ? {
                                      disableUnderline: isStandardTextField,
                                  }
                                : {}),
                            ...(inputType === INPUT_TYPE.PERCENTAGE
                                ? {
                                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                  }
                                : {}),
                            readOnly,
                            inputProps: {
                                ...sanitizeReactHookFormRegisterOptions(reactHookFormRegisterOptions),
                                min: reactHookFormRegisterOptions?.min
                                    ? (typeof reactHookFormRegisterOptions?.min === "object" && reactHookFormRegisterOptions?.min?.value) ||
                                      reactHookFormRegisterOptions?.min
                                    : 0,
                                max: reactHookFormRegisterOptions?.max
                                    ? (typeof reactHookFormRegisterOptions?.max === "object" && reactHookFormRegisterOptions?.max?.value) ||
                                      reactHookFormRegisterOptions?.max
                                    : undefined,
                                "data-testid": dataTestId,
                            },
                        }}
                        inputProps={{
                            min: reactHookFormRegisterOptions?.min
                                ? (typeof reactHookFormRegisterOptions?.min === "object" && reactHookFormRegisterOptions?.min?.value) ||
                                  reactHookFormRegisterOptions?.min
                                : 0,
                            max: reactHookFormRegisterOptions?.max
                                ? (typeof reactHookFormRegisterOptions?.max === "object" && reactHookFormRegisterOptions?.max?.value) ||
                                  reactHookFormRegisterOptions?.max
                                : undefined,
                            "data-testid": dataTestId,
                        }}
                        helperText={
                            (getValues(name) === "" || getValues(name) === undefined || Number.isNaN(getValues(name))) &&
                            getValues("showIncompleteField") == true
                                ? "This field is required"
                                : ""
                        }
                        error={
                            (getValues(name) === "" || getValues(name) === undefined || Number.isNaN(getValues(name))) &&
                            getValues("showIncompleteField") == true
                        }
                        className={combineClassNames(
                            "common__mui__textfield",
                            isDisabled ? "bg-shade" : undefined,
                            isStandardTextField ? "border-none" : "",
                            error ? "border-red" : "",
                            (getValues(name) === "" || getValues(name) === undefined || Number.isNaN(getValues(name))) &&
                                getValues("showIncompleteField") == true
                                ? "error"
                                : ""
                        )}
                        onKeyPress={(event) => {
                            // ignore "-", "+" and "e"
                            if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                                event.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            let value = reactHookFormRegisterOptions?.setValueAs?.(e.target.value) || e.target.value;

                            const max =
                                typeof reactHookFormRegisterOptions?.max === "object"
                                    ? reactHookFormRegisterOptions?.max?.value
                                    : (reactHookFormRegisterOptions?.max as number);
                            const min =
                                typeof reactHookFormRegisterOptions?.min === "object"
                                    ? reactHookFormRegisterOptions?.min?.value
                                    : (reactHookFormRegisterOptions?.min as number);

                            if (max !== undefined && max < value) {
                                value = max;
                            }
                            if (min !== undefined && min > value) {
                                value = min;
                            }

                            onChange?.({ target: { name, value } });
                            if (!disableReactHookForm) setValue(name, value);
                        }}
                        {...registerMethod}
                    />
                    <FormHelperText
                        className={combineClassNames("common__helper-text", isValidate ? "validate" : "", verified ? "verified" : "")}
                        onClick={handleValidate}
                    >
                        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                            {verified ? <CheckCircleIcon className="icon validate-icon" /> : ""}
                            <span>{subtitle}</span>
                        </Grid>
                    </FormHelperText>
                    <FormHelperText error={true} data-testid={`${name}__error`}>
                        <ErrorMessage name={name} render={({ message }) => message} />
                    </FormHelperText>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default memo(CommonNumberField, (prevProps, nextProps) => isEqual(prevProps, nextProps));
