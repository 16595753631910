import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import ShortFormContext from "./../../contexts/shortForm/context";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { useIsSmallScreen } from "hooks";

const ExperienceForm = ({ setCurrentStep }: { setCurrentStep: React.Dispatch<React.SetStateAction<number>> }) => {
    const { handleNestedFieldChange, form } = useContext(ShortFormContext);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNestedFieldChange({
            experienceForm: {
                [e.target.name]: e.target.value,
            },
        });
    };
    const isSmallScreen = useIsSmallScreen();

    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="experience-form">
                <Grid item xs={isSmallScreen ? 12 : 7}></Grid>
                <Grid item xs={isSmallScreen ? 12 : 5}>
                    <Typography variant="h5" className="short-form__title" component="h4">
                        Your Experience
                    </Typography>
                    <div className="short-form__option-body">
                        <RadioGroup value={form.experienceForm.type} onChange={handleChange} name="type">
                            <FormControlLabel value="established_business" control={<Radio size="small" />} label="Established Business" />
                            <FormControlLabel value="starting_now" control={<Radio size="small" />} label="Starting now" />
                        </RadioGroup>
                        {form.experienceForm.type === "established_business" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="h6">
                                    Establishment Year
                                </Typography>
                                <TextField
                                    id="establishmentYear"
                                    label=""
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black", borderRadius: "10px" } }}
                                    placeholder="YYYY"
                                    type="number"
                                    onChange={handleChange}
                                    value={form.experienceForm.establishmentYear}
                                    name="establishmentYear"
                                    inputProps={{
                                        maxLength: 4,
                                        minLenght: 4,
                                        className: "short-form__input-field",
                                    }}
                                    className="short-form__input input-short"
                                />
                                <FormHelperText className="subtitle">Year when you Started</FormHelperText>
                            </>
                        ) : (
                            ""
                        )}
                        {form.experienceForm.type === "starting_now" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="h6">
                                    Work Experience
                                </Typography>
                                <RadioGroup value={form.experienceForm.experience} onChange={handleChange} name="experience">
                                    <FormControlLabel value="construction_industry" control={<Radio size="small" />} label="Construction Industry" />
                                    <FormControlLabel value="other_industry" control={<Radio size="small" />} label="Other Industry" />
                                    <FormControlLabel value="no_prior_experience" control={<Radio size="small" />} label="No Prior Experience" />
                                </RadioGroup>
                                {form.experienceForm.experience !== "no_prior_experience" ? (
                                    <>
                                        <Typography variant="h6" className="short-form__heading" component="h6">
                                            Year of Experience
                                        </Typography>
                                        <TextField
                                            id="yearOfExperience"
                                            label=""
                                            placeholder="YYYY"
                                            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black", borderRadius: "10px" } }}
                                            type="number"
                                            onChange={handleChange}
                                            value={form.experienceForm.yearOfExperience}
                                            name="yearOfExperience"
                                            inputProps={{
                                                maxLength: 4,
                                                minLenght: 4,
                                                className: "short-form__input-field",
                                            }}
                                            className="short-form__input input-short"
                                        />
                                    </>
                                ) : (
                                    ""
                                )}
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <br />
                    <Grid justifyContent="flex-end" style={{ width: "80%" }} container>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(1)}>
                            <ArrowBackIosIcon />
                            Back
                        </Button>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(3)}>
                            Next
                            <NavigateNextIcon />
                        </Button>
                    </Grid>
                    <Grid container direction="row" className="progress">
                        <Grid container direction="column" className="progress-bar" id="experience">
                            60%
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};
export default ExperienceForm;
