import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "kindOfExperience",
        label: "Kind of Experience",
        inputType: INPUT_TYPE.CHECKBOX,
        editable: true,
        options: ["Operator", "Supervisor", "Owner", OTHER],
    },
    {
        name: "businessActivity",
        label: "Business Activity",
        editable: true,
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Brick Klin", "Road Construction", "Construction of Building", "Farms", "Mining", "Quarry", OTHER],
        clarificatoryText: "Activity for New Machine",
    },
    {
        name: "otherBusinessActivity",
        label: "Other",
        subtitle: "Specify Business Activity",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-sector",
    },

    {
        name: "operatorExperienceInYears",
        label: "Operator Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "supervisorExperienceInYears",
        label: "Supervisor Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "ownerExperienceInYears",
        label: "Owner Experience",
        inputType: INPUT_TYPE.NUMBER,
        subtitle: "Years",
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
    },
    {
        name: "otherExperienceInYears",
        label: "Other Experience",
        subtitle: "Years",
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        inputType: INPUT_TYPE.NUMBER,
        editable: true,
    },
    {
        name: "otherExperience",
        label: "Other",
        subtitle: "Description of Role",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-kind-of-experience",
    },
    {
        name: "industryExperienceInYears",
        label: "Industry Experience",
        inputType: INPUT_TYPE.NUMBER,
        subtitle: "Years",
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
        },
    },
    {
        name: "equipmentExperience",
        label: "Equipment experience",
        inputType: INPUT_TYPE.CHECKBOX,
        editable: true,
        options: [
            "Backhoe Loaders",
            "Compactors",
            "Excavators",
            "Gensets",
            "Mini Excavators",
            "Skid Steer Loaders",
            "Super Loaders",
            "Telehandlers",
            "Wheeled Loaders",
            OTHER,
        ],
    },
    {
        name: "backhoeLoadersExperienceInYears",
        label: "Backhoe Loaders Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },

        subtitle: "Years",
    },
    {
        name: "compactorsExperienceInYears",
        label: "Compactors Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "excavatorsExperienceInYears",
        label: "Excavators Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "gensetsExperienceInYears",
        label: "Gensets Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "miniExcavatorsExperienceInYears",
        label: "Mini Excavators Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "skidSteerLoadersExperienceInYears",
        label: "Skid Steer Loaders Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "superLoadersExperienceInYears",
        label: "Super Loaders Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "telehandlersExperienceInYears",
        label: "Telehandlers Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "wheeledLoadersExperienceInYears",
        label: "Wheeled Loaders Experience",
        inputType: INPUT_TYPE.NUMBER,
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        subtitle: "Years",
    },
    {
        name: "otherEquipmentExperienceInYears",
        label: "Other Equipment Experience",
        subtitle: "Years",
        reactHookFormRegisterOptions: {
            min: 0,
            max: 100,
            onChange(event) {
                if (Number(event.target.value || 0) > 100) {
                    event.target.value = "100";
                }
            },
        },
        inputType: INPUT_TYPE.NUMBER,
        editable: true,
    },
    {
        name: "otherEquipmentExperience",
        label: "Other",
        subtitle: "Description of Equipment",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-equipment-experience-description",
    },
    {
        name: "customerSourcing",
        label: "Customer Sourcing",
        clarificatoryText: "Customer Sourcing Channels",
        inputType: INPUT_TYPE.CHECKBOX,
        editable: true,
        options: ["Broker Network", "Existing Customers", "Private Bidding from Organisations", "Public Bidding from Organisations", OTHER],
    },
    {
        name: "principal",
        label: "Principal",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        clarificatoryText: "Name of customer/organisation",
        showMask: true,
        dataTestId: "principal",
    },
    {
        name: "expectedOrderValue",
        label: "Expected Order Value",
        inputType: INPUT_TYPE.PRICE,
        clarificatoryText: "Total Order Value (in Rs.)",
        editable: true,
        defaultValue: "",
        dataTestId: "expected-order-value",
    },
    {
        name: "otherCustomerSourcing",
        label: "Other",
        subtitle: "Specify Customer Sourcing",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-customer-sourcing",
    },
    // {
    //     name: "noOfCustomers",
    //     label: "No. of Customers",
    //     clarificatoryText: "Number of Customers (0-10)",
    //     inputType: INPUT_TYPE.NUMBER,
    //     editable: true,
    //     dataTestId: "no-of-customers-text-field",
    // },
    {
        name: "deploymentOfMachine",
        label: "Deployment of Machine",
        clarificatoryText: "Geographical Area of existing business",
        subtitle: "Specify Pincode",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "deployment-of-machine",
    },
];

export default fixture;
