import React, { Fragment, useRef } from "react";
import { useAuthUser } from "react-auth-kit";
import { RegisterOptions, useFormContext, useController } from "react-hook-form";
import toast from "react-hot-toast";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import CalendarTodayOutlinedIcon from "@mui/icons-material/CalendarTodayOutlined";
import CancelIcon from "@mui/icons-material/Cancel";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useDownload } from "__generated__/apiComponents";
import WrapWithFormContext from "components/WrapWithFormContext";
import WrapWithFormController from "components/WrapWithFormController/WrapWithFormController";
import { INPUT_TYPE } from "globalConstants";
import { useMaskedFields } from "hooks";
import moment from "moment";
import { ICommonFixture, IOption } from "types";
import generateTestId from "utils/generateTestId";
import getKeyFromS3Url from "utils/getKeyFromS3Url";
import "./CommonFields.styles.scss";
import "./CommonFields.styles.scss";
import {
    CommonDateField,
    CommonPriceField,
    CommonCheckBoxField,
    CommonNumberField,
    CommonTextField,
    CommonSelectField,
    CommonRadioField,
    CommonLabel,
} from "./components";
import CommonMultipleTextField from "./components/CommonMultipleTextField";

interface prop {
    value: string;
}

interface CommonFieldsProps {
    inputType: INPUT_TYPE;
    label: string;
    value?: string | number;
    handleChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    defaultValue?: string;
    options?: IOption[];
    handleFileModalOpen?: ({ position }: { position: number }) => void;
    name: string;
    subtitle?: string;
    placeholder?: string;
    multiLine?: boolean;
    editable?: boolean;
    id?: string;
    showLabel?: boolean;
    positionHelperTextAbsolute?: boolean;
    prop?: prop;
    className?: string;
    isValidate?: boolean;
    handleValidate?: () => void;
    labelSubtitle?: string;
    shouldAddMarginTop?: boolean;
    verified?: boolean;
    "data-testid"?: string;
    textFieldVariant?: "filled" | "outlined" | "standard";
    reactHookFormRegisterOptions?: RegisterOptions;
    ignoreOthers?: boolean;
    otherOptionProps?: ICommonFixture["otherOptionProps"];
    readOnly?: boolean;
    disableReactHookForm?: boolean;
    adorment?: any;
    infoText?: string;
    showCross?: boolean;
    multiple?: boolean;
    onlyNumbers?: boolean;
    onClick?: () => void;
    showMask?: boolean;
    disableFuture?: boolean;
}

const CommonFields = ({
    value,
    handleChange,
    inputType,
    label,
    defaultValue,
    options,
    name,
    subtitle,
    placeholder,
    editable = true,
    id,
    showLabel = true,
    positionHelperTextAbsolute = false,
    prop,
    className,
    multiLine = false,
    isValidate,
    labelSubtitle,
    shouldAddMarginTop = true,
    handleValidate,
    textFieldVariant,
    verified = false,
    reactHookFormRegisterOptions,
    ignoreOthers = false,
    otherOptionProps,
    handleFileModalOpen,
    readOnly = false,
    disableReactHookForm = false,
    adorment,
    infoText,
    showCross = false,
    multiple = false,
    onlyNumbers = false,
    onClick,
    "data-testid": dataTestId,
    showMask,
    disableFuture,
    ...props
}: CommonFieldsProps) => {
    const { control, setValue, getValues } = useFormContext();
    const { field } = useController({
        control,
        name,
    });

    let { isMasked } = useMaskedFields();

    if (!showMask) {
        isMasked = false;
    }

    const valueFromContext = getValues(name);

    const downloadRef = useRef<HTMLAnchorElement>(null);
    const fileUploadRef = useRef<HTMLInputElement>(null);

    const { mutate: getDownloaUrl } = useDownload({
        onSuccess: (data: any) => {
            if (data && downloadRef.current) {
                console.log(downloadRef.current);
                downloadRef.current.href = data;
                downloadRef.current?.click();
            }
        },

        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });

    // calculate date from previous 30 year
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth();
    const day = date.getDate();
    const minDate = new Date(year - 30, month, day);
    // handle change function for date picker
    const handleDateChange = (newValue: Date | null) => {
        if (handleChange) {
            handleChange({
                target: {
                    name,
                    value: newValue?.toString(),
                },
            } as React.ChangeEvent<HTMLInputElement>);
        }
    };

    const handleFileDownload = (link: string) => {
        getDownloaUrl({
            body: {
                key: getKeyFromS3Url(decodeURIComponent(link)),
            },
        });
    };

    if (inputType === INPUT_TYPE.NUMBER || inputType === INPUT_TYPE.PERCENTAGE) {
        // @ts-ignore
        reactHookFormRegisterOptions = {
            ...reactHookFormRegisterOptions,
            valueAsNumber: true,
        };
    }

    if (!dataTestId) {
        dataTestId = generateTestId(label, "field");
    }

    switch (inputType) {
        case INPUT_TYPE.TEXT:
            return (
                <CommonTextField
                    showLabel={showLabel}
                    label={label}
                    infoText={prop?.value || ""}
                    labelSubtitle={labelSubtitle}
                    shouldAddMarginTop={shouldAddMarginTop}
                    subtitle={subtitle || ""}
                    textFieldVariant={textFieldVariant}
                    name={name}
                    multiline={multiLine}
                    placeholder={placeholder || ""}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                    isValidate={isValidate}
                    handleValidate={handleValidate}
                    className={className}
                    verified={verified}
                    readOnly={readOnly}
                    disableReactHookForm={disableReactHookForm}
                    value={value}
                    onlyNumbers={onlyNumbers}
                    adorment={adorment}
                    data-testid={dataTestId}
                    showMask={isMasked}
                />
            );

        case INPUT_TYPE.MULTIPLE_TEXT:
            return (
                <CommonMultipleTextField
                    showLabel={showLabel}
                    label={label}
                    infoText={prop?.value || ""}
                    labelSubtitle={labelSubtitle}
                    shouldAddMarginTop={shouldAddMarginTop}
                    subtitle={subtitle || ""}
                    textFieldVariant={textFieldVariant}
                    name={name}
                    multiline={multiLine}
                    placeholder={placeholder || ""}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                    isValidate={isValidate}
                    handleValidate={handleValidate}
                    className={className}
                    verified={verified}
                    readOnly={readOnly}
                    disableReactHookForm={disableReactHookForm}
                    value={value}
                    onlyNumbers={onlyNumbers}
                    adorment={adorment}
                    data-testid={dataTestId}
                />
            );

        case INPUT_TYPE.PRICE:
            return (
                <WrapWithFormController
                    name={name}
                    rules={
                        {
                            ...reactHookFormRegisterOptions,
                        } || {}
                    }
                >
                    {(methods, getFieldState) => (
                        <CommonPriceField
                            showLabel={showLabel}
                            label={label}
                            infoText={prop?.value || ""}
                            labelSubtitle={labelSubtitle}
                            shouldAddMarginTop={shouldAddMarginTop}
                            subtitle={subtitle}
                            textFieldVariant={textFieldVariant}
                            name={name}
                            multiline={multiLine}
                            placeholder={placeholder || ""}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            isValidate={isValidate}
                            handleValidate={handleValidate}
                            className={className}
                            verified={verified}
                            readOnly={readOnly}
                            formContextMethods={methods}
                            disableReactHookForm={disableReactHookForm}
                            customValue={value}
                            getFieldState={getFieldState}
                            getValues={getValues}
                            data-testid={dataTestId}
                        />
                    )}
                </WrapWithFormController>
            );

        case INPUT_TYPE.NUMBER:
            return (
                <WrapWithFormContext>
                    {(methods) => (
                        <CommonNumberField
                            showLabel={showLabel}
                            label={label}
                            infoText={prop?.value || ""}
                            labelSubtitle={labelSubtitle}
                            shouldAddMarginTop={shouldAddMarginTop}
                            subtitle={subtitle}
                            textFieldVariant={textFieldVariant}
                            name={name}
                            multiline={multiLine}
                            placeholder={placeholder || ""}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            isValidate={isValidate}
                            handleValidate={handleValidate}
                            className={className}
                            verified={verified}
                            inputType={inputType}
                            readOnly={readOnly}
                            formContextMethods={methods}
                            getValues={getValues}
                            data-testid={dataTestId}
                            value={value}
                            disableReactHookForm={disableReactHookForm}
                        />
                    )}
                </WrapWithFormContext>
            );
        case INPUT_TYPE.PERCENTAGE:
            return (
                <WrapWithFormContext>
                    {(methods) => (
                        <CommonNumberField
                            showLabel={showLabel}
                            label={label}
                            infoText={prop?.value || ""}
                            labelSubtitle={labelSubtitle}
                            shouldAddMarginTop={shouldAddMarginTop}
                            subtitle={subtitle}
                            textFieldVariant={textFieldVariant}
                            name={name}
                            multiline={multiLine}
                            placeholder={placeholder || ""}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            isValidate={isValidate}
                            handleValidate={handleValidate}
                            className={className}
                            verified={verified}
                            inputType={inputType}
                            readOnly={readOnly}
                            formContextMethods={methods}
                            defaultValue={defaultValue}
                            getValues={getValues}
                            data-testid={dataTestId}
                            value={value}
                            disableReactHookForm={disableReactHookForm}
                        />
                    )}
                </WrapWithFormContext>
            );
        case INPUT_TYPE.DATE:
            return (
                <CommonDateField
                    showLabel={showLabel}
                    label={label}
                    name={name}
                    readOnly={readOnly}
                    data-testid={dataTestId}
                    showMask={isMasked}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                    disableFuture={disableFuture}
                />
            );
        case INPUT_TYPE.YEAR:
            return (
                <Grid container className="common__mui__textfield__container" justifyContent="center" alignItems="center">
                    <Grid item xs={showLabel ? 6 : 12}>
                        <Typography className="common-label" data-testid={`${dataTestId}-label`}>
                            {label}
                        </Typography>
                    </Grid>

                    <Grid item xs={window.innerWidth < 400 ? 8 : showLabel ? 6 : 12}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} style={{ width: "100%" }} className="date__container">
                            <DatePicker
                                className="common__mui__date__container"
                                maxDate={new Date()}
                                minDate={minDate}
                                views={["year"]}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled={readOnly}
                                        type="number"
                                        onChange={(e) => {
                                            const value = parseInt(e.target.value);
                                            if (e.target.value.length > 3) {
                                                if (value > new Date().getFullYear()) {
                                                    e.target.value = new Date().getFullYear().toString();
                                                }
                                                if (value < minDate.getFullYear()) {
                                                    e.target.value = minDate.getFullYear().toString();
                                                }
                                            }
                                            setValue(name, e.target.value);
                                        }}
                                        onKeyPress={(event) => {
                                            // ignore "-", "+" and "e"
                                            if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                                                event.preventDefault();
                                            }
                                            // if character is not number then ignore except decimal point and
                                            if (event?.key !== "." && event?.key && isNaN(+event.key)) {
                                                event.preventDefault();
                                            }
                                        }}
                                        data-testid={dataTestId}
                                    />
                                )}
                                components={{
                                    OpenPickerIcon: CalendarTodayOutlinedIcon,
                                }}
                                {...field}
                                onChange={(newValue) => {
                                    setValue(name, moment(newValue).format("YYYY"));
                                    field.onChange(newValue);
                                }}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            );
        case INPUT_TYPE.RADIO:
            return (
                <CommonRadioField
                    showLabel={showLabel}
                    label={label}
                    options={options || []}
                    subtitle={subtitle || ""}
                    labelSubtitle={labelSubtitle}
                    readOnly={readOnly}
                    name={name}
                    positionHelperTextAbsolute={positionHelperTextAbsolute}
                    infoText={infoText || prop?.value || ""}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions || {}}
                    className={className}
                    data-testid={dataTestId}
                    value={value}
                    disableReactHookForm={disableReactHookForm}
                />
            );
        case INPUT_TYPE.CHECKBOX:
            return (
                <CommonCheckBoxField
                    label={label}
                    showLabel={showLabel}
                    infoText={prop?.value || ""}
                    labelSubtitle={labelSubtitle}
                    ignoreOthers={ignoreOthers}
                    options={options || []}
                    name={name}
                    readOnly={readOnly}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions || {}}
                    otherOptionProps={otherOptionProps || {}}
                    data-testid={dataTestId}
                />
            );

        case INPUT_TYPE.FILE:
            return (
                <Fragment>
                    <CommonLabel
                        showLabel={showLabel}
                        label={label}
                        infoText={prop?.value || ""}
                        labelSubtitle={labelSubtitle}
                        required={!!reactHookFormRegisterOptions?.required}
                        data-testid={`${dataTestId}-label`}
                    />
                    <Grid style={{ paddingTop: "25px" }} container alignItems="center" item xs={6}>
                        <div style={{ display: "none" }}>
                            <TextField
                                name={name}
                                id={`file-select-${name}`}
                                type={INPUT_TYPE.FILE}
                                disabled={readOnly}
                                onChange={handleChange}
                                inputProps={{
                                    multiple: multiple,
                                    "data-testid": dataTestId,
                                }}
                                inputRef={fileUploadRef}
                            />
                        </div>
                        {showCross ? (
                            <Fragment>
                                {multiple ? (
                                    <div className="flex flex-col">
                                        {valueFromContext?.map((file: any, index: number) => {
                                            return (
                                                <div key={index} className="flex items-center">
                                                    <Tooltip arrow placement="top" title={file?.filename}>
                                                        <label className="download-text">{file?.filename}</label>
                                                    </Tooltip>
                                                    <CancelIcon
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (handleFileModalOpen) {
                                                                handleFileModalOpen({
                                                                    position: index,
                                                                });
                                                                if (fileUploadRef?.current) fileUploadRef.current.value = "";
                                                            }
                                                        }}
                                                    />
                                                    <FileDownloadIcon className="download-icon" onClick={() => handleFileDownload(file?.link)} />
                                                    <a ref={downloadRef} href="" hidden download={file?.filename}></a>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : (
                                    <div className="flex">
                                        <Tooltip arrow placement="top" title={valueFromContext?.filename}>
                                            <label data-testid={`${dataTestId}-attach`} className="download-text">
                                                {valueFromContext?.filename}
                                            </label>
                                        </Tooltip>
                                        <CancelIcon
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (handleFileModalOpen) {
                                                    handleFileModalOpen({
                                                        position: 0,
                                                    });

                                                    if (fileUploadRef?.current) fileUploadRef.current.value = "";
                                                }
                                            }}
                                        />
                                        <FileDownloadIcon
                                            className="download-icon"
                                            onClick={() => {
                                                handleFileDownload(valueFromContext?.link);
                                            }}
                                        />
                                        <a ref={downloadRef} href="" hidden download={valueFromContext?.filename}></a>
                                    </div>
                                )}
                            </Fragment>
                        ) : (
                            <label data-testid={`${dataTestId}-attach`} onClick={onClick} htmlFor={`file-select-${name}`} className="attach">
                                <AttachFileIcon className="icon" />
                                <span>Attach</span>
                            </label>
                        )}
                        <FormHelperText error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}>
                            {(getValues(name) === "" || getValues(name) === undefined) &&
                                getValues("showIncompleteField") == true &&
                                "This field is required"}
                        </FormHelperText>
                    </Grid>
                </Fragment>
            );
            break;
        case INPUT_TYPE.SELECT:
            return (
                <CommonSelectField
                    showLabel={showLabel}
                    label={label}
                    readOnly={readOnly}
                    multiple={multiple}
                    options={options || []}
                    subtitle={subtitle}
                    labelSubtitle={labelSubtitle}
                    reactHookFormRegisterOptions={reactHookFormRegisterOptions || {}}
                    name={name}
                    ignoreOthers={ignoreOthers}
                    className={className || ""}
                    otherOptionProps={otherOptionProps || {}}
                    data-testid={dataTestId}
                    showMask={isMasked}
                    value={value}
                    disableReactHookForm={disableReactHookForm}
                />
            );

        default:
            return <Fragment />;
    }
};

export default CommonFields;
export type { CommonFieldsProps };
