import { useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useGenerateBSAReport } from "__generated__/apiComponents";
import { useSaveForm, useUploadFile } from "hooks";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import getEntityDetails from "utils/getEntityDetails";

type Props = {
    fileAddress: string;
    name: string;
    prefix: string;
    multiple: boolean;
    entityPrefix?: string;
};

const useBSAReport = ({ name, fileAddress, prefix, entityPrefix, multiple = false }: Props) => {
    const formId = localStorage.getItem("form_id");
    const { setValue } = useFormContext();
    const documentBusiness = useWatch({ name: `${prefix}` });
    const bankAccount = useWatch({ name: `${fileAddress.replace(`.${name}`, "")}` });
    if (!entityPrefix) entityPrefix = `${prefix.replace(".document.business", "")}`;
    const entity = useWatch({ name: `${entityPrefix}.entity` });
    const save = useSaveForm(false);

    const { pan, business } = entity;
    const entityName = getEntityDetails(entity).entityName;

    const { mutate: generateBSA } = useGenerateBSAReport({
        onSuccess: (data: any) => {
            setValue(`${fileAddress.replace(`.${name}`, "")}.referenceId`, data?.referenceId);
            if (!documentBusiness.mergeReferenceId) {
                setValue(`${prefix}.mergeReferenceId`, data?.referenceId);
            }
            toast.dismiss();
            toast.success("Bank Statement uploaded succesfully");
            save();
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
            // addIssue({
            //     body: {
            //         loanApplicationId: formId as string,
            //         description: error?.stack?.message ?? "",
            //         field: `applicant.data.document.business.mergeReferenceId`,
            //         status: "unresolved",
            //         typeOfIssue: "BSA Report Generation Failed",
            //         comments: [],
            //         resolution: "",
            //         recordDate: new Date().toString(),
            //     },
            // });
        },
    });
    const handleBSAUpload = (downloadData: any) => {
        toast.dismiss();
        toast.loading("Uploading Files...");

        bankAccount.statement = downloadData;
        for (let field of ["accountNumber", "bankName", "bankCode", "accountType"]) {
            if (!bankAccount[field]) {
                toast.dismiss();
                toast.error(`${convertCamelCaseToTitleCase(field)} is required`);
                return;
            }
        }

        if (!entityName) {
            toast.dismiss();
            toast.error("Entity Name is required");
            return;
        }

        if (!pan) {
            toast.dismiss();
            toast.error("PAN is required");
            return;
        }

        if (!business) {
            toast.dismiss();
            toast.error("Entity Type is required");
            return;
        }

        if (fileAddress.includes("document.business.loanAccountAdditional") || fileAddress.includes("document.business.loanAccountCredit")) {
            let accountType = bankAccount.accountType;
            if (["Credit Card", "Credit Cards", "Credit Card Loans"].includes(accountType)) accountType = "CASH_CREDIT";
            else accountType = "OVERDRAFT";
            bankAccount.accountType = accountType;

            if (bankAccount.emiReceipt) delete bankAccount.emiReceipt;
            if (bankAccount.accountStatus) delete bankAccount.accountStatus;
        }

        // TODO: need to include loan accounts as well

        generateBSA({
            body: {
                data: {
                    entityName,
                    entityType: business,
                    loanApplicationId: formId as string,
                    pan,
                    fileAddress,
                    entityKey: prefix.replace(".document.business", ""),
                },
                bankAccount,
            },
        });
    };

    const handleUploadFile = useUploadFile({
        name,
        fileAddress,
        showToast: false,
        multiple: true,
        callback: handleBSAUpload,
    });

    return handleUploadFile;
};

export default useBSAReport;
