import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "income",
        label: "Income",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        editable: true,
        subtitle: "Last 12 Months",
    },
    {
        name: "propertyDetail",
        label: "Detail",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            {
                label: "Commercial",
                value: "Commercial",
            },
            {
                label: "Residential",
                value: "Residential",
            },
            {
                label: "Industrial",
                value: "Industrial",
            },
            {
                label: "Agricultural",
                value: "Agricultural",
            },
        ],
    },
    {
        name: "propertyType",
        label: "Property Type",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        extendedOptions: {
            isCommercial: {
                options: [
                    {
                        label: "Shop",
                        value: "Shop",
                    },
                    {
                        label: "Office",
                        value: "Office",
                    },
                    {
                        label: "Shop cum Office",
                        value: "Shop cum Office",
                    },
                ],
            },
            isResidential: {
                options: [
                    {
                        label: "Independent House",
                        value: "Independent House",
                    },
                    {
                        label: "Flat",
                        value: "Flat",
                    },
                    {
                        label: "Apartment",
                        value: "Apartment",
                    },
                ],
            },
            isIndustrial: {
                options: [
                    {
                        label: "Factory",
                        value: "Factory",
                    },
                    {
                        label: "Warehouse",

                        value: "Warehouse",
                    },
                    {
                        label: "Port",
                        value: "Port",
                    },
                    {
                        label: "Other",
                        value: OTHER,
                    },
                ],
            },
            isAgricultural: {
                options: [
                    {
                        label: "Farm Land",
                        value: "Farm Land",
                    },
                    {
                        label: "Fish Farm",
                        value: "Fish Farm",
                    },
                    {
                        label: "Other",
                        value: OTHER,
                    },
                ],
            },
        },
    },
    {
        name: "ownUnderlyingAsset",
        label: "Asset",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            {
                label: "yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
        subtitle: "Own Underlying Asset ?",
    },
];

export default fixture;
