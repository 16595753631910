import { useAuthUser } from "react-auth-kit";
import mask from "utils/maskField";

const useMaskedFields = () => {
    const authUser = useAuthUser()();
    const isLoanMasked = localStorage.getItem("is_masked");
    let isAuthMasked = false;
    let isMasked = false;
    if (authUser?.email === "demo_user@gmail.com") {
        isAuthMasked = true;
    }
    isMasked = isAuthMasked && isLoanMasked === "true";

    const maskField = (str: string) => {
        return mask(str, isMasked);
    };

    return { maskField, isMasked, isAuthMasked };
};

export default useMaskedFields;
