import { INPUT_TYPE, BANK_NAMES } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "bankName",
        label: "Bank Name",
        inputType: INPUT_TYPE.SELECT,
        editable: false,
        defaultValue: "",
        placeholder: "Select Bank Name",
        options: BANK_NAMES.map((name) => name.name),
        showMask: true,
    },
    {
        name: "accountType",
        label: "Account Type",
        inputType: INPUT_TYPE.SELECT,
        editable: false,
        defaultValue: "",
        placeholder: "Select Account Type",
        options: ["Cash Credit", "Overdraft"],
    },
    {
        name: "accountNumber",
        label: "Account Number",
        inputType: INPUT_TYPE.TEXT,
        editable: false,
        defaultValue: "",
        placeholder: "Enter Account Number",
        showMask: true,
    },
];

export default fixture;
