import { CheckCreateInput } from "__generated__/apiSchemas";

const fixture: CheckCreateInput = {
    referenceChecker: "",
    referenceUpdateTimestamp: "",
    referenceCheckTranscript: "",
    remarks: "",
};

export default fixture;
