import { Fragment, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const IncomeStatement = ({ prefix, header, pane }: { prefix: string; header: string; pane?: string }) => {
    const incomeStatement = useWatch({ name: `${prefix}.incomeStatement` });
    const businessIncome = useWatch({ name: `${prefix}.businessIncome` });
    const sales = businessIncome.sales;
    const { setValue, getValues } = useFormContext();

    useEffect(() => {
        let income = parseInt(businessIncome.sales || "0");
        let expense = parseInt(incomeStatement.expense || "0");
        let depreciation = parseInt(incomeStatement.depreciation || "0");
        let financialCost = parseInt(incomeStatement.financingCost || "0");
        const grossProfit = income - (expense + depreciation);
        const netProfit = income - (expense + depreciation + financialCost);
        setValue(`${prefix}.incomeStatement.income`, income);
        setValue(`${prefix}.incomeStatement.grossProfit`, grossProfit);
        setValue(`${prefix}.incomeStatement.netProfit`, netProfit);
    }, [sales]);

    const activeFields: string[] = [];

    // useEffect(() => {
    //     let errorsCounts = 0;
    //     for (const item of activeFields) {
    //         if (getValues(`${prefix}.incomeStatement.${item}`) === "" || getValues(`${prefix}.incomeStatement.${item}`) === undefined) {
    //             errorsCounts++;
    //         }
    //     }
    //     setValue(`${prefix}.incomeStatement.errorsCounts`, errorsCounts);
    // }, []);

    return (
        <CommonAccordion
            keys={`${header}.incomeStatement`}
            showError={incomeStatement.errorsCounts > 0}
            className="common-accordion-field entity-container"
            title="Income Statement"
            data-testid={generateTestId(`income-statement-${pane}`, "accordion")}
            tab="income"
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(({ name, inputType, editable, options, reactHookFormRegisterOptions, subtitle, label }, index) => {
                    activeFields.push(name);
                    return (
                        <Fragment key={index}>
                            <CommonFields
                                inputType={inputType}
                                name={`${prefix}.incomeStatement.${name}`}
                                options={
                                    options?.map((value: string) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                label={label}
                                data-testid={generateTestId(`${label}-income-statement-${pane}`, "field")}
                                reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                                subtitle={subtitle}
                                {...(name === "grossProfit" || name === "netProfit" || name === "income"
                                    ? { className: "common__mui__textfield-grey" }
                                    : {})}
                            />
                        </Fragment>
                    );
                })}
            </Grid>
        </CommonAccordion>
    );
};

export default IncomeStatement;
