import { AssetDetailsCreateInput } from "@/__generated__/apiSchemas";

const fixture: AssetDetailsCreateInput = {
    emiAmount: 0,
    sanctionAmount: 0,
    hasAsset: false,
    outstandingLoanAmount: 0,
    typeOfLoan: "",
    repaymentFrequency: "",
    remainingLoanTenorInMonths: 0,
    originalLoanTenorInMonths: 0,
    type: "Construction Equipment",
    value: "0",
    filledExcel: {
        link: "",
        filename: "",
        year_index: 0,
    },
    propertyType: "Shop",
    propertyDetail: "Commercial",
    manufacturer: "JCB",
    otherManufacturer: "",
    category: "Electric Scissors",
    otherCategory: "",
    model: "S1530E ELECTRIC SCISSOR",
    otherModel: "",
    description: "",
    landArea: {
        value: 0,
        unit: "Sq Foot",
    },
    builtUpArea: {
        value: 0,
        unit: "Sq Foot",
    },
    yearOfManufacture: "",
    registrationNumber: "",
    registrationNumberVerified: false,
    isFinanced: false,
    financedBy: "",
    dateOfPurchase: "",
};

export default fixture;
