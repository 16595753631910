enum ROUTES {
    HOME = "/",
    LOGIN = "/login",
    LONGFORM = "/long-form",
    DASHBOARD = "/dashboard",
    HOME_PAGE = "/home-page",
    CONTACT_US = "/contact-us",
    PRIVACY_POLICY = "/privacy-policy",
    TERMS_AND_CONDITIONS = "/terms-&-conditions",
    SHORTFORM = "/short-form",
    REGISTER = "/register",
    ADMIN_VIEW = "/admin-view",
    CRITERIA_VIEW = "/criteria-view",
    DASHBOARD_ID = "/dashboard/:id",
    PROSPECTIVE_FORM = "/prospective-form/:id",
    VIEW_APPLICATION_ID = "/view-application/:id",
    RESET_PASSWORD = "/reset-password",
    RESET_EMAIL = "/reset-email",
    PROFILE = "/profile",
    GOOGLE_AUTH_CALLBACK = "/auth/google/callback",
    REDIRECTED_LOGIN = "/redirected-login",
}

export default ROUTES;
