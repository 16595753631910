import { useState, Fragment } from "react";
import { useFormContext, useController, RegisterOptions } from "react-hook-form";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { IOption } from "types";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import generateTestId from "utils/generateTestId";
import CommonLabel from "../CommonLabel";

type Props = {
    label: string;
    showLabel: boolean;
    infoText: string;
    labelSubtitle?: string;
    reactHookFormRegisterOptions?: RegisterOptions;
    options: IOption[];
    name: string;
    ignoreOthers: boolean;
    readOnly?: boolean;
    otherOptionProps: {
        name?: string;
        subtitle?: string;
        label?: string;
        reactHookFormRegisterOptions?: RegisterOptions;
        className?: string;
        placeholder?: string;
    };
    "data-testid"?: string;
};

const CommonCheckBoxField = ({
    label,
    showLabel,
    options,
    name,
    otherOptionProps,
    ignoreOthers,
    readOnly,
    infoText,
    labelSubtitle,
    reactHookFormRegisterOptions,
    "data-testid": dataTestId,
}: Props) => {
    const { control, watch } = useFormContext();

    const { field } = useController({
        control,
        name,
    });
    const otherOptionField =
        otherOptionProps.name ||
        name
            .split(".")
            .slice(0, -1)
            .concat(
                "other" +
                    name
                        .split(".")
                        .slice(-1)
                        .map((word) => word[0].toUpperCase() + word.slice(1))
            )
            .join(".");

    const otherSubtitle = "Specify " + convertCamelCaseToTitleCase(name.split(".")[name.split(".").length - 1]);
    const [value, setValue] = useState(field.value || []);

    let pane = "";
    if (!name.includes("requirement")) pane = `-${dataTestId?.split("-").slice(-2, -1)[0]} ` ?? "";

    return (
        <Fragment>
            <Grid className="common__mui__textfield__container" container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={window.innerWidth < 400 ? 4 : showLabel ? 6 : 12}>
                    <CommonLabel
                        showLabel={showLabel}
                        label={label}
                        infoText={infoText}
                        labelSubtitle={labelSubtitle}
                        required={!!reactHookFormRegisterOptions?.required}
                        data-testid={`${dataTestId}-label`}
                    />
                </Grid>
                <Grid item xs={window.innerWidth < 400 ? 8 : showLabel ? 6 : 12}>
                    <FormGroup className="common__mui__checkbox__container" data-testid={name}>
                        {options.map((option, index) => (
                            <FormControlLabel
                                key={option.label}
                                data-testid={generateTestId(`${option.label}-${label}${pane}`, "checkbox-field")}
                                control={
                                    <Checkbox
                                        disabled={readOnly}
                                        size="small"
                                        onChange={(e) => {
                                            // update local state
                                            // setValue((previousValue: string[]) => {
                                            const valueCopy = [...(field.value ?? [])];

                                            // update checkbox value
                                            const index = valueCopy.indexOf(option.value);
                                            if (e.target.checked && index === -1) {
                                                valueCopy.push(option.value);
                                            } else if (!e.target.checked && index !== -1) {
                                                valueCopy.splice(index, 1);
                                            } else {
                                                return;
                                            }

                                            // send data to react hook form
                                            field.onChange(valueCopy);

                                            return valueCopy;
                                            // });
                                        }}
                                        checked={field.value?.includes(option.value)}
                                    />
                                }
                                label={
                                    <span
                                        // data-testid={generateTestId(
                                        //     option.label === "Other"
                                        //         ? `other-${label}`
                                        //         : option.label === "Material Handling" && label === "Ports"
                                        //         ? `${option.label}-ports`
                                        //         : option.label.includes("Government Department")
                                        //         ? `Government Department`
                                        //         : option.label,
                                        //     "checkbox-field-label"
                                        // )}
                                        data-testid={generateTestId(`${option.label}-${label}${pane}`, "checkbox-field-label")}
                                    >
                                        {option.label}
                                    </span>
                                }
                                value={option.value}
                            />
                        ))}
                    </FormGroup>
                </Grid>
            </Grid>
            {watch(name) == OTHER && !ignoreOthers && (
                <CommonFields
                    inputType={INPUT_TYPE.TEXT}
                    readOnly={readOnly}
                    multiLine={true}
                    subtitle={otherOptionProps.subtitle || otherSubtitle}
                    reactHookFormRegisterOptions={otherOptionProps.reactHookFormRegisterOptions || {}}
                    label={otherOptionProps.label || "Other"}
                    name={otherOptionField}
                    className={otherOptionProps.className || ""}
                    placeholder={otherOptionProps.placeholder || ""}
                />
            )}
        </Fragment>
    );
};

export default CommonCheckBoxField;
