import { EntityCreateInput } from "@/__generated__/apiSchemas";

const getEntityDetails = (entity: EntityCreateInput) => {
    switch (entity.business) {
        case "Registered Under Company Act":
            return {
                business: entity.business,
                entityName: entity?.entityName,
                entityField: "companyName",
                entityFieldLabel: "Company Name",
                personalField: "directors",
                personalFieldLabel: "Director",
                membersDetails: entity.shareholdingDirectors.directorDetails,
                membersField: "shareholdingDirectors.directorDetails",
            };

        case "Partnership":
            return {
                business: entity.business,
                entityName: entity?.entityName,
                entityField: "firmName",
                entityFieldLabel: "Firm Name",
                personalField: "partners",
                personalFieldLabel: "Partner",
                membersDetails: entity.ownershipDetails.partnerDetails,
                membersField: "ownershipDetails.partnerDetails",
            };

        case "other":
            return {
                business: entity.otherBusiness,
                entityName: entity?.entityName,
                entityField: "firmName",
                entityFieldLabel: "Firm Name",
                personalField: "persons",
                personalFieldLabel: "Person",
                membersDetails: entity.managementDetails.names,
                membersField: "managementDetails.names",
            };

        case "Individual":
            return {
                business: entity.business,
                entityName: entity?.entityName,
                entityField: "individualName",
                entityFieldLabel: "Individual Name",
                personalField: "personal",
                personalFieldLabel: "",
                membersDetails: [],
                membersField: "",
            };
        default:
            return {
                business: entity.business,
                entityName: entity?.entityName,
                entityField: "firmName",
                entityFieldLabel: "Firm Name",
                personalField: "personal",
                personalFieldLabel: "",
                membersDetails: [],
                membersField: "",
            };
    }
};

export default getEntityDetails;
