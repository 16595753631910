import { Fragment, memo, useEffect, useLayoutEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { Typography, Autocomplete, TextField, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE, OTHER } from "globalConstants";
import { useCompanyNames, useUploadFile } from "hooks";
import isEqual from "lodash/isEqual";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

type Props = {
    index: number;
    appendNewWorkOrder: (index: number) => void;
    prefix: string;
    pane?: string;
    setCurrentInput: any;
};

const WorkOrderItem = ({ index: externalIndex, setCurrentInput, appendNewWorkOrder, prefix, pane }: Props) => {
    const workOrder = useWatch({ name: `${prefix}.${externalIndex}` });
    const errors = useWatch({ name: `errors.${prefix}.${externalIndex}` });
    const { setValue, getValues, register } = useFormContext();
    const activeFields: string[] = ["hasWorkOrder"];

    const { companyNames, isCompaniesLoading, setCompanyName } = useCompanyNames();

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${externalIndex}.${item}`) === "" || getValues(`${prefix}.${externalIndex}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.${externalIndex}.errorsCounts`, errorsCounts);
        if (workOrder.contractorType === "Company") setCompanyName(workOrder?.contractorName);
    }, [workOrder]);

    return (
        <CommonAccordion
            title={`Work Order ${externalIndex === 0 ? "" : externalIndex + 1}`}
            data-testid={generateTestId(`Work Order ${externalIndex === 0 ? "" : externalIndex + 1}-${pane}`, "accordion")}
            tab="income"
        >
            {
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    {fixture.map(
                        (
                            { name, inputType, options, subtitle, label, showMask, reactHookFormRegisterOptions, clarificatoryText, dataTestId },
                            index
                        ) => {
                            if (name === "contractorName" && workOrder?.contractorType === "Company") {
                                activeFields.push(name);
                                return (
                                    <Fragment key={index}>
                                        <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                                            <Grid item xs={6}>
                                                <Typography
                                                    className="common-label"
                                                    data-testid={generateTestId(`${label}-work-order-${externalIndex + 1}-${pane}`, "field-label")}
                                                >
                                                    {label}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={6}>
                                                <Autocomplete
                                                    freeSolo
                                                    disablePortal
                                                    disabled={localStorage.getItem("disable_form") === "true"}
                                                    className="common__mui__textfield"
                                                    options={companyNames}
                                                    value={workOrder?.contractorName}
                                                    data-testid={generateTestId(
                                                        `${dataTestId || label}-work-order-${externalIndex + 1}-${pane}`,
                                                        "field"
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            disabled={localStorage.getItem("disable_form") === "true" || isCompaniesLoading}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                startAdornment: isCompaniesLoading ? (
                                                                    <CircularProgress color="inherit" size={20} />
                                                                ) : null,
                                                            }}
                                                            className="autocomplete-input"
                                                            //@ts-ignore
                                                            {...register(`${prefix}.${externalIndex}.${name}`)}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Fragment>
                                );
                            }
                            if (name === "workOrder")
                                return (
                                    <CommonFields
                                        inputType={INPUT_TYPE.FILE}
                                        label="Upload Work Order"
                                        data-testid={generateTestId(`upload-work-order-work-order-${externalIndex + 1}-${pane}`, "field")}
                                        value={workOrder?.workOrder}
                                        name={`${prefix}.${externalIndex}.workOrder`}
                                        readOnly={localStorage.getItem("disable_form") === "true"}
                                        handleChange={useUploadFile({
                                            name: "workOrder",
                                            fileAddress: `${prefix}.${externalIndex}.workOrder`,
                                        })}
                                        showCross={
                                            localStorage.getItem("disable_form") !== "true" &&
                                            workOrder?.workOrder &&
                                            workOrder?.workOrder?.link !== ""
                                        }
                                        handleFileModalOpen={() =>
                                            setCurrentInput({
                                                name: `${prefix}.${externalIndex}.workOrder`,
                                                open: true,
                                                multiple: false,
                                                position: 0,
                                            })
                                        }
                                    />
                                );
                            return (
                                <CommonFields
                                    key={index}
                                    showMask={showMask}
                                    options={options}
                                    prop={{ value: clarificatoryText || "" }}
                                    inputType={inputType}
                                    subtitle={subtitle}
                                    readOnly={localStorage.getItem("disable_form") === "true"}
                                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                    ignoreOthers={true}
                                    label={label}
                                    name={`${prefix}.${externalIndex}.${name}`}
                                    data-testid={generateTestId(`${dataTestId || label}-work-order-${externalIndex + 1}-${pane}`, "field")}
                                />
                            );
                        }
                    )}
                </Grid>
            }
        </CommonAccordion>
    );
};

export default memo(WorkOrderItem, (prevProps, nextProps) => isEqual(prevProps, nextProps));
