import DocumentForm from "./DocumentForm";
import INPUT_TYPE from "globalConstants/inputType";
import BusinessTab from "./components/BusinessTab";
import PersonalTab from "./components/PersonalTab";
import PostDisbursalTab from "./components/PostDisbursalTab";
import RequirementForm from "./components/BusinessTab/components/RequirementForm/RequirementForm";
import EntityForm from "./components/BusinessTab/components/EntityForm/EntityForm";
import IncomeForm from "./components/BusinessTab/components/IncomeForm/IncomeForm";
import PersonalForm from "./components/PersonalTab/components/PersonalForm/PersonalForm";
import PostDisbursalForm from "./components/PostDisbursalTab/components/PostDisbursalForm/PostDisbursalForm";
export default DocumentForm;

export { INPUT_TYPE, PostDisbursalForm, PersonalForm, EntityForm, IncomeForm, BusinessTab, RequirementForm, PersonalTab, PostDisbursalTab };
