import { useWatch } from "react-hook-form";
import { NameAndShareCreateInput } from "@/__generated__/apiSchemas";
import { OTHER } from "globalConstants";

const getFields = ({
    noOfPartners,
    noOfDirectors,
    noOfPersons,
    directorDetails = [],
    partnerDetails = [],
    business,
    entityType,
    entity,
    index,
    totalLength = 0,
}: {
    noOfPartners: number;
    noOfDirectors: number;
    noOfPersons: number;
    index: number;
    business: string;
    entity?: any;
    directorDetails?: NameAndShareCreateInput[];
    partnerDetails?: NameAndShareCreateInput[];
    entityType: "applicant" | "coborrower" | "guarantor";
    totalLength?: number;
}) => {
    let titlePrefix = "";

    switch (entityType) {
        case "coborrower":
            if (totalLength > 1) {
                titlePrefix = `Co-Borrower ${index + 1}`;
            } else {
                titlePrefix = `Co-Borrower`;
            }

            break;
        case "guarantor":
            if (totalLength > 1) {
                titlePrefix = `Guarantor ${index + 1}`;
            } else {
                titlePrefix = `Guarantor`;
            }
            break;
    }

    switch (business) {
        case "Individual":
            return [
                {
                    entityType,
                    business,
                    externalIndex: index,
                    internalIndex: 0,
                    title: `${titlePrefix} ${entityType === "applicant" ? "Individual" : entity.entity.entityName}`,
                },
            ];
        case "Sole Proprietor":
            return [
                {
                    entityType,
                    business,
                    externalIndex: index,
                    internalIndex: 0,
                    title: `${titlePrefix}  ${entityType === "applicant" ? "Sole Proprietor" : ""}`,
                },
            ];
        case "Partnership":
            // return Array.from({ length: noOfPartners }, (_, i) => ({
            //     entityType,
            //     business,
            //     externalIndex: index,
            //     internalIndex: i,
            //     title: `${titlePrefix} Partner ${i + 1}`,
            // }));
            return partnerDetails
                .map((detail, i) => ({
                    applicantEntityType: detail.entityType,
                    entityType,
                    business,
                    externalIndex: index,
                    internalIndex: i,
                    title: `${titlePrefix} Partner ${i + 1}`,
                }))
                .filter((detail) => (detail.applicantEntityType ? detail.applicantEntityType === "applicant" : true));
        case "Registered Under Company Act":
            // return Array.from({ length: noOfDirectors }, (_, i) => ({
            //     entityType,
            //     business,
            //     externalIndex: index,
            //     internalIndex: i,
            //     title: `${titlePrefix} Director ${i + 1}`,
            // }));
            return directorDetails
                .map((detail, i) => ({
                    applicantEntityType: detail.entityType,
                    entityType,
                    business,
                    externalIndex: index,
                    internalIndex: i,
                    title: `${titlePrefix} Director ${i + 1}`,
                }))
                .filter((detail) => (detail.applicantEntityType ? detail.applicantEntityType === "applicant" : true));
        case OTHER:
            return Array.from({ length: noOfPersons }, (_, i) => ({
                entityType,
                business,
                externalIndex: index,
                internalIndex: i,
                title: `${titlePrefix} Person ${i + 1}`,
            }));
        default:
            return [];
    }
};

const usePersonalTabTitles = () => {
    // logic for personal:Applicant
    const applicant = useWatch({ name: `applicant.entity` });
    const coborrowers = useWatch({ name: `coborrower` });
    const guarantors = useWatch({ name: `guarantor` });

    const hasGuarantor = useWatch({
        name: `requirement.hasGuarantor`,
    });

    const hasCoborrower = useWatch({
        name: `requirement.hasCoBorrower`,
    });

    const applicantFields = getFields({
        noOfPartners: applicant?.ownershipDetails?.noOfPartners,
        noOfDirectors: applicant?.shareholdingDirectors?.noOfDirectors,
        noOfPersons: applicant?.managementDetails?.noOfPersons,
        directorDetails: applicant?.shareholdingDirectors?.directorDetails,
        partnerDetails: applicant?.ownershipDetails?.partnerDetails,
        business: applicant?.business,
        entityType: "applicant",
        index: 0,
    });

    const coborrowerFields =
        hasCoborrower == true || hasCoborrower == true
            ? coborrowers.map((coborrower: any, index: number) => {
                  return getFields({
                      noOfPartners: coborrower?.entity?.ownershipDetails?.noOfPartners,
                      noOfDirectors: coborrower?.entity?.shareholdingDirectors?.noOfDirectors,
                      noOfPersons: coborrower?.entity?.managementDetails?.noOfPersons,
                      directorDetails: coborrower?.entity?.shareholdingDirectors?.directorDetails,
                      partnerDetails: coborrower?.entity?.ownershipDetails?.partnerDetails,
                      business: coborrower?.entity?.business,
                      entityType: "coborrower",
                      index,
                      entity: coborrower,
                      totalLength: coborrowers?.length,
                  });
              })
            : [];

    const guarantorFields =
        hasGuarantor == true || hasGuarantor == true
            ? guarantors.map((guarantor: any, index: number) =>
                  getFields({
                      noOfPartners: guarantor?.entity?.ownershipDetails?.noOfPartners,
                      noOfDirectors: guarantor?.entity?.shareholdingDirectors?.noOfDirectors,
                      noOfPersons: guarantor?.entity?.managementDetails?.noOfPersons,
                      business: guarantor?.entity?.business,
                      directorDetails: guarantor?.entity?.shareholdingDirectors?.directorDetails,
                      partnerDetails: guarantor?.entity?.ownershipDetails?.partnerDetails,
                      entityType: "guarantor",
                      entity: guarantor,
                      index,
                      totalLength: guarantors?.length,
                  })
              )
            : [];

    return [...applicantFields, ...coborrowerFields.flat(), ...guarantorFields.flat()];
};

export default usePersonalTabTitles;
