import React, { useState } from "react";
import toast from "react-hot-toast";
import { useMutation } from "react-query";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useForgetPassword } from "__generated__/apiComponents";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle style={{ lineHeight: "1.4rem", letterSpacing: "0.0075em", padding: "6px 24px" }} sx={{ m: 0, p: 0 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    data-testid="close-forgot-password-button"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 6,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type ForgotPaswdProps = {
    handleClickOpen: (params: any) => any;
    handleClose: () => void;
    open: boolean;
};

export default function CustomizedDialogs(props: ForgotPaswdProps) {
    const { mutate: handleForgotPassword } = useForgetPassword({
        onSuccess: (data: any) => {
            if (data) {
                toast.dismiss();
                toast.success(data);
                props.handleClose();
                setEmail("");
            }
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message ?? error?.message ?? "Something went wrong");
        },
    });

    const validateEmail = (email: string) => {
        let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

        if (email.match(validRegex)) {
            return true;
        } else {
            return false;
        }
    };

    const [email, setEmail] = useState("");

    const handleSend = () => {
        if (email === "") {
            toast.error("Please enter your email address");
            return;
        }

        if (!validateEmail(email)) {
            toast.error("Please enter a valid email address");
            return;
        }

        handleForgotPassword({
            body: { email, isFrontOffice: true },
        });

        // props.handleClose();
    };

    return (
        <div>
            <BootstrapDialog
                onClose={() => {
                    props.handleClose();
                    setEmail("");
                }}
                aria-labelledby="forgot-password-dialog-title"
                open={props.open}
            >
                <BootstrapDialogTitle
                    id="forgot-password-dialog-title"
                    onClose={() => {
                        props.handleClose();
                        setEmail("");
                    }}
                >
                    <h3 data-testid="forgot-password-title" style={{ fontSize: "24px", fontWeight: "bolder", padding: 8 }}>
                        Forgot Password
                    </h3>
                </BootstrapDialogTitle>
                <DialogContent>
                    <FormControl className="field" sx={{ width: { xs: "45ch", sm: "55ch" }, m: 1 }}>
                        <label data-testid="email-forgot-password-field-label">{""}</label>
                        <TextField
                            className="common__mui__textfield"
                            name="email"
                            value={email}
                            onChange={(e) => {
                                setEmail(e.target.value);
                            }}
                            inputProps={{ "data-testid": "email-forgot-password-field" }}
                            placeholder="Enter your email address"
                            type="text"
                        />
                        <FormHelperText id="helperText"></FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button
                        data-testid="cancel-forgot-password-button"
                        variant="text"
                        onClick={() => {
                            props.handleClose();
                            setEmail("");
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        data-testid="send-forgot-password-button"
                        variant="contained"
                        onClick={handleSend}
                        style={{ borderRadius: "1rem", backgroundColor: "#f15a29" }}
                    >
                        Send
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
