import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "isFirstTimeUser",
        label: "First Time User",
        inputType: INPUT_TYPE.RADIO,
        editable: true,
        options: ["yes", "no"],
        defaultValue: "",
        subtitle: "First Time Using CE Machine ?",
    },
    {
        name: "isFirstTimeBuyer",
        label: "First Time Buyer",
        inputType: INPUT_TYPE.RADIO,
        options: ["yes", "no"],
        defaultValue: "",
        editable: true,
        subtitle: "First Time Buying CE Machine ?",
    },
    {
        name: "endUse",
        label: "End Use",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        extendedOptions: {
            isFirstTimeUser: {
                options: ["Own Use"],
                defaultValue: "Own Use",
            },
            isNotFirstTimeUser: {
                options: ["Hirer", "Own Use"],
                defaultValue: "Own Use",
            },
        },
        defaultValue: "Own Use",
    },
    {
        name: "hiringRate",
        label: "Hiring Rate",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        clarificatoryText: "Rate at which you rent out the machine",
    },
    {
        name: "hiringRateUnit",
        label: "",
        inputType: INPUT_TYPE.SELECT,
        defaultValue: "Per Month",
        options: ["Per Month", "Per Hour", "Per Week"],
        editable: true,
        dataTestId: "hiring-rate-unit-field",
    },
    {
        name: "rentingRate",
        label: "Renting Rate",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        clarificatoryText: " Rate at which you rent if you don’t buy",
    },
    {
        name: "rentingRateUnit",
        label: "",
        inputType: INPUT_TYPE.SELECT,
        defaultValue: "Per Month",
        options: ["Per Month", "Per Hour", "Per Week"],
        editable: true,
        dataTestId: "renting-rate-unit-field",
    },
    {
        name: "monthlyHours",
        label: "Monthly Hours",
        editable: true,
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        name: "isWorkContract",
        label: "Work Contract",
        inputType: INPUT_TYPE.RADIO,
        options: ["yes", "no"],
        editable: true,
        defaultValue: "",
        subtitle: "Confirmed Work Order",
        clarificatoryText: "Have you got a work contract?",
    },
    {
        name: "revenueSourcing",
        label: "Revenue Sourcing",
        inputType: INPUT_TYPE.CHECKBOX,
        editable: true,
        options: ["Broker Network", "Existing Customers", "Private Bidding from Organisations", "Public Bidding from Organisations", OTHER],
    },
    {
        name: "otherRevenueSourcing",
        label: "Other",
        subtitle: "Specify Revenue Sourcing",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-revenue-sourcing-text-field",
    },
    {
        name: "customerType",
        label: "Customer Type",
        inputType: INPUT_TYPE.RADIO,
        options: ["Company", OTHER],
        editable: true,
        defaultValue: "",
    },
    {
        name: "customer",
        label: "Customer",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        defaultValue: "",
        clarificatoryText: "Name of customer/organisation",
        showMask: true,
    },
    {
        name: "expectedOrderValue",
        label: "Expected Order Value",
        inputType: INPUT_TYPE.PRICE,
        clarificatoryText: "Total Order Value",
        editable: true,
        defaultValue: "",
    },
    {
        name: "businessActivity",
        label: "Business Activity",
        inputType: INPUT_TYPE.CHECKBOX,
        clarificatoryText: "Activity For New Machine",
        editable: true,
        options: ["Infrastructure", "Civil Work", "Farming", "Material Handling", "Mines", "Ports", "Quarry", OTHER],
    },
    {
        name: "otherSubSector",
        label: "Other",
        subtitle: "Specify Sector",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-business-activity-text-field",
    },
    {
        name: "infrastructure",
        label: "Infrastructure",
        inputType: INPUT_TYPE.CHECKBOX,
        editable: true,
        options: ["Airports", "Railways", "Road", OTHER],
    },
    {
        name: "otherInfrastructure",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Specify Sector",
        editable: true,
        dataTestId: "other-infrastructure-text-field",
    },
    {
        name: "civilWork",
        editable: true,
        label: "Civil Work",
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Corporate Building", "Factory Building", "Residential/Hotel/Hospital", OTHER],
    },
    {
        name: "otherCivilWork",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Specify Sector",
        editable: true,
        dataTestId: "other-civil-work-text-field",
    },
    {
        name: "farming",
        label: "Farming",
        editable: true,
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Agriculture Farming", OTHER],
    },
    {
        name: "otherFarming",
        label: "Other",
        subtitle: "Specify Sector",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-farming-text-field",
    },
    {
        name: "materialHandling",
        editable: true,
        label: "Material Handling",
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Material Handler", OTHER],
    },
    {
        name: "otherMaterialHandling",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Specify Sector",
        editable: true,
        dataTestId: "other-material-handling-text-field",
    },
    {
        name: "mines",
        editable: true,
        label: "Mines",
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Coal", "Iron Ore", OTHER],
    },
    {
        name: "otherMines",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Specify Sector",
        editable: true,
        dataTestId: "other-mines-text-field",
    },
    {
        name: "ports",
        editable: true,
        label: "Ports",
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Material Handling", "Port Development", OTHER],
    },
    {
        name: "otherPorts",
        label: "Other",
        subtitle: "Specify Sector",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-ports-text-field",
    },
    {
        name: "quarry",
        editable: true,
        label: "Quarry",
        inputType: INPUT_TYPE.CHECKBOX,
        options: ["Granite", "Marble", "Stone", OTHER],
    },

    {
        name: "otherQuarry",
        label: "Other",
        subtitle: "Specify Sector",
        inputType: INPUT_TYPE.TEXT,
        editable: true,
        dataTestId: "other-quarry-text-field",
    },

    {
        name: "machineExperience",
        label: "Machine Experience",
        clarificatoryText: "No of years of using similar machine",
        subtitle: "(0-30) Years",
        inputType: INPUT_TYPE.NUMBER,
        editable: true,
        reactHookFormRegisterOptions: {
            max: {
                value: 30,
                message: "Maximum 30 years are allowed",
            },
            min: { value: 0, message: "Minimum 0 years are allowed" },
            setValueAs(value) {
                return Number(value);
            },
        },
    },
];

export default fixture;
