import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Button from "@mui/material/Button";
import { defaultEquipmentFixture } from "fixtures";
import "styles/common.scss";

type Props = {
    append: any;
};

const AddAnotherEquipment = ({ append }: Props) => {
    return (
        <Button
            onClick={() => append(defaultEquipmentFixture)}
            className="common-fullwidth-button"
            disabled={localStorage.getItem("disable_form") === "true"}
            style={{ marginBottom: "1rem" }}
            data-testid="add-another-equipment-button"
        >
            <AddCircleOutlineRoundedIcon fontSize="small" className="AddEquipmentIcon" />
            <span>Add Another Equipment</span>
        </Button>
    );
};

export default AddAnotherEquipment;
