import { ChangeEvent, useRef, useState } from "react";
import { useSignOut } from "react-auth-kit";
import { useFormContext } from "react-hook-form";
import { toast } from "react-hot-toast";
import { getImageSize } from "react-image-size";
import { useNavigate } from "react-router-dom";
import { useUpload } from "__generated__/apiComponents";
import axios from "axios";
import { ROUTES } from "globalConstants";
import useSaveForm from "./useSaveForm";

type Props = {
    fileAddress: string;
    yearIndex?: number;
    name: string;
    associatedDate?: string;
    showToast?: boolean;
    typeAccepted?: string[];
    callback?: (downloadData: any) => void;
    multiple?: boolean;
    isAsset?: boolean;
    dimensionCheck?: {
        width: number;
        height: number;
    };
};

const useUploadFile = ({
    name,
    associatedDate,
    yearIndex,
    typeAccepted,
    dimensionCheck,
    isAsset = false,
    fileAddress,
    callback,
    showToast = true,
    multiple = false,
}: Props) => {
    const formId = localStorage.getItem("form_id");
    const { setValue, getValues } = useFormContext();
    const [files, setFiles] = useState<FileList | null>(null);
    const signOut = useSignOut();
    const navigate = useNavigate();
    const saveForm = useSaveForm(false);
    const toastId = useRef<any>(null);

    // async function checkImageDimensions(data: any) {
    //     try {
    //         const dimensions = await getImageSize(data?.link);
    //         if (dimensionCheck && (dimensions?.width > dimensionCheck?.width || dimensions?.height > dimensionCheck?.height)) {
    //             toast.dismiss();
    //             toast.error(`Image dimensions should be ${dimensionCheck?.width}x${dimensionCheck?.height}`);
    //         } else {
    //             toast.dismiss();
    //             toast.success("File Uploaded Successfully");
    //             setValue(fileAddress, data);
    //         }
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    const { mutate: upload } = useUpload({
        onSuccess: async (data: any) => {
            if (!files) return;
            let counter = 0;

            try {
                await Promise.all(
                    data.map(async (item: any, i: number) => {
                        const formData = new FormData();
                        counter++;
                        for (let i in item?.uploadParams) {
                            formData.append(i, item?.uploadParams[i]);
                        }
                        formData.append("file", files[i]);
                        return axios.post(item?.uploadUrl, formData, {
                            onUploadProgress(progressEvent) {
                                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                                toast.loading(`Uploading files (${percentCompleted}%)`, { id: toastId.current });
                            },
                        });
                    })
                );

                if (data?.length >= 1) {
                    if (showToast) {
                        toast.success(`Files Uploaded`, { id: toastId.current });
                    } else {
                        toast.dismiss(toastId.current);
                    }
                    toastId.current = null;
                    const downloadData = data?.map((item: any, i: number) => ({
                        link: item?.downloadUrl,
                        filename: files[i]?.name,
                        year_index: yearIndex,
                        associated_date: associatedDate,
                        upload_date: new Date(),
                    }));

                    if (fileAddress.includes("creditBureau.report")) {
                        setValue(fileAddress, downloadData[0]);
                        callback?.(downloadData);
                    } else if (fileAddress.includes("0.filledExcel")) {
                        callback?.(downloadData);
                    } else if (
                        fileAddress.includes("document.business.bankAccount") ||
                        fileAddress.includes("document.business.loanAccountAdditional") ||
                        fileAddress.includes("document.business.loanAccountCredit")
                    ) {
                        setValue(fileAddress, downloadData);
                        callback?.(downloadData);
                    } else {
                        if (downloadData.length === 1 && !multiple) setValue(fileAddress, downloadData[0]);
                        else setValue(fileAddress, downloadData);
                        saveForm();
                    }
                }
            } catch (error) {}
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message ?? "Something went wrong");
            if (error?.stack?.message === "Session Expired") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });

    const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files;
        if (!file) return;

        const requestBody: {
            fileName: string;
            path: string;
            fileSize: number;
        }[] = [];

        for (let i = 0; i < file.length; i++) {
            if (typeAccepted && !typeAccepted.includes(file[i].type)) {
                toast.error("File type not supported");
                return;
            }
            if (file[i]?.size > 5 * 1024 * 1024) {
                toast.error("File size should be less than 5MB");
                return;
            }

            let key = `${formId}/data/${fileAddress.replace(/\./g, "/")}`;
            requestBody.push({
                fileName: file[i]?.name,
                path: key,
                fileSize: file[i]?.size,
            });
        }

        toast.dismiss();
        if (toastId.current === null) toastId.current = toast.loading("Uploading files");

        upload({
            body: requestBody,
        });
        setFiles(file);
        return "File Uploaded Successfully";
    };

    return handleUploadFile;
};

export default useUploadFile;
