import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import OtpInput from "react-otp-input";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useGetCompanyInfo } from "__generated__/apiComponents";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { defaultPersonalDetailsWithKYCFixture } from "fixtures";
import { OTHER } from "globalConstants";
import { useAadharVerify, useCompanyNames, useError, useGstVerify, usePanVerify } from "hooks";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";
import getEntityDetails from "utils/getEntityDetails";
import fixture from "./fixture";

const EntityTab = ({ header, index: indexFromProp, prefix, pane }: { header: string; index: number; prefix: string; pane: string }) => {
    const entity = useWatch({ name: `${prefix}entity` });
    const applicantEntity = useWatch({ name: `applicant.entity` });
    const errors = useWatch({ name: `errors.${prefix}entity` });
    const showError = useError();
    const aadhar = useWatch({ name: `${prefix}entity.aadhar` });
    const gst = useWatch({ name: `${prefix}entity.gst` });
    const pan = useWatch({ name: `${prefix}entity.pan` });
    const business = useWatch({ name: `${prefix}entity.business` });
    const [show, setShow] = useState(entity.incorporationType !== "");
    const [companyFound, setCompanyFound] = useState(false);
    const { register } = useFormContext();

    const { companyNames, data, setCompanyName, setCompanyType } = useCompanyNames();

    const { mutate: getCompanyInfo } = useGetCompanyInfo({
        onError: showError,
        onSuccess: (data: any) => {
            if (data) {
                const companyInfo = data;
                setShow(true);
                let incorporationType = companyInfo?.class.toLowerCase().includes("private") ? "Private" : "Public";
                let date = new Date(companyInfo?.["date_of_registration"] * 1000).toISOString();
                const rocLocation = companyInfo?.roc;
                const postalCode = companyInfo?.address?.match(/\d{6}/)?.[0] ?? "";
                const addressFields = companyInfo?.address?.replace(postalCode, "")?.split(",");

                const registeredAddress = {
                    ...entity.registeredAddress,
                    rocLocation,
                    // @ts-ignore
                    address1: addressFields[0],
                    // @ts-ignore
                    address2: addressFields[1],
                    address3: addressFields[2],
                    landmark: addressFields[3],
                    // @ts-ignore
                    postalCode: !isNaN(Number(postalCode)) ? postalCode : "",
                    // @ts-ignore
                    city: "",
                    // @ts-ignore
                    state: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    // in case of rentType === "Monthly Rent PM"
                    monthlyRent: 0,
                    // in case of rentType === "Owner Name"
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                };
                setValue(`${prefix}entity.dateOfEstablishment`, date);
                setValue(`${prefix}entity.incorporationType`, incorporationType);
                setValue(`${prefix}entity.registeredAddress`, registeredAddress);
                setValue(`${prefix}entity.cin`, companyInfo?.cin);
                if (companyInfo?.["company_type"] === "LLP") {
                    let numberOfPartners = (companyInfo?.["number_of_partners"] ?? 0) + (companyInfo?.["number_of_designated_partners"] ?? 0);
                    let partnerDetails = Array.from({ length: numberOfPartners }).map((partner) => ({
                        name: "",
                        sharePercentage: 0,
                        entityType: "applicant",
                    }));
                    if (companyInfo?.director_details) {
                        partnerDetails = companyInfo?.["director_details"].map((director: any) => ({
                            name: director["Director Name"],
                            sharePercentage: 0,
                            entityType: "applicant",
                        }));
                        numberOfPartners = companyInfo?.["director_details"].length;
                        const partners = Array.from({ length: numberOfPartners }).map((item) =>
                            JSON.parse(JSON.stringify(defaultPersonalDetailsWithKYCFixture))
                        );
                        setValue(`${prefix}personal.partners`, partners);
                        companyInfo?.["director_details"].forEach((director: any, i: number) => {
                            setValue(`${prefix}personal.partners.${i}.kyc.din`, director["DIN"]);
                            let [firstName, middleName, lastName] = director["Director Name"]?.split(" ");
                            if (!firstName) firstName = "";
                            if (!lastName) {
                                lastName = middleName;
                                middleName = "";
                            }
                            if (!middleName && !lastName) {
                                lastName = "";
                                middleName = "";
                            }
                            setValue(`${prefix}personal.partners.${i}.yourDetails.firstName`, firstName);
                            setValue(`${prefix}personal.partners.${i}.yourDetails.middleName`, middleName);
                            setValue(`${prefix}personal.partners.${i}.yourDetails.lastName`, lastName);
                        });
                    }
                    setValue(`${prefix}income.income.businessIncome.obligation`, companyInfo?.["obligation_of_contribution"]);
                    setValue(`${prefix}entity.ownershipDetails.noOfPartners`, numberOfPartners);
                    setValue(`${prefix}entity.ownershipDetails.partnerDetails`, partnerDetails);
                    setValue(`${prefix}entity.entityName`, companyInfo?.["company_name"]);
                } else {
                    if (companyInfo?.director_details) {
                        const directorDetails = companyInfo?.["director_details"].map((director: any) => ({
                            name: director["Director Name"],
                            sharePercentage: 0,
                            entityType: "applicant",
                        }));
                        const numberOfDirectors = companyInfo?.["director_details"].length;
                        const directors = Array.from({ length: numberOfDirectors }).map((item) =>
                            JSON.parse(JSON.stringify(defaultPersonalDetailsWithKYCFixture))
                        );
                        setValue(`${prefix}personal.directors`, directors);
                        companyInfo?.["director_details"].forEach((director: any, i: number) => {
                            setValue(`${prefix}personal.directors.${i}.kyc.din`, director["DIN"]);
                            let [firstName, middleName, lastName] = director["Director Name"]?.split(" ");
                            if (!firstName) firstName = "";
                            if (!lastName) {
                                lastName = middleName;
                                middleName = "";
                            }
                            if (!middleName && !lastName) {
                                lastName = "";
                                middleName = "";
                            }
                            setValue(`${prefix}entity.shareholdingDirectors.noOfDirectors`, companyInfo["director_details"].length);
                            setValue(`${prefix}entity.shareholdingDirectors.directorDetails`, directorDetails);
                            setValue(`${prefix}personal.directors.${i}.yourDetails.firstName`, firstName);
                            setValue(`${prefix}personal.directors.${i}.yourDetails.middleName`, middleName);
                            setValue(`${prefix}personal.directors.${i}.yourDetails.lastName`, lastName);
                        });
                    }
                    setValue(`${prefix}income.income.businessIncome.authorized`, companyInfo?.authorized);
                    setValue(`${prefix}income.income.businessIncome.paidUp`, companyInfo?.["paid_up"]);
                    setValue(`${prefix}entity.entityName`, companyInfo?.["company_name"]);
                }
            }
        },
    });

    const handleSelectCompany = (value: string) => {
        getCompanyInfo({
            body: {
                cin: value,
            },
        });
    };

    useEffect(() => {
        //@ts-ignore
        if (data?.length === 0) {
            setCompanyFound(false);
            setValue(`${prefix}entity.dateOfEstablishment`, "");
            setValue(`${prefix}entity.incorporationType`, "");
            setValue(`${prefix}entity.cin`, "");
            setShow(true);
        } else {
            setCompanyFound(true);
        }
    }, [data]);

    const { setValue, getValues } = useFormContext();

    useLayoutEffect(() => {
        if (aadhar) {
            setAadhar(aadhar);
        }
        if (pan) {
            setPan(pan);
        }
        if (gst) {
            setGst(gst);
        }
    }, [pan, aadhar, gst]);

    const { handleOtpSubmit, open, handleClose, aadharVerified, handleAadharValidate, setAadhar, aadharOtp, setAadharOtp, aadharDetails } =
        useAadharVerify();
    const { setPan, panVerified, handlePanValidate } = usePanVerify();
    const { setGst, gstVerified, handleGstValidate, gstDetails } = useGstVerify();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        boxShadow: 24,
        pt: 2,
        px: 4,
        pb: 2,
        borderRadius: "10px",
    };

    useEffect(() => {
        if (aadharDetails !== null) {
            const { full_name, care_of, dob, gender, address, zip } = aadharDetails;

            const yourDetails = {
                // @ts-ignore
                firstName: full_name.split(" ")[0],
                // @ts-ignore
                lastName: full_name.split(" ")[1],
                dateOfBirth: new Date(dob).toLocaleDateString(),
                prefix: "Mr.",
                otherPrefix: "",
                middleName: "",
                maritalStatus: "Single",
                noOfDependents: 0,
                // if maritalStatus married
                noOfChildren: 0,
            };
            const residentialAddress = {
                ...entity.residentialAddress,
                // @ts-ignore
                address1: address.house,
                // @ts-ignore
                address2: address.loc,
                address3: "",
                landmark: "",
                // @ts-ignore
                postalCode: zip,
                // @ts-ignore
                city: address.vtc,
                // @ts-ignore
                state: address.state,
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                // in case of rentType === "Monthly Rent PM"
                monthlyRent: 0,
                // in case of rentType === "Owner Name"
                ownerDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
            };
            setValue(`${prefix}entity.proprietorName`, full_name);
            setValue(`${header === "applicant" ? header : `${header}.${indexFromProp}`}.entity.residentialAddress`, residentialAddress);
            setValue(`${header === "applicant" ? header : `${header}.${indexFromProp}`}.entity.aadharValidated`, true);
            setValue(`${header === "applicant" ? header : `${header}.${indexFromProp}`}.personal.personal.yourDetails`, yourDetails);
        }
        if (gstDetails !== null) {
            //@ts-ignore
            const addressFields = gstDetails?.address.split(",") ?? [];

            const registeredAddress = {
                ...entity.registeredAddress,
                // @ts-ignore
                address1: addressFields[0],
                // @ts-ignore
                address2: addressFields[1],
                address3: addressFields[2],
                landmark: "",
                // @ts-ignore
                postalCode: addressFields[addressFields.length - 1],
                // @ts-ignore
                city: addressFields[addressFields.length - 3],
                // @ts-ignore
                state: addressFields[addressFields.length - 2],
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                // in case of rentType === "Monthly Rent PM"
                monthlyRent: 0,
                // in case of rentType === "Owner Name"
                ownerDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
            };
            setValue(`${header === "applicant" ? header : `${header}.${indexFromProp}`}.entity.registeredAddress`, registeredAddress);
            setValue(`${header === "applicant" ? header : `${header}.${indexFromProp}`}.entity.businessAddress`, registeredAddress);
        }
    }, [aadharDetails, gstDetails]);

    const panValidated = useWatch({
        name: `${prefix}entity.panValidated`,
    });

    const gstValidated = useWatch({
        name: `${prefix}entity.gstValidated`,
    });

    const aadharValidated = useWatch({
        name: `${prefix}entity.aadharValidated`,
    });

    useEffect(() => {
        if (aadharVerified) {
            setValue(`${prefix}entity.aadharValidated`, true);
        }
        if (panVerified) {
            setValue(`${prefix}entity.panValidated`, true);
        }
        if (gstVerified) {
            setValue(`${prefix}entity.gstValidated`, true);
        }
    }, [aadharVerified, panVerified, gstVerified]);

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.entity.${item}`) === "" || getValues(`${prefix}.entity.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.entity.errorsCounts`, errorsCounts);
        if (entity.business === "Partnership" && entity.liabilityType === "Limited") {
            setCompanyType("LLP");
        } else if (entity.business === "Registered Under Company Act") {
            setCompanyType("Company");
        }

        let name = entity.entityName;
        if (entity.business === "Sole Proprietor" || entity.business === "Individual") {
            if (entity.business === "Sole Proprietor") name = entity.proprietorName;
            let [firstName, middleName, lastName] = name.split(" ").filter((name: string) => name);
            if (!firstName) firstName = "";
            if (!lastName) {
                lastName = middleName;
                middleName = "";
            }
            if (!middleName && !lastName) {
                lastName = "";
                middleName = "";
            }
            if (header === "applicant") setValue("contact.full_name", name);
            setValue(`${prefix}personal.personal.yourDetails.firstName`, firstName);
            setValue(`${prefix}personal.personal.yourDetails.middleName`, middleName);
            setValue(`${prefix}personal.personal.yourDetails.lastName`, lastName);
        }
        if (header === "applicant") {
            setValue("contact.businessName", entity.entityName);
        }
    }, [entity]);

    const { membersDetails } = getEntityDetails(applicantEntity);

    let isEntityAssignedByApplicant = false;
    //@ts-ignore
    membersDetails?.forEach((member, i) => {
        if (member?.entityType && member.entityType !== "applicant") {
            const [entity, index] = member.entityType?.split("-");
            const entityType = `${entity}.${Number(index ?? "1") - 1}.`;
            if (prefix === entityType) isEntityAssignedByApplicant = true;
        }
    });

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="Entity Field"
            tab="entity"
            keys={`${header}.entity`}
            data-testid={generateTestId(`entity-field-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(({ name, inputType, options, subtitle, label, readOnly, dataTestId, showMask }, index) => {
                    if (business === "Sole Proprietor") {
                        if (
                            name === "otherBusiness" ||
                            name === "liabilityType" ||
                            name === "incorporationType" ||
                            name === "companyName" ||
                            name === "individualName" ||
                            name === "cin"
                        ) {
                            return "";
                        }
                    }
                    if (name === "proprietorName") {
                        if (aadharVerified) {
                            readOnly = true;
                        }
                    }
                    if (header === "applicant") {
                        if (name === "relationship" || name === "otherRelationship") {
                            return "";
                        }
                    }
                    if (business === "Partnership" || business === "Registered Under Company Act" || business === OTHER) {
                        if (name === "relationship" || name === "otherRelationship") {
                            return "";
                        }
                    }
                    if (business === "Partnership") {
                        if (
                            name === "otherBusiness" ||
                            name === "incorporationType" ||
                            name === "aadhar" ||
                            name === "proprietorName" ||
                            name === "companyName" ||
                            name === "individualName"
                        ) {
                            return "";
                        }
                    }
                    if (business === "Registered Under Company Act") {
                        if (
                            name === "otherBusiness" ||
                            name === "individualName" ||
                            name === "firmName" ||
                            name === "liabilityType" ||
                            name === "aadhar" ||
                            name === "proprietorName"
                        ) {
                            return "";
                        }
                    }
                    if (business === "Individual") {
                        if (
                            name === "otherBusiness" ||
                            name === "gst" ||
                            name === "firmName" ||
                            name === "liabilityType" ||
                            name === "companyName" ||
                            name === "incorporationType" ||
                            name === "dateOfEstablishment" ||
                            name === "proprietorName" ||
                            name === "cin"
                        ) {
                            return "";
                        }
                    }
                    if (business === OTHER) {
                        if (
                            name === "individualName" ||
                            name === "incorporationType" ||
                            name === "liabilityType" ||
                            name === "companyName" ||
                            name === "aadhar" ||
                            name === "proprietorName" ||
                            name === "cin"
                        ) {
                            return "";
                        }
                    }
                    if (!show && business === "Registered Under Company Act") {
                        if (name === "incorporationType" || name === "dateOfEstablishment") return "";
                    }
                    if (
                        name === "entityName" &&
                        (entity.business === "Registered Under Company Act" ||
                            (entity.business === "Partnership" && entity.liabilityType === "Limited"))
                    ) {
                        activeFields.push(name);
                        return (
                            <Fragment key={index}>
                                <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                                    <Grid item xs={6}>
                                        <Typography className="common-label" data-testid={generateTestId(`${label}-${pane}`, "field-label")}>
                                            {getEntityDetails(entity).entityFieldLabel}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            freeSolo
                                            disablePortal
                                            disabled={localStorage.getItem("disable_form") === "true"}
                                            className="common__mui__textfield"
                                            options={companyNames}
                                            value={entity?.entityName}
                                            data-testid={generateTestId(`${label}-${pane}`, "field")}
                                            onChange={(e, value) => {
                                                if (value?.id) {
                                                    handleSelectCompany(value.id);
                                                }
                                            }}
                                            onInputChange={(event, value) => {
                                                setCompanyName(value);
                                                if (value.length === 0) {
                                                    setValue(`${prefix}entity.dateOfEstablishment`, "");
                                                    setValue(`${prefix}entity.incorporationType`, "");
                                                    setShow(false);
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    disabled={localStorage.getItem("disable_form") === "true"}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        // startAdornment: isCompaniesLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                                    }}
                                                    className="autocomplete-input"
                                                    {...register(`${prefix}entity.${name}`)}
                                                    data-testid={`${prefix}entity.${name}`}
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            </Fragment>
                        );
                    }
                    activeFields.push(name);
                    return (
                        <Fragment key={index}>
                            <CommonFields
                                inputType={inputType}
                                name={`${prefix}entity.${name}`}
                                subtitle={subtitle}
                                data-testid={generateTestId(`${dataTestId || label}-${pane}`, "field")}
                                showMask={showMask}
                                readOnly={
                                    localStorage.getItem("disable_form") === "true" ||
                                    (name === "business" && isEntityAssignedByApplicant) ||
                                    (name === "incorporationType" || name === "dateOfEstablishment" || name === "cin"
                                        ? entity?.business === "Registered Under Company Act" || entity?.business === "Partnership"
                                            ? companyFound
                                                ? true
                                                : false
                                            : false
                                        : readOnly)
                                }
                                options={
                                    options?.map((value) => ({
                                        value,
                                        label: convertSnakeCaseToHeaderCase(value),
                                    })) || []
                                }
                                ignoreOthers={name === "business" ? true : false}
                                label={name === "entityName" ? getEntityDetails(entity).entityFieldLabel : label}
                                {...(name === "aadhar" && { onlyNumbers: true })}
                                {...(name === "aadhar" && aadharValidated
                                    ? { verified: true }
                                    : name === "pan" && panValidated
                                    ? { verified: true }
                                    : name === "gst" && gstValidated
                                    ? { verified: true }
                                    : {})}
                                {...(name === "aadhar" && !aadharVerified
                                    ? { handleValidate: handleAadharValidate }
                                    : name === "pan" && !panValidated
                                    ? { handleValidate: handlePanValidate }
                                    : name === "gst" && !gstVerified
                                    ? { handleValidate: handleGstValidate }
                                    : {})}
                                {...(name === "aadhar" || name === "pan" || name === "gst"
                                    ? {
                                          isValidate: true,
                                          reactHookFormRegisterOptions: {
                                              setValueAs(value) {
                                                  return value.toUpperCase();
                                              },
                                              value: name === "gst" ? gst : name === "pan" ? pan : aadhar,
                                          },
                                      }
                                    : {})}
                                {...(name === "grossProfit" || name === "netProfit" || name === "income"
                                    ? { className: "common__mui__textfield-grey" }
                                    : {})}
                            />
                        </Fragment>
                    );
                })}
                <Modal hideBackdrop open={open} onClose={handleClose}>
                    <Box sx={{ ...style, width: 200 }}>
                        <Grid container rowSpacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} container justifyContent={"flex-end"} alignItems="center">
                                <CloseOutlinedIcon onClick={handleClose} className="close-icon" />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className="otp-text">Enter the OTP sent your registered phone linked with Aadhar Number</Typography>
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <OtpInput
                                    value={aadharOtp}
                                    className="otp-input"
                                    isInputNum={true}
                                    shouldAutoFocus={true}
                                    onChange={(e: any) => {
                                        setAadharOtp(e);
                                    }}
                                    numInputs={4}
                                    separator={<span>-</span>}
                                />
                            </Grid>
                            <Grid item xs={12} container justifyContent="center">
                                <Button variant="contained" size="large" style={{ backgroundColor: "#1b75bc" }} onClick={handleOtpSubmit}>
                                    Submit
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Modal>
            </Grid>
        </CommonAccordion>
    );
};

export default EntityTab;
