import { memo } from "react";
import { useWatch } from "react-hook-form";
import { OTHER } from "globalConstants";
import isEqual from "lodash/isEqual";
import { IndividualBusiness, OtherPersons, SoleProprietorship } from "..";

interface Props {
    entityType: "applicant" | "coborrower" | "guarantor";
    externalIndex: number;
    internalIndex: number;
    business: string;
    title: string;
}

const PersonalPane = ({ entityType, externalIndex, business, internalIndex, title }: Props) => {
    const prefix = `${entityType}${entityType === "applicant" ? "" : "." + externalIndex}`;

    const entityAadharValidated = useWatch({
        name: `${prefix}.entity.aadharValidated`,
    });

    switch (business) {
        case "Sole Proprietor":
            return <SoleProprietorship prefix={`${prefix}.personal.personal`} entityAadharValidated={entityAadharValidated} pane={title} />;
        case "Individual":
            return <IndividualBusiness prefix={`${prefix}.personal.personal`} entityAadharValidated={entityAadharValidated} pane={title} />;
        case "Partnership":
            return (
                <OtherPersons
                    prefix={`${prefix}.personal.partners.${internalIndex}`}
                    pane={title}
                    entityAadharValidated={entityAadharValidated}
                    entityPrefix={prefix}
                />
            );
        case "Registered Under Company Act":
            return (
                <OtherPersons
                    prefix={`${prefix}.personal.directors.${internalIndex}`}
                    entityAadharValidated={entityAadharValidated}
                    entityPrefix={prefix}
                    pane={title}
                />
            );
        case OTHER:
            return (
                <OtherPersons
                    prefix={`${prefix}.personal.persons.${internalIndex}`}
                    entityAadharValidated={entityAadharValidated}
                    entityPrefix={prefix}
                    pane={title}
                />
            );
    }

    return <h1>404</h1>;
};

export default memo(PersonalPane, isEqual);
