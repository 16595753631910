import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "email",
        label: "Email",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "mobile",
        label: "Mobile",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        showMask: true,
    },
    {
        name: "rocLocation",
        label: "ROC Location",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        showMask: true,
    },
    {
        name: "address1",
        label: "Address 1",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Building Number and Name",
        editable: true,
        showMask: true,
    },
    {
        name: "address2",
        label: "Address 2",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Street Number and Street Name",
        editable: true,
        showMask: true,
    },
    {
        name: "address3",
        label: "Address 3",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Locality",
        editable: true,
        showMask: true,
    },
    {
        name: "landmark",
        label: "LandMark",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Near your Place",
        editable: true,
    },
    {
        name: "postalCode",
        label: "Postal Code",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        showMask: true,
    },
    {
        name: "city",
        label: "City",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "state",
        label: "State",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: false,
    },
    {
        name: "lengthOfStayInYears",
        label: "Length of Stay",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
        subtitle: "Residing at Address(Years)",
        editable: true,
    },
    {
        name: "lengthOfCityStayInYears",
        label: "Length of City Stay",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
        subtitle: "Residing at City(Years)",
        editable: true,
    },
    {
        name: "ownership",
        label: "Ownership",
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "Owned by self",
                value: "Owned by self",
            },
            {
                label: "Owned by relation",
                value: "Owned by relation",
            },
            {
                label: "Rented",
                value: "Rented",
            },
        ],
        defaultValue: "",
        subtitle: "Owned or rented?",
        editable: true,
    },
    {
        name: "relation",
        label: "Relation",
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "Son",
                value: "Son",
            },
            {
                label: "Daughter",
                value: "Daughter",
            },
            {
                label: "Brother",
                value: "Brother",
            },
            {
                label: "Sister",
                value: "Sister",
            },
            {
                label: "Father",
                value: "Father",
            },
            {
                label: "Mother",
                value: "Mother",
            },
            {
                label: "Grand Father",
                value: "Grand Father",
            },
            {
                label: "Grand Mother",
                value: "Grand Mother",
            },
            {
                label: "other",
                value: "other",
            },
        ],
        defaultValue: "",
        editable: true,
    },
    {
        name: "monthlyRent",
        label: "Rent",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        subtitle: "Monthly rent",
        editable: true,
    },
    {
        name: "ownerDetails.prefix",
        label: "Prefix",
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "Mr.",
                value: "Mr.",
            },
            {
                label: "Mrs.",
                value: "Mrs.",
            },
            {
                label: "Ms.",
                value: "Ms.",
            },
            {
                label: "Dr.",
                value: "Dr.",
            },
            {
                label: "Prof.",
                value: "Prof.",
            },
            {
                label: "Other",
                value: OTHER,
            },
        ],
        editable: true,
        defaultValue: "",
    },
    {
        name: "ownerDetails.firstName",
        label: "First Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "ownerDetails.middleName",
        label: "Middle Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "ownerDetails.lastName",
        label: "Last Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
];

export default fixture;
