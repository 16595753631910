import { LoanApplicationDataCreateInput } from "@/__generated__/apiSchemas";
import { defaultWorkOrderFixture } from "fixtures";

const defaultValues: LoanApplicationDataCreateInput = {
    disabled: false,
    requirement: {
        loanType: "Construction Equipment",
        hasGuarantor: false,
        numberOfGuarantor: 1,
        hasCoBorrower: false,
        numberOfCoBorrower: 1,
        equipments: [
            {
                newOrUsed: "new",
                registrationNumber: "",
                registrationNumberVerified: false,
                manufacturer: "JCB",
                otherManufacturer: "",
                sellerName: "MGB Motors & Auto Agencies Pvt. Ltd.",
                otherSellerName: "",
                noOfUnits: 1,
                category: "Electric Scissors",
                otherCategory: "",
                model: "S1530E ELECTRIC SCISSOR",
                otherModel: "",
                invoiceValue: "",
                disableInvoiceValue: false,
                basicPrice: 0,
                tcsPercentage: 0,
                gst: 0,
                ownMargin: 0,
                disableOwnMargin: false,
                salesAgent: {
                    username: "",
                    fullName: "",
                },
                sendEmailTo: "",
                loanToValue: 0,
                tenor: 0,
                manufacturerDate: "",
            },
        ],
        vehicles: [
            {
                newOrUsed: "new",
                registrationNumber: "",
                registrationNumberVerified: false,
                manufacturer: "TATA_MOTORS",
                otherManufacturer: "",
                sellerName: "",
                otherSellerName: "",
                noOfUnits: 1,
                category: "RIGID_TRUCKS",
                otherCategory: "",
                model: "TATA_ULTRA_1918.T",
                otherModel: "",
                invoiceValue: "",
                disableInvoiceValue: false,
                basicPrice: 0,
                tcsPercentage: 0,
                gst: 0,
                ownMargin: 0,
                disableOwnMargin: false,
                salesAgent: {
                    username: "",
                    fullName: "",
                },
                sendEmailTo: "",
                loanToValue: 0,
                tenor: 0,
                manufacturerDate: "",
            },
        ],
        machineUsage: {
            isFirstTimeUser: false,
            isFirstTimeBuyer: false,
            endUse: "Own Use",
            businessActivity: [],
            rentingRate: 0,
            rentingRateUnit: "Per Month",
            hiringRate: 0,
            hiringRateUnit: "Per Month",
            monthlyHours: 0,
            isWorkContract: false,
            subSectors: {
                infrastructure: [],
                otherInfrastructure: "",
                civilWork: [],
                otherCivilWork: "",
                farming: [],
                otherFarming: "",
                materialHandling: [],
                otherMaterialHandling: "",
                mines: [],
                otherMines: "",
                ports: [],
                otherPorts: "",
                quarry: [],
                otherQuarry: "",
                otherSubSector: "",
            },
        },
        loan: {
            newLoanOrRefinance: "New Loan",
            loanType: "Term Loan",
            repaymentFrequency: "   ",
            otherRepaymentFrequency: "",
            interestRate: 12,
            totalLoan: 0,
            totalLTV: 0,
            totalOwnMargin: 0,
            totalEmi: 0,
        },
    },
    applicant: {
        entity: {
            business: "Sole Proprietor",
            otherBusiness: "",
            entityName: "",
            aadhar: "",
            aadharValidated: false,
            gst: "",
            gstValidated: false,
            pan: "",
            panValidated: false,
            proprietorName: "",
            liabilityType: "Limited",
            relationship: "",
            otherRelationship: "",
            incorporationType: "",
            dateOfEstablishment: "",
            certificateOfIncorporation: "",
            managementDetails: {
                noOfPersons: 1,
                names: [" "],
            },
            shareholdingDirectors: {
                noOfDirectors: 1,
                directorDetails: [
                    {
                        name: "",
                        sharePercentage: 0,
                        entityType: "applicant",
                    },
                ],
            },
            ownershipDetails: {
                noOfPartners: 1,
                partnerDetails: [
                    {
                        name: "",
                        sharePercentage: 0,
                        entityType: "applicant",
                    },
                ],
            },
            businessExperience: {
                kindOfExperience: [],
                operatorExperienceInYears: 0,
                supervisorExperienceInYears: 0,
                ownerExperienceInYears: 0,
                otherExperience: "",
                otherExperienceInYears: 0,
                businessActivity: [],
                industryExperienceInYears: 0,
                equipmentExperience: [],
                backhoeLoadersExperienceInYears: 0,
                compactorsExperienceInYears: 0,
                excavatorsExperienceInYears: 0,
                gensetsExperienceInYears: 0,
                miniExcavatorsExperienceInYears: 0,
                skidSteerLoadersExperienceInYears: 0,
                superLoadersExperienceInYears: 0,
                telehandlersExperienceInYears: 0,
                wheeledLoadersExperienceInYears: 0,
                otherEquipmentExperienceInYears: 0,
                otherEquipmentExperience: "",
                noOfCustomers: 0,
                customers: [],
            },
            creditBureau: {
                bureauName: "Equifax",
                creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                report: {
                    link: "",
                    upload_date: "",
                    filename: "",

                    year_index: 0,
                },
                concentShared: false,
                cibilScore: 0,
                cibilReport: {
                    link: "",
                    upload_date: "",
                    filename: "",

                    year_index: 0,
                },
                scrapedData: {
                    credit_score: 0,
                    accounts: [],
                },
                excelURL: "",
            },
            residentialAddress: {
                address1: "",
                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                email: "",
                mobile: "",
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                monthlyRent: 0,
                ownerDetails: {
                    prefix: "",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                isSameAsRegisteredAddress: false,
                isSameAsResidentialAddress: false,
            },
            registeredAddress: {
                address1: "",
                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                email: "",
                mobile: "",
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                monthlyRent: 0,
                ownerDetails: {
                    prefix: "",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                isSameAsRegisteredAddress: false,
                isSameAsResidentialAddress: true,
            },
            businessAddress: {
                address1: "",
                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                email: "",
                mobile: "",
                lengthOfStayInYears: 0,
                lengthOfCityStayInYears: 0,
                ownership: "Owned by self",
                rentType: "Monthly Rent PM",
                monthlyRent: 0,
                ownerDetails: {
                    prefix: "",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                isSameAsRegisteredAddress: true,
                isSameAsResidentialAddress: false,
            },
            mailingAddress: {
                address1: "",
                address2: "",
                address3: "",
                landmark: "",
                postalCode: "",
                city: "",
                state: "",
                isSameAsBusinessAddress: true,
            },
        },
        income: {
            income: {
                businessIncome: {
                    hasBusinessIncome: false,
                    sales: "0",
                    hasFiledIncomeTaxReturn: false,
                    incomeTaxPassword: "",
                    hasProfitLossStatement: false,
                    hasBalanceSheet: false,
                },
                incomeStatement: {
                    expense: "0",
                    depreciation: "0",
                    financingCost: "0",
                },
                employmentIncome: {
                    hasEmploymentIncome: false,
                    income: "0",
                    sector: "Private",
                    companyName: "",
                    certificateOfIncorporation: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    businessConstitution: "Sole Proprietor",
                    otherBusinessConstitution: "",
                    employerName: "",
                    designation: "",
                    otherDesignation: "",
                    startOfEmployment: "",
                    doYouPayIncomeTax: false,
                    liabilityType: "Limited",
                    noOfPartners: 0,
                    incorporationType: "Public",
                    noOfDirectors: 0,
                },
                agricultureIncome: {
                    hasAgricultureIncome: false,
                    income: "0",
                    activityType: "",
                    otherActivityType: "",
                    ownUnderlyingAsset: "",
                },
                rentalIncome: {
                    hasRentalIncome: false,
                    income: "0",
                    propertyDetail: "",
                    propertyType: "",
                    otherPropertyType: "",
                    ownUnderlyingAsset: "",
                },
                investmentIncome: {
                    hasInvestmentIncome: false,
                    income: "0",
                    ownUnderlyingAsset: "",
                },
            },
            loanFromAccount: [],
            asset: [
                {
                    emiAmount: 0,
                    sanctionAmount: 0,
                    hasAsset: true,
                    outstandingLoanAmount: 0,
                    typeOfLoan: "",
                    repaymentFrequency: "",
                    remainingLoanTenorInMonths: 0,
                    originalLoanTenorInMonths: 0,
                    type: "Construction Equipment",
                    value: "0",
                    filledExcel: {
                        link: "",
                        filename: "",
                        year_index: 0,
                    },
                    propertyType: "Shop",
                    propertyDetail: "Commercial",

                    manufacturer: "JCB",
                    otherManufacturer: "",
                    category: "Electric Scissors",
                    otherCategory: "",
                    model: "S1530E ELECTRIC SCISSOR",
                    otherModel: "",
                    description: "",
                    landArea: {
                        value: 0,
                        unit: "Sq Foot",
                    },
                    builtUpArea: {
                        value: 0,
                        unit: "Sq Foot",
                    },
                    yearOfManufacture: "",
                    registrationNumber: "",
                    registrationNumberVerified: false,
                    isFinanced: false,
                    financedBy: "",
                    dateOfPurchase: "",
                    registrationDetails: {},
                },
            ],
            loanOutstanding: [
                {
                    hasAdditionalLoanOutstanding: false,
                    accountType: "Term Loan",
                    collateralValue: 0,
                    loanSecurity: "",

                    accountNumber: "",
                    bankName: "",
                    financedBy: "",
                    otherFinancedBy: "",
                    outstandingLoanAmount: "0",
                    repaymentFrequency: "",
                    otherRepaymentFrequency: "",
                    originalLoanTenorInMonths: 0,
                    remainingTenorInMonths: 0,
                    emiAmount: "0",
                    isLoanOverdue: false,
                    amountOverdue: "0",
                    numberOfEMIOverdue: 0,
                    reasonForNonPayment: "",
                },
            ],
        },
        personal: {
            personal: {
                yourDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                    dateOfBirth: "7/17/2023",
                    maritalStatus: "Single",
                    noOfDependents: 0,
                    noOfChildren: 0,
                    age: 0,
                },
                fatherDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                motherDetails: {
                    prefix: "Mrs.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                spouseDetails: {
                    prefix: "Mr.",
                    otherPrefix: "",
                    firstName: "",
                    middleName: "",
                    lastName: "",
                },
                background: {
                    educationQualification: "Junior High School (10th Class)",
                    otherEducationQualification: "",
                    profession: "Practicing Doctor",
                    otherProfession: "",
                    ownerOrEmployed: "Owner",
                    caste: "General",
                    otherCaste: "",
                    subCaste: "",
                    religion: "Buddhist",
                    otherReligion: "",
                    physicalDisability: "No",
                },
                residentialAddress: {
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    email: "",
                    mobile: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    monthlyRent: 0,
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    isSameAsRegisteredAddress: false,
                    isSameAsResidentialAddress: false,
                },
                permanentAddress: {
                    address1: "",
                    address2: "",
                    address3: "",
                    landmark: "",
                    postalCode: "",
                    city: "",
                    state: "",
                    email: "",
                    mobile: "",
                    lengthOfStayInYears: 0,
                    lengthOfCityStayInYears: 0,
                    ownership: "Owned by self",
                    rentType: "Monthly Rent PM",
                    monthlyRent: 0,
                    ownerDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    isSameAsRegisteredAddress: false,
                    isSameAsResidentialAddress: true,
                },
            },
            partners: [
                {
                    yourDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        dateOfBirth: "7/17/2023",
                        maritalStatus: "Single",
                        noOfDependents: 0,
                        noOfChildren: 0,
                        age: 0,
                    },
                    fatherDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    motherDetails: {
                        prefix: "Mrs.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    spouseDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    background: {
                        educationQualification: "Junior High School (10th Class)",
                        otherEducationQualification: "",
                        profession: "Practicing Doctor",
                        otherProfession: "",
                        ownerOrEmployed: "Owner",
                        caste: "General",
                        otherCaste: "",
                        subCaste: "",
                        religion: "Buddhist",
                        otherReligion: "",
                        physicalDisability: "No",
                    },
                    residentialAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: false,
                    },
                    permanentAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: true,
                    },
                    kyc: {
                        aadhar: "",
                        aadharValidated: false,
                        pan: "",
                        panValidated: false,
                    },
                    creditBureau: {
                        bureauName: "Equifax",
                        creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                        report: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        concentShared: false,
                        cibilScore: 0,
                        cibilReport: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        scrapedData: {
                            credit_score: 0,
                            accounts: [],
                        },
                        excelURL: "",
                    },
                },
            ],
            directors: [
                {
                    yourDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        dateOfBirth: "7/17/2023",
                        maritalStatus: "Single",
                        noOfDependents: 0,
                        noOfChildren: 0,
                        age: 0,
                    },
                    fatherDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    motherDetails: {
                        prefix: "Mrs.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    spouseDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    background: {
                        educationQualification: "Junior High School (10th Class)",
                        otherEducationQualification: "",
                        profession: "Practicing Doctor",
                        otherProfession: "",
                        ownerOrEmployed: "Owner",
                        caste: "General",
                        otherCaste: "",
                        subCaste: "",
                        religion: "Buddhist",
                        otherReligion: "",
                        physicalDisability: "No",
                    },
                    residentialAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: false,
                    },
                    permanentAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: true,
                    },
                    kyc: {
                        aadhar: "",
                        aadharValidated: false,
                        pan: "",
                        panValidated: false,
                    },
                    creditBureau: {
                        bureauName: "Equifax",
                        creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                        report: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        concentShared: false,
                        cibilScore: 0,
                        cibilReport: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        scrapedData: {
                            credit_score: 0,
                            accounts: [],
                        },
                        excelURL: "",
                    },
                },
            ],
            persons: [
                {
                    yourDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                        dateOfBirth: "7/17/2023",
                        maritalStatus: "Single",
                        noOfDependents: 0,
                        noOfChildren: 0,
                        age: 0,
                    },
                    fatherDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    motherDetails: {
                        prefix: "Mrs.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    spouseDetails: {
                        prefix: "Mr.",
                        otherPrefix: "",
                        firstName: "",
                        middleName: "",
                        lastName: "",
                    },
                    background: {
                        educationQualification: "Junior High School (10th Class)",
                        otherEducationQualification: "",
                        profession: "Practicing Doctor",
                        otherProfession: "",
                        ownerOrEmployed: "Owner",
                        caste: "General",
                        otherCaste: "",
                        subCaste: "",
                        religion: "Buddhist",
                        otherReligion: "",
                        physicalDisability: "No",
                    },
                    residentialAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: false,
                    },
                    permanentAddress: {
                        address1: "",
                        address2: "",
                        address3: "",
                        landmark: "",
                        postalCode: "",
                        city: "",
                        state: "",
                        email: "",
                        mobile: "",
                        lengthOfStayInYears: 0,
                        lengthOfCityStayInYears: 0,
                        ownership: "Owned by self",
                        rentType: "Monthly Rent PM",
                        monthlyRent: 0,
                        ownerDetails: {
                            prefix: "Mr.",
                            otherPrefix: "",
                            firstName: "",
                            middleName: "",
                            lastName: "",
                        },
                        isSameAsRegisteredAddress: false,
                        isSameAsResidentialAddress: true,
                    },
                    kyc: {
                        aadhar: "",
                        aadharValidated: false,
                        pan: "",
                        panValidated: false,
                    },
                    creditBureau: {
                        bureauName: "Equifax",
                        creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
                        report: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        concentShared: false,
                        cibilScore: 0,
                        cibilReport: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        scrapedData: {
                            credit_score: 0,
                            accounts: [],
                        },
                        excelURL: "",
                    },
                },
            ],
        },
        document: {
            business: {
                requirement: {
                    workContract: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    invoice: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                },
                entity: {
                    businessAddressProof: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    memorandumOfAssociation: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    udyogAadharCertificate: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    partnershipDeed: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    partnershipRegistrationCertificate: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    aggrementOfAssociationOfPersons: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    objectOfAssociation: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    managingCommitteePowers: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    certificateOfRegistration: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    memorandumAndAritclesOfAssociation: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    rulesAndRegulations: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    gstRegistrationCertificate: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                },
                income: {
                    emiReceipt: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    loanAccStatement: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    bankStatement: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    lastFiledITReturn: [],
                    GSTReturn: [],
                    balanceSheet: [],
                    profitLoss: [],
                    cashFlow: [],
                },
                loanAccountCredit: [],
                loanAccountAdditional: [],
                bankAccount: [
                    {
                        statement: [
                            {
                                link: "",
                                upload_date: "",
                                filename: "",

                                year_index: 0,
                            },
                        ],
                        bankName: "",
                        accountNumber: "",
                        bankCode: "",
                    },
                ],
                workOrder: [],
            },
            personal: {
                personal: [
                    {
                        pan: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        addressProof: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                        recentPhoto: {
                            link: "",
                            upload_date: "",
                            filename: "",

                            year_index: 0,
                        },
                    },
                ],
            },
            postDisbursal: {
                postDisbursal: {
                    purchaseInvoice: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    registrationNumber: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    RCVerification: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    insuranceCertificate: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                    vehicleTaxCertificate: {
                        link: "",
                        upload_date: "",
                        filename: "",

                        year_index: 0,
                    },
                },
            },
        },
    },
    coborrower: [],
    guarantor: [],
    references: {
        tradeReferences: [
            {
                name: "",
                mobile: "",
                landline: "",
                email: "",
                details: "",
                noOfYears: 0,
                reference: "",
                relationshipToBorrower: "Son",
                otherRelationshipToBorrower: "",
                remarks: "",
            },
            {
                name: "",
                mobile: "",
                landline: "",
                email: "",
                details: "",
                noOfYears: 0,
                reference: "",
                relationshipToBorrower: "Son",
                otherRelationshipToBorrower: "",
                remarks: "",
            },
        ],
        checks: [
            {
                referenceChecker: "",
                referenceUpdateTimestamp: "",
                referenceCheckTranscript: "",
                remarks: "",
            },
            {
                referenceChecker: "",
                referenceUpdateTimestamp: "",
                referenceCheckTranscript: "",
                remarks: "",
            },
        ],
    },
};

export default defaultValues;
