import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "contractorType",
        label: "Contractor Type",
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Company",
                value: "Company",
            },
            {
                label: "Other",
                value: OTHER,
            },
        ],
        editable: true,
        defaultValue: "",
    },
    {
        name: "contractorName",
        label: "Contractor Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        showMask: true,
    },

    {
        name: "workOrder",
        label: "Upload Work Order",
        inputType: INPUT_TYPE.FILE,
        defaultValue: "",
        editable: true,
    },
];

export default fixture;
