enum INPUT_TYPE {
    SELECT = "select",
    PRICE = "price",
    PERCENTAGE = "percentage",
    NUMBER = "number",
    TEXT = "text",
    MULTIPLE_TEXT = "multiple-text",
    DATE = "date",
    RADIO = "radio",
    FILE = "file",
    CHECKBOX = "checkbox",
    YEAR = "month",
}

export default INPUT_TYPE;
