import { EquipmentCreateInput } from "__generated__/apiSchemas";

const fixture: EquipmentCreateInput = {
    newOrUsed: "new",
    sellerName: "MGB Motors & Auto Agencies Pvt. Ltd.",
    otherSellerName: "",
    manufacturer: "JCB",
    otherManufacturer: "",
    category: "Electric Scissors",
    otherCategory: "",
    model: "S1530E ELECTRIC SCISSOR",
    otherModel: "",
    disableInvoiceValue: false,
    disableOwnMargin: false,
    registrationNumber: "",
    registrationNumberVerified: false,
    basicPrice: 0,
    noOfUnits: 1,
    loanToValue: 0,
    ownMargin: 0,
    invoiceValue: "",
    gst: 0,
    salesAgent: {
        username: "",
        fullName: "",
    },
    sendEmailTo: "",
    tenor: 0,
    tcsPercentage: 0,
    manufacturerDate: "",
};

export default fixture;
