import { useContext, useState, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import AuthUserContext from "contexts/session";
import { INPUT_TYPE } from "globalConstants";
import generateTestId from "utils/generateTestId";

const Checks = ({ index: externalIndex }: { index: number }) => {
    const { watch, setValue, getValues } = useFormContext();

    const name = useWatch({ name: `references.tradeReferences.${externalIndex}.name` });

    const referenceCheck = watch(`references.checks.${externalIndex}`);
    const errors = watch(`errors.references.checks.${externalIndex}`);
    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };

    const { authUser } = useContext(AuthUserContext);

    const [isUpdated, setIsUpdated] = useState(false);
    const handleReferenceUpdate = () => {
        setIsUpdated(true);
        setValue(`references.checks.${externalIndex}.referenceUpdateTimestamp`, new Date());
        setValue(`references.checks.${externalIndex}.referenceChecker`, authUser?.username ?? "");
    };

    const activeFields: string[] = ["referenceCheckTranscript", "remarks"];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (
                getValues(`references.checks.${externalIndex}.${item}`) === "" ||
                getValues(`references.checks.${externalIndex}.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.references.checks.${externalIndex}.errorsCounts`, errorsCounts);
    }, [referenceCheck]);

    return (
        <Grid container id="machine-usage__container" direction="column" justifyContent="center" alignItems="center" data-testid="machine-usage">
            <CommonAccordion
                showError={errors?.errorsCounts > 0}
                title={`${name === "" ? indexText(externalIndex) : name} Reference Check`}
                data-testid={generateTestId(`${indexText(externalIndex)} Reference Check`, "accordion")}
                tab="guarantor"
            >
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                    <CommonFields
                        inputType={INPUT_TYPE.TEXT}
                        name={`references.checks.${externalIndex}.referenceCheckTranscript`}
                        label="Reference Check Transcript"
                        placeholder="Reference Check Transcript"
                        reactHookFormRegisterOptions={{
                            onChange: (e) => {
                                setIsUpdated(false);
                            },
                        }}
                        multiLine={true}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        data-testid={`reference-check-transcript-check-${externalIndex + 1}-field`}
                    />
                    <CommonFields
                        inputType={INPUT_TYPE.TEXT}
                        name={`references.checks.${externalIndex}.remarks`}
                        label="Remarks"
                        reactHookFormRegisterOptions={{
                            onChange: (e) => {
                                setIsUpdated(false);
                            },
                        }}
                        placeholder="Remarks"
                        multiLine={true}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        data-testid={`remarks-check-${externalIndex + 1}-field`}
                    />
                    <Grid item xs={6} container></Grid>
                    <Grid item xs={6} container>
                        <Button
                            className={`validate btn ${isUpdated ? "verified" : ""}`}
                            disabled={isUpdated || localStorage.getItem("disable_form") === "true"}
                            onClick={handleReferenceUpdate}
                            data-testid={`update-check-${externalIndex + 1}-button`}
                        >
                            {isUpdated && <CheckCircleIcon className="icon validate-icon" />} Update
                        </Button>
                    </Grid>
                </Grid>
            </CommonAccordion>
        </Grid>
    );
};
export default Checks;
