import React from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import { Stack } from "@mui/material";
import Button from "@mui/material/Button";
import { defaultBankAccountFixture } from "fixtures";
import generateTestId from "utils/generateTestId";

const AddAnotherBankAccount = ({ append, pane }: { append: any; pane?: string }) => {
    return (
        <Button
            onClick={() => {
                append(defaultBankAccountFixture);
            }}
            className="common-fullwidth-button"
            disabled={localStorage.getItem("disable_form") === "true"}
            style={{ marginBottom: "1rem" }}
            data-testid={generateTestId(`add-another-bank-account-${pane}`, "button")}
        >
            <AddCircleOutlineRoundedIcon fontSize="small" className="AddEquipmentIcon" />
            <span>Add Another Bank Account</span>
        </Button>
    );
};

export default AddAnotherBankAccount;
