import { memo, useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { RegisterOptions, UseControllerReturn, UseFormGetFieldState } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import isEqual from "lodash/isEqual";
import { combineClassNames } from "utils";
import CommonLabel from "../CommonLabel";

type Props = {
    showLabel: boolean;
    label: string;
    infoText: string;
    labelSubtitle?: string;
    shouldAddMarginTop?: boolean;
    subtitle?: string;
    textFieldVariant?: "filled" | "outlined" | "standard";
    name: string;
    multiline: boolean;
    placeholder: string;
    reactHookFormRegisterOptions?: RegisterOptions;
    isValidate?: boolean;
    handleValidate?: () => void;
    className?: string;
    verified?: boolean;
    readOnly: boolean;
    formContextMethods: UseControllerReturn;
    disableReactHookForm?: boolean;
    customValue?: number | string;
    getFieldState?: UseFormGetFieldState<any>;
    getValues?: any;
    "data-testid"?: string;
};

const CommonPriceField = memo(
    ({
        showLabel,
        label,
        infoText,
        labelSubtitle,
        shouldAddMarginTop,
        subtitle,
        textFieldVariant = "outlined",
        name,
        placeholder,
        reactHookFormRegisterOptions,
        isValidate,
        handleValidate,
        className,
        verified,
        readOnly,
        formContextMethods,
        disableReactHookForm,
        customValue,
        getFieldState,
        getValues,
        "data-testid": dataTestId,
    }: Props) => {
        const { field } = formContextMethods;

        const { error = undefined } = getFieldState ? getFieldState(name) : {};

        const isDisabled = reactHookFormRegisterOptions?.disabled === true || readOnly;

        const inlineStyles: any = {};

        if (!shouldAddMarginTop) {
            inlineStyles.marginTop = 0;
        }

        if (subtitle) {
            inlineStyles.marginBottom = "1.5rem";
        }

        const isStandardTextField = textFieldVariant === "standard";
        reactHookFormRegisterOptions = {
            min: 0,
            ...reactHookFormRegisterOptions,
        };

        const value = disableReactHookForm ? customValue : field.value;

        useEffect(() => {
            if (customValue)
                field.onChange({
                    target: {
                        name,
                        value,
                    },
                });
        }, [customValue]);

        return (
            <Grid
                className={isStandardTextField ? "" : "common__mui__textfield__container"}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                style={inlineStyles}
            >
                <CommonLabel
                    showLabel={showLabel}
                    label={label}
                    infoText={infoText}
                    labelSubtitle={labelSubtitle}
                    required={!!reactHookFormRegisterOptions?.required}
                    data-testid={`${dataTestId}-label`}
                />
                <Grid item xs={showLabel ? 6 : 12}>
                    <FormControl fullWidth>
                        <div
                            className={combineClassNames(
                                "flex flex-row items-stretch border rounded-md overflow-hidden",
                                error?.message ||
                                    ((getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true)
                                    ? "border-2 border-red-500"
                                    : "border-black"
                            )}
                        >
                            <div
                                className={combineClassNames(
                                    "text-lg text-gray-500 flex items-center bg-gray-200 border-r  px-2",
                                    error?.message ||
                                        ((getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true)
                                        ? "border-r-2 border-red-500"
                                        : "border-black"
                                )}
                            >
                                ₹
                            </div>
                            {/* @ts-ignore */}
                            <CurrencyInput
                                {...reactHookFormRegisterOptions}
                                // override onChange to prevent firing twice
                                value={value}
                                name={name}
                                data-testid={dataTestId}
                                decimalScale={2}
                                placeholder={placeholder}
                                onValueChange={(value = "") => {
                                    field.onChange({
                                        target: {
                                            name,
                                            value,
                                        },
                                    });
                                }}
                                allowNegativeValue={false}
                                step={0.01}
                                intlConfig={{ locale: "en-IN" }}
                                prefix=""
                                className={combineClassNames(
                                    "w-full py-2 px-3 text-sm focus:border-black outline-none",
                                    isDisabled ? "bg-gray-200 text-gray-400" : "",
                                    (getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true
                                        ? "error"
                                        : ""
                                )}
                                disabled={isDisabled}
                            />
                        </div>
                        <FormHelperText error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}>
                            {(getValues(name) === "" || getValues(name) === undefined) &&
                                getValues("showIncompleteField") == true &&
                                "This field is required"}
                        </FormHelperText>
                        <FormHelperText
                            className={combineClassNames("common__helper-text", isValidate ? "validate" : undefined)}
                            onClick={handleValidate}
                        >
                            {verified ? <CheckCircleIcon className="icon" /> : ""}
                            <span>{subtitle}</span>
                        </FormHelperText>
                        <FormHelperText error={true} data-testid={`${name}__error`}>
                            <ErrorMessage name={name} render={({ message }) => <p>{message}</p>} />
                        </FormHelperText>
                    </FormControl>
                </Grid>
            </Grid>
        );
    },
    (prevProps, nextProps) => {
        const { formContextMethods: prevFormContextMethods, getFieldState: _, ...restPrevProps } = prevProps;
        const { formContextMethods: nextFormContextMethods, getFieldState: __, ...restNextProps } = nextProps;

        // rerender either if formContextMethods.value changes or any other prop changes
        return isEqual(restPrevProps, restNextProps) && prevFormContextMethods.field.value === nextFormContextMethods.field.value;
    }
);

export default CommonPriceField;
