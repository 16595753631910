import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "income",
        label: "Income",
        inputType: INPUT_TYPE.PRICE,
        editable: true,
        subtitle: "Last 12 Months",
    },
    {
        name: "sector",
        label: "Sector",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "Private",
                value: "Private",
            },
            {
                label: "Government Department",
                value: "Government Department",
            },
            {
                label: "Government Company",
                value: "Government Company",
            },
            {
                label: "Public",
                value: "Public",
            },
        ],
    },
    {
        name: "businessConstitution",
        label: "Business Constitution",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            {
                label: "Sole Proprietor",
                value: "Sole Proprietor",
            },
            {
                label: "Partnership",
                value: "Partnership",
            },
            {
                label: "Registered Under Company Act",
                value: "Registered Under Company Act",
            },
            {
                label: "Individual",
                value: "Individual",
            },
            {
                label: "Other",
                value: OTHER,
            },
        ],
        subtitle: "Employer",
    },
    {
        name: "companyName",
        label: "Company Name",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Where do you work ?",
    },
    {
        name: "liabilityType",
        label: "Liability Type",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Limited",
                value: "Limited",
            },
            {
                label: "Unlimited",
                value: "Unlimited",
            },
        ],
    },
    {
        name: "noOfPartners",
        label: "Partner",
        editable: true,
        inputType: INPUT_TYPE.NUMBER,
        subtitle: "Number of Partners",
    },
    {
        name: "incorporationType",
        label: "Incorporation Type",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Private",
                value: "Private",
            },
            {
                label: "Public",
                value: "Public",
            },
        ],
    },
    {
        name: "noOfDirectors",
        editable: true,
        label: "Director",
        inputType: INPUT_TYPE.NUMBER,
        subtitle: "Number of Shareholding Directors",
    },
    {
        name: "otherBusinessConstitution",
        label: "Other",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: [
            {
                label: "Association",
                value: "Association",
            },
            {
                label: "Body of Individuals",
                value: "Body of Individuals",
            },
            {
                label: "Club",
                value: "Club",
            },
            {
                label: "Hindu Undivided Family (HUF)",
                value: "Hindu Undivided Family (HUF)",
            },
            {
                label: "Society",
                value: "Society",
            },
            {
                label: "Trust",
                value: "Trust",
            },
        ],
        dataTestId: "other-business-constitution",
    },
    {
        name: "employerName",
        label: "Employer Name",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Where Do You Work ?",
    },
    {
        name: "designation",
        label: "Designation",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: [
            {
                label: "Sales Manager",
                value: "Sales Manager",
            },
            {
                label: "Manager",
                value: "Manager",
            },
            {
                label: "Senior Manager",
                value: "Senior Manager",
            },
            {
                label: "Vice President",
                value: "Vice President",
            },
            {
                label: "Other",
                value: OTHER,
            },
        ],
    },
    {
        name: "startOfEmployment",
        label: "Date",
        inputType: INPUT_TYPE.DATE,
        subtitle: "Start Date of Employment",
    },
    {
        name: "doYouPayIncomeTax",
        label: "Do you pay Income Tax ?",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: [
            {
                label: "Yes",
                value: true,
            },
            {
                label: "No",
                value: false,
            },
        ],
    },
];

export default fixture;
