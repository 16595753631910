import { Fragment, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import { NameAndShareCreateInput } from "__generated__/apiSchemas";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { convertSnakeCaseToHeaderCase } from "utils";
import convertCamelCaseToTitleCase from "utils/convertCamelCaseToTitleCase";
import generateTestId from "utils/generateTestId";
import getEntityDetails from "utils/getEntityDetails";
import getPersonalName from "utils/getPersonalName";
import fixture from "./fixture";

type Props = {
    prefix?: string;
    entityAadharValidated: boolean;
    pane?: string;
};

const Personal = ({ prefix, entityAadharValidated, pane }: Props) => {
    const personal = useWatch({
        name: `${prefix}`,
    });
    const errors = useWatch({
        name: `errors.${prefix}`,
    });

    const dateOfBirth = useWatch({
        name: `${prefix}.yourDetails.dateOfBirth`,
    });

    const aadharValidated = useWatch({
        name: `${prefix}.kyc.aadharValidated`,
    });

    const maritalStatus = useWatch({
        name: `${prefix}.yourDetails.maritalStatus`,
    });

    const isApplicant = prefix?.includes("applicant");
    const entityPrefix = isApplicant ? "applicant" : prefix?.split(".").slice(0, 2).join(".");

    const entity = useWatch({
        name: `${entityPrefix}.entity`,
    });
    const applicantEntity = useWatch({
        name: `applicant.entity`,
    });

    const { setValue, getValues } = useFormContext();

    const autoPopulatedFields = ["firstName", "lastName", "dateOfBirth"];

    const validated = entityAadharValidated || aadharValidated;

    useEffect(() => {
        if (dateOfBirth) {
            var dob = new Date(dateOfBirth.split("-").reverse().join("-"));
            var year = Number(dob?.getFullYear());
            var month = Number(dob?.getMonth());
            var day = Number(dob?.getDay());
            var today = new Date();
            var age = today.getFullYear() - year;
            if (today.getMonth() < month || (today.getMonth() == month && today.getDate() < day)) {
                age--;
            }
            setValue(`${prefix}.yourDetails.age`, age);
        }
    }, [dateOfBirth]);

    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${prefix}.${item}`) === "" || getValues(`${prefix}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}.errorsCounts`, errorsCounts);
    }, [personal]);

    useEffect(() => {
        let index = prefix?.slice(-1);
        const name = getPersonalName(personal.yourDetails);
        if (entity.business === "Sole Proprietor") {
            setValue(`${entityPrefix}.entity.proprietorName`, name);
            setValue(`contact.full_name`, name);
        } else if (entity.business === "Individual") {
            setValue(`${entityPrefix}.entity.entityName`, name);
            setValue(`contact.full_name`, name);
            const { membersDetails, membersField } = getEntityDetails(applicantEntity);
            let assignedMemberTo = "";
            let assignedMemberIndex = 0;
            //@ts-ignore
            membersDetails?.forEach((member, i) => {
                if (member.entityType !== "applicant") {
                    const [entity, index] = member.entityType?.split("-");
                    const entityType = `${entity}.${Number(index ?? "1") - 1}`;
                    if (entityPrefix === entityType) {
                        assignedMemberTo = entityType;
                        assignedMemberIndex = i;
                    }
                }
            });
            if (assignedMemberTo) setValue(`applicant.entity.${membersField}.${assignedMemberIndex}.name`, name);
        } else if (entity.business === "Registered Under Company Act") {
            setValue(`${entityPrefix}.entity.shareholdingDirectors.directorDetails.${index}.name`, name);
        } else if (entity.business === "Partnership") {
            setValue(`${entityPrefix}.entity.ownershipDetails.partnerDetails.${index}.name`, name);
        } else {
            setValue(`${entityPrefix}.entity.managementDetails.names.${index}`, name);
        }
    }, [personal.yourDetails]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title="Personal"
            tab="personal"
            keys={`${prefix}.personal`}
            data-testid={generateTestId(`personal-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                {fixture.map(
                    (
                        { name, inputType, otherOptionProps, editable, options, subtitle, label, className, showMask, reactHookFormRegisterOptions },
                        index
                    ) => {
                        const fieldName = name;
                        let start = "yourDetails";
                        if (name.includes("f_")) {
                            start = "fatherDetails";
                            name = name.replace("f_", "");
                        }
                        if (name.includes("m_")) {
                            start = "motherDetails";
                            name = name.replace("m_", "");
                        }
                        if (maritalStatus !== "Married") {
                            if (
                                name === "noOfChildren" ||
                                name === "s_prefix" ||
                                name === "s_firstName" ||
                                name === "s_lastName" ||
                                name === "s_middleName"
                            ) {
                                return "";
                            }
                        }
                        if (maritalStatus === "Married") {
                            if (name.includes("s_")) {
                                start = "spouseDetails";
                                name = name.replace("s_", "");
                            }
                        }
                        {
                            activeFields.push(start + "." + name);
                        }
                        return (
                            <Fragment key={index}>
                                {name === "prefix" && start === "yourDetails" && (
                                    <Grid item xs={12} justifyContent="center">
                                        <h4 className="other-label">Your Details</h4>
                                    </Grid>
                                )}
                                {name === "prefix" && start === "fatherDetails" && (
                                    <Grid item xs={12} justifyContent="center">
                                        <h4 className="other-label">Father's Details</h4>
                                    </Grid>
                                )}
                                {name === "prefix" && start === "motherDetails" && (
                                    <Grid item xs={12} justifyContent="center">
                                        <h4 className="other-label">Mother's Details</h4>
                                    </Grid>
                                )}
                                {name === "prefix" && start === "spouseDetails" && (
                                    <Grid item xs={12} justifyContent="center">
                                        <h4 className="other-label">Spouse Details</h4>
                                    </Grid>
                                )}

                                <CommonFields
                                    inputType={inputType}
                                    name={`${prefix}.${start}.${name}`}
                                    data-testid={generateTestId(`${label}-${convertCamelCaseToTitleCase(start)}-${pane}`, "field")}
                                    subtitle={subtitle}
                                    showMask={showMask}
                                    options={
                                        options?.map((value) => ({
                                            value: value !== OTHER ? convertSnakeCaseToHeaderCase(value) : value,
                                            label: convertSnakeCaseToHeaderCase(value),
                                        })) || []
                                    }
                                    label={label}
                                    readOnly={
                                        localStorage.getItem("disable_form") === "true" ||
                                        (autoPopulatedFields.includes(fieldName) ? (validated ? true : !editable) : !editable)
                                    }
                                    reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                                    otherOptionProps={otherOptionProps}
                                    className={className}
                                />
                            </Fragment>
                        );
                    }
                )}
            </Grid>
        </CommonAccordion>
    );
};

export default Personal;
