import { Fragment, useContext, useEffect, useState } from "react";
import { useAuthUser } from "react-auth-kit";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { Button, Grid, Stack } from "@mui/material";
import { useAddFollowUpComment, useUpdateFollowUpComment } from "__generated__/apiComponents";
import CommonFields from "components/CommonFields";
import AccordionContext from "contexts/accordion";
import { OTHER } from "globalConstants";
import { useError } from "hooks";
import moment from "moment";
import { convertSnakeCaseToHeaderCase } from "utils";
import { INPUT_TYPE } from "../../Document";
import AddNewNote from "./AddNewNote";
import fixture from "./fixture";

const FollowUpDate = ({ index, refetch, isNew }: { index: number; refetch?: any; isNew?: boolean }) => {
    const { control, setValue } = useFormContext();
    const authUser = useAuthUser()();
    const showError = useError();
    const { id: userId } = useParams();
    const prefix = isNew ? "newFollowUpDate" : `followUpDate.${index}`;
    const followUpDate = useWatch({ name: prefix });
    const contactDetails = useWatch({ name: "contact" });
    const requirement = useWatch({ name: "requirement" });
    const { currentTab, setCurrentTab } = useContext(AccordionContext);

    const { fields, append } = useFieldArray({
        control,
        name: `${prefix}.note`,
    });
    const [newNote, setNewNote] = useState("");

    const { mutate: updateComment, isLoading: isUpdating } = useUpdateFollowUpComment({
        onSuccess: (data: any) => {
            toast.dismiss();
            toast.success(data);
            refetch?.();
        },
        onError: showError,
    });

    const handleUpdateFollowUpDate = () => {
        updateComment({
            body: {
                id: followUpDate.id,
                ...followUpDate,
            },
        });
    };

    const { mutate: addComment, isLoading: isAdding } = useAddFollowUpComment({
        onSuccess: (data: any) => {
            toast.dismiss();
            toast.success(data);
            refetch();
            setCurrentTab((prevData: any) => ({ ...prevData, "follow-up": "" }));
            setValue("newFollowUpDate.note", []);
            setNewNote("");
        },
        onError: showError,
    });

    const handleAddFollowUpDate = () => {
        const newFollowUpDate = followUpDate;
        if (!newFollowUpDate.date) return toast.error("Date cannot be empty");
        addComment({
            body: {
                userId: userId!,
                ...newFollowUpDate,
            },
        });
    };

    let equipment = requirement.equipments?.[0];
    if (requirement.loanType === "Commercial Vehicles") equipment = requirement.vehicles?.[0];

    useEffect(() => {
        for (let field of ["manufacturer", "category", "noOfUnits"]) {
            if (field === "manufacturer" && equipment[field] === OTHER) setValue(`${prefix}.${field}`, equipment["otherManufacturer"]);
            else if (field === "category" && equipment[field] === OTHER) setValue(`${prefix}.${field}`, equipment["otherCategory"]);
            else setValue(`${prefix}.${field}`, equipment[field]);
        }
    }, [equipment]);

    return (
        <Grid container direction="row" justifyContent="flex-end" alignItems="center">
            {fixture.map(({ name, inputType, reactHookFormRegisterOptions, options, label, subtitle, editable, defaultValue }, i) => {
                if (contactDetails?.role !== "user" || contactDetails?.userType === "registered") {
                    if (name === "manufacturer" || name === "category" || name === "noOfUnits") return "";
                }

                return (
                    <CommonFields
                        key={i}
                        inputType={inputType}
                        multiLine
                        name={`${prefix}.${name}`}
                        options={options ?? []}
                        label={label}
                        reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                        readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                        subtitle={subtitle}
                        disableFuture={false}
                    />
                );
            })}
            {fields?.map((field: any, index) => (
                <Fragment key={field.id}>
                    <CommonFields
                        multiLine
                        inputType={INPUT_TYPE.TEXT}
                        name={`${prefix}.note.${index}.text`}
                        label={index === 0 ? "Note" : ""}
                        readOnly={true}
                        subtitle={`${moment(field?.createAt).format("DD-MM-YY")} | ${field?.email}`}
                    />
                </Fragment>
            ))}
            <CommonFields
                multiLine
                inputType={INPUT_TYPE.TEXT}
                disableReactHookForm
                value={newNote}
                reactHookFormRegisterOptions={{
                    onChange(event) {
                        setNewNote(event.target.value);
                    },
                }}
                name=""
                label={fields.length === 0 ? "Note" : ""}
            />
            <AddNewNote
                append={() => {
                    if (!newNote.trim()) {
                        toast.dismiss();
                        toast.error("Note cannot be empty");
                        return;
                    }
                    append({
                        id: crypto.randomUUID(),
                        text: newNote,
                        createAt: new Date().toISOString(),
                        email: authUser?.email,
                    });
                    setNewNote("");
                }}
            />
            <Stack width="100%" mt={4} justifyContent="center">
                <Button
                    disabled={localStorage.getItem("disable_form") === "true" || isAdding || isUpdating}
                    onClick={isNew ? handleAddFollowUpDate : handleUpdateFollowUpDate}
                    variant="contained"
                    className="btn-arrow"
                    data-testid="save-button"
                    style={{ backgroundColor: "#1b75bc", width: "fit-content", alignSelf: "center" }}
                >
                    Save
                </Button>
            </Stack>
        </Grid>
    );
};

export default FollowUpDate;
