import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import ShortFormContext from "./../../contexts/shortForm/context";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { useIsSmallScreen } from "hooks";

const LoanForm = ({ setCurrentStep }: { setCurrentStep: React.Dispatch<React.SetStateAction<number>> }) => {
    const { handleNestedFieldChange, form } = useContext(ShortFormContext);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNestedFieldChange({
            loanForm: {
                [e.target.name]: e.target.value,
            },
        });
    };
    const isSmallScreen = useIsSmallScreen();

    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="loan-form">
                <Grid item xs={isSmallScreen ? 12 : 7}></Grid>
                <Grid item xs={isSmallScreen ? 12 : 5}>
                    <Typography variant="h5" className="short-form__title" component="h4">
                        Outstanding Loans
                    </Typography>
                    <div className="short-form__option-body">
                        <RadioGroup row value={form.loanForm.outstanding} onChange={handleChange} name="outstanding">
                            <FormControlLabel value="yes" control={<Radio size="small" />} label="Yes" />
                            <FormControlLabel value="no" control={<Radio size="small" />} label="No" />
                        </RadioGroup>
                        <FormHelperText className="subtitle">Includes business and personal loans</FormHelperText>
                        {form.loanForm.outstanding === "yes" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="h6">
                                    Default Status
                                </Typography>
                                <RadioGroup row value={form.loanForm.status} onChange={handleChange} name="status">
                                    <FormControlLabel value="paid_all_due_EMI" control={<Radio size="small" />} label="Paid all due EMI" />
                                    <FormControlLabel value="not_paid_all_due_EMI" control={<Radio size="small" />} label="Not paid all due EMI" />
                                </RadioGroup>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <br />
                    <Grid justifyContent="flex-end" style={{ width: "80%" }} container>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(2)}>
                            <ArrowBackIosIcon />
                            Back
                        </Button>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(4)}>
                            Next
                            <NavigateNextIcon />
                        </Button>
                    </Grid>
                    <Grid container direction="row" className="progress">
                        <Grid container direction="column" className="progress-bar" id="loan">
                            80%
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default LoanForm;
