import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "newLoanOrRefinance",
        label: "Loan",
        editable: true,
        inputType: INPUT_TYPE.RADIO,
        options: ["New Loan", "Refinance"],
        defaultValue: "Refinance",
    },
    {
        name: "loanType",
        editable: true,
        label: "Type of Loan",
        inputType: INPUT_TYPE.RADIO,
        options: ["Term Loan", "Overdraft"],
        defaultValue: "Term Loan",
    },
    {
        name: "repaymentFrequency",
        label: "Repayment Frequency",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["monthly", "three_month", "six_month", "12_month", "at_maturity", OTHER],
        defaultValue: "monthly",
    },
    {
        name: "interestRate",
        label: "Interest Rate",
        inputType: INPUT_TYPE.PERCENTAGE,
        subtitle: "Preferred Range",
        defaultValue: "",
        editable: true,
        reactHookFormRegisterOptions: {
            max: {
                value: 17,
                message: "* Interest Rate should be in range of 8-17%",
            },
            min: {
                value: 8,
                message: "* Interest Rate should be in range of 8-17%",
            },
        },
    },
    {
        name: "totalLoan",
        label: "Total Loan",
        inputType: INPUT_TYPE.PRICE,
        subtitle: "Amount",
        defaultValue: "0",
        editable: false,
    },
    {
        name: "totalOwnMargin",
        label: "Total Own Margin",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        editable: false,
    },
    {
        name: "totalEmi",
        label: "Total EMI",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "0",
        subtitle: "Equated Monthly Instalment",
        editable: false,
    },
];

export default fixture;
