import React from 'react'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useFormContext } from 'react-hook-form';

const HandleNextDialog = ({
    handleClose,
    open,
    handleProceed,
    handleCancel,
    type,
}: {
    handleClose: () => void;
    open: boolean;
    handleProceed: () => void;
    handleCancel: () => void;
    type: string;
}) => {
    const { setValue } = useFormContext();

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent  className='max-w-md'>
                <Typography className='w-full text-center font-medium mb-6' gutterBottom>
                You have not fully filled this section. 
                    {
                        type === "next" ? " Are you sure you want to skip to the next section?" : " Are you sure you want to submit it?"
                    }
                </Typography>
                <div className='flex items-center justify-between px-8'>
                    <Button className='bg-blue-600 text-white capitalize hover:bg-blue-600 hover:text-white' onClick={() => {
                        setValue("showIncompleteField", false)
                        handleProceed();
                    }}>Skip ahead</Button>
                    <Button className='bg-gray-400 capitalize text-black hover:bg-gray-400 hover:text-black' onClick={() => {
                        setValue("showIncompleteField", true)
                        handleCancel()
                    }}>Cancel</Button>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default HandleNextDialog