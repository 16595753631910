import { DocumentPersonalDetailsCreateInput } from "@/__generated__/apiSchemas";

const fixture: DocumentPersonalDetailsCreateInput = {
    addressProof: { link: "" },
    pan: { link: "" },
    recentPhoto: { link: "" },
    loanAccountCredit: [],
};

export default fixture;
