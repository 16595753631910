import { useLayoutEffect } from "react";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields";
import { defaultEquipmentFixture } from "fixtures";
import { INPUT_TYPE } from "globalConstants";
import { getLtvFromOwnMargin } from "utils";

const OwnMarginOrLTV = ({ index }: { index: number }) => {
    const { setValue, watch, getFieldState } = useFormContext();
    const disableOwnMargin = watch(`requirement.vehicles.${index}.disableOwnMargin`);
    const { error: ownMarginError } = getFieldState(`requirement.vehicles.${index}.ownMargin`);
    const { error: LTVError } = getFieldState(`requirement.vehicles.${index}.loanToValue`);
    const equipment = watch(`requirement.vehicles.${index}`) || defaultEquipmentFixture;
    const ownMargin = equipment.ownMargin;
    const invoiceValue = watch(`requirement.vehicles.${index}.invoiceValue`);
    const loanToValue = watch(`requirement.vehicles.${index}.loanToValue`);
    const numberOfUnits = watch(`requirement.vehicles.${index}.noOfUnits`);

    useLayoutEffect(() => {
        if (disableOwnMargin) {
            setValue(
                `requirement.vehicles.${index}.ownMargin`,
                `${(loanToValue >= 100 ? 0 : +(+invoiceValue - (+invoiceValue * loanToValue) / 100).toFixed(2)) * numberOfUnits}`
            );
        } else {
            if (+ownMargin === 0) {
                setValue(`requirement.vehicles.${index}.loanToValue`, 0);
            } else {
                setValue(
                    `requirement.vehicles.${index}.loanToValue`,
                    +ownMargin > +invoiceValue
                        ? 0
                        : getLtvFromOwnMargin({
                              ownMargin: Number(ownMargin),
                              invoiceValue: Number(invoiceValue),
                          })
                );
            }
        }
    }, [loanToValue, ownMargin, invoiceValue, numberOfUnits, setValue, index, disableOwnMargin]);

    return (
        <Grid container className="common__or__container" justifyContent="center" alignItems="center">
            <Grid item xs={6} alignSelf={ownMarginError ? "flex-start" : "center"}>
                <div className={`or__top or__top--has-subtitle ${ownMarginError ? "or__top--has-error" : ""}`}>
                    <div className={`line ${ownMarginError ? "line--has-error" : ""}`} />
                    <Typography className="common-mui-accordian__text" data-testid={`own-margin-vehicle-${index + 1}-field-label`}>
                        Own Margin <span style={{ color: "red" }}> *</span>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <CommonFields
                    name={`requirement.vehicles.${index}.ownMargin`}
                    inputType={INPUT_TYPE.PRICE}
                    data-testid={`own-margin-vehicle-${index + 1}-field`}
                    label=""
                    subtitle="Downpayment For Your Loan"
                    showLabel={false}
                    positionHelperTextAbsolute={true}
                    shouldAddMarginTop={false}
                    reactHookFormRegisterOptions={{
                        onChange: (e) => {
                            const value = e.target.value?.split(",").join("");
                            setValue(`requirement.vehicles.${index}.ownMargin`, `${value}`);

                            console.log(typeof value, typeof invoiceValue);

                            if (+value === 0) setValue(`requirement.vehicles.${index}.loanToValue`, 0);
                            else
                                setValue(
                                    `requirement.vehicles.${index}.loanToValue`,
                                    +value > +invoiceValue
                                        ? 0
                                        : getLtvFromOwnMargin({
                                              ownMargin: Number(value),
                                              invoiceValue: Number(invoiceValue),
                                          })
                                );
                            setValue(`requirement.vehicles.${index}.disableOwnMargin`, false);
                        },
                        min: 0,
                        max: {
                            value: invoiceValue,
                            message: "* Own Margin cannot be greater than Equipment Cost",
                        },
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || (loanToValue && disableOwnMargin)}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="or__circle or__circle--has-subtitle">or</div>
            </Grid>
            <Grid item xs={6} alignSelf={LTVError ? "flex-start" : "center"}>
                <div className={`or__bottom or__bottom--has-subtitle ${LTVError ? "or__bottom--has-error" : ""}`}>
                    <div className="line" />
                    <Typography className="common-mui-accordian__text" data-testid={`ltv-vehicle-${index + 1}-field-label`}>
                        Loan to Value (LTV) <span style={{ color: "red" }}>*</span>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <CommonFields
                    name={`requirement.vehicles.${index}.loanToValue`}
                    inputType={INPUT_TYPE.PERCENTAGE}
                    label=""
                    data-testid={`ltv-vehicle-${index + 1}-field`}
                    subtitle="Preferred LTV (70 = 70% Loan)"
                    showLabel={false}
                    positionHelperTextAbsolute={true}
                    shouldAddMarginTop={false}
                    reactHookFormRegisterOptions={{
                        onChange: (e) => {
                            const value = +e.target.value;
                            setValue(`requirement.vehicles.${index}.loanToValue`, Number(value));

                            setValue(`requirement.vehicles.${index}.disableOwnMargin`, true);
                        },
                        min: 0,
                        max: {
                            value: 100,
                            message: "* LTV cannot be greater than 100",
                        },
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || (ownMargin && !disableOwnMargin)}
                />
            </Grid>
        </Grid>
    );
};

export default OwnMarginOrLTV;
