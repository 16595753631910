import { Box, Button, Grid } from "@mui/material";
import { useState, Fragment, useMemo } from "react";
import Paper from "@mui/material/Paper";
import Navbar from "components/Navbar";
import { withShortFormProvider } from "contexts/shortForm";
import "styles/short-form.scss";
import { RequirementForm, EntityForm, ExperienceForm, LoanForm, Offers, Footer } from ".";
import useIsSmallScreen from "hooks/useIsSmallScreen";
import BottomShadow from "assets/images/shadow_large.jpg";
const ShortForm = () => {
    const [currentStep, setCurrentStep] = useState(0);
    const isSmallScreen = useIsSmallScreen();
    // TODO: Change steps to tabs
    const steps = useMemo(
        () => [
            {
                form: <RequirementForm setCurrentStep={setCurrentStep} />,
                icon: "https://www.smecreditt.com/static-images/image/short_form_icon_1.png",
            },
            {
                form: <EntityForm setCurrentStep={setCurrentStep} />,
                icon: "https://www.smecreditt.com/static-images/image/short_form_icon_2.png",
            },
            {
                form: <ExperienceForm setCurrentStep={setCurrentStep} />,
                icon: "https://www.smecreditt.com/static-images/image/short_form_icon_3.png",
            },
            {
                form: <LoanForm setCurrentStep={setCurrentStep} />,
                icon: "https://www.smecreditt.com/static-images/image/short_form_icon_4.png",
            },
            {
                form: <Offers setCurrentStep={setCurrentStep} />,
                icon: "https://www.smecreditt.com/static-images/image/short_form_icon_5.png",
            },
        ],
        []
    );

    return (
        <Fragment>
            <Navbar />
            <div style={{ backgroundColor: "white" }}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" id="short-form-container">
                    <Grid item xs={isSmallScreen ? 12 : 1}>
                        <Grid container id="button-list-container" direction={isSmallScreen ? "row" : "column"} justifyContent="" alignItems="center">
                            {steps.map((step, index) => (
                                <Button
                                    key={index}
                                    className={`button ${index === currentStep ? "active" : ""}`}
                                    onClick={() => setCurrentStep(index)}
                                >
                                    <Box className="button-img" component="img" src={step.icon} />
                                </Button>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 11}>
                        <Paper id="form-container">
                            <Grid container direction="row">
                                <Grid item xs={12}>
                                    {steps[currentStep].form}
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <img src={BottomShadow} alt="shadow" id="shadow-bottom" />
                </Grid>
                <Footer />
            </div>
        </Fragment>
    );
};

export default withShortFormProvider(ShortForm);
