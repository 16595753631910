import { ReactNode, useLayoutEffect, useContext } from "react";
import { useFormContext } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import AccordionContext from "contexts/accordion/context";
import { combineClassNames } from "utils";
import generateTestId from "utils/generateTestId";
import "./CommonAccordion.styles.scss";

type Props = {
    title: string;
    children: ReactNode;
    titleReactNode?: ReactNode;
    className?: string;
    id?: string;
    disabled?: boolean;
    defaultExpanded?: boolean;
    tab: string;
    showDetail?: boolean;
    "data-testid"?: string;
    keys?: string;
    showError?: boolean;
};

const CommonAccordion = ({
    title,
    titleReactNode,
    children,
    className,
    tab,
    disabled = false,
    showDetail = true,
    defaultExpanded,
    keys = "",
    showError = false,
    ...props
}: Props) => {
    const { getValues } = useFormContext();

    const { currentTab, setCurrentTab } = useContext(AccordionContext);

    const key = keys !== "" ? keys : title;

    return (
        <Accordion
            expanded={currentTab[tab] === key}
            TransitionProps={{
                timeout: 0,
            }}
            disabled={disabled}
            // @ts-ignore
            onChange={() => {
                setCurrentTab((prevState: any) => {
                    // @ts-ignore
                    if (prevState[tab] === key) return { ...prevState, [tab]: "" };
                    // @ts-ignore
                    return { ...prevState, [tab]: key };
                });
            }}
            className={combineClassNames(
                "accordion--common",
                className,
                getValues("showIncompleteField") == true && showError ? "error" : "",
                `${showDetail ? "" : "not-expandable-accordion"}`
            )}
            data-testid={props["data-testid"] ? props["data-testid"] : generateTestId(title, "accordion")}
            {...props}
        >
            <AccordionSummary style={{ position: "relative" }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
                {titleReactNode ? (
                    titleReactNode
                ) : (
                    <Typography className="common-accordian__title">
                        {title}
                        {/* {infoText ? (
                            <sup id="tooltip">
                                <Tooltip title={infoText} placement="top" arrow>
                                    <img src={infoIcon} alt="icon" />
                                </Tooltip>
                            </sup>
                        ) : (
                            ""
                        )} */}
                    </Typography>
                )}
            </AccordionSummary>
            {showDetail && <AccordionDetails>{children}</AccordionDetails>}
        </Accordion>
    );
};

export default CommonAccordion;
