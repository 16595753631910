//@ts-nocheck
import { useEffect, useState } from "react";
import { useSignIn } from "react-auth-kit";
import { useLocation, useParams } from "react-router-dom";
import toast from "react-hot-toast";
import { useResetEmail, useVerifyEmail } from "__generated__/apiComponents";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import "./ResetEmail.styles.scss";

type Props = {};

const ResetEmail = (props: Props) => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const signIn = useSignIn();
    const [message, setMessage] = useState("");
    // if (!username || !email || !token) return <div>Invalid Link</div>;

    const { data, error } = useVerifyEmail({
        queryParams: {
            username: query.get("username") ?? "",
            email: query.get("email") ?? "",
            token: query.get("token") ?? "",
        },
    });

    const { mutate: updateEmail } = useResetEmail({
        onSuccess: (data) => {
            setMessage(data);
            setTimeout(() => {
                navigate(ROUTES.LOGIN);
            }, 3000);
        },
        onError: (error: any) => {
            if (error?.stack?.message === "Session Expired") {
                toast.error("Session Expired. Please login again");
                navigate(ROUTES.LOGIN);
            }
            toast.error(error?.stack?.message);
        },
    });

    useEffect(() => {
        if (data)
            updateEmail({
                body: { email: data?.email, token: data?.token, username: data?.username },
            });
    }, [data]);

    return (
        <>
            <Navbar />
            <div className="reset_email__container">{error?.stack?.message || message}</div>;
            <Footer />
        </>
    );
};

export default ResetEmail;
