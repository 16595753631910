import React, { useEffect } from "react";
import { useSignIn } from "react-auth-kit";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { CircularProgress, Stack } from "@mui/material";
import { useRedirectedLogin } from "__generated__/apiComponents";
import { ROUTES } from "globalConstants";
import { useError } from "hooks";
import qs from "query-string";

type Props = {};

const RedirectedLogin = (props: Props) => {
    const location = useLocation();
    const { token, path } = qs.parse(location.search);
    const navigate = useNavigate();
    const showError = useError();
    const signIn = useSignIn();

    const { mutate: login } = useRedirectedLogin({
        onSuccess: (data: any) => {
            if (data) {
                const { accessToken, role, first_login, refreshToken, full_name } = data;
                localStorage.setItem("authToken", accessToken);
                localStorage.setItem("refreshToken", refreshToken);

                if (
                    signIn({
                        token: accessToken,
                        // 10 minutes
                        expiresIn: 20,
                        tokenType: "Bearer",
                        refreshToken,
                        // one day in minutes
                        refreshTokenExpireIn: 1 * 24 * 60,
                        authState: {
                            // formID,
                            isAdmin: role === "admin",
                            role,
                            username: data.username,
                            email: data?.email,
                            mobile: data?.mobile,
                            full_name,
                        },
                    })
                ) {
                    if (path?.includes("dashboard")) {
                        //@ts-ignore
                        localStorage.setItem("form_id", path?.replace("/dashboard/", ""));
                    }
                    navigate(path as string);
                }
            } else if (data.message === "User already logged in on another device") {
                toast.success("User already logged in on another device");
            }
        },
        onError: showError,
    });

    useEffect(() => {
        if (token)
            login({
                body: { token: token as string },
            });
        else {
            navigate(ROUTES.LOGIN);
        }
    }, [token]);

    return (
        <Stack justifyContent="center" alignItems="center" height="100vh">
            <CircularProgress />
        </Stack>
    );
};

export default RedirectedLogin;
