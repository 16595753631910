const indexText = (index: number) => {
    switch (index) {
        case 0:
            return "";
        case 1:
            return "2nd";
        case 2:
            return "3rd";
        default:
            return `${index + 1}th`;
    }
};

export default indexText;
