import { Fragment } from "react";
import Navbar from "components/Navbar";
import Footer from "components/Footer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import "./AdminView.styles.scss";
const AdminView = () => {
    const style = {
        border: 1,
        fontSize: "1rem",
        fontFamily: "Arial",
        fontWeight: "750",
    };
    return (
        <Fragment>
            <Navbar />
            <div id="admin-view__container">
                <TableContainer>
                    <Table sx={{ minWidth: 650 }}>
                        <TableHead id="thead">
                            <TableRow>
                                <TableCell sx={style}>Sr No.</TableCell>
                                <TableCell sx={style}>Name of Customer</TableCell>
                                <TableCell sx={style}>Loan Amount</TableCell>
                                <TableCell sx={style}>LTV</TableCell>
                                <TableCell sx={style}>Type of Loan</TableCell>
                                <TableCell sx={style}>Acoount Opening Form</TableCell>
                                <TableCell sx={style}>Eligibity Criteria</TableCell>
                                <TableCell sx={style}>Client Financials</TableCell>
                                <TableCell sx={style}>Date of Approval</TableCell>
                                <TableCell sx={style}>Application Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                                <TableCell component="th" scope="row"></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Footer />
        </Fragment>
    );
};

export default AdminView;
