import React, { useContext, memo } from "react";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Typography } from "@mui/material";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import TextField from "@mui/material/TextField";
import { useIsSmallScreen } from "hooks";
import isEqual from "lodash/isEqual";
import ShortFormContext from "./../../contexts/shortForm/context";

const RequirementForm = ({ setCurrentStep }: { setCurrentStep: React.Dispatch<React.SetStateAction<number>> }) => {
    const { handleNestedFieldChange, form } = useContext(ShortFormContext);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNestedFieldChange({
            requirementForm: {
                [e.target.name]: e.target.value,
            },
        });
    };
    const isSmallScreen = useIsSmallScreen();
    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="requirement-form">
                <Grid item xs={isSmallScreen ? 12 : 7}></Grid>
                <Grid item xs={isSmallScreen ? 12 : 5}>
                    <Typography variant="h5" className="short-form__title" component="h4">
                        Requirement
                    </Typography>
                    <div className="short-form__option-body">
                        <RadioGroup value={form.requirementForm.type} onChange={handleChange} name="type">
                            <FormControlLabel
                                value="construction_equipment_loan"
                                control={
                                    <Radio
                                        size="small"
                                        sx={{
                                            "&:hover": {
                                                bgcolor: "transparent",
                                            },
                                        }}
                                    />
                                }
                                label="Construction Equipment Loan"
                            />
                            <FormControlLabel value="other_business_loan" control={<Radio size="small" />} label="Other Business Loan" />
                        </RadioGroup>
                        <TextField
                            id="email"
                            sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none" } }}
                            label=""
                            required
                            type="text"
                            placeholder="Email Address"
                            onChange={handleChange}
                            value={form.requirementForm.email}
                            name="email"
                            className="short-form__input"
                            inputProps={{ className: "short-form__input-field" }}
                        />
                        {form.requirementForm.type === "construction_equipment_loan" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="p">
                                    Value
                                </Typography>
                                <TextField
                                    id="value"
                                    label=""
                                    type="number"
                                    variant="outlined"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black", borderRadius: "10px" } }}
                                    placeholder="10,000,000,000"
                                    onChange={handleChange}
                                    value={form.requirementForm.value}
                                    name="value"
                                    fullWidth
                                    autoComplete="outlined"
                                    className="short-form__input input-short"
                                    inputProps={{
                                        className: "short-form__input-field",
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <CurrencyRupeeIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <FormHelperText className="subtitle">Equipment Text</FormHelperText>
                                <Typography variant="h6" className="short-form__heading" component="p">
                                    Loan to Value (LTV)
                                </Typography>
                                <TextField
                                    id="loan-value"
                                    label=""
                                    type="number"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black", borderRadius: "10px" } }}
                                    placeholder="70%"
                                    onChange={handleChange}
                                    value={form.requirementForm.loanValue}
                                    name="loanValue"
                                    className="short-form__input input-short"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                            </>
                        ) : (
                            ""
                        )}
                        {form.requirementForm.type === "other_business_loan" ? (
                            <>
                                <Typography variant="h5" className="short-form__heading" component="h5">
                                    Get in touch
                                </Typography>
                                <FormHelperText className="subtitle">
                                    Give us your email and someone from our team will follow up with you.
                                </FormHelperText>
                                <TextField
                                    id="fname"
                                    label=""
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black" } }}
                                    type="text"
                                    placeholder="First Name"
                                    onChange={handleChange}
                                    value={form.requirementForm.fname}
                                    name="fname"
                                    className="short-form__input"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                                <br />
                                <TextField
                                    id="lname"
                                    label=""
                                    type="text"
                                    placeholder="Last Name"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black" } }}
                                    onChange={handleChange}
                                    value={form.requirementForm.lname}
                                    name="lname"
                                    className="short-form__input"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                                <br />
                                <TextField
                                    id="company"
                                    label=""
                                    type="text"
                                    placeholder="Company Name"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black" } }}
                                    onChange={handleChange}
                                    value={form.requirementForm.company}
                                    name="company"
                                    className="short-form__input"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                                <br />
                                <TextField
                                    id="phone"
                                    label=""
                                    type="text"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "1px solid black" } }}
                                    placeholder="Phone Number"
                                    onChange={handleChange}
                                    value={form.requirementForm.phone}
                                    name="phone"
                                    className="short-form__input"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                                <br />
                                <Button variant="contained" className="button-submit">
                                    Share Contact Details
                                </Button>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <br />
                    <Grid justifyContent="flex-end" style={{ width: "80%" }} container>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(1)}>
                            Next
                            <NavigateNextIcon />
                        </Button>
                    </Grid>
                    <Grid container direction="row" className="progress">
                        <Grid container direction="column" className="progress-bar" id="requirement">
                            20%
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default memo(RequirementForm, isEqual);
