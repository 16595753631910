import { YourDetailsCreateInput } from "@/__generated__/apiSchemas";
import { OTHER } from "globalConstants";

const getPersonalName = (personalDetails: YourDetailsCreateInput) => {
    // let prefix = personalDetails.prefix;
    // if (prefix === OTHER) prefix = personalDetails.otherPrefix;
    let name = personalDetails.firstName;
    if (personalDetails.middleName) name = name + " " + personalDetails.middleName;
    if (personalDetails.lastName) name = name + " " + personalDetails.lastName;

    return name ? `${name}` : "";
};

export default getPersonalName;
