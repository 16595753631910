import React from "react";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Button from "@mui/material/Button";
import { defaultWorkOrderFixture } from "fixtures";
import generateTestId from "utils/generateTestId";

const AddAnotherWorkOrder = ({ append, pane }: { append: any; pane?: string }) => {
    return (
        <Button
            onClick={() => {
                append({ ...defaultWorkOrderFixture, id: crypto.randomUUID() });
            }}
            className="common-fullwidth-button"
            disabled={localStorage.getItem("disable_form") === "true"}
            style={{ marginBottom: "1rem" }}
            data-testid={generateTestId(`add-work-order-${pane}`, "button")}
        >
            <AddCircleOutlineRoundedIcon fontSize="small" className="AddEquipmentIcon" />
            <span>Add Work Order</span>
        </Button>
    );
};

export default AddAnotherWorkOrder;
