import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

const InvestmentIncome = ({ prefix, activeFields, pane }: { prefix: string; activeFields: string[]; pane?: string }) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            {fixture.map(({ name, inputType, editable, options, reactHookFormRegisterOptions, subtitle, label }, index) => {
                activeFields.push(name);
                return (
                    <Fragment key={index}>
                        <CommonFields
                            inputType={inputType}
                            name={`${prefix}.investmentIncome.${name}`}
                            data-testid={generateTestId(`${label}-investment-${pane}`, "field")}
                            options={
                                options?.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                })) || []
                            }
                            label={label}
                            reactHookFormRegisterOptions={reactHookFormRegisterOptions}
                            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
                            subtitle={subtitle}
                        />
                    </Fragment>
                );
            })}
        </Grid>
    );
};

export default InvestmentIncome;
