import React from "react";
import Grid from "@mui/material/Grid";
import BankAccountItem from "./BankAccountItem";

const BankAccount = ({ prefix, setCurrentInput, index, pane }: { prefix: string; setCurrentInput: any; index: number; pane?: string }) => {
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <BankAccountItem index={index} prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
        </Grid>
    );
};

export default BankAccount;
