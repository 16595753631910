import React, { Fragment, useEffect } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { Autocomplete, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import CommonFields from "components/CommonFields";
import { OTHER } from "globalConstants";
import { useCompanyNames } from "hooks";
import generateTestId from "utils/generateTestId";
import { INPUT_TYPE } from "../../../Document";
import DeleteCustomer from "./DeleteCustomer";

type Props = {
    index: number;
    remove: (index: number) => void;
    prefix: string;
    pane?: string;
};

const Customer = ({ index, remove, prefix, pane }: Props) => {
    const customer = useWatch({
        name: `${prefix}.${index}`,
    });

    const { companyNames, isCompaniesLoading, setCompanyName } = useCompanyNames();

    const { register } = useFormContext();

    useEffect(() => {
        if (customer?.customerType === "Company") setCompanyName(customer?.customerName);
    }, [customer]);

    return (
        <Grid className="equipment-tab relative" container direction="row" justifyContent="flex-start" alignItems="flex-start">
            <CommonFields
                inputType={INPUT_TYPE.RADIO}
                readOnly={localStorage.getItem("disable_form") === "true"}
                ignoreOthers={true}
                options={[
                    {
                        label: "Company",
                        value: "Company",
                    },
                    {
                        label: "Other",
                        value: OTHER,
                    },
                ]}
                label={"Customer Type"}
                name={`${prefix}.${index}.customerType`}
                data-testid={generateTestId(`customer-type-customer-${index + 1}-${pane}`, "field")}
            />
            {customer?.customerType === "Company" ? (
                <Fragment key={index}>
                    <Grid item xs={12} container style={{ marginTop: "1rem" }}>
                        <Grid item xs={6}>
                            <Typography
                                className="common-label"
                                data-testid={generateTestId(`customer-name-customer-${index + 1}-${pane}`, "field-label")}
                            >
                                Customer Name
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Autocomplete
                                freeSolo
                                disablePortal
                                disabled={localStorage.getItem("disable_form") === "true"}
                                className="common__mui__textfield"
                                options={companyNames}
                                value={customer?.customerName}
                                data-testid={generateTestId(`customer-name-customer-${index + 1}-${pane}`, "field")}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        disabled={localStorage.getItem("disable_form") === "true" || isCompaniesLoading}
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: isCompaniesLoading ? <CircularProgress color="inherit" size={20} /> : null,
                                        }}
                                        className="autocomplete-input"
                                        //@ts-ignore
                                        {...register(`${prefix}.${index}.customerName`)}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Fragment>
            ) : (
                <CommonFields
                    inputType={INPUT_TYPE.TEXT}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    ignoreOthers={true}
                    subtitle={`Customer ${index + 1}`}
                    label={"Customer Name"}
                    name={`${prefix}.${index}.customerName`}
                    data-testid={generateTestId(`customer-name-customer-${index + 1}-${pane}`, "field")}
                />
            )}

            <CommonFields
                inputType={INPUT_TYPE.PRICE}
                readOnly={localStorage.getItem("disable_form") === "true"}
                ignoreOthers={true}
                subtitle={`Customer ${index + 1}`}
                label={"Revenue"}
                name={`${prefix}.${index}.revenue`}
                prop={{ value: "Revenue per year earned from this Customer" }}
                data-testid={generateTestId(`revenue-customer-${index + 1}-${pane}`, "field")}
            />

            {/* <DeleteCustomer index={index} remove={remove} /> */}
        </Grid>
    );
};

export default Customer;
