import { env } from "../../env";
import { getGenericGetRequest, getGenericPostRequest, buildURLs, buildKeys } from "../utils";

const BASE_URLS = {
    //GET requests
    GET_ALL_FORM_DATA: "form-data",
    UPLOAD_FILE: "file-data",
    SUBMIT_FORM_DATA: "form-data",
    CREDIT_BUREAU: "credit-bureau",

    // Post requests
    DELETE_FORM: "loan-application/delete",
    CONTACT_EMAIL: "loan-application/contactemail",
    SEND_EMAIL_SALESPERSON: "loan-application/assign-email",
    GENERATE_BANK_STATEMENT: "scoreme/sqs-bsa",
};

let prefix;
if (env.REACT_APP_API_URL) {
    prefix = `${env.REACT_APP_API_URL}`;
} else {
    prefix = `${env.REACT_APP_API_URL}`;
}
const URLS = buildURLs({
    urls: BASE_URLS,
    prefix: prefix,
});

// Export all generic functions
// GET requests
export const getAllFormData = getGenericGetRequest(URLS.GET_ALL_FORM_DATA);

// POST requests
export const uploadFile = getGenericPostRequest(URLS.UPLOAD_FILE);
export const submitFormData = getGenericPostRequest(URLS.SUBMIT_FORM_DATA);
export const deleteForm = getGenericPostRequest(URLS.DELETE_FORM);
export const contactEmail = getGenericPostRequest(URLS.CONTACT_EMAIL);
export const sendEmailSalesPerson = getGenericPostRequest(URLS.SEND_EMAIL_SALESPERSON);
export const uploadCreditBureauReport = getGenericPostRequest(URLS.CREDIT_BUREAU);
export const getScrapedCreditBureauReport = getGenericGetRequest(URLS.CREDIT_BUREAU);
export const getBankStatementReport = getGenericPostRequest(URLS.GENERATE_BANK_STATEMENT);

// PUT requests

// Export query keys
const KEYS = buildKeys(BASE_URLS);

export default KEYS;
