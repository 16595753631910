type Args = {
    invoiceValue: number;
    ownMargin: number;
};

const getLtvFromOwnMargin = ({ invoiceValue, ownMargin }: Args) => {
    const value = (((Number(invoiceValue) - Number(ownMargin)) / Number(invoiceValue)) * 100).toFixed(2);

    return value === null || isNaN(Number(value)) ? 0 : +value;
};

export default getLtvFromOwnMargin;
