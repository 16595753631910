import React, { useContext } from "react";
import Grid from "@mui/material/Grid";
import { Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import ShortFormContext from "./../../contexts/shortForm/context";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Button from "@mui/material/Button";
import { useIsSmallScreen } from "hooks";

const EntityForm = ({ setCurrentStep }: { setCurrentStep: React.Dispatch<React.SetStateAction<number>> }) => {
    const { handleNestedFieldChange, form } = useContext(ShortFormContext);
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        handleNestedFieldChange({
            entityForm: {
                [e.target.name]: e.target.value,
            },
        });
    };
    const isSmallScreen = useIsSmallScreen();

    return (
        <>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" id="entity-form">
                <Grid item xs={isSmallScreen ? 12 : 7}></Grid>
                <Grid item xs={isSmallScreen ? 12 : 5}>
                    <Typography variant="h5" className="short-form__title" component="h4">
                        Entity Details
                    </Typography>
                    <div className="short-form__option-body">
                        <RadioGroup value={form.entityForm.type} onChange={handleChange} name="type">
                            <FormControlLabel value="sole_proprietor" control={<Radio size="small" />} label="Sole Proprietor" />
                            <FormControlLabel value="partnership" control={<Radio size="small" />} label="Partnership" />
                            <FormControlLabel value="company" control={<Radio size="small" />} label="Company" />
                            <FormControlLabel value="other" control={<Radio size="small" />} label="Other:" />
                        </RadioGroup>
                        {form.entityForm.type === "partnership" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="h6">
                                    Liability Type
                                </Typography>
                                <RadioGroup row value={form.entityForm.liability} onChange={handleChange} name="liability">
                                    <FormControlLabel value="limited" control={<Radio size="small" />} label="Limited Liability" />
                                    <FormControlLabel value="unlimited" control={<Radio size="small" />} label="Unlimited Liability" />
                                </RadioGroup>
                            </>
                        ) : (
                            ""
                        )}
                        {form.entityForm.type === "company" ? (
                            <>
                                <Typography variant="h6" className="short-form__heading" component="h6">
                                    Incorporation Type
                                </Typography>
                                <RadioGroup row value={form.entityForm.incorporation} onChange={handleChange} name="incorporation">
                                    <FormControlLabel value="private" control={<Radio size="small" />} label="Private Limited" />
                                    <FormControlLabel value="public" control={<Radio size="small" />} label="Public Limited" />
                                </RadioGroup>
                            </>
                        ) : (
                            ""
                        )}
                        {form.entityForm.type === "other" ? (
                            <>
                                <TextField
                                    id="entity"
                                    label=""
                                    type="text"
                                    sx={{ "& .MuiOutlinedInput-notchedOutline": { border: "none", width: "50%" } }}
                                    onChange={handleChange}
                                    value={form.entityForm.entity}
                                    name="entity"
                                    className="short-form__input input-short"
                                    inputProps={{ className: "short-form__input-field" }}
                                />
                                <FormHelperText className="subtitle">Entity details</FormHelperText>
                            </>
                        ) : (
                            ""
                        )}
                    </div>
                    <br />
                    <Grid justifyContent="flex-end" style={{ width: "80%" }} container>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(0)}>
                            <ArrowBackIosIcon />
                            Back
                        </Button>
                        <Button variant="contained" className="btn-arrow" onClick={() => setCurrentStep(2)}>
                            Next
                            <NavigateNextIcon />
                        </Button>
                    </Grid>
                    <Grid container direction="row" className="progress">
                        <Grid container direction="column" className="progress-bar" id="entity">
                            40%
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default EntityForm;
