import { LoanFromAccountCreateInput } from "@/__generated__/apiSchemas";
import { BANK_NAMES } from "globalConstants";

const mapLoanAccountCreditFields = (loanAccount: LoanFromAccountCreateInput[]) =>
    loanAccount.map((loan: any) => {
        return {
            statement: [{ link: "" }],
            accountType: loan["accountType"],
            accountStatus: loan["accountStatus"],
            accountNumber: loan["accountNumber"],
            bankName: loan["financedBy"],
            bankCode: BANK_NAMES.find((bank) => bank.name === loan["financedBy"])?.code ?? "",
        };
    });

export default mapLoanAccountCreditFields;
