import axios, { AxiosRequestConfig } from "axios";
import { ICommonObject } from "types";

const getCookie = (name: string) => {
    var cookieValue = null;
    if (document.cookie && document.cookie !== "") {
        var cookies = document.cookie.split(";");
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            if (cookie.substring(0, name.length + 1) === name + "=") {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
};

// Get CSRF token
export const getCSRFToken = () => {
    const csrftoken = getCookie("csrftoken");

    return csrftoken;
};

// Append all the routes with the URL_PREFIX
export const buildURLs = ({ urls, prefix }: { urls: ICommonObject; prefix: string }) => {
    const newUrls: ICommonObject = {};

    for (const key of Object.keys(urls)) {
        newUrls[key] = prefix + urls[key];
    }

    return newUrls;
};

// Build keys for react query
export const buildKeys = (urls: ICommonObject) => {
    const keys: ICommonObject = {};

    for (const key of Object.keys(urls)) {
        keys[key] = key;
    }

    return keys;
};

export const getCommonHeaders = (isExternal = false) => ({
    "Content-Type": "application/json",
    // add authorization header if not external
    ...(isExternal ? {} : { Authorization: `Bearer ${localStorage.getItem("authToken") || ""}` }),
});

export const getGenericGetRequest =
    (url: string, isExternal = false) =>
    async ({ pathParams, queryParams }: { pathParams?: ICommonObject; queryParams?: ICommonObject } = {}) => {
        let urlWithPathParams = url;

        // Add path params to url
        if (pathParams) {
            for (const key of Object.keys(pathParams)) {
                urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key]);
            }
        }

        const options: AxiosRequestConfig = {
            baseURL: "/",
            headers: {
                ...getCommonHeaders(isExternal),
            },
        };

        // Add query params to options
        if (queryParams) {
            options.params = queryParams;
        }

        const response = await axios.get(urlWithPathParams, options);

        return response.data;
    };

// Function to return the generic post request
export const getGenericPostRequest = (url: string) => async (postData: ICommonObject<any>) => {
    const { pathParams, headers: overridenHeaders, queryParams, ...data } = postData || {};
    const headers = {
        ...getCommonHeaders(),
        ...(overridenHeaders || {}),
    };
    let urlWithPathParams = url;

    // Add path params to url
    if (pathParams) {
        for (const key of Object.keys(pathParams)) {
            urlWithPathParams = urlWithPathParams.replace(`:${key}`, pathParams[key]);
        }
    }

    const options: AxiosRequestConfig = {
        baseURL: "/",
        headers,
        withCredentials: true,
    };

    // Add query params to options
    if (queryParams) {
        options.params = queryParams;
    }

    const response = await axios.post(urlWithPathParams, data || {}, options);
    return response.data;
};

// Function to return the generic put request
export const getGenericPutRequest = (url: string) => async (data: ICommonObject) => {
    const headers = {
        "Content-Type": "application/json",
    };

    const response = await axios.put(url, data || {}, {
        headers,
        baseURL: "/",
        data,
    });
    return response.data;
};
