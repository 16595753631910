import { Fragment, memo, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useCityStateValue } from "hooks";
import isEqual from "lodash/isEqual";
import generateTestId from "utils/generateTestId";
import fixture from "./fixture";

type Props = {
    name: string;
    variant: "basic" | "common";
    isSameAsOtherAddress?: {
        name: string;
        options: {
            label: string;
            value: boolean;
        }[];
    };
    title: string;
    readOnly?: boolean;
    tab: string;
    keys?: string;
    aadharValidated?: boolean;
    pane?: string;
};

const FIELDS_NOT_IN_BASIC = ["lengthOfStayInYears", "lengthOfCityStayInYears", "ownership", "rentType", "monthlyRent", "ownerDetails"];

const emptyArray: [] = [];

const CommonAddressField = ({
    name,
    pane,
    variant,
    isSameAsOtherAddress,
    title,
    tab,
    keys = "",
    aadharValidated = false,
    readOnly = false,
}: Props) => {
    const postalCode = useWatch({
        name: `${name}.postalCode`,
    });

    const rentType = useWatch({
        name: `${name}.rentType`,
    });

    const { setValue, getValues } = useFormContext();

    const ownership = useWatch({
        name: `${name}.ownership`,
    });

    // const isSameAsOtherAddressValue = useWatch({
    //     name: isSameAsOtherAddress ? `${name}.${isSameAsOtherAddress.name}` : "",
    // });

    const { isLoading } = useCityStateValue({
        pincode: postalCode,
        cityName: `${name}.city`,
        stateName: `${name}.state`,
    });

    const autoPopulatedFields = ["address1", "address2", "postalCode"];

    const address = useWatch({
        name: `${name}`,
    });
    const errors = useWatch({
        name: `errors.${name}`,
    });

    const isSameAsOtherAddressValue = address[isSameAsOtherAddress?.name ?? ""];
    const activeFields: string[] = [];

    useEffect(() => {
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (getValues(`${name}.${item}`) === "" || getValues(`${name}.${item}`) === undefined) {
                errorsCounts++;
            }
        }
        setValue(`errors.${name}.errorsCounts`, errorsCounts);
    }, [address]);

    useEffect(() => {
        if ((name.includes("registeredAddress") && !isSameAsOtherAddressValue) || name.includes("residentialAddress")) {
            setValue("contact.email", address?.email);
            setValue("contact.mobile", address?.mobile);
        }
    }, [address, isSameAsOtherAddressValue]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title={title}
            tab={tab}
            keys={keys}
            data-testid={generateTestId(`${title}-${pane}`, "accordion")}
        >
            <Grid className="address-tab" container direction="row" justifyContent="flex-start" alignItems="center">
                {isSameAsOtherAddress && (
                    <Fragment>
                        {activeFields.push(isSameAsOtherAddress.name)}
                        <CommonFields
                            inputType={INPUT_TYPE.SELECT}
                            name={`${name}.${isSameAsOtherAddress.name}`}
                            subtitle={""}
                            options={
                                isSameAsOtherAddress?.options.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                })) || []
                            }
                            label={""}
                            readOnly={readOnly}
                            reactHookFormRegisterOptions={{
                                setValueAs: (value) => {
                                    return value == "true" || value == true;
                                },
                            }}
                            data-testid={generateTestId(`same-or-different-address-${title.split(" ")[0].toLowerCase()}-${pane}`, `field`)}
                        />
                    </Fragment>
                )}

                {!isSameAsOtherAddressValue &&
                    fixture.map(({ name: fieldName, inputType, options, subtitle, label, editable, showMask }, index) => {
                        if (variant == "basic") {
                            for (let i = 0; i < FIELDS_NOT_IN_BASIC.length; i++) {
                                if (fieldName == FIELDS_NOT_IN_BASIC[i]) return "";
                            }
                        }
                        //owner details is not shown
                        if (fieldName.includes("ownerDetails")) return "";
                        //monthly rent only shown in rented ownership
                        if (ownership !== "Rented" && fieldName === "monthlyRent") return "";
                        //relation drop down only shown in when owned by relation
                        if (ownership !== "Owned by relation" && fieldName === "relation") return "";

                        if (["email", "mobile"].includes(fieldName) && !name.includes("residentialAddress") && !name.includes("registeredAddress"))
                            return "";

                        if (fieldName === "rocLocation" && !name.includes("registeredAddress")) return "";
                        activeFields.push(fieldName);

                        return (
                            <Fragment key={index}>
                                {ownership === "Rented" && fieldName === "ownerDetails.prefix" && (
                                    <Grid container xs={12} justifyContent="center" alignItems="center">
                                        <h4 className="other-label">Owner Details</h4>
                                    </Grid>
                                )}
                                <CommonFields
                                    inputType={inputType}
                                    name={`${name}.${fieldName}`}
                                    subtitle={subtitle}
                                    options={options || emptyArray}
                                    label={label}
                                    readOnly={
                                        readOnly || (autoPopulatedFields.includes(fieldName) ? (aadharValidated ? true : !editable) : !editable)
                                    }
                                    showMask={showMask}
                                    adorment={
                                        isLoading &&
                                        (fieldName.endsWith("city") || fieldName.endsWith("state")) && (
                                            <CircularProgress size={20} style={{ marginRight: "1rem" }} />
                                        )
                                    }
                                    data-testid={generateTestId(`${label}-${title.split(" ")[0].toLowerCase()}-${pane}`, "field")}
                                />
                            </Fragment>
                        );
                    })}
            </Grid>
        </CommonAccordion>
    );
};

export default memo(CommonAddressField, isEqual);
