import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { getInvoiceValueFromBasicPrice, getLtvFromOwnMargin } from "utils";

const BasicPriceOrInvoiceValue = ({ index }: { index: number }) => {
    const { setValue, watch, clearErrors, setError } = useFormContext();
    const disableInvoiceValue = watch(`requirement.vehicles.${index}.disableInvoiceValue`);
    const disableOwnMargin = watch(`requirement.vehicles.${index}.disableOwnMargin`);
    const tcsPercentage = watch(`requirement.vehicles.${index}.tcsPercentage`);
    const invoiceValue = watch(`requirement.vehicles.${index}.invoiceValue`);
    const loanToValue = watch(`requirement.vehicles.${index}.loanToValue`);
    const basicPrice = watch(`requirement.vehicles.${index}.basicPrice`);
    const OwnMargin = watch(`requirement.vehicles.${index}.ownMargin`);

    return (
        <Grid container className="common__or__container" justifyContent="center" alignItems="center">
            <Grid item xs={6}>
                <div className="or__top">
                    <div className="line" />
                    <Typography className="common-mui-accordian__text" data-testid={`invoice-value-vehicle-${index + 1}-field-label`}>
                        Invoice value <span style={{ color: "red" }}> *</span>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <CommonFields
                    name={`requirement.vehicles.${index}.invoiceValue`}
                    label=""
                    inputType={INPUT_TYPE.PRICE}
                    placeholder="35,75,400"
                    showLabel={false}
                    data-testid={`invoice-value-vehicle-${index + 1}-field`}
                    shouldAddMarginTop={false}
                    reactHookFormRegisterOptions={{
                        onChange: (e) => {
                            const value = e.target.value?.split(",").join("");
                            const invoiceValue = value || 0;
                            const tcs = +((invoiceValue * tcsPercentage) / (100 + tcsPercentage));
                            const basicPrice = +((invoiceValue - tcs) / 1.18).toFixed(2);
                            if (disableOwnMargin) {
                                let ownMargin = +(invoiceValue - invoiceValue * (loanToValue / 100)).toFixed(2);
                                ownMargin = ownMargin > 0 ? ownMargin : 0;
                                setValue(`requirement.vehicles.${index}.ownMargin`, `${ownMargin}`);
                            } else {
                                let ltv = getLtvFromOwnMargin({ ownMargin: watch(`requirement.vehicles.${index}.ownMargin`), invoiceValue });
                                setValue(`requirement.vehicles.${index}.loanToValue`, ltv > 0 ? ltv : 0);
                                if (OwnMargin < invoiceValue) {
                                    clearErrors(`requirement.vehicles.${index}.ownMargin`);
                                } else {
                                    setError(`requirement.vehicles.${index}.ownMargin`, {
                                        type: "manual",
                                        message: "Own Margin cannot be greater than Equipment Cost",
                                    });
                                }
                            }
                            setValue(`requirement.vehicles.${index}.basicPrice`, `${basicPrice}`);
                            setValue(`requirement.vehicles.${index}.disableInvoiceValue`, false);
                        },
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || (basicPrice && disableInvoiceValue)}
                />
            </Grid>
            <Grid item xs={12}>
                <div className="or__circle">or</div>
            </Grid>
            <Grid item xs={6}>
                <div className="or__bottom">
                    <div className="line" />
                    <Typography className="common-mui-accordian__text" data-testid={`basic-price-vehicle-${index + 1}-field-label`}>
                        Basic Price <span style={{ color: "red" }}> *</span>
                    </Typography>
                </div>
            </Grid>
            <Grid item xs={6}>
                <CommonFields
                    name={`requirement.vehicles.${index}.basicPrice`}
                    label=""
                    inputType={INPUT_TYPE.PRICE}
                    data-testid={`basic-price-vehicle-${index + 1}-field`}
                    reactHookFormRegisterOptions={{
                        onChange: (e) => {
                            const value = e.target.value?.split(",").join("");
                            setValue(`requirement.vehicles.${index}.basicPrice`, `${value}`);
                            let invoiceValue = getInvoiceValueFromBasicPrice({ basicPrice: value, tcsPercentage });
                            setValue(`requirement.vehicles.${index}.invoiceValue`, `${invoiceValue}`);
                            if (disableOwnMargin) {
                                let ownMargin = +(invoiceValue - invoiceValue * (loanToValue / 100)).toFixed(2);
                                ownMargin = ownMargin > 0 ? ownMargin : 0;
                                setValue(`requirement.vehicles.${index}.ownMargin`, `${ownMargin}`);
                            } else {
                                let ltv = getLtvFromOwnMargin({ ownMargin: watch(`requirement.vehicles.${index}.ownMargin`), invoiceValue });

                                setValue(`requirement.vehicles.${index}.loanToValue`, ltv > 0 ? ltv : 0);
                            }

                            setValue(`requirement.vehicles.${index}.disableInvoiceValue`, true);
                        },
                    }}
                    shouldAddMarginTop={false}
                    showLabel={false}
                    placeholder="30,00,000"
                    readOnly={localStorage.getItem("disable_form") === "true" || (invoiceValue && !disableInvoiceValue)}
                />
            </Grid>
        </Grid>
    );
};

export default BasicPriceOrInvoiceValue;
