import { INPUT_TYPE } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "aadhar",
        label: "Aadhar",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Validate Aadhar",
    },
    {
        name: "pan",
        label: "PAN",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        subtitle: "Validate PAN",
    },
    {
        name: "din",
        label: "DIN",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
];

export default fixture;
