import { RegisterOptions, useFormContext } from "react-hook-form";
import { useController } from "react-hook-form";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { IOption } from "types";
import { combineClassNames } from "utils";
import generateTestId from "utils/generateTestId";
import CommonLabel from "../CommonLabel";

type Props = {
    showLabel: boolean;
    label: string;
    labelSubtitle?: string;
    readOnly?: boolean;
    options: IOption[];
    reactHookFormRegisterOptions?: RegisterOptions;
    subtitle: string;
    positionHelperTextAbsolute: boolean;
    name: string;
    infoText?: string;
    className?: string;
    "data-testid"?: string;
    value?: string | number;
    disableReactHookForm: boolean;
};

const CommonRadioField = ({
    showLabel,
    label,
    labelSubtitle,
    readOnly = false,
    reactHookFormRegisterOptions,
    options,
    subtitle,
    positionHelperTextAbsolute,
    name,
    infoText,
    className,
    "data-testid": dataTestId,
    value,
    disableReactHookForm,
}: Props) => {
    const { control, register, getValues } = useFormContext();

    const { field } = useController({
        control,
        name,
        rules: {
            ...reactHookFormRegisterOptions,
            // need to do this to prevent unexpected behaviour
            onChange: undefined,
        },
    });

    const registerObject = disableReactHookForm ? { value } : field;

    return (
        <Grid className="common__mui__textfield__container" container direction="row" justifyContent="center" alignItems="center">
            <CommonLabel
                infoText={infoText}
                showLabel={showLabel}
                label={label}
                labelSubtitle={labelSubtitle}
                required={!!reactHookFormRegisterOptions?.required}
                data-testid={`${dataTestId}-label`}
            />
            <Grid item xs={showLabel ? 6 : 12}>
                <RadioGroup
                    className={combineClassNames("common-mui-accordian__radio", className)}
                    row
                    {...registerObject}
                    data-testid={dataTestId}
                    onChange={(e) => {
                        let value: string | boolean = e.target.value;
                        if (e.target.value === "true") value = true;
                        if (e.target.value === "false") value = false;

                        if (!disableReactHookForm) field.onChange({ target: { value } });

                        if (reactHookFormRegisterOptions?.onChange) {
                            reactHookFormRegisterOptions.onChange(e);
                        }
                        if (reactHookFormRegisterOptions?.setValueAs) {
                            reactHookFormRegisterOptions.setValueAs(e.target.value);
                        }
                    }}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            disabled={readOnly}
                            value={option.value}
                            key={option.label}
                            control={<Radio size="small" />}
                            label={option.label}
                            data-testid={generateTestId(`${option.label.toLowerCase()}-${dataTestId?.replace("-field", "")}`, "radio-button")}
                        />
                    ))}
                </RadioGroup>
                {subtitle ? (
                    <FormHelperText className={`common__helper-text ${positionHelperTextAbsolute ? "absolute" : ""}`}>{subtitle}</FormHelperText>
                ) : (
                    ""
                )}
                <FormHelperText error={(getValues(name) === "" || getValues(name) === undefined) && getValues("showIncompleteField") == true}>
                    {(getValues(name) === "" || getValues(name) === undefined) &&
                        getValues("showIncompleteField") == true &&
                        "This field is required"}
                </FormHelperText>
            </Grid>
        </Grid>
    );
};

export default CommonRadioField;
