import { INPUT_TYPE } from "globalConstants";
import moment from "moment";
import { ICommonFixtures } from "types";

const fixture: ICommonFixtures[] = [
    {
        name: "date",
        label: "Follow Up Date",
        inputType: INPUT_TYPE.DATE,
        defaultValue: "",
        editable: true,
        reactHookFormRegisterOptions: {
            setValueAs(value) {
                return moment(value).toISOString();
            },
        },
    },

    {
        name: "priority",
        label: "Priority",
        inputType: INPUT_TYPE.SELECT,
        defaultValue: "1",
        options: [
            {
                label: "Low",
                value: 1,
            },
            {
                label: "Medium",
                value: 2,
            },
            {
                label: "High",
                value: 3,
            },
        ],
        editable: true,
        reactHookFormRegisterOptions: {
            setValueAs(value) {
                return Number(value)
            },
        }
    },
    {
        name: "subject",
        label: "Subject",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "manufacturer",
        label: "Manufacturer",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
    {
        name: "category",
        label: "Category",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
    {
        name: "noOfUnits",
        label: "No. of Units",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
];

export default fixture;
