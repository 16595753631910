const ACCOUNT_TYPES = [
    "Term Loan",
    "Overdraft",
    "Property Loan",
    "Housing Loan",
    "Auto Loan",
    "Construction Equipment Loan",
    "Credit Card",
    "Credit Cards",
    "Credit Card Loans",
    "Home Loan",
    "Consumer Loan",
    "Bl General",
    "Personal Loan",
    "Two Wheeler Loan",
];

export default ACCOUNT_TYPES;
