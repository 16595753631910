import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useGetAllLoanApplication } from "__generated__/apiComponents";
import { LoanApplicationCreateWithoutApplicantInput } from "__generated__/apiSchemas";
import { defaultValues } from "contexts/longForm";
import { ROUTES } from "globalConstants";
import { useError } from "hooks";

const useSetDefaultValues = () => {
    const [loading, setLoading] = useState(true);

    const location = useLocation();

    const [allData, setAllData] = useState<any>();

    const showError = useError();

    const { id: formId } = useParams();

    const navigate = useNavigate();

    const { refetch: refetchValues, error } = useGetAllLoanApplication<LoanApplicationCreateWithoutApplicantInput[]>(
        {},
        {
            onSuccess(data: any) {
                setAllData(data);
            },
            enabled: false,
        }
    );

    useEffect(() => {
        refetchValues();
        setLoading(true);
    }, []);

    if (error) {
        showError(error);
    }

    let formData = useMemo(() => {
        // let formId = localStorage.getItem("form_id");
        if (formId && allData) {
            const formData = allData?.find((item: any) => item.id === formId);
            if (formData) {
                return formData;
            } else {
                setLoading(false);
                toast.dismiss();
                toast.error("Loan application does not exist");
                navigate(ROUTES.HOME_PAGE);
            }
        }
        return [];
    }, [allData]);

    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });

    const setValue = methods.setValue;

    const setDefaultValues = async (values: any) => {
        await Object?.entries(values).forEach(([name, value]: any) => setValue(name, value));
        setLoading(false);
    };

    useEffect(() => {
        if (formData?.data) {
            setDefaultValues(formData?.data);
            // @ts-ignore
            setValue("loanApplicant", formData?.applicant);
            localStorage.setItem("is_masked", formData?.is_masked);
            setLoading(true);
        }
    }, [formData?.data]);

    return { loading, methods, refetchValues, formData };
};

export default useSetDefaultValues;
