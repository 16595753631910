import FEATURES from "globalConstants/features";

const useFeature = (featureName: keyof typeof FEATURES) => {
    let env: "test" | "development" | "staging" | "production" = process.env.NODE_ENV;
    if (window.location.hostname === "smecreditt-web-client.caprover.articunocoding.com") env = "staging";
    const feature = FEATURES[featureName];
    return feature.includes(env);
};

export default useFeature;
