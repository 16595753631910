import jwtDecode from "jwt-decode";

const getSessionInterval = () => {
    const authToken = localStorage.getItem("authToken");
    let interval = 15; // Refresh the token in every 10 minutes
    if (authToken) {
        try {
            const data: any = jwtDecode(authToken);
            interval = (data.exp - Date.now() / 1000) / 60;
        } catch (error) { }
    }
    return interval > 0 ? interval : 15;

}

export default getSessionInterval