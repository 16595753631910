import { TradeReferenceCreateInput } from "__generated__/apiSchemas";

const fixture: TradeReferenceCreateInput = {
    name: "",
    mobile: "",
    landline: "",
    email: "",
    details: "",
    noOfYears: 0,
    reference: "",

    relationshipToBorrower: "Son",
    otherRelationshipToBorrower: "",
    remarks: "",
};

export default fixture;
