import { useWatch } from "react-hook-form";
import { defaultCoborrowerFixture } from "fixtures";
import { useFieldArrayWithAutoAddAndRemove } from "hooks";

const useGuarantorFields = () => {
    const guarantorValue = useWatch({
        name: `requirement.hasGuarantor`,
    });

    const hasGuarantor = guarantorValue === true || guarantorValue === true ? true : false;

    const { fields } = useFieldArrayWithAutoAddAndRemove({
        name: `guarantor`,
        countName: `requirement.numberOfGuarantor`,
        fixture: defaultCoborrowerFixture,
    });

    return hasGuarantor === true ? fields : [];
};

export default useGuarantorFields;
