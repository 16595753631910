import { useSignOut } from "react-auth-kit";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import { useDelete, useSaveLoanApplication } from "__generated__/apiComponents";
import { LoanApplicationDataCreateInput } from "__generated__/apiSchemas";
import { ROUTES } from "globalConstants";
import { useSaveForm } from "hooks";
import { fixFormValues } from "utils/fixFormValues";

interface Props {
    handleClose: () => void;
    currentInput: {
        name: string;
        open: boolean;
        multiple: boolean;
        position: number;
    };
}
const DeselectFileModal = ({ handleClose, currentInput }: Props) => {
    const { open, name, multiple, position } = currentInput;
    const { setValue, getValues } = useFormContext();
    const style = {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "#eae7ed",
        border: "1px solid #000",
        borderRadius: "10px",
        boxShadow: 24,
        p: 3,
    };

    const signOut = useSignOut();
    const navigate = useNavigate();
    const formId = localStorage.getItem("form_id");
    const saveForm = useSaveForm(false);

    const { mutate: deleteFile } = useDelete({
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message ?? "Something went wrong");
            if (error?.stack?.message === "Session Expired") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });

    const handleDeselect = () => {
        const value = getValues(name);

        // console.log(value, position, name);

        if (name.includes("creditBureau.report")) setValue(name, { link: "" });
        else {
            if (multiple) {
                const filterValue = value?.filter((item: any, index: number) => {
                    if (index !== position) {
                        return item;
                    }
                });
                if (filterValue?.length === 0) {
                    setValue(name, [{ link: "" }]);
                } else {
                    setValue(name, filterValue);
                }
            } else {
                setValue(name, { link: "" });
            }
        }
        let fileType = name.split(".")[4];
        const subtab = name.split(".")[3];
        const selection = name.split(".")[2];
        if (subtab === "personal") {
            fileType = fileType.replace(".", "/");
        }
        if (selection === "creditBureau") {
            fileType = selection;
        }

        let path = "";

        if (multiple) {
            path = `${formId}/data/${name.replace(/\./g, "/")}/${value?.[position]?.filename}`;
        } else {
            path = `${formId}/data/${name.replace(/\./g, "/")}/${value?.filename}`;
        }

        deleteFile({ body: { path } });
        saveForm();
        toast.dismiss();
        toast.success("File Removed Successfully");
        handleClose();
    };
    return (
        <Modal hideBackdrop open={open} onClose={handleClose}>
            <Box sx={{ ...style, width: 200 }}>
                <Grid container justifyContent="center" alignItems="center">
                    <Grid item xs={12} container justifyContent={"flex-end"} alignItems="center">
                        <CloseOutlinedIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className="otp-text">Are you sure you want to Deselect the file?</Typography>
                    </Grid>
                    <Grid item xs={12} container justifyContent="space-around">
                        <Button variant="contained" className="btn bg-blue-600" onClick={handleDeselect}>
                            Yes
                        </Button>
                        <Button variant="contained" className="btn text-black hover:bg-gray-300" onClick={handleClose}>
                            No
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>
    );
};

export default DeselectFileModal;
