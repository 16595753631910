import React, { useState, useContext } from "react";
import toast from "react-hot-toast";
import P from "react-phone-input-2";
import { useMutation } from "react-query";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormControlLabel } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormHelperText from "@mui/material/FormHelperText";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useRegister } from "__generated__/apiComponents";
import { register } from "api";
import { AxiosError } from "axios";
import Footer from "components/Footer";
import GoogleLoginButton from "components/GoogleLoginButton";
import Navbar from "components/Navbar";
import { ROUTES } from "globalConstants";
import "styles/register.scss";

// @ts-ignore
const PhoneInput = P.default ?? P;

const Register = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const queryEmail = query.get("email");

    const [{ password, confirmPassword, email, mobile, country_code }, setValues] = useState({
        password: "",
        confirmPassword: "",
        email: queryEmail ?? "",
        mobile: "",
        country_code: "",
    });

    const [privacyCheck, setPrivacyCheck] = useState(false);

    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const navigate = useNavigate();

    const { mutate: handleRegister, isLoading } = useRegister({
        onSuccess: (data) => {
            toast.success("Registration Successful. Please Login to Continue");
            navigate(ROUTES.LOGIN);
        },
        onError: (error: any) => {
            toast.error(error?.stack?.message || "Something went wrong");
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handlePrivacyCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrivacyCheck(e.target.checked);
    };

    const handleClickShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };
    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const handleSubmit = () => {
        toast.dismiss();

        const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@!%*?&])[A-Za-z\d#@!%*?&]{8,}/;
        if (!email) {
            toast.error("Email is required");
            return;
        } else if (!mobile && mobile.length < 10) {
            toast.error("Mobile Number is required");
            return;
        } else if (!password) {
            toast.error("Password is required");
            return;
        } else if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        } else if (!passwordRegEx.test(password)) {
            toast.error(
                "Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number and one special character"
            );
            return;
        } else if (!privacyCheck) {
            toast.error("please accept privacy and terms and conditions to continue");
            return;
        }

        handleRegister({
            body: { email, password, username: email, mobile: mobile.replace(country_code, `+${country_code}-`) },
        });
    };

    return (
        <Grid container direction="column" id="register__container">
            <Navbar />
            <Grid id="register__content" item xs={12} justifyContent="center">
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                    <Grid id="register__image" item sx={{ display: { xs: "none", md: "block" } }} xs={12} md={6}>
                        <div />
                    </Grid>
                    <Grid item id="register__form" xs={12} md={6}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h4" id="register__heading">
                                REGISTER
                            </Typography>
                            {/* {!queryEmail && (
                                <>
                                    <GoogleLoginButton id="google-btn" type="register" />
                                    <Typography id="dash">
                                        <Typography id="circle">or</Typography>
                                    </Typography>
                                </>
                            )} */}

                            <FormControl className="field">
                                <TextField
                                    className="styled-textfield"
                                    name="email"
                                    label="Email"
                                    placeholder=""
                                    defaultValue=""
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                    value={email}
                                    required
                                    inputProps={{ "data-testid": "register-email-input" }}
                                />
                                <FormHelperText id="helperText" />
                            </FormControl>
                            <FormControl className="field">
                                <PhoneInput
                                    country={"in"}
                                    value={mobile}
                                    onChange={(val: string, data: any) => {
                                        setValues((prevState) => ({
                                            ...prevState,
                                            mobile: val,
                                            country_code: data?.dialCode,
                                        }));
                                    }}
                                    required
                                    inputProps={{
                                        name: "mobile",
                                        required: true,
                                        className: "w-full py-2 pl-12 outline-none border border-gray-200 ",
                                        "data-testid": "register-mobile-input",
                                    }}
                                    placeholder="Phone Number"
                                />
                                <FormHelperText id="helperText" />
                            </FormControl>

                            <FormControl className="field" variant="outlined" required>
                                <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    name="password"
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                    inputProps={{ "data-testid": "register-password-input" }}
                                />
                            </FormControl>
                            <FormControl className="field" variant="outlined" required>
                                <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmPassword}
                                    onChange={handleChange}
                                    name="confirmPassword"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm Password"
                                    inputProps={{ "data-testid": "register-confirm-password-input" }}
                                />
                            </FormControl>
                            <FormControl className="field" variant="outlined">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Checkbox className="privacy-checkbox" name="privacyCheck" onChange={handlePrivacyCheck} />}
                                        data-testid="register-checkbox"
                                        label={
                                            <p className="label">
                                                By clicking this checkbox, you agree with our{" "}
                                                <a href={ROUTES.TERMS_AND_CONDITIONS} rel="noreferrer" target="_blank">
                                                    {" "}
                                                    Terms & Conditions{" "}
                                                </a>{" "}
                                                and{" "}
                                                <a href={ROUTES.PRIVACY_POLICY} rel="noreferrer" target="_blank">
                                                    {" "}
                                                    Privacy Policy{" "}
                                                </a>
                                            </p>
                                        }
                                    />
                                </FormGroup>
                            </FormControl>

                            <Button disabled={isLoading} onClick={handleSubmit} variant="contained" id="register__btn" data-testid="register-button">
                                {isLoading ? <CircularProgress className="white" /> : "Register"}
                            </Button>
                            <Link to={ROUTES.LOGIN} id="login__btn">
                                Existing User? Login
                            </Link>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Grid>
    );
};

export default Register;
