import React, { useState } from "react";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { useChangePassword } from "__generated__/apiComponents";
import "./ChangePasswordPopup.styles.scss";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2),
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1),
    },
}));

export interface DialogTitleProps {
    id: string;
    children?: React.ReactNode;
    onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
    const { children, onClose, ...other } = props;
    return (
        <DialogTitle style={{ lineHeight: "1.4rem", letterSpacing: "0.0075em", padding: "6px 24px" }} sx={{ m: 0, p: 0 }} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    data-testid="close-change-password-button"
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
};

type ChangePasswordProps = {
    handleClickOpen: (params: any) => any;
    handleClose: () => void;
    open: boolean;
};

export default function CustomizedDialogs(props: ChangePasswordProps) {
    const [{ currentPassword, newPassword }, setValues] = useState({
        currentPassword: "",
        newPassword: "",
    });
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues((prevState) => ({ ...prevState, [event.target.name]: event.target.value }));
    };

    const handleClickShowCurrentPassword = () => {
        setShowCurrentPassword((prevState) => !prevState);
    };
    const handleClickShowNewPassword = () => {
        setShowNewPassword((prevState) => !prevState);
    };
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleClose = () => {
        props.handleClose();
        setValues({
            currentPassword: "",
            newPassword: "",
        });
        setShowCurrentPassword(false);
        setShowNewPassword(false);
    };

    const { mutate: changePassword } = useChangePassword({
        onSuccess: (data: any) => {
            toast.dismiss();
            toast.success(data);
            handleClose();
        },
        onError: (error) => {
            toast.dismiss();
            // @ts-ignore
            toast.error(error?.stack?.message || "Something went wrong");
        },
    });

    const handlePasswordChange = () => {
        if (!currentPassword) {
            toast.error("Current password is required");
            return;
        } else if (!newPassword) {
            toast.error("New password is required");
            return;
        }

        changePassword({
            body: {
                oldPassword: currentPassword,
                newPassword,
            },
        });
    };

    return (
        <div>
            <BootstrapDialog onClose={handleClose} aria-labelledby="change-password-dialog-title" open={props.open}>
                <BootstrapDialogTitle id="change-password-dialog-title" onClose={handleClose}>
                    <h3 data-testid="change-password-title" style={{ fontSize: "24px", fontWeight: "bolder", padding: 8 }}>
                        Change Password
                    </h3>
                </BootstrapDialogTitle>
                <DialogContent>
                    <FormControl sx={{ width: { xs: "45ch", sm: "55ch" }, m: 1 }} className="change-password__container">
                        <OutlinedInput
                            fullWidth
                            className="password-input"
                            name="currentPassword"
                            value={currentPassword}
                            onChange={handleChange}
                            // label="Enter Current Password"
                            placeholder="Enter Current Password"
                            type={showCurrentPassword ? "text" : "password"}
                            style={{ marginBottom: "15px" }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowCurrentPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps={{ "data-testid": "current-password-change-password-field" }}
                        />
                        <OutlinedInput
                            fullWidth
                            className="password-input"
                            type={showNewPassword ? "text" : "password"}
                            name="newPassword"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={handleChange}
                            required
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowNewPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            inputProps={{ "data-testid": "new-password-change-password-field" }}
                            // label="Enter new password"
                        />
                        <FormHelperText id="helperText"></FormHelperText>
                    </FormControl>
                </DialogContent>
                <DialogActions>
                    <Button data-testid="cancel-change-password-button" variant="text" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        data-testid="change-password-button"
                        variant="contained"
                        style={{ borderRadius: "1rem", backgroundColor: "#f15a29" }}
                        color="warning"
                        onClick={handlePasswordChange}
                    >
                        Change Password
                    </Button>
                </DialogActions>
            </BootstrapDialog>
        </div>
    );
}
