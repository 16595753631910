import { Fragment, useState } from "react";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import DeleteForeverOutlinedIcon from "assets/images/delete.svg";
import generateTestId from "utils/generateTestId";

const DeleteAsset = ({ index, handleDeleteAsset }: { index: number; handleDeleteAsset: (index: number) => void }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDelete = (index: number) => {
        handleClose();
        handleDeleteAsset(index);
    };

    return (
        <Fragment>
            {index > 0 ? (
                <Button
                    style={{
                        position: "absolute",
                        top: "2px",
                        right: "-62px",
                        width: "1rem",
                    }}
                    disabled={localStorage.getItem("disable_form") === "true"}
                    color="error"
                    data-testid={generateTestId(`Asset ${index + 1}`, "delete-button")}
                    onClick={() => handleOpen()}
                >
                    <img
                        style={{
                            height: "21px",
                            width: "21px",
                            color: "red",
                        }}
                        src={DeleteForeverOutlinedIcon}
                    />
                </Button>
            ) : (
                ""
            )}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "#eae7ed",
                        border: "1px solid #000",
                        borderRadius: "10px",
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                        style={{ display: "flex", justifyContent: "center", fontSize: "1.1rem", margin: "10px 0px", color: "#79797a" }}
                    >
                        Are you sure you want to delete this Asset ?
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            style={{ backgroundColor: "#1b75bc" }}
                            variant="contained"
                            name="yes"
                            onClick={() => handleDelete(index)}
                            data-testid={generateTestId(`Delete Asset`, "modal-yes-button")}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            name="no"
                            onClick={() => handleClose()}
                            style={{ backgroundColor: "#767676", marginLeft: "10px" }}
                        >
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default DeleteAsset;
