import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import Button from "@mui/material/Button";
import { defaultReferenceCheckFixture } from "fixtures";
import "styles/common.scss";

type Props = {
    append: any;
};

const AddAnotherReference = ({ append }: Props) => {
    return (
        <Button
            onClick={append}
            className="common-fullwidth-button"
            disabled={localStorage.getItem("disable_form") === "true"}
            style={{ marginBottom: "1rem" }}
            data-testid="add-another-reference-button"
        >
            <AddCircleOutlineRoundedIcon fontSize="small" className="AddEquipmentIcon" />
            <span>Add Another Reference</span>
        </Button>
    );
};

export default AddAnotherReference;
