import { useEffect, useLayoutEffect } from "react";
import { useFieldArray, useFormContext, useWatch } from "react-hook-form";
import toast from "react-hot-toast";
import { NameAndShareCreateInput } from "@/__generated__/apiSchemas";
import { defaultCoborrowerFixture } from "fixtures";
import { cloneDeep } from "lodash";

type Args = {
    entity: string;
    entityDetails: any;
    isApplicant: boolean;
};

const usePartnerOrDirectorToCoborrowerOrGuarantorConversion = ({ entity, entityDetails, isApplicant }: Args) => {
    if (!isApplicant) return;

    const personal = useWatch({ name: "applicant.personal" });
    const document = useWatch({ name: "applicant.document" });

    const { setValue } = useFormContext();

    const coborrower = useWatch({ name: "coborrower" });
    const guarantor = useWatch({ name: "guarantor" });

    useEffect(() => {
        entityDetails.forEach(({ entityType: type, name }: any, index: number) => {
            let [entityType, entityIndex] = type?.split("-");
            entityIndex = Number(entityIndex ?? 1);
            // console.log(entityType, entityIndex);
            const coborrowerFixture = cloneDeep(defaultCoborrowerFixture);
            coborrowerFixture.entity.business = "Individual";
            coborrowerFixture.entity.entityName = name;

            coborrower.forEach((item: any, entityIndex: number) => {
                if (item.personal.personal.entityType?.includes(index)) {
                    setValue(`coborrower.${entityIndex}`, defaultCoborrowerFixture);
                }
            });
            guarantor.forEach((item: any, entityIndex: number) => {
                if (item.personal.personal.entityType?.includes(index)) {
                    setValue(`guarantor.${entityIndex}`, defaultCoborrowerFixture);
                }
            });

            if (!entityType) return;

            if (entityType !== "applicant") {
                if (entity === "director") {
                    // console.log(document.personal.personal[index]);
                    coborrowerFixture.entity.creditBureau = personal.directors[index].creditBureau;
                    coborrowerFixture.income.loanFromAccount = personal.directors[index].loanFromAccount;
                    coborrowerFixture.document.business.loanAccountCredit = document.personal.personal[index].loanAccountCredit;
                    coborrowerFixture.personal.personal = cloneDeep(personal.directors[index]);
                    coborrowerFixture.document.personal.personal = [cloneDeep(document.personal.personal[index])];
                    coborrowerFixture.personal.personal.entityType = `${entity}-${index}`;
                }

                if (entity === "partner") {
                    coborrowerFixture.entity.creditBureau = personal.partners[index].creditBureau;
                    coborrowerFixture.income.loanFromAccount = personal.partners[index].loanFromAccount;
                    coborrowerFixture.document.business.loanAccountCredit = document.personal.personal[index].loanAccountCredit;
                    coborrowerFixture.personal.personal = cloneDeep(personal.partners[index]);
                    coborrowerFixture.document.personal.personal = [cloneDeep(document.personal.personal[index])];
                    coborrowerFixture.personal.personal.entityType = `${entity}-${index}`;
                }

                //@ts-ignore
                delete coborrowerFixture.personal.personal.kyc;
                //@ts-ignore
                delete coborrowerFixture.personal.personal.creditBureau;
                //@ts-ignore
                delete coborrowerFixture.personal.personal.loanFromAccount;
                //@ts-ignore
                delete coborrowerFixture.personal.personal.loanAccountCredit;
                //@ts-ignore
                coborrowerFixture.document.personal.personal[0].loanAccountCredit = [];

                if (entityType === "coborrower") {
                    setValue(`${entityType}.${entityIndex - 1}`, coborrowerFixture);
                } else {
                    setValue(`${entityType}.${entityIndex - 1}`, coborrowerFixture);
                }
            }
        });
    }, [entityDetails]);
};

export default usePartnerOrDirectorToCoborrowerOrGuarantorConversion;
