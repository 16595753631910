import { BANK_NAMES, INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "businessName",
        label: "Business Name",
        labelGenerator: (role) => {
            switch (role) {
                case "fi":
                    return "Financial Institution";
                case "dealer":
                    return "Dealer Name";
                case "manufacturer":
                    return "Manufacturer Name";
                default:
                    return "Business Name";
            }
        },
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
        options: BANK_NAMES.map((bank) => bank.name),
    },
    {
        name: "full_name",
        label: "Contact Name",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "manufacturer",
        label: "Manufacturer",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "dealer",
        label: "Dealer",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "email",
        label: "Email",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "landline",
        label: "Landline",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "mobile",
        label: "Mobile",
        editable: true,
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
];

export default fixture;
