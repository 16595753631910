import { useRef, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useDownload } from "__generated__/apiComponents";
import axios from "axios";
import CommonFields from "components/CommonFields";
import { CommonLabel } from "components/CommonFields";
import { env } from "env";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import DeselectFileModal from "pages/Dashboard/components/Document/components/DeselectFileModal";
import { mapAssetFieldsValues } from "utils";
import generateTestId from "utils/generateTestId";
import getKeyFromS3Url from "utils/getKeyFromS3Url";

const UploadForm = ({ prefix, pane }: { prefix: string; pane?: string }) => {
    const asset = useWatch({ name: `${prefix}.0` });

    const { setValue } = useFormContext();
    const downloadRef = useRef<HTMLAnchorElement>(null);

    const { mutate: getDownloaUrl } = useDownload({
        onSuccess: (data: any) => {
            if (data && downloadRef.current) {
                downloadRef.current.href = data;
                downloadRef.current?.click();
            }
        },

        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.stack?.message);
        },
    });

    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });

    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };

    const getAssetDetails = async (data: any) => {
        const result = await axios.post(`${env.REACT_APP_API_URL}multi-asset-excel`, {
            form_id: localStorage.getItem("form_id"),
            file_link: data?.[0]?.link,
        });

        const assets = mapAssetFieldsValues(result?.data?.data, data?.[0]);

        setValue(prefix, assets);
    };
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <Grid className={"common__mui__textfield__container"} container direction="row" justifyContent="center" alignItems="center">
                <CommonLabel
                    showLabel={true}
                    label={"Download and Fill"}
                    data-testid={generateTestId(`download-and-fill-asset-1-${pane}`, `field-label`)}
                    infoText={""}
                    labelSubtitle={""}
                />
                <Grid item xs={6} container direction="row" justifyContent="flex-end" alignItems="center">
                    <a ref={downloadRef} download hidden></a>
                    <Button
                        data-testid={generateTestId(`download-asset-1-${pane}`, `button`)}
                        onClick={() => {
                            getDownloaUrl({
                                body: {
                                    key: getKeyFromS3Url(
                                        decodeURIComponent(
                                            "https://smecredit-object-storage-1.s3.ap-south-1.amazonaws.com/documents/Multi Asset Template.xls"
                                        )
                                    ),
                                },
                            });
                        }}
                        className="text-sm bg-orange-600 text-white capitalize w-fit hover:bg-orange-600 "
                    >
                        Download
                    </Button>
                </Grid>
            </Grid>
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Upload Filled Excel"
                value={asset?.filledExcel}
                data-testid={generateTestId(`upload-filled-excel-asset-1-${pane}`, `field`)}
                name={`${prefix}.0.filledExcel`}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "filledExcel",
                    isAsset: true,
                    fileAddress: `${prefix}.0.filledExcel`,
                    callback: getAssetDetails,
                    typeAccepted: ["application/vnd.ms-excel"],
                })}
                showCross={localStorage.getItem("disable_form") !== "true" && asset?.filledExcel && asset?.filledExcel?.link !== ""}
                handleFileModalOpen={() => setCurrentInput({ name: `${prefix}.0.filledExcel`, open: true, multiple: false, position: 0 })}
            />
            <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
        </Grid>
    );
};

export default UploadForm;
