import { Fragment, useState } from "react";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";

const DeleteBankAccount = ({ index, remove }: { index: number; remove: any }) => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleDelete = (index: number) => {
        handleClose();
        remove(index);
    };
    return (
        <Fragment>
            <Button
                onClick={() => handleOpen()}
                className=""
                style={{
                    position: "absolute",
                    left: "100%",
                    width: "1rem",
                }}
                data-testid={`deleteBankAccount.${index}`}
                color="error"
                disabled={localStorage.getItem("disable_form") === "true"}
            >
                <DeleteForeverOutlinedIcon />
            </Button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                data-testid={`deleteBankAccountModal.${index}`}
            >
                <Box
                    sx={{
                        position: "absolute" as "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: 400,
                        bgcolor: "#eae7ed",
                        border: "1px solid #000",
                        borderRadius: "10px",
                        boxShadow: 24,
                        p: 3,
                    }}
                >
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <ErrorOutlineIcon style={{ fontSize: "3.5rem", color: "#9adbf1" }} />
                    </Typography>
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h6"
                        style={{ width: "100%", textAlign: "center", fontSize: "1.1rem", margin: "10px 0px", color: "#79797a" }}
                    >
                        Are you sure you want to delete this Bank Account ?
                    </Typography>
                    <Typography id="modal-modal-title" variant="h6" component="h2" style={{ display: "flex", justifyContent: "center" }}>
                        <Button
                            variant="contained"
                            name={`deleteBankAccountModal.${index}.yes`}
                            onClick={() => handleDelete(index)}
                            data-testid={`deleteBankAccountModal.${index}.yes`}
                        >
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            name={`deleteBankAccountModal.${index}.no`}
                            onClick={() => handleClose()}
                            style={{ backgroundColor: "#767676", marginLeft: "10px" }}
                            data-testid={`deleteBankAccountModal.${index}.no`}
                        >
                            No
                        </Button>
                    </Typography>
                </Box>
            </Modal>
        </Fragment>
    );
};

export default DeleteBankAccount;
