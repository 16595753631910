import React, { useEffect } from "react";
import { useFieldArray, useWatch, useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import CommonFields from "components/CommonFields";
import { defaultDocumentPersonalDetailsFixture, defaultPersonalDetailsWithKYCFixture } from "fixtures";
import { INPUT_TYPE } from "globalConstants";
import { useFieldArrayWithAutoAddAndRemove } from "hooks";
import generateTestId from "utils/generateTestId";

const ManagementDetails = ({ prefix, pane }: { prefix: string; pane?: string }) => {
    const { fields, append, remove } = useFieldArray({
        name: `${prefix}entity.managementDetails.names`,
    });
    useFieldArrayWithAutoAddAndRemove({
        countName: `${prefix}entity.managementDetails.noOfPersons`,
        name: `${prefix}personal.persons`,
        fixture: defaultPersonalDetailsWithKYCFixture,
    });
    useFieldArrayWithAutoAddAndRemove({
        countName: `${prefix}entity.managementDetails.noOfPersons`,
        name: `${prefix}document.personal.personal`,
        fixture: defaultDocumentPersonalDetailsFixture,
    });

    const { getValues, setValue } = useFormContext();

    const managementDetails = useWatch({
        name: `${prefix}entity.managementDetails`,
    });

    const errors = useWatch({
        name: `errors.${prefix}entity.managementDetails`,
    });

    useEffect(() => {
        const activeFields: string[] = ["noOfPersons"];
        fields.map((item, index) => {
            activeFields.push(`names.${index}`);
        });
        let errorsCounts = 0;
        for (const item of activeFields) {
            if (
                getValues(`${prefix}entity.managementDetails.${item}`) === "" ||
                getValues(`${prefix}entity.managementDetails.${item}`) === undefined
            ) {
                errorsCounts++;
            }
        }
        setValue(`errors.${prefix}entity.managementDetails.errorsCounts`, errorsCounts);

        managementDetails.names.forEach((person: any, i: number) => {
            let [firstName, middleName, lastName] = person.split(" ").filter((name: string) => name);
            if (!lastName) {
                lastName = middleName;
                middleName = "";
            }
            if (!middleName && !lastName) {
                lastName = "";
                middleName = "";
            }
            setValue(`${prefix}personal.persons.${i}.yourDetails.firstName`, firstName);
            setValue(`${prefix}personal.persons.${i}.yourDetails.middleName`, middleName);
            setValue(`${prefix}personal.persons.${i}.yourDetails.lastName`, lastName);
        });
    }, [managementDetails]);

    return (
        <CommonAccordion
            showError={errors?.errorsCounts > 0}
            title={`Management Detail`}
            tab="entity"
            data-testid={generateTestId(`management-detail-${pane}`, "accordion")}
        >
            <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                <CommonFields
                    inputType={INPUT_TYPE.NUMBER}
                    name={`${prefix}entity.managementDetails.noOfPersons`}
                    prop={{
                        value: "No. of Persons Managing",
                    }}
                    data-testid={generateTestId(`persons-${pane}`, "field")}
                    reactHookFormRegisterOptions={{
                        onChange: (e: any) => {
                            // get fields.length and compare with e.target.value
                            // depending on that append or remove
                            const currentLength = fields.length;
                            const targetValue = +e.target.value || 0;

                            if (currentLength < targetValue) {
                                const runningArray = [];
                                for (let i = 0; i < targetValue - currentLength; i++) {
                                    runningArray.push(" ");
                                }
                                append(runningArray, {
                                    shouldFocus: false,
                                });
                            } else if (currentLength > targetValue) {
                                // get the indices of elements to be removed starting from last
                                const indicesToRemove = [];
                                for (let i = 0; i < currentLength - targetValue; i++) {
                                    indicesToRemove.push(currentLength - i - 1);
                                }
                                // remove the elements
                                remove(indicesToRemove);
                            }
                        },
                        max: {
                            value: 5,
                            message: "Maximum 5 Persons allowed",
                        },
                        min: {
                            value: 1,
                            message: "Minimum 1 Person required",
                        },
                    }}
                    readOnly={localStorage.getItem("disable_form") === "true" || false}
                    label="Persons"
                    className={"short-input-field"}
                />
                {fields.map((field, index) => (
                    <Grid key={index} style={{ marginTop: "0" }} container direction="row" justifyContent="flex-start" alignItems="center">
                        <Grid item xs={6} container>
                            <Typography className="common-label" data-testid={generateTestId(`names-${pane}`, "field-label")}>
                                {index === 0 ? "Names" : ""}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} container>
                            <CommonFields
                                inputType={INPUT_TYPE.TEXT}
                                name={`${prefix}entity.managementDetails.names.${index}`}
                                data-testid={generateTestId(`names-${index + 1}-${pane}`, "field")}
                                readOnly={localStorage.getItem("disable_form") === "true" || false}
                                showLabel={false}
                                label=""
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </CommonAccordion>
    );
};
export default ManagementDetails;
