const formatLoanAccountFIName = (fi: string) => {
    if (!fi) return "";
    const words = fi.split(" ").filter((word) => !["PRIVATE", "LIMITED", "INDIA", "LTD"].includes(word.toUpperCase()));
    let word = "";
    if (words[0].length >= 10) word = words[0];
    else word = `${words[0]} ${words[1]}`;

    return word.length > 20 ? word.slice(0, 20) + "..." : word;
};

export default formatLoanAccountFIName;
