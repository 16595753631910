import { Fragment } from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import EmailIcon from "assets/images/email.png";
import Navbar from "components/Navbar";
import { useIsSmallScreen } from "hooks";
import image from "../../assets/images/layer-8-copy.png";
import ContactForm from "./ContactForm";
import "./ContactUs.styles.scss";

const Contact = () => {
    const isSmallScreen = useIsSmallScreen();

    return (
        <Fragment>
            <Navbar />
            <div id="contact-us-container">
                <Grid xs={12} className="contact-us-section-1">
                    <Typography variant="h4" component="h4" className="contact-text">
                        Contact Us
                    </Typography>
                    <img src={image} alt="contact-img" className="contact-img" />
                </Grid>
                <Grid className="contact-us-section-2" container direction="row" justifyContent="space-between">
                    <Grid item xs={isSmallScreen ? 12 : 3} className="contact-us-section-2-item">
                        <Typography variant="h5" className="contact-us-section-2-h5" component="h5">
                            Singapore
                        </Typography>
                        <div className="country-data">
                            <Typography variant="h5" className="country-data-h5 !capitalize" component="h5">
                                25,North bridge road
                            </Typography>
                            <Typography variant="h5" className="country-data-h5 !capitalize" component="h5">
                                #08 - 01
                            </Typography>
                            <Typography variant="h5" className="country-data-h5 !capitalize" component="h5">
                                Singapore, 179104
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 3} className="contact-us-section-2-item">
                        <Typography variant="h5" className="contact-us-section-2-h5" component="h5">
                            India
                        </Typography>
                        <div className="country-data">
                            <Typography variant="h5" className="country-data-h5" component="h5">
                                Pocket A/4, Flat No. 163,
                            </Typography>
                            <Typography variant="h5" className="country-data-h5" component="h5">
                                Konark Apartments,
                            </Typography>
                            <Typography variant="h5" className="country-data-h5" component="h5">
                                Kalkaji Extension,
                            </Typography>
                            <Typography variant="h5" className="country-data-h5" component="h5">
                                New Delhi-110019
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={isSmallScreen ? 12 : 3} className="contact-us-section-2-item">
                        <Typography variant="h5" className="contact-us-section-2-h5" component="h5">
                            <img style={{ width: "12%" }} src={EmailIcon} alt="" />
                            &nbsp;Email
                        </Typography>
                        <div className="country-data">
                            <Typography variant="h5" className="country-data-h5" component="h5">
                                support@smecreditt.com
                            </Typography>
                        </div>
                    </Grid>
                </Grid>
                <ContactForm />
            </div>
        </Fragment>
    );
};

export default Contact;
