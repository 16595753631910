import { PersonalDetailsWithKycCreateInput } from "@/__generated__/apiSchemas";

const fixture: PersonalDetailsWithKycCreateInput = {
    yourDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: new Date().toLocaleDateString(),
        maritalStatus: "Single",
        noOfDependents: 0,
        // if maritalStatus married
        noOfChildren: 0,
    },
    fatherDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    motherDetails: {
        prefix: "Mrs.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    spouseDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    background: {
        educationQualification: "Junior High School (10th Class)",
        otherEducationQualification: "",
        profession: "Practicing Doctor",
        otherProfession: "",

        ownerOrEmployed: "Owner",
        caste: "General",
        otherCaste: "",
        subCaste: "",
        religion: "Buddhist",
        otherReligion: "",
        physicalDisability: "No",
    },
    residentialAddress: {
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        postalCode: "",
        city: "",
        state: "",
        lengthOfStayInYears: 0,
        lengthOfCityStayInYears: 0,
        ownership: "Owned by self",
        rentType: "Monthly Rent PM",
        // in case of rentType === "Monthly Rent PM"
        monthlyRent: 0,
        // in case of rentType === "Owner Name"
        ownerDetails: {
            prefix: "Mr.",
            otherPrefix: "",
            firstName: "",
            middleName: "",
            lastName: "",
        },
    },
    permanentAddress: {
        isSameAsResidentialAddress: true,
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        postalCode: "",
        city: "",
        state: "",
        lengthOfStayInYears: 0,
        lengthOfCityStayInYears: 0,
        ownership: "Owned by self",
        rentType: "Monthly Rent PM",
        // in case of rentType === "Monthly Rent PM"
        monthlyRent: 0,
        // in case of rentType === "Owner Name"
        ownerDetails: {
            prefix: "Mr.",
            otherPrefix: "",
            firstName: "",
            middleName: "",
            lastName: "",
        },
    },
    kyc: {
        aadhar: "",
        aadharValidated: false,
        pan: "",
        panValidated: false,
        din: ""
    },
    creditBureau: {
        bureauName: "Equifax",
        creditReportLink: "https://d2c.equifax.co.in/gcs/registration.jsp#/gcs/portalRouter/registration",
        report: {
            link: "",
            upload_date: "",
            filename: "",

            year_index: 0,
        },
        concentShared: false,
        cibilScore: 0,
        cibilReport: {
            link: "",
            upload_date: "",
            filename: "",

            year_index: 0,
        },
        scrapedData: {
            credit_score: 0,
            accounts: [],
        },
        excelURL: "",
    },
    loanFromAccount: [],
};

export default fixture;
