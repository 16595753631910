import { Fragment } from "react";
import { useState } from "react";
import { Grid } from "@mui/material";
import CommonAccordion from "components/CommonAccordion";
import "styles/document.scss";
import generateTestId from "utils/generateTestId";
import { PostDisbursalForm } from "../..";
import DeselectFileModal from "../DeselectFileModal";

const PostDisbursalTab = ({ prefix, pane }: { prefix: string; pane?: string }) => {
    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });
    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };

    return (
        <Fragment>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <CommonAccordion title={`Post Disbursal`} tab="document" data-testid={generateTestId(`post-disbursal-${pane}`, "accordion")}>
                    <PostDisbursalForm prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
                </CommonAccordion>
            </Grid>

            <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
        </Fragment>
    );
};

export default PostDisbursalTab;
