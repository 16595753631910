import { useState } from "react";
import { AuthProvider, createRefresh } from "react-auth-kit";
import { Toaster } from "react-hot-toast";
import { QueryClientProvider as OldQueryClientProvider } from "react-query";
import { ThemeProvider } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient, { oldQueryClient } from "api/queryClient";
import axios from "axios";
import GOOGLE_LOGIN_CLIENT_ID from "globalConstants/googleLoginClientId";
import MainRouter from "./MainRouter";
import UserSessionProvider, { deleteSession } from "./contexts/userSession/UserSessionProvider";
import { env } from "./env";
import THEME from "./globalConstants/theme";
import useFeature from "./hooks/useFeature";
import "./styles/imports.scss";
import { getSessionInterval } from "./utils";

const App = () => {
    const [open, setOpen] = useState(false);
    const shouldShowSessionTimeoutModal = useFeature("session-modal");

    // console.log("interval", getSessionInterval());

    const refreshApi = createRefresh({
        interval: getSessionInterval(),
        refreshApiCallback: async () => {
            if (!shouldShowSessionTimeoutModal) {
                window.alert("Session Expired. Please Login Again");
                return deleteSession({});
            }
            setOpen(true);
            const refreshToken = localStorage.getItem("refreshToken") || "";
            let prefix: string;
            if (env.REACT_APP_NODEJS_API_URL) {
                prefix = `${env.REACT_APP_NODEJS_API_URL}`;
            } else {
                prefix = `${env.REACT_APP_API_URL}`;
            }
            try {
                const { data } = await axios.post(
                    `${prefix}/users/token-refresh`,
                    { refreshToken },
                    {
                        headers: {
                            Authorization: `Bearer ${refreshToken}`,
                        },
                    }
                );
                localStorage.setItem("authToken", data?.accessToken);
                return {
                    isSuccess: true, // For successful network request isSuccess is true
                    newAuthToken: data?.accessToken,
                    newAuthTokenExpireIn: 20,
                };
            } catch (error) {
                console.error(error, `${window.location.hostname}/login`);
                console.log("Session Expired. Something went wrong");
                return deleteSession({});
            }
        },
    });

    return (
        <AuthProvider authType="localstorage" authName="authToken" refresh={refreshApi}>
            <UserSessionProvider open={open} handleClose={() => setOpen(false)}>
                <GoogleOAuthProvider clientId={GOOGLE_LOGIN_CLIENT_ID}>
                    <OldQueryClientProvider client={oldQueryClient}>
                        <QueryClientProvider client={queryClient}>
                            <StyledEngineProvider injectFirst>
                                <ThemeProvider theme={THEME}>
                                    <MainRouter />
                                </ThemeProvider>
                            </StyledEngineProvider>
                            <Toaster position="top-center" reverseOrder={false} />
                        </QueryClientProvider>
                    </OldQueryClientProvider>
                </GoogleOAuthProvider>
            </UserSessionProvider>
        </AuthProvider>
    );
};

export default App;
