import React, { ComponentClass, useState, MemoExoticComponent, useCallback } from "react";
import LongFormContext from "./context";


const getNewObject = (
    currentObj: {
        [key: string]: any;
    },
    updateObj: {
        [key: string]: any;
    }
) => {
    const newObj = { ...currentObj };
    const keys = Object.keys(updateObj);
    keys.forEach((key) => {
        if (typeof updateObj[key] === "object") {
            newObj[key] = getNewObject(currentObj[key], updateObj[key]);
        } else {
            newObj[key] = updateObj[key];
        }
    });
    return newObj;
};

const defaultValue = {
    business: "sole_proprietor",
    firmName: "",
    aadhar: "",
    pan: "",
    proprietorName: "",
    dateOfEstablishment: "",
    liabilityType: "limited",
    companyName: "",
    name: "",
    operator: false,
    supervisor: false,
    owner: false,
    other: false,
    residentialAddress: {
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    registeredAddress: {
        type: "same",
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    businessAddress: {
        type: "same",
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    mailingAddress: {
        type: "same",
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    registerAdd: "same_as_residential_address",
    businessAdd: "same_as_residential_address",
    mailAdd: "same_as_residential_address",
    isbusinessIncome: "yes",
    isemployeeIncome: "",
    isAgriIncome: "",
    isRentalIncome: "",
    isInvestmentIncome: "",
    businessIncome: {
        income: "",
        incomeTaxReturn: "",
        profitLoss: "",
        balanceSheet: "",
    },
    employeeIncome: {
        income: "",
        sector: "private",
        bussinessConstitution: "sole_proprietor",
        employeeName: "",
        designation: "",
        date: "",
        payIncomeTax: "",
    },
    agriIncome: {
        income: "",
        activity: "",
        asset: "yes",
    },
    rentalIncome: {
        income: "",
        detail: "",
        propType: "shop_office",
        asset: "",
    },
    investmentIncome: {
        income: "",
        asset: "",
    },
    asset: "",
    loan: "",
    assetVal: {
        type: "equipment",
        value: "",
        equipManufac: "",
        equipCategory: "backhoe",
        equipModel: "",
        yearOfManuf: "",
        regNum: "",
        financed: "",
        vehicleCatg: "",
        vehicleManuf: "",
        vehicleModel: "",
        detail: "",
        propType: "",
        builtArea: "",
        builtAreaUnit: "",
        description: "",
    },
    loanVal: {
        type: "",
        financedBy: "",
        OutstandingLoan: "",
        RepaymentFreq: "",
        originalLoanTender: "",
        remainTender: "",
        emiAmt: "",
        loanOverdue: "",
        amtOverdue: "",
        reasonOver: "",
    },
    isCoborrower: "",
    coborrower: {
        businessConstitution: "",
        firmName: "",
        date_of_estb: "",
        relationship: "",
        personName: "",
    },
    isConstitution: "",
    coborrowerBusinessExp: {
        industryExp: "",
    },
    coborrowerBusinessIncome: {
        income: "",
    },
    businessIncometype: "",
    isGuarantor: "",
    guarantor: {
        relationship: "",
    },
    Personal: {
        prefix: "",
        fname: "",
        mname: "",
        lname: "",
        dob: "",
        age: 0,
        marriage_sta: "",
        no_depend: "",
        f_prefix: "",
        f_fname: "",
        f_mname: "",
        f_lname: "",
        m_prefix: "",
        m_fname: "",
        m_mname: "",
        m_lname: "",
    },
    Background: {
        education: "",
        profession: "",
        type: "",
        caste: "",
        sub_caste: "",
        religion: "",
        disability: "",
    },
    guarantorResidentialAddress: {
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    personalAddress: {
        type: "same",
        address1: "",
        address2: "",
        address3: "",
        landMark: "",
        postalCode: "",
        city: "",
        state: "",
        stay: "",
        cityStay: "",
        ownership: "",
    },
    requirementForm: {
        workContract: "",
    },
    entityForm: {
        businessAddressProof: "",
    },
    incomeForm: {
        emiReceipt: "",
        loanAccStatement: "",
        bankStatement: "",
        lastFiledITReturn: "",
        GSTReturn: "",
        balanceSheet: "",
        profitLoss: "",
        cashFlow: "",
        salarySlip: "",
        form16A: "",
    },
    personalForm: {
        pan: "",
        addressProof: "",
    },
    postDisbursalForm: {
        purchaseInvoice: "",
        registrationNumber: "",
        RCVerification: "",
        insuranceCertificate: "",
        vehicleTaxCertificate: "",
    },
};

export default (Component: ComponentClass | (() => JSX.Element) | MemoExoticComponent<() => JSX.Element>) => (props: any) => {
    // TODO: Fix any type
    const [form, setForm] = useState<any>(defaultValue);
    const [otherElement, setOtherElement] = useState(false);
    const [formId, setFormId] = useState("");

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevState: any) => ({
            ...prevState,
            [e.target.name]: e.target.value,
        }));
    };
    const handleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
        setForm((prevState: any) => ({
            ...prevState,
            [e.target.name]: !form[e.target.name],
        }));
    };

    const handleEquipmentChange = useCallback(({ e, index }: { e: React.ChangeEvent<HTMLInputElement>; index: number }) => {
        setForm((prevState: any) => ({
            ...prevState,
            equipments: prevState.equipments.map((equipment: any, i: number) => {
                if (i === index) {
                    return {
                        ...equipment,
                        [e.target.name]: e.target.value,
                    };
                }
                return equipment;
            }),
        }));
    }, []);

    const handleNestedFieldChange = useCallback((updateObj: { [key: string]: any }) => {
        setForm((prevState: any) => {
            return getNewObject(prevState, updateObj);
        });
    }, []);

    return (
        <LongFormContext.Provider
            value={{
                form,
                setForm,
                handleEquipmentChange,
                handleChange,
                otherElement,
                setOtherElement,
                handleCheckBox,
                handleNestedFieldChange,
                setFormId,
                formId,
            }}
        >
            <Component {...props} />
        </LongFormContext.Provider>
    );
};