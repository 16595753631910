import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "designation",
        label: "Designation",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "department",
        label: "Department",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "segment",
        label: "Segment",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
    {
        name: "operatingArea",
        label: "Operating Area",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
        editable: true,
    },
];

export default fixture;
