import { useSaveLoanApplication, useUpdateProspectiveUser } from "__generated__/apiComponents";
import { useFormContext } from "react-hook-form";
import toast from "react-hot-toast";
import { useLocation, useParams } from "react-router-dom";
import { fixFormValues } from "utils/fixFormValues";
import useError from "./useError";

const useSaveProspectiveForm = (showToast = true) => {
    const showError = useError();
    const location = useLocation()
    const { id: userId } = useParams();
    const { getValues } = useFormContext();
    const isProspectiveForm = location.pathname.includes("prospective-form")
    const role = getValues("contact.role");

    const { mutate: saveForm } = useSaveLoanApplication({
        onSuccess: (data) => {
            toast.dismiss();
            toast.success("Form Saved Successfully");

        },
        onError: showError,
    });

    const { mutate: updateProspectiveUser } = useUpdateProspectiveUser({
        onError: showError,

        onSuccess: (data: any) => {
            if (role === "user") handleSaveForm()
            else {
                toast.dismiss();
                toast.success("Form Saved Successfully");
            }
        },
    });


    const handleUpdateProspectiveUser = () => {
        const values = getValues("contact");
        if (userId) {
            updateProspectiveUser({
                body: {
                    businessName: values?.businessName ?? "",
                    full_name: values?.full_name ?? "",
                    landline: values?.landline ?? "",
                    mobile: values?.mobile ?? "",
                    email: values?.email ?? "",
                    address: values?.address ?? {
                        ...values?.address,
                    },
                    roleDetails: values?.roleDetails,
                    socialMedia: values?.socialMedia,
                    id: userId,
                },
            });
        }
    };

    const handleSaveForm = () => {
        let values = getValues();
        const formId = localStorage.getItem("form_id");

        let requestObj = {
            id: formId || "",
            data: fixFormValues(values),
        };
        saveForm({ body: requestObj });
    }


    const handleSave = () => {
        if (isProspectiveForm) handleUpdateProspectiveUser()
        else handleSaveForm()

        toast.dismiss();
        toast.loading("Saving Form");

    };

    return handleSave;
};

export default useSaveProspectiveForm;
