import { useState, useCallback } from "react";
import { useSignOut } from "react-auth-kit";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useVerifyPan } from "__generated__/apiComponents";
import { verifyPan } from "api";
import { ROUTES } from "globalConstants";

const usePanVerify = () => {
    const [pan, setPan] = useState("");
    const formId = localStorage.getItem("form_id");
    const [panVerified, setPanVerified] = useState(false);
    const navigate = useNavigate();
    const signOut = useSignOut();
    const { mutate: panVerify } = useVerifyPan({
        onSuccess: () => {
            setPanVerified(true);
            toast.dismiss();
            toast.success("Pan Verification Successful");
        },
        onError: (error) => {
            toast.dismiss();
            toast.error(error?.payload??"");
            if (error?.payload === "Your access token has expired. Please login again") {
                signOut();
                navigate(ROUTES.LOGIN);
            }
        },
    });
    const handlePanValidate = useCallback(() => {
        toast.dismiss();
        if (formId && pan.length > 0) {
            toast.dismiss();
            toast.loading("Verifying PAN...");
            panVerify({
                body: {
                    pan: pan.toUpperCase(),
                    loanApplicationId: formId,
                },
            });
        }
    }, [pan, formId, panVerify]);
    return { setPan, panVerified, handlePanValidate };
};

export default usePanVerify;
