import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";

const fixture: ICommonFixture[] = [
    {
        name: "type",
        label: "Type of Loan",
        inputType: INPUT_TYPE.RADIO,
        options: ["term_loan", "overdraft"],
        editable: true,
        defaultValue: "term_loan",
    },
    {
        name: "financedBy",
        label: "Financed by",
        inputType: INPUT_TYPE.SELECT,
        options: [
            "AU_SMALL_FINANCE_BANK_LIMITED",
            "CAPITAL_SMALL_FINANCE_BANK_LIMITED",
            "FINCARE_SMALL_FINANCE_BANK_LIMITED",
            "EQUITAS_SMALL_FINANCE_BANK_LIMITED",
            "ESAF_SMALL_FINANCE_BANK_LIMITED",
            "JANA_SMALL_FINANCE_BANK_LIMITED",
            "NORTH_EAST_SMALL_FINANCE_BANK_LIMITED",
            "SURYODAY_SMALL_FINANCE_BANK_LIMITED",
            "UJJIVAN_SMALL_FINANCE_BANK_LIMITED",
            "UNITY_SMALL_FINANCE_BANK_LIMITED",
            "UTKARSH_SMALL_FINANCE_BANK_LIMITED",
            "AIRTEL_PAYMENT_BANK",
            "FINO_PAYMENT_BANK",
            "INDIA_POST_PAYMENT_BANK",
            "JIO_PAYMENT_BANK_",
            "NSDL_PAYMENT_BANK",
            "PAYTM_PAYMENT_BANK",
            "AXIS_BANK_LTD",
            "BANDHAN_BANK_LTD",
            "CATHOLIC_SYRIAN_BANK_LTD",
            "CITY_UNION_BANK",
            "DCB_BANK_LTD",
            "DHANALAKSHMI_BANK_LTD",
            "FEDERAL_BANK_LTD",
            "HDFC_BANK_LTD",
            "ICICI_BANK_LTD",
            "IDBI_LTD",
            "IDFC_Bank_Limited",
            "INDUSIND_BANK_LTD",
            "JAMMU_AND_KASHMIR_BANK",
            "KARNATAKA_BANK_LTD",
            "KARUR_VYSYA_BANK_LTD",
            "KOTAK_MAHINDRA_BANK_LTD",
            "RATNAKAR_BANK_LIMITED",
            "SOUTH_INDIAN_BANK",
            "TAMILNAD_MERCANTILE_BANK_LTD",
            "YES_BANK_LTD",
            "BANK_OF_BARODA",
            "BANK_OF_INDIA",
            "BANK_OF_MAHARASHTRA",
            "CANARA_BANK",
            "CENTRAL_BANK_OF_INDIA",
            "INDIAN_BANK",
            "INDIAN_OVERSEAS_BANK",
            "PUNJAB_AND_SIND_BANK",
            "PUNJAB_NATIONAL_BANK",
            "STATE_BANK_OF_INDIA",
            "UCO_BANK",
            "UNION_BANK_OF_INDIA",
            "AMERICAN_EXPRESS",
            "BANK_OF_AMERICA",
            "BARCLAYS_BANK_PLC",
            "CITI_BANK",
            "DBS_BANK",
            "DEUTSCHE_BANK_LTD",
            "HONGKONG_AND_SHANGHAI_BKG_CORPN",
            "SBM_BANK_INDIA",
            "STANDARD_CHARTERED_BANK_LTD",
            "Arman_Financial_Services_Limited",
            "Chinmay_Finlease_Limited",
            "Ishan_Finlease_Limited",
            "JAYLAKSHMI_CREDIT_COMPANY_LIMITED",
            "K_Z_Leasing_and_Finance_Limited",
            "North_Pole_Finance_Limited",
            "Rajath_Finance_Limited",
            "AMRIT_MALWA_CAPITAL_LIMITED",
            "BABA_PURAN_DASS_FINANCIAL_SERVICES_LIMITED",
            "DELHI_PUNJAB_FINANCE_CO_LIMITED",
            "PHF_LEASING_LIMITED",
            "PKF_FINANCE_LIMITED",
            "PUNJAB_KASHMIR_FINANCE_LIMITED",
            "EXCELLENT_FIN-INVEST_LTD",
            "G_T_P_FINANCE_LIMITED",
            "GALADA_FINANCE_LIMITED",
            "GOVE_FINANCE_LIMITED",
            "INTEGRATED_FINANCE_COMPANY_LIMITED",
            "NEWLINK_OVERSEAS_FINANCE_LIMITED",
            "SAKTHI_FINANCE_LIMITED",
            "SEYAD_SHARIAT_FINANCE_LIMITED",
            "SHRIRAM_CITY_UNION_FINANCE_LIMITED",
            "SHRIRAM_TRANSPORT_FINANCE_COMPANY_LIMITED",
            "SRI_VIJAYARAM_HIRE_PURCHASE_AND_LEASING_FINANCE_LIMITED",
            "SUNDARAM_FINANCE_LIMITED",
            "TAMILNADU_INDUSTRIAL_DEVELOPMENT_CORPORATION_LIMITED",
            "TAMILNADU_POWER_FINANCE_AND_INFRASTRUCTURE_DEVELOPMENT_CORPORATION_LIMITED",
            "TAMILNADU_TRANSPORT_DEVELOPMENT_FINANCE_CORPORATION_LIMITED",
            "TAMILNADU_URBAN_FINANCE_AND_INFRASTRUCTURE_DEVELOPMENT_CORPORATION_LIMITED",
            "NeelGagan_Finance_Limited",
            "S.P.R._Finance_Limited",
            "Sangrama_Motors_Finance_India_Limited",
            "Sudershan_Hire_Purchase_Limited",
            "Balaji_Instalments_Limited",
            "Dhara_Motor_Finance_Limited",
            "The_Peerless_General_Finance_&_Investment_Company_Ltd",
            "Bajaj_Finance_Ltd._[Formerly:_Bajaj_Auto_Finance_Ltd.]",
            "Berar_Finance_Limited",
            "Mahindra_&_Mahindra_Financial_Services_Ltd",
            "MOONSHINE_FINVEST_LIMITED",
            "Bansal_Credits_Limited",
            "Samrat_Motor_Finance_Ltd.",
            "The_Delhi_Safe_Deposit_Company_Ltd.",
            "Grihastha_Finance_Ltd",
            "Opel_Finance_Ltd",
            "Kerala_State_Power_And_Infrastructure_Finance_Corporation_Limited",
            "Muthoot_Vehicle_&_Asset_Finance_Ltd",
            "Muthoot_Capital_Services_Limited",
            "Sreeragh_General_Finance_Limited",
            "Adani_Capital_Private_Limited",
            "Aditya_Birla_Finance_Limited",
            "Bilakhia_Holdings_Private_Limited",
            "Gujarat_Industrial_Investment_Corporation_Limited",
            "Gujarat_State_Financial_Services_Limited_(GSFS)",
            "HDB_Financial_Services_Limited",
            "M3_Investment_Pvt._Ltd",
            "MAS_Financial_Services_Limited",
            "NAMRA_FINANCE_LIMITED",
            "Pahal_Financial_Services_Limited",
            "Canbank_Factors_Limited",
            "CAPFLOAT_FINANCIAL_SERVICES_PRIVATE_LIMITED.",
            "CATERPILLAR_FINANCIAL_SERVICES_INDIA_PRIVATE_LIMITED",
            "Chaitanya_India_Fin_Credit_Private_Limited",
            "Cisco_Systems_Capital_(India)_Pvt.Ltd.",
            "CreditAccess_Grameen_Limited",
            "D_Devaraj_Urs_Backward_Classes_Development_Corporation_Ltd.",
            "Dr.B.R.Ambedkar_Development_Corporation",
            "Hewlett-Packard_Financial_Services_(India)_Private_Limited",
            "HIGHER_EDUCATION_FINANCING_AGENCY_(_GOVT._Company)",
            "Jana_Holding_Limited_(Transferred_to_Mumbai_RO)",
            "JUPITER_CAPITAL_PRIVATE_LIMITED",
            "KISETSU_SAISON_FINANCE_(INDIA)_PRIVATE_LIMITED",
            "KRAZYBEE_SERVICES_PRIVATE_LIMITED",
            "NABFINS_LIMITED",
            "NAVI_FINSERV_PRIVATE_LIMITED",
            "PRAZIM_TRADING_AND_INVESTMENT_COMPANY_PRIVATE_LIMITED",
            "Samasta_Microfinance_Limited",
            "TARISH_INVESTMENT_AND_TRADING_COMPANY_PRIVATE_LIMITED",
            "The_Karnataka_Minorities_Development_Corporation_Ltd",
            "TOYOTA_FINANCIAL_SERVICES_INDIA_LIMITED",
            "VARTHANA_FINANCE_PRIVATE_LIMITED",
            "Visage_Holdings_and_Finance_Pvt_Ltd_(Shifted_from_New_Delhi_R.O)",
            "Vistaar_Financial_Services_P_Ltd_(Transferred_from_MUMBAI_RO)",
            "VOLVO_FINANCIAL_SERVICES_(INDIA)_PRIVATE_LIMITED",
            "ANNAPURNA_FINANCE_PRIVATE_LIMITED.",
            "OCEAN_CAPITAL_MARKET_LTD._(TRANSFERRED_FROM_KOLKATA_RO)",
            "KARUN_CARPETS_PRIVATE_LIMITED",
            "MIDLAND_MICROFIN_LIMITED",
            "NAHAR_CAPITAL_AND_FINANCIAL_SERVICES_LIMITED",
            "VARDHMAN_HOLDINGS_LIMITED",
            "ACSYS_INVESTMENTS_PRIVATE_LIMITED",
            "APTUS_FINANCE_INDIA_PRIVATE_LIMITED",
            "ARISE_INVESTMENTS_AND_CAPITAL_LIMITED",
            "ASIRVAD_MICRO_FINANCE_LIMITED",
            "BELSTAR_MICROFINANCE_LIMITED",
            "CHOLAMANDALAM_INVESTMENT_AND_FINANCE_COMPANY_LIMITED",
            "DAIMLER_FINANCIAL_SERVICES_INDIA_PRIVATE_LIMITED",
            "DVARA_KSHETRIYA_GRAMIN_FINANCIAL_SERVICES_PRIVATE_LIMITED",
            "FIVE_STAR_BUSINESS_FINANCE_LIMITED",
            "FORD_CREDIT_INDIA_PRIVATE_LIMITED",
            "FULLERTON_INDIA_CREDIT_COMPANY_LIMITED",
            "HINDUJA_LEYLAND_FINANCE_LIMITED",
            "IDFC_FINANCIAL_HOLDING_COMPANY_LIMITED",
            "IDFC_LIMITED",
            "KKR_INDIA_ASSET_FINANCE_LIMITED",
            "MADURA_MICRO_FINANCE_LIMITED",
            "NABKISAN_FINANCE_LIMITED",
            "NISSAN_RENAULT_FINANCIAL_SERVICES_INDIA_PRIVATE_LIMITED",
            "Northern_Arc_Capital_Limited",
            "RAMA_INVESTMENT_COMPANY_PRIVATE_LIMITED",
            "REPCO_MICRO_FINANCE_LIMITED",
            "S.M.I.L.E_MICRO_FINANCE_LIMITED",
            "SAMUNNATI_FINANCIAL_INTERMEDIATION_&_SERVICES_PRIVATE_LIMITED",
            "SITA_INVESTMENT_COMPANY_LIMITED",
            "TVS_CREDIT_SERVICES_LTD",
            "VERITAS_FINANCE_PRIVATE_LIMITED",
            "VIVRITI_CAPITAL_PRIVATE_LIMITED",
            "NORTH_EASTERN_DEVELOPMENT_FINANCE_CORPORATION_LTD.",
            "Andhra_Pradesh_Power_Finance_Corporation_Ltd",
            "Caspian_Impact_Investments_Private_Limited",
            "Ikf_Finance_Ltd._(converted_from_category_A_to_category_B)",
            "Indian_School_Finance_Company_Pvt._Ltd._(FORMERLY_CORPORATE_DEPOSITS_&_INVESTMENTS_LTD)",
            "Kanakadurga_Finance_Limited_(formerly-Kanaka_Durga_Leasing_&_Finance_Ltd.)",
            "KLM_Axiva_Finvest_Limited_(Formerly_Needs_Finvest_Limited)",
            "Maanaveeya_Development_&_Finance_Private_Limited_(formerly_Maanaveeya_Holdings_&_Investments_Private_Limited)",
            "NABSAMRUDDHI_FINANCE_LIMITED(Formerly_known_as_Agri_Business_Finance_Limited_(Formerly_Agri_Business_Finance(Ap)_Ltd.))",
            "Ncc_Infrastructure_Holdings_Limited",
            "Share_Microfin_Ltd",
            "Spandana_Sphoorty_Financial_Ltd.",
            "VAYA_Finserv_Private_Limied",
            "ESS_KAY_FINCORP_LTD",
            "FINOVA_CAPITAL_PRIVATE_LIMITED",
            "Jumbo_Finvest_(India)_Ltd.",
            "Kogta_Financial_(India)_Ltd.",
            "LAXMI_INDIA_FINLEASECAP_PRIVATE_LIMITED",
            "Sonata_Finance_Private_Limited",
            "ADVENTZ_FINANCE_PRIVATE_LIMITED",
            "Arohan_Financial_Services_Limited",
            "ASA_INTERNATIONAL_INDIA_MICROFINANCE_LIMITED",
            "BANDHAN_FINANCIAL_HOLDINGS_LIMITED",
            "Brabourne_Commerce_(P)_Ltd",
            "CENTRAL_INDIA_INDUSTRIES_LIMITED",
            "GWALIOR_FINANCE_CORPORATION_LIMITED",
            "KIRAN_VYAPAR_LTD(Formerly_Kiran_Vyapar_Private_Limited)",
            "L&T_Finance_Limited",
            "Meenakshi_Mercantiles_Ltd",
            "Pilani_Investment_&_Industries_Corporation_Ltd.",
            "Placid_Ltd.",
            "Poonawalla_Fincorp_Limited(Formerly_Magma_Fincorp_Limited)",
            "RAINBOW_INVESTMENTS_LIMITED",
            "Russell_Credit_Ltd",
            "Shekhavati_Investments_And_Traders_Limited",
            "Shree_Capital_Services_Ltd.",
            "Spotlight_Vanijya_Ltd.",
            "Srei_Equipment_Finance_Limited(Formerly_Srei_Equipment_Finance_Private_Limited)",
            "Srei_Infastructure_Finance_Limited",
            "Umang_Commercial_Co_Pvt_Ltd",
            "Umt_Investments_Ltd",
            "Village_Financial_Services_Limited",
            "West_Bengal_Infrastructure_Development_Finance_Corpn",
            "Williamson_Magor_&_Co_Ltd",
            "A_K_CAPITAL_FINANCE_LIMITED_(Previously_A_K_Capital_Finance_Private_Limited_)_(Trfd_from_Kolkata)",
            "ABANS_FINANCE_PRIVATE_LIMITED_(FORMERLY_ABANS_FINANCE_LIMITED)_(FORMERLY_SOFED_COMMTRADE_PVT._LTD.)",
            "Agriwise_Finserv_Limited",
            "ALCO_COMPANY_PRIVATE_LIMITED",
            "Ambit_Finvest_Pvt._Ltd._[Formerly:_Ambit_Retail_Finance_Pvt._Ltd.]",
            "Anand_Rathi_Global_Finance_Ltd._[Formerly:_Rathi_Global_Finance_Ltd.]",
            "Aneri_Fincap_Limited_(Previously_Farry_Industries_Ltd)",
            "ARKA_Fincap_Limited_(Formerly_known_as_KIRLOSKAR_CAPITAL_LIMITED)",
            "ASEEM_INFRASTRUCTURE_FINANCE_LIMITED",
            "Ashv_Finance_Limited_(Formerly_Jain_Sons_Finlease_Limited)",
            "AUTHUM_INVESTMENT_&_INFRASTRUCTURE_LIMITED_(shifted_from_Kolkata)",
            "AUXILO_FINSERVE_PRIVATE_LIMITED_(w.e.f._September_11",
            "Avanse_Financial_Services_Ltd",
            "AVENDUS_FINANCE_PRIVATE_LIMITED_(formerly_Pacific_Hire_Purchase_Limited)-_Shifted_from_Bangalore",
            "Axis_Finance_Ltd._{Old_Name-_ENAM_FINANCE_PVT_LTD_(Transferred_from_Chennai)}",
            "Bachhraj_&_Co._Pvt._Limited_[Formerly_Bachhraj_&_Co._Limited]",
            "BAJAJ_HOLDINGS_AND_INVESTMENT_LIMITED",
            "Barclays_Investments_&_Loans_(India)_Private_Limited_(Previously_Barclays_Investments_&_Loans_(India)_Limited.",
            "Birla_Group_Holdings_Pvt_Ltd",
            "Birla_Tmt_Holdings_Private_Limited",
            "Blacksoil_Capital_Private_Limited_(Formerly_Sarvodaya_Capital_Pvt._Ltd.)",
            "Bob_Financial_Solutions_Limited_(Previously_known_as_Bobcards_Limited)",
            "Capri_Global_Capital_Limited_(formerly_Money_Matters_Financial_Services_Ltd.)",
            "Centrum_Financial_Services_Ltd.",
            "Centrum_Microcredit_Limited_(Old_Name:_Centrum_Microcredit_Private_Limited)",
            "Chhattisgarh_Investments_Limited",
            "Citicorp_Finance_(India)_Limited_(Citi_Financial_Consumer_Finance_India_Ltd)",
            "COX_&_KINGS_FINANCIAL_SERVICE_LIMITED",
            "CREDIT_SUISSE_FINANCE_(INDIA)_PRIVATE_LIMITED",
            "Cyrus_Investments_Private_Limited",
            "De_Lage_Landen_Financial_Services_India_Pvt_Ltd",
            "Deutsche_India_Holdings_Pvt._Ltd.",
            "Deutsche_Investments_India_Pvt._Ltd.",
            "Dsp_Adiko_Holdings_Pvt._Ltd._(_Formerly_-_Adiko_Investment_Private_Limited)",
            "Dsp_Hmk_Holdings_Pvt._Ltd._(_Formerly_-_Hmk_Investment_P_Ltd._)",
            "Dsp_Investment_Pvt._Ltd._(_Formerly_-_Biko_Investment_Pvt_Ltd.)",
            "ECL_Finance_Limited",
            "EDC_LIMITED",
            "EDELWEISS_FINANCE_&_INVESTMENTS_LIMITED",
            "EDELWEISS_FINVEST_LIMITED_(formerly_Edelweiss_Finvest_Private_Limited)",
            "EDELWEISS_RETAIL_FINANCE_LIMITED(Formerly_Affluent_Dealcom_(P)_Ltd)",
            "Electronica_Finance_Limited_(Formerly_Electronica_Leasing_&_Finance_Limited)",
            "Fino Finance Pvt Limited (Previously Intrepid Finance & Leasing Pvt. Ltd",
            "Finquest Financial Solutions Pvt. Ltd.",
            "Fortune Credit Capital Ltd.",
            "FORTUNE INTEGRATED ASSETS FINANCE LIMITED",
            "Gagandeep Credit Capital Private Limited",
            "Goldman Sachs (India) Capital Markets Pvt Ltd",
            "Goldman Sachs (India) Finance Pvt Ltd (Formerly: Pratham Investments & Trading Pvt. Ltd.)",
            "Hathway Investments Private Limited",
            "HDFC Credila Financial Services Limited",
            "HDFC Investments Limited",
            "HSBC InvestDirect Financial Services (India) Ltd. (old name Investsmart Financial Services Ltd.)",
            "ICICI Securities Primary Dealership Ltd.",
            "IGH Holdings Pvt. Ltd.",
            "IIFL Finance Limited (Formerly known as IIFL Holdings Limited)",
            "IIFL WEALTH PRIME LIMITED (formerly Chephis Capital Markets Ltd)",
            "IL & FS Financial Services Ltd. ( Formerly Il & Fs Finvest  Ltd.)",
            "INCRED FINANCIAL SERVICES LIMITED",
            "India Factoring & Finance Solutions Pvt Ltd",
            "India Infoline Finance Limited (Old Name- India Infoline Investment Services Ltd.)",
            "INDIA INFRADEBT LIMITED",
            "INDOSTAR CAPITAL FINANCE LTD",
            "Industrial Investment Trust Ltd.",
            "Infina Finance Pvt. Ltd.",
            "InnoVen Capital India Private Limited",
            "Instant Holdings Ltd. [Formerly: Kec Holdings Ltd.]",
            "J M Financial & Investment Consultancy Services Pvt. Ltd.",
            "J. P. Morgan Securities India Pvt. Ltd.",
            "Jaykay Finholding (India) Pvt. Ltd.",
            "JM FINANCIAL CAPITAL LIMITED",
            "JM Financial Credit Solutions Limited (Earlier FICS Consultancy Services ltd)",
            "JM Financial Products Ltd. [Formerly: JM Financial Products Pvt Ltd]",
            "JOHN DEERE FINANCIAL INDIA PRIVATE LIMITED",
            "Julius Baer Capital (India) Private Limited",
            "Kanishtha Finance & Investment Pvt. Ltd",
            "Karvy Financial Services Ltd",
            "KKR INDIA FINANCIAL SERVICES LIMITED",
            "KOTAK INFRASTRUCTURE DEBT FUND LIMITED",
            "Kotak Mahindra Investments Ltd.",
            "Kotak Mahindra Prime Ltd.",
            "L & T INFRA DEBT FUND LIMITED",
            "L & T Infrastructure Finance Company Limited",
            "LENDINGKART FINANCE LIMITED (formerly known as AADRI INFIN LIMITED)",
            "Mahimna Mecantile Credits Ltd",
            "Mentor Capital Ltd. [Formerly; Pacific Corporate Services Ltd.]",
            "MICRO UNITS DEVELOPMENT & REFINANCE AGENCY LIMITED",
            "Morgan Stanley India Primary Dealer Pvt. Ltd.",
            "MOTILAL OSWAL FINVEST LIMITED",
            "Muthoot Microfin Ltd (formerly known as Panchratna Securities Limited)_MFI",
            "Naman Finance & Investment Pvt. Ltd.",
            "NEOGROWTH CREDIT PRIVATE LIMITED",
            "NIIF Infrastructure Finance Limited ( IDFC INFRASTRUCTURE FINANCE LIMITED)",
            "Nomura Capital (India) Pvt. Ltd.",
            "Nomura Fixed Income Securities Pvt. Ltd.",
            "ORIX Leasing & Financial Services India Limited (Formerely OAIS AUTO FINANCIAL SERVICES LIMITED",
            "PayU Finance India Private Limited (Formerly known as Sidvik Leasing Pvt. Ltd.)",
            "Phl Fininvest Private Limited (Npil Fininvest Private Limited)",
            "POONAWALLA FINANCE PRIVATE LIMITED (formerly Adar Poonawalla Finvest Private Limited)",
            "PROFECTUS CAPITAL PVT. LTD.",
            "Reliance Commercial Finance Limited(Formerly known as Reliance Gilts Ltd.)",
            "Reliance Financial Limited",
            "Reliance Retail Finance Ltd. (Formerly Reliance Power Ventures Ltd.)",
            "Reliance Strategic Investments Limited",
            "Reliance Ventures Limited (formerly known as Reliance Fertilisers Private Limited)",
            "SBFC Finance Private Limited",
            "SBI DFHI Ltd. (Formerely  DICOUNT & FINANCE HOUSE OF INDIA)",
            "SBI Global Factors Ltd. [Formerly: Global Trade Finance Limited]",
            "Shamyak Investment Private Limited (previously Amrit Petroleums Private Limited)",
            "Shanghvi Finance Private Limited",
            "Shapoorji Pallonji Finance Private Limited",
            "Sharekhan BNP Paribas  Financial  Services Limited",
            "Sicom Investments & Finance Ltd.",
            "Sicom Limited",
            "SIEMENS FINANCIAL SERVICES PRIVATE LIMITED",
            "Standard Chartered Capital Limited (Formerly Standard Chartered Investments & Loans (India) Ltd.)",
            "STCI Finance Ltd. ( Old Name - Securities Trading Corporation Of India Ltd.)",
            "STCI Primary Dealer Limited",
            "Sterling Investment Corporation Private Limited",
            "Summit Securities Ltd. [Formerly: Rpg Itochu Finance Ltd.]",
            "SVATANTRA MICROFIN PRIVATE LIMITED",
            "TATA CAPITAL FINANCIAL SERVICES LIMITED",
            "TATA CLEANTECH CAPITAL LIMITED",
            "Tata Investment Corporation Ltd",
            "Tata Motors Finance Limited (Fromerly Sheba Properties Limited)",
            "Tata Motors Finance Solutions Limited",
            "Telecom Investment India Pvt. Ltd.",
            "Thakur Fininvest Pvt. Ltd",
            "Thar Commercial Finance Private Limited ( Formerly known as India Advantage Finance Pvt. Ltd.)",
            "The Swastik Safe Deposit & Investments Ltd",
            "Ugro Capital  Limited (Previously Chokhani Securities Limited)",
            "United Petro Finance Limited",
            "VOLKSWAGEN FINANCE PRIVATE LIMITED",
            "WEST END INVESTMENT & FINANCE CONSULTANCY PVT. LTD.",
            "WHEELSEMI PRIVATE LIMITED (FORMERLY Varadnarayan Savings & Investment Co.Pvt.Ltd.)",
            "Winro Commercial (India) Ltd.",
            "XANDER FINANCE PRIVATE LIMITED",
            "Avail Financial Services Ltd.",
            "Aye Finance Private Limited",
            "BFL INVESTMENTS & FINANCIAL CONSULTANTS PVT. LTD.",
            "BMW India Financial Services Private Limited",
            "Bussan Auto Finance India Private Ltd.",
            "CAPITAL INDIA FINANCE LIMITED",
            "CAPITAL TRUST LIMITED",
            "Cellcap Invofin India Pvt Ltd",
            "Chowdry Associates",
            "Clix Capital Services Private Limited",
            "Clix Finance India Private Limited",
            "CNH INDUSTRIAL CAPITAL (INDIA) PRIVATE LIMITED",
            "Dhani Loans and Services Limited",
            "Dmi Finance Pvt Ltd.",
            "FUSION MICROFINANCE LIMITED",
            "Globe Fincap Ltd.",
            "Gyan Enterprises Pvt. Ltd.",
            "HCL Capital Private Limited",
            "HCL CORPORATION PRIVATE LIMITED",
            "Hero Fincorp Limited",
            "HOME CREDIT INDIA FINANCE PRIVATE LIMITED",
            "IFCI  Limited",
            "IFCI Factors Ltd.",
            "IFFCO Kisan Finance Limited",
            "INDIA INFRASTRUCTURE FINANCE COMPANY LIMITED",
            "Indiabulls Commercial Credit Ltd.",
            "Indian Railway Finance Corporation Ltd",
            "Indian Renewable Energy Development Agency Limited",
            "LIGHT MICRO FINANCE PRIVATE LIMITED",
            "Mehrotra Invofin India Pvt Ltd",
            "Milky Investments And Trading Company",
            "MONEYWISE FINANCIAL SERVICES PRIVATE LIMITED",
            "OXYZO FINANCIAL SERVICES PRIVATE LIMITED",
            "P C Media Systems Ltd",
            "P.C. Financial Services Pvt. Ltd.",
            "Paisalo Digital Limited",
            "Pnb Gilts Ltd.",
            "Power Finance Corporation Ltd.",
            "Ptc India Financial Services Ltd.",
            "Puran Associates Pvt.Ltd",
            "Rajasthan Global Securities Private Limited",
            "Ratna Commercial Enterprises Private Limited",
            "RATTANINDIA FINANCE PRIVATE LIMITED",
            "REC LIMITED",
            "Religare Finvest Ltd.",
            "RKG Finvest Ltd.",
            "S V Creditline Limited",
            "Satin Creditcare Network Limited",
            "SATYA MICROCAPITAL LIMITED",
            "SBI Cards & Payment Services Ltd.",
            "Sital Leasing & Finance Ltd.",
            "Teletec Finsec India Pvt Ltd",
            "The National Small Industries Corporation Ltd.",
            "Tourism Finance Corporation of India Ltd.",
            "Utsav Securities Pvt. Ltd",
            "Vic Enterprises P. Ltd.",
            "WINDY INVESTMENTS PRIVATE LIMITED",
            "FEDBANK FINANCIAL SERVICES LIMITED",
            "Kerala State Backward Classes Development Corporation Ltd",
            "Kerala State Industrial Development Corporation Limited",
            "Kerala Transport Development Finance Corporation Limited",
            "Kosamattam Finance Limited",
            "MANAPPURAM FINANCE LIMITED",
            "Maxvalue Credits & Investments Limited",
            "Muthoot Finance  Limited",
            "Muthoot Fincorp Limited",
            "Muthoottu Mini Financiers Limited",
            "A N N CAPITAL FINANCE PRIVATE LIMITED",
            "AAGAM HOLDINGS PRIVATE LIMITED",
            "Abhinandan Tradex Limited",
            "ABHISHEK FINLEASE LIMITED",
            "ACHI FINANCE AND MANAGEMENT CONSULTANCY PRIVATE LIMITED",
            "Adarsh Capital Finstock Ltd",
            "ADINATH EXIM RESOURCES LTD",
            "ADJAVIS CAPITAL PRIVATE LIMITED",
            "ADVAITA FINANCE SOLUTION PRIVATE LIMITED",
            "ADVANCE FINSTOCK PRIVATE LTD.",
            "AGARWAL FISCAL PRIVATE LIMITED",
            "AIM FINCON PRIVATE LIMITED",
            "AIRAN FINANCIAL SERVICES PRIVATE LIMITED",
            "ALFA FISCAL SERVICES PRIVATE LTD",
            "ALTITUDE FINVEST LIMITED",
            "AMARNATH SECURITIES LIMITED",
            "Amarpadma Credits Pvt.Ltd",
            "AMIGO FINSTOCK PRIVATE LTD.",
            "ANAND PROPERTY FINANCE LIMITED",
            "ANANYA FINANCE FOR INCLUSIVE GROWTH PVT. LTD.",
            "ANKIT FINANCIAL SERVICES PRIVATE LIMITED",
            "ANMOL TIE UP PRIVATE LIMITED",
            "APA FINANCE LIMITED",
            "Apollo Industries & Project Ltd.",
            "ARIHANT CLASSIC FINANCE LTD",
            "ARISTO TRADERS PRIVATE LIMITED",
            "ARMOUR CAPITAL PRIVATE LIMITED",
            "ARYAFINROOT FINANCIALS PRIVATE LIMITED",
            "ASCOM LEASING & INVESTMENTS LIMITED (Transferred from Hyderabad RO)",
            "Ashok Family Holdings Pvt. Ltd.",
            "ASHUTOSH AUTOFIN PVT LTD",
            "ASHUTOSH FINSEC LIMITED",
            "Atlanta Infrastructure and Finance Ltd",
            "ATREYI VINCOM PRIVATE LIMITED",
            "ATUL FIN RESOURCES LIMITED",
            "Aurfin Leasing Limited",
            "AVIRAT FINANCE PRIVATE LIMITED",
            "AVISA FINANCE LIMITED",
            "AXON FINANCE AND SECURITIES LTD (previously known as Unity Securities & Finance Ltd)",
            "BANSIDHAR LEASE AND FINANCE PRIVATE LTD.",
            "Baroda Brokers Pvt. Ltd.",
            "BHANDERI FINCAP LIMITED",
            "BHARAT FINLEASE LIMITED",
            "BIR FINANCE PRIVATE LIMITED",
            "Brahma Leasing & Finance Pvt. Ltd.",
            "Brahmputra Capital & Financial Services Limited",
            "BRIJLAXMI LEASING AND FINANCE LTD.",
            "Bulti Leasing & Finance Ltd (Restored vide AA order dated December 2",
            "CAPTREE FINANCE PVT LTD",
            "Ceejay Finance Ltd",
            "Ceejay Microfin Limited",
            "CENTURY VEHICLELOANS PRIVATE LIMITED",
            "Champawat Investments & Consultants Pvt. Ltd.",
            "CHAMPION AGRO FINANCE LIMITED",
            "CHOUDHRY FINANCE AND INVESTMENT PVT LTD",
            "CHRYSALIS FINANCE LTD.",
            "CLIMAX WATERPROOFING PRODUCTS PRIVATE LIMITED",
            "Countwell Management Services (P) Ltd.",
            "D. S. Integrated Finsec Pvt. Ltd.",
            "DAVE SECURITIES PRIVATE LTD.",
            "DEALING BENEFICIAL FINANCIAL SERVICES PVT. LTD.",
            "DEVIKA FINCAP PRIVATE LIMITED (Restored vide AA order dated January 20",
            "DH  QUICKFIN  PRIVATE  LIMITED",
            "DIMPLE FINANCE AND INVESTMENT PRIVATE LIMITED (transferred from Chandigarh RO)",
            "DIVINE LIGHT FINANCE LIMITED",
            "Divyalaxmi Finlease Pvt. Ltd.",
            "DIVYANIRMAN GOLD FINANCE PRIVATE LIMITED",
            "DOWNTOWN FINANCE PRIVATE LTD",
            "DP SONS FINVEST PRIVATE LIMITED",
            "EDIFICE FINCAP PRIVATE LIMITED",
            "Enn Enn Capital Private Limited",
            "FINCRED INVESTMENTS LIMITED",
            "FINSTARS CAPITAL LIMTED",
            "FLIPSTAR FINANCE LIMITED",
            "G K FINCAP LIMITED",
            "Gandhinagar Leasing & Finance Ltd.",
            "GANESH FINSTOCK LIMITED",
            "GEETGANGA INVESTMENT PRIVATE LIMITED",
            "GILTEDGED INDUSTRIAL SECURITIES LIMITED",
            "Goyal Associates Ltd.",
            "GRAMADHAR FINSERVE PRIVATE LIMITED",
            "GSD TRADING AND FINANCIAL SERVICES PVT. LTD",
            "GUARDIAN FINANCE PRIVATE LIMITED",
            "GUJARAT FINVEST SERVICES PRIVATE LIMITED",
            "Gujarat Investa Ltd.",
            "HARDIK FINANCE LIMITED",
            "HASU INVESTMENTS PRIVATE LIMITED",
            "HET FINANCE PRIVATE LIMITED",
            "HIM FINCAP PRIVATE LIMITED",
            "HIMACHAL MOTOR AND GENERAL FINANCE LIMITED",
            "HIREN HOLDINGS PRIVATE LIMITED",
            "IBL FINANCE PRIVATE LIMITED",
            "Indo Credit Capital Limited",
            "Isha Securities  Ltd.",
            "JAIN FINSCAP PRIVATE LIMITED",
            "Jaspat Investments & Consultancy P. Ltd.",
            "JYOT INTERNATIONAL MARKETING LIMITED",
            "K.L.B. Securities Private Ltd.",
            "KALINGA HIRE PURCHASE FINANCE LIMITED",
            "Kapashi Commercial Ltd.",
            "Karnavati Capital Market Ltd.",
            "Kashiba Finance Pvt. Ltd.",
            "KESHAV FINCAP PRIVATE LIMITED",
            "KHAVDA FINANCE LTD",
            "Khetan Trexcon Pvt Ltd. (Transferred to Ahmedabad RO vide letter dated 11/12/2020)",
            "KHETAWAT FINANCIAL RESOURCES PRIVATE LIMITED",
            "KHUSHBU AUTO FINANCE LIMITED",
            "KIFS Financial Services Limited",
            "KIVA CAPITAL PRIVATE LIMITED",
            "Krishna  Capital  &Securities  Ltd",
            "KRONE FINSTOCK PRIVATE LIMITED",
            "KRUNAL CONSTRUCTION & FINANCE LTD.",
            "KUBER UDYOG LTD",
            "KUNJAL INVESTMENTS PRIVATE LIMITED",
            "Kunvarji Fincorp Pvt. Ltd",
            "LALWANI LEASE FINANCE LIMITED",
            "Landmark Globfin Limited",
            "LIANER DISTRIBUTORS PRIVATE LIMITED",
            "Long View Financial Services Pvt. Ltd",
            "LUCKY-DHAN VYAPAAR PRIVATE LIMITED",
            "LUFT CAPITALS LIMITED",
            "M.G.F. GROWTH RESEARCH AND INVESTMART LIMITED",
            "M/s MANDHANA LEAFIN LIMITED",
            "Madhuram  Traders  Private Limited",
            "MAHAN INDUSTRIES LIMITED",
            "MALIBU KAPITAL LIMITED",
            "MANGALAM FINSERV PRIVATE LIMITED",
            "Mani Market Creaters Ltd",
            "Mansarover Tradex Limited",
            "Marco Private Limited (Formerly As Ygb Holdings Pvt. Ltd. )",
            "Marg Techno Projects Ltd",
            "Marwadi Finlease Pvt. Ltd.",
            "MAULESH INVESTMENT PRIVATE LTD.",
            "Maxim Finance Pvt. Ltd.",
            "MERIDIAN FINANCE PRIVATE LIMITED",
            "Meridian Securities Private Ltd.",
            "Merit Credit Corporation Limited",
            "Minix Holdings  Pvt.  Ltd",
            "Mixon Holdings Pvt. Ltd.",
            "MOYAL INVESTMENTS PRIVATE LIMITED",
            "MRUNAL AGENCY & FINANCIALS PRIVATE LIMITED",
            "Nalin Lease Finance Ltd",
            "Nalwa Trading Limited",
            "NAR NARAYAN FINCAP PRIVATE LIMITED",
            "Narmada Fintrade Pvt. Limited",
            "NAVDEEP INVESTMENT PRIVATE LIMITED",
            "NAVDURGA INVESTMENTS PRIVATE LIMITED",
            "NJ CAPITAL PRIVATE LIMITED",
            "NOVATIS FINOVATIVE LIMITED",
            "Optimus Finance Limited",
            "Oriental Finstock Services Ltd",
            "Pacific Finstock Ltd",
            "PADMALAYA FINSERVE PRIVATE LIMITED",
            "PARKER FINANCIAL SERVICES PRIVATE LIMITED",
            "PARSHWA CREDIT AND FINANCE PRIVATE LIMITED",
            "POOJA FINSTOCK INTERNATIONAL LIMTIED",
            "PRAHASH FIN-STOCK PRIVATE LTD",
            "PRAMOL TRADERS HOLDINGS PRIVATE LIMITED",
            "PRASHAM FINANCE LIMITED",
            "Prayas Financial Services Private Limited",
            "Premium Finance Pvt. Ltd.",
            "Pripan  Investment  Private  Ltd",
            "Prism  Finance  Ltd",
            "PUNJAB LEASE FINANCING LIMITED",
            "RAAJRATNA STOCKHOLDING PRIVATE LIMITED",
            "Radhe Finsec India Ltd",
            "RAJ RADHE FINANCE LTD.",
            "Rajkot Investment Trust Ltd",
            "RAJRUSHI WEALTH CREATORS LIMITED",
            "Ramchandra  Leasing  &Finance  Ltd",
            "RAMKRISHNA FINLEASE PRIVATE LIMITED",
            "RATNAAFIN CAPITAL PRIVATE LIMITED",
            "Ravi Fincorp Limited",
            "RIDDHI SECURITIES LIMITED",
            "RIYA CAPLEASE (INDIA) LTD.",
            "RSA FINCORP PRIVATE LIMITED",
            "RUDRA SECURITIES & CAPITAL LTD.",
            "Sagar Samrat Caplease Private Limited",
            "Sampati Securities Limited",
            "SANJEET MOTOR FINANCE PRIVATE LIMITED",
            "Satyam Finstock Private Limited",
            "Shanti Suri Securities Private Limited",
            "Sharad Finlease Private Limited",
            "Shelja Finlease Private Limited",
            "Shikha Investments Private Limited",
            "Shilchar Finance Private Limited",
            "Shree Bala Finvest Private Limited",
            "Shree Kamdhenu Financial Services Private Limited",
            "Shree Ramkrupa Finance Private Limited",
            "Shree Umiya Finlease Private Limited",
            "Shri Arya Investment Private Limited",
            "Shroff Capital & Finance Private Limited",
            "Siddhi Vinayak Fincap Limited",
            "SIHL Fincap Limited",
            "Simandhar Finance Limited",
            "Simandhar Finlease Private Limited",
            "Span Fincap Limited",
            "Stanrose Mafatlal Investments & Finance Ltd",
            "Sugan Enterprise Private Limited",
            "Sugan Finstock Private Limited",
            "Suman Finstocks Private Limited",
            "Sun Finlease (Gujarat) Limited",
            "Sun Investments Private Limited",
            "Sungold Capital Limited",
            "Sunrise Finlease Limited",
            "TANOT FINANCE LIMITED",
            "TAPTIGANGA FINSERV PRIVATE LIMITED",
            "TEXMAT AGENCIES PRIVATE LIMITED",
            "TIRUPATI FINLEASE LTD.",
            "TOKYO FINANCE LTD.",
            "TRADEBULLS CAPITAL LIMITED",
            "Trendline Finance Ltd",
            "TRIBHUVAN FINLEASE & DEVELOPERS PVT LTD",
            "TRIMURTI FINCAP PRIVATE LIMITED",
            "TRIPOLI MANAGEMENT PRIVATE LIMITED",
            "TRISHNA FINVEST PVT. LTD.",
            "TRUST FINSTOCK PRIVATE LIMITED",
            "Typhoon  Financial  Services  Ltd",
            "ULHAS SECURITIES PRIVATE LIMITED",
            "UNICON SUPPLIERS PRIVATE LIMITED",
            "VAISHALI SECURITIES LIMITED",
            "Vardan Finlease Limited",
            "VDCAP Finserv Private Limited",
            "VIKRAM VENTURE CAPITAL PVT.LTD.",
            "VINI INVESTMENTS PRIVATE LIMITED",
            "VINZOL CAPITAL & FINANCE LTD.",
            "Vip Finstocks Pvt. Ltd.",
            "VISNAGAR LEASE FINANCE LTD.",
            "WELL FIN SECURITIES LIMITED",
            "WELLWISHER SALES PROMOTION PRIVATE LIMITED",
            "WESTERN CAPITAL ADVISORS PRIVATE LIMITED",
            "WESTERN FINTRADE PVT.LTD.",
            "WHITE-ELEPHANT FINANCE LIMITED",
            "Y AND H PATEL FINANCE LIMITED",
            "YASH FINCAP LIMITED",
            "ZEN FINCORP PRIVATE LIMITED",
            "Achal Finance Ltd",
            "ANASHRI FINVEST LIMITED",
            "APANA FINVEST PVT LTD (Transferred from Kolkata Office)",
            "APARAMPAAR FINANCE PRIVATE LIMITED",
            "APAROKSHA FINANCIAL SERVICES PRIVATE LIMITED",
            "Avanti Finance Private Limited",
            "Avanti Microfinance Private Limited",
            "Bagaria Investech Pvt Limited",
            "Bairavi Finance Private Limited",
            "Basanth Investments (P) Ltd.",
            "Bharat Energy Ventures Limited",
            "BIKANNA COMMERCIAL COMPANY LTD(Transferred from Kolkota RO)",
            "BILLIONLOANS FINANCIAL SERVICES PRIVATE LIMITED",
            "BOTHRA CAPITAL PRIVATE LIMITED",
            "Celindia Finance & Investment Company Limited",
            "Chemm Finance Ltd  (converted from Deposit to Non-Deposit taking NBFC)",
            "Chetana Financial Services Private Limited (Formerly Nimba Finance & Leasing Ltd)",
            "CITRUS SECURITIES PRIVATE LIMITED",
            "CONATUS FINSERVE PRIVATE LIMITED",
            "Coorg Tea Company Ltd",
            "Davinta Financial Services Private Limited",
            "DELFIN INTERNATIONAL LIMITED",
            "Deshana Finlease Servces Ltd",
            "Dhanlaxmi Motors Finance Pvt Ltd",
            "DHRUVI SECURITIES PVT LTD",
            "Diganta Investments And Finance Co. Ltd",
            "EKAGRATA FINANCE PRIVATE LIMITED",
            "ETYACOL TECHNOLOGIES PRIVATE LIMITED",
            "Ezcred Private Limited",
            "Festino Commerce Private Limited",
            "FINCFRIENDS PRIVATE LIMITED (reissued on December 24",
            "FINNABLE CREDIT PRIVATE LIMITED",
            "GEETANJALI CREDIT AND CAPITAL LIMITED",
            "Genfin Capital Private Limited",
            "Gilada Finance And Investments Ltd.",
            "Grandhi Enterprises  Private Limited(Transfered from CHE formerly Bluemoon Investments P Ltd)",
            "Heramba Leasing Ltd",
            "HIVELOOP CAPITAL PRIVATE LIMITED",
            "Idf Financial Services Private Limited",
            "IMPACT CORPORATE INVESTMENTS PRIVATE LIMITED",
            "Jayanth Financial  Services",
            "JINVANI TRADING & INVESTMENT COMPANY PRIVATE LIMITED (Files Shifted To Bangalore RO)",
            "Kadamba MyMoney Limited.",
            "KALANDRI CAPITAL PRIVATE LIMITED",
            "Kallarakkal Fincorp Private Limited ( Formely Known as HMF Financial Services Private Limited) (Formerly Known as Adhokshaja Finance & Investments (P)Ltd)",
            "KALPAVITTA FINANCE PRIVATE LIMITED",
            "Kanva Shree Credit Pvt Ltd",
            "KASHIRAJ SUPPLY PRIVATE LIMITED",
            "Kaunshya Investments Private Limited",
            "Kemfin Services Pvt Ltd",
            "KNAB FINANCE ADVISORS PRIVATE LIMITED",
            "Koushik Financial Services Pvt Ltd",
            "Kshetrapal Finance Pvt Ltd",
            "Lakshmi Saraswathi Motors & General Finance ( Pvt.) Ltd.",
            "Lamina Leasing And Finance Ltd",
            "Lf2peer Financial Services Private Limited",
            "LOANZEN FINANCE PRIVATE LIMITED",
            "LTCV CREDIT PRIVATE LIMITED",
            "Maba Corporate Services Pvt Ltd",
            "Manjushree Fincap Private Limited  (Transfered From Guwahati)",
            "Meghana Corporates Pvt Ltd",
            "MICROGRAAM MARKETPLACE PRIVATE LIMITED",
            "MOHAM CAPITAL PRIVATE LIMITED",
            "MONEYGEAR FINTECH PRIVATE LIMITED",
            "MOTCAB FINANCE PRIVATE LIMITED",
            "Mysore Leasing & Finance Limited",
            "Nagarjuna Credits And Capitals Ltd.",
            "NARENDRA FINANCE CO PVT LTD",
            "Navachetana Microfin Services Private Limited",
            "Nirantara FinAccess Private Limited",
            "NISKHA FINANCE PRIVATE LIMITED",
            "NITSTONE FINSERV PRIVATE LIMITED",
            "NUTAN TRADE & AGENCIES PRIVATE LIMITED",
            "o3 FINANCE PRIVATE LIMITED",
            "Olety Finance Pvt Ltd.",
            "Opportunity Micro Finance India Limited",
            "P.S.N FINANCE PRIVATE LIMITED",
            "PARK VIEW PROPERTIES PRIVATE LIMITED",
            "PCI MARKETING PRIVATE LIMITED",
            "Perfios Account Aggregation Services Private Limited",
            "Perseus Finance Pvt Ltd",
            "Praveen Capital Private Limited (transferred from Mumbai RO)",
            "PREKSHA FINANCE PRIVATE LIMITED",
            "PROLIFIC FINVEST PVT LTD",
            "PUPIL FINANCE PRIVATE LIMITED",
            "QUADRILLION FINANCE PRIVATE LIMITED",
            "RAMAIAH CAPITAL PRIVATE LIMITED",
            "RANG DE P2P FINANCIAL SERVICES PRIVATE LIMITED",
            "RNAWEP INVESTMENTS PRIVATE LIMITED",
            "RORS Finance Private Limited (Formerly known as Pace Finance Private Limited)",
            "RSK FUND FINANCE PRIVATE LIMITED",
            "Rupeek Capital Pvt lTD",
            "Sagar Leasfin Ltd",
            "Shaan Finance  Limited (Reclassified to B)",
            "SHABRI INVESTMENTS PRIVATE LIMITED",
            "Shivangini Properties Limited",
            "Shree Biradev Finance Pvt Ltd",
            "SHREE MARIKAMBA MICRO FINANCE PRIVATE LIMITED",
            "Shruti Financial Services Private Limited (Transfered From Guwahati)",
            "South India Finvest Private Limited",
            "SOUTHERN HIGHTECH CAPITAL PRIVATE LIMITED",
            "Sowjenvee Finance Ltd",
            "Sri Ramachandra Enterprises Pvt Ltd",
            "Srikara Finance & Investment Pvt Ltd",
            "SURABHI FINANCE CORPORATION LIMITED",
            "TAPSTART CAPITAL PRIVATE LIMITED",
            "TECHFINO CAPITAL PRIVATE LIMITED",
            "The General Investment & Commercial Corporation",
            "The Western India Industrial Corporation",
            "Trikaal Leasing And Finance Ltd - Converted to NBFC-ND-ICC vide RDs order dated August 10",
            "U M B Securities Limited",
            "U.D.Finnvest Pvt Ltd",
            "UC INCLUSIVE CREDIT PRIVATE LIMITED",
            "UNIMONI FINANCIAL SERVICES LIMITED",
            "UPMOVE CAPITAL PRIVATE LIMITED",
            "Vasavi Finvest   Ltd",
            "Vijayanagar Industrial Credits Ltd",
            "Vimal Infin  Pvt  Ltd",
            "VINAYAK COMMERCE PRIVATE LIMITED",
            "Way 2 Wealth Capital Pvt Limited",
            "WHIZDM FINANCE PRIVATE LIMITED",
            "WORTGAGE FINANCE PRIVATE LIMITED",
            "YUNUS SOCIAL BUSINESS FUND BENGALURU PRIVATE LIMITED",
            "ZERODHA CAPITAL PRIVATE LIMITED",
            "A S P FINCAP PRIVATE LIMITED",
            "Aalok Deep Finance Pvt. Ltd.",
            "Ab Leasing & Finance Limited",
            "Adcon Capital Services Ltd.",
            "Ad-Manum Finance Limited.",
            "AEREO DEALCOM PRIVATE LIMITED",
            "Agni Fin-Lease Pvt.Ltd.",
            "Anjani Finance Limited",
            "Antaral Finance & Investment (P) Ltd.",
            "ANTRIKSH COMMERCE PRIVATE LIMITED",
            "ARHAM CREDIT CAPITAL PVT LTD",
            "Arihant Financial Services Ltd.",
            "Arihant Udyog Ltd.",
            "Armour Finance & Commerce Pvt. Ltd.",
            "ARYABHATT SALES PRIVATE LIMITED",
            "Ascent Leasing & Finance Co. Ltd.",
            "Atlanta Securities Private Limited",
            "Available Finance Ltd.",
            "AVIRAL FINANCE PRIVATE LIMITED",
            "B.C.M. Investments (P) Ltd.",
            "BANKA FINANCE & SECURITIES PVT. LTD.",
            "Beryl Securities Limited",
            "Beyant Holding & Finance Ltd.",
            "BLUE RIVER FINVEST PRIVATE LIMITED",
            "Casella Secutities Pvt. Ltd.",
            "Chandralok Finvest Private Limited",
            "Charbhuja Finance Ltd..",
            "Chorsiya Finvest Private Limited",
            "CI Capital Private Limited",
            "Compeer Leasing & Investment Pvt. Ltd.",
            "Continental Jeweltech Mining Pvt. Ltd.",
            "Dinpati Commercials Private Limited",
            "DITYA FINANCE PRIVATE LIMITED",
            "Dolphin Holdings & Real Estate Pvt. Ltd",
            "Drap Leasing & Finance Pvt. Ltd.",
            "East West Finvest India  Limited",
            "Econotech Services Private Limited",
            "ESCORT FINVEST PRIVATE LIMITED",
            "Fairwell Finance Ltd.",
            "Fastlend Capital Limited",
            "G.S. Leasing & Finance Pvt. Ltd.",
            "Gagan Investment Pvt Ltd",
            "GANGOTRI TRACON PVT. LTD.",
            "Genesis Securities Pvt. Ltd.",
            "GTLAXMI FINANCE PRIVATE LIMITED",
            "HARA COMMERCIALS PVT LTD",
            "HARE KRISHNA FINANCE PRIVATE LIMITED",
            "Himmat Finlease Pvt. Ltd.",
            "Hind Carpets Pvt Ltd",
            "IRFC FINANCE PRIVATE LIMITED",
            "Ives Leasing And Finance Ltd.",
            "J.V. Modi Securities Ltd.",
            "JAINAND FINVEST PRIVATE LIMITED",
            "Jds Capital Markets Pvt. Ltd.",
            "K.K. Patel Finance Ltd.",
            "K.S. Finlease Ltd.",
            "Khedia Finvest (India) Pvt. Limited",
            "KRISHNAKRIPA HOLDINGS PRIVATE LIMITED",
            "Landmark Capital Markets Pvt. Ltd.",
            "Leo S.M. Finance Limited",
            "M.L. Securities & Finance Private Limited",
            "MAHAMAYA FINANCE PRIVATE LIMITED",
            "Mahautkarsh Securities & Finance Pvt. Ltd",
            "Majestic Leasing Company Private Limited",
            "Malani Financial Services Limited",
            "MANGLAMBALAJEE FINANCE LIMITED",
            "MARWAH FINANCE PRIVATE LIMITED",
            "Msj Colonising And Leasing Co. Pvt. Ltd.",
            "MVS LEASING PRIVATE LIMITED",
            "NEURATY AGENTS PRIVATE LIMITED",
            "ORRISH FINANCE PRIVATE LIMITED",
            "P J Credit Capital Ltd.",
            "Palriwala Finance Pvt. Ltd.",
            "PANAJI FINANCE LIMITED",
            "PIONEER MAX BUSINESS PVT LTD.",
            "PRECISE FINANCE & INVESTMENT LIMITED",
            "PRODIGEE FINANCE LIMITED",
            "PUJAM SALES AND SERVICES PRIVATE LIMITED",
            "Purvi Finvest  Limited",
            "R.G. Finlease Pvt. Ltd.",
            "Rajvir Securities And Finance Pvt. Ltd.",
            "Raneka Fincom  Private Limited",
            "Ranjit Securities Ltd.",
            "Rathi Finlease Ltd.",
            "RDK GLOBAL FINANCE PRIVATE LIMITED",
            "RECAPITA FINANCE PRIVATE LIMITED",
            "Rudraksh Gold Securities Pvt. Ltd.",
            "Samruddhi Swastik Trading & Investments Limited",
            "Sarda Resources Pvt. Ltd.",
            "Satya Prakash Capital Investment Ltd.",
            "Sawalram Leasing & Financing Ltd.",
            "Scindia Investments Pvt. Ltd.",
            "Sgk Finvest Pvt. Ltd.",
            "Shree Naivedya Fincom Pvt.Ltd.",
            "Shreyash Securities & Finance Limited",
            "SHRI AARISHT FINANCE PRIVATE LIMITED",
            "Shri Ram Finance Corporation  Private Limited",
            "SILVER FINANCE SOLUTIONS PRIVATE LIMITED",
            "Silver Star Finance & Leasing Ltd",
            "Sojatia Auto Finance Pvt. Ltd.",
            "SUKHCHAIN HIRE PURCHASE PRIVATE LIMITED",
            "Sulabh Plantations And Finance Pvt. Ltd.",
            "Sunita Finlease Ltd.",
            "SWADHA FINLEASE PRIVATE LIMITED",
            "Swan Finance Limited",
            "Swastika Fin-Mart Private Limited",
            "SWASTIKA PRINTS PRIVATE LIMITED",
            "TDS Fincap  Pvt. Ltd.",
            "TRIDENT LAMI-PACK PRIVATE LIMITED",
            "Trimurthi Finvest Pvt. Ltd.",
            "Tumus Investment Pvt. Ltd.",
            "Unitara Finance Limited",
            "Viconic Vyapar Pvt Limited",
            "Viiking Finance Private Limited",
            "Viji Finance Limited",
            "Vsc Finance Pvt. Ltd.",
            "White Paper Capital Limited",
            "WOOD CAPITAL FINANCE LIMITED",
            "Adhikar Microfinance Ltd (Trfd from Ahmedabad)",
            "Bharatiya Fincom Private Limited (formerly Utkal Holdings Pvt. Ltd.)",
            "CARNEX SALES AGENCIES PRIVATE LIMITED (TRANSFERRED FROM KOLKATA RO)",
            "East Coast Tradefin Ltd.",
            "EVERBLUE TRAVIN PVT. LTD.",
            "G U FINANCIAL SERVICES PRIVATE LIMITED.",
            "GLOWMORE FINANCE PRIVATE LIMITED",
            "HILL VIEW HIRE PURCHASE PRIVATE LIMITED (TRANSFERRED FROM KOLKATA RO)",
            "IMFA Alloys Finlease Limited",
            "Madhumalti Distributors Private Limited",
            "ORICRED FINSERV PRIVATE LIMITED",
            "Prime Capital Market Limited",
            "Rajpath Financial Services Private Ltd.",
            "Sahebramka Projects Ltd.",
            "Shree Leasing & Finance Ltd.",
            "Tetron Capital Limited",
            "VECTOR FINANCE PRIVATE LIMITED",
            "Vedvyas Finance Private Ltd.",
            "AASHRIYA CAPITAL & FINANCIAL SERVICES PRIVATE LIMITED",
            "AGILE FINSERV PRIVATE LIMITED",
            "AKAL HIRE PURCHASE LIMITED",
            "AMBIUM FINSERVE PRIVATE LIMITED",
            "ANADI INVESTMENTS PVT LTD",
            "ART FINANCE (INDIA) PRIVATE LIMITED",
            "ASHISH FINVEST PRIVATE LIMITED",
            "AVANCER FINLEASE INDIA LIMITED",
            "BAGRRYS HOLDING LIMITED",
            "BAJWA ADVANCES PVT LTD",
            "BALRAJ HIRE PURCHASE PVT LTD",
            "BAROTA FINANCE LIMITED",
            "BASEL INVESTMENTS LIMITED",
            "BHAGYODAY INVESTMENTS PVT LTD",
            "BHATIA HIRE PURCHASE PVT LTD",
            "BSN CAPITAL SERVICES PVT LTD",
            "BSN FINVEST PVT LTD",
            "CHAAR CHINAR DEPOSITS AND HIRE PURCHASE LIMITED",
            "CHATHA FINANCIAL SERVICES PRIVATE LIMITED",
            "CHATHA FINANCIERS PRIVATE LIMITED",
            "CHOPRA HIRE PURCHASE PVT LTD",
            "CITYGOLD CREDIT CAPITAL LIMITED",
            "CLASSIC GLOBAL FINANCE AND CAPITAL LTD",
            "CONSOLIDATED CAPITAL SERVICES LIMITED",
            "CORPORATE TRUST PRIVATE LIMITED",
            "CREATIVE INTRA LIMITED",
            "DAYANAND MUNJAL INVESTMENTS PVT LTD",
            "DECIMUS FINANCIAL LIMITED",
            "DEE DEE SECURITIES PRIVATE LIMITED",
            "DEEP FINANCE LTD",
            "DEEP FINVEST LIMITED",
            "DEEPAK DEPOSIT AND ADVANCES LTD",
            "DEVAKAR INVESTMENT AND TRADING COMPANY PVT LTD",
            "DHANSAMRIDHI FINANCE PRIVATE LIMITED",
            "DOABA FINSERV PRIVATE LIMITED",
            "DPGC FINANCE LIMITED",
            "EMERALD LEASING FINANCE AND INVESTMENT COMPANY LIMITED",
            "EX SERVICEMEN HIRE PURCHASE FINANCE PRIVATE LIMITED",
            "FAIRDEAL GENERAL FINANCE CO LIMITED",
            "FINDOC FINVEST PRIVATE LIMITED",
            "FINVASIA FINANCIAL SERVICES PRIVATE LIMITED",
            "FLAMINGO FINANCE AND INVESTMENT CO LTD",
            "FOLSOM FINANCE PRIVATE LIMITED",
            "GALLEY FINANCIERS AND PROPERTY CONSULTANTS PVT LTD",
            "GANG-JONG DEVELOPMENT FINANCE PRIVATE LIMITED",
            "GILL AND VIRK FINANCE COMPANY LIMITED",
            "GLOSEC FINANCE PRIVATE LIMITED",
            "GOLF LINK FINANCE AND RESORTS PRIVATE LIMITED",
            "GOODLUCK ADVANCES AND FINLEASE LIMITED",
            "GURDASPUR FINANCE & LEASING PRIVATE LIMITED",
            "H S B LEASING LTD",
            "HANUMANT KRIPA EASY FINANCE PRIVATE LIMITED",
            "HARGOBIND SAHIB HIRE PURCHASE PRIVATE LIMITED",
            "HIMALYAN FINANCIAL SERVICES LIMITED",
            "HIMANI LEASING COMPANY LTD",
            "HRD FINCAP LIMITED",
            "IMPACT LEASING PRIVATE LIMITED",
            "J R FINANCE LIMITED",
            "JALANDHAR CARGO ADVANCES LIMITED",
            "JARYAL MOTOR FINANCE COMPANY LIMITED",
            "JASHAN FINLEASE LIMITED",
            "JASSI FINANCE PRIVATE LIMITED",
            "JASSI HIRE PURCHASE LIMITED",
            "JIWANJYOTI DEPOSITS AND ADVANCES LIMITED",
            "Kamal Finserve Private Limited",
            "KAY KAY HIRE PURCHASE PRIVATE LIMITED",
            "KINETIC TRUST LIMITED",
            "KING LEASING AND REAL ESTATE LIMITED",
            "KISSAN FINVEST LIMITED",
            "KOVALAM INVESTMENT AND TRADING COMPANY LIMITED",
            "KSR CAPITAL SERVICES LTD",
            "LIBRA AUTO AND GENERAL FINANCE LIMITED",
            "LOTUS CAPITAL SERVICES PVT LTD",
            "M R FINLEASE LIMITED",
            "MAHAKALI CAPITAL INVESTMENTS SERVICES PRIVATE LIMITED",
            "MAKRO LEASE PRIVATE LIMITED",
            "MAND FINANCE LIMITED",
            "MANHAR FINANCE PRIVATE LIMITED",
            "MANSAROVER HOLDINGS LIMITED",
            "MARBLE FINVEST LIMITED",
            "MARSH CAPITAL SERVICES PRIVATE LIMITED",
        ],
        editable: true,
    },
    {
        name: "OutstandingLoan",
        label: "Outstanding Loan",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
        subtitle: "Amount",
    },
    {
        name: "RepaymentFreq",
        label: "Repayment Frequency",
        inputType: INPUT_TYPE.SELECT,
        options: ["one_(1)_month", "three_(3)_month", "six_(6)_month", "twelve_(12)_month", "at_maturity_of_loan", OTHER],
        defaultValue: "one_(1)_month",
        editable: true,
    },
    {
        name: "otherRepayment",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
    {
        name: "originalLoanTenor",
        label: "Original Loan Tenor",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
        subtitle: "Loan period in months",
        placeholder: "Month",
    },
    {
        name: "remainLoanTenor",
        label: "Remaining Loan Tenor",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
        subtitle: "Loan Period In Months",
        placeholder: "Month",
    },
    {
        name: "emiAmt",
        label: "EMI Amount",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "loanOverdue",
        label: "Loan Overdue",
        inputType: INPUT_TYPE.RADIO,
        options: ["yes", "no"],
        editable: true,
        defaultValue: "",
        subtitle: "Is Loan Overdue ?",
    },
    {
        name: "noOfEmiOverdue",
        label: "No. of EMI Overdue",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
    },
    {
        name: "amtOverdue",
        label: "Amount Overdue",
        inputType: INPUT_TYPE.NUMBER,
        defaultValue: "",
    },
    {
        name: "reasonOver",
        label: "Reason (for non-payment)",
        inputType: INPUT_TYPE.SELECT,
        options: [
            "Late disbursement",
            "Complicated terms",
            " High interest rate",
            "Inadequate loan",
            "Illness",
            "Family contingency",
            "Legal contingency",
            "Business failure",
            "Mismanagement",
            "Technical error",
            "Missed payment",
            "Dispute / Fraud",
            "Others",
        ],
        editable: true,
        defaultValue: "Late disbursment",
    },
    {
        name: "otherReason",
        label: "Other",
        inputType: INPUT_TYPE.TEXT,
        defaultValue: "",
    },
];

export default fixture;
