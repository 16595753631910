import React from "react";
import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";
import LoanAccountAdditionalItem from "./LoanAccountAdditionalItem";

const LoanAccountAdditional = ({
    prefix,
    setCurrentInput,
    index,
    entityIndex,
    entityType,
    pane,
}: {
    prefix: string;
    setCurrentInput: any;
    index: number;
    entityType: string;
    entityIndex: number;
    pane?: string;
}) => {
    const firstLoan = useWatch({ name: `${prefix}.loanAccountAdditional.0` });

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <LoanAccountAdditionalItem
                index={index}
                prefix={prefix}
                setCurrentInput={setCurrentInput}
                entityType={entityType}
                entityIndex={entityIndex}
                pane={pane}
            />
            {index === 0 && (
                <CommonFields
                    inputType={INPUT_TYPE.FILE}
                    label="Emi Receipt"
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    name={`${prefix}.loanAccountAdditional.0.emiReceipt`}
                    data-testid={generateTestId(`emi-receipt-loan-account-additional-${index + 1}-${pane}`, "field")}
                    multiple={true}
                    prop={{
                        value: "Entire Loan Period",
                    }}
                    handleChange={useUploadFile({
                        name: "emiReceipt",
                        fileAddress: `${prefix}.loanAccountAdditional.0.emiReceipt`,
                        multiple: true,
                    })}
                    value={firstLoan?.emiReceipt}
                    showCross={localStorage.getItem("disable_form") !== "true" && firstLoan?.emiReceipt && firstLoan?.emiReceipt?.[0]?.link !== ""}
                    handleFileModalOpen={({ position }) => {
                        setCurrentInput({ name: `${prefix}.loanAccountAdditional.0.emiReceipt`, open: true, multiple: true, position: position });
                    }}
                />
            )}
        </Grid>
    );
};

export default LoanAccountAdditional;
