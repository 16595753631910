import { AssetDetailsCreateInput, DownloadDataCreateInput, EnumerableAssetDetailsCreateInput } from "@/__generated__/apiSchemas";

const mapAssetFieldsValues = (data: any[], filledExcel: DownloadDataCreateInput) => {
    const assets: EnumerableAssetDetailsCreateInput[] = [];

    for (const asset of data) {
        let newAsset: any = {
            hasAsset: true,
            type: "Construction Equipment",
            value: "0",
            filledExcel: filledExcel,
            propertyType: "Shop",
            propertyDetail: "Commercial",
            manufacturer: "JCB",
            otherManufacturer: "",
            category: "Electric Scissors",
            otherCategory: "",
            model: "S1530E ELECTRIC SCISSOR",
            otherModel: "",
            description: "",
            landArea: {
                value: 0,
                unit: "Sq Foot",
            },
            builtUpArea: {
                value: 0,
                unit: "Sq Foot",
            },
            yearOfManufacture: "",
            registrationNumber: "",
            registrationNumberVerified: false,
            isFinanced: false,
            financedBy: "",
            dateOfPurchase: "",
            registrationDetails: {},
        };

        const { hasAsset, ...other } = asset;

        newAsset = {
            ...newAsset,
            ...other,
        };

        assets.push(newAsset);
    }

    return assets;
};

export default mapAssetFieldsValues;
