import { Fragment } from "react";
import { useSignIn } from "react-auth-kit";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import GoogleIcon from "@mui/icons-material/Google";
import Button from "@mui/material/Button";
import { useGoogleLogin } from "@react-oauth/google";
import { login, register } from "api";
import { AxiosError } from "axios";
import { ROUTES } from "globalConstants";
import { env } from "../../env";

interface GoogleLoginButtonProps {
    id?: string;
    className?: string;
    type: "login" | "register";
    onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => any;
}

const GoogleLoginButton = (props: GoogleLoginButtonProps) => {
    const { type, ...rest } = props;

    // const navigate = useNavigate();

    // const signIn = useSignIn();

    // const { mutate: handleLogin } = useMutation(login, {
    //     onSuccess: (data) => {
    //         if (data.message === "User does not exist") {
    //             toast.error("User doesn't exist. Please sign up.");
    //         } else if (data.message === "Password Incorrect") {
    //             toast.error("Password is incorrect.");
    //         } else if (data.message === "Login Successful") {
    //             const { access_token: accessToken, role, first_login, refresh_token: refreshToken, form_id: formID, full_name } = data.data;
    //             localStorage.setItem("authToken", accessToken);
    //             localStorage.setItem("refreshToken", refreshToken);
    //             if (
    //                 signIn({
    //                     token: accessToken,
    //                     // 10 minutes
    //                     expiresIn: 10,
    //                     tokenType: "Bearer",
    //                     refreshToken,
    //                     // one day in minutes
    //                     refreshTokenExpireIn: 1 * 24 * 60,
    //                     authState: {
    //                         formID,
    //                         isAdmin: role === "admin",
    //                         role,
    //                         username: data.username,
    //                         full_name,
    //                         loan: undefined,
    //                     },
    //                 })
    //             ) {
    //                 if (first_login) {
    //                     navigate(ROUTES.DASHBOARD);
    //                 } else {
    //                     navigate(ROUTES.HOME_PAGE);
    //                 }
    //             }
    //         } else if (data.message === "User already logged in on another device") {
    //             toast.info("User already logged in on another device");
    //         }
    //     },
    //     onError: (error: AxiosError) => {
    //         toast.error(error.message);
    //     },
    // });

    // const { mutate: handleRegister } = useMutation(register, {
    //     onSuccess: (data) => {
    //         if (data.status === "Ok") {
    //             toast.dismiss();
    //             toast.success("Registration Successful. Please Login to Continue");
    //             navigate(ROUTES.LOGIN);
    //         } else {
    //             toast.dismiss();
    //             toast.error(data?.message ?? "Similar Username already exists");
    //         }
    //     },
    //     onError: (error: AxiosError) => {
    //         toast.error(error.message);
    //     },
    // });

    // const onSuccess = (res: any) => {
    //     if (type === "login") {
    //         handleLogin({
    //             // username: res.profileObj.email,
    //             login_source: "SSO",
    //             sso_source: "google",
    //             code: res.code,
    //         });
    //     } else {
    //         handleRegister({
    //             // username: res.profileObj.email,
    //             login_source: "SSO",
    //             sso_source: "google",
    //             code: res.code,
    //         });
    //     }
    // };

    // const signInWithGoogle = useGoogleLogin({
    //     scope: "profile email",
    //     onSuccess: onSuccess,
    //     flow: "auth-code",
    // });

    return (
        <Button variant="contained" LinkComponent="a" href={`${env.REACT_APP_NODEJS_API_URL}/auth/google`} color="error" {...rest}>
            <Fragment>
                <GoogleIcon /> &nbsp; Google
            </Fragment>
        </Button>
    );
};

export default GoogleLoginButton;
