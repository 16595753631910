import { INPUT_TYPE, OTHER } from "globalConstants";
import { ICommonFixture } from "types";
import { convertSnakeCaseToHeaderCase } from "utils";

//
const fixture: ICommonFixture[] = [
    {
        name: "newOrUsed",
        label: "New or Used",
        inputType: INPUT_TYPE.SELECT,
        options: ["new", "used"],
        editable: true,
        defaultValue: "new",
    },
    {
        name: "registrationNumber",
        label: "Registration Number",
        inputType: INPUT_TYPE.TEXT,
        subtitle: "Validate RC",
        showMask: true,
    },
    {
        name: "manufacturer",
        label: "Manufacturer",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        options: ["TATA_MOTORS", OTHER],
        defaultValue: "TATA_MOTORS",
        labelGenerator: (value) => (value === "TATA_MOTORS" ? convertSnakeCaseToHeaderCase(value) : convertSnakeCaseToHeaderCase(value)),
        otherOptionProps: {
            subtitle: "Name of Manufacturer",
        },
        showMask: true,
    },

    {
        name: "category",
        label: "Category",
        editable: true,
        inputType: INPUT_TYPE.SELECT,
        defaultValue: "RIGID_TRUCKS",
        otherOptionProps: {
            subtitle: "Vehicle Category",
        },
        getOptions: ({ manufacturer }) => {
            switch (manufacturer) {
                case "TATA_MOTORS":
                    return ["RIGID_TRUCKS", "TRACTOR_TRAILER", "TIPPERS_(Signa_&_Prima)", OTHER];
                case OTHER:
                    return [OTHER];
                default:
                    return [];
            }
        },
        showMask: true,
    },
    {
        name: "model",
        label: "Model",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        getOptions: ({ category }) => {
            switch (category) {
                case "RIGID_TRUCKS":
                    return [
                        "TATA_ULTRA_1918.T",
                        "TATA_LPT_1918_COWL",
                        "TATA_LPT_1918_5L_TURBOTRONN_COWL",
                        "TATA_LPT_2818_COWL_&_SIGNA_2818.T",
                        "TATA_LPT_2821_COWL_&_SIGNA_2821.T_BS6_5L_TURBOTRONN",
                        "TATA_LPT_3118_COWL_&_SIGNA_3118.T",
                        "TATA_LPT_3518_COWL_&_SIGNA_3518.T",
                        "TATA_LPT_3521_COWL_&_SIGNA_3521.T_5L_TURBOTRONN",
                        "TATA_LPT_4225_COWL_&_SIGNA_4225.T",
                        "TATA_LPT_4221_COWL_&_SIGNA_4221.T",
                        "TATA_LPT_4825_COWL_&_SIGNA_4825.T",
                        "TATA_LPT_4925_COWL_&_SIGNA_4925.T",
                        "TATA_ULTRA_2821.T_RIGID_TRUCKS_BS6",
                        OTHER,
                    ];
                case "TRACTOR_TRAILER":
                    return [
                        "TATA_SIGNA_4018.S_&_LPS_4018_COWL",
                        "TATA_SIGNA_4021.S",
                        "TATA_SIGNA_4625.S",
                        "TATA_SIGNA_4021.S_ESC",
                        "TATA_PRIMA_4025.S",
                        "TATA_SIGNA_5530.S",
                        "TATA_PRIMA_5530.S",
                        "TATA_SIGNA_5525.S_4X2_BS6",
                        "TATA_SIGNA_5530.S_4X2",
                        OTHER,
                    ];
                case "TIPPERS_(Signa_&_Prima)":
                    return [
                        "TATA_SIGNA_1923.K",
                        "TATA_SIGNA_2823.K_HD_9S",
                        "TATA_SIGNA_2825.K/.TK",
                        "TATA_SIGNA_2823.K/.TK_STD_6S",
                        "TATA_PRIMA_2825.K/.TK",
                        "TATA_SIGNA_2823.K/.TK_RMC_STD_6S",
                        "TATA_PRIMA_2830.K",
                        "TATA_PRIMA_2830.K_REPTO",
                        "TATA_SIGNA_3523.TK",
                        "TATA_SIGNA_3525.K/.TK",
                        "TATA_PRIMA_3525.K/.TK",
                        "TATA_PRIMA_3530.K",
                        "TATA_SIGNA_4225.TK",
                        "TATA_SIGNA_4825.TK",
                        OTHER,
                    ];
                case OTHER:
                    return [OTHER];
                default:
                    return [];
            }
        },
        otherOptionProps: {
            subtitle: "Machine Model",
        },
        showMask: true,
    },
    {
        name: "sellerName",
        label: "Seller Name",
        inputType: INPUT_TYPE.SELECT,
        editable: true,
        options: ["leo_earthmovers", OTHER],
        readOnly: false,
        subtitle: "Purchased From",
        defaultValue: "leo_earthmovers",
        showMask: true,
    },
    {
        name: "salesAgent.fullName",
        label: "Sales Agent",
        inputType: INPUT_TYPE.SELECT,
        options: [],
        subtitle: "CE Sales Agent, if any",
        defaultValue: "",
        showMask: true,
    },
    {
        name: "noOfUnits",
        label: "No. of Units",
        inputType: INPUT_TYPE.NUMBER,
        placeholder: "1",
        infoText: "No. of machines being purchased for this model",
        className: "short-input-field",
        dataTestId: "no-of-units-field",
    },
    {
        name: "basicPriceOrInvoiceValue",
        label: "Basic Price or Invoice Value",
        inputType: INPUT_TYPE.PRICE,
        defaultValue: "",
    },
    {
        name: "gst",
        label: "GST",
        inputType: INPUT_TYPE.PRICE,
        placeholder: "5,40,000",
        className: "common__mui__textfield-grey",
        readOnly: true,
        disableReactHookForm: true,
    },
    {
        name: "tcsPercentage",
        label: "TCS",
        inputType: INPUT_TYPE.PRICE,
        placeholder: "35,000",
        readOnly: true,
        className: "common__mui__textfield-grey",
        disableReactHookForm: true,
    },
    {
        name: "ownMarginOrLtv",
        label: "Own Margin or LTV",
        inputType: INPUT_TYPE.NUMBER,
    },
    {
        name: "tenor",
        label: "Tenor",
        inputType: INPUT_TYPE.NUMBER,
        placeholder: "Month",
        subtitle: "Loan period in Months",
        required: true,
        reactHookFormRegisterOptions: {
            max: {
                value: 60,
                message: "* Tenor cannot be greater than 60",
            },
        },
    },
    {
        name: "manufacturerDate",
        label: "Manufacture date",
        inputType: INPUT_TYPE.DATE,
        placeholder: "Date",
    },
];

export default fixture;
