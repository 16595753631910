import { PersonalDetailsCreateInput } from "@/__generated__/apiSchemas";

const fixture: PersonalDetailsCreateInput = {
    yourDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
        dateOfBirth: new Date().toLocaleDateString(),
        maritalStatus: "Single",
        noOfDependents: 0,
        // if maritalStatus married
        noOfChildren: 0,
    },
    fatherDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    motherDetails: {
        prefix: "Mrs.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    spouseDetails: {
        prefix: "Mr.",
        otherPrefix: "",
        firstName: "",
        middleName: "",
        lastName: "",
    },
    background: {
        educationQualification: "Junior High School (10th Class)",
        otherEducationQualification: "",
        profession: "Practicing Doctor",
        otherProfession: "",

        ownerOrEmployed: "Owner",
        caste: "General",
        otherCaste: "",
        subCaste: "",
        religion: "Buddhist",
        otherReligion: "",
        physicalDisability: "No",
    },
    residentialAddress: {
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        postalCode: "",
        city: "",
        state: "",
        lengthOfStayInYears: 0,
        lengthOfCityStayInYears: 0,
        ownership: "Owned by self",
        rentType: "Monthly Rent PM",
        // in case of rentType === "Monthly Rent PM"
        monthlyRent: 0,
        // in case of rentType === "Owner Name"
        ownerDetails: {
            prefix: "Mr.",
            otherPrefix: "",
            firstName: "",
            middleName: "",
            lastName: "",
        },
    },
    permanentAddress: {
        isSameAsResidentialAddress: true,
        address1: "",
        address2: "",
        address3: "",
        landmark: "",
        postalCode: "",
        city: "",
        state: "",
        lengthOfStayInYears: 0,
        lengthOfCityStayInYears: 0,
        ownership: "Owned by self",
        rentType: "Monthly Rent PM",
        // in case of rentType === "Monthly Rent PM"
        monthlyRent: 0,
        // in case of rentType === "Owner Name"
        ownerDetails: {
            prefix: "Mr.",
            otherPrefix: "",
            firstName: "",
            middleName: "",
            lastName: "",
        },
    },
};

export default fixture;
