import { memo } from "react";
import { useFormContext } from "react-hook-form";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import { LongFormFieldValuesType } from "contexts/longForm";
import "./ConstructionEquipment.styles.scss";

const ConstructionEquipment = () => {
    const { register } = useFormContext<LongFormFieldValuesType>();

    return (
        <Grid
            container
            className="common__mui__textfield__container"
            id="construction-equipment__container"
            justifyContent="center"
            alignItems="center"
        >
            <Grid item xs={12}>
                <FormControl className="common__mui__selectfield" fullWidth>
                    <select
                        disabled={localStorage.getItem("is_submit") === "true"}
                        {...register("requirement.loanType")}
                        className="loan-type"
                        data-testid="equipment-vehicle-dropdown"
                    >
                        <ExpandMoreIcon fontSize="large" />
                        <option value="Construction Equipment">Construction Equipment</option>
                        <option value="Commercial Vehicles">Commercial Vehicles</option>
                    </select>
                </FormControl>
            </Grid>
        </Grid>
    );
};

export default memo(ConstructionEquipment);
