import React, { useState } from "react";
import { useAuthUser, useSignIn } from "react-auth-kit";
import P from "react-phone-input-2";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
    Grid,
    Typography,
    FormControl,
    TextField,
    FormHelperText,
    InputLabel,
    OutlinedInput,
    InputAdornment,
    IconButton,
    FormGroup,
    Checkbox,
    Button,
    CircularProgress,
    ToggleButton,
    ToggleButtonGroup,
    Box,
    Container,
    Stack,
} from "@mui/material";
import { useChangeEmail, useChangePassword } from "__generated__/apiComponents";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import { ROUTES } from "globalConstants";
import useFeature from "hooks/useFeature";
import combineClassNames from "utils/combineClassNames";
import "./Profile.styles.scss";

// @ts-ignore
const PhoneInput = P.default ?? P;

type Props = {};

const Profile = (props: Props) => {
    const shouldShowProfilePage = useFeature("profile-page");
    const auth = useAuthUser();

    const [values, setValues] = useState({
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
        email: auth()?.email,
        mobile: auth()?.mobile,
        newsletterFrequency: "weekly",
        marketingCompaigns: false,
        platformUpdates: false,
        accountActivityDigestFrequency: "weekly",
        accountAndApplications: true,
    });

    const {
        newPassword,
        oldPassword,
        confirmNewPassword,
        email,
        mobile,
        newsletterFrequency,
        accountActivityDigestFrequency,
        accountAndApplications,
        platformUpdates,
        marketingCompaigns,
    } = values;

    const [privacyCheck, setPrivacyCheck] = useState(false);
    const [verifiedEmail, setVerifiedEmail] = useState(false);
    const [verifiedMobile, setVerifiedMobile] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);

    const [requiredFields, setRequiredFields] = useState<
        {
            label: string;
            value: string;
        }[]
    >([]);

    const navigate = useNavigate();

    const { mutate: handleChangePassword, isLoading } = useChangePassword({
        onSuccess: (data: any) => {
            if (data) {
                toast.success("Password Reset Successful");
            }
        },
        onError: (error: any) => {
            if (error?.stack?.message === "Session Expired") {
                toast.error("Session Expired. Please login again");
                navigate(ROUTES.LOGIN);
            }
            toast.error(error?.stack?.message);
        },
    });

    const { mutate: handleChangeEmail } = useChangeEmail({
        onSuccess: (data: any) => {
            toast.success(data);
        },
        onError: (error: any) => {
            if (error?.stack?.message === "Session Expired") {
                toast.error("Session Expired. Please login again");
                navigate(ROUTES.LOGIN);
            }
            toast.error(error?.stack?.message);
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        if (name === "marketingCompaigns" || name === "platformUpdates" || name === "accountAndApplications") {
            setValues((prevState) => ({ ...prevState, [name]: checked }));
        } else if (name === "newsletterFrequency" || name === "accountActivityDigestFrequency") {
            setValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
        } else setValues((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    };

    const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPrivacyCheck(e.target.checked);
    };

    const handleClickShowPassword = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleClickShowOldPassword = () => {
        setShowOldPassword((prevState) => !prevState);
    };

    const handleClickShowConfirmPassword = () => {
        setShowConfirmPassword((prevState) => !prevState);
    };

    const handleMouseDownPassword = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
    };

    const handleNewsletterFrequencyChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, newsletterFrequency: string) => {
        setValues((prevState) => ({ ...prevState, newsletterFrequency }));
    };

    const handleAccountActivityDigestFrequencyChange = (e: React.MouseEvent<HTMLElement, MouseEvent>, accountActivityDigestFrequency: string) => {
        setValues((prevState) => ({ ...prevState, accountActivityDigestFrequency }));
    };

    const handleVerifyEmail = () => {
        handleChangeEmail({
            body: { newEmail: email },
        });
    };

    const handleVerifyMobile = () => {};

    const handleSubmit = () => {
        const requiredFields = [];
        toast.dismiss();
        const passwordRegEx = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#@!%*?&])[A-Za-z\d#@!%*?&]{8,}/;
        if (!email) {
            requiredFields.push({ label: "Email", value: "email" });
            // setRequiredFields((prevState) => [...prevState, "email"]);
            // toast.error("Email is required");
            // return;
        }
        if (!mobile && mobile.length < 10) {
            requiredFields.push({ label: "Mobile", value: "mobile" });
            // setRequiredFields((prevState) => [...prevState, "mobile"]);
            // toast.error("Mobile Number is required");
            // return;
        }
        if (!oldPassword) {
            requiredFields.push({ label: "Old Password", value: "oldPassword" });
            // setRequiredFields((prevState) => [...prevState, "oldPassword"]);
            // toast.error("Old Password is required");
            // return;
        }
        if (!newPassword) {
            requiredFields.push({ label: "New Password", value: "newPassword" });
            // setRequiredFields((prevState) => [...prevState, "newPassword"]);
            // toast.error("New Password is required");
            // return;
        }
        if (!confirmNewPassword) {
            requiredFields.push({ label: "Confirm New Password", value: "confirmNewPassword" });
            // setRequiredFields((prevState) => [...prevState, "cpnfirmNewPassword"]);
            // toast.error("Passwords do not match");
            // return;
        } else if (confirmNewPassword !== newPassword) {
            toast.error("Passwords do not match");
            return;
        }
        if (newPassword && !passwordRegEx.test(newPassword)) {
            toast.error(
                "Password must be at least 8 characters long, contain at least one lowercase letter, one uppercase letter, one number and one special character"
            );
            return;
        }

        let combinedErrors = requiredFields[0]?.label + " is required";
        if (requiredFields.length > 1)
            combinedErrors =
                requiredFields
                    .map((field) => field.label)
                    .slice(0, requiredFields.length - 1)
                    .join(", ") +
                " and " +
                requiredFields[requiredFields.length - 1].label +
                " are required";

        if (requiredFields.length !== 0) {
            toast.error(combinedErrors);
            setRequiredFields(requiredFields);
            return;
        }

        setRequiredFields([]);

        handleChangePassword({
            body: {
                oldPassword: oldPassword,
                newPassword: newPassword,
            },
        });
    };

    if (!shouldShowProfilePage) return null;

    return (
        <Box id="profile__container">
            <Navbar />
            <Grid id="profile__content" item xs={12} justifyContent="center">
                <Grid container direction="row" justifyContent="center" alignItems="stretch">
                    <Grid item id="profile__form" xs={12} md={8}>
                        <Grid container direction="column" justifyContent="center" alignItems="center">
                            <Typography variant="h4" id="profile__heading">
                                PROFILE
                            </Typography>
                            <FormControl className="field" required>
                                <TextField
                                    className={`styled-textfield ${requiredFields.some((field) => field.value === "email") && "error"}`}
                                    name="email"
                                    label="Email"
                                    placeholder=""
                                    defaultValue=""
                                    type="text"
                                    fullWidth
                                    onChange={handleChange}
                                    value={email}
                                    required
                                />
                                <FormHelperText
                                    className={combineClassNames(
                                        "common__helper-text",
                                        !verifiedEmail ? "validate" : "",
                                        verifiedEmail ? "verified" : ""
                                    )}
                                    onClick={handleVerifyEmail}
                                    disabled={email === ""}
                                >
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        {verifiedEmail ? (
                                            <Grid item xs="auto" container>
                                                {" "}
                                                <CheckCircleIcon className="Icon validate-icon" />{" "}
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                        <Grid item xs={true}>
                                            <span>Verify Email</span>
                                        </Grid>
                                    </Grid>
                                </FormHelperText>
                            </FormControl>

                            <FormControl className="field">
                                <PhoneInput
                                    country={"in"}
                                    value={mobile}
                                    onChange={(val: string) => {
                                        setValues((prevState) => ({
                                            ...prevState,
                                            mobile: val,
                                        }));
                                    }}
                                    disabled
                                    required
                                    inputProps={{
                                        name: "mobile",
                                        required: true,
                                        className: "w-full py-2 pl-12 outline-none border border-gray-200 ",
                                    }}
                                    placeholder="Phone Number"
                                />
                                <FormHelperText
                                    className={combineClassNames(
                                        "common__helper-text",
                                        !verifiedMobile ? "validate" : "",
                                        verifiedMobile ? "verified" : ""
                                    )}
                                    onClick={handleVerifyMobile}
                                    disabled
                                >
                                    <Grid container direction="row" justifyContent="center" alignItems="center">
                                        {verifiedMobile ? (
                                            <Grid item xs="auto" container>
                                                {" "}
                                                <CheckCircleIcon className="Icon validate-icon" />{" "}
                                            </Grid>
                                        ) : (
                                            ""
                                        )}
                                        <Grid item xs={true}>
                                            <span>Verify Mobile</span>
                                        </Grid>
                                    </Grid>
                                </FormHelperText>
                            </FormControl>
                            <FormControl className="field" variant="outlined" required>
                                <InputLabel htmlFor="outlined-adornment-password">Old Password</InputLabel>
                                <OutlinedInput
                                    id="outlined-adornment-password"
                                    className={`styled-textfield ${requiredFields.some((field) => field.value === "oldPassword") && "error"}`}
                                    type={showOldPassword ? "text" : "password"}
                                    value={oldPassword}
                                    name="oldPassword"
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowOldPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Password"
                                />
                            </FormControl>
                            <FormControl className="field" variant="outlined" required>
                                <InputLabel htmlFor="outlined-adornment-password">New Password</InputLabel>
                                <OutlinedInput
                                    className={`styled-textfield ${requiredFields.some((field) => field.value === "newPassword") && "error"}`}
                                    id="outlined-adornment-password"
                                    type={showPassword ? "text" : "password"}
                                    value={newPassword}
                                    name="newPassword"
                                    onChange={handleChange}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="New Password"
                                />
                            </FormControl>
                            <FormControl className="field" variant="outlined" required>
                                <InputLabel htmlFor="outlined-adornment-password">Confirm New Password</InputLabel>
                                <OutlinedInput
                                    className={`styled-textfield ${requiredFields.some((field) => field.value === "confirmNewPassword") && "error"}`}
                                    id="outlined-adornment-password"
                                    type={showConfirmPassword ? "text" : "password"}
                                    value={confirmNewPassword}
                                    onChange={handleChange}
                                    name="confirmNewPassword"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                edge="end"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    label="Confirm New Password"
                                />
                            </FormControl>
                            <FormControl className="field" variant="outlined">
                                <FormGroup>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item width={"5%"}>
                                            <Checkbox
                                                checked={marketingCompaigns}
                                                className="marketing-compaign-checkbox"
                                                name="marketingCompaigns"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width={"91%"}>
                                            <p className="label">Marketing campaigns</p>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                            <FormControl className="field" variant="outlined">
                                <FormGroup>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item width={"5%"}>
                                            <Checkbox
                                                className="platform-updates-checkbox"
                                                checked={platformUpdates}
                                                name="platformUpdates"
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width={"91%"}>
                                            <p className="label">Platform Updates</p>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                            <FormControl className="field" variant="outlined">
                                <FormGroup>
                                    <Grid container direction="row" justifyContent="space-between" alignItems="center">
                                        <Grid item width={"5%"}>
                                            <Checkbox
                                                disabled
                                                className="account-and-applications-checkbox"
                                                name="accountAndApplications"
                                                checked={accountAndApplications}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item width={"91%"}>
                                            <p className="label">Account and Applications</p>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                            </FormControl>
                            <Stack spacing={2} mb={2} width="100%">
                                <Stack direction="row" justifyContent="space-between" alignItems="center">
                                    <InputLabel htmlFor="newsletter-frequency">Newsletter Frequency</InputLabel>
                                    <ToggleButtonGroup
                                        color="primary"
                                        id="newsletter-frequency"
                                        value={newsletterFrequency}
                                        exclusive
                                        onChange={handleNewsletterFrequencyChange}
                                        aria-label="Platform"
                                    >
                                        <ToggleButton value="weekly">Weekly</ToggleButton>
                                        <ToggleButton value="monthly">Monthly</ToggleButton>
                                        <ToggleButton value="never">Never</ToggleButton>
                                    </ToggleButtonGroup>
                                </Stack>
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    <InputLabel>Account Activity Digest Frequency</InputLabel>
                                    <ToggleButtonGroup
                                        color="primary"
                                        id="account-activity-digest-frequency"
                                        value={accountActivityDigestFrequency}
                                        exclusive
                                        onChange={handleAccountActivityDigestFrequencyChange}
                                        aria-label="Account Activity Digest Frequency"
                                    >
                                        <ToggleButton value="daily">Daily</ToggleButton>
                                        <ToggleButton value="weekly">Weekly</ToggleButton>
                                        <ToggleButton value="never">Never</ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                            </Stack>

                            <Button id="profile__btn" disabled={isLoading} onClick={handleSubmit} variant="contained">
                                {isLoading ? <CircularProgress className="white" /> : "Save"}
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Footer />
        </Box>
    );
};

export default Profile;
