import React, { useState } from "react";
import TabsWrapperContext from "./context";

const defaultValue = {
    entity: 0,
    income: 0,
    personal: 0,
    document: 0,
};

const AccordionProvider = ({ children }: { children: JSX.Element }) => {
    const [value, setValue] = useState(defaultValue);

    return (
        <TabsWrapperContext.Provider
            value={{
                value,
                setValue,
            }}
        >
            {children}
        </TabsWrapperContext.Provider>
    );
};

export default AccordionProvider;
