import { useState } from "react";
import { useWatch } from "react-hook-form";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import CommonAccordion from "components/CommonAccordion";
import CommonAddressField from "components/CommonAddressField";
import { OTHER } from "globalConstants";
import { padEnd } from "lodash";
import DeselectFileModal from "pages/Dashboard/components/Document/components/DeselectFileModal";
import { BusinessExperience, EntityTab, OwnerShipDetails, ManagementDetails, ShareHoldingDirector, CreditBureau } from "..";

const EntityForm = ({ header, index, title }: { header: string; index: number; title: string }) => {
    const prefix = `${header}.${header === "applicant" ? "" : `${index}.`}`;

    const business = useWatch({
        name: `${prefix}entity.business`,
    });
    const aadharValidated = useWatch({
        name: `${prefix}entity.aadharValidated`,
    });

    const isDisabled = useWatch({
        name: "disabled",
    });

    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });

    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };

    const loanAccountCreditPrefix = `${header}.${header === "applicant" ? "" : `${index}.`}document.business.loanAccountCredit`;

    return (
        <Grid container id="entity-form" direction="column" justifyContent="center" alignItems="center">
            <fieldset className="fieldset" disabled={isDisabled}>
                <EntityTab header={header} index={index} prefix={prefix} pane={title} />

                {business === "Partnership" && <OwnerShipDetails prefix={prefix} pane={title} />}
                {business === "Registered Under Company Act" && <ShareHoldingDirector prefix={prefix} pane={title} />}
                {business === OTHER && <ManagementDetails prefix={prefix} pane={title} />}

                <BusinessExperience prefix={prefix} header={header} pane={title} />

                <CreditBureau
                    key={prefix}
                    prefix={prefix}
                    loanAccountCreditPrefix={loanAccountCreditPrefix}
                    setCurrentInput={setCurrentInput}
                    pane={title}
                />

                <Divider className="divider" />
                {(business === "Sole Proprietor" || business === "Individual") && (
                    <CommonAddressField
                        name={`${prefix}entity.residentialAddress`}
                        variant="common"
                        title="Residential Address"
                        tab="entity"
                        aadharValidated={aadharValidated}
                        readOnly={localStorage.getItem("disable_form") === "true"}
                        keys={`${header}.residentialAddress`}
                        pane={title}
                    />
                )}

                <CommonAddressField
                    name={`${prefix}entity.registeredAddress`}
                    variant="common"
                    title="Registered Address"
                    tab="entity"
                    pane={title}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    keys={`${header}.registeredAddress`}
                    {...(business === "Sole Proprietor" || business === "Individual"
                        ? {
                              isSameAsOtherAddress: {
                                  name: "isSameAsResidentialAddress",
                                  options: [
                                      {
                                          label: "Same As Residential",
                                          value: true,
                                      },
                                      {
                                          label: "Different Registered Address",
                                          value: false,
                                      },
                                  ],
                              },
                          }
                        : {})}
                />

                <CommonAddressField
                    title="Business Address"
                    keys={`${header}.businessAddress`}
                    name={`${prefix}entity.businessAddress`}
                    tab="entity"
                    pane={title}
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    variant="common"
                    isSameAsOtherAddress={{
                        name: "isSameAsRegisteredAddress",
                        options: [
                            {
                                label: "Same As Registered",
                                value: true,
                            },
                            {
                                label: "Different Business Address",
                                value: false,
                            },
                        ],
                    }}
                />

                <CommonAddressField
                    title="Mailing Address"
                    tab="entity"
                    pane={title}
                    keys={`${header}.mailingAddress`}
                    name={`${prefix}entity.mailingAddress`}
                    variant="basic"
                    readOnly={localStorage.getItem("disable_form") === "true"}
                    isSameAsOtherAddress={{
                        name: "isSameAsBusinessAddress",
                        options: [
                            {
                                label: "Same As Business",
                                value: true,
                            },

                            {
                                label: "Different Mailing Address",
                                value: false,
                            },
                        ],
                    }}
                />
                <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
            </fieldset>
        </Grid>
    );
};

export default EntityForm;
