import { useState, Fragment } from "react";
import { useFieldArray } from "react-hook-form";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CommonAccordion from "components/CommonAccordion";
import { useMaskedFields } from "hooks";
import formatLoanAccountFIName from "utils/formatLoanAcccountFIName";
import generateTestId from "utils/generateTestId";
import { RequirementForm, EntityForm, IncomeForm } from "../..";
import DeselectFileModal from "../DeselectFileModal";
import WorkOrder from "../WorkOrder";
import BankAccount from "./components/BankAccount";
import AddAnotherBankAccount from "./components/BankAccount/AddAnotherBankAccount";
import DeleteBankAccount from "./components/BankAccount/DeleteBankAccount";
import LoanAccountAdditional from "./components/LoanAccountAdditional";
import LoanAccountCredit from "./components/LoanAccountCredit";

const BusinessTab = ({ prefix, entityType, index, pane }: { prefix: string; entityType: string; index: number; pane: string }) => {
    const [currentInput, setCurrentInput] = useState({
        name: "",
        open: false,
        multiple: false,
        position: 0,
    });
    const handleClose = () => {
        setCurrentInput({ name: "", open: false, multiple: false, position: 0 });
    };

    const { maskField } = useMaskedFields();

    const {
        fields: bankAccountFields,
        append: appendBankAccount,
        remove: removeBankAccount,
    } = useFieldArray({
        name: `${prefix}.bankAccount`,
    });
    const { fields: loanAccountAdditionalFields } = useFieldArray({
        name: `${prefix}.loanAccountAdditional`,
    });

    const { fields: loanAccountCreditFields } = useFieldArray({
        name: `${prefix}.loanAccountCredit`,
    });

    const workOrderPrefixInReactForm = `${prefix}.workOrder`;

    const indexText = (index: number) => {
        switch (index) {
            case 0:
                return "";
            case 1:
                return "2nd";
            case 2:
                return "3rd";
            default:
                return `${index + 1}th`;
        }
    };

    return (
        <Fragment>
            <Grid container direction="column" justifyContent="center" alignItems="center">
                <CommonAccordion title={`Requirement`} tab="document" data-testid={generateTestId(`requirement-${pane}`, "accordion")}>
                    <RequirementForm prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
                </CommonAccordion>
                <CommonAccordion title={`Entity`} tab="document" data-testid={generateTestId(`entity-${pane}`, "accordion")}>
                    <EntityForm prefix={prefix} setCurrentInput={setCurrentInput} pane={pane} />
                </CommonAccordion>{" "}
                <CommonAccordion title={`Income`} tab="document" data-testid={generateTestId(`income-${pane}`, "accordion")}>
                    <IncomeForm prefix={prefix} setCurrentInput={setCurrentInput} entityType={entityType} index={index} pane={pane} />
                </CommonAccordion>
                {loanAccountCreditFields.map((field: any, index: number) => {
                    return (
                        <CommonAccordion
                            key={field.id}
                            keys={`${index}-loanAccount`}
                            title=""
                            data-testid={generateTestId(`loan-account-${index + 1}-${pane}`, "accordion")}
                            titleReactNode={
                                <Grid container item xs={12} alignItems="center" justifyContent="space-between">
                                    <Typography className="common-custom-label">{maskField(formatLoanAccountFIName(field.bankName))}</Typography>
                                    <Typography className="common-custom-label">
                                        {field.accountType.length > 20 ? field.accountType.slice(0, 20) + "..." : field.accountType}
                                    </Typography>
                                </Grid>
                            }
                            tab="document"
                            className="common-accordion-field entity-container"
                        >
                            <LoanAccountCredit prefix={prefix} index={index} setCurrentInput={setCurrentInput} />
                        </CommonAccordion>
                    );
                })}
                {loanAccountAdditionalFields.map((field, index) => (
                    <CommonAccordion
                        key={field.id}
                        title={`${index > 0 ? indexText(index) : ""} Additional Loan Account`}
                        tab="document"
                        data-testid={generateTestId(`${index > 0 ? indexText(index) : ""} Additional Loan Account-${pane}`, "accordion")}
                    >
                        <LoanAccountAdditional prefix={prefix} index={index} setCurrentInput={setCurrentInput} pane={pane} />
                    </CommonAccordion>
                ))}
                {bankAccountFields.map((field, index) => (
                    <Grid
                        className="equipment-tab"
                        container
                        direction="row"
                        style={{
                            position: "relative",
                        }}
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        key={field.id}
                    >
                        <CommonAccordion
                            title={`${index > 0 ? indexText(index) : ""} Bank Account`}
                            tab="document"
                            data-testid={generateTestId(`${index > 0 ? indexText(index) : ""} Bank Account-${pane}`, "accordion")}
                        >
                            <BankAccount prefix={prefix} index={index} setCurrentInput={setCurrentInput} pane={pane} />
                        </CommonAccordion>
                        {index > 0 && <DeleteBankAccount index={index} remove={removeBankAccount} />}
                    </Grid>
                ))}
                <AddAnotherBankAccount append={appendBankAccount} pane={pane} />
                <Divider className="divider" />
                <WorkOrder workOrderPrefixInReactForm={workOrderPrefixInReactForm} pane={pane} setCurrentInput={setCurrentInput} />
            </Grid>
            <DeselectFileModal handleClose={handleClose} currentInput={currentInput} />
        </Fragment>
    );
};

export default BusinessTab;
