import React, { useState } from "react";
import toast from "react-hot-toast";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useContactUs } from "__generated__/apiComponents";
import { useIsSmallScreen } from "hooks";
import "./ContactForm.styles.scss";

const ContactForm = () => {
    const isSmallScreen = useIsSmallScreen();

    const { mutate: handleContact } = useContactUs({
        onSuccess: (data) => {
            toast.dismiss();
            toast.success("Details sent successfully");
            setContact({
                fname: "",
                lname: "",
                email: "",
                company: "",
                phone: "",
                message: "",
            });
        },
        onError: (error: any) => {
            toast.dismiss();
            toast.error(error?.response?.data?.message);
        },
    });

    const [contact, setContact] = useState({
        fname: "",
        lname: "",
        email: "",
        company: "",
        phone: "",
        message: "",
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setContact({
            ...contact,
            [name]: value,
        });
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        if (!contact.fname || !contact.lname || !contact.email || !contact.company || !contact.phone) {
            toast.error("Please fill all the fields");
            return;
        }

        handleContact({
            body: {
                firstName: contact.fname,
                lastName: contact.lname,
                email: contact.email,
                companyName: contact.company,
                mobile: contact.phone,
                message: contact.message ?? "",
            },
        });
    };

    return (
        <Grid className="contact-us-section-3" container direction="row" justifyContent="center">
            <Grid xs={isSmallScreen ? 12 : 4} className="left">
                <Typography variant="h5" className="left-h5" component="h5">
                    Get in touch
                </Typography>
                <Typography variant="h6" className="left-p" component="p">
                    Give us your email and someone from our team will follow up with you.
                </Typography>
            </Grid>
            <Grid xs={isSmallScreen ? 12 : 7} className="right">
                <div className="row1">
                    <TextField
                        id="fname"
                        label=""
                        style={{ width: "50%" }}
                        type="text"
                        className="row1-textfield"
                        placeholder="First Name"
                        onChange={(e) => handleChange(e)}
                        value={contact.fname}
                        name="fname"
                        inputProps={{ className: "input-text" }}
                    />
                    <TextField
                        id="lname"
                        label=""
                        style={{ width: "50%" }}
                        type="text"
                        className="row1-textfield"
                        placeholder="Last Name"
                        onChange={(e) => handleChange(e)}
                        value={contact.lname}
                        name="lname"
                        inputProps={{ className: "input-text" }}
                    />
                </div>
                <TextField
                    id="email"
                    label=""
                    type="email"
                    style={{ marginTop: "10px", width: "100%" }}
                    placeholder="Email Address"
                    onChange={(e) => handleChange(e)}
                    value={contact.email}
                    name="email"
                    inputProps={{ className: "input-text-field" }}
                />
                <TextField
                    style={{ marginTop: "10px", width: "100%" }}
                    id="company"
                    label=""
                    type="text"
                    placeholder="Company Name"
                    onChange={(e) => handleChange(e)}
                    value={contact.company}
                    name="company"
                    inputProps={{ className: "input-text-field" }}
                />
                <TextField
                    id="phone"
                    label=""
                    type="text"
                    style={{ marginTop: "10px", width: "100%" }}
                    placeholder="Phone Number"
                    onChange={(e) => handleChange(e)}
                    value={contact.phone}
                    name="phone"
                    inputProps={{ className: "input-text-field" }}
                />
                <TextField
                    id="message"
                    label=""
                    type="text"
                    style={{ marginTop: "10px", width: "100%" }}
                    placeholder="Message"
                    onChange={(e) => handleChange(e)}
                    value={contact.message}
                    name="message"
                    inputProps={{ className: "input-text-field" }}
                />
                <Button onClick={(e) => handleSubmit(e)} variant="contained" className="button">
                    Share Contact Details
                </Button>
                <Typography id="contact-us-section-3-p" component="p">
                    By clicking this button, you agree to our &nbsp;<a href="#">Privacy Policy</a> and <a href="#"> Terms of Use</a>.
                </Typography>
            </Grid>
        </Grid>
    );
};

export default ContactForm;
