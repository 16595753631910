import { useWatch } from "react-hook-form";
import Grid from "@mui/material/Grid";
import CommonFields from "components/CommonFields";
import { INPUT_TYPE } from "globalConstants";
import { useUploadFile } from "hooks";
import generateTestId from "utils/generateTestId";

const PostDisbursalForm = ({ prefix, setCurrentInput, pane }: { prefix: string; setCurrentInput: any; pane?: string }) => {
    const postDisbursal = useWatch({ name: `${prefix}.postDisbursal` });
    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="center">
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                label="Purchase Invoice"
                data-testid={generateTestId(`Purchase Invoice-${pane}`, "field")}
                value={postDisbursal?.purchaseInvoice}
                readOnly={localStorage.getItem("disable_form") === "true"}
                name={`${prefix}.postDisbursal.purchaseInvoice`}
                handleChange={useUploadFile({
                    name: "purchaseInvoice",
                    fileAddress: `${prefix}.postDisbursal.purchaseInvoice`,
                })}
                showCross={
                    localStorage.getItem("disable_form") !== "true" && postDisbursal?.purchaseInvoice && postDisbursal?.purchaseInvoice?.link !== ""
                }
                handleFileModalOpen={() =>
                    setCurrentInput({ name: `${prefix}.postDisbursal.purchaseInvoice`, open: true, multiple: false, position: 0 })
                }
            />
            <br />
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                name={`${prefix}.postDisbursal.registrationNumber`}
                value={postDisbursal?.registrationNumber}
                label="Registration Number"
                data-testid={generateTestId(`Registration Number-${pane}`, "field")}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "registrationNumber",
                    fileAddress: `${prefix}.postDisbursal.registrationNumber`,
                })}
                showCross={
                    localStorage.getItem("disable_form") !== "true" &&
                    postDisbursal?.registrationNumber &&
                    postDisbursal?.registrationNumber?.link !== ""
                }
                handleFileModalOpen={() =>
                    setCurrentInput({ name: `${prefix}.postDisbursal.registrationNumber`, open: true, multiple: false, position: 0 })
                }
            />
            <br />
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                name={`${prefix}.postDisbursal.RCVerification`}
                label="RC Verification"
                data-testid={generateTestId(`RC Verification-${pane}`, "field")}
                value={postDisbursal?.RCVerification}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "RCVerification",
                    fileAddress: `${prefix}.postDisbursal.RCVerification`,
                })}
                showCross={
                    localStorage.getItem("disable_form") !== "true" && postDisbursal?.RCVerification && postDisbursal?.RCVerification?.link !== ""
                }
                handleFileModalOpen={() =>
                    setCurrentInput({ name: `${prefix}.postDisbursal.RCVerification`, open: true, multiple: false, position: 0 })
                }
            />
            <br />
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                name={`${prefix}.postDisbursal.insuranceCertificate`}
                label="Insurance Certificate"
                data-testid={generateTestId(`Insurance Certificate-${pane}`, "field")}
                value={postDisbursal?.insuranceCertificate}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "insuranceCertificate",
                    fileAddress: `${prefix}.postDisbursal.insuranceCertificate`,
                })}
                showCross={
                    localStorage.getItem("disable_form") !== "true" &&
                    postDisbursal?.insuranceCertificate &&
                    postDisbursal?.insuranceCertificate?.link !== ""
                }
                handleFileModalOpen={() =>
                    setCurrentInput({ name: `${prefix}.postDisbursal.insuranceCertificate`, open: true, multiple: false, position: 0 })
                }
            />
            <br />
            <CommonFields
                inputType={INPUT_TYPE.FILE}
                name={`${prefix}.postDisbursal.vehicleTaxCertificate`}
                value={postDisbursal?.vehicleTaxCertificate}
                label="Vehicle Tax Certificate"
                data-testid={generateTestId(`Vehicle Tax Certificate-${pane}`, "field")}
                readOnly={localStorage.getItem("disable_form") === "true"}
                handleChange={useUploadFile({
                    name: "vehicleTaxCertificate",
                    fileAddress: `${prefix}.postDisbursal.vehicleTaxCertificate`,
                })}
                showCross={
                    localStorage.getItem("disable_form") !== "true" &&
                    postDisbursal?.vehicleTaxCertificate &&
                    postDisbursal?.vehicleTaxCertificate?.link !== ""
                }
                handleFileModalOpen={() =>
                    setCurrentInput({ name: `${prefix}.postDisbursal.vehicleTaxCertificate`, open: true, multiple: false, position: 0 })
                }
            />
        </Grid>
    );
};

export default PostDisbursalForm;
