import { Fragment, useEffect } from "react";
import { useWatch, useFormContext } from "react-hook-form";
import { Manufacturer } from "@/types/manufacturers";
import FormControl from "@mui/material/FormControl";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { useGetAllManufacturers } from "__generated__/apiComponents";
import { CommonLabel } from "components/CommonFields";
import CommonFields from "components/CommonFields";
import { OTHER, INPUT_TYPE } from "globalConstants";
import { ICommonFixtures } from "types";
import { convertSnakeCaseToHeaderCase } from "utils";
import generateTestId from "utils/generateTestId";

type Props = {
    prefix: string;
    externalIndex: number;
    index: number;
    props: ICommonFixtures;
    activeFields: string[];
    handleRcValidate: any;
    setRcDetails: React.Dispatch<any>;
    rcVerified: boolean;
    setRcVerified: React.Dispatch<React.SetStateAction<boolean>>;
    pane?: string;
};

const AssetItem = ({
    prefix,
    externalIndex,
    index,
    handleRcValidate,
    activeFields,
    rcVerified,
    setRcVerified,
    setRcDetails,
    pane,
    props: { inputType, defaultValue, name, extendedOptions, editable, label, options, subtitle, reactHookFormRegisterOptions },
}: Props) => {
    const { data: manufacturers } = useGetAllManufacturers<Manufacturer[]>({});

    const asset = useWatch({ name: `${prefix}.${externalIndex}` });

    const { register, setValue } = useFormContext();

    const resetFields = (type: string) => {
        if (type === "Vehicle") {
            setValue(`${prefix}.${externalIndex}.manufacturer`, "");
            setValue(`${prefix}.${externalIndex}.category`, "");
            setValue(`${prefix}.${externalIndex}.model`, "");
        }
        if (type === "Construction Equipment") {
            setValue(`${prefix}.${externalIndex}.manufacturer`, "JCB");
            setValue(`${prefix}.${externalIndex}.category`, "Electric Scissors");
            setValue(`${prefix}.${externalIndex}.model`, "S1530E ELECTRIC SCISSOR");
        }
        setValue(`${prefix}.${externalIndex}.registrationNumberVerified`, false);
        setValue(`${prefix}.${externalIndex}.registrationNumber`, "");
        setValue(`${prefix}.${externalIndex}.yearOfManufacture`, "");
        setValue(`${prefix}.${externalIndex}.registrationDetails`, {});
        setRcVerified(false);
        setRcDetails(null);
    };

    useEffect(() => {
        if (name === "category" && asset.manufacturer !== OTHER) {
            options = manufacturers
                ?.find((element) => element.manufacturer_name === asset.manufacturer)
                ?.categories.map((item) => ({
                    label:
                        convertSnakeCaseToHeaderCase(item.category) === "Other" || convertSnakeCaseToHeaderCase(item.category) === "other"
                            ? "OTHER"
                            : convertSnakeCaseToHeaderCase(item.category),
                    value: item.category,
                }));
            setValue(`${prefix}.${externalIndex}.category`, options?.[0]?.value);
            // if (!options?.find((option) => option?.value === asset?.category))
            //     if (options && options?.length > 0) {
            //         setValue(`${prefix}.${externalIndex}.category`, options?.[0]?.value);
            //     }
        }
        if (asset.manufacturer === OTHER) {
            setValue(`${prefix}.${externalIndex}.category`, OTHER);
            setValue(`${prefix}.${externalIndex}.model`, OTHER);
        }
    }, [asset.manufacturer]);

    useEffect(() => {
        if (name === "model" && asset.category !== OTHER) {
            options = manufacturers
                ?.find((item) => item.manufacturer_name === asset.manufacturer)
                ?.categories?.find((item) => item.category === asset.category)
                ?.models?.map((item) => ({
                    label:
                        convertSnakeCaseToHeaderCase(item) === "Other" || convertSnakeCaseToHeaderCase(item) === "other"
                            ? "OTHER"
                            : convertSnakeCaseToHeaderCase(item),
                    value: item,
                }));
            setValue(`${prefix}.${externalIndex}.${name}`, options?.[0]?.value);
            // if (!options?.find((option) => option?.value === asset?.model))
            //     if (options && options?.length > 0) setValue(`${prefix}.${externalIndex}.${name}`, options?.[0]?.value);
        }
        if (asset.category === OTHER) {
            setValue(`${prefix}.${externalIndex}.model`, OTHER);
        }
    }, [asset.category]);

    if (asset.type === "Construction Equipment") {
        if (name === "landArea" || name === "propertyDetail" || name === "propertyType" || name === "builtArea" || name === "description") {
            return <Fragment />;
        }
        if (name === "manufacturer") {
            options = manufacturers?.map((element) => ({
                label:
                    convertSnakeCaseToHeaderCase(element.manufacturer_name) === "Other" ||
                    convertSnakeCaseToHeaderCase(element.manufacturer_name) === "other"
                        ? "OTHER"
                        : convertSnakeCaseToHeaderCase(element.manufacturer_name),
                value: element.manufacturer_name,
            }));
            if (!options?.find((option) => option?.value?.toLocaleLowerCase() === "other"))
                options?.push({
                    label: "OTHER",
                    value: OTHER,
                });
        }
        if (name === "category") {
            options = manufacturers
                ?.find((element) => element.manufacturer_name === asset.manufacturer)
                ?.categories.map((item) => ({
                    label:
                        convertSnakeCaseToHeaderCase(item.category) === "Other" || convertSnakeCaseToHeaderCase(item.category) === "other"
                            ? "OTHER"
                            : convertSnakeCaseToHeaderCase(item.category),
                    value: item.category,
                }));
            if (!options?.find((option) => option?.value?.toLocaleLowerCase() === "other"))
                options?.push({
                    label: "OTHER",
                    value: OTHER,
                });
        }
        if (name === "model") {
            options = manufacturers
                ?.find((item) => item.manufacturer_name === asset.manufacturer)
                ?.categories?.find((item) => item.category === asset.category)
                ?.models?.map((item) => ({
                    label:
                        convertSnakeCaseToHeaderCase(item) === "Other" || convertSnakeCaseToHeaderCase(item) === "other"
                            ? "OTHER"
                            : convertSnakeCaseToHeaderCase(item),
                    value: item,
                }));
            if (!options?.find((option) => option?.value?.toLocaleLowerCase() === "other"))
                options?.push({
                    label: "OTHER",
                    value: OTHER,
                });
        }
    } else if (asset.type === "Vehicle") {
        if (name === "landArea" || name === "propertyDetail" || name === "propertyType" || name === "builtArea" || name === "description") {
            return <Fragment />;
        }
        if (name === "manufacturer" || name === "model") {
            inputType = INPUT_TYPE.TEXT;
            defaultValue = "";
        }
        if (name === "category") {
            options = extendedOptions!.isVehicle.options;
        }
    } else if (asset.type === "Property") {
        if (
            name === "manufacturer" ||
            name === "category" ||
            name === "model" ||
            name === "yearOfManufacture" ||
            name === "registrationNumber" ||
            name === "description"
        ) {
            return <Fragment />;
        }
        if (asset.propertyDetail === "Industrial") {
            if (name === "builtArea") return <Fragment />;
        }
        if (name === "propertyType") {
            if (asset.propertyDetail === "Commercial") {
                options = extendedOptions!.isCommercial.options;
            } else if (asset.propertyDetail === "Residential") {
                options = extendedOptions!.isResidential.options;
            } else if (asset.propertyDetail === "Industrial") {
                options = extendedOptions!.isIndustrial.options;
            } else {
                options = extendedOptions!.isAgricultural.options;
            }
        }
        if (name === "landArea") {
            if (asset.propertyDetail !== "Residential") {
                return <Fragment />;
            }
        }
    } else {
        if (
            name === "manufacturer" ||
            name === "category" ||
            name === "model" ||
            name === "landArea" ||
            name === "propertyType" ||
            name === "registrationNumber" ||
            name === "builtArea" ||
            name === "propertyDetail"
        ) {
            return <Fragment />;
        }
    }
    if (name === "landArea") {
        const inlineStyles: any = {};
        if (subtitle) {
            inlineStyles.marginBottom = "1.5rem";
        }
        activeFields.push("landArea.value", "landArea.unit");
        return (
            <Grid
                className={"common__mui__textfield__container"}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={inlineStyles}
            >
                <CommonLabel
                    showLabel={true}
                    label={label}
                    infoText={""}
                    labelSubtitle={""}
                    required={!!reactHookFormRegisterOptions?.required}
                    data-testid={generateTestId(`${label}-asset-${externalIndex + 1}-${pane}`, "field-label")}
                />
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField
                            variant={"outlined"}
                            fullWidth
                            disabled={localStorage.getItem("disable_form") === "true"}
                            inputProps={{
                                "data-testid": `${prefix}.${externalIndex}.landArea.value`,
                                ...reactHookFormRegisterOptions,
                            }}
                            onKeyPress={(event) => {
                                // ignore "-", "+" and "e"
                                if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                                    event.preventDefault();
                                }
                            }}
                            type="number"
                            InputProps={{
                                className: "common-textfield",
                                inputProps: {
                                    min: 0,
                                },
                            }}
                            className={"common__mui__textfield"}
                            {...register(`${prefix}.${externalIndex}.landArea.value`, reactHookFormRegisterOptions)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className="common__mui__selectfield" fullWidth>
                        <select
                            {...register(`${prefix}.${externalIndex}.landArea.unit`, reactHookFormRegisterOptions)}
                            data-testid={`${prefix}.${externalIndex}.landArea.unit`}
                            disabled={localStorage.getItem("disable_form") === "true"}
                        >
                            <option key={index} value={"Sq Foot"}>
                                Sq Foot
                            </option>
                            <option key={index} value={"Sq Meter"}>
                                Sq Meter
                            </option>
                            <option key={index} value={"Sq Yard"}>
                                Sq Yard
                            </option>
                        </select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }

    if (name === "builtArea") {
        const inlineStyles: any = {};
        if (subtitle) {
            inlineStyles.marginBottom = "1.5rem";
        }
        activeFields.push("builtUpArea.value", "builtUpArea.unit");
        return (
            <Grid
                className={"common__mui__textfield__container"}
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                style={inlineStyles}
            >
                <CommonLabel
                    showLabel={true}
                    label={label}
                    infoText={""}
                    labelSubtitle={""}
                    required={!!reactHookFormRegisterOptions?.required}
                    data-testid={generateTestId(`${label}-asset-${externalIndex + 1}-${pane}`, "field-label")}
                />
                <Grid item xs={3}>
                    <FormControl fullWidth>
                        <TextField
                            variant={"outlined"}
                            fullWidth
                            disabled={localStorage.getItem("disable_form") === "true"}
                            inputProps={{
                                "data-testid": `${prefix}.${externalIndex}.builtUpArea.value`,
                                ...reactHookFormRegisterOptions,
                            }}
                            onKeyPress={(event) => {
                                // ignore "-", "+" and "e"
                                if (event?.key === "-" || event?.key === "+" || event?.key === "e") {
                                    event.preventDefault();
                                }
                            }}
                            type="number"
                            InputProps={{
                                className: "common-textfield",
                                inputProps: {
                                    min: 0,
                                },
                            }}
                            className={"common__mui__textfield"}
                            {...register(`${prefix}.${externalIndex}.builtUpArea.value`, reactHookFormRegisterOptions)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={3}>
                    <FormControl className="common__mui__selectfield" fullWidth>
                        <select
                            {...register(`${prefix}.${externalIndex}.builtUpArea.unit`, reactHookFormRegisterOptions)}
                            data-testid={`${prefix}.${externalIndex}.builtUpArea.unit`}
                            disabled={localStorage.getItem("disable_form") === "true"}
                        >
                            <option key={index} value={"Sq Foot"}>
                                Sq Foot
                            </option>
                            <option key={index} value={"Sq Meter"}>
                                Sq Meter
                            </option>
                            <option key={index} value={"Sq Yard"}>
                                Sq Yard
                            </option>
                        </select>
                    </FormControl>
                </Grid>
            </Grid>
        );
    }
    if (name === "value") {
        if (asset.type === "Construction Equipment") {
            subtitle = "Equipment Cost";
        } else if (asset.type === "Vehicle") {
            subtitle = "Vehicle Cost";
        } else if (asset.type === "Property") {
            subtitle = "Property Cost";
        } else {
            subtitle = "Cost";
        }
    }
    activeFields.push(name);
    let value = asset[name];
    return (
        <CommonFields
            key={index}
            inputType={inputType}
            name={`${prefix}.${externalIndex}.${name}`}
            options={options || []}
            defaultValue={defaultValue}
            data-testid={generateTestId(`${label}-asset-${externalIndex + 1}-${pane}`, "field")}
            label={label}
            value={value}
            reactHookFormRegisterOptions={{
                ...reactHookFormRegisterOptions,
                onChange(event) {
                    if (name === "type") {
                        resetFields(event.target.value);
                    }
                },
            }}
            readOnly={localStorage.getItem("disable_form") === "true" || !editable}
            ignoreOthers={name === "type"}
            subtitle={subtitle}
            multiLine={name === "manufacturer" || name === "model" || name === "description"}
            {...(name === "registrationNumber" && rcVerified ? { verified: true } : {})}
            {...(name === "registrationNumber" && !rcVerified ? { handleValidate: handleRcValidate } : {})}
            {...(name === "registrationNumber" ? { isValidate: true } : {})}
        />
    );
};

export default AssetItem;
